export const MembershipValues = [
    {
        title: 'Free Level',
        image: '',
        price: '0',
        points: [
            "5 recipes per month, all of them in guided mode.",
            "Ad-free content space. No distractions.",
            "Ability to cook with step by step mode.",
            "5 food articles to keep you updated with the latest news.",
            "Your personal feed to view the content.",
        ]
    },
    {
        title: 'Paid Level',
        image: '',
        price: '299',
        points: [
            "10 recipes per month, all of them in guided mode.",
            "A personal feed with the ability to like, share and feedback.",
            "2 weekly meal plans created specifically for your diet type.",
            "5 prepped meal-kits delivered per week.",
            "Exclusive recipes according to your cuisine preferences.",
            "One-on-one chat and cooking session for expert guidance.",
            "Live masterclass to learn cooking techniques.",
            "10% on Klynk.appliances for smart devices.",
        ]
    }
]