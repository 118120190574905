import { CRUDoperations } from "../CRUD/crud";

const presets = {
    create: async (data) => {
        try {
            const result = await CRUDoperations.create('presets', data);
            return result;
        } catch (err) {
            console.log(err);

        }
    },
    read: async () => {
        try {
            const data = await CRUDoperations.read('presets');
            return data;

        } catch (err) {
            console.log(err);
        }
    },

    update: async (docID, data) => {
        try {
            const updatedData = await CRUDoperations.update('presets', docID, data);
            return updatedData;
        } catch (err) {
            console.log(err);

        }
    },
    delete: async (docID) => {
        try {
            const deletedData = await CRUDoperations.delete('presets', docID);
            return deletedData;
        } catch (err) {
            console.log(err);
        }
    },

}

export default presets