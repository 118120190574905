import React from 'react';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionHeader, HeaderTypography, ItemsLink } from '../Styles';
import { Profileinformation } from './initialValues';
import styled from '@emotion/styled';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingBottom: 0,
  '&:last-child' : {
    paddingBottom: '10px'
  }
  // borderTop: "1px solid rgba(0, 0, 0, .125)"
}));

const ProfileDashBord = () => {
  return (
    <div style={{ borderBottomLeftRadius: 5, borderBottomRightRadius: 5 }}>
      <AccordionHeader>
        <HeaderTypography>PROFILE COMPLETION</HeaderTypography>
      </AccordionHeader>
      {Profileinformation.map((item, index)=> {
        return (
          <Accordion key={index}>
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{item.headerName}</Typography>
            </AccordionSummary>
            {Array.isArray(item.itemList) ? ( item.itemList.map((item, index)=> {
              return (
                <AccordionDetails key={index}>
                  <ItemsLink to={item.to}>
                    {item.name}
                  </ItemsLink>
                </AccordionDetails>
              )
            })
              
            ) : (
              "No data"
            )}
          </Accordion>
        )
      })}
    </div>
  );
}

export default ProfileDashBord;