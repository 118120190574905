import { firestore } from "../firebaseConfig";
const EntityAPI = {

    diets: firestore.collection("/diets"),
    allergies: firestore.collection("allergies"),
    cuisines: firestore.collection("/cuisines"),
    tools: firestore.collection("tools"),
    ingredientCategory: firestore.collection("ingredientCategories"),

    ingredient: firestore.collection("ingredients"),
    medical: firestore.collection('medicalRestrictions'),
    presets: firestore.collection("presets"),
    units: firestore.collection("units"),
    cookAction: firestore.collection("cookAction"),
    ingredientType: firestore.collection("ingredientType"),
    prepStyle: firestore.collection("prepStyles"),
    conversions: firestore.collection("conversions"),
}
export default EntityAPI