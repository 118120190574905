import React,{useState} from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {makeStyles} from '@mui/styles'

const useStyles = makeStyles((theme)=>({
    imageDiv:{
        
        width:47,
        height:47,
        backgroundColor:'#F5F5F5',
        borderRadius:50,
        border:'1px solid #707070',
        borderLeft:'none',
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center'
            
    },
    chip:{
        margin:5,
        border:"1px solid #DDDDDD",
        padding:5,
        borderRadius:50,
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'flex-start',
       
        '&:hover':{
            cursor:'pointer',
            backgroundColor:'#5200F1',
            color:'white',
         
        }
    },
    ingredientChip:{ 
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        border:"1px solid #707070",
        borderRadius:24,
        margin:5,
        cursor:'pointer',
        transition:'0.2s linear',
        '&:hover':{
            backgroundColor:(props)=>props.insideSection ? 'transparent' : theme.palette.secondary.main,
            color:(props)=>props.insideSection ? 'black' : 'white'
        }
    },
    chipClose:{
        margin:0,
        padding:"0 5px",
        transition:'0.5s ease-in-out',
        fontSize:26
        
    },

}))

export default function IngredientChip(props) {
    const [startedHover ,setStartedHover] = useState(false)
    const classes = useStyles(props)
    return (
        <Grid item  
            onMouseEnter={()=>setStartedHover(true)}
            onMouseLeave={()=>setStartedHover(false)}
            onClick={props.shiftToIngredients}
            >
                <Box
             
                className={classes.ingredientChip}
                >

            
                <Box  className={classes.imageDiv} >
                    <img src={props.image} style={{width:'60%',height:'70%'}} />
                </Box>
                <Typography margin={0} fontSize={18} padding={1}  >{props.name}</Typography>
                {startedHover && props.insideSection && (
                    <p className={classes.chipClose} onClick={props.remove}>x</p>
                )}
                </Box>
            </Grid>
    )
}
