import { Grid } from "@mui/material";
import { styled } from "@mui/styles";

export const SiteEditorCustomGrid = styled(Grid)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '5px',
    height: '40px',
    padding: '5px',
    border: '1px solid #DBDCDD',
    borderRadius: '5px',
});

export const SiteStylesCustomGrid = styled(Grid)({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '5px',
    height: '100px',
    padding: '5px',
    width: 'fit-content',
    border: '1px solid #DBDCDD',
    borderRadius: '5px',
});