import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Masonry from '@mui/lab/Masonry';
import { ProfileLeftContainerStep3 } from '../Styles';

const heights = [150, 90, 70, 110, 150, 80, 50, 130, 90, 100, 150, 80];

const Item = styled(Paper)(({ theme }) => ({
  margin: 25,
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function BasicMasonry() {
  return (
    <ProfileLeftContainerStep3>
      <Masonry columns={3} spacing={2}>
        {heights.map((height, index) => (
          <Item key={index} sx={{ height }}>
            <label>Recipe Name</label>
            <br />
            <label>Cook: 1hr 30m | Prep: 15m</label>
          </Item>
        ))}
      </Masonry>
    </ProfileLeftContainerStep3>
  );
}