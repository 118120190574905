import React,{useState} from 'react'
import Button from '@mui/material/Button'
import { ButtonBase } from '@mui/material'
import {makeStyles} from '@mui/styles'
import {styled} from '@mui/material/styles'

const useStyles = makeStyles({
    button:{
        color:'black',
        

        '&:hover':{

            backgroundColor:'#F94C0C',
            color:'white'
        },
    },
    
})

const CustomButton = styled(Button)((theme)=>({
    '& .MuiButton-outlinedPrimary':{
        color:'#F94C0C'
    },
    '& .MuiButtonBase-root':{

    },
    '& .MuiButtonBase-root:hover':{
        backgroundColor:'transparent'
    },
    '& .MuiButton-root':{
        backgroundColor:'black'
    }
}))


export default function MuiButton(props) {
    const [hovered,setHoverd] = useState(false);
    
    return (
        <Button 
            color="primary"
            variant={hovered ? "contained" : "outlined"} 
            href={props.to} 
            startIcon={props.start} 
            endIcon={props.end} 
            onMouseEnter = { ()=>setHoverd(true) }
            onMouseLeave = { ()=>setHoverd(false) }
            onClick={props.click}
            sx={{ width: "100%", height: "auto" }}
            {...props}
        >
            {props.title}
        </Button>
    )
}
