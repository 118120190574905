import React,{useEffect,useState} from 'react'
import {Grid, Typography,Box} from '@mui/material'
import CloudServices from '../../cloud-services-core'

export default function RecipeCard(props) {
    const [recipe,setRecipe] = useState({})

    useEffect(() => {
        // getRecipe(props.id)
    }, [])

    const getRecipe = async (id) =>{
        try {
            const gettingRecipe = await CloudServices.recipes.getRecipeWithId(id)
            setRecipe(gettingRecipe)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Box  style={{width:300,boxSizing:'border-box',border:'1px solid #DBDCDD',borderRadius:10,boxShadow:'0 0 3px #DBDCDD'}}>

        <Grid  container  style={{borderRadius:10}} >
            <Grid item xs={5}>
                <img src='https://www.indianhealthyrecipes.com/wp-content/uploads/2018/10/fruit-custard-recipe.jpg' style={{width:'100%',height:'100%',objectFit:'cover',borderRadius:10}} />
            </Grid>
            <Grid item xs={7} style={{padding:10}}>
                <Typography style={{fontSize:12}}>A dessert made by mixing different cut fruits in chilled custard sauce. Any sweet tasting fresh fruits go well in this fruit custard recipe. Serve fruit custard as a chilled dessert.</Typography>
            </Grid>
        </Grid>
        </Box>
    )
}
