import {
    REACT_APP_VISION_KEY,
  } from 'dotenv'





const vision = {
    get:async(uri)=>{
        try {
            const body = JSON.stringify({
                requests: [
                    {
                      features: [
                        { type: "LABEL_DETECTION", maxResults: 5 },
                        // { type: "LANDMARK_DETECTION", maxResults: 5 },
                        // { type: "FACE_DETECTION", maxResults: 5 },
                        // { type: "LOGO_DETECTION", maxResults: 5 },
                        // { type: "TEXT_DETECTION", maxResults: 5 },
                        // { type: "DOCUMENT_TEXT_DETECTION", maxResults: 5 },
                        // { type: "SAFE_SEARCH_DETECTION", maxResults: 5 },
                        // { type: "IMAGE_PROPERTIES", maxResults: 5 },
                        // { type: "CROP_HINTS", maxResults: 5 },
                        // { type: "WEB_DETECTION", maxResults: 5 }
                      ],
                      image: {
                        content:uri
                      }
                    }
                  ]
            })
            let response = await fetch("https://vision.googleapis.com/v1/images:annotate?key="+ REACT_APP_VISION_KEY,
            {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json"
                },
                method: "POST",
                body: body
              })
              let responseJson = await response.json();
              return responseJson
        } catch (error) {
            throw error
        }

    }
}

export default vision