import React, { useEffect, useContext, useState } from 'react'
import { Typography, Box, Grid, TextField, Button, Divider } from '@mui/material';
import AppContext from '../../../config/AppContext';
import { Apps } from '@mui/icons-material';
import CreatorNavbar from '../Common/CreatorNavbar';


const PaymentsSuccess = () => {
    const AppState = useContext(AppContext);
    useEffect(() => {
        AppState.handleNavbar(false);

        return () => {
            AppState.handleNavbar(true);
        }
    }, [])


    return (
        <Grid style={{ display: 'flex', height: '100vh', width: '100vw', flexDirection: 'row', justifyContent: 'flexStart' }}>
            <CreatorNavbar />

            <Grid style={{ display: 'flex', width: '40vw', flexDirection: 'column', marginTop: 45, marginLeft: 30, marginRight: 30, marginBottom: 30 }}>
                <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', marginBottom: 20 }}>
                    <Typography variant='h5' style={{ color: '#000000' }}>
                        Purchase Receipt
                    </Typography>
                    <Typography style={{ color: '#7B7B7B' }}>
                        Invoice #6822BSJ-9279
                    </Typography>
                    <Typography style={{ color: '#7B7B7B' }}>
                        Receipt #2374-3829
                    </Typography>

                </Grid>

                <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20 }}>
                    <Grid item >
                        <Typography style={{ color: '#7B7B7B', textTransform: 'uppercase' }}>
                            Amount Paid
                        </Typography>

                        <Typography style={{}}>
                            Rs. 350
                        </Typography>




                    </Grid>

                    <Grid item >
                        <Typography style={{ color: '#7B7B7B', textTransform: 'uppercase' }}>
                            Date Paid
                        </Typography>

                        <Typography style={{}}>
                            May 10, 2022
                        </Typography>




                    </Grid>

                    <Grid item >
                        <Typography style={{ color: '#7B7B7B', textTransform: 'uppercase' }}>
                            Payment Method
                        </Typography>

                        <Typography style={{}}>
                            Masted Card 444
                        </Typography>




                    </Grid>




                </Grid>

                <Grid style={{ display: 'flex', flexDirection: 'column', marginBottom: 100 }}>
                    <Typography item style={{ color: '#7B7B7B', textTransform: 'uppercase' }}>
                        SUMMARY
                    </Typography>

                    <Grid item style={{ display: 'flex', flexDirection: 'column', marginTop: 5, background: '#F4F4F4', padding: 20 }}>
                        <Typography item style={{ textTransform: 'uppercase' }}>
                            21 May to June 31
                        </Typography>

                        <Grid item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Typography item style={{ color: '#5C5C5C' }}>
                                Silver Memebership
                            </Typography>
                            <Typography item style={{ textTransform: 'uppercase' }}>
                                Rs. 142
                            </Typography>
                        </Grid>

                        <Grid item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
                            <Typography item style={{ color: '#5C5C5C' }}>
                                Tax
                            </Typography>
                            <Typography item style={{ textTransform: 'uppercase' }}>
                                Rs. 21
                            </Typography>

                        </Grid>

                        <Divider />

                        <Grid item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 15 }}>
                            <Typography item style={{ color: '#5C5C5C', fontWeight: 600 }}>
                                Amount Paid
                            </Typography>
                            <Typography item style={{ textTransform: 'uppercase' }}>
                                Rs. 163
                            </Typography>

                        </Grid>



                    </Grid>


                </Grid>

                <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Grid item style={{ border: '1px solid grey', borderRadius: 50, padding: 5, marginRight: 10 }}>
                        <Typography item style={{}}>
                            Back to my memberships
                        </Typography>


                    </Grid>

                    <Grid item style={{ border: '1px solid grey', borderRadius: 50, padding: 5, background: '#C9C9C9', color: '#535353' }}>
                        <Typography item >
                            Download as pdf
                        </Typography>


                    </Grid>





                </Grid>



            </Grid>

            <Grid style={{ display: 'flex', width: '40vw', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', background: '#E3E3E3' }}>
                <Typography item variant='h4'>
                    New Recipes Unlocked
                </Typography>

                <Grid item style={{ marginTop: 150, border: '1px solid grey', borderRadius: 50, padding: 5, background: '#FFF', color: '#000', paddingLeft: 30, paddingRight: 30 }}>
                    <Typography item marginLeft='30'>
                        Explore
                    </Typography>


                </Grid>

            </Grid>


        </Grid >
    )
}

export default PaymentsSuccess;
