import React, { useState, useEffect, useContext } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { FirebaseApi } from '../../../firebaseConfig';
import CloudServices from '../../../cloud-services-core';
import { Grid } from '@mui/material';
import AppContext from '../../../config/AppContext';


const SubscribersTable = (props) => {
    const [loadingSubcribers, setLoadingSubscribers] = useState(true);
    const AppState = useContext(AppContext);
    console.log(AppState);
    const [rowData, setRowData] = useState([]);

    const columns =
        [
            { field: 'id', headerName: '#', width: 130 },
            { field: 'username', headerName: 'User Name', width: 150 },
            { field: 'email', headerName: 'Email', width: 200 },
            {
                field: 'subscription',
                headerName: 'Subscription',
                width: 150,

            },
            {
                field: 'level',
                headerName: 'Level',
                width: 150,
            },
            { field: 'activity', headerName: 'Activity', width: 150 },


        ];




    // to get subscribers
    const getData = async () => {
        try {
            const data = await FirebaseApi["creatorSubscribers"].get()
            console.log(data);
            const parsedData = data.docs.map((item) => {
                return {
                    id: item.id,
                    ...item.data()
                }
                // return item
            })

            setRowData(parsedData);
            setLoadingSubscribers(false);

            console.log('calling getDetails', parsedData)
            getDetails(parsedData);


        } catch (error) {
            console.log(error);
        }
    }

    let final = [];

    // to get subscribers details
    const getDetails = (data) => {
        console.log(data);
        data.map(async (x) => {
            console.log("get details called");
            let val = await CloudServices.user.getUserDetails(x.subscriberId);
            let newData = Object.assign({ ...x, ... { username: val['username'] }, ...{ email: val['email'] } });
            //rowData.push({ ...x, ... { username: val['username'] }, ...{ email: val['email'] } });
            console.log(newData);
            //rowData.unshift(newData);
            final = [...final, newData];
            console.log(final);
            setRowData(final);


        })


    }



    useEffect(() => {
        getData();



    }, [])

    // to get member figures
    const getMembers = () => {
        let free = 0;
        let silver = 0;
        let gold = 0;
        rowData.map(x => {
            console.log(x.level);
            if (x.level == 'Free') {
                free += 1;
            }
            else if (x.level == 'Silver') {
                silver += 1;
            } else {
                gold += 1;
            }
        });

        props.setFreeMembers(free);
        props.setSilverMembers(silver);
        props.setGoldMembers(gold);


    }
    getMembers();


    const noData = () => {
        return (
            <Grid style={{ display: 'flex', justifyContent: 'center', height: 300, alignItems: 'center' }}>
                <h2> No Data available </h2>
            </Grid>
        )
    }


    return (
        <>
            {loadingSubcribers ?
                <h3> Loading Data... </h3>
                :

                <Grid style={{ height: 400, width: '100%' }}>
                    <DataGrid
                        rows={rowData}
                        columns={columns}
                        components={{
                            NoRowsOverlay: noData
                        }}

                        checkboxSelection
                    />
                </Grid>
            }
        </>

    )
}

export default SubscribersTable;