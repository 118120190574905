import React from 'react'
import { Grid, TextField, Typography } from '@mui/material';
import { SiteEditorCustomGrid, SiteStylesCustomGrid } from './Styles';
import Simple from '../../../assets/CreatorScreen/simple.svg'
import Random from '../../../assets/CreatorScreen/random.svg'

const StylesLayout = ({ formik }) => {
    const { values, setFieldValue } = formik;

    return (

        <Grid container style={{ margin : '10px auto', width: '80%'}}>
            <Grid item>
                <Typography style={{ textTransform: 'capitalize' }}>Layout</Typography>
                <Grid item container>
                    <SiteStylesCustomGrid>
                        <img src={Simple} alt='' />
                        Simple
                    </SiteStylesCustomGrid>
                    <SiteStylesCustomGrid>
                        <img src={Random} alt='' />
                        Random
                    </SiteStylesCustomGrid>
                </Grid>
            </Grid>
            <Grid item container>
                <Typography style={{ textTransform: 'capitalize', marginTop: 15 }}>Colors</Typography>
                <Grid item container direction='column'>
                    <SiteEditorCustomGrid>
                        <Typography style={{ textTransform: 'capitalize', width: 'fit-content' }}>Background</Typography>
                        <TextField type="color" variant='standard' value={values.backgroundColor} onChange={e=> setFieldValue('backgroundColor', e.target.value)} id='backgroundColor' name='backgroundColor'placeholder='' style={{ marginLeft: 2, marginBottom: -5, width: '20%' }} InputProps={{ disableUnderline: true }} />
                    </SiteEditorCustomGrid>
                    <SiteEditorCustomGrid>
                        <Typography style={{ textTransform: 'capitalize' }}>Accent</Typography>
                        <TextField variant='standard' type="color" value={values.accentColor} onChange={e=> setFieldValue('accentColor', e.target.value)} id="accentColor" name='accentColor' placeholder='' style={{ marginLeft: 2, marginBottom: -5, width: '20%' }} InputProps={{ disableUnderline: true }} />
                    </SiteEditorCustomGrid>
                </Grid>
            </Grid>
            <Grid item container>
                <Typography style={{ textTransform: 'capitalize', marginTop: 15  }}>Fonts</Typography>
                <Grid item container direction='column'>
                    <SiteEditorCustomGrid>
                        <Typography style={{ textTransform: 'capitalize' }}>Heading : </Typography>
                        <TextField variant='standard' type="text" value={values.headerFont} onChange={e=> setFieldValue('headerFont', e.target.value)} id="headerFont" name='headerFont' size='small' placeholder='Arial Bold' style={{ marginLeft: 2, marginBottom: -5, width: '70%' }} InputProps={{ disableUnderline: true }} />
                    </SiteEditorCustomGrid>
                    <SiteEditorCustomGrid>
                        <Typography style={{ textTransform: 'capitalize' }}>Body : </Typography>
                        <TextField type="text" variant='standard' value={values.paraFont} onChange={e=> setFieldValue('paraFont', e.target.value)} id="paraFont" name="paraFont" size='small' placeholder='Arial Regular' style={{ marginLeft: 2, marginBottom: -5, width: '70%' }} InputProps={{ disableUnderline: true }} />
                    </SiteEditorCustomGrid>
                </Grid>
            </Grid>
        </Grid>
    )
  }

export default StylesLayout;