import React, { useState, useEffect, useContext } from 'react'
import { Typography, Grid } from '@mui/material';
import { Formik, useFormik } from 'formik';
import CloudServices from '../../../cloud-services-core';


const TestCollection = () => {
    console.log(CloudServices);

    const submitArticles = async (values) => {
        try {
            const article = await CloudServices.creatorMemberships.create(values);

            console.log("Updated");
        } catch (err) {
            console.log(err);
        }
    }

    const formik = useFormik({
        initialValues: {
            creatorId: '',
            membershipTitle: '',
            membershipAmount: '',
        },
        onSubmit: values => {
            submitArticles(values);
        },
    });
    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid style={{ display: 'flex', flexDirection: 'column', height: 800, justifyContent: 'center', alignItems: 'center' }}>
                <Typography >Creator Id</Typography>
                <input

                    id="creatorId"
                    onChange={formik.handleChange}

                />
                <Typography>Plan Name</Typography>
                <input
                    id="membershipTitle"
                    onChange={formik.handleChange}

                />
                <Typography >Amount</Typography>
                <input

                    id="membershipAmount"
                    onChange={formik.handleChange}

                />


                <Grid>
                    <button type="submit">Submit</button>
                </Grid>
            </Grid>
        </form>
    )
}

export default TestCollection;