import React, { Component } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

// import {theme} from './config/theme';

import theme from './config/MuiTheme'
import MainContent from './components/MainContent';
import { routes } from './config/routes';
import AppContext from './config/AppContext';
import HomePage from './pages/Home/HomePage'

import rikuLogo from './assets/rikuLogo1x.png'

import Snackbar from '@mui/material/Snackbar';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import Login from './pages/Auth/Login';
import MuiAlert from '@mui/material/Alert';
import VerifyEmail from './pages/Auth/VerifyEmail';
import ResetPassword from './pages/Auth/ResetPassword';
import CloudServices from './cloud-services-core';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class App extends Component {
  state = {
    drawerOpen: false,
    loggedIn: false,
    // user: {},
    user: {
      id: '007',
      // fullName: ${`firstName+' '+lastName`},
      userName : 'james', // displayName
      email: 'james007@ftlabs.com',
      password: '',
      displayName: '',
      // billings data
      // subscription data
      // memberships
      confirmPassword: '', //new
      profileImage: '',
      importRecipesURL: '',
      webURL: '',
      spaceName: {
        layout: 'Simple',
        accentColor: '',
        backgroundColor: '',
        paraFont: '',
        headerFont: '',
        description: '',
        button: 'SIGN UP'
      },
      social: {
        facebookID: '',
        youtubeID: '',
        googleID: ''
      },
      memberShipLevels: [], // new
      rechartsData: [], // new
      firstName: 'James',
      lastName: 'Bond',
      country: 'India',
      emailVerify: false,
      razorPayDetails: [],
      parseFromOutsideURL: false,
      // recipes & articles
      collection: {
        draftedRecipes: [],
        publishedRecipes: [],
        draftedArticles: [],
        publishedArticles: [],
        featuredCollection: [],
      }
    },
    recipes: [],
    selectRow: '',
    snackBarOpen: false,
    snackBarMessage: '',
    snackBarMessageType: '',
    vertical: 'bottom',
    horizontal: 'left',
    currentEntityType: '',
    currentEntityData: '',
    rowsCount: '',
    hideNavbar: true,
    isEditing: false
  }

  getRecipesFromDB = async () => {
    const recipesFromDB = await CloudServices.recipes.search({ order: 'descending', orderBy: 'datePublished', limit: 20 });
    this.setState({ recipes: recipesFromDB });
    // console.log(this.state.recipes);
  };

  componentDidMount(){
    this.getRecipesFromDB();
  }
  
  handleSelectedRows = (value) => {
    this.setState({ selectRow: value})
  }

  handleDrawerToggle = () => {
    this.setState({ drawerOpen: !this.state.drawerOpen })
  }
  closeDrawer = () => {
    // console.log("closing drawer")
    this.setState({ drawerOpen: false })
  }

  authenticate = (user) => {
    // console.log("loggin in...", user)
    this.setState({ loggedIn: true, user })
  }

  logOut = () => {
    this.setState({ loggedIn: false, user: {} })
  }

  snackBarAlert = (message, messageType) => {
    this.setState({
      snackBarOpen: true,
      snackBarMessage: message,
      snackBarMessageType: messageType
    })
  }

  handleCurrentEntityType = (value) => {
    this.setState({
      currentEntityType: value
    })
  }

  handleCurrentEntityData = (value) => {
    this.setState({
      currentEntityData: value,
    })
  }

  handleRows = (rows) => {
    this.setState({
      rowsCount: rows
    })
  }

  handleNavbar = (value) => {
    this.setState({
      hideNavbar: value
    })
  }

  handleUser = (value) => {
    this.setState({
      user: value
    })
  }

  render() {
    const { vertical, horizontal } = this.state

    return (
      <ThemeProvider theme={theme}>
        <AppContext.Provider value={{ recipes: this.state.recipes, isEditing: this.state.isEditing, currentEntityData: this.state.currentEntityData, currentEntityType: this.state.currentEntityType, drawerOpen: this.state.drawerOpen, handleDrawerToggle: this.handleDrawerToggle, closeDrawer: this.closeDrawer, authenticate: this.authenticate, loggedIn: this.state.loggedIn, user: this.state.user, logOut: this.logOut, showSnackBar: this.snackBarAlert, handleCurrentEntityType: this.handleCurrentEntityType, handleCurrentEntityData: this.handleCurrentEntityData, handleRows: this.handleRows, handleNavbar: this.handleNavbar, handleUser: this.handleUser }}>
          <Router>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {this.state.hideNavbar && <HomePage />}
              <MainContent>
                <AppBar position="static" sx={{
                  display: { xs: 'block', sm: 'none' },
                  backgroundColor: '#2D3142'
                }} >
                  <Toolbar>
                    <IconButton
                      size="large"
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      sx={{ mr: 2 }}
                      onClick={() => this.setState({ drawerOpen: !this.state.drawerOpen })}
                    >
                      <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                      <div style={{ width: 100, height: 50 }}>
                        <img src={rikuLogo} alt="logo" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                      </div>
                    </Typography>
                    <Button color="inherit">Login</Button>
                  </Toolbar>
                </AppBar>
                <Switch >
                  <Route path="/login" component={Login} />
                  <Route path="/verifyEmail" component={VerifyEmail} />
                  <Route path="/resetPassword" component={ResetPassword} />
                  {/* <Auth auth={this.state.loggedIn} path="/"> */}
                  {routes.map(route => (
                    <Route
                      key={route.path}
                      exact={route.exact}
                      path={route.path}
                      component={route.component}
                    />
                  ))}

                  {/* </Auth> */}
                </Switch>
                <Snackbar
                  anchorOrigin={{ vertical, horizontal }}
                  open={this.state.snackBarOpen}
                  autoHideDuration={3000}
                  onClose={() => this.setState({ snackBarOpen: false })}
                >
                  <Alert onClose={() => this.setState({ snackBarOpen: false })} severity={this.state.snackBarMessageType} sx={{ width: '100%' }}>
                    {this.state.snackBarMessage}
                  </Alert>
                </Snackbar>
              </MainContent>
            </div>
          </Router>
        </AppContext.Provider>
      </ThemeProvider>
    );
  }
}

export default App;
