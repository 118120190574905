import {createTheme} from '@mui/material/styles'


const theme = createTheme({
    palette:{
        primary:{
            main:"#F94C0C"
        },
        secondary:{
            main:"#292929"
        },
        info:{
            main:'#ffffff'
        }
    },components:{
    
    }
    
})

export default theme