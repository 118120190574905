import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid';
import { Button, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import ImageUploader from '../ImageUploader';
import Modal from '@mui/material/Modal';
import { Autocomplete } from '@mui/material';
import { FirebaseApi } from '../../../firebaseConfig';
import { useLocation } from 'react-router-dom';
import * as Yup from "yup";

const IngredientModal = () => {
    const modal = true;
    const dataOf = 'ingredient';
    const [selectedRows, setSelectedRowData] = useState([])
    const [dietList, setDietList] = useState([])
    const [medicalList, setMedicalList] = useState([])
    const [cuisinesList, setCuisinesList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const location = useLocation()
    const [currentEntityData, setCurrentEntityData] = useState(location.state);

    const history = useHistory();

    const getMedicalList = async () => {
        try {
            const medicalsFromDB = await FirebaseApi["medical"].get();
            const medicalsData = medicalsFromDB.docs.map((item) => {
                return {
                    id: item.id,
                    ...item.data()
                }
            })
            setMedicalList(medicalsData);
        } catch (error) {
            console.log(error)
        }
    };

    const getDietsList = async () => {
        try {
            const dietsFromDB = await FirebaseApi["diets"].get();
            const dietsData = dietsFromDB.docs.map((item) => {
                return {
                    id: item.id,
                    ...item.data(),
                }
            })
            setDietList(dietsData);
        } catch (error) {
            console.log(error)
        }
    }

    const getCuisineList = async () => {
        try {
            const cuisinesFromDB = await FirebaseApi["cuisines"].get();
            const cuisinesData = cuisinesFromDB.docs.map((item) => {
                return {
                    id: item.id,
                    ...item.data(),
                }
            })
            setCuisinesList(cuisinesData);
        } catch (error) {
            console.log(error)
        }
    }

    const getCategoryList = async () => {
        try {
            const categoryFromDB = await FirebaseApi["ingredientCategory"].get();
            const categoryData = categoryFromDB.docs.map((item) => {
                return {
                    id: item.id,
                    ...item.data(),
                }
            })
            setCategoryList(categoryData);
        } catch (error) {
            console.log(error)
        }
    }

    const changeImageLink = (value) => {
        formik.setFieldValue('imageUrl', value);
    }
    
    useEffect(() => {
        if (location.state) {
            setCurrentEntityData(location.state)
        } else {
            // Get the data from the db using the params
        }
        getMedicalList();
        getDietsList();
        getCategoryList();
        getCuisineList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // CRUD
    const addIngredient = (rowData) => {
        console.log(rowData, 'adding');
        FirebaseApi[dataOf].add({
            ...rowData
        }).then(function (docRef) {
            // console.log(docRef);
        })
        .catch(function (error) {
            console.log(error.message);
        })
    }

    const updateIngredient = (data, id) => {
        console.log(data, 'updating');
        FirebaseApi[dataOf].doc(id).update(data)
            .then((result) => {
                // setSelectedRowData(null);
                // console.log(result);
            })
            .catch((error) => {
                console.log(error.message);
            })
            console.log(data, 'updated');
    }

    const actionTriggered = (edited, data) => {
        if (data.id) {
            const { name, categories, imageUrl, cuisines, medicalPreference, dietsPreference } = data
            // const { name, density, baseValue, preparationStyle, unit, humanBaseValue, category, defaultType, imageUrl, expiry,expirationDate,itemCategory,  } = data
            updateIngredient({ name, categories, imageUrl, cuisines, medicalPreference, dietsPreference }, data.id);
            history.goBack();
        }
        else {
            const { name, categories, imageUrl, cuisines, medicalPreference, dietsPreference } = data
            addIngredient({ name, categories, imageUrl, cuisines, medicalPreference, dietsPreference })
            history.goBack();
        }
    };

    const formik = useFormik({
        initialValues: {
            'id': currentEntityData ? currentEntityData.id : '' ,
            'name': currentEntityData ? (currentEntityData.name ?? currentEntityData.data) : '',
            'imageUrl' :  currentEntityData ? (currentEntityData.imageUrl ?? null ) : null,
            'categories': currentEntityData ? (currentEntityData.categories ?? []) : [],
            'dietsPreference' : currentEntityData ? (currentEntityData.dietsPreference ?? []) : [] ,
            'medicalPreference': currentEntityData ? (currentEntityData.medicalPreference ?? []) : [],
            'cuisines':currentEntityData ? (currentEntityData.cuisines ?? []) : [],
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required('Required'),
            // imageUrl: Yup.string().required('Required'),
        }),
        onSubmit: values => {
            if (currentEntityData) {
                actionTriggered(true, values)
            } else {
                actionTriggered(false, values)
            }
            console.log(values, 'submitting')
        },
    });
    // console.log(formik.values.imageUrl);

    return (
        <Modal
        open={modal}
        // close={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            <form onSubmit={formik.handleSubmit} style={{ outline: "none" }}>
            <Grid sx={style} container spacing={2} flexDirection='column' justifyContent="space-between">
                  <Grid item style={{ display: "flex", flexDirection: "row" }} justifyContent='space-between' marginBottom='10'>
                        {currentEntityData ? <Typography fontWeight='700' variant='h6'>Edit Ingredient</Typography> : <Typography fontWeight='700' variant='h6'>Add Ingredient</Typography>}
                        <CloseIcon style={{ marginLeft: '100px', cursor: 'pointer' }} onClick={() => history.goBack()} />
                    </Grid>
                    <Grid item>
                        {formik.errors.name && formik.errors.imageUrl ? 
                            <Typography style={{ color: 'red', fontSize: 10 }}>Please fill in the required fields</Typography>: null
                        }
                        { formik.errors.imageUrl ? 
                            <Typography style={{ color: 'red', fontSize: 10 }}>Please fill in the image field</Typography>: null
                        }
                        {formik.errors.name  ? 
                            <Typography style={{ color: 'red', fontSize: 10 }}>Please fill in the name field</Typography>: null
                        }
                    </Grid>
                    <Grid item style={{ display: "flex", flexDirection: "row" }}>
                            {
                                formik.values.imageUrl &&
                                <img src={formik.values.imageUrl} id="imageUrl" style={{ paddingLeft: "1px", width: 100, height: 100 }} alt="add ingredient"/>
                            }
                        <ImageUploader id="imageUrl" changeImageLink={changeImageLink} storagePlace={'ingredient'} />
                    </Grid>
                <Grid item width={0.8}>
                    <Typography>
                        Ingredient Name*
                    </Typography>
                    <TextField
                        id="name"
                        defaultValue={formik.values.name ?? ''}
                        variant="outlined"
                        size='small'
                        onChange={formik.handleChange}
                    />
                </Grid>
                <Grid item width={0.8}>
                    <Typography>
                        Category
                    </Typography>
                    <Autocomplete 
                        id="category"
                        size="small"
                        multiple
                        freeSolo
                        options={categoryList}
                        getOptionLabel={(option) => (option.name) || ''}
                        defaultValue={formik.values.categories ?? []}
                        filterSelectedOptions
                        onChange={(e, value) => formik.setFieldValue('categories', value)}
                        renderInput={params => (
                            <TextField
                                placeholder='Category'
                                {...params}
                            />
                        )}
                    />
                </Grid>
                <Grid item width={0.8}>
                    <Typography>
                        Diets Preferences (if applicable)
                    </Typography>
                    <Autocomplete 
                        id="dietsPreference"
                        size="small"
                        multiple
                        freeSolo
                        filterSelectedOptions
                        options={dietList}
                        getOptionLabel={(option) => option.name}
                        defaultValue={formik.values.dietsPreference}
                        onChange={(e, value) => formik.setFieldValue('dietsPreference', value)}
                        renderInput={params => (
                            <TextField
                                placeholder='Diets Preference'
                                {...params}
                            />
                        )}
                    />
                </Grid>
                <Grid item width={0.8}>
                    <Typography>
                        Medical Preference (if applicable)
                    </Typography>
                    <Autocomplete 
                        id="medicalPreference"
                        size="small"
                        multiple
                        freeSolo
                        filterSelectedOptions
                        options={medicalList}
                        getOptionLabel={(option) => option.name}
                        defaultValue={formik.values.medicalPreference}
                        onChange={(e, value) => formik.setFieldValue('medicalPreference', value)}
                        renderInput={params => (
                            <TextField
                                placeholder='Medical Preference'
                                {...params}
                            />
                        )}
                    />
                </Grid>
                <Grid item width={0.8}>
                    <Typography>
                        Cuisines (if applicable)
                    </Typography>
                    <Autocomplete 
                        id="cuisines"
                        multiple
                        freeSolo
                        filterSelectedOptions
                        size="small"
                        options={cuisinesList}
                        getOptionLabel={(option) => (option.name || [])}
                        value={formik.values.cuisines}
                        onChange={(e, value) => formik.setFieldValue('cuisines', value)}
                        renderInput={params => (
                            <TextField
                                placeholder='Cuisines'
                                {...params}
                            />
                        )}
                    />
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={() => { formik.handleSubmit(); }}>{currentEntityData ? 'Save' : 'Add'}</Button>
                </Grid>       
            </Grid>
        </form>
    </Modal>
    )
}

export default IngredientModal

const style = {
    marginTop: "2%",
    marginLeft: 'auto',
    marginRight: "auto",
    width: '50%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
};
