const storage = {
  getItem: (key) =>
    typeof localStorage !== "undefined" ? localStorage.getItem(key) : undefined,
  setItem: (key, value) => {
    if (key && typeof localStorage !== "undefined") {
      localStorage.setItem(key, value);
    }
  },
  removeItem: (key) => {
    if (key && typeof localStorage !== "undefined") {
      localStorage.removeItem(key);
    }
  },
};

export default storage;