import React, { useEffect, useContext, useState } from 'react';
import { Grid, Button, Modal, Typography, TextField, Box } from '@mui/material';
import { makeStyles } from '@mui/styles'

import { Formik, useFormik, FieldArray, FormikProvider } from 'formik';
import AppContext from '../../../config/AppContext';
import { FirebaseApi } from '../../../firebaseConfig';
import CloudServices from '../../../cloud-services-core';

const useStyles = makeStyles({
    textField: {

        height: 42,
        border: '2px solid transparent',
        width: 261,
        "&.Mui-focused": {
            border: "2px solid #397EF5",
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
            }
        }
    },
    typography: {
        "& .MuiTypography-root": {

            fontFamily: 'Product Sans',
        }
    },

})


const TurnCreator = (props) => {
    const AppState = useContext(AppContext);
    const classes = useStyles();
    const [creatorModal, setCreatorModal] = useState(props.creatorModal || false);
    const [detailsModal, setDetailsModal] = useState(false || props.detailsModal);

    const formik = useFormik({
        initialValues: {
            beneficiaryName: '',
            accountType: '',
            location: '',
            ifscCode: '',
            accountNumber: '',

        },

        onSubmit: values => {
            console.log(values);
            updateUser(values)
        }
    })

    console.log(AppState.user, CloudServices);

    const updateUser = async (values) => {

        try {
            await FirebaseApi['user'].doc(AppState.user.id).update(
                JSON.parse(JSON.stringify({

                    bankDetails: values,
                    creator: true
                }))
            )

            updateLocal();
        } catch (err) {
            console.log(err);
        }


    }

    const updateLocal = async () => {
        try {
            const data = await FirebaseApi.user.doc(AppState.user.id).get();
            console.log(data.data());
            localStorage.setItem('user', JSON.stringify(data.data()));

        } catch (err) {
            console.log(err);
        }
    }



    const { values, setFieldValue } = formik

    console.log(values);


    useEffect(
        () => {

            //updateUser();
            //setCreatorModal(true);
            //setDetailsModal(true);

        }, []
    )

    const handleCreatorModalClose = () => {
        setCreatorModal(false);
    }

    const handleDetailsModalClose = () => {
        setDetailsModal(false);
    }

    const creatorModeModal = () => {
        return (
            <Modal
                open={creatorModal}
                onClose={handleCreatorModalClose}
                classes={{ root: classes.typography, }}
            >

                <Grid sx={creatorStyle}>
                    <Grid style={{ display: 'flex', flexDirection: 'column' }}>
                        <Grid style={{ marginBottom: 20 }}>
                            <Typography variant='h5' color='#F94C0C' marginBottom="5px">
                                Want to become a Creator?
                            </Typography>

                            <Typography>
                                Once you become a creator, you’ll be able to publish posts on a public page accessible by a unique URL.
                            </Typography>

                        </Grid>

                        <Grid style={{ marginBottom: 20 }}>

                            <Typography color='#8B8B8B' marginBottom="5px">
                                Other benefits include:
                            </Typography>
                            <Typography>
                                Hello World;
                            </Typography>

                            <Typography>
                                ...
                            </Typography>

                            <Typography>
                                World ends here;
                            </Typography>


                        </Grid>

                        <Grid style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
                            <Button onClick={handleCreatorModalClose} style={{ background: '#E2E4E6', color: 'black', borderRadius: 32, marginRight: 15 }}>
                                No, cancel

                            </Button>
                            <Button onClick={() => { setCreatorModal(false); setDetailsModal(true); }} style={{ background: '#F94C0C', color: 'white', borderRadius: 32, }}>
                                Yes, make me a creator

                            </Button>



                        </Grid>

                    </Grid>
                </Grid>

            </Modal >
        )
    }


    const detailsModeModal = () => {
        return (
            <Modal
                open={detailsModal}
                onClose={handleDetailsModalClose}
                classes={{ root: classes.typography, }}
            >

                <Grid sx={detailsStyle}>

                    <Grid style={{ marginBottom: 20 }}>
                        <Typography variant='h5' color='#F94C0C' marginBottom="5px">
                            Fill in your bank details
                        </Typography>

                        <Typography color='#E0E3E7'>
                            Build your brand, audience and income through our creator economy.
                        </Typography>

                    </Grid>

                    <Grid style={{ display: 'flex' }}>
                        <Grid item style={{ marginRight: 15, }}>

                            <Grid  >
                                <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', textTransform: 'uppercase' }}>Beneficiary Name</Typography>
                                <TextField autoComplete='off' placeholder="Enter Beneficaries Name" type="text" onBlur={(e) => setFieldValue('beneficiaryName', e.target.value)} defaultValue={values.beneficiaryName} InputProps={{ className: classes.textField }} />

                            </Grid>

                            <Grid  >

                                <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', textTransform: 'uppercase' }}>Accounts Type</Typography>
                                <TextField autoComplete='off' placeholder="Enter Account Type" type="text" onBlur={(e) => setFieldValue('accountType', e.target.value)} defaultValue={values.accountType} InputProps={{ className: classes.textField }} />


                            </Grid>

                            <Grid  >

                                <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', textTransform: 'uppercase' }}>location</Typography>
                                <TextField autoComplete='off' placeholder="Enter Location" type="text" onBlur={(e) => setFieldValue('location', e.target.value)} defaultValue={values.location} InputProps={{ className: classes.textField }} />


                            </Grid>



                        </Grid>

                        <Grid item  >
                            <Grid  >

                                <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', textTransform: 'uppercase' }}>Branch IFSC Code</Typography>
                                <TextField autoComplete='off' placeholder="Enter Banks IFSC code" type="text" onBlur={(e) => setFieldValue('ifscCode', e.target.value)} defaultValue={values.ifscCode} InputProps={{ className: classes.textField }} />

                            </Grid>

                            <Grid  >

                                <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', textTransform: 'uppercase' }}>Account Number</Typography>
                                <TextField autoComplete='off' placeholder="Enter Account Number" type="text" onBlur={(e) => setFieldValue('accountNumber', e.target.value)} defaultValue={values.accountNumber} InputProps={{ className: classes.textField }} />


                            </Grid>

                            <Grid>
                                <Button onClick={() => formik.submitForm()} style={{ color: 'white', background: '#F94C0C', marginTop: '27px', marginLeft: '74px' }}>
                                    Add me as a Creator
                                </Button>
                            </Grid>

                        </Grid>
                    </Grid>


                </Grid>





            </Modal >
        )
    }

    return (

        <Grid classes={{ root: classes.typography, }}>
            {
                creatorModeModal()
            }

            {detailsModal && detailsModeModal()

            }
        </Grid>

    )
}

export default TurnCreator;

const creatorStyle = {
    position: 'absolute',
    bottom: '-17%',
    left: '12%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    width: 377,
    padding: '32px',
    borderTopRightRadius: 40,
    borderBottomRightRadius: 40,
}

const detailsStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    padding: '40px',
    borderRadius: '35px',
    width: 660,

}

