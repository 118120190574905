import { Grid } from '@material-ui/core'
import { Typography } from '@mui/material'
import React from 'react'
import { InfoPageEmailTextArea, InfoPageInfoTextArea, InfoPageNameTextArea } from './Styles'

const CreatorInfoSide = () => {
  return (
    <Grid container alignItems='center' justifyContent='center' style={{ height: 'inherit' }}>
      <Grid item container direction="column" justifyContent='center' alignItems='center' style={{ height: 'inherit' }}>
            <Grid item style={{ width: 'min(50%, 200px)', height: '120px', marginBottom: '5px', border: '1px solid black'}}>
                Image
            </Grid>
            <InfoPageNameTextArea>
                <Typography variant='h5' style={{ fontWeight: '600', textTransform: 'capitalize' }}>Chef Name</Typography>
            </InfoPageNameTextArea>
            <InfoPageInfoTextArea>
                Find tested and perfected recipes that work for you the very first time with Step-by-step photos, ingredient recommendations, and detailed parameters for each step. Covers articles on meal planning, food & drink, kitchen tips & tools, entertaining and holiday recipes.
            </InfoPageInfoTextArea>
            <InfoPageEmailTextArea>
                Email
            </InfoPageEmailTextArea>
            <Grid item style={{ width: 'fit-content', padding: '5px 25px', borderRadius: '15px', background: '#1F2122' }}>
                <Typography variant='h6' style={{ color: '#DACEBE' }}>Sign Up</Typography>
            </Grid>
        </Grid>
    </Grid>
  )
}

export default CreatorInfoSide