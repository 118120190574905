import React from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import { Button } from '@mui/material';
import { ProfileStylesCustomGrid } from '../Styles';
import RandomImage from '../../../assets/CreatorScreen/random.svg';
import SimpleImage from '../../../assets/CreatorScreen/simple.svg';
import { styled } from '@mui/system';

const ColorTextField = styled((props)=> (
  <TextField {...props} />
))(({theme})=> ({
  '& .MuiInputBase-input': {
    width: "50px",
    height: '40px',
    borderRadius: 10,
  },
}));

const ProfileStep3 = (props) => {
  const { values, setFieldValue } = props;

  console.log(values);

  return (
    <Grid container style={{ width: '80%', textAlign: "center" }} marignLeft={2}>
      <Grid item container direction='column' style={{ textAlign: 'center' }}>
        <Grid item>
          <Typography>Pick a layout</Typography>
        </Grid>
        <Grid item>
        <Button onClick={() => setFieldValue('layout', 'Simple')} style={values.layout === 'Simple' ? {border: "1px solid red"} : {}}>
          <img src={SimpleImage} alt="simple" />
          <Typography>Simple</Typography>
        </Button>
        <Button onClick={() => setFieldValue('layout', 'Random')} style={values.layout === 'Random' ? {border: "1px solid red"} : {}}>
          <img src={RandomImage} alt="random" />
          <Typography>Random</Typography>
        </Button>
        </Grid>
      </Grid>
      <Grid item container direction='column'>
        Add brand Colors
        <ProfileStylesCustomGrid>
            <Typography style={{ textTransform: 'capitalize', width: 'fit-content' }}>Background</Typography>
            <ColorTextField
              type="color" 
              variant='standard' 
              value={values.backgroundColor} 
              onChange={e=> setFieldValue('backgroundColor', e.target.value)} 
              id='backgroundColor' 
              name='backgroundColor' 
              InputProps={{ disableUnderline: true }} 
            />
        </ProfileStylesCustomGrid>
        <ProfileStylesCustomGrid>
            <Typography style={{ textTransform: 'capitalize' }}>Accent</Typography>
            <ColorTextField
              variant='standard' 
              type="color" 
              value={values.accentColor} 
              onChange={e=> setFieldValue('accentColor', e.target.value)} 
              id="accentColor" 
              name='accentColor' 
              InputProps={{ disableUnderline: true }} 
            />
        </ProfileStylesCustomGrid>
      </Grid>
      <Grid item container>
        Finish with fonts
        <Grid item>
          <Button>Heading</Button>
          <TextField 
            id="headerFont"
            type='text' 
            value={values.headerFont} 
            onChange={(e) => setFieldValue('headerFont', e.target.value)}
            size='small' 
          />
        </Grid>
        <Grid item>
          <Button>Body</Button>
          <TextField 
            id="paraFont"
            type='text' 
            value={values.paraFont} 
            onChange={(e) => setFieldValue('paraFont', e.target.value)}
            size="small" 
          />
        </Grid>
      </Grid>
      <Grid item>
        <Button type="submit">Done</Button>
      </Grid>
    </Grid>
  )
}

export default ProfileStep3