import React from 'react'
import { Grid, Typography } from '@mui/material'
import { FeaturedRecipeCard, FeaturedRecipeHeader, FeaturedSectionArea } from './Styles'
import RecipeCard from './RecipeCard'

const FeaturedPosts = () => {
  const featuredRecipes = [1,2,3,4,5,6];

  return (
    <Grid container direction='column' style={{ background: '#1F2122', height: 'fit-content' }}>
      <FeaturedRecipeHeader>
        <Typography variant='h6' style={{ color: '#DACEBE', textTransform: 'uppercase' }}>Featured Posts</Typography>
      </FeaturedRecipeHeader>
      <FeaturedSectionArea>
        {featuredRecipes.map((item) => {
          return(
            <FeaturedRecipeCard>
              <RecipeCard />
            </FeaturedRecipeCard>
          )
        })}
      </FeaturedSectionArea>
    </Grid>
  )
}

export default FeaturedPosts