import React, { Component } from 'react';
import DataApi from '../../apis/firebaseApi';
import NewRow from './cupdate';
import { DataGrid } from '@material-ui/data-grid';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Backdrop, Button, CircularProgress, Divider, Grid, Paper, Snackbar, Typography, Table, TableHead, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import RefreshIcon from '@material-ui/icons/Refresh';

const header = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 150 },
    { field: "description", headerName: "Description", width: 250 },
    { field: "synonyms", headerName: "synonyms", width: 100 },
]

class IngredientCategories extends Component {
    state = {
        openDialog: false,
        dataOf: "spices",
        dataOfPlural: "Spices",
        rowData: [],
        selectedRows: [],
        snackbarVisibe: true,
        snackBarMessage: "Fetching data ...",
        actionInProgress: false,
        lastFetchedTimeStamp: null
    }

    toggleDialog = (e) => {
        e.preventDefault();
        this.setState({ openDialog: !this.state.openDialog })
    }

    updateData = (updatedData) => {
        this.setState({ rowData: updatedData, snackbarVisibe: false })
    }

    toggleSnackbar = (message = "", visible = false, actionInProgress = false) => {
        this.setState({ snackBarMessage: message, snackbarVisible: visible, actionInProgress: actionInProgress })
    }

    fetchData = () => {
        this.toggleSnackbar("Fetching data ...", true, false);
        DataApi[this.state.dataOf].get().then(function (snapshot) {
            var newData = [];
            snapshot.forEach(function (childSnapshot) {
                console.log(childSnapshot.data())
                var id = childSnapshot.id;
                var data = childSnapshot.data();
                //   newData.push({ id: id, name: data.name, description: data.description, synonyms: data.synonyms});
                newData.push({ ...data, id: id });
            });
            this.updateData(newData);
            this.setState({ lastFetchedTimeStamp: new Date().toGMTString() })
            this.toggleSnackbar("Updated data ... ", true, false)
        }.bind(this));
    }

    addRow = (rowData) => {
        this.toggleSnackbar("Saving ...", true, true)
        DataApi[this.state.dataOf].add({
            ...rowData
        })
            .then(function (docRef) {
                this.toggleSnackbar("Saved ...", true, false);
                console.log("Data row created with ID: ", docRef.id);
            }.bind(this))
            .catch(function (error) {
            })
        this.toggleSnackbar("", false, false)
    }

    componentDidMount() {
        this.fetchData()
    }

    handleDialogClose = () => {
        this.setState({ openDialog: !this.state.openDialog, snackbarVisibe: false })
        this.fetchData()
    }

    deleteRows = () => {
        console.log(this.state.selectedRows)
        var rowsToDelete = this.state.selectedRows
        rowsToDelete.map((row) => {
            DataApi[this.state.dataOf].doc(row.id).delete().then(() => {
                console.log("Deleted :" + row.name);
            }).catch((e) => {
                // console.log("Failed to delete :" + row.name )
            })
        })
        this.fetchData();
    }

    render() {
        console.log(this.state)
        return (
            <Grid>
                <Backdrop style={{ zIndex: 4, color: "white" }} open={this.state.snackbarVisibe} onClose={this.toggleSnackbar}>
                    <CircularProgress />
                </Backdrop>
                <Dialog open={this.state.openDialog} onClose={this.handleDialogClose} aria-labelledby="form-dialog-title" >
                    <Button onClick={this.handleDialogClose} color="primary" style={{ position: "absolute", top: 10, right: 10 }}>
                        x
                    </Button>
                    <DialogTitle id="form-dialog-title">Add New {this.state.dataOf}</DialogTitle>
                    <DialogContent>
                        <NewRow toggleSnackbar={this.toggleSnackbar} actionInProgress={this.state.actionInProgress} addRow={this.addRow} />
                    </DialogContent>
                </Dialog>
                <Grid container direction="column" style={{ height: 150 }} spacing={2}>
                    <Typography variant="h3">
                        {this.state.dataOfPlural}
                    </Typography>
                    <Typography variant="caption">Last updated at :{this.state.lastFetchedTimeStamp}</Typography>
                    <Grid container direction="row" alignItems="center" justify="flex-start" spacing={2} style={{ marginTop: 5, marginBottom: 5 }}>
                        <Button variant="contained" onClick={this.fetchData} disabled={this.state.actionInProgress} style={{ margin: 5 }}>
                            <RefreshIcon />
                        </Button>
                        <Button variant="contained" onClick={this.toggleDialog} style={{ margin: 5 }}>
                            <AddIcon />
                        </Button>
                        <Button variant="contained" onClick={this.editRow} style={{ margin: 5 }} disabled={this.state.selectedRows.length == 1 ? false : true}>
                            <EditIcon />
                        </Button>
                        <Button variant="contained" onClick={this.deleteRows} style={{ margin: 5 }}
                            disabled={this.state.selectedRows.length > 0 ? false : true}>
                            <DeleteIcon />
                        </Button>
                    </Grid>
                </Grid>
                <Divider />
                <Grid style={{ height: 550, width: '100%' }}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>

                                    <TableCell ><b>Spice Name</b></TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.rowData.map((row) => (
                                    <TableRow key={row.name}>
                                        <TableCell >
                                            {row.name}
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* <DataGrid sortModel={[
                        {
                            field: 'name',
                            sort: 'asc',
                        },
                    ]}
                        onRowSelected={(row) => this.setState({ selectedRows: [...this.state.selectedRows, row.data], })}
                        // onSelectionChange={(newSelection) => this.setState({ selectedRows: newSelection.rowIds, value: newSelection })}
                        rows={this.state.rowData}
                        columns={header}
                        disableExtendRowFullWidth={false}
                        pageSize={10}
                        checkboxSelection
                    /> */}
                </Grid>
            </Grid>
        );
    }
}

export default IngredientCategories;