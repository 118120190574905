import { FirebaseApi } from "../../../firebaseConfig";
import { CRUDoperations } from "../../CRUD/crud";


const creatorBillings = {
    create: async (userId, data) => {
        try {
            const newEarning = await CRUDoperations.create('creatorBillings', userId, data);
            return newEarning
        }
        catch (err) {
            return "No Billings found";
        }
    },

    createWithId: async (creatorId, payerId, value) => {
        console.log(creatorId, payerId, value)

        const result = await CRUDoperations.create("creatorBillings", {
            type: 'billings',
            creatorId,
            payerId,
            issueDate: value.issueDate,
            dueDate: value.dueDate,
            amount: value.amount,
            status: value.status


        })
        console.log("Added new Bill ");
        return result


    },
    fetch: async () => {
        try {
            const getBillings = await FirebaseApi['creatorBillings'].get();
            const bills = getBillings.docs.map(bills => ({
                ...bills.data(), id: bills.id
            }))




            return bills;

        } catch (err) {

        }
    },
    fetchByUserId: async (userId) => {
        try {
            const data = await FirebaseApi.creatorBillings.where('creatorId', '==', `${userId}`).get();
            const final = data.docs.map(Billings => ({
                ...Billings.data(), id: Billings.id
            }))

            return final;


        } catch (err) {

        }
    },
    delete: async (docId) => {
        try {
            const deleting = await CRUDoperations.delete("creatorBillings", docId)
            return 'done'

        }
        catch (err) {
            throw err

        }

    },
    update: async (docId, data) => {

        try {
            const updating = await CRUDoperations.update("creatorBillings", docId, data)
            return updating

        } catch (err) {
            return err;
        }
    },

}

export default creatorBillings;