import React, { useState, useEffect, useContext } from 'react'
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid'
import { FirebaseApi } from '../../../firebaseConfig'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles';
import AppContext from '../../../config/AppContext'
import EntityNavbar from '../EntityNavbar';
import IngredientModal from './IngredientModal';

// const DataGrids = styled(MuiDataGrid)({

// })

const IngredientTable = (props) => {
    const AppState = useContext(AppContext)
    const [rowData, setRowData] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentIngredient, setCurrentIngredient] = useState()
    const [selectedRows, setSelectedRows] = useState([]);
    const currentEntity = 'ingredient'
    const currentEntityCategory = 'dependent'

    const selectARow = (row) => {
        setCurrentIngredient(row?.row)
        AppState.handleCurrentEntityData(row?.row)
        AppState.handleCurrentEntityType(currentEntity)
    }

    const handleSelectedRows = (rows) => {
        console.log(rows)
        setSelectedRows(rows)
    }

    console.log("in ingredient table")

    const column = [
        {
            field: "imageUrl", headerName: 'Image', width: 110,
            renderCell: (props) => {
                console.log(props, props.row.image?.[0])
                return (
                    <div style={{ display: 'flex', flexDirection: 'column', width: 100, height: 100 }} >
                        {props.nameLink ? (

                            <img src={props.row.image?.[0]} style={{ width: '100%', height: '100%' }} alt="ingredient" />
                        ) : (

                            <img src={props.row.imageUrl} style={{ width: '100%', height: '100%' }} alt="ingredient" />
                        )}
                    </div>
                )
            }

        },
        // { field: "name", headerName: "Name", width: 150 },
        // { field: "Synonym", headerName: "Synonym", width: 150 },
        // { field: "Preparation Style", headerName: "Preparation Style", width: 150 },
        // { field: "Category", headerName: "Category", width: 50 },
        // { field: "Default type", headerName: "Default type", width: 150 },
        // { field: "Default Unit", headerName: "Default Unit", width: 150 },
        // { field: "Human base value", headerName: "Human base value", width: 150 },
        // // { field: "Expiration Date", headerName: "Expiration Date", width: 150 }?
    ]

    const getData = async () => {
        setLoading(true)
        try {
            const data = await FirebaseApi["ingredient"].get()
            const parsedData = data.docs.map((item) => {
                return {
                    id: item.id,
                    ...item.data()
                }
            })
            setRowData(parsedData);
            console.log(parsedData);
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)

        }
    }

    useEffect(() => {
        getData()
        return () => {
            setRowData([])
        }
    }, [props.entity])


    const addIngredient = () => {
        
    }
    const editIngredient = () => {}
    const deleteIngredient = () => {}
    const searchIngredients = () => {}

    return (
        <>
            <EntityNavbar entity="ingredients" currentEntityCategory={currentEntityCategory} rowsCount={selectedRows} addEntity={addIngredient} editEntity={editIngredient} deleteEntity={deleteIngredient} searchEntity={searchIngredients}></EntityNavbar>
            {loading ? <div>Loading..</div> :
                <Box style={{ height: '65vh', width: '100%' }}>
                    <MuiDataGrid
                        hideFooter
                        columns={column}
                        rows={rowData}
                        checkboxSelection
                        onRowClick={(details) => selectARow(details)}
                        onSelectionModelChange={(details) => handleSelectedRows(details)}
                    />
                </Box>
            }
            <IngredientModal />
        </>


    )

}

export default IngredientTable