import React from 'react'
import { Button, Grid, TextField, Typography } from '@material-ui/core'
import { Checkbox } from '@mui/material';
import { ProfileRightContainerButton } from '../Styles';

const ProfileStep2 = (props) => {
  const { values, setFieldValue, handleChange } = props;

  return (
    <Grid container direction='column'>
      <Grid item container style={{ marginLeft: 20 }} >
        <Grid item>
          <Typography>
            If You have an existing website, import URL
          </Typography>
        </Grid>
        <Grid item>
          <TextField 
            type='text' 
            size='small' 
            value={values.importRecipesURL} 
            onChange={(e) => setFieldValue('importRecipesURL', e.target.value)} 
            id="importRecipesURL"
            name="importRecipesURL"
            style={{ width: 300 }}
            placeholder='Enter URL to import'
          />
        </Grid>
        <Grid item>
          <small style={{ fontSize: 10 }}>It will parse your website and save your recipes in here automatically.</small>
        </Grid>
      </Grid>
      <Grid item container direction='row'>
        <Grid item>
          <Checkbox
            value={values.parseFromOutsideURL} 
            onChange={handleChange} 
            id="parseFromOutsideURL"
            name='parseFromOutsideURL'
          />
        </Grid>
        <Grid item style={{ marginTop: '7px'}}>
          <small style={{ fontSize: 10 }}>It will parse your website and save your recipes in here automatically.</small>
        </Grid>
      </Grid>
      <Grid item container direction='column'>
        <ProfileRightContainerButton>
          <Button type='submit'>
            <Typography style={{ color: 'white'}}>
              Import
            </Typography>
          </Button>
        </ProfileRightContainerButton>
        <Grid item>
          <Button type='submit'>
            <Typography style={{ marginLeft: 40, marginTop: 15, fontSize: 14 }}>
              Skip
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ProfileStep2