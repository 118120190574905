import React,{useState,useEffect} from 'react'
import {Button, Container,Grid, TextField, Typography,Box} from '@mui/material'
import {FirebaseApi} from '../../firebaseConfig'

export default function () {

  const [command,setCommand] = useState('')
  const [commands,setCommands] = useState([])
  useEffect(()=>{

    getCommands()
  },[])

  const getCommands = async()=>{
    try {
      FirebaseApi['semiTest'].onSnapshot(snapShot=>{
        const items = snapShot.docs.map(item=>{
          return({id:item.id,...item.data()})
        })

        setCommands(items)
      })
    } catch (error) {
      console.log(error)
    }
  }


  const saveCommand = async() =>{
    try {
        const result = await FirebaseApi['semiTest'].add({command})
   
        if(result){

          setCommand('')
        }
    } catch (error) {
        console.log(error)
    }

  }

  const deleteCommand = async (id) =>{
    try {
      await FirebaseApi['semiTest'].doc(id).delete()
    } catch (error) {
      console.log(error)
    }
  }
  return (
    
    <Container maxWidth="xl" style={{height:'100vh'}}>
      

     
      
      <Grid container sx={{flexDirection:{xs:'column',md:'row'},justifyContent:{xs:'space-evenly',md:'center'}}} alignItems='center'  lg={12} height={'100%'}  >
        <Grid item lg={6} >
          <Grid container direction={'row'} justifyContent='center' alignItems={'center'} >

         
          <Grid  container  direction={'row'} justifyContent="center" alignItems={'center'} item xs={12}  >

          <TextField placeholder='command' value={command} onChange={(e)=>setCommand(e.target.value)} />
          </Grid>
          <Grid container  direction={'row'} justifyContent="center" alignItems={'center'} item xs={12}  >

          <Button onClick={saveCommand} variant='contained' style={{margin:"10px 0"}}>Add</Button>
          </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} style={{border:'1px solid black'}} >
          {commands.length > 0 && commands.map((cmd,index)=>(
            <Box style={{
              padding:10,
              borderBottom:index!=commands.length-1 ? '1px solid black' : 'none',
              display:'flex',
              flexDirection:'row',
              alignItems:'center'
              }}>
              <Box style={{flex:1}}>

            <Typography>{cmd.command}</Typography>
              </Box>
            <Button onClick={()=>deleteCommand(cmd.id)} >Delete</Button>
            </Box>
          ))}
        </Grid>
      </Grid>
      
    </Container>
  )
}
