import { Button, Container, Grid, Typography,Box } from '@mui/material'
import React,{useContext} from 'react'
import AppContext from '../../config/AppContext'
import {auth} from '../../firebaseConfig'


export default function Profile(props) {
    const AppState = useContext(AppContext)
    console.log(AppState)

    const logOut = async()=>{
        try{
            await auth().signOut()
            AppState.logOut()
        }
        catch(err){
            console.log(err.message)

        }
    }


    return (
        <Container maxWidth="lg">
            <Box style={{padding:20}}>
            <Grid container alignItems="center">
                <Grid item md={6} xs={12} container direction="row" alignItems="center">
                    <Grid item xs={4}>
                    <img src={AppState?.user.photoURL} style={{width:100,height:100,borderRadius:'50%'}} alt="photoURL" />
                    </Grid>
                    <Grid item container direction="column" xs={8}>
                    <Typography>{AppState.user.displayName}</Typography>
                    <Typography>{AppState.user.email}</Typography>
                    </Grid>
                </Grid>
                <Grid item md={6} xs={12} container justifyContent="center" alignItems="center">
                    <Button variant="outlined" onClick={logOut}>Logout</Button>
                </Grid>
            </Grid>
            </Box>
        </Container>
    )
}
