import React, { useEffect, useContext, useState } from 'react';
import { Avatar, Grid, Typography, Button, Box } from '@mui/material';
import CreatorNavbar from '../Common/CreatorNavbar';
import BillingsTable from './BillingsTable';
import AppContext from '../../../config/AppContext';


const Billings = () => {
    const [user, setUser] = useState('creator');
    const AppState = useContext(AppContext);

    useEffect(() => {
        AppState.handleNavbar(false)
        return () => AppState.handleNavbar(true)
    }, [])

    return (
        <Grid container spacing={2} marginTop={3}>
            <Grid item xs={2}>
                <CreatorNavbar currentScreen="Billings" />
            </Grid>
            <Grid item container xs={10} spacing={2} >
                <Grid item container direction='row'>
                    <Grid item container style={{ width: 300, height: 200, border: '1px solid black' }}>
                        <Grid item>
                            <Typography>Pause upcoming billing cycle</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>If you pause a billing cycle. Riku will not charge your subscribers on May 1. Billing will resume on June 1, or you can pause again between May 1 and June 1.</Typography>
                            <Button>Pause May billing cycle</Button>
                        </Grid>
                    </Grid>
                    <Grid item container style={{ width: 300, height: 200, border: '1px solid black' }}>
                        <Grid item>
                            <Typography>Payout method</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>Change how you wish to recieve money.</Typography>
                            <Box>
                                <img src='' alt='' />
                                <Typography>Visa ending in 1234</Typography>
                                <Typography>Expiry 06/2023</Typography>
                                <Button>Edit</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item style={{ width: '100%' }}>
                    <BillingsTable />
                </Grid>
            </Grid>

        </Grid>
    )
}


export default Billings;