import React, { Component } from 'react';

import FirebaseApi from '../../apis/firebaseApi';
// import NewRow from './cupdate';

import { DataGrid } from '@material-ui/data-grid';
import { Avatar, Button, Card, CardActionArea, CardActions, CardContent,Modal, CardHeader, CardMedia, Divider, Grid, IconButton, Paper, Snackbar, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';
import { red } from '@material-ui/core/colors';



// Scaling Factor
// https://modernistcuisine.com/mc/how-to-scale-a-recipe/

class Filters extends Component {

    state = {
        openDialog: false,
        dataOf: "recipe",
        dataOfPlural: "Recipes",
        rowData: [],
        selectedRows: [],
        snackbarVisibe: true,
        snackBarMessage: "Fetching data ...",
        actionInProgress: false,
        lastFetchedTimeStamp: null,
        header : [
            { field: "name", headerName: "Name", width: 250 },
            {field:"recipeCuisine",headerName:"Cuisine",width:200},
            {field:"ingredients",headerName:"ingredients",width:500}
        ],
        selectedRowData:{},
        selectedData:{}
    }

    toggleDialog = (e) => {
        e.preventDefault();
        this.setState({ openDialog: !this.state.openDialog })
    }

    updateData = (updatedData) => {
        this.setState({ rowData: updatedData, snackbarVisibe: false })
    }

    toggleSnackbar = (message = "", visible = false, actionInProgress = false) => {
        this.setState({ snackBarMessage: message, snackbarVisible: visible, actionInProgress: actionInProgress })
    }
    

    fetchData = () => {
        this.toggleSnackbar("Fetching data ...", true, false);
        FirebaseApi[this.state.dataOf].get().then(function (snapshot) {
            var newData = [];
            snapshot.forEach(function (childSnapshot) {
                var id = childSnapshot.id;
                var data = childSnapshot.data();
                //   newData.push({ id: id, name: data.name, description: data.description, synonyms: data.synonyms});
                newData.push({ ...data, id: id });
            });
            
            var highestSteps = 0
            const filteringData = newData.map(data=>{
                // if(data.template === 0){
                    if(data.recipeInstructionsParsed?.length > 0){
                        var steps={}
                        data.recipeInstructionsParsed.map((instruction,instructionIndex)=>{
                            instruction.itemListElement.map((element,index)=>{
                                var details = []
                                element.machineCode.map((code,codeIndex)=>{
                                    details.push({category: code.category, ingredient :`${code.cookingAction} ${code.ingredientDetails? code.ingredientDetails.quantity : ''} ${code.ingredientDetails? code.ingredientDetails.unit : ''} ${code.ingredientDetails? code.ingredientDetails.ingredient : ''}`})
                                })
                                steps[`step ${instructionIndex} ${index+1}`] = details
                            })
                        })
                        if([...Object.keys(steps)].length > highestSteps ){
                            highestSteps = [...Object.keys(steps)].length
                        }
                    
                        // for (const key of Object.keys(steps)) {
                        //     console.log(key)
                        //     // header.push({field:key,headerName:key,width:200})
                            
                        // }
                        var ingredients = []
                        if(data.recipeIngredientParsed){
                            data.recipeIngredientParsed.map(ingredient=>{
                                ingredients.push(ingredient.ingredient)
                            })
                        }
                        return {...data,...steps,ingredients,totalSteps:[...Object.keys(steps)].length,instructionSet:data.recipeInstructionsParsed?.length}
                    }
                // }
                
    
            })
           
            // for(let i=1;i<=highestSteps;i++){
            //     this.setState({header:[...this.state.header,{field:`step ${i}`,headerName:`step ${i}`,width:200}]})
            // }

            const filteredData = filteringData.filter(data=>{
                if(data){
                    return data
                }
            } )
            
            console.log({filteredData})
            this.updateData(filteredData);
            this.setState({ lastFetchedTimeStamp: new Date().toGMTString() })
            this.toggleSnackbar("Updated data ... ", true, false)
        }.bind(this));
    }
    
    addRow = (rowData) => {
        this.toggleSnackbar("Saving ...", true, true)
        FirebaseApi[this.state.dataOf].add({
            ...rowData
        })
            .then(function (docRef) {
                this.toggleSnackbar("Saved ...", true, false);
                // console.log( "Data row created with ID: ", docRef.id);
            }.bind(this))
            .catch(function (error) {
            })
        this.toggleSnackbar("", false, false)
    }
    // Store in hash here and keep updating the variable
    // addMetaData
    // addIngredient(s)
    // addInstruction(s)
    // Final save

    componentDidMount() {
        this.fetchData()
    }

    handleDialogClose = () => {
        this.setState({ openDialog: !this.state.openDialog, snackbarVisibe: false })
        // this.fetchData()
    }

    deleteRows = () => {
        console.log(this.state.selectedRows)
        var rowsToDelete = this.state.selectedRows
        rowsToDelete.map((row) => {
            FirebaseApi[this.state.dataOf].doc(row.id).delete().then(() => {
                // console.log("Deleted :" + row.name);
            }).catch((e) => {
                // console.log("Failed to delete :" + row.name )
            })
        })
        this.fetchData();
    }

    editRow = () => {
        this.setState({openDialog:true})

    }

    editRecipe = (recipe) => {

    }

    deleteRecipe = (recipe) => {
        FirebaseApi[this.state.dataOf].doc(recipe.id).delete().then(() => {
            console.log("Deleted :" + recipe.name);
        }).catch((e) => {
            // console.log("Failed to delete :" + row.name )
        })
        this.fetchData();
    }

    fetchRow = (ids)=>{
        console.log(ids)
        this.setState({selectedRows:ids})
        if(ids.length === 1){
            const selectedData = this.state.rowData.filter(data=> data.id === ids[0])
            this.setState({selectedData:selectedData[0]})
            // console.log(selectedData[0])
        }

    }

    colorIngredients = ({category,ingredient})=>{
        switch(category){
            case 'Oil':
                return <Typography style={{color:'#0033cc'}}>{ingredient}</Typography>
            case 'Spice/Herb':
                return <Typography style={{color:'#ff3333'}}>{ingredient}</Typography>
            case 'Sauce Ingredient':
                return <Typography style={{color:'#666666'}}>{ingredient}</Typography>
            case 'Main Ingredient':
                return <Typography style={{color:'#6600cc'}}>{ingredient}</Typography>
            case 'Garnish':
                return <Typography style={{color:'#006600'}}>{ingredient}</Typography>
            default:
                return <Typography style={{color:'black'}}>{ingredient}</Typography>
        }
    }


    render() {
        console.log(this.state.rowData)
        console.log(this.state.header)
        return (
            <Grid>
                <Snackbar key={this.state.dataOf} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={this.state.snackbarVisibe} autoHideDuration={6000} onClose={this.toggleSnackbar} message={this.state.snackBarMessage} />
                <Modal
                    open={this.state.openDialog}
                    onClose={this.handleDialogClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    >
                    <div style={{width:'100%',height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        <div style={{width:'70%',height:'80%',backgroundColor:'white',overflowY:'scroll',}}>
                            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',padding:10}}>
                                <Typography variant="h4">Recipe details</Typography>
                                <Button onClick={this.handleDialogClose}>x</Button>
                            </div>
                            <div style={{padding:25}}>
                                <Typography variant="h5">{this.state.selectedData?.name}</Typography>
                                <Typography variant="subtitle1">All Ingredients in this recipe</Typography>
                                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <div style={{flex:1,display:'flex',flexDirection:'column'}}>
                                        {this.state.selectedData?.ingredients?.map((ingredient,index)=>(
                                            <Typography key={index}>{ingredient}</Typography>
                                            
                                            ))}


                                    </div>
                                    <div style={{flex:1,display:'flex',flexDirection:'column',width:'100%'}}>
                                        {[...Array(this.state.selectedData?.instructionSet)].map((instruction,instructionIndex)=>(
                                              [...Array(this.state.selectedData?.totalSteps)].map((step,index)=>(
                                                <div key={index} style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-start'}}>
                                                    {console.log(this.state.selectedData[`step ${instructionIndex} ${index+1}`])}
                                                    <Typography>{`step ${instructionIndex} ${index + 1}`}</Typography>
                                                    {this.colorIngredients(this.state.selectedData[`step ${instructionIndex} ${index+1}`] ? this.state.selectedData[`step ${instructionIndex} ${index+1}`][0] : {category:'',ingredient:''})}
                                                    {/* <Typography style={{marginLeft:20}}>{this.state.selectedData[`step ${instructionIndex} ${index+1}`] && this.state.selectedData[`step ${instructionIndex} ${index+1}`][0]}</Typography> */}
                                                
                                                </div>
                                            ))
                                        ))
                                      }

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </Modal>
                
                {/* <Dialog open={this.state.openDialog} onClose={this.handleDialogClose} aria-labelledby="form-dialog-title" >
                    <Button onClick={this.handleDialogClose} color="primary" style={{ position: "absolute", top: 10, right: 10 }}>
                        x
                        </Button>
                    <DialogTitle id="form-dialog-title">Recipe Detail</DialogTitle>
                    <DialogContent>
                        <Typography>{this.state.selectedData.name}</Typography>
                        <Typography>Ingredients</Typography>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <div style={{display:'flex',flexDirection:'column'}}>
                                {this.state.selectedData?.ingredients?.map((ingredient,index)=>(
                                    <Typography key={index}>{ingredient}</Typography>
                                    
                                    ))}


                            </div>
                            <div style={{display:'flex',flexDirection:'column'}}>
                                {[...Array(this.state.selectedData?.totalSteps)].map((step,index)=>(
                                    <div key={index} style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                                        <Typography>{`step ${index + 1}`}</Typography>
                                        <Typography>{this.state.selectedData[`step ${index+1}`] && this.state.selectedData[`step ${index+1}`][0]}</Typography>
                                       
                                    </div>
                                ))}

                            </div>
                        </div>
                    </DialogContent>
                </Dialog> */}
                <Grid container direction="column" style={{ height: 150 }} spacing={2}>
                    <Typography variant="h3">
                        {this.state.dataOfPlural}
                    </Typography>
                    <Typography variant="caption">Last updated at :{this.state.lastFetchedTimeStamp}</Typography>
                    <Grid container direction="row" alignItems="center" justify="flex-start" spacing={2} style={{ marginTop: 5, marginBottom: 5 }}>
                        <Button variant="contained" onClick={this.fetchData} disabled={this.state.actionInProgress} style={{ margin: 5 }}>
                            <RefreshIcon />
                        </Button>
                        <Link to='/recipes/new/0'>
                            <Button variant="contained" style={{ margin: 5 }}>
                                <AddIcon />
                            </Button>
                        </Link>
                        <Button variant="contained" onClick={this.editRow} style={{ margin: 5}} disabled={this.state.selectedRows.length ==1 ? false : true}>
                            <EditIcon/>
                        </Button>
                        {/* <Button variant="contained" onClick={this.deleteRows}style={{ margin: 5}} 
                        disabled={this.state.selectedRows.length > 0 ? false : true}>
                            <DeleteIcon/>
                        </Button>  */}
                    </Grid>
                </Grid>
                <Divider />
                <Grid container style={{ height: 700, width: '100%' }} spacing={2}>
                <DataGrid onSelectionChange={(number)=>this.fetchRow(number.rowIds)} onRowSelected={(newSelection) => console.log(newSelection)} rows={this.state.rowData} columns={this.state.header} pageSize={50} checkboxSelection />
                </Grid>
            </Grid>
        );
    }
}

export default Filters;

{/* <DataGrid onSelectionChange={(newSelection) => this.setState({selectedRows: newSelection.rows})} rows={this.state.rowData} columns={header} pageSize={5} checkboxSelection /> */ }
