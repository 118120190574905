import styled from "@emotion/styled";
import { Button, Grid, Typography } from "@mui/material";

export const UpdateMembershipLevelTypograpghy = styled(Typography)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    width: '100%',
    marginLeft: 20,
    marginTop: '-10px',
    wordBreak: 'break-all'
});

export const MemberShipTypograghy = styled(Typography)({
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: '12px'
});

export const MemberShipButton = styled(Button)({
    padding: '5px 10px',
    width: 'fit-content',
    marginTop: '30px',
    background: '#292929',
    color: '#FFFFFF',
    borderRadius: 5,
    '&:hover': {
        opacity: 1,
        background: '#292929',
    }
});

export const MemberShipGoBack = styled(Grid)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '10px',
    marginTop: '30px',
    width: 'fit-content',
    cursor: 'pointer',
});

export const MemberShipLevels = styled(Grid)({
    width: 421,
    height: 452,
    // border: '1px solid black',
    marginLeft: '30px',
    background: '#FFFFFF',
    borderRadius: 10
});

export const UnderLine = styled('div')({
    height: '2px',
    width: '85px',
    background: '#F94C0C',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
});