import { Grid } from '@mui/material'
import React, { useEffect, useContext, useState } from 'react'
import HeaderNavbar from './Common/HeaderNavbar'
import SubscriptionCard from './Common/SubscriptionCard'
import CreatorNavbar from './Common/CreatorNavbar'
import AppContext from '../../config/AppContext'
import CloudServices from '../../cloud-services-core'

import { Typography, TextField, Button } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import UpdateMembership from './Membership/UpdateMembershipLevel'



const MySubscriptions = () => {
  const AppState = useContext(AppContext);
  const [changePlan, setChangePlan] = useState(false);

  const [finalUsers, setFinalUsers] = useState([]);
  const [loadingSubscription, setLoadingSubcriptions] = useState(true);

  let newUsers = [];
  const getUsers = async () => {
    let vals = await CloudServices.user.getAllUsers();
    console.log(vals);
    newUsers = vals.slice(0, 4);
    setFinalUsers(newUsers);
    setLoadingSubcriptions(false);

  }
  console.log(newUsers, finalUsers);




  useEffect(() => {
    AppState.handleNavbar(false)
    getUsers()
    return () => AppState.handleNavbar(true)
  }, [])
  return (
    <Grid style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>

      <CreatorNavbar currentScreen="Manage subscription" />

      <Grid style={{ width: '100%' }}>

        {loadingSubscription ? <h2> Loading Data... </h2> :
          <Grid style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {
              finalUsers?.map((user) => (
                <Grid container style={{ width: 300, height: 600, border: '1px solid #A5A5A5', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', margin: 10 }}>
                  <Grid item style={{ backgroundColor: '#64D0F3', height: 150, width: '100%' }}>

                  </Grid>
                  <Avatar alt="profile Image" style={{ width: 126, height: 126, marginTop: -80 }} />
                  <Grid item style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography> {user.username} </Typography>
                    <Typography style={{ color: '#8D8D8D' }}>Subscription ID: {user.id} </Typography>
                  </Grid>
                  <Grid item style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 16, paddingRight: 16 }}>
                    <Typography style={{ color: '#8D8D8D' }}>Started On</Typography>
                    <Typography>12/10/2017</Typography>
                  </Grid>
                  <Grid item style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 16, paddingRight: 16 }}>
                    <Typography style={{ color: '#8D8D8D' }}>Recurring</Typography>
                    <Typography>Yes</Typography>
                  </Grid>
                  <Grid item style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 16, paddingRight: 16 }}>
                    <Typography style={{ color: '#8D8D8D' }}>Price</Typography>
                    <Typography>Rs.149</Typography>
                  </Grid>
                  <Button style={{ backgroundColor: '#EDEDED', width: 204, color: 'black' }} onClick={() => setChangePlan(true)} outlined>Change Plan</Button>
                  <Grid item style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography style={{ color: '#8D8D8D' }}> Next billing </Typography>
                    <Typography>11 Oct, 2020</Typography>
                  </Grid>

                  {changePlan && <UpdateMembership />}
                </Grid>
              ))
            }
          </Grid>
        }
      </Grid>
    </Grid>
  )
}

export default MySubscriptions