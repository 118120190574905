//react
import React, { useState,useContext, useEffect } from 'react'

//mui
import { Grid, MobileStepper } from '@material-ui/core';
import { Form, Formik } from 'formik';

//internal components
import ProfileStep1 from './Profile1/ProfileStep1';
import ProfileStep2 from './Profile2/ProfileStep2';
import ProfileStep3 from './Profile3/ProfileStep3';

//styles
import { ProfileHeader, ProfileHeaderTypography, ProfileRightContainerFormElements } from './Styles';

//App Context
import AppContext from '../../config/AppContext';
import { useProfileSteps } from './Values';
import { useHistory } from 'react-router-dom';
import ProfileImage1 from './Profile1/ProfileImage1';
import ProfileImage2 from './Profile2/ProfileImage2';
import ProfileImage3 from './Profile3/ProfileImage3';
import { styled } from '@mui/styles';

import storage from '../../utils/storage';

// profile components
const profileSteppers = (step, values, handleChange, setFieldValue) => {
  switch(step) {
    case 0 : return <ProfileStep1 values={values} handleChange={handleChange} setFieldValue={setFieldValue} /> ;
    case 1 : return <ProfileStep2 values={values} handleChange={handleChange} setFieldValue={setFieldValue} />;
    case 2 : return <ProfileStep3 values={values} handleChange={handleChange} setFieldValue={setFieldValue} />;
    default: return <p> Not Found </p>
  };
};

const MobileSteppers = styled((props)=> (
  <MobileStepper {...props} />
))(({theme})=> ({
  '& .MuiMobileStepper-progress': {
    width: "40vw",
  },
}));

const ProfileCreationStepper = () => {
  const { initialValues, steps } = useProfileSteps();
  const AppState = useContext(AppContext);
  const [ activeStep, setActiveStep ] = useState(0);
  const stepsLength = steps.length-1;
  const history = useHistory();
  const user = 'user';

  const next = () => {
    setActiveStep((prevState) => prevState + 1);
  }

  useEffect(() => {
    AppState.handleNavbar(false);
    return () => AppState.handleNavbar(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <Grid container style={{ height: '100vh' }}>
      <Grid item xs={6} style={{ height: 'inherit' }}>
          {activeStep === 0 && <ProfileImage1 />}
          {activeStep === 1 && <ProfileImage2 />}
          {activeStep === 2 && <ProfileImage3 />}
      </Grid>
      <Grid item container xs={5} direction='column'>
        <ProfileHeader>
          <ProfileHeaderTypography>{steps[activeStep].label}</ProfileHeaderTypography>  
          <MobileSteppers
            variant='progress'
            steps={3}
            position = 'static'
            activeStep={activeStep}
         />
      </ProfileHeader>
      <ProfileRightContainerFormElements>
      <Formik
        initialValues={initialValues}
        onSubmit = {(values) => {
          if(activeStep === stepsLength){
            alert(JSON.stringify(values, null, 2));
            storage.setItem(user, JSON.stringify({ ...values }));
            history.push('/creator')
          } else {
            next(values);
          }
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            {profileSteppers(activeStep, values, handleChange, setFieldValue)}
            {/* <Button onClick={handleReset}>Reset</Button> */}
          </Form>
        )}
        
      </Formik>
      </ProfileRightContainerFormElements>
      </Grid>
    </Grid>
  )
}

export default ProfileCreationStepper