import React from 'react'
import { Grid, Typography } from '@mui/material'
import Back from '../../../assets/CreatorScreen/BackButton.svg'
import { InfoPageEmailTextArea, InfoPageGoBackButton, InfoPageInfoTextArea, InfoPageNameTextArea } from './Styles'
import { useHistory } from 'react-router-dom'

const CreatorInfoTopContainer = () => {
    const history = useHistory();
    
  return (
    <Grid container direction='column' style={{ width: '100%' }}>
        <InfoPageGoBackButton onClick={() => history.goBack()}>
            <img src={Back} alt='' />
            <Typography style={{ marginLeft: '10px' }}>Go Back</Typography>
        </InfoPageGoBackButton>
        <Grid item container direction="column" justifyContent='center' alignItems='center' style={{ height: '85vh' }}>
            <Grid item style={{ width: 'max(300px, 200px)', height: '200px', marginBottom: '15px', border: '1px solid black'}}>
                Image
            </Grid>
            <InfoPageNameTextArea>
                <Typography variant='h5' style={{ fontWeight: '600', textTransform: 'capitalize' }}>Chef Name</Typography>
            </InfoPageNameTextArea>
            <InfoPageInfoTextArea>
                Find tested and perfected recipes that work for you the very first time with Step-by-step photos, ingredient recommendations, and detailed parameters for each step. Covers articles on meal planning, food & drink, kitchen tips & tools, entertaining and holiday recipes.
            </InfoPageInfoTextArea>
            <InfoPageEmailTextArea>
                Email
            </InfoPageEmailTextArea>
            <Grid item style={{ width: 'fit-content', padding: '5px 25px', borderRadius: '15px', background: '#1F2122' }}>
                <Typography variant='h6' style={{ color: '#DACEBE' }}>Sign Up</Typography>
            </Grid>
        </Grid>
    </Grid>
  )
}

export default CreatorInfoTopContainer