import React,{useState,useEffect} from 'react'
import { ErrorMessage, Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';



export default function CupUpdate(props) {    
    return (
        <Formik
                initialValues={props.data ?? props.initialValues}
                onSubmit={(values, actions) => {
                    props.triggerAction(props.data ? {data:values,id:props.data?.id,edited:props.data ? true : false} : values);
                }}
            >
                {formik => (
                    <form onSubmit={formik.handleSubmit} style={{
                        display: "flex", flexDirection: "column", justifyContent: "space-between",
                        alignItems: "flex-start", minHeight: 300, height: "auto"
                    }}>
                        <div>
                        
                        {props.dataStructure.map(key=>(
                            <TextField id={key} key={key} label={key}
                            onChange={formik.handleChange}
                            fullWidth
                            value={formik.values[key]}
                            />
                        ))}
                           
                        </div>
                        <Button variant="contained" color="primary" type="submit" >
                            Save
                        </Button>
                    </form>
                )
                }
            </Formik>
    )
}
