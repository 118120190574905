import React, { useState, useEffect, useContext } from 'react'
import AppContext from '../../../config/AppContext'
import { Grid, Typography } from '@mui/material'
import CreatorNavbar from '../Common/CreatorNavbar'
import EmptyImage from '../../../assets/CreatorScreen/emptyImage.png'
import { useHistory } from 'react-router-dom'
import Back from '../../../assets/CreatorScreen/BackButton.svg'
import UpdateMembershipLevel from './UpdateMembershipLevel'
import EditMemberLevelScreen from './EditMemberLevels'
import { MemberShipButton, MemberShipGoBack, MemberShipTypograghy } from './Styles'

const MembershipLevel = () => {
    const AppState = useContext(AppContext);
    const history = useHistory();
    const [currentTab, setCurrentTab] = useState('Add');
    const { handleNavbar, user } = AppState;

    useEffect(() => {
        handleNavbar(false);
        return () => handleNavbar(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

  return (
    <Grid container>
        <Grid item xs={2} marginTop={3}>
            <CreatorNavbar />
        </Grid>
        <Grid item container xs={10} direction="column" style={{ textAlign: 'center', background: '#F4F5F7', height: '100vh' }}>
            <MemberShipGoBack item onClick={() => history.goBack()}>
                <img src={Back} alt='' />
                <small style={{ fontWeight: 600, marginLeft: '10px' }}>Go Back</small>
            </MemberShipGoBack>
            <Grid item container direction='column' style={{ marginTop: '20px' }}>
                <Grid item>
                    <Typography variant='h5'>Manage membership levels</Typography>
                </Grid>
                <Grid item style={{ width: '100%' }}>
                    <MemberShipTypograghy style={{ width: '800px' }}>A membership plan is a subscription to your customers that entitles them to certain benefits. Each member"’"s plan makes them an active member for a specific period of time, with a start date and end date, and entitles them to specific benefits that you offer them.</MemberShipTypograghy>
                </Grid>
                <Grid item>
                    {user.memberShipLevels.length === 0 && currentTab === 'Add' ? <MemberShipButton onClick={() => setCurrentTab('Edit')}>Add plans</MemberShipButton> : <MemberShipButton onClick={() => history.push('/managemember')}>Edit Levels</MemberShipButton>}
                    {/* {user.memberShipLevels.length === 2 && currentTab === 'Edit' ? <MemberShipButton onClick={() => setCurrentTab('Save')}>Edit Levels</MemberShipButton> : <MemberShipButton onClick={() => setCurrentTab('Edit')}> Save Levels</MemberShipButton>} */}
                </Grid>
            </Grid>
            <Grid item container direction='column' marginTop={8}>
                {currentTab === 'Add' && 
                <>
                    <Grid item>
                        <img src={EmptyImage} alt='' style={{ width: 200, height: 200, marginBottom: 30 }} />
                    </Grid>
                    <Grid item>
                        <Typography>You have not added any plans yet.</Typography>
                    </Grid>
                    <Grid item>
                        <MemberShipTypograghy style={{ width: '400px' }}>Go to the “Add plans” button, to create detailed plans for your potential subscribers.</MemberShipTypograghy>
                    </Grid>
                </>}
                {currentTab === 'Edit' && <UpdateMembershipLevel />}
                {currentTab === 'Save' && <EditMemberLevelScreen />}
            </Grid>
        </Grid>
    </Grid>
  )
}

export default MembershipLevel