import React, { useState, useEffect, useContext } from 'react'
import Grid from '@mui/material/Grid';
import { Formik, useFormik } from 'formik';
import { Button, TextField, Typography, Select, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


import { FirebaseApi } from '../../../firebaseConfig';
import Modal from '@mui/material/Modal';

import * as Yup from 'yup';
//import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useHistory, useLocation, useParams } from 'react-router-dom';


const PrepStyleModal = (props) => {


    const [modal, setModal] = useState(true);
    const [dataOf, setDataOf] = useState('prepStyle')
    const [imageLink, setImageLink] = useState('');
    const [selectedRows, setSelectedRowData] = useState([])

    const location = useLocation()

    const [currentEntityData, setCurrentEntityData] = useState(location.state)

    const history = useHistory();


    // For Images
    const isImageEdited = () => {
        if (currentEntityData) {
            console.log("setting image to current Data")
            setImageLink(currentEntityData.imageLink)
            formik.setFieldValue('imageLink', currentEntityData.imageLink);
            //setInputValue(currentEntityData.imageLink)

        } else {
            console.log("setting image to zero")
            setImageLink(imageLink);

        }
    }


    const changeImageLink = (value) => {
        formik.setFieldValue('imageLink', value);
        setImageLink(value);
    }

    useEffect(() => {
        if (location.state) {
            setCurrentEntityData(location.state)
        } else {
            // Get the data from the db using the params
        }
        isImageEdited();
    }, [])




    // CRUD
    const addStyle = (rowData) => {
        FirebaseApi[dataOf].add({
            ...rowData
        }).then(function (docRef) {
        })
            .catch(function (error) {
            })
    }

    const updateStyle = (data, id) => {
        FirebaseApi[dataOf].doc(id).update(data)
            .then((result) => {
                setSelectedRowData(null)
            })
            .catch((error) => {
                console.log({ error })
            })
    }



    const actionTriggered = (edited, data) => {
        if (data.id) {
            const { name, description } = data
            updateStyle({ name, description }, data.id);
            history.goBack();
        }
        else {
            const { name, description } = data
            addStyle({ name, description });
            history.goBack();
        }

    }



    const formik = useFormik({
        initialValues: {
            'id': currentEntityData ? currentEntityData.id : '',
            'name': currentEntityData ? currentEntityData.name : '',
            // 'imageLink': imageLink,
            'description': currentEntityData ? currentEntityData.description : '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required("required"),
            description: Yup.string().required("required"),
        }),
        onSubmit: values => {
            console.log(values)
            if (currentEntityData) {
                actionTriggered(true, values)
            } else {
                actionTriggered(false, values)
            }

        },
    });

    return (
        <Grid >
            <Modal
                open={modal}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <form onSubmit={formik.handleSubmit} style={{ outline: "none" }}>
                    <Grid sx={style} container spacing={2} flexDirection='column' justifyContent="space-between">
                        <Grid item style={{ display: "flex", flexDirection: "row" }} justifyContent='space-between' marginBottom='10'>
                            <Typography fontWeight='700' variant='h6'> {currentEntityData ? "Edit Prep Style": "Add Prep Style"} </Typography>
                            <CloseIcon style={{ marginLeft: '100px', cursor: 'pointer' }} onClick={() => history.goBack()} />
                        </Grid>
                        {/* <Grid item style={{ display: "flex", flexDirection: "row" }}>
                            {
                                imageLink &&
                                <img src={imageLink} alt="prepStyle" id="imageLink" style={{ paddingLeft: "px", width: 100, height: 100 }} />
                            }
                            <ImageUploader id="imageLink" setImageLink={changeImageLink} />
                        </Grid> */}
                        <Grid item>
                        {
                            formik.errors.name && formik.errors.description ? 
                            <Typography style={{ color: 'red', fontSize: 10 }}>Please fill in the required fields</Typography>: null
                        }
                        </Grid>
                        <Grid item>
                            <Typography>Name*</Typography>
                            <TextField
                                id="name"
                                defaultValue={formik.values.name}
                                variant="outlined"
                                onChange={formik.handleChange}
                                placeholder="Name"
                            />
                        </Grid>
                        <Grid item>
                            <Typography>Description*</Typography>
                            <TextField
                                id="name"
                                defaultValue={formik.values.description}
                                variant="outlined"
                                onChange={formik.handleChange}
                                placeholder="Name"
                            />
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={() => { formik.handleSubmit(); }}>{currentEntityData ? 'Save' : 'Add'}</Button>
                        </Grid>
                    </Grid>
                </form>
            </Modal>
        </Grid>

    )
}


export default PrepStyleModal

const style = {
    marginTop: "5%",
    marginLeft: 'auto',
    marginRight: "auto",
    width: '50%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};