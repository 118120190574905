import React, { useState, useEffect, useContext } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Typography, Box } from '@mui/material'
import Grid from '@mui/material/Grid'
import { styled, useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom'
import Entities from '..'

import { FirebaseApi } from '../../../firebaseConfig'
import AppContext from '../../../config/AppContext'
import EntityNavbar from '../EntityNavbar'



export default function AllergiesEntityTable(props) {
    const AppState = useContext(AppContext);
    const { addedEntity, setAddedEntity, close } = props;
    const [currentIngredient, setCurrentIngredient] = useState('');
    const [rowData, setRowData] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentEntityData, setCurrentEntityData] = useState();
    const [rowsLength, setRowsLength] = useState([]);
    const currentEntity = 'allergies'
    const currentEntityCategory = 'dependent'
    console.log(rowData);





    return (
        <>
            <EntityNavbar entity="allergies" currentEntityCategory={currentEntityCategory} > </EntityNavbar>

        </>


    )

}