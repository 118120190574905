import React, { Component } from 'react';
import {FirebaseApi} from '../../firebaseConfig';
import NewRow from './cupdate';

import { DataGrid } from '@material-ui/data-grid';
import { Button, Divider, Grid, Paper, Snackbar, Typography, Avatar } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import CupUpdate from '../../components/CupUpdate';


function RenderAvatar(params) {
    return (
        <Avatar variant="rounded" style={{ backgroundColor: "#FEFEFE" }}>
            <img src={params.value} style={{ objectFit: "contain", height: 35 }} />
        </Avatar>
    );
}

const header = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "imageUrl", headerName: "Thumbnail", renderCell: RenderAvatar, width: 150 },
    { field: "name", headerName: "Name", width: 250 },
    { field: "description", headerName: "Description", width: 500 },
]

class Ingredients extends Component {
    state = {
        openDialog: false,
        dataOf: "ingredient",
        dataOfPlural: "Ingredients",
        rowData: [],
        selectedRows: [],
        snackbarVisibe: true,
        snackBarMessage: "Fetching data ...",
        actionInProgress: false,
        lastFetchedTimeStamp: null,
        selectedData: {},
        dataStructure:["imageUrl",'name',"description"]
    }

    toggleDialog = (e) => {
        e.preventDefault();
        this.setState({ openDialog: !this.state.openDialog })
    }

    updateData = (updatedData) => {
        this.setState({ rowData: updatedData, snackbarVisibe: false })
    }

    toggleSnackbar = (message = "", visible = false, actionInProgress = false) => {
        this.setState({ snackBarMessage: message, snackbarVisible: visible, actionInProgress: actionInProgress })
    }

    fetchData = () => {
        this.toggleSnackbar("Fetching data ...", true, false);
        FirebaseApi[this.state.dataOf].get().then(function (snapshot) {
            var newData = [];
            var names=[]
            snapshot.forEach(function (childSnapshot) {
                var id = childSnapshot.id;
                var data = childSnapshot.data();
                //   newData.push({ id: id, name: data.name, description: data.description, synonyms: data.synonyms});
                names.push({id:id,name:data.name})
                newData.push({ ...data, id: id });
            });
            console.log(JSON.stringify(names))
            this.updateData(newData);
            this.setState({ lastFetchedTimeStamp: new Date().toGMTString() })
            this.toggleSnackbar("Updated data ... ", true, false)
        }.bind(this));
    }

    addRow = (rowData) => {
        console.log(rowData)
        this.toggleSnackbar("Saving ...", true, true)
        FirebaseApi[this.state.dataOf].add({
            ...rowData
        })
            .then(function (docRef) {
                this.toggleSnackbar("Saved ...", true, false);
                // console.log( "Data row created with ID: ", docRef.id);
            }.bind(this))
            .catch(function (error) {
            })
        this.toggleSnackbar("", false, false)
    }

    componentDidMount() {
        this.fetchData()
    }

    handleDialogClose = () => {
        this.setState({ openDialog: !this.state.openDialog, snackbarVisibe: false })
        this.fetchData()
    }

    deleteRows = () => {
        console.log(this.state.selectedRows)
        var rowsToDelete = this.state.selectedRows
        rowsToDelete.map((row) => {
            FirebaseApi[this.state.dataOf].doc(row.id).delete().then(() => {
                // console.log("Deleted :" + row.name);
            }).catch((e) => {
                // console.log("Failed to delete :" + row.name )
            })
        })
        this.fetchData();
    }

    editRow = () => {
        this.setState({openDialog:true})

        // if (this.state.selectedRows.length === 1) {
        //     const selectedData = this.state.rowData.filter(row => row.id === this.state.selectedRows[0])[0]
        //     this.setState({ selectedData, editDialog: true })
        // }

    }

    handleEditDialogClose = () => {
        this.setState({ editDialog: false })
    }

    selectARow = (newSelection)=>{
        const getSelectedData = this.state.rowData.filter((data)=>data.id===newSelection.rowIds[0])
        console.log({getSelectedData})
        this.setState({selectedRows: newSelection.rowIds ,selectedRowData:getSelectedData[0]})
    }

    updateRow = (data,id) =>{
        this.toggleSnackbar("Updating ...", true, true)
        FirebaseApi[this.state.dataOf].doc(id).update(data)
        .then((result)=>{
            this.toggleSnackbar("Updated ...", true, false);

        })
        .catch((error)=>{
            console.log({error})
        })
    }

    actionTriggered = ({edited=false,data,id}) =>{
        if(edited){
            this.updateRow(data,id)

        }
        else{
            this.addRow(data)
            
        }

    }

    render() {
        console.log(this.state)
        return (
            <Grid>
                <Snackbar key={this.state.dataOf} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={this.state.snackbarVisibe} autoHideDuration={6000} onClose={this.toggleSnackbar} message={this.state.snackBarMessage} />
                {/* adding new data manually */}
                <Dialog open={this.state.openDialog} onClose={this.handleDialogClose} aria-labelledby="form-dialog-title" >
                    <Button onClick={this.handleDialogClose} color="primary" style={{ position: "absolute", top: 10, right: 10 }}>
                        x
                    </Button>
                    <DialogTitle id="form-dialog-title">Add New {this.state.dataOf}</DialogTitle>
                    <DialogContent>
                        <CupUpdate data={this.state.selectedRowData} initialValues={{imageUrl:"",name:"",description:""}} dataStructure={this.state.dataStructure} triggerAction={(data)=>this.actionTriggered(data)} />
                        {/* <NewRow toggleSnackbar={this.toggleSnackbar} actionInProgress={this.state.actionInProgress} addRow={this.addRow} /> */}
                    </DialogContent>
                </Dialog>
                {/* editing already existing data */}
                <Dialog open={this.state.editDialog} onClose={this.handleEditDialogClose} aria-labelledby="form-dialog-title" >
                    <Button onClick={this.handleEditDialogClose} color="primary" style={{ position: "absolute", top: 10, right: 10 }}>
                        x
                    </Button>
                    <DialogTitle id="form-dialog-title">Add New {this.state.dataOf}</DialogTitle>
                    <DialogContent>
                        <NewRow initialValues={this.state.selectedData} toggleSnackbar={this.toggleSnackbar} actionInProgress={this.state.actionInProgress} addRow={this.addRow} />
                    </DialogContent>
                </Dialog>
                <Grid container direction="column" style={{ height: 150 }} spacing={2}>
                    <Typography variant="h3">
                        {this.state.dataOfPlural}
                    </Typography>
                    <Typography variant="caption">Last updated at :{this.state.lastFetchedTimeStamp}</Typography>
                    <Grid container direction="row" alignItems="center" justify="flex-start" spacing={2} style={{ marginTop: 5, marginBottom: 5 }}>
                        <Button variant="contained" onClick={this.fetchData} disabled={this.state.actionInProgress} style={{ margin: 5 }}>
                            <RefreshIcon />
                        </Button>
                        <Button variant="contained" onClick={this.toggleDialog} style={{ margin: 5 }}>
                            <AddIcon />
                        </Button>
                        <Button variant="contained" onClick={this.editRow} style={{ margin: 5 }} disabled={this.state.selectedRows.length == 1 ? false : true}>
                            <EditIcon />
                        </Button>
                        <Button variant="contained" onClick={this.deleteRows} style={{ margin: 5 }}
                            disabled={this.state.selectedRows.length > 0 ? false : true}>
                            <DeleteIcon />
                        </Button>
                    </Grid>
                </Grid>
                <Divider />
                <Grid style={{ height: 800, width: '100%' }}>
                    <DataGrid onSelectionChange={(newSelection) => this.selectARow(newSelection)} rows={this.state.rowData} columns={header} pageSize={50} checkboxSelection />
                </Grid>
            </Grid>
        );
    }
}

export default Ingredients;