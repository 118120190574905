import React, { useState, useEffect, useContext } from 'react'
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid'
import { FirebaseApi } from '../../../firebaseConfig'
import { Typography, Box } from '@mui/material'
import Grid from '@mui/material/Grid'
import { styled, useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom'
import Entities from '..';
import AppContext from '../../../config/AppContext'

const DataGrids = styled(MuiDataGrid)({

})


const UnitsTable = (props) => {
    const AppState = useContext(AppContext);
    const { setAddedEntity, close } = props;
    const [rowData, setRowData] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentIngredient, setCurrentIngredient] = useState('');
    const [rowsLength, setRowsLength] = useState([]);
    const [currentEntityData, setCurrentEntityData] = useState();
    const currentEntity = 'units'
    const currentEntityCategory = 'pure'
    console.log(rowData);


    console.log("current selected", rowsLength);
    const column = [
        { field: "name", headerName: "Name", width: 250 },
        { field: "unitType", headerName: 'Type of unit', width: 250 },
        { field: "shortHand", headerName: 'Short hand', width: 250 },
    ]

    const getData = async () => {
        setLoading(true)
        try {
            const data = await FirebaseApi["units"].get()
            const parsedData = data.docs.map((item) => {
                return {
                    id: item.id,
                    ...item.data()
                }
                // return item
            })
            console.log(parsedData)
            setRowData(parsedData)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)

        }
    }

    const selectARow = (row) => {
        setCurrentIngredient(row?.row)
        console.log(row);
        // setCurrentEntityData(row?.row)

        AppState.handleCurrentEntityData(row?.row)
        AppState.handleCurrentEntityType(currentEntity)
    }

    const handleRowsSelected = (value) => {
        setRowsLength(value)
    }

    useEffect(() => {
        getData()
    }, [])


    // return loading ? (
    //     <div>Loading..</div>
    // ) : <>
    // <Entities />
    // <Box style={{ height: '90vh', width: '100%' }}>
    //     <DataGrids
    //         hideFooter
    //         checkboxSelection
    //         columns={column}
    //         rows={rowData}
    //         onRowClick={(details) => selectARow(details)}
    //     />
    // </Box>
    // </>
    return (
        <>
            <Entities Entity={'units'} currentEntityData={currentEntityData} currentEntityCategory={currentEntityCategory} rowsCount={rowsLength} />
            {loading ? <div>Loading..</div> :
                <Box style={{ height: '90vh', width: '100%' }}>
                    <DataGrids
                        hideFooter
                        columns={column}
                        rows={rowData}
                        checkboxSelection
                        onRowClick={(details) => selectARow(details)}
                        onSelectionModelChange={(details) => handleRowsSelected(details)}
                    />
                </Box>}

        </>


    )

}

export default UnitsTable