import React,{useContext,useState,useEffect} from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import {FormikProvider,FieldArray} from 'formik'
import {makeStyles} from '@mui/styles'
import AddRecipeContext from '../../../config/AddRecipeContext';
import CloudServices from '../../../cloud-services-core';
import generateId from '../../../components/GenerateId';


// icons
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
// assets
import IngredientsPro from '../../../assets/IngredientsPro.png'
import NoInstruciton from '../../../assets/noInstruction.png'
import Fuse from 'fuse.js'
import { FirebaseApi } from '../../../firebaseConfig';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import IngredientChip from '../../../components/AddRecipe/IngredientChip';
import { Accordion, AccordionDetails, AccordionSummary, Modal } from '@mui/material';
import CupUpdate from '../../../components/CupUpdate';


const useStyles = makeStyles({
    sectionNameDiv:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        
    },
    sectionName:{
        outline:'none',
        backgroundColor:'transparent',
        // backgroundColor:'#e6e6e6',
        border:0,
        fontSize:14,
        
    },
    sectionSelection:{
        width:35,
        height:35,
        '&:checked':{
            accentColor:'#2D2C27',
           
        }
    },
    chip:{
        margin:5,
        border:"1px solid #DDDDDD",
        padding:5,
        borderRadius:50,
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'flex-start',
        // width:'20%',
        '&:hover':{
            cursor:'pointer',
            backgroundColor:'#5200F1',
            color:'white',
            // width:'22%',
            // transition:'width 0.5s linear',
        }
    },
    chipClose:{
        margin:0,
        padding:"0 5px"
        
    },
    clearAllDiv:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'flex-start',
        alignItems:'center',
        paddingTop:10

    },
    clearAll:{
        border:'none',
        backgroundColor:'transparent',
        cursor:'pointer',
        fontSize:16,
        '&:hover':{
            color:'#5200F1',
            textDecorationLine:'underline'
        }
    },
    addSection:{
        width:'100%',
        border:'none',
        backgroundColor:'transparent',
        fontSize:14,
        fontWeight:'bold',
        color:'#5200F1',
        '&:hover':{
            cursor:'pointer'
        },
       

    },
    addSectionDiv:{
        border:"1px solid #5200F1",
        padding:15,
        backgroundColor:'white',
        borderRadius:12
    },
    searchIngredientsDiv:{
        position:'sticky',
        top:10,
        border:"1px solid #DDDDDD",
        borderRadius:12,
        height:'80vh',
        backgroundColor:'white'
        // overflowY:'scroll'
    },
    suggestedIngredientsDiv:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        flexWrap:'wrap',
        overflowY:'scroll',
        // height:'55vh',

    },
    ingredientChip:{ 
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        border:"1px solid #707070",
        borderRadius:24,
        margin:5,
        cursor:'pointer',
        '&:hover':{
            backgroundColor:'#5200F1',
            color:'white'
        }
    },

    searchIngredientsText:{
        width:'100%',
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        padding:"15px 0px",
        borderBottom:"1px solid #DDDDDD"
    },
    searchIngredientInput:{
        width:'100%',
        border:'none',
        outline:'none',
        backgroundColor:'transparent',
    },
    ingredientListContainer:{
        overflowX:'scroll',
        height:'auto',
        maxHeight:'55vh'
    },
    loading:{
        width:100,
        height:100,
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        animation: "$fadeIn 1s linear infinite "
    },
    "@keyframes fadeIn": {
        "0%": {
          transform:'rotate(90deg)'
            
        },
        "50%":{
            transform:'rotate(180deg)'
            
        },
        "75%":{
            transform:'rotate(270deg)'
        },
        "100%": {
            transform:'rotate(360deg)'
           
        }
      },

})

export default function IngredientSection(props) {
    const {formik,proMode} = useContext(AddRecipeContext)
    const [ingredientList,setIngredientList] = useState([])
    const [ingredientsLoading,setIngredientsLoading] = useState(false)
    const [selectedSection,setSelectedSection] = useState(null)
    const [ingredientHover,setIngredientHover] = useState({
        sectionIndex:null,
        ingredientIndex:null
    })

    const [searchIngredient,setSearchIngredient] = useState("")

    const classes = useStyles()

    const [fuse,setFuse] = useState(null)
    const [searchResultIngredients,setSearchResultIngredients] = useState([])
    const {values,setFieldValue} = formik
    const [suggestedIngredients,setSuggestedIngredients] = useState(values.recipeIngredient)


    const [openIngredientAddModal,setOpenIngredientAddModal] = useState(false)
    
    useEffect(()=>{
       
        // getIngredientsList()
    },[])

    const getIngredientsList  =async()=>{
        setIngredientsLoading(true)
        try {
            const ingredients = await FirebaseApi["ingredient"].get()

            const ingredientsData = ingredients.docs.map((ingredient)=>{
                return {
                    id:ingredient.id,
                    ingredient:ingredient.data().name,
                    ...ingredient.data()
                }
            })
            
            
            setIngredientList(ingredientsData)
            setFuse(new Fuse(ingredientsData,{
                keys:["name"]
            }))
            setIngredientsLoading(false)
        } catch (error) {
            console.log(error)
        }
    }


    const array_move = (arr, old_index, new_index) =>{
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    };

    const onDragEnd = (result) =>{

        if (!result.destination) {
            return;
        }
        

        // console.log(result)
        // console.log(selectedSection)
      
        if(result.destination.droppableId===result.source.droppableId){
            
            if(selectedSection !== null){
                
                setSelectedSection(result.destination.index)

            }
            const itemsReordered = array_move(
                formik.values.recipeInstructions,
                result.source.index,
                result.destination.index
            );

    

        

        }
        else{
            const index = result.destination.droppableId[result.destination.droppableId.length-1]
            const sectionValues =formik.values

            const updatedValues = sectionValues.map((values,valIndex)=>{
               
                if(valIndex===Number(index)){

                    const draggedItem = ingredientList.filter((ingredient)=>ingredient.id==result.draggableId)[0]
                 
                    values.ingredients.push(draggedItem.name)
                }
            })
            
           

        }

    }


   

    const grid = 8;
    const getListStyle = (isDraggingOver) => ({
        // background: isDraggingOver ? "lightblue" : "lightgrey",
        // padding: grid,
        width: '100%',
      });
      const getItemStyle = (isDragging, draggableStyle) => ({
        display:'flex',
        flexDirection:'column',
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        // padding: grid * 2,
        margin: `0 0 ${grid}px 0`,
       
        
        // change background colour if dragging
        background: "white",
        // color:isDragging? 'white' : 'black',
        
        // styles we need to apply on draggables
        ...draggableStyle,
        // border:'1px solid black',
      });


    const removeIngredient =(sectionNumber,ingredient)=>{

        const updatedRecipeIngredients = values.recipeIngredient.filter(recipeIngredient=>recipeIngredient.id !== ingredient)

        const updatedSectionIngredients = values.recipeInstructions[sectionNumber].ingredients.filter(ing=>ing !== ingredient)

        setFieldValue('recipeIngredient',updatedRecipeIngredients)

        setFieldValue(`recipeInstructions[${sectionNumber}].ingredients`,updatedSectionIngredients)

        // console.log({updatedRecipeIngredients,updatedSectionIngredients})




        // const sections =values.recipeInstructions




        // const updatedValues = sections.map((values,valIndex)=>{
            
        //     if(valIndex===sectionNumber){
                
        //         const newValues = values.ingredients.filter((item,index)=>{
        //             if(index!==ingredientIndex){
        //                 return item
        //             }
        //         })
        //         values.ingredients = newValues
            
        //         return values
        //     }
        //     return values
        // })

        
        // setFieldValue('recipeInstructions',updatedValues)

    }

    const shiftIngredientsToSections =(item)=>{
        console.log({item})

        const checkForItemsInSection = (item) =>{
            let pattern = new RegExp(`\\b(${item.ingredient})\\b`,'i')

            return values.recipeInstructions[selectedSection-1].ingredients.filter(value=>{
                const newVal = suggestedIngredients.filter(ingredient=>ingredient.id == value)
                console.log({newVal})
                console.log(pattern)
                console.log(pattern.test(newVal[0].ingredient))
                // if(value===item.id && )
                return value===item.id
            })
        }
       
        if(selectedSection && checkForItemsInSection(item).length <=0){
            // values.recipeIngredient.push({
               
            //     quantity:'',
            //     units:'',
            //     preparationStyle:'',
            //     optional:false,
            //     brandName:'',
            //     ingredientType:'',
            //     ...item,
            // })
          
            // setFieldValue('recipeIngredient',values.recipeIngredient)


            // values.recipeInstructions[selectedSection-1] = {
            //     ...values.recipeInstructions[selectedSection-1],
            //     'ingredients' : [item.id]
            // }
            values.recipeInstructions[selectedSection-1].ingredients.push(item.id)
           

            setFieldValue(`recipeInstructions[${selectedSection-1}].ingredients`,values.recipeInstructions[selectedSection-1].ingredients)
        }
    }

    const shiftToIngredients = (item) =>{
        console.log({item})
       
        const checkForItems = (item) =>{

            return values.recipeInstructions[selectedSection-1].ingredients.filter(value=>value===item.id)
        }
  
        if(selectedSection && checkForItems(item).length <= 0){

            
            
            values.recipeIngredient.push({
                ...item,
                quantity:'',
                unit:'',
                preparationStyle:'',
                optional:false,
                brandName:'',
                ingredientType:''
            })
          
            setFieldValue('recipeIngredient',values.recipeIngredient)


            // values.recipeInstructions[selectedSection-1] = {
            //     ...values.recipeInstructions[selectedSection-1],
            //     'ingredients' : [item.id]
            // }
            values.recipeInstructions[selectedSection-1].ingredients.push(item.id)
           

            setFieldValue(`recipeInstructions[${selectedSection-1}].ingredients`,values.recipeInstructions[selectedSection-1].ingredients)





        }
        
        
    //     console.log(item)
    //     const sections =values.recipeInstructions
    

    //     const checkForItems = (item,values) =>{
    //         return values.ingredients.filter(value=>value.name===item)
    //     }

    //    const updatedValues = sections.map((values,valIndex)=>{
        
    //        if(valIndex+1===selectedSection){
            
             
    //            const alreadyPresent = checkForItems(item.name,values)
    //             console.log(values)
    //            if(alreadyPresent.length<=0){
    //                const newValues = [...values.ingredients,{id:generateId(),...item}]
    //             //    values.ingredients.push({id:generateId(),...item})
    //                values.ingredients = newValues
    //             //    formik.setFieldValue('recipeIngredient',newValues)

    //             //    const recipeInstructionSectionIngredients = [...values.recipeInstructions[valIndex].ingredients,{id:generateId(),...item}]
    //             //    values.recipeIngredient.push(item)

    //            }
    //            return values
    //        }
    //        return values
    //    })
      

    //    setFieldValue('recipeInstructions',updatedValues)

    
        
    }

    const clearAllIngredients = (index)=>{
        const sections =values.recipeInstructions

        const updatedValues = sections.map((values,valIndex)=>{
            if(valIndex===index){
                values.ingredients = []
                return values
            }
            return values
        })

       setFieldValue('recipeInstructions',updatedValues)


    }

    const searchFromIngredients = (e)=>{
        setSearchIngredient(e.target.value)

        // console.log(fuse.search(e.target.value))

        if(searchIngredient.length==0 && ingredientList.length < 1){
            getIngredientsList()
        }
        else if(ingredientList.length > 0){
            console.log(fuse.search(e.target.value))
            setSearchResultIngredients(fuse.search(e.target.value))
        }
        

        // const match = new RegExp(`${e.target.value}`,"i")
        // console.log(match.test('apple'))

        // let item = 'apple'

        // console.log(item.search(match),match)
        // const matchedIngredients = ingredientList.filter(ingredient=>match.test(ingredient.name))
        // console.log(matchedIngredients)
        // setSearchIngredientResult(matchedIngredients)
        

    }


    const renderIngredientsList = {
        suggested:(data)=>{
           
            return data.map((item,index)=>(
                // <Draggable key={item.id} draggableId={item.id} index={index}>
                // {(provided, snapshot) => (
                    <Grid item  key={index}
                    
                    onClick={()=>shiftIngredientsToSections(item)}
                    >
                 
                    <Box
                    className={classes.ingredientChip}
                    >

                
                    <Box  style={{
                        width:47,
                        height:47,
                        backgroundColor:'#F5F5F5',
                        borderRadius:50,
                        border:'1px solid #707070',
                        borderLeft:'none',
                        display:'flex',
                        flexDirection:'row',
                        justifyContent:'center',
                        alignItems:'center'
                        }} >
                        {/* <img src={''} style={{width:'60%',height:'70%'}} /> */}
                    </Box>
                    <Typography margin={0} fontSize={18} padding={1}  >{item.ingredient}</Typography>
                    </Box>
                </Grid>
        ))
        },
        allItems :(data)=>{
            return data.map((item,index)=>(
                // <Draggable key={item.id} draggableId={item.id} index={index}>
                // {(provided, snapshot) => (
                    <IngredientChip key={index} shiftToIngredients={()=>shiftToIngredients(item)} image={item.imageUrl} name={item.ingredient}  />
                // <Grid item  key={index}
            
                // onClick={()=>shiftToIngredients(item)}
                // >
                //     <Box
                //     className={classes.ingredientChip}
                //     >

                
                //     <Box  style={{
                //         width:47,
                //         height:47,
                //         backgroundColor:'#F5F5F5',
                //         borderRadius:50,
                //         border:'1px solid #707070',
                //         borderLeft:'none',
                //         display:'flex',
                //         flexDirection:'row',
                //         justifyContent:'center',
                //         alignItems:'center'
                //         }} >
                //         <img src={item.imageUrl} style={{width:'60%',height:'70%'}} />
                //     </Box>
                //     <Typography margin={0} fontSize={18} padding={1}  >{item.name}</Typography>
                //     </Box>
                // </Grid>
                // )}
                                            // </Draggable>
            ))
        },
        searchResult:(data)=>{
          
            return data.map(({item},index)=>(
                // <Draggable key={item.id} draggableId={item.id} index={index}>
                // {(provided, snapshot) => (
                    <Grid item  key={index}
                    
                    onClick={()=>shiftToIngredients(item)}
                    >
                 
                    <Box
                    className={classes.ingredientChip}
                    >

                
                    <Box  style={{
                        width:47,
                        height:47,
                        backgroundColor:'#F5F5F5',
                        borderRadius:50,
                        border:'1px solid #707070',
                        borderLeft:'none',
                        display:'flex',
                        flexDirection:'row',
                        justifyContent:'center',
                        alignItems:'center'
                        }} >
                        <img src={item.imageUrl} style={{width:'60%',height:'70%'}} />
                    </Box>
                    <Typography margin={0} fontSize={18} padding={1}  >{item.ingredient}</Typography>
                    </Box>
                </Grid>
                // )}
                // </Draggable>
            ))
        }
    }

    const changeInstructionName = (e,index) =>{
        e.preventDefault()

        setFieldValue(`recipeInstructions[${index}].name`,e.target.value)

    }


    const saveToIngredients = async(data) =>{
        try {
            console.log(data)
            const saveNewIngredient = await FirebaseApi['ingredient'].add(data)
            console.log(await saveNewIngredient)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Grid container spacing={3} >
            <DragDropContext onDragEnd={onDragEnd}>
            <Grid item md={proMode ? 6 : 8 }>
                <FormikProvider value={formik}>

              
                <FieldArray name='recipeInstructions'>
                    {(arrayHelpers)=>{
                        
                        const {push,remove} = arrayHelpers
                        return (
                            
                            <div>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                        >
                                        {values.recipeInstructions.length > 0 ? values.recipeInstructions.map((item,index)=>(
                                            <Draggable key={index} draggableId={`${index}`} index={index}>
                                                
                                            {(provided, snapshot) => (
                                                <div
                                                    // style={getItemStyle(
                                                    //     snapshot.isDragging,
                                                    //     provided.draggableProps?.style
                                                    // )}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    
                                                    // onClick={()=>setSelectedSection(index)}
                                                    style={{
                                                        ...getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps?.style
                                                            ),
                                                        // border:`${selectedSection==index+1 ? '2px' :'1px' } solid ${selectedSection===index +1? '#5200F1' : '#DDDDDD'}`,
                                                        border:'1px solid #DDDDDD',
                                                        borderTopRightRadius:12,
                                                        borderTopLeftRadius:12,
                                                        marginBottom:15
                                                    }}
                                                    
                                                >
                                               
                                                    
                                                    <div style={{display:'flex',flexDirection:'column',}}>
                                                        <div style={{display:'flex',flexDirection:'row',padding:5,alignItems:'center',
                                                            borderBottom:'1px solid #DDDDDD'
                                                            }}>

                                                        
                                                            <div
                                                                {...provided.dragHandleProps}
                                                                style={{display:'flex',flexDirection:'row',margin:"0px 10px"}}>
                                                                    <DragIndicatorIcon style={{color:selectedSection==index+1 ? '#B998F9' : '#BDBDBD'}} />
         
                                                            </div>
                                                            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',width:'100%'}}>
                                                                <div className={classes.sectionNameDiv}>
                                                                    <p style={{margin:0}}>{index+1}.</p>
                                                                    <input type="text" value={item.name} onChange={(e)=>changeInstructionName(e,index)}  className={classes.sectionName} />
                                                                    

                                                                </div>
                                                                <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',}}>
                                                                    {/* <Checkbox checked={selectedSection===index}  onChange={(e)=>setSelectedSection(selectedSection ? null : selectedSection===0 ? null : index)} /> */}
                                                                    {selectedSection===index+1 && (
                                                                        <div style={{width:35,height:35,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',border:"1px solid #FA3131",borderRadius:4,}}>
                                                                            <DeleteIcon style={{color:'#FA3131',cursor:'pointer',}} onClick={()=>remove(index)} />
                                                                        </div>

                                                                    )}
                                                                    <input type="checkbox" onChange={(e)=>selectedSection===index+1 ? setSelectedSection(null) : setSelectedSection(index+1)} checked={selectedSection===index+1} className={classes.sectionSelection}  />
                                                                    {/* checked={selectedSection===index && (selectedSection || selectedSection==0)} */}
                                                                    {/* {selectedSection===index && (selectedSection || selectedSection==0)? (
                                                                            <DoneIcon onClick={(e)=>setSelectedSection(null)} style={{color:'gray',fontSize:26,margin:"0 10px",cursor:'pointer'}} />
                                                                    ) : (
                                                                        
                                                                        <AddIcon onClick={(e)=>setSelectedSection(index)} style={{color:'gray',fontSize:30,margin:"0 10px",cursor:'pointer'}}/>
                                                                    )} */}
                                                                    
                                                                </div>
                                                            </div>
                   
                                                        </div>
                                                        <div style={{padding:20,position:'relative'}}>
                                                            <FieldArray name={`recipeInstructions[${index}].ingredients`}>
                                                                {(ingredientHelpers)=>{
                                                                    
                                                                    return(
                                                                        <div style={{display:'flex',flexDirection:'column',}}>
                                                                            
                                                                                    <div
                                                                                        
                                                                                        style={{flex:1,display:'flex',flexDirection:'row',flexWrap:'wrap'}}
                                                                                    >

                                                            
                                                                                    {values.recipeInstructions[index]?.ingredients?.length > 0 &&
                                                                                    values.recipeInstructions[index].ingredients.map((ingredient,ingredientIndex)=>{
                                                                                        const fromIngredients = values.recipeIngredient.filter((recipeIngredient)=>recipeIngredient.id ==ingredient)[0]
                                                                                        console.log({fromIngredients})
                                                                                        if(fromIngredients){

                                                                                            return(
                                                                                                <IngredientChip key={ingredient} image={fromIngredients?.imageUrl? fromIngredients.imageUrl : ''} name={fromIngredients.ingredient} insideSection  remove={()=>removeIngredient(index,ingredient)} />
                                                                                                // <div key={ingredientIndex} onMouseEnter={()=>setIngredientHover({sectionIndex:index,ingredientIndex})} onMouseLeave={()=>setIngredientHover({sectionIndex:null,ingredientIndex:null})} className={classes.chip}>
                                                                                                //     <p style={{margin:0,padding:"0 10px",flex:1}}>{ingredient.name}</p>
                                                                                                //     {ingredientHover.sectionIndex===index && ingredientHover.ingredientIndex===ingredientIndex && <p className={classes.chipClose} onClick={()=>removeIngredient(index,ingredientIndex,ingredient)}>x</p>}
                                                                                                // </div>
                                                                                                )
                                                                                            }
                                                                                        })}
                                                                                    
                                                                                    </div>
                                                                                
                                                                            {selectedSection ===index+1 && values.recipeInstructions[index]?.ingredients?.length > 0  && (
                                                                                <div className={classes.clearAllDiv}>

                                                                                <button className={classes.clearAll} onClick={()=>clearAllIngredients(index)}>clear all</button>
                                                                                </div>

                                                                            )}
                                                                        </div>
                                                                    )
                                                                }}

                                                            </FieldArray>
                                                            {selectedSection===index+1 && (
                                                                <div style={{position:'absolute',left:-10,top:0,height:'100%',width:10,backgroundColor:'#2D2C27',borderTopLeftRadius:4,borderBottomLeftRadius:4}}>

                                                                </div>

                                                            )}
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                </div>
                                                )}
                                            </Draggable> 
                                        )) : (
                                            <Grid sx={{
                                                height:{
                                                    xs:'30vh',
                                                    sm:'67vh'
                                                }
                                            }} container direction="row" justifyContent="center" alignItems="center" >
                                                <img src={NoInstruciton} style={{width:'55%'}} />
                                            </Grid>
                                        )}
                                        {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                                { !proMode &&  (values.recipeInstructions.length > 0  ? (
                                    <div className={classes.addSectionDiv}>

                                        <button  className={classes.addSection} onClick={()=>push({
                                            id:`${values.recipeInstructions.length+1}`,
                                            '@type':'HowToSection',
                                            itemListElement:[
                                                {
                                                    id:generateId(),
                                                    text:"add instruction here",
                                                    parameters:{
                                                        toolsUsed:[],
                                                        temperature:'off',
                                                        duration:{
                                                            time:0,
                                                            unit:'sec'
                                                        },    
                                                    },
                                                    image:{}
                                                }

                                                
                                            ],
                                            name:'new Section',
                                            ingredients:[]
                                            })}
                                            
                                            >ADD A SECTION</button>
                                    </div>

                                ) : (
                                    <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',margin:50,}}>
                                        <Button onClick={()=>push({
                                            id:`${values.recipeInstructions.length+1}`,
                                            '@type':'HowToSection',
                                            itemListElement:[
                                                {
                                                    id:generateId(),
                                                    text:"Add instruction here",
                                                    parameters:{
                                                        toolsUsed:[],
                                                        temperature:'off',
                                                        duration:{
                                                            time:0,
                                                            unit:'sec'
                                                        },
                                                        pro:{
                                                            cookingAction:'',
                                                            stirring:false,
                                                            temperature:{
                                                                value:0,
                                                                unit:'c'
                                                            },
                                                            duration:{
                                                                time:0,
                                                                unit:'sec'
                                                            },
                                                            power:{
                                                                value:0,
                                                                unit:'watt'
                                                            }
                                                        }
                                                     
                                                        
                                                    },
                                                    image:''

                                                }
                                            ],
                                            name:'new Section',
                                            ingredients:[]
                                            })} variant="outlined"
                                            sx={{
                                                width:{
                                                    xs:'50%',
                                                    sm:'30%'
                                                }
                                            }} style={{padding:10,fontSize:17,fontWeight:'bold',backgroundColor:'white',borderColor:'#F94C0C',color:'#F94C0C'}}>ADD A SECTION</Button>
                                    </div>
                                ))}
                            </div>
                            
                            
                        )
                    }}
                </FieldArray>
                </FormikProvider>
                    
            
                
            </Grid>
            <Grid item md={proMode ? 6 : 4} style={{transition:'.3s ease-in-out'}}>
                {proMode ? (
                    <Grid container direction="row" alignItems="center" justifyContent="center" style={{height:'77%'}}>
                        <Grid item >
                            <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>

                                <img src={IngredientsPro}  style={{width:'75%',height:'100%'}} />
                            </div>
                        </Grid>
                    </Grid>
                ) : (
                    <div className={classes.searchIngredientsDiv}>
                    <div className={classes.searchIngredientsText}>
                        <Typography>Search Ingredients</Typography>

                    </div>
                    <div>
                        <Accordion>
                            <AccordionSummary>
                                <Typography>Recipe Ingredients</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container direction="column"  >
                                    {suggestedIngredients.map(ingredient=>(
                                        <Grid item key={ingredient.id}>
                                            <Typography>{ingredient.ingredient}</Typography>
                                        </Grid>
                                    ))}
                                </Grid>

                            </AccordionDetails>
                        </Accordion>
                    </div>

                    {/* <div style={{width:'95%',margin:'auto',padding:10}}> */}
                        <Container>
                            
                            <Grid container spacing={2}>

                         
                                <Grid item xs={12}>
                                    <Grid  container direction="row" alignItems="center" border="1px solid #707070" borderRadius={8} marginTop={1} >
                                        <Grid xs={1} item container direction="row" justifyContent="center" alignItems="center" width={70} height={40}>
                                            <SearchIcon />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <input 
                                                list="searchIngredients" 
                                                type="text"  
                                                placeholder="search ingredients" 
                                                className={classes.searchIngredientInput}  
                                                value={searchIngredient} 
                                                onChange={searchFromIngredients}
                                            />
                                        </Grid>
                                        <Grid item xs={1} container direction="row" justifyContent="center" alignItems="center">
                                            <CloseIcon  onClick={()=> (setSearchIngredient(''), setSearchResultIngredients([]))} />
                                        </Grid>

                                    </Grid>

                                </Grid>
                                <Grid item container direction="column" > 
                                    <Grid item>
                                        <Typography fontSize={12} fontWeight="bold"> SUGGESTED INGREDIENTS/ SEARCH RESULT</Typography>
                                    </Grid>
                                    <Grid item>
                                        { ingredientsLoading ? (
                                            <Grid container  direction="row"  alignItems="center" flexWrap="wrap" className={classes.ingredientListContainer}>
                                            <Grid item xs={12} style={{minHeight:'50vh',height:'50vh'}} >
                                                <Grid container direction="row" justifyContent="center" alignItems="center" style={{height:'100%'}} >
                                                    <Grid item  >
                                                        
        
                                                        <p  style={{fontSize:70}} className={classes.loading}>X</p>
        
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        ) :  
                                            <Grid container  direction="row"   flexWrap="wrap" className={classes.ingredientListContainer}>
                                                    {searchResultIngredients.length > 0 ?
                                                renderIngredientsList['searchResult'](searchResultIngredients) : 
                                                // renderIngredientsList['allItems'](ingredientList)
                                                renderIngredientsList['suggested'](suggestedIngredients)}
                                                </Grid>
                                        }


                                    </Grid>

                                </Grid>

                            </Grid>

                        
                        
                        <div >

                           
                            {/* <Droppable droppableId="search">
                                {(provided, snapshot) => ( */}
                                                
                                <div
                                    // {...provided.droppableProps}
                                    // ref={provided.innerRef}
                                    // ...getListStyle(snapshot.isDraggingOver),
                                    className={classes.suggestedIngredientsDiv} 
                                    >
                                    {/* {ingredientList.map((item,index)=>(
                                        // <Draggable key={item.id} draggableId={item.id} index={index}>
                                        // {(provided, snapshot) => (
                                        <div key={index}
                                        // ref={provided.innerRef}
                                        //     {...provided.draggableProps}
                                        //     {...provided.dragHandleProps}
                                        // ...getItemStyle(
                                        //     snapshot.isDragging,
                                        //     provided.draggableProps?.style
                                        // )
                                        onClick={()=>shiftToIngredients(item)}
                                        className={classes.ingredientChip}
                                        >
                                            <div  style={{
                                                width:47,
                                                height:47,
                                                backgroundColor:'#F5F5F5',
                                                borderRadius:50,
                                                border:'1px solid #707070',
                                                borderLeft:'none',
                                                display:'flex',
                                                flexDirection:'row',
                                                justifyContent:'center',
                                                alignItems:'center'
                                                }} >
                                                <img src={item.imageUrl} style={{width:'60%',height:'70%'}} />
                                            </div>
                                            <p style={{margin:0,padding:"10px 10px",fontSize:18}}>{item.name}</p>
                                        </div>

                                        // )}
                                        // </Draggable>
                                    ))} */}
                                    
                                </div>
                                {/* )}
                            </Droppable> */}

                        </div>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Grid item >
                                <Button variant="contained" onClick={()=>setOpenIngredientAddModal(true)}>Add New Ingredient</Button>
                            </Grid>
                        </Grid>
                        </Container>
                    {/* </div> */}

                    
                    
                    

                </div>

                )}
                
                
            </Grid>
            </DragDropContext>

            <Modal
            open={openIngredientAddModal}
            onClose={()=>setOpenIngredientAddModal(false)}
            disableAutoFocus
            style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}
            >
                <Box style={{width:'60%',height:'clamp(415px,60%,47vh)',backgroundColor:'white',borderRadius:6,padding:20}}>
                    <Grid container>
                        <Grid item xs={12} container direction="row" justifyContent="flex-end" alignItems="center">
                            <Grid item >
                                <div onClick={()=>setOpenIngredientAddModal(false)}>X</div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {/* <CupUpdate /> */}
                            <CupUpdate  initialValues={{imageUrl:"",name:"",description:""}} dataStructure={["imageUrl",'name',"description"]} triggerAction={(data)=>saveToIngredients(data)} />
                        </Grid>
                    </Grid>

                </Box>

            </Modal>
        </Grid>
    )
}
