import React,{useEffect,useState} from 'react'
import {Grid, Typography,Container,Box} from '@mui/material'
import { makeStyles } from '@mui/styles'

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {Link} from 'react-router-dom'
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';

import CloudServices from '../../cloud-services-core/index'
import RecipeCard from '../../components/RecipeCard';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';


const useStyles = makeStyles({
    switchView:{
        position:'relative',
        width:120,
        height:40,
        appearance:'none',
        backgroundColor:'white',
        outline:'none',
        borderRadius: 20,
        transition:'.5s',
        margin:0,
        border:'1px solid black',
        '&::before':{
            content:'""',
            position:'absolute',
            backgroundPosition: 'center', 
            backgroundSize: 'cover', 
            backgroundRepeat: 'no-repeat',
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            width:40,
            height:40,
            borderRadius:20,
            top:-1,
            left:0,
            backgroundColor:'black',
            transition:'.5s'
        }, 
        '&::after':{
            content:'"Tabluar"',
            position:'absolute',
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            top:-1,
            right:0,
            width:100,
            height:'100%',
            transition:'width .5s linear'
        },
        '&:checked':{
            backgroundColor:'black'
        },   
        '&:checked::before':{
            content:'""',
            left:'68%',
            top:-1,
            backgroundColor:'white',
            transition:'.5s'
        },
        '&:checked::after':{
            content:'"Card"',
            color:'white',
            left:0,
            transition:'left .5s linear'
        }
    }

})
export default function SearchResults(props) {
    const classes = useStyles(props)
    const [option,setOption] = useState('card')
    const [recipes,setRecipes] = useState([])


    useEffect(()=>{
        getRecipes()
    },[])

    const getRecipes = async() =>{
        try{
            const recipesFromDB = await CloudServices.recipes.search({
                keyword:[['name','in',[props.location.state]]]
            })
            setRecipes(recipesFromDB)
        }
        catch(err){
            console.log(err)
        }
    }

    
    const columns = [
        { field: 'name', headerName: 'Recipe Name', width: 150 },
        { field: 'Thumbnail', headerName: 'Thumbnail ', width: 150 },
        { field: 'recipeCategory', headerName: 'Meal Type', width: 150 },
        { field: 'recipeCuisine', headerName: 'Cuisine', width: 150 },
        { field: 'Diet', headerName: 'Diet', width: 150 },
        { field: 'Pro Version', headerName: 'Pro Version', width: 150 },
        { field: 'Created On', headerName: 'Created On', width: 150 },
        { field: 'Updated On', headerName: 'Updated On', width: 150 },
      ];

     const removeRecipeFromRecipes = (id)=>{

     }

    const renderDetails = (name)=>{
        switch(name){
            case 'card':
                return(
                    <Grid item xs={12}>
                        <div style={{width:'95%',margin:'auto'}}>
                            <Grid container  direction="column" >
                                <Grid item sm={12}>
                                    <Grid container alignItems="center" >
                                        <Grid item xs={6}>
                                            <Typography>Recently Added</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid sx={{
                                        justifyContent:{
                                            xs:'space-between',
                                            md:"flex-end"
                                        }
                                    }} container  direction="row"  alignItems="center">
                                                <Grid item sx={{
                                            marginRight:{
                                                md:2
                                            }
                                        }}>
                                                    <div style={{padding:"5px 25px",border:"1px solid gray",borderRadius:5}}>sort</div>

                                                </Grid>
                                                <Grid item>
                                                    <div style={{padding:"5px 25px",border:"1px solid gray",borderRadius:5}}>filter</div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm={12}>
                                    <Grid container columnSpacing={3} rowSpacing={2} direction="row" alignItems="center">
                                        {recipes.length > 0 && recipes.map((recipe=>(
                                            <Grid item lg={3} md={4} sm={6} xs={12} key={recipe.id}>
                                                

                                                <RecipeCard 
                                                    id={recipe.id} 
                                                    name={recipe.name} 
                                                    image={recipe.image && recipe.image[0]} 
                                                    date={recipe.datePublished.substring(0,10)} 
                                                    meal={`${recipe.recipeCategory} | ${recipe.recipeCuisine} `} 
                                                    updateRecipes={(id)=>removeRecipeFromRecipes(id)}
                                                />
                                                {/* <RecipeCard 
                                                    id={recipe.id} 
                                                    image={recipe?.image?.length > 0 ? recipe.image[0] : ""} 
                                                    name={recipe.name} 
                                                    meal={`${recipe.recipeCategory} | ${recipe.recipeCuisine} `} 
                                                    date={recipe.datePublished.substring(0,10)} 
                                                    recipe={recipe}
                                                    updateRecipes={(id)=>removeRecipeFromRecipes(id)}
                                                /> */}
                                            </Grid>
                                        )))}

                                    </Grid>

                                </Grid>
                                




                            </Grid>

                            
                        
                        </div>

                    </Grid>
                    )
            case 'table':
                return(
                    <Grid item xs={12}>
                        <div style={{ height: '70vh', width: '100%' }}>
                        <DataGrid hideFooter rows={recipes} columns={columns} />
                        </div>
                    </Grid>
                )

                    
        }
    }
    return (
       
        
        <Grid container spacing={2} direction="column">

            <Grid item xs={12} >
                <Box  style={{minHeight:'7vh',backgroundColor:'#F94C0C',position:'sticky',top:0,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                    <Container maxWidth="xl"  >

                 
                    <Grid  container style={{padding:"0 25px"}} >

                            <Grid  item md={8} xs={12} container direction="row" alignItems="center">

                                    <Grid item sm={1} xs={3}>
                                    <Box style={{
                                        width:40,
                                        height:40,
                                        display:'flex',
                                        flexDirection:'row',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        borderRadius:'50%',
                                        backgroundColor:'rgba(255,255,255,0.5)'
                                        }}>
                                        <KeyboardBackspaceIcon  style={{color:'white'}}/>

                                    </Box>
                                    </Grid>
                                    <Grid item sm={11} xs={9}>
                                        <Typography>Searching for "{props.location.state}"</Typography>
                                    </Grid>

                                

                            </Grid>
                            <Grid item md={4} xs={12} >
                                <Grid container direction="row" alignItems="center"  >
                                    <Grid item xs={4}>
                                            <Grid container direction="row" justifyContent="flex-start"  alignItems="center">
                                                <Grid item>
                                                    <div style={{
                                                        border:'1px solid #707070',
                                                        borderRadius:5,
                                                        padding:"5px 15px",
                                                        display:'flex',
                                                        flexDirection:'row',
                                                        justifyContent:'center',
                                                        alignItems:'center',
                                                        backgroundColor:'white'
                                                    }}>

                                                        <Typography>Move to drafts</Typography>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                    </Grid>
                                    <Grid item xs={4}  >
                                        <Grid container direction="row" justifyContent="center" alignItems="center">
                                            <Grid sm={12} item>
                                                <div style={{
                                                    border:'1px solid #707070',
                                                    padding:5,
                                                    borderRadius:5,
                                                    display:'flex',
                                                    flexDirection:'row',
                                                    justifyContent:'center',
                                                    alignItems:'center',
                                                    backgroundColor:'white'
                                                    }}>

                                                     <Typography >Delete</Typography>
                                                </div>
                                            </Grid>

                                        </Grid>
                                        {/* <Grid container direction="row" justifyContent="center" alignItems="center" style={{width:'100%',height:'100%',backgroundColor:'blue'}}>
                                                {/* <div style={{backgroundColor:'white',border:'1px solid black'}}>

                                                <Typography>Delete</Typography>
                                                </div> 
                                        </Grid> */}
                                      

                                    </Grid>
                                    <Grid item xs={4}>
                                    <Grid container direction="row" justifyContent="center" alignItems="center">
                                        <Grid item>

                                            <input type="checkbox" className={classes.switchView} checked={option==='card'} onChange={()=>setOption(option==='card' ? 'table' : 'card')} />
                                        </Grid>
                                        </Grid>
                                       

                                    </Grid>
                                </Grid>

                            </Grid>
                        
                    


                    </Grid>
                    </Container>
                    
                </Box>
            </Grid>
            <Grid item>

            <Container maxWidth="xl">
            <Grid container>

            {renderDetails(option)}
            </Grid>
            </Container>
            </Grid>
        </Grid>
      
    )
}
