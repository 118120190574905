import { textAlign } from "@mui/system";
import React, { useEffect, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import { makeStyles } from '@mui/styles'


import "react-quill/dist/quill.snow.css";
import './ArticleEditor.css'

const useStyles = makeStyles(() => ({
    textEditor: {
        '.ql-snow': {
            backgroundColor: '#23241f',
            color: '#f8f8f2',
            overflow: 'visible',
        }
    }
}))

const ArticleEditor = (props) => {
    const [text, setText] = useState('');
    const formik = props.formik;

    const classes = useStyles();

    const { values, handleChange, setFieldValue, setValues } = formik


    const modules = {
        toolbar: [
            [{ font: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ color: [] }, { background: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image", "video"],
        ]
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image"
    ];

    const handleEditor = (e) => {
        console.log(e);
        setFieldValue('mainArticle', e);
    }



    return (


        <div className='text-editor'>
            <ReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                value={values.mainArticle ? values.mainArticle : text}
                onChange={(e) => handleEditor(e)}


            />

        </div>

    );
}

export default ArticleEditor;
