import { Grid, Typography } from '@mui/material'
import React from 'react';
import IngredientsTable from './IngredientsTable';
import { useStyles } from './Styles'

const IngridentsIndex = ({ formik }) => {
    const classes = useStyles();

  return (
    <Grid container direction="column">
        <Grid item style={{ marginLeft: 135, marginTop: 15, maxWidth: 1300 }}>
            <Grid item container direction="row">
                <Grid item className={classes.ingredientTab}>
                    <Typography style={{ fontSize: 17, fontWeight: 'bold', padding: "15px 20px" }}>
                        Ingredient Name
                    </Typography>
                </Grid>
                <Grid item className={classes.quantityTab}>
                    <Typography style={{ fontSize: 17, fontWeight: 'bold', padding: "15px 20px"}}>
                        Quantity
                    </Typography>
                </Grid>
                <Grid item className={classes.unitsTab}>
                    <Typography style={{ fontSize: 17, fontWeight: 'bold', padding: "15px 20px"}}>
                        Units
                    </Typography>
                </Grid>
                <Grid item className={classes.prepstylesTab}>
                    <Typography style={{ fontSize: 17, fontWeight: 'bold', padding: "15px 20px"}}>
                        Prep Styles
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container>
                <IngredientsTable formik={formik} />
            </Grid>
        </Grid>
    </Grid>
  )
}

export default IngridentsIndex;