export const pureEntities = [
        {
            heading: "Ing Category",
            db: 'ingredientCategory'
        }, 
        // {
        //     heading: "Ing Type",
        //     db: 'ingredientType'
        // },
        {
            heading: "Units",
            db: 'units'
        },  {
            heading: "Prep Style",
            db: 'prepStyle'
        }, {
            heading: "Cooking Action",
            db: 'cookingMethod'
        }, {
            heading: "Tools",
            db: 'tools'
        }, {
            heading: "Conversion",
            db: 'conversions'
        }];
        
export const dependentEntities = [
        {
            heading: "Ingredients",
            db: 'ingredient'
        }, {
            heading: "Allergies",
            db: "allergies"
        }, {
            heading: "Diet Preference",
            db: 'diets'
        }, {
            heading: "Medical Restrictions",
            db: 'medical'
        },  {
            heading: "Cuisines",
            db: "cuisines"
        }, {
            heading: "Presets",
            db: 'presets'
        }]