import React, { useState, useEffect, useContext } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { Grid, Typography } from '@mui/material';
import EmptyImage from '../../../assets/CreatorScreen/emptyImage.png'
import { Link } from 'react-router-dom';


export const PublishedCollectionsTable = ({ data }) => {
    const [rowData, setRowData] = useState(data);

    const handleData = () => {
        rowData.map((item) => {
            if (item.values != undefined) {
                console.log(item);
            }
        })
    }
    const columns =
        [
            { field: 'date', headerName: 'Date Added', width: 130 },
            { field: `name`, headerName: 'Post Title', width: 300 },
            { field: 'values.level', headerName: 'Level', width: 150 },
            { field: 'type', headerName: 'Type', width: 150 },
            { field: 'views', headerName: 'views', width: 150 },
            { field: 'engagement', headerName: 'engagement', width: 100 },
        ];

    useEffect(() => {
        handleData();
    }, [])

    //getCost();

    const noData = () => {
        return (
            <Grid style={{ display: 'flex', justifyContent: 'center', flexDirection:'column', marginTop: 50, alignItems: 'center' }}>
                <img src={EmptyImage} alt="" style={{ height: 300, width: 300 }} />
                <Typography style={{ marginTop: 10 }}>There's no post published yet</Typography>
                <Typography>When you publish a post, its analytics will be shown here.</Typography>
                <Typography>Not sure where to start? Create a <Link to='/createRecipe/0'>recipe</Link> or <Link to="/createArticle/0">article</Link></Typography>
            </Grid>
        )
    }


    return (
        <div style={{ height: 800, width: '97%' }}>
            <Typography style={{ marginBottom: 10 }}>Published Table</Typography>
            <DataGrid
                hideFooter
                rows={rowData}
                columns={columns}
                components={{
                    NoRowsOverlay: noData
                }}
                checkboxSelection
            />
        </div>
    )
}

export const SubscriptionsTable = ({ data }) => {
    const [rowData, setRowData] = useState(data);

    const handleData = () => {
        rowData.map((item) => {
            if (item.values != undefined) {
                console.log(item);
            }
        })
    }
    const columns =
        [
            { field: 'date', headerName: 'Date Added', width: 130 },
            { field: `name`, headerName: 'Post Title', width: 300 },
            { field: `name`, headerName: 'Contact', width: 300 },
            { field: 'values.level', headerName: 'Level', width: 150 },
            { field: 'type', headerName: 'Type', width: 150 },
            { field: 'views', headerName: 'views', width: 150 },
            { field: 'engagement', headerName: 'engagement', width: 100 },
        ];

    useEffect(() => {
        handleData();
    }, [])

    //getCost();

    const noData = () => {
        return (
            <Grid style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: 50, alignItems: 'center' }}>
                <img src={EmptyImage} alt="" style={{ height: 300, width: 300 }} />
                <Typography style={{ marginTop: 10 }}>There's no subscribers joined yet</Typography>
            </Grid>
        )
    }


    return (

        <div style={{ height: 800, width: '95%' }}>
            <Typography style={{ marginBottom: 10 }}>Subscription Table</Typography>
            <DataGrid
                hideFooter
                rows={rowData}
                columns={columns}
                components={{
                    NoRowsOverlay: noData
                }}
                checkboxSelection
            />
        </div>
    )
}

export const EarningsTable = ({ data }) => {
    const [rowData, setRowData] = useState(data);

    const handleData = () => {
        rowData.map((item) => {
            if (item.values != undefined) {
                console.log(item);
            }
        })
    }
    const columns =
        [
            { field: 'date', headerName: 'Date Added', width: 130 },
            { field: `name`, headerName: 'Post Title', width: 300 },
            { field: `name`, headerName: 'Earnings', width: 300 },
            { field: 'values.level', headerName: 'Level', width: 150 },
            { field: 'type', headerName: 'Type', width: 150 },
            { field: 'views', headerName: 'views', width: 150 },
            { field: 'engagement', headerName: 'engagement', width: 100 },
        ];

    useEffect(() => {
        handleData();
    }, [])

    //getCost();

    const noData = () => {
        return (
            <Grid style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: 50, alignItems: 'center' }}>
                <img src={EmptyImage} alt="" style={{ height: 300, width: 300 }} />
                <Typography style={{ marginTop: 10 }}>There's no earnings started yet</Typography>
            </Grid>
        )
    }


    return (

        <div style={{ height: 800, width: '95%' }}>
            <Typography style={{ marginBottom: 10 }}>Earnings Table</Typography>
            <DataGrid
                hideFooter
                rows={rowData}
                columns={columns}
                components={{
                    NoRowsOverlay: noData
                }}
                checkboxSelection
            />
        </div>

    )

}
