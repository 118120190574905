import { Button, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useContext, useState } from 'react'
import CreatorNavbar from '../Common/CreatorNavbar'
import AppContext from '../../../config/AppContext'
import Switch from '@mui/material/Switch';

import { useFormik } from 'formik';
import { FirebaseApi } from '../../../firebaseConfig'
import DefaultImage from '../../../assets/ImageDefault.svg'

import { makeStyles } from '@mui/styles'
import { SettingButton, SettingsHeaderTabs, SettingsTextFieldArea, SettingTypography } from './Styles';

const useStyles = makeStyles(() => ({
    // imageModal
    modalHeadingContainer: {
        // backgroundColor:theme.palette.secondary.main,

        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 6
    },
    imageHeading: {
        padding: "0 25px"
    },
    emptyImagesContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 124
    },
    imagesContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflowX: 'scroll',
        flexWrap: 'nowrap',
        padding: "10px 20px"
    },
    image: {
        width: 124,
        height: 124,
        borderRadius: 8,
        flexShrink: 0,
        marginRight: 10,
        cursor: 'pointer'
    },

}))


const Settings = () => {
    const AppState = useContext(AppContext);
    const [option, setOption] = useState('ProfileInfo');
    const [userData, setUserData] = useState(AppState.user);

    const formik = useFormik(({
        enableReinitialize: true,
        initialValues: {
            userName: userData?.userName || '',
            email: userData?.email || '',
            profileImage: userData?.profileImage || '',
            password: '',
            confirmPassWord: '',
            socialLinks: {
                facebook: '',
                youtube: '',
                instagram: '',
            },
            notifications: {
                creatorMode: {
                    otherChef: userData?.notifications?.creatorMode?.otherChef || false,
                    newRecipe: userData?.notifications?.creatorMode?.newRecipe || false,

                },
                subscriberMode: {
                    otherChef: userData?.notifications?.subscriberMode?.otherChef || false,
                    newRecipe: userData?.notifications?.subscriberMode?.newRecipe || false,

                }
            }

        },
        onSubmit: (values => {
            passwordValidation(values);
            console.log(values);
        })
    }))

    const passwordValidation = async (values) => {
        const { password, currentPassword } = values;
        if(password === currentPassword){
            console.log(values);
            // updateUser(values);
        } else {
            alert('password does not match');
        }
    }

    const { values, setFieldValue } = formik;

    useEffect(() => {
        AppState.handleNavbar(false)
        return () => AppState.handleNavbar(true)
    }, [])

    const updateUser = async (values) => {
        // console.log("In update")
        try {
            await FirebaseApi['user'].doc(userData?.id).update(
                JSON.parse(JSON.stringify({
                    notifications: values?.notifications,
                    username: values?.username,
                    email: values?.email,
                    socialLinks: values?.socialLinks,
                    profileImage: values?.profileImage,
                }))
            )
            // console.log("Updated")

            // to update local
            const data = await FirebaseApi.user.doc(userData?.id).get();
            localStorage.setItem('user', JSON.stringify(data?.data()));
            // console.log("data saved");
        } catch (err) {
            console.log(err);
        }
    }

    function getBase64(file) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
            }
        })
    }

    const collectImagesWithIndex = async (e, sectionIndex = 0, stepIndex = 0) => {
        // console.log(sectionIndex, stepIndex)

        var imageObject = {};
        if (formik.values.images && [...Object.keys(formik.values.images)].length >= 0) {
            await Promise.all([...Array(e.target.files.length).keys()].map(async (item, index) => {
                const data = await getBase64(e.target.files[item])
                imageObject[`img-${sectionIndex}-${stepIndex}-${[...Object.keys(formik.values.images)].length + index}`] = data
            }));
        }
        else {
            await Promise.all([...Array(e.target.files.length).keys()].map(async (item, index) => {
                const data = await getBase64(e.target.files[item])
                imageObject[`img-${sectionIndex}-${stepIndex}-${index}`] = data
            }));
        }
        setFieldValue('profileImage', [imageObject['img-0-0-0']]);
    }
    // console.log(formik)

    const renderScreen = (screen) => {
        switch (screen) {
            case 'ProfileInfo':
                return (
                    <Grid container style={{ display: 'flex', flexDirection: 'column', width: 570 }}>
                        <Grid style={{ height: 324, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: 15 }}>
                            <SettingsTextFieldArea>
                                <SettingTypography style={{ marginBottom: 4 }}>Image</SettingTypography>
                                {values?.profileImage && values?.profileImage[0] &&
                                    <label htmlFor='upload'>
                                        <img src={values.profileImage[0] ?? DefaultImage } style={{ height: 100, width: 100, border: '1px solid transparent', borderRadius: '50%' }} alt='' />
                                        <input id="upload" type="file" accept="image/png, image/jpeg" onChange={e => collectImagesWithIndex(e)} style={{ display: 'none' }} />
                                    </label>
                                }
                            </SettingsTextFieldArea>
                            <SettingsTextFieldArea>
                                <SettingTypography>DISPLAY NAME</SettingTypography>
                                <TextField placeholder='Enter Name' type='text' onBlur={(e) => setFieldValue('username', e.target.value)} defaultValue={values?.userName} size='small' style={{ width: '100%', background: '#F4F4F4', padding: "5px 10px",border: '1px solid black', borderRadius: 5 }} variant="standard" InputProps={{ disableUnderline: true }} />
                            </SettingsTextFieldArea>
                            <SettingsTextFieldArea>
                                <SettingTypography>EMAIL-ID</SettingTypography>
                                <TextField placeholder='Enter Email' type='email' onBlur={(e) => setFieldValue('email', e.target.value)} defaultValue={values?.email} size='small' style={{ width: '100%', background: '#F4F4F4', padding: "5px 10px",border: '1px solid black', borderRadius: 5 }} variant="standard" InputProps={{ disableUnderline: true }} />
                            </SettingsTextFieldArea>
                            <SettingsTextFieldArea>
                                <SettingTypography>Password</SettingTypography>
                                <TextField placeholder='Enter Password' type='password' onBlur={(e) => setFieldValue('password', e.target.value)} defaultValue={values?.password} size='small' style={{ width: '100%', background: '#F4F4F4', padding: "5px 10px",border: '1px solid black', borderRadius: 5 }} variant="standard" InputProps={{ disableUnderline: true }} />
                            </SettingsTextFieldArea>
                            <SettingsTextFieldArea>
                                <SettingTypography>Confirm Password</SettingTypography>
                                <TextField placeholder='Re Enter Password' type='password' onBlur={(e) => setFieldValue('currentPassword', e.target.value)} defaultValue={values?.currentPassword} size='small' style={{ width: '100%', background: '#F4F4F4', padding: "5px 10px",border: '1px solid black', borderRadius: 5 }} variant="standard" InputProps={{ disableUnderline: true }} />
                            </SettingsTextFieldArea>
                            <Grid item container direction='row'>
                                <SettingsTextFieldArea style={{ width: '50%' }}>
                                    <SettingTypography>Connect With Google</SettingTypography>
                                    <small style={{ fontSize: '12px' }}>You must have a password before disconnecting your Google account.</small>
                                    <br />
                                    <SettingButton>Connect</SettingButton>
                                </SettingsTextFieldArea>
                                <SettingsTextFieldArea style={{ width: '50%' }}>
                                    <SettingTypography>Connect With Facebook</SettingTypography>
                                    <SettingButton>Connect</SettingButton>
                                </SettingsTextFieldArea>
                            </Grid>
                        </Grid>
                    </Grid>
                )

            // case 'SocialLinks':
            //     return (
            //         <Grid container style={{ width: 410 }}>
            //             <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between', margin: 10, color: '#BEBEBE' }}>
            //                 <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            //                     <FacebookIcon style={{ width: 34, height: 34 }} />
            //                     <Typography>Facebook</Typography>
            //                 </Grid>
            //                 <Typography>Connect</Typography>
            //             </Grid>
            //             <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between', margin: 10, color: '#BEBEBE' }}>
            //                 <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            //                     <InstagramIcon style={{ width: 34, height: 34 }} />
            //                     <Typography>Instagram</Typography>
            //                 </Grid>
            //                 <Typography>Connect</Typography>
            //             </Grid>
            //             <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between', margin: 10, color: '#BEBEBE' }}>
            //                 <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            //                     <YouTubeIcon style={{ width: 34, height: 34 }} />
            //                     <Typography>Youtube</Typography>
            //                 </Grid>
            //                 <Typography>Connect</Typography>
            //             </Grid>
            //         </Grid>
            //     )

            case 'Notifications':
                return (
                    <Grid container style={{ width: 500 }}>
                        <Grid item xs={4} sm={8} md={12} lg={12} display='flex' flexDirection='column' alignItems="center" style={{ width: '100%' }}>


                            <Typography variant='subtitle1' style={{ marginTop: 10, marginBottom: 10 }}>As A Creator</Typography>
                            <Grid style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: 10 }}>
                                <Switch checked={values?.notifications?.creatorMode?.otherChef} onChange={() => setFieldValue('notifications.creatorMode.otherChef', !values?.notifications?.creatorMode?.otherChef)} color='success' />
                                <Typography>Other Chef</Typography>
                            </Grid>
                            <Grid style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: 10 }}>
                                <Switch checked={values?.notifications?.creatorMode?.newRecipe} onChange={() => setFieldValue('notifications.creatorMode.newRecipe', !values?.notifications?.creatorMode?.newRecipe)} color='success' />
                                <Typography>New Recipe</Typography>
                            </Grid>
                            <Typography variant='subtitle1' style={{ marginBottom: 10 }}>As A Subscriber</Typography>
                            <Grid style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: 10, marginBottom: 10 }}>
                                <Switch checked={values?.notifications?.subscriberMode?.otherChef} onChange={() => setFieldValue('notifications.subscriberMode.otherChef', !values?.notifications?.subscriberMode?.otherChef)} color='success' />
                                <Typography>Other Chef</Typography>
                            </Grid>
                            <Grid style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: 10 }}>
                                <Switch checked={values?.notifications?.subscriberMode?.newRecipe} onChange={() => setFieldValue('notifications.subscriberMode.newRecipe', !values?.notifications?.subscriberMode?.newRecipe)} color='success' />
                                <Typography>New Recipe</Typography>
                            </Grid>
                            {/* <Typography>Billing</Typography> */}
                        </Grid>
                    </Grid>
                )
                default:
                    return;
        }
    }

    return (
        <Grid container marginTop={3}>
            <Grid item xs={2} >
                <CreatorNavbar currentScreen="Settings" />
            </Grid>
            <Grid item xs={10}>
                <Grid style={{ width: '100%', height: '50%', display: 'flex', flexDirection: 'column', }}>
                    <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography onClick={() => setOption('ProfileInfo')} variant='h6' style={option === 'ProfileInfo' ? {borderBottom: "1px solid #383838", cursor: 'pointer' } : { cursor: 'pointer' }}>Profile Information</Typography>
                        {/* <Typography onClick={() => setOption('SocialLinks')} style={{ marginLeft: 200 }} variant='h6'>Social Links</Typography> */}
                        <Typography onClick={() => setOption('Notifications')} style={option === 'Notifications' ? { marginLeft: 30, borderBottom: '1px solid #383838', cursor: 'pointer' } : { marginLeft: 30, cursor: 'pointer' }} variant='h6'>Notifications</Typography>

                    </Grid>
                    {renderScreen(option)}
                </Grid>

                {/* <Button style={{ position: 'absolute', right: '45%', bottom: '15%' }} variant="contained" onClick={() => formik.submitForm()}>
                    Save
                </Button> */}
            </Grid>
        </Grid>
    )
}

export default Settings