import { FirebaseApi } from "../../firebaseConfig";
import { CRUDoperations } from "../CRUD/crud";


const creatorEarnings = {
    create: async (userId, data) => {
        try {
            const newEarning = await CRUDoperations.create('creatorEarnings', userId, data);
            return newEarning
        }
        catch (err) {
            return "No earnings found";
        }
    },

    createWithId: async (userId, userName, value) => {
        console.log(userId, userName, value)

        const result = await CRUDoperations.create("creatorEarnings", {
            userId,
            creatorName: `${userName} earnings`,
            type: 'earnings',
            name: value.name,
            level: value.level,
            status: value.status,
            cost: value.cost,


        })
        console.log("Completed posting");
        return result


    },
    fetch: async () => {
        try {
            const getEarnings = await FirebaseApi['creatorEarnings'].get();
            const earnings = getEarnings.docs.map(earnings => ({
                ...earnings.data(), id: earnings.id
            }))




            return earnings;

        } catch (err) {

        }
    },
    fetchByUserId: async (userId) => {
        try {
            const data = await FirebaseApi.creatorEarnings.where('creatorId', '==', `${userId}`).get();
            const final = data.docs.map(earnings => ({
                ...earnings.data(), id: earnings.id
            }))

            return final;


        } catch (err) {

        }
    },
    delete: async (docId) => {
        try {
            const deleting = await CRUDoperations.delete("creatorEarnings", docId)
            return 'done'

        }
        catch (err) {
            throw err

        }

    },
    update: async (docId, data) => {

        try {
            const updating = await CRUDoperations.update("creatorEarnings", docId, data)
            return updating

        } catch (err) {
            return err;
        }
    },

}

export default creatorEarnings;