import firebase from 'firebase/app';
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/auth'

var firebaseConfig = {
  apiKey: "AIzaSyCvCN1YIrhHw5vOy7L55P8xfGJ8Yl_PF1U",
  authDomain: "riku-staging.firebaseapp.com",
  databaseURL: "https://riku-staging.firebaseio.com",
  projectId: "riku-staging",
  storageBucket: "riku-staging.appspot.com",
  messagingSenderId: "379205716367",
  appId: "1:379205716367:web:ec86caa8e45d53bda8a3fa",
  measurementId: "G-THMPJ1VLCE"
};
// Initialize Firebase

firebase.initializeApp(firebaseConfig);

var firestore = firebase.firestore();
const storage = firebase.storage();
const storageRef = firebase.storage().ref();



const FirebaseApi = {
  cuisines: firestore.collection("/cuisines"),
  course: firestore.collection("/courses"),


  diets: firestore.collection("/diets"),
  measurementUnit: firestore.collection("/measurementUnits"),

  cookingMethod: firestore.collection("/cookingMethods"),
  tool: firestore.collection("/tools"),

  recipe: firestore.collection("recipes"),
  recipeCategory: firestore.collection("/recipeCategories"),
  recipeState: firestore.collection("/recipeStates"),


  ingredientCategory: firestore.collection("ingredientCategories"),
  ingredientState: firestore.collection("ingredientStates"),

  drafts: firestore.collection("drafts"),

  actions: firestore.collection("/actions"),
  // tools: firestore.collection("/tools"),
  spices: firestore.collection("/spices"),
  cleaned: firestore.collection('cleaned'),
  trash: firestore.collection('trash'),

  // new added entities
  // pure
  units: firestore.collection("units"),
  tools: firestore.collection("tools"),
  prepStyle: firestore.collection("prepStyles"),
  conversions: firestore.collection("conversions"),
  ingredientType: firestore.collection("ingredientType"),
  cookAction: firestore.collection("cookAction"),



  // dependent
  allergies: firestore.collection("allergies"),
  medical: firestore.collection("medicalRestrictions"),
  presets: firestore.collection("presets"),

  humanScale: firestore.collection("humanScales"),
  ingredient: firestore.collection("ingredients"),

  // semiTest
  semiTest: firestore.collection("semiTest"),

  // ContentCreator - 
  articles: firestore.collection("articles"),
  creatorMemberships: firestore.collection("creatorMemberships"),
  creatorEarnings: firestore.collection("creatorEarnings"),
  creatorUILayouts: firestore.collection("creatorUILayouts"),
  user: firestore.collection('users'),
  //users: firestore.collection('users'),
  creatorSubscribers: firestore.collection("creatorSubscribers"),
  creatorBillings: firestore.collection("creatorBillings"),
  creatorCollections: firestore.collection("creatorCollections"),
  articleDrafts: firestore.collection("articleDrafts"),


}


const auth = () => {
  return firebase.auth()
}

export default firebase
export { firestore, FirebaseApi, storage, auth, storageRef }
