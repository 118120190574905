import { CRUDoperations } from '../CRUD/crud'



const tools = {
    create: async (value) => {
        try {
            const result = await CRUDoperations.create("tool", value)
            return result


        }
        catch (err) {

        }
    },
    read: async () => {
        try {
            const Data = await CRUDoperations.read("tool")
            console.log(Data)
            return Data
        }
        catch (err) {
            throw err

        }

    },
    update: async (docId, updatedData) => {

        try {
            const updating = await CRUDoperations.update("tool", docId, updatedData)
            return updating


        }
        catch (err) {
            throw err

        }

    },
    delete: async (docId) => {
        try {
            const deleting = await CRUDoperations.delete("tool", docId)
            return deleting

        }
        catch (err) {
            throw err

        }

    }
}

export default tools