import { FirebaseApi } from "../../firebaseConfig";
import { CRUDoperations } from "../CRUD/crud";


const creatorMemberships = {
    create: async (data) => {
        try {
            const newMembership = await CRUDoperations.create('creatorMemberships', data);
            return newMembership
        }
        catch (err) {
            return "No creatorMemberships found";
        }
    },

    fetch: async () => {
        try {
            const getcreatorMemberships = await FirebaseApi['creatorMemberships'].get();
            const creatorMemberships = getcreatorMemberships.docs.map(creatorMemberships => ({
                ...creatorMemberships.data(), id: creatorMemberships.id
            }))




            return creatorMemberships;

        } catch (err) {

        }
    },

    createWithId: async (creatorId, value) => {
        console.log(creatorId, value, value.silver)

        const result = await CRUDoperations.create("creatorMemberships", {
            creatorId,
            type: 'Memberships',
            free: value.free,
            silver: value.silver,
            platinum: value.platinum


        })
        console.log("Added membership plans ");
        return result


    },
    fetchByUserId: async (userId) => {
        try {
            const data = await FirebaseApi.creatorMemberships.where('creatorId', '==', `${userId}`).get();
            const final = data.docs.map(creatorMemberships => ({
                ...creatorMemberships.data(), id: creatorMemberships.id
            }))

            return final;


        } catch (err) {

        }
    },
    delete: async (docId) => {
        try {
            const deleting = await CRUDoperations.delete("creatorMemberships", docId)
            return 'done'

        }
        catch (err) {
            throw err

        }

    },
    update: async (docId, data) => {

        try {
            const updating = await CRUDoperations.update("creatorMemberships", docId, data)
            return updating




        } catch (err) {
            return err;
        }
    },

}

export default creatorMemberships;