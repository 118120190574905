import { Grid, Typography } from '@mui/material'
import React, { useEffect, useContext, useState } from 'react'
import HeaderNavbar from '../Common/HeaderNavbar'
import CreatorNavbar from '../Common/CreatorNavbar'
import AppContext from '../../../config/AppContext'
import SubscribersTable from './SubscribersTable'
import CloudServices from '../../../cloud-services-core'
import FirebaseApi from '../../../firebaseConfig';



const SubscribersScreen = () => {

    console.log(CloudServices);
    const AppState = useContext(AppContext);
    const [freeMembers, setFreeMembers] = useState(0);
    const [silverMembers, setSilverMembers] = useState(0);
    const [goldMembers, setGoldMembers] = useState(0);
    useEffect(() => {
        AppState.handleNavbar(false)
        return () => AppState.handleNavbar(true)
    }, [])

    const getUsers = async () => {
        let val = await FirebaseApi['user'].get()
        console.log(val);
    }

    //getUsers();


    return (
        <Grid style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>

            <CreatorNavbar currentScreen="Subscribers" />

            <Grid style={{ width: '84%' }}>


                <Grid style={{ display: 'flex', flexDirection: 'column', padding: '70px' }}>
                    <Grid item style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
                        <Grid item>
                            <Typography>
                                Calendar here
                            </Typography>

                        </Grid>
                        <Grid item style={{ display: 'flex', flexDirection: 'row', marginTop: 10, justifyContent: 'space-between' }}>
                            <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                <Typography variant='h4' item width='638px' >
                                    Subscribers Stats goes here
                                </Typography>



                            </Grid>

                            <Grid item style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Grid item style={{ display: 'flex', flexDirection: 'column', padding: 8, width: 185, background: '#F7F7F7', marginBottom: 10 }}>
                                    <Typography item style={{ color: '#858080' }} >
                                        Gold Members
                                    </Typography>

                                    <Grid item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 5 }}>
                                        <Typography item variant='h6'>
                                            {goldMembers}

                                        </Typography>

                                        <Typography item style={{ color: '#858080', textDecoration: 'underline' }} >
                                            Past Week
                                        </Typography>

                                    </Grid>


                                </Grid>

                                <Grid item style={{ display: 'flex', flexDirection: 'column', padding: 8, width: 185, background: '#F7F7F7', marginBottom: 10 }}>
                                    <Typography item style={{ color: '#858080' }} >
                                        Silver Members
                                    </Typography>

                                    <Grid item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 5 }}>
                                        <Typography item variant='h6'>
                                            {silverMembers}

                                        </Typography>

                                        <Typography item style={{ color: '#858080', textDecoration: 'underline' }} >
                                            Past Week
                                        </Typography>

                                    </Grid>


                                </Grid>

                                <Grid item style={{ display: 'flex', flexDirection: 'column', padding: 8, width: 185, background: '#F7F7F7', marginBottom: 10 }}>
                                    <Typography item style={{ color: '#858080' }} >
                                        Free Members
                                    </Typography>

                                    <Grid item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 5 }}>
                                        <Typography item variant='h6'>
                                            {freeMembers}
                                        </Typography>

                                        <Typography item style={{ color: '#858080', textDecoration: 'underline' }} >
                                            Past Week
                                        </Typography>

                                    </Grid>



                                </Grid>


                            </Grid>


                        </Grid>

                    </Grid>

                    <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>


                        <SubscribersTable freeMembers={freeMembers} setFreeMembers={setFreeMembers}
                            silverMembers={silverMembers} setSilverMembers={setSilverMembers}
                            goldMembers={goldMembers} setGoldMembers={setGoldMembers}

                        />

                    </Grid>

                </Grid>

            </Grid>

        </Grid>

    )

}


export default SubscribersScreen;  
