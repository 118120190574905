import React from 'react'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Box } from '@mui/system'
// import { useFormik } from 'formik';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import uploadImage from '../../utils/saveImageToStorage';

const useStyles = makeStyles({
    dragContainer: {

    }, drag: {
        display: 'flex'
    }
})

const ImageUploader = (props) => {
    const { changeImageLink, storagePlace } = props;
    const classes = useStyles();

    const collectImages = async (storagePlace, e) => {
        console.log(e.target.files)
        async function getImageUrl (){
            return new Promise((resolve)=> {
                const imageFile = e.target.files[0];
                const imageDetails = uploadImage(storagePlace, imageFile);
                resolve(imageDetails);
            })
        }

        const result = await getImageUrl(storagePlace, e);
        changeImageLink(result);
        console.log(result);
    }

        return (
        <Grid item xs={8} >
            <Box className={classes.dragContainer} >
                <Box className={classes.drag} >

                    <label htmlFor="upload">
                        <div style={{ width: '100px', height: '100px', backgroundColor: '#DBDCDD', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                            <AddAPhotoIcon color="primary" />
                            <sup style={{ color: 'red' }}>*</sup>
                            {/* <p style={{margin:5}}>upload</p> */}
                        </div>
                    </label>
                    <input id="upload" type="file" accept="image/png, image/jpeg" onChange={e => collectImages(storagePlace, e)} style={{ display: 'none' }} />
                </Box>
            </Box>

        </Grid>
    )
}

export default ImageUploader