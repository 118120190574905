import { FirebaseApi } from "../../../firebaseConfig";
import { CRUDoperations } from "../../CRUD/crud";

const creatorCollections = {
    create: async (data) => {
        try {
            const newCollection = await CRUDoperations.create('creatorCollections', data);
            return newCollection
        }
        catch (err) {
            return "No creatorCollections found";
        }
    },
    createWithUserId: async (creatorId, data) => {
        console.log(creatorId, data)

        const result = await CRUDoperations.create("creatorCollections", {
            creatorId: creatorId,
            type: 'Collection',
            values: data,



        })
        console.log("Added new collection");
        return result

    },
    fetch: async () => {
        try {
            const newData = await FirebaseApi['creatorCollections'].get();

            const collections = newData.docs.map(collection => ({
                ...collection.data(), id: collection.id
            }))




            return collections;

        } catch (err) {

        }
    },
    fetchByCreatorId: async (userId) => {
        try {
            const data = await FirebaseApi.creatorCollections.where('creatorId', '==', `${userId}`).get();
            const final = data.docs.map(collections => ({
                ...collections.data(), id: collections.id
            }))

            return final;


        } catch (err) {

        }
    },

    fetchByCollectionId: async (collectionId) => {

        try {
            const result = await FirebaseApi.creatorCollections.doc(collectionId).get();

            if (result.data()) {
                return { id: collectionId, ...result.data() }
            }
        } catch (err) {
            console.log(err);
        }
    },
    delete: async (docId) => {
        try {
            const deleting = await CRUDoperations.delete("creatorCollections", docId)
            return 'done'

        }
        catch (err) {
            throw err

        }

    },
    update: async (docId, data) => {

        try {
            const updating = await CRUDoperations.update("creatorCollections", docId, data)
            return updating

        } catch (err) {
            return err;
        }
    },
    fetchLatest: async () => {
        try {
            const getAllCollections = await FirebaseApi['creatorCollections'].orderBy("datePublished", "desc").get()

            const allCollections = getAllCollections.docs.map(collection => ({ ...collection.data(), id: collection.id }))
            return allCollections
        } catch (error) {
            throw error
        }
    },

}

export default creatorCollections;