import { CRUDoperations } from "../CRUD/crud";


const allergies = {
    create: async (data) => {
        try {
            const result = await CRUDoperations.create('allergies', data);
            return result
        } catch (err) {
            console.log(err);
        }
    },
    read: async () => {
        try {
            const data = await CRUDoperations.read('allergies');
            return data;
        } catch (err) {
            console.log(err);
        }
    },
    update: async (docID, data) => {
        try {
            const updatedData = await CRUDoperations.update('allergies', docID, data);
            return updatedData;

        } catch (err) {
            console.log(err);
        }
    },
    delete: async (docID) => {
        try {
            const deleting = await CRUDoperations.delete('allergies', docID);
            return deleting;

        } catch (err) {
            console.log(err);
        }
    }

}


export default allergies;