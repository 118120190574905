import { FirebaseApi } from '../../firebaseConfig'





const searchFilter = {
    ascending: (value, startAt) => ascending(value, startAt),
    descending: (value, startAt) => descending(value, startAt),
}

const ascending = (value, startAt = 0) => {
    return `.orderBy("${value}").startAt(${startAt})`
}

const descending = (value, startAt = 0) => {
    return `.orderBy("${value}", "desc")`
}



export const CRUDoperations = {
    create: async (collectionName, value) => {
        // value format 
        // {name:'',description:'',type:'',unit:''}

        try {
            const addingToActionFirestore = await FirebaseApi[collectionName].add(value)

            return 'Added successfully'

        }
        catch (err) {
            throw err

        }

    },
    read: async (collectionName) => {
        try {
            const gettingData = await FirebaseApi[collectionName].get()
            // const data = gettingData.docs.map(doc => doc.data())
            // const data = gettingData.docs.filter(doc => doc.data().name == 'RandomTest')
            // console.log(data)
            // return { id: data[0].id, data: data[0].data() }
            const data = gettingData.docs.map(doc => {
                const id = doc.id

                return { id, ...doc.data() }
            })
            return data
        }
        catch (err) {
            throw err

        }

    },
    getCollectionWithId: async (collectionName, docId) => {
        try {
            const gettingData = await FirebaseApi[collectionName].doc(docId).get()
            if (gettingData.data()) {
                return { ...gettingData.data(), id: gettingData.id };
            }
            else {
                return {}
            }

        } catch (error) {
            throw error
        }

    },
    update: async (collectionName, docId, updatedData) => {
        // updatedData format 
        // {name:'',description:'',type:'',unit:''}
        try {
            const updating = await FirebaseApi[collectionName].doc(docId).update(updatedData)

            return "Updated Successfully"


        }
        catch (err) {
            throw err
        }

    },
    delete: async (collectionName, docId) => {
        try {
            const deleting = await FirebaseApi[collectionName].doc(docId).delete()
            return "Deleted Successfully"

        }
        catch (err) {
            throw err

        }

    },
    search: async (collectionName, options) => {
        try {

            let queryString = `FirebaseApi["${collectionName}"]`
            options.keyword && options.keyword.length > 0 && options.keyword.map(async (key) => {
                const typeofSearching = typeof key[2]
                if (typeofSearching !== 'string') {

                    queryString += `.where("${key[0]}","${key[1]}", ${JSON.stringify(key[2])})`
                }
                else {
                    queryString += `.where("${key[0]}","${key[1]}", "${key[2]}")`

                }
            })
            if (options.order) {

                const orderBy = searchFilter[options.order](options.orderBy, options.startAt)

                queryString += orderBy || `.orderBy("recipe.name")`
            }

            queryString += options.limit ? `.limit(${options.limit})` : `.limit(15)`
            queryString += ".get()"


            const result = new Function("FirebaseApi", `return ${queryString} `)
            const collectionData = await result(FirebaseApi)
            const map = collectionData.docs.map(doc => {
                const id = doc.id
                return { id, ...doc.data() }
            })

            return map

        }
        catch (err) {

            throw err

        }
    },
    suggestSearch: async (collectionName, options) => {
        try {
            let queryString = `FirebaseApi["${collectionName}"]`
            queryString += `.orderBy("name","asc")`
            if (options.startAt) {
                queryString += `.startAt("${options.startAt}").endAt("${options.startAt}")`
            }
            queryString += options.limit ? `.limit(${options.limit})` : `.limit(15)`
            queryString += ".get()"

            const result = new Function("FirebaseApi", `return ${queryString} `)
            const collectionData = await result(FirebaseApi)
            const map = collectionData.docs.map(doc => {
                const id = doc.id
                return { id, ...doc.data() }
            })

            return map

        } catch (error) {
            throw error
        }


    }


}