import React, { useEffect, useContext, useState } from 'react'
import { Typography, Box, Grid, TextField, Button } from '@mui/material';
import AppContext from '../../../config/AppContext';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Avatar from '@mui/material/Avatar';
import { Link } from 'react-router-dom'

const HeaderNavbar = (props) => {

  return (
    <Box style={{ width: '100%', height: 80, backgroundColor: 'white', border: '1px solid #707070' }}>
      <Grid container style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', padding: 10, alignItems: 'center' }}>
        <Grid item style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Link to={props.text == 'Dashboard' ? './' : '/creator'} style={{ textDecoration: 'none' }}>
            <KeyboardBackspaceIcon />
          </Link>
          <Typography style={{ marginLeft: 20 }}>{props.text}</Typography>
        </Grid>
        <Avatar alt="profileImage" style={{ width: 47, height: 47, borderRadius: 50 }} />
      </Grid>
    </Box>
  )
}

export default HeaderNavbar