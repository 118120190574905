import { Grid,Container, Typography, TextField, Button } from '@mui/material'
import React, { useContext, useEffect,useState } from 'react'
import CloudServices from '../../cloud-services-core'
import AppContext from '../../config/AppContext'

export default function ResetPassword(props) {
    console.log(props)
    const [email,setEmail] = useState({
      loading:false,
      id:'',
      error:''
    })

    const { loading:emailLoading, id, error:emailError } = email;

    const [password,setPassword] = useState('');
    const [confirmPassword,setConfirmPassword] = useState('');

    const [reset,setReset] = useState({
      loading:false,
      result:null,
      error:''
    })

    const { loading:resetLoading, result, error:ResetError } = reset

    useEffect(()=>{
      getUserEmail()
    },[])

    const getUserEmail = async()=>{
      setEmail((prevData)=>({...prevData,loading:true}))
      try {
        const email = await CloudServices.user.verifyResetPasswordCode(props.location?.state?.oobCode);
        console.log(email);
        setEmail((prevData)=>({...prevData,loading:false,id:email}))
      
      } catch (error) {
        setEmail((prevData)=>({...prevData,loading:false,error:error.code}))
        console.log(error)
      }
    }
    
    const resetPassword = async()=>{
        try {
          if(password===confirmPassword){
            console.log('matched')
            const result = await CloudServices.user.resetPassword(props.location?.state?.oobCode,password)
            console.log(result)
            setReset((prevData)=>({...prevData,result}))
          }
          else{
            throw new Error("password doesn't match")
          }
        } catch (error) {
          console.log(error)
        }
      }
    
  return (
    <Container maxWidth="xl">

    <Grid container direction={'row'} justifyContent={"center"}  height="60vh" >
      <Grid item container direction={'column'} justifyContent="center" alignItems={"center"}>
        <Typography>Changing password for </Typography>
        {emailLoading ? <Typography>loading....</Typography> : 
        <Typography>{id}</Typography>
        }
        <br/>
        {emailError && (
          <Grid>
            <Typography color={'red'}>code expired , request reset again</Typography>
         
          </Grid>
          )}
      </Grid>
      <Grid container item direction={'column'} justifyContent="space-evenly" alignItems="center" >
        {result==='success'  ? (
          <Typography color="green">Changed Successfully</Typography>
        ) : (
          <>
          <Grid item>
            
          <TextField type={'password'} inputProps={{
        maxLength: 15,
      }} label='password' value={password} onChange={(e)=>setPassword(e.target.value)} />
          </Grid>
          <Grid item>
  
          <TextField type={'password'}  inputProps={{
        maxLength: 15,
      }} label="confirm password" value={confirmPassword} onChange={(e)=>setConfirmPassword(e.target.value)} />
          </Grid>
          <Button variant='contained' disabled={emailError} onClick={resetPassword} >Reset</Button>
          </>
        )}
      </Grid>
      </Grid>
    </Container>
  )
}
