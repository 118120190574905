import React, { useState, useEffect, useContext } from 'react'
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid'
import { FirebaseApi } from '../../../firebaseConfig'
import { Typography, Box } from '@mui/material'
import Grid from '@mui/material/Grid'
import { styled, useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom'
import Entities from '..'
import AppContext from '../../../config/AppContext'


const DataGrids = styled(MuiDataGrid)({

})


const CookingActionTable = (props) => {
    const AppState = useContext(AppContext);
    const { currentEntityData, setCurrentEntityData, setAddedEntity, close, addedEntity } = props;
    const [currentIngredient, setCurrentIngredient] = useState('');
    const [rowData, setRowData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [loading, setLoading] = useState(false)
    const [rowsLength, setRowsLength] = useState([]);

    const currentEntity = 'cookingMethod'
    const currentEntityCategory = 'pure'

    const column = [
        {
            field: "imageLink", headerName: "Image", width: 250,
            renderCell: (props) => {
                // console.log(props, props.row.image?.[0])
                return (
                    <div style={{ display: 'flex', flexDirection: 'column', width: 100, height: 100 }} >
                        {props.row ? (

                            <img src={props?.row?.imageLink} alt="entityImage" style={{ width: '100%', height: '100%' }} />
                        ) : (
                            <img src={props?.row?.imageLink} alt="entityImage" style={{ width: '100%', height: '100%' }} />
                        )}
                    </div>
                )
            }
        },
        { field: "name", headerName: "Name", width: 250 },
    ]

    const a = 10;
    const b = 10;
    console.log({ a }, { b })

    const getData = async () => {
        setLoading(true)
        try {
            const data = await FirebaseApi["cookAction"].get()
            const parsedData = data.docs.map((item) => {
                return {
                    id: item.id,
                    ...item.data()
                }
                // return item
            })
            console.log(parsedData)
            setRowData(parsedData)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)

        }
    }

    const selectARow = (row) => {
        setCurrentIngredient(row?.row)
        console.log(row);
        AppState.handleCurrentEntityData(row?.row)
        AppState.handleCurrentEntityType(currentEntity)
        // setCurrentEntityData(row?.row)
    }
    const handleRowsSelected = (value) => {
        setRowsLength(value)
    }

    useEffect(() => {
        getData()
    }, [currentEntityData])
    

    // return loading ? (
    //     <div>Loading..</div>
    // ) : <>
    // <Entities />
    // <Box style={{ height: '90vh', width: '100%' }}>
    //     <DataGrids
    //         hideFooter
    //         checkboxSelection
    //         columns={column}
    //         rows={rowData}
    //         onRowClick={(details) => selectARow(details)}
    //     />
    // </Box>
    // </>

    return (
        <>
            <Entities Entity={currentEntity} currentEntityData={currentEntityData} currentEntityCategory={currentEntityCategory} rowsCount={rowsLength} />
            {loading ? <div>Loading..</div> :
                <Box style={{ height: '90vh', width: '100%' }}>
                    <DataGrids
                        hideFooter
                        columns={column}
                        rows={rowData}
                        checkboxSelection
                        onRowClick={(details) => selectARow(details)}
                        onSelectionModelChange={(details) => handleRowsSelected(details)}
                    />
                </Box>}

        </>


    )


}

export default CookingActionTable