import React,{useContext,useState} from 'react'
import AddRecipeContext from '../../../../config/AddRecipeContext'
import StepDetailContext from '../../../../config/StepDetailContext'
import {Grid,Typography,Box,Chip,Button,Checkbox} from '@mui/material'
import {makeStyles} from '@mui/styles'


//  icons
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseIcon from '@mui/icons-material/Close';


import {Switch} from '../../../../components/Switch'


import Ramen from '../../../../assets/ramen@2x.png'



const useStyles = makeStyles({
    stepDuration:{
        flex:1,
        zIndex:3,
    },
    stepDurationUnit:{
        
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
       '&[data-selected=true]':{
           color:'#F94C0C'
       }
        // '&[data-durationUnit=true]':{
        //     border:'2px solid #F94C0C',
        //     borderRadius:7
        // }
    },
    stepTemperature:{
        flex:1,
        transform: "scale(1)",
        border:"none",
        zIndex:3,
        // borderRadius:8,
        // border: "1px solid gray",
        // '&[data-temperature=true]':{
        //     transform:"scale(1.4)",
        //     // padding:20,
        //     backgroundColor: 'white',
        //     border: "1px solid #F94C0C",
        //     borderRadius:8
        // },
        
        
    },
    
    stepTemperatureElement:{
        transition:'transform .2s ease-in-out',
        zIndex:3,
        "&[data-selected=true]":{
            color:'#F94C0C'
        }
       
        
    },
})

const RenderStepDetails = (props) =>{

    const classes = useStyles()
    
    const {stepDetailonFocus} = useContext(StepDetailContext)
    const {formik,proMode,images,setUploadImages} = useContext(AddRecipeContext)
    const [temperatureLeftValue,setTemperatureLeftValue] = useState(0)
    const [durationLeftValue,setDurationLeftValue] = useState(0)
    const [proDurationLeftValue,setProDurationLeftValue] = useState(0)
    const [leftValue,setLeftValue] = useState(0)

    const [derivedIngredientName,setDerivedIngredientName] = useState("")


    const [showRemoveIngredientFromStep,setShowRemoveIngredientFromStep] = useState(false)
    
    const [ingredientHovered,setIngredientHovered] = useState('')
    const {values,setFieldValue,handleChange,setValues} = formik
    
    const changeStepTemperature = (value,increase) =>{
        let parameters =  values.recipeInstructions[stepDetailonFocus?.sectionIndex].itemListElement[stepDetailonFocus?.stepIndex]?.parameters

        parameters['temperature'] = value
     
        
       setValues(values)
       setLeftValue(increase * 25)
      
    }
    const changeStepDurationUnit = (unit,increase)=>{
        let parameters =  values.recipeInstructions[stepDetailonFocus?.sectionIndex].itemListElement[stepDetailonFocus?.stepIndex]?.parameters
        
    
        parameters.duration.unit = unit

        setValues(values)
        setDurationLeftValue( increase * 33.3)
     
    }

    const changeOptional=(e,item,recipeIngredientIndex)=>{

        const updatedValues = values.recipeIngredient.map((ingredient)=>{
            if(ingredient.id==recipeIngredientIndex){
                ingredient['optional'] = e.target.checked
              
                return ingredient
            }
            return ingredient
        })
       
        setFieldValue('recipeIngredient',updatedValues)
        // const currentSection = values.recipeInstructions[stepDetailonFocus.sectionIndex]
        // const newValues = currentSection.ingredients.map((ingredient,ingredientIndex)=>{
        //     console.log(ingredient.id,item.id)
        //     if(ingredient.id==item.id){
                
        //         ingredient['optional'] = e.target.checked
              
        //         return ingredient
        //     }
        //     return ingredient
            
        // })
        // setFieldValue(`values.recipeInstrucions[${stepDetailonFocus.sectionIndex}].ingredients`,newValues)
       
        // values.recipeInstructions[index].ingredients = newValues
        
    

    }



    const renderDerivedIngredients = () =>{

        let derived = values.recipeIngredient.filter(ingredient=>ingredient.derived==true)
        // let derived = values.recipeIngredient.map((ingredient,index)=>{
        //     if(ingredient.derived==true){
                
        //         return { 
        //             recipeIngredientIndex:index,
        //             ...ingredient
        //         }

        //     }
            
        // })
        
      



        // const derived = values.recipeInstructions[stepDetailonFocus?.sectionIndex].derivedIngredients
        const addToDerivedValue =(e,indexInIngredients) =>{
            setFieldValue(`recipeInstructions[${indexInIngredients}].quantity`,e.target.value)
        }
        const addDerivedIngredientToStepIngredientList = (e,item)=>{
            const stepIngredients =values.recipeInstructions[stepDetailonFocus.sectionIndex].itemListElement[stepDetailonFocus.stepIndex].ingredients
            if(e.target.checked){

                setFieldValue(`recipeInstructions[${stepDetailonFocus.sectionIndex}].itemListElement[${stepDetailonFocus.stepIndex}].ingredients`,[...stepIngredients,item])
            }
            else{
                const removedIngredient = stepIngredients.filter(ingredient=>ingredient.id != item.id)
                setFieldValue(`recipeInstructions[${stepDetailonFocus.sectionIndex}].itemListElement[${stepDetailonFocus.stepIndex}].ingredients`,removedIngredient)
            }
        }


        // const value = values.recipeInstructions[stepDetailonFocus.sectionIndex].itemListElement[stepDetailonFocus.stepIndex].ingredients
        // value.filter(ingredient)
        return(
            <div>
                <Typography>Derived</Typography>
                {derived.length > 0 &&derived.map((item,index)=>{
                    const val = values.recipeInstructions[stepDetailonFocus.sectionIndex].itemListElement[stepDetailonFocus.stepIndex].ingredients.filter(ingredient=>ingredient.name===item.name)
                  
                    return(
                    <div key={index} style={{display:'flex',flexDirection:'row',alignItems:'center',margin:"15px 0"}}>
                        <div style={{width:70,height:70,borderRadius:50,backgroundColor:'gray'}}>

                        </div>
                        <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'flex-start',marginLeft:10}}>
                            <Typography>{item.name}</Typography>
                            <Typography flexWrap="nowrap">{`section ${Number(item.sectionNumber) + 1}`}</Typography>
                            {/* <Typography>{item.brandName}</Typography> */}
                        </div>
                        <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-end',}}>
                                {/* <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-end'}}>
                                    <input  style={{width:'30%'}} onChange={(e)=>addToDerivedValue(e,item.recipeIngredientIndex)} />
                                    <Typography style={{marginLeft:10}}>g</Typography>
                                </div> */}
                                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <Typography>use ingredient</Typography>
                                 
                                    <Switch checked={val.length > 0}  onChange={(e)=>addDerivedIngredientToStepIngredientList(e,item)}  />
                                </div>
                            </div>
                    </div>
                )})}
            </div>
        )
    }

    const changeProStepDurationUnit = (parameters,unit,increase)=>{
        
        
        // {...parameters,pro:{duration:{unit:unit}}}
        parameters.duration.unit = unit
        


        setValues(values)
        // setFieldValue(`recipeInstructions[${stepDetailonFocus?.sectionIndex}].itemListElement[${stepDetailonFocus?.stepIndex}].parameters`,parameters)
        setProDurationLeftValue( increase * 33.3)



        
        
    }

    const changeDerivedOrigin = (e) =>{
        if(e.target.checked){
           
        }
        else{
            const removeDerived = values.recipeIngredient.filter(ingredient=>!(ingredient.sectionNumber ==stepDetailonFocus.sectionIndex && ingredient.stepNumber == stepDetailonFocus.stepIndex))
     
            setFieldValue('recipeIngredient',removeDerived)
        }
    }


    const selectImageForStep={
        'basic':(file,element)=>{
          
        
            setFieldValue(`recipeInstructions[${stepDetailonFocus?.sectionIndex}].itemListElement[${stepDetailonFocus?.stepIndex}].image`,file)
        
            // if(element['image']){
            //     setFieldValue(`recipeInstructions[${stepDetailonFocus?.sectionIndex}].itemListElement[${stepDetailonFocus?.stepIndex}].image`,"")
            // }
            // else{
            // }

        },
        'pro':(file,element)=>{
            setFieldValue(`recipeInstructionsPro[${stepDetailonFocus?.sectionIndex}].itemListElement[${stepDetailonFocus?.stepIndex}].image`,file)
            // if(element['image']){
            //     setFieldValue(`recipeInstructionsPro[${stepDetailonFocus?.sectionIndex}].itemListElement[${stepDetailonFocus?.stepIndex}].image`,"")
            // }
            // else{
            // }

        }
       

        

    }

    const removeImage = ( )=>{
        setFieldValue(`recipeInstructions[${stepDetailonFocus?.sectionIndex}].itemListElement[${stepDetailonFocus?.stepIndex}].image`,"")

    }


    const makeSection = () =>{
        const newValues  = [...values.recipeIngredient,{
            derived:true,
            name:derivedIngredientName,
            sectionNumber:stepDetailonFocus.sectionIndex,
            stepNumber:stepDetailonFocus.stepIndex,
            quantity:'',
            unit:'g',
            optional:false,
            preparationStyle:'',
            ingredientType:''
        }]
        setFieldValue('recipeIngredient',newValues)

    }


    const createDerivedIngredient = (e) => {
        // const derived = values.recipeIngredient.filter(ingredient=>ingredient.derived === true)
        

        if(e.key=='Enter'){
          makeSection()
          setDerivedIngredientName('')
          e.target.blur()
        }
    


    }

  
    const details ={
        parameters:(instructions)=>{
          
            let parameters = instructions ? instructions[stepDetailonFocus?.sectionIndex]?.itemListElement[stepDetailonFocus?.stepIndex]?.parameters : {}
            console.log({parameters})

            var leftVal;

            var durationVal;

          
            const changeLeftValue = (value) =>{
                switch(value){
                    case 'off':
                        leftVal = 0
                        break;
                    case 'low':
                        leftVal = 25
                        break;
                    case 'mid':
                        leftVal = 50
                        // setLeftValue(50)
                        break;
                    case 'high':
                        leftVal = 75
                        // setLeftValue(75)
                        break;
                }
            }

            const changeDurationValue = {
                'sec':()=>{

                    durationVal = 0
                },
                'min':()=>{
                    durationVal = 33.3
                },
                'hour':()=>{
                    durationVal = 66.6
                }
            }

           
            if(typeof parameters?.temperature === 'string'){

                changeLeftValue(parameters.temperature)
            }
            
            if(typeof parameters?.duration === 'object'){

                changeDurationValue[parameters.duration.unit]()
            }
            // if(proMode && values.recipeInstructionsPro ){
            //     console.log("inside pro and instructions pro")
            //     parameters = values.recipeInstructionsPro[stepDetailonFocus?.sectionIndex]?.itemListElement[stepDetailonFocus?.stepIndex]?.parameters
            // }
            // else{
            //     parameters = values.recipeInstructions[stepDetailonFocus?.sectionIndex]?.itemListElement[stepDetailonFocus?.stepIndex]?.parameters
            // }
           
            
          
          
            
        
           
            
            

            // console.log(parameters?.pro?.duration?.unit,parameters?.pro?.duration?.unit==='min')


            // const changeAccordingToValueType = {
            //     'object':(e,param,value,sub)=> setFieldValue(`recipeInstructions[${stepDetailonFocus?.sectionIndex}].itemListElement[${stepDetailonFocus?.stepIndex}].parameters.pro[${param}][${sub}]`,e.target.value),
            //     'string':(e,param,value)=>setFieldValue(`recipeInstructions[${stepDetailonFocus?.sectionIndex}].itemListElement[${stepDetailonFocus?.stepIndex}].parameters.pro[${param}]`,e.target.value),
            //     'boolean':(e,param,value)=>{
            //         console.log({checked:e.target.checked,stirring:values.recipeInstructions[stepDetailonFocus?.sectionIndex].itemListElement[stepDetailonFocus?.stepIndex].parameters.pro[param]})
            //         values.recipeInstructions[stepDetailonFocus?.sectionIndex].itemListElement[stepDetailonFocus?.stepIndex].parameters.pro[param] = e.target.checked
            //         setFieldValue(`recipeInstructions[${stepDetailonFocus?.sectionIndex}].itemListElement[${stepDetailonFocus?.stepIndex}].parameters.pro[${param}]`,values.recipeInstructions[stepDetailonFocus?.sectionIndex].itemListElement[stepDetailonFocus?.stepIndex].parameters.pro[param])
            //     }
                
            // }

            const changeProValues = (e,param,value,sub)=>{
            //     console.log(typeof value,param)
            //     changeAccordingToValueType[typeof value](e,param,value,sub)
            //     // if(value){
            //     //     setFieldValue(`recipeInstructions[${stepDetailonFocus?.sectionIndex}].itemListElement[${stepDetailonFocus?.stepIndex}].parameters.pro.[${param}][${value}]`,e.target.value)

            //     // }
            //     // else{
            //     //     setFieldValue(`recipeInstructions[${stepDetailonFocus?.sectionIndex}].itemListElement[${stepDetailonFocus?.stepIndex}].parameters.pro.[${param}]`,e.target.value)
            //     // }
            //     console.log(values.recipeInstructions[stepDetailonFocus?.sectionIndex].itemListElement[stepDetailonFocus?.stepIndex].parameters.pro)
            }

            const changeStirring = (e)=>{
             
                values.recipeInstructionsPro[stepDetailonFocus?.sectionIndex].itemListElement[stepDetailonFocus?.stepIndex].parameters.stirring = e.target.checked
                setFieldValue(`recipeInstructionsPro[${stepDetailonFocus?.sectionIndex}].itemListElement[${stepDetailonFocus?.stepIndex}].parameters.stirring`,values.recipeInstructionsPro[stepDetailonFocus?.sectionIndex].itemListElement[stepDetailonFocus?.stepIndex].parameters.stirring)
               
            }


            const changeTemperatureUnit = (e,increment,value)=>{
                setFieldValue(`recipeInstructionsPro[${stepDetailonFocus?.sectionIndex}].itemListElement[${stepDetailonFocus?.stepIndex}].parameters.temperature.unit`,value)
                setTemperatureLeftValue(increment * 50)
            }


        
            return parameters && (
                <Box style={{padding:"10px 0"}}>

            
                <Grid container direction="column" alignItems="space-around" justifyContent="space-around"  spacing={2}  >
                    {proMode ? (
                        values.recipeInstructionsPro ?(
                        <Grid item xs={12} container spacing={1}>
                            <Grid item lg={5} xs={12} container>
                                <Grid item xs={12}>
                                    <Typography>Temperature</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <input style={{width:'100%'}} type="number" name={`recipeInstructionsPro[${stepDetailonFocus?.sectionIndex}].itemListElement[${stepDetailonFocus?.stepIndex}].parameters.temperature.value`} value={parameters?.temperature?.value} onChange={handleChange} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Box style={{position:'relative'}} >
                                        <Grid  container direction="row">
                                            <Grid item style={{flex:1,zIndex:3}} onClick={(e)=>changeTemperatureUnit(e,0,'c')}>
                                                <Typography textAlign="center" >˚C</Typography>
                                            </Grid>
                                            <Grid item style={{flex:1,zIndex:3}} onClick={(e)=>changeTemperatureUnit(e,1,'f')}>
                                                <Typography textAlign="center" >˚F</Typography>
                                            </Grid>
                                        </Grid>
                                        <Box style={{position:'absolute',top:0,left:`${temperatureLeftValue}%`,border:"1px solid #F94C0C",width:'50%',height:'100%',transition:'.2s ease-in-out',borderRadius:8,zIndex:2,backgroundColor:'white'}} />

                                    

                                    </Box>
                                </Grid>

                            </Grid>
                            <Grid item lg={7} xs={12} container>
                                <Grid item xs={12}>
                                    <Typography>Duration</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <input 
                                        style={{width:'100%'}} 
                                        type="number" 
                                        name={`recipeInstructionsPro[${stepDetailonFocus?.sectionIndex}].itemListElement[${stepDetailonFocus?.stepIndex}].parameters.duration.time`} 
                                        value={parameters?.duration?.time} 
                                        onChange={handleChange} 
                                        />
                                </Grid>
                                <Grid item xs={7}>
                                    <Box style={{position:'relative'}}>

                                    <Grid  container direction="row" alignItems="center" border="1px solid #707070" borderRadius={2}>
                                                
                                                <Grid className={classes.stepDuration} item onClick={()=>changeProStepDurationUnit(parameters,'sec',0)}>
                                                    <Typography data-durationunit={parameters?.duration?.unit=='sec'} data-selected={durationVal===0} className={classes.stepDurationUnit}>sec</Typography>
                                                </Grid>
                                                <Grid className={classes.stepDuration} item onClick={()=> changeProStepDurationUnit(parameters,'min',1)}>
                                                    <Typography data-durationunit={parameters?.duration?.unit=='min'} data-selected={durationVal===33.3} className={classes.stepDurationUnit}>min</Typography>
                                                </Grid>
                                                <Grid className={classes.stepDuration} item onClick={()=> changeProStepDurationUnit(parameters,'hour',2)}> 
                                                    <Typography data-durationunit={parameters?.duration?.unit=='hour'} data-selected={durationVal===66.6} className={classes.stepDurationUnit}>hour</Typography>
                                                </Grid>
                                    </Grid>
                                    <Box style={{position:'absolute',top:0,left:`${durationVal}%`,border:"1px solid #F94C0C",width:'33.3%',height:'100%',transition:'.2s ease-in-out',borderRadius:8,zIndex:2,backgroundColor:'white'}}>

                                    </Box>
                                    </Box>
                                            
                                </Grid>

                            </Grid>
                            <Grid item xs={6} container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography>Power</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <input style={{width:'100%'}} name={`recipeInstructionsPro[${stepDetailonFocus?.sectionIndex}].itemListElement[${stepDetailonFocus?.stepIndex}].parameters.power.value`} value={parameters?.power?.value} onChange={handleChange} />
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography>Watt</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography>Cooking Action</Typography>
                                </Grid>
                                <Grid item xs={12} >
                                <select value={parameters.cookingAction}  style={{width:'100%',padding:10}} name={`recipeInstructionsPro[${stepDetailonFocus?.sectionIndex}].itemListElement[${stepDetailonFocus?.stepIndex}].parameters.cookingAction`} onChange={handleChange}>
                                    <option >Select Action</option>
                                    <option value="heat" >Heat</option>
                                    <option value="dispense" >Dispense</option>
                                    <option value="info" >Info</option>
                                    <option value="dispenseInfo" >Dispense Info</option>
                                    <option value="stirring" >Stirring</option>
                                </select>

                                </Grid>
                            </Grid>
                            <Grid item xs={12} container>
                                <Grid item xs={5}>
                                    <Typography>Stirring</Typography>
                                </Grid>
                                <Grid item xs={7} container justifyContent="flex-end">
                                    <Grid item >
                                        <Typography>On</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Switch name={`recipeInstructionsPro[${stepDetailonFocus?.sectionIndex}].itemListElement[${stepDetailonFocus?.stepIndex}].parameters.stirring`} checked={parameters?.stirring}  onChange={handleChange} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ):(
                        <Grid item>
                            <Typography>Generate pro template instructions</Typography>
                        </Grid>
                    )) : 
                    (
                        <Grid item xs={12} container spacing={1}>
                        
                            <Grid item container spacing={1}>
                                <Grid item >
                                    <Typography>Temperature</Typography>
                                </Grid>
                                <Grid item xs={12} >
                                    <Box style={{position:'relative'}}>

                                    
                                        <Grid   
                                            container 
                                            direction="row" 
                                            alignItems="center"
                                            border="1px solid black"
                                            borderRadius={1}
                                            borderColor="#707070"
                                        >
                                            <Grid  item  data-temperature={parameters.temperature ? true : false}    className={classes.stepTemperature} onClick={()=>changeStepTemperature('off',0)}  >
                                                <Typography  textAlign="center"   data-selected={leftVal===0}  className={classes.stepTemperatureElement} >OFF</Typography>
                                            </Grid>
                                            <Grid item  data-temperature={parameters.temperature  ? true : false}    className={classes.stepTemperature} onClick={()=>changeStepTemperature('low',1)}>
                                                <Typography  textAlign="center"  data-selected={leftVal===25}  className={classes.stepTemperatureElement}>LOW</Typography>
                                            </Grid>
                                            <Grid item  data-temperature={parameters.temperature  ? true : false}    className={classes.stepTemperature} onClick={()=>changeStepTemperature('mid',2)}>
                                                <Typography  textAlign="center"  data-selected={leftVal===50} className={classes.stepTemperatureElement} >MID</Typography>
                                            </Grid>
                                            <Grid item   data-temperature={parameters.temperature  ? true : false}    className={classes.stepTemperature} onClick={()=>changeStepTemperature('high',3)}>
                                                <Typography   textAlign="center"  data-selected={leftVal===75} className={classes.stepTemperatureElement}>HIGH</Typography>
                                            </Grid>
                                        </Grid>

                                        <Box   style={{position:'absolute',top:-7,left:`${leftVal}%`,height:40,width:'25%',border:"1px solid #F94C0C",transition:'.3s ease-in-out',zIndex:2,borderRadius:8,backgroundColor:'white'}}>

                                        </Box>
                                    </Box>
                                </Grid>
                        
                            </Grid>
                        
                            <Grid item xs={12} container  spacing={1} >
                                <Grid item>
                                    <Typography>Duration(optional)</Typography>
                                </Grid>
                                <Grid item xs={12} container spacing={2} >
                                    <Grid item xs={4}>
                                        <input type="text" onChange={handleChange} value={parameters.duration.time} name={`recipeInstructions[${stepDetailonFocus?.sectionIndex}].itemListElement[${stepDetailonFocus?.stepIndex}].parameters.duration.time`} style={{width:'100%'}} />
                                    </Grid>
                                    <Grid item xs={6} >
                                        <Box style={{position:'relative'}} >

                                            <Grid container direction="row" alignItems="center" border="1px solid #707070" borderRadius={2}>

                                                <Grid className={classes.stepDuration} item onClick={()=>changeStepDurationUnit('sec',0)}>
                                                    <Typography data-durationunit={parameters.duration.unit=='sec'} data-selected={durationVal===0} className={classes.stepDurationUnit}>sec</Typography>
                                                </Grid>
                                                <Grid className={classes.stepDuration} item onClick={()=> changeStepDurationUnit('min',1)}>
                                                    <Typography data-durationunit={parameters.duration.unit=='min'} data-selected={durationVal===33.3} className={classes.stepDurationUnit}>min</Typography>
                                                </Grid>
                                                <Grid className={classes.stepDuration} item onClick={()=> changeStepDurationUnit('hour',2)}> 
                                                    <Typography data-durationunit={parameters.duration.unit=='hour'} data-selected={durationVal===66.6} className={classes.stepDurationUnit}>hour</Typography>
                                                </Grid>
                                            </Grid>
                                            <Box style={{position:'absolute',top:0,left:`${durationVal}%`,border:"1px solid #F94C0C",width:'33.3%',height:'100%',transition:'.2s ease-in-out',borderRadius:8,zIndex:2,backgroundColor:'white'}}>

                                            </Box>
                                        </Box>
                                    </Grid>

                                </Grid>
                            </Grid>
                            

                        </Grid>
                    )}
                
                    {/* <Grid item xs={12} container >
                        <Grid item>
                            <Typography>TOOLS USED</Typography>
                        </Grid>
                        <Grid item xs={12}>
                        

                            <Grid container alignItems="center" border="1px solid #B9B9B9" borderRadius={1}>
                                <Grid item xs={2} container direction="row" justifyContent="center" alignItems="center">
                                    <Grid item>

                                    <SearchOutlinedIcon />
                                    </Grid>
                                </Grid>
                                <Grid item xs={10}>
                                    <input type="text" style={{border:'none',outline:'none',width:'100%'}}  />
                                </Grid>
                            </Grid>
                            
                            
                            
                        </Grid>
                        <Grid item xs={12} container direction="row" alignItems="center" flexWrap="wrap">
                            {['Tool name one','Two','Three','Four'].map((item,index)=>(
                                <Grid item key={index}>
                                    <Chip label={item} />
                                </Grid>
                            ))}
                            
                        </Grid>
                    </Grid> */}
                </Grid> 
                
                </Box>
            )

        },
        
        ingredients:(instructions)=>{
       
            // console.log(values.recipeInstructions[stepDetailonFocus.sectionIndex]?.itemListElement[stepDetailonFocus.stepIndex]?.ingredients)
            const derived = values.recipeIngredient.filter(ingredient=>ingredient.derived===true)

            let checked = false;
            if(derived.length > 0){

                derived.map(ingredient=>{
                    
                    if(ingredient.sectionNumber===stepDetailonFocus.sectionIndex && ingredient.stepNumber === stepDetailonFocus.stepIndex){
                        checked =  true
                    }
                })
            }

            const changeIngredientQuantity = (e,recipeIngredientIndex)=>{
                const updatedRecipeIngredients = values.recipeIngredient.map((ingredient)=>{
                    if(ingredient.id===recipeIngredientIndex){
                        ingredient['quantity'] = e.target.value
                        return ingredient
                    }
                    return ingredient
                })

                
                setFieldValue('recipeIngredient',updatedRecipeIngredients)
            }

            let ingredients = instructions ? instructions[stepDetailonFocus?.sectionIndex]?.itemListElement[stepDetailonFocus?.stepIndex]?.ingredients : []
          


            const removeIngredientFromStep = (id) =>{
                if(proMode){
                    const newStepDetailIngredients = values.recipeInstructionsPro[stepDetailonFocus?.sectionIndex].itemListElement[stepDetailonFocus.stepIndex].ingredients.filter(ingredient=>ingredient !== ingredientHovered)
                    console.log(newStepDetailIngredients)
                    setFieldValue(`recipeInstructionsPro[${stepDetailonFocus?.sectionIndex}].itemListElement[${stepDetailonFocus.stepIndex}].ingredients`,newStepDetailIngredients)
                }
                else{

                    const newStepDetailIngredients = values.recipeInstructions[stepDetailonFocus?.sectionIndex].itemListElement[stepDetailonFocus.stepIndex].ingredients.filter(ingredient=>ingredient !== ingredientHovered)
                    console.log(newStepDetailIngredients)
                    setFieldValue(`recipeInstructions[${stepDetailonFocus?.sectionIndex}].itemListElement[${stepDetailonFocus.stepIndex}].ingredients`,newStepDetailIngredients)
                }
            }
        
    
            return(
                <div>
                    {ingredients.length > 0 && ingredients.map((ingredient,index)=>{

                        const stepIngredients = values.recipeIngredient.filter(recipeIngredient=>{
                           
                            return recipeIngredient.id == ingredient
                        })[0]
 
                        return (
                    
                        
                        <div key={index} style={{display:'flex',flexDirection:'row',alignItems:'center',margin:"15px 0"}}>
                            <div style={{position:'relative',width:70,height:70,borderRadius:50,backgroundColor:'gray',transform:'.5 linear'}} 
                            onMouseEnter={()=>(setIngredientHovered(stepIngredients.id),setShowRemoveIngredientFromStep(true))} 
                            onMouseLeave={()=>setShowRemoveIngredientFromStep(false)}>
                                {showRemoveIngredientFromStep && ingredientHovered===stepIngredients.id &&(
                                    <div style={{position:'absolute',background:'rgba(0,0,0,0.5)',width:'100%',height:'100%',borderRadius:'50%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',transform:'.5 linear'}}>
                                        <p style={{color:'white',fontSize:32,cursor:'pointer'}} onClick={()=>removeIngredientFromStep()}>X</p>

                                    </div>
                                )} 

                            </div>
                            <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'flex-start',marginLeft:10}}>
                                <Typography>{stepIngredients.ingredient}</Typography>
                                <Typography>{stepIngredients.preparationStyle}</Typography>
                                <Typography>{stepIngredients.brandName}</Typography>
                            </div>
                            <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-end',}}>
                                <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-end'}}>
                                    <input 
                                        type="text"
                                        value={stepIngredients.quantity} 
                                        
                                        onChange={(e)=>changeIngredientQuantity(e,ingredient)}  
                                        style={{width:'30%'}} 
                                    />
                                    <Typography style={{marginLeft:10}}>{stepIngredients.unit}</Typography>
                                </div>
                                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <Typography>optional ?</Typography>
                                
                                    <Switch checked={stepIngredients.optional} onChange={(e)=>changeOptional(e,stepIngredients,ingredient)}    />
                                </div>
                            </div>

                        </div>
                    )})}
                    {/* {values.recipeInstructions[stepDetailonFocus?.sectionIndex].derivedIngredients.length > 0 && renderDerivedIngredients()} */}
                    {renderDerivedIngredients()}
                    <Grid container spacing={1} direction="row" alignItems="center">
                        <Grid item xs={7} container direction="row" alignItems="center">
                                <Checkbox checked={checked} onChange={changeDerivedOrigin} />
                                <Typography>Derived Ingredient</Typography>
                            

                        </Grid>
                        <Grid item  xs={5} >
                            
                
                                <input style={{width:'100%'}} type="text"  value={derivedIngredientName} onKeyPress={createDerivedIngredient} onChange={(e)=>setDerivedIngredientName(e.target.value)} />

                        
                            {/* <Grid item xs={6}>
                                <Button size="small" variant="contained" onClick={makeSection} >Add</Button>
                            </Grid> */}
                        </Grid>
                    </Grid>
                </div>
            )

        },
    
        images:(instructions)=>{
           
            if(!instructions){
                return null
            }
            const element = instructions[stepDetailonFocus?.sectionIndex]?.itemListElement[stepDetailonFocus?.stepIndex]
            
            return (
                <>
            
                {values.images?.length < 1 ? (
                <Grid container direction="column" justifyContent="space-around" alignItems="center" style={{height:'100%'}} >
                    <Grid item >
                        <Box style={{width:153,height:153}}>

                        <img src={Ramen} style={{width:'100%',height:'100%'}} />
                        </Box>
                    </Grid>
                    <Grid item > 
                        <Box >
                            <Button onClick={()=>setUploadImages(true)}>Add Step Images</Button>
                        </Box>
                    </Grid>
                </Grid>

                ) : (
                    <Grid container spacing={1}   paddingTop={2}  className={classes.stepImages}>
                        
                        {[...Object.keys(values?.images)].map((file,index)=>{
                            
                            return(
                                <Grid item xs={4} key={index} >
                                    <Box style={{position:'relative'}}> 
                                        <Grid container direction="row" alignItems="center" flexWrap="wrap">
                                            <Grid item key={file.id}   onClick={()=>selectImageForStep[proMode ? 'pro' : 'basic'](file,element)}  >
                                                <img src={values.images[file]} style={{width:100,height:100,borderRadius:8,border:element.image===file ? '2px solid #F94C0C' : 'none'}} />
                                            </Grid>
                                        </Grid>
                                        {element.image===file && (
                                            <Box style={{position:'absolute',top:5,right:20}}>
                                                <Box style={{display:'flex',flexDirection:'row',justifyItems:'center',alignItems:'center',backgroundColor:'rgba(0,0,0,0.5)',borderRadius:'50%',padding:5}}>
                                                    <CloseIcon onClick={removeImage}  style={{fontSize:16,color:'white'}}/>

                                                </Box>

                                            </Box>

                                        )}
                                    </Box>
                                </Grid>
                        )})}
                        <Grid item xs={12}> 
                            <Box >
                                <Button onClick={()=>setUploadImages(true)}>Add Step Images</Button>
                            </Box>
                        </Grid>
                    </Grid>
                )}

            </>
            )

        }
    }

    
    let instructions = values[proMode ? 'recipeInstructionsPro' : 'recipeInstructions']

    return  (
        <>
        {details[props.detailsOf](instructions)}
        </>
    )
}

export default RenderStepDetails