import { Autocomplete, Grid, TextField } from '@mui/material';
import { FieldArray, FormikProvider } from 'formik';
import React, { useEffect, useState } from 'react';
import MuiButton from '../../../../components/MuiButton';
import { useStyles } from './Styles';
import DeleteAccordion from '../../../../assets/DeleteAccordion.svg';
import { FirebaseApi } from '../../../../firebaseConfig';

const IngredientsTable = ({formik}) => {
    const classes= useStyles();
    const { values, setFieldValue } = formik;
    const [ingredients, setIngredients] = useState([]);
    const [prepStyles, setPrepStyles] = useState([]);
    const [units, setUnits] = useState([]);

    const getIngredientsList = async () => {
      const ingredientFromDB = await FirebaseApi["ingredient"].get();
      const ingredientData = ingredientFromDB.docs.map((item) => {
        return {
          id: item.id,
          ...item.data()
        }
      })
      setIngredients(ingredientData);
    }

    const getPrepStylesList = async () => {
      const prepStylesFromDB = await FirebaseApi["prepStyle"].get();
      const prepStylesData = prepStylesFromDB.docs.map((item) => {
        return {
          id: item.id,
          ...item.data()
        }
      })
      setPrepStyles(prepStylesData);
    }
    
    const getUnitsList = async () => {
      const unitsFromDB = await FirebaseApi["units"].get();
      const unitsData = unitsFromDB.docs.map((item) => {
        return {
          id: item.id,
          ...item.data()
        }
      })
      setUnits(unitsData);
    }

    useEffect(() => {
      getIngredientsList();
      getPrepStylesList();
      getUnitsList();
    }, []);

    return (
    <FormikProvider value={formik}>
    <FieldArray name="recipeIngredient">
        {(recipeIngredientProps)=> {
            const { push, remove } = recipeIngredientProps;
            return (
              <>
                {values?.recipeIngredient?.length > 0 && values?.recipeIngredient?.map((item, index)=> {
                  return (
                    <>
                      <Grid item container direction='row' key={index}>
                        <Grid item className={classes.ingredientTab}>
                          <Autocomplete 
                            name={`item[${index}].ingredient`}
                            id='ingredient'
                            options={ingredients}
                            getOptionLabel={(option) => (option.name || option.data || "")}
                            freeSolo
                            inputValue={(item.ingredient) || ""}
                            onInputChange={(e, value) => {
                              setFieldValue(`recipeIngredient[${index}].ingredient`, value);
                            }}
                            size="small"
                            renderInput={(params)=> (
                              <TextField 
                                {...params} 
                                placeholder="Ingredients Name"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item className={classes.quantityTab}>
                          <TextField 
                            id='quantity'
                            name={`item[${index}].quantity`}
                            type='text'
                            autoComplete='off' 
                            fullWidth 
                            style={{ padding: 5 }} 
                            InputProps={{ style: { height: 30 }}}
                            value={(item.quantity) || ''}
                            onChange={(e, value) => {
                              setFieldValue(`recipeIngredient[${index}].quantity`, e.target.value);
                            }}
                            placeholder="Quantity"
                          />
                        </Grid>
                        <Grid item className={classes.unitsTab}>
                          <Autocomplete 
                            name={`item[${index}].unit`}
                            id="unit"
                            options={units}
                            getOptionLabel={(option) => (option.name || "")}
                            size="small"
                            freeSolo
                            inputValue={(item.unitt) || (item.name) || (item.unit) || ""}
                            onInputChange={(e, value) => {
                              setFieldValue(`recipeIngredient[${index}].unit`, value)
                            }}
                            renderInput={(params) => (
                              <TextField 
                                {...params} 
                                placeholder="Units"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item className={classes.prepstylesTab}>
                          <Autocomplete 
                            name={`item[${index}].prepStyle`}
                            id="prepStyle"
                            options={prepStyles}
                            getOptionLabel={(option) => (option.name || "")}
                            size="small"
                            freeSolo
                            onInputChange={(e, value) => {
                              setFieldValue(`recipeIngredient[${index}].prepStyle`, value)
                            }}
                            inputValue={(item.prepStyle)|| (item.name) || ""}
                            renderInput={(params) => (
                              <TextField 
                                {...params} 
                                placeholder="Prep Styles"
                              />
                            )}
                          />
                        </Grid>
                          <Grid item onClick={() => remove(index)} className={classes.closeTab}>
                            <img src={DeleteAccordion} style={{ height: 35, marginLeft: 2, marginTop: 6 }} alt="delete" />
                          </Grid>
                      </Grid>
                    </>
                  )
                })}
                <Grid item container>
                  <MuiButton 
                    title='Add Ingredient Row' 
                    click={() => push({ ingredient: '', units: '', quantity: '', prepStyle: ''})}
                    style={{ width: 1238 }}
                  />
                </Grid>
              </>
            )
        }}
    </FieldArray>
    </FormikProvider>
  )
}

export default IngredientsTable
