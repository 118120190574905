import { Card, CardActionArea, CardContent } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom';
import { RecipeNameOrArticleName, RecipeNameOrArticleNameCardMedia, RecipeOrArticleBadge } from './Styles'

const RecipeCard = () => {
    const url = 'https://images.unsplash.com/photo-1542866789-bb9c9d086a5d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80';
    const id='';
    const collectionType='';

  return (
    <Card sx={{ width: '150px', height: '200px' }}>
        {/* condition from fetch data card for props. url="" */}
        <RecipeNameOrArticleNameCardMedia image={url}>
            {/* condition from fetch data card for props. to="" */}
            <CardActionArea component={Link} to={`/createRecipe/0`}>
            <CardContent>
                <RecipeOrArticleBadge>
                    Recipe 
                    {/* condition from fetch data card for props. */}
                </RecipeOrArticleBadge>
                <RecipeNameOrArticleName>
                    {/* condition from fetch data card for props. */}
                    Characters has to fix
                </RecipeNameOrArticleName>
            </CardContent>
            </CardActionArea>
        </RecipeNameOrArticleNameCardMedia>
    </Card>
  )
}

export default RecipeCard