import { CRUDoperations } from '../CRUD/crud'



const ingredientType = {
    create: async (value) => {
        try {
            const result = await CRUDoperations.create("ingredientType", value)
            return result


        }
        catch (err) {

        }
    },
    read: async () => {
        try {
            const Data = await CRUDoperations.read("ingredientType")
            console.log(Data)
            return Data
        }
        catch (err) {
            throw err

        }

    },
    update: async (docId, updatedData) => {

        try {
            const updating = await CRUDoperations.update("ingredientType", docId, updatedData)
            return updating


        }
        catch (err) {
            throw err

        }

    },
    delete: async (docId) => {
        try {
            const deleting = await CRUDoperations.delete("ingredientType", docId)
            return deleting

        }
        catch (err) {
            throw err

        }

    }
}

export default ingredientType;