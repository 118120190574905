import { Grid } from '@mui/material'
import React from 'react'
import HeaderNavbar from './HeaderNavbar'

const Test = () => {
  return (
   <Grid>
       {/* <Grid style={{width:'20vw',borderRight:'1 px'}}>

       </Grid>   */}
       <HeaderNavbar text='Edit membership'/>
   </Grid>
  )
}

export default Test