import { FirebaseApi } from "../../../firebaseConfig";
import { CRUDoperations } from "../../CRUD/crud";


const creatorSubscribers = {
    create: async (userId, data) => {
        try {
            const newSubscriber = await CRUDoperations.create('creatorSubscribers', userId, data);
            return newSubscriber
        }
        catch (err) {
            return "No subscribers found";
        }
    },

    createWithId: async (creatorId, subscriberId, value) => {
        console.log(creatorId, subscriberId, value)

        const result = await CRUDoperations.create("creatorSubscribers", {
            creatorId,
            subscriberId,
            type: 'subscriber',
            subscription: value.subscription,
            level: value.level,
            activity: value.activity


        })
        console.log("Added subscribers ");
        return result


    },
    fetch: async () => {
        try {
            const getSubscribers = await FirebaseApi['creatorSubscribers'].get();
            const subs = getSubscribers.docs.map(subs => ({
                ...subs.data(), id: subs.id
            }))




            return subs;

        } catch (err) {

        }
    },
    fetchByUserId: async (userId) => {
        try {
            const data = await FirebaseApi.creatorSubscribers.where('creatorId', '==', `${userId}`).get();
            const final = data.docs.map(subscribers => ({
                ...subscribers.data(), id: subscribers.id
            }))

            return final;


        } catch (err) {

        }
    },
    delete: async (docId) => {
        try {
            const deleting = await CRUDoperations.delete("creatorSubscribers", docId)
            return 'done'

        }
        catch (err) {
            throw err

        }

    },
    update: async (docId, data) => {

        try {
            const updating = await CRUDoperations.update("creatorSubscribers", docId, data)
            return updating

        } catch (err) {
            return err;
        }
    },

}

export default creatorSubscribers;