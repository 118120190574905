import React from 'react'
import { Grid, MenuItem, Select, Typography } from '@mui/material'
import { ButtonCreatorPage } from '../Styles'
import { useState } from 'react'

export const RecipePublishedSortBy = () => {
  const [sortBy, setSortBy] = useState(7);

  return (
    <Grid container spacing={2} style={{ marginTop: 90 }}>
        <Grid item>
            SortBy
        </Grid>
        <Grid item>
            <Select value={sortBy} onChange={(e) => setSortBy(e.target.value)} variant='standard' InputProps={{ disableUnderline: true }}>
              <MenuItem value={7}>Last Week</MenuItem>
              <MenuItem value={15}>Last 15 days</MenuItem>
              <MenuItem value={30}>Last Month</MenuItem>
              <MenuItem value={90}>Last 3 Months</MenuItem>
              <MenuItem value={365}>Current financial year</MenuItem>
            </Select>
        </Grid>
    </Grid>
  )
}

export const SubscribersJoinedManageMemberShipLevels = () => {
  return (
    <Grid container style={{ marginTop: 100 }}>
      <ButtonCreatorPage to="/memberShipLevels">
        <Typography>
          Manage MemberShip Levels
        </Typography>
      </ButtonCreatorPage>
    </Grid>
  )
}

export const EarnedSubscriptionInPrevMonth = () => {
  return (
    <Grid container style={{ marginTop: 100 }}>
      <ButtonCreatorPage to="">
        <Typography>
          Set up your payout method
        </Typography>
      </ButtonCreatorPage>
    </Grid>
  )
}
