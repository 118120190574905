import React from 'react';
import { Grid, TextField, Typography } from '@mui/material'
import ImageReplace from '../../../assets/ImageReplace.jpg';


const DetailsEditor = ({ formik }) => {
  
  const { values, setFieldValue, } = formik;
  
  function getBase64(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    })
  }

  const collectImagesWithIndex = async (e, sectionIndex = 0, stepIndex = 0) => {
        console.log(sectionIndex, stepIndex)

        var imageObject = {};
        if (values.profileImage && [...Object.keys(values.profileImage)].length >= 0) {
            await Promise.all([...Array(e.target.files.length).keys()].map(async (item, index) => {
                const data = await getBase64(e.target.files[item])
                imageObject[`img-${sectionIndex}-${stepIndex}-${[...Object.keys(values.profileImage)].length + index}`] = data
            }));
        }
        else {
            await Promise.all([...Array(e.target.files.length).keys()].map(async (item, index) => {
                const data = await getBase64(e.target.files[item])
                imageObject[`img-${sectionIndex}-${stepIndex}-${index}`] = data
            }));
        }
        setFieldValue('profileImage', [imageObject['img-0-0-0']]);
    }

    return (
      <Grid container style={{ margin : '10px auto', width: '80%'}}>
        <Grid item>
            <Typography>Cover Image</Typography>
            <label htmlFor='upload'>
              <img src={!values.profileImage[0] ? (ImageReplace) : values.profileImage[0]} style={{ height: 127, width: 240, borderRadius: 4 }} alt='' />
              <input id="upload" type="file" accept="image/png, image/jpeg" style={{ display: 'none' }} onChange={e => collectImagesWithIndex(e)} />
            </label>
        </Grid>
        <Grid item>
          <Typography>Display Name</Typography>
          <TextField placeholder='What does your audience call you' onChange={(e) => setFieldValue('creatorName', e.target.value)} value={values.creatorName} size='small' style={{ width: 240 }} />
        </Grid>
        <Grid item>
          <Typography>Bio</Typography>
          <TextField placeholder='Share about your journey here' onChange={(e) => setFieldValue('description', e.target.value)} value={values.description} size='small' multiline rows={4} style={{ width: 240 }} />
        </Grid>
        <Grid item>
          <Typography>Button</Typography>
          <TextField placeholder='Display text' onChange={(e) => setFieldValue('button', e.target.value)} value={values.button} size='small' style={{ width: 240 }} />
        </Grid>
      </Grid>
    )
  }

export default DetailsEditor