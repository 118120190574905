import React from 'react';
import { Grid, Typography } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done';
import { MemberShipLevels, UnderLine, UpdateMembershipLevelTypograpghy } from './Styles';
import { MembershipValues } from './MemberShipValues';

const UpdateMembershipLevel = () => {
  return (
    <Grid container justifyContent='center' marginTop={-4}>
        {MembershipValues.map((item) => {
            return (
                <MemberShipLevels item>
                    <Grid item container direction='column' rowSpacing={2} marginTop={2}>
                        <Grid item>
                            <Typography variant='h6' style={{ fontWeight: 600 }}>{item.title}</Typography>
                            <UnderLine />
                        </Grid>
                        <Grid item>
                            <img src='' alt='' style={{ width: 57, height: 57, border: '1px solid black' }} />
                        </Grid>
                        <Grid item>
                            <Typography>
                                <sup style={{ marginBottom: 10 }}>₹</sup>
                                <strong style={{ fontSize: 30 }}>{item.price}</strong>
                                <small>/month</small>
                            </Typography>
                        </Grid>
                        <Grid item container rowSpacing={-1}>
                            {item.points.map((itemPoints) => {
                                return (
                                    <Grid item>
                                        <UpdateMembershipLevelTypograpghy>
                                            <i>
                                                <DoneIcon style={{ color: '#5821BD', marginRight: '5px' }} />
                                            </i>
                                            {itemPoints}
                                        </UpdateMembershipLevelTypograpghy>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                </MemberShipLevels>
            )
        })}
    </Grid>
  )
}

export default UpdateMembershipLevel