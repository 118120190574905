import React, { useState, useEffect, useContext } from 'react'
import { Grid, Box, Modal, Typography, TextField, Button } from '@mui/material'
import AddRecipeContext from '../../../config/AddRecipeContext'
import { FirebaseApi } from '../../../firebaseConfig'
import Fuse from 'fuse.js'
import IngredientChip from '../../../components/AddRecipe/IngredientChip'
import CupUpdate from '../../../components/CupUpdate'
import { parse } from 'recipe-ingredient-parser-v2'


export default function IngredientCheck(props) {
    const { formik, proMode, initialIngredientsLoad, setInitialIngredientsLoad } = useContext(AddRecipeContext)

    const [ingredientsLoading, setIngredientsLoading] = useState(false)
    const [ingredientList, setIngredientList] = useState([])
    const [fuse, setFuse] = useState(null)

    const [editAndReplaceIngredient, setEditAndReplaceIngredient] = useState(false)
    const [editIngredientDetails, setEditIngredientDetails] = useState("")
    const [suggestedIngredients, setSuggestedIngredients] = useState([])
    const [addIngredientModal, setAddIngredientModal] = useState(false)

    const [newIngredientSuggestions, setNewIngredientSuggestions] = useState([])

    const [unMatchedIngredients, setUnMatchedIngredients] = useState([])
    const [addingIngredient, setAddingIngredient] = useState('')

    const { values, setFieldValue, setValues } = formik

    const prepStyle = new Fuse(["stemmed", "finely chopped", "grated", "ground", "minced", "heaped", "Heaped", "roasted", "shredded", "sliced", "toasted", "boiled", "Boiled", "Crumbled", "crumbled", "crushed", "Crushed", "cubbed", "Cubbed", "seeded", "whipped", "trimmed", "drained", "peeled", "rinse", "soak",])

    useEffect(() => {
        // if(initialIngredientsLoad){


        getIngredientsList()
        // setInitialIngredientsLoad(false)
        // }
    }, [])

    const getIngredientsList = async () => {
        setIngredientsLoading(true)
        try {
            const ingredientsFromDB = await FirebaseApi["ingredient"].get()

            const ingredientsData = ingredientsFromDB.docs.map((ingredient) => {
                return {
                    id: ingredient.id,
                    ingredient: ingredient.data().name,
                    ...ingredient.data()
                }
            })


            setIngredientList(ingredientsData)
            const fuseInstance = new Fuse(ingredientsData, {
                useExtendedSearch: true,
                keys: ["name"]
            })
            setFuse(fuseInstance)

            checkRecipeIngredientsInOurDB['exactMatch'](fuseInstance)
        } catch (error) {
            console.log(error)
        }
    }




    const checkForPrepStyle = (string) => {
        const data = ["minced", "diced", "stemmed", "finely chopped", "chopped", "grated", "ground", "heaped", "Heaped", "roasted", "shredded", "sliced", "toasted", "boiled", "Boiled", "Crumbled", "crumbled", "crushed", "Crushed", "cubbed", "Cubbed", "seeded", "whipped", "trimmed", "drained", "peeled", "rinse", "soak",]
            .filter(item => {

                const ref = new RegExp(item, 'i')
                console.log(ref.test(string))
                if (ref.test(string)) return item

            })
        console.log(data)
        return data


    }
    const checkRecipeIngredientsInOurDB = {
        exactMatch: (fuse) => {
            var ingredients = []
            var unMatched = []
            values.recipeIngredient.map((ingredient => {
                if (ingredient.matchedToDB) {
                    ingredients.push(ingredient)

                }
                else {
                    console.log(ingredient)



                    const foundIngredient = checkForExactIngredient(fuse, ingredient.ingredient)
                    // console.log(foundIngredient)


                    if (foundIngredient.length > 0) {
                        var newIngredient = foundIngredient[0]

                        const prep = checkForPrepStyle(ingredient.ingredient)


                        ingredients.push({
                            ...newIngredient.item,
                            ingredient: newIngredient.item.ingredient,
                            matchedToDB: true,
                            quantity: ingredient.quantity,
                            unit: ingredient.unit,
                            prepStyle: prep.length > 0 ? prep[0] : ''
                        });


                    }
                    else if (!values.recipeIngredientUnMatched) {
                        unMatched.push({
                            ...ingredient,
                            searchable: ingredient.ingredient.replace(/[^a-zA-Z ]/g, "").trim(),
                            ingredient: ingredient.ingredient.replace(/[^a-zA-Z ]/g, "").trim(),
                            matchedToDB: false,

                        })

                    }
                }
                // fuse?.search(ingredient.ingredient)
            }))
            console.log(ingredients)
            console.log(unMatched)


            if (!values.recipeIngredientUnMatched && unMatched.length > 0) {

                setFieldValue('recipeIngredientUnMatched', unMatched)
            }

            setFieldValue('recipeIngredientOriginal', values.recipeIngredient)
            setFieldValue('recipeIngredient', ingredients)
            setIngredientsLoading(false)

        },
        similarMatch: (fuse) => {
            var ingredients = []
            values.recipeIngredient.map((ingredient => {
                const foundIngredients = checkForIngredientOrSimilarIngredients(fuse, ingredient.ingredient)

                ingredients = foundIngredients
                // fuse?.search(ingredient.ingredient)
            }))
            console.log(ingredients)

        }


    }



    const checkForIngredientOrSimilarIngredients = (fuse, name) => {

        return fuse.search(`'${name.replace(/[^a-zA-Z ]/g, "").trim()}`)
    }
    const checkForExactIngredient = (fuse, name) => {

        return fuse.search(`="${name.replace(/[^a-zA-Z ]/g, "").trim()}"`)
    }


    const editIngredient = (ingredient) => {
        setEditAndReplaceIngredient(true)
        setEditIngredientDetails(ingredient)
        // console.log(checkForIngredientOrSimilarIngredients(fuse,ingredient.ingredient))
        setSuggestedIngredients(checkForIngredientOrSimilarIngredients(fuse, ingredient.ingredient))
    }


    const editSuggestedValue = (e) => {
        setEditIngredientDetails({ ...editIngredientDetails, searchable: e.target.value })
        setSuggestedIngredients(checkForIngredientOrSimilarIngredients(fuse, e.target.value))

    }

    const addingNewIngredient = (e) => {
        setAddingIngredient(e.target.value)
        setNewIngredientSuggestions(checkForIngredientOrSimilarIngredients(fuse, e.target.value))
    }

    const saveToIngredients = async (data) => {
        try {
            await FirebaseApi['ingredient'].add(data)
            getIngredientsList()
            setAddIngredientModal(false)
        } catch (error) {
            console.log(error)

        }
    }


    const addToIngredients = (item) => {
        const prep = checkForPrepStyle(editIngredientDetails.ingredient)


        const newIngredients = [
            ...values.recipeIngredient,
            {
                ...item,
                quantity: editIngredientDetails.quantity,
                unit: editIngredientDetails.unit,
                matchedToDB: true,
                prepStyle: prep.length > 0 ? prep[0] : ''
            }
        ]

        setFieldValue('recipeIngredient', newIngredients)
        if (editIngredientDetails) {

            const newUnMatched = values.recipeIngredientUnMatched.filter(ingredient => ingredient.id !== editIngredientDetails.id)

            setUnMatchedIngredients(newUnMatched)
            setFieldValue('recipeIngredientUnMatched', newUnMatched)
        }
        setEditAndReplaceIngredient(false)
    }


    const removeFromIngredients = (item) => {
        const newIngredients = values.recipeIngredient.filter(ingredient => ingredient.id !== item.id)
        console.log(newIngredients)
        setFieldValue('recipeIngredient', newIngredients)
    }

    return ingredientsLoading ? (
        <div>
            <p>Loading...</p>
        </div>
    ) : (
        <Grid container spacing={3}>
            <Grid item container>
                <Grid item xs={12}>
                    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography>Add Ingredients </Typography>
                        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                            <TextField value={addingIngredient} onChange={addingNewIngredient} />
                            <Button onClick={() => setAddIngredientModal(true)} >Add new </Button>
                        </Box>
                        <Box style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>
                            {newIngredientSuggestions.length > 0 && newIngredientSuggestions.map(({ item }) => (
                                <IngredientChip key={item.id} image={item?.imageUrl ? item.imageUrl : ''} name={item.ingredient} shiftToIngredients={() => addToIngredients(item)} />
                            ))}
                        </Box>

                    </Box>
                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                        <Box style={{ flex: 1, margin: "0 20px" }} >
                            <p>Matched Ingredients</p>
                            {values.recipeIngredient.map((ingredient) => {

                                if (ingredient.matchedToDB) {
                                    return (
                                        <IngredientChip key={ingredient.id} image={ingredient?.imageUrl ? ingredient.imageUrl : ''} insideSection remove={() => removeFromIngredients(ingredient)} name={ingredient.ingredient} />
                                    )
                                }

                            })}
                        </Box>
                        <Box style={{ flex: 1, margin: "0 20px" }}>
                            <p>Un Matched Ingredients</p>
                            {values.recipeIngredientUnMatched?.length > 0 && values.recipeIngredientUnMatched.map((ingredient) => {


                                return (
                                    <div>

                                        <IngredientChip key={ingredient.id} image={ingredient?.imageUrl ? ingredient.imageUrl : ''} name={ingredient.ingredient} shiftToIngredients={() => editIngredient(ingredient)} />
                                    </div>
                                )


                            })}


                        </Box>
                        {/* {editAndReplaceIngredient && ( */}
                        <Box style={{ flex: 1, margin: "0 20px", visibility: editAndReplaceIngredient ? 'visible' : 'hidden', overflow: 'hidden', padding: 20, borderRadius: 20, boxShadow: "0px 0px 6px gray" }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div style={{ flex: 1 }}>

                                    <input value={editIngredientDetails.searchable} onChange={(e) => editSuggestedValue(e)} style={{ padding: 10, width: '90%', borderRadius: 10, outline: 'none' }} />
                                </div>
                                <p onClick={() => setEditAndReplaceIngredient(false)}>X</p>
                            </div>
                            <p>suggestions</p>
                            <div>

                                <p>did not find ingredient ? </p>
                                <button onClick={() => setAddIngredientModal(true)} >Add</button>
                            </div>
                            <div style={{ overflow: 'scroll', height: '46vh' }}>


                                {suggestedIngredients.length > 0 && suggestedIngredients.map(({ item }) => (
                                    <IngredientChip key={item.id} image={item?.imageUrl ? item.imageUrl : ''} name={item.ingredient} shiftToIngredients={() => addToIngredients(item)} />
                                ))}
                            </div>
                        </Box>
                        {/* )} */}
                        <Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item>
                </Grid>

            </Grid>
            <Modal
                open={addIngredientModal}
                onClose={() => setAddIngredientModal(false)}
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>

                <Box style={{ width: '60%', height: 'clamp(415px,60%,47vh)', backgroundColor: 'white', borderRadius: 6 }}>
                    <Typography >Enter details for adding ingredient</Typography>
                    <Box style={{ height: '100%', padding: 20 }}>

                        <CupUpdate initialValues={{ imageUrl: "", name: "", description: "" }} dataStructure={["imageUrl", 'name', "description"]} triggerAction={(data) => saveToIngredients(data)} />
                    </Box>
                </Box>



            </Modal>


        </Grid>
    )
}
