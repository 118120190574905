import { Grid, Button, Typography } from '@mui/material'
import React, { useEffect, useContext, useState } from 'react'
import CreatorNavbar from '../Common/CreatorNavbar'
import AppContext from '../../../config/AppContext'
import EditMemberLevelScreen from './EditMemberLevels'

const ManageLevelsScreen = () => {
    const [editLevels, setEditLevels] = useState(false);
    const AppState = useContext(AppContext);
    useEffect(() => {
        AppState.handleNavbar(false)
        return () => AppState.handleNavbar(true)
    }, [])

    const layout1 = () => {
        return (
            <Grid style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Grid style={{ width: 300, height: 400, border: '1px solid #A5A5A5', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', margin: 10 }}>
                    <Grid item style={{ width: 210, padding: 4, height: 310, display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'space-between' }} >
                        <Grid item style={{ height: '28%', width: '42%', background: '#A5A5A5', border: '1px solid #FFf', borderRadius: '50%', marginTop: 20, marginBottom: 35 }}>

                        </Grid>
                        <Grid item >
                            <Typography variant='h6'> Title of level: FREE </Typography>
                        </Grid>
                        <Grid item>
                            <Typography> Hey </Typography>
                        </Grid>
                    </Grid>
                    <Grid item style={{ height: 37, fontWeight: 600, background: '#EFEFEF', color: '#000', marginTop: 20, marginBottom: 20, paddingLeft: 10, paddingRight: 10 }}>
                        <Button variant='small' > Free </Button>


                    </Grid>
                </Grid>

                <Grid style={{ width: 300, height: 400, border: '1px solid #A5A5A5', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', margin: 10 }}>
                    <Grid item style={{ width: 210, padding: 4, height: 310, display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'space-between' }} >
                        <Grid item style={{ height: '28%', width: '42%', background: '#A5A5A5', border: '1px solid #FFf', borderRadius: '50%', marginTop: 20, marginBottom: 35 }}>

                        </Grid>
                        <Grid item >
                            <Typography variant='h6'> Title of level: Silver </Typography>
                        </Grid>
                        <Grid item>
                            <Typography> Hey </Typography>
                        </Grid>
                    </Grid>
                    <Grid item style={{ height: 37, fontWeight: 600, background: '#EFEFEF', color: '#000', marginTop: 20, marginBottom: 20, paddingLeft: 10, paddingRight: 10 }}>
                        <Button variant='small' > Free </Button>


                    </Grid>
                </Grid>

                <Grid style={{ width: 300, height: 400, border: '1px solid #A5A5A5', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', margin: 10 }}>
                    <Grid item style={{ width: 210, padding: 4, height: 310, display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'space-between' }} >
                        <Grid item style={{ height: '28%', width: '42%', background: '#A5A5A5', border: '1px solid #FFf', borderRadius: '50%', marginTop: 20, marginBottom: 35 }}>

                        </Grid>
                        <Grid item >
                            <Typography variant='h6'> Title of level: Platinum </Typography>
                        </Grid>
                        <Grid item>
                            <Typography> Hey </Typography>
                        </Grid>
                    </Grid>
                    <Grid item style={{ height: 37, fontWeight: 600, background: '#EFEFEF', color: '#000', marginTop: 20, marginBottom: 20, paddingLeft: 10, paddingRight: 10 }}>
                        <Button variant='small' > Free </Button>


                    </Grid>
                </Grid>
            </Grid>
        )
    }



    return (
        <Grid container marginTop={3} spacing={2}>
            <Grid item xs={2}>
                <CreatorNavbar />
            </Grid>
            <Grid item container xs={10}>
                <Grid item>
                    <Grid item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20 }} >
                        <Grid item >
                            <Typography variant='h5'>
                                Manage memberships levels
                            </Typography>
                            <Typography>
                                Description here
                            </Typography>
                        </Grid>
                        <Grid item style={{ height: 37, background: '#EFEFEF', color: '#000' }}>
                            <Button variant='small' onClick={() => setEditLevels(!editLevels)}>
                                {editLevels ? "Edit Levels" : "Save Levels"}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item>
                        {editLevels ? layout1() : <EditMemberLevelScreen />}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    )

}
export default ManageLevelsScreen;

