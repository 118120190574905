import React, { useState, useContext } from 'react'
import { Grid, Typography, Button, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import { FirebaseApi } from '../firebaseConfig';
import { CircularProgress } from '@material-ui/core';
import AppContext from '../config/AppContext';


const useStyles = makeStyles({
    RecipeCard__BoxContainer: {
        boxShadow: '2px 2px 10px #bcbcbc',
        width: 'max(25vh,300px)',
        borderRadius: 12
    },
    RecipeCard__InfoContainer: {
        borderLeft: '1px solid #BCBCBC',
        borderRight: '1px solid #BCBCBC'
    },
    RecipeCard__CardActions: {
        padding: "0 10px 5px 10px",
        border: '1px solid #BCBCBC',
        borderTop: 'none',
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12
    }
})

export default function RecipeCard(props) {
    const classes = useStyles(props)
    const [loading, setLoading] = useState(false)
    const AppState = useContext(AppContext)

    // const deleteRecipe = async () => {
    //     setLoading(true)
    //     try {
    //         const result = await FirebaseApi['trash'].doc(props.id).set(JSON.parse(JSON.stringify({ ...props.recipe, deletedOn: new Date() })))
    //         setLoading(false)
    //     }
    //     catch (err) {
    //         AppState.showSnackBar(err.message, 'error')
    //         setLoading(false)
    //     }
    // }
    const moveToTrash = async (collection) => {
        try {
            setLoading(true)
            await FirebaseApi[collection].doc(props.id).delete()
            await FirebaseApi['trash'].doc(props.id).set(JSON.parse(JSON.stringify({ ...props.recipe, movedToTrashOn: new Date(), from: collection })))
            AppState.showSnackBar('Moved to trash', 'error')
            setLoading(false)
            props.action()
        }
        catch (error) {
            AppState.showSnackBar(error.message, 'error')
            setLoading(false)
        }
    }

    const deletePermanently = async () => {
        try {
            setLoading(true)
            await FirebaseApi['trash'].doc(props.id).delete()
            setLoading(false)
            AppState.showSnackBar('deleted permanently', 'error')
            props.action()
        } catch (error) {
            AppState.showSnackBar(error.message, 'error')
            setLoading(false)
        }
    }

    const restoreToSameCollection = async () => {
        try {
            setLoading(true)
            console.log(props)
            await FirebaseApi[props.from].doc(props.id).set(JSON.parse(JSON.stringify({ ...props.recipe, restoredOn: new Date() })));
            await FirebaseApi['trash'].doc(props.id).delete()
            setLoading(false)
            AppState.showSnackBar('Moved to same collection', 'success')
            props.action()
        } catch (error) {
            console.log(error)
            AppState.showSnackBar(error.message, 'error')
            setLoading(false)
        }
    }

    const renderCollectionActions = {
        drafts: () => (
            <Grid item container direction="row" justifyContent="space-between" alignItems="center" >
                <Grid item>
                    <Button onClick={() => moveToTrash('drafts')}>move to trash</Button>
                </Grid>
                <Grid item >
                    <Link to={`/createRecipe/${props.id}?collection=drafts`} style={{ textDecoration: 'none' }}>
                        <Button >Edit</Button>
                    </Link>
                </Grid>
            </Grid>
        ),
        trash: () => (
            <Grid item container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Button onClick={deletePermanently} >Delete Permanently</Button>
                </Grid>
                <Grid item >
                    <Button onClick={restoreToSameCollection} >Restore</Button>
                </Grid>
            </Grid>
        ),
        recipe: () => (
            <Grid item container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Button onClick={() => moveToTrash('recipe')} >Delete</Button>
                </Grid>
                <Grid item >
                    <Link to={`/createRecipe/${props.id}?collection=recipe`} style={{ textDecoration: 'none' }}>
                        <Button>Edit</Button>
                    </Link>
                </Grid>
            </Grid>
        )
    }

    return loading ? (
        <Box style={{ width: 'max(25vh,300px)', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
        </Box>
    ) : (
        <Box className={classes.RecipeCard__BoxContainer}>
            <Grid container className={classes.RecipeCard__Container} direction="column"  >
                <Grid item>
                    <Link to={`/recipe/${props.id}?collection=${props.collectionType}`} className={classes.RecipeCard} style={{ textDecoration: 'none', color: 'black' }}>
                        <Grid container direction="column" >
                            <Grid item style={{ height: 'max(15vh,300px)' }} className={classes.RecipeCard__ImageContainer}>
                                <img src={props.image} className={classes.RecipeCard__Image} style={{ height: '100%', width: '100%', objectFit: 'cover', borderTopLeftRadius: 12, borderTopRightRadius: 12 }} alt='recipe total images' />
                            </Grid>
                            <Grid item container direction="row" justifyContent="flex-start" alignItems="center" className={classes.RecipeCard__InfoContainer} style={{ height: 'max(15vh,150px)', padding: 15, boxSizing: 'border-box' }}>
                                <Grid item container direction="column" justifyContent="space-between" className={classes.RecipeCard__InfoCenterItem} style={{ height: '100%' }}>
                                    <Grid item>
                                        <Typography style={{ fontSize: 18 }}>{props.meal?.length > 30 ? `${props.meal?.substring(0, 30)}...` : props.meal}</Typography>
                                    </Grid>
                                    <Grid item style={{ height: '5vh', minHeight: '5vh' }}>
                                        <Typography style={{ fontSize: 24, fontWeight: 'bold' }}>{props.name ? props.name.length > 20 ? `${props.name.substring(0, 18)}...` : props.name : ''}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography style={{ fontSize: 18, color: '#A7A7A7', fontStyle: 'italic' }}>Date of addition : {props.date}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Link>
                </Grid>
                {!props.hideActions && (
                    <Box className={classes.RecipeCard__CardActions}>
                        {renderCollectionActions[props.collectionType]()}
                    </Box>
                )}
            </Grid>
        </Box>
    )
}

RecipeCard.propTypes = {
    size: PropTypes.number,
    functionality: PropTypes.func,
    name: PropTypes.string,
    collection: PropTypes.string,
    drafts: PropTypes.bool,
    trash: PropTypes.bool,
    hideActions: PropTypes.bool
}

RecipeCard.defaultProps = {
    hideActions: false
}