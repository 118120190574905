import React, { useState, useEffect, useContext } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { FirebaseApi } from '../../firebaseConfig'
import { Box, Chip, Stack, Snackbar } from '@mui/material'
import { makeStyles } from "@material-ui/core/styles";
import EntityNavbar from './EntityNavbar'
import ImageDefault from '../../assets/ImageDefault.svg'
import { useHistory, useParams } from 'react-router-dom';
// import EntityProvider from './EntityProvider';
// import EntityContext from '../../config/EntityContext';

const useStyles = makeStyles(() => ({
    scrollBar: {
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none',
        }

    }
}));

export default function EntitiesTable(props) {
    const [rowData, setRowData] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentEntityData, setCurrentEntityData] = useState();

    const [selectedRows, setSelectedRows] = useState([]);
    const [rowsDeleted, setRowsDeleted] = useState(false);

    //For SnackBar
    const [snackbarVisible, setSnackBarVisible] = useState(null);
    const [snackBarMessage, setSnackBarMessage] = useState("Fetching data ...")
    const [actionInProgress, setActionInProgress] = useState(false);
    const history = useHistory();
    const { currentEntityCategory, currentEntity } = useParams();
    const classes = useStyles();

    useEffect(() => {
        getRows()

        return () => {
            setSelectedRows([])
            setRowsDeleted(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEntity, rowsDeleted])

    const toggleSnackbar = (message = "", visible = false, actionInProgress = false) => {
        setSnackBarMessage(message)
        setSnackBarVisible(visible)
        setActionInProgress(actionInProgress)
    }

    const handleSelectedRows = (rows) => {
        setSelectedRows(rows)
    }

    const generateEntityColumn = () => {
        switch (currentEntity) {
            // independent
            case "diets":
                return (
                    [
                        { field: "name", headerName: "Name", width: 250 },
                        {
                            field: "ingredientExcluded", headerName: "Ingredients Excluded", width: 700,
                            renderCell: (props) => {
                                return (
                                    <div>
                                        {props.row ? props.row.ingredientExcluded.length > 0 && props.row?.ingredientExcluded.map((item) => {
                                            return (
                                                <Stack direction="row" spacing={2}>
                                                    <Chip label={item.name ?? item.data} />
                                                </Stack>
                                            )
                                        }
                                        ) : ''}
                                    </div> 
                                )
                            }
                        },
                    ]
                )
            case "ingredient":
                return (
                    [
                        {
                            field: "imageUrl", headerName: 'Image', width: 100,
                            renderCell: (props) => {
                                return (
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "center", borderRadius: 10, border: "1px solid #707070", width: 50, height: 50 }} >
                                        {props.row.imageUrl && (
                                                <img src={props.row.imageUrl ?? ImageDefault} style={{ width: '90%', height: '90%', borderRadius: 5 }} alt="" />
                                            )
                                        }
                                    </div>
                                )
                            }

                        },
                        { field: "name", headerName: "Name", width: 170, 
                            valueGetter: (params) =>
                                `${params.row.name || params.row.data}`, 
                        },
                        { field: "categories", headerName: "Category", width: 250,
                            renderCell: (props)=> {
                                return (
                                    <div className={classes.scrollBar}>
                                        {props?.row ? props?.row?.categories?.length > 0 && props?.row?.categories?.map((item) => {
                                            return (
                                                <Stack direction="row" spacing={2}>
                                                    <Chip label={item.name} />
                                                </Stack>
                                            )
                                        }
                                        ) : ''}
                                    </div>
                                )
                            } 
                        },
                        { field: "dietsPreference", headerName: "Diet Preference(If Applicable)", width: 250,
                            renderCell: (props) => {
                                return (
                                    <div className={classes.scrollBar}>
                                        {props?.row ? props?.row?.dietsPreference?.length > 0 && props?.row?.dietsPreference?.map((item) => {
                                            return (
                                                <Stack direction="row" spacing={2}>
                                                    <Chip label={item.name} />
                                                </Stack>
                                            )
                                        }
                                        ) : ''}
                                    </div> 
                                )
                            }
                        },
                        { field: "medicalPreference", headerName: "Medical Preference(If Applicable)", width: 250, 
                            renderCell: (props) => {
                                return (
                                    <div className={classes.scrollBar}>
                                        {props?.row ? props?.row?.medicalPreference?.length > 0 && props?.row?.medicalPreference?.map((item) => {
                                            return (
                                                <Stack direction="row" spacing={2}>
                                                    <Chip label={item.name} />
                                                </Stack>
                                            )
                                        }
                                        ) : ''}
                                    </div> 
                                )
                            }
                        },
                        { field: "cuisines", headerName: "Cuisinse(If Applicable)", width: 250, 
                            renderCell: (props) => {
                                return (
                                    <div className={classes.scrollBar}>
                                        {props?.row ? props?.row?.cuisines?.length > 0 && props?.row?.cuisines?.map((item) => {
                                            return (
                                                <Stack direction="row" spacing={2}>
                                                    <Chip label={item.name} />
                                                </Stack>
                                            )
                                        }
                                        ) : ''}
                                    </div> 
                                )
                            }
                        },
                    ]
                )
            case "medical":
                return (
                    [
                        { field: "name", headerName: "Name", width: 250 },
                        {
                            field: "ingredientExcluded", headerName: 'Ingredients excluded', width: 300,
                            renderCell: (props) => {
                                return (
                                    <div className={classes.scrollBar} >
                                        {props.row ? props.row.ingredientExcluded.length > 0 && props.row.ingredientExcluded?.map((item) => {
                                            return (
                                                <Stack direction="row" spacing={2}>
                                                    <Chip label={item.name ?? item.data} />
                                                </Stack>
                                            )
                                        }

                                        ) : ''}
                                    </div>
                                )
                            }
                        },
                        {
                            field: 'allergies', headerName: 'Allergies', width: 300,
                            renderCell: (props) => {
                                return (
                                    <div className={classes.scrollBar} >
                                        {props.row.allergies ? 
                                            props?.row?.allergies?.length > 0 && props?.row?.allergies?.map((item) => {
                                            return (
                                                <Stack direction="row" spacing={2}>
                                                    <Chip label={item.name} />
                                                </Stack>
                                            )
                                        }
                                        ) : ''}
                                    </div>
                                )
                            }
                        }
                    ]
                )
            case "allergies":
                return ([
                    { field: "name", headerName: "Name", width: 250 },
                    {
                        field: "ingredientExcluded", headerName: "Ingredients Excluded", width: 700,
                        renderCell: (props) => {

                            return (
                                <div className={classes.scrollBar} >
                                    {props.row ? props.row.ingredientExcluded.length > 0 && props.row.ingredientExcluded?.map((item) => {
                                        return (
                                            <Stack direction="row" spacing={2}>
                                                <Chip label={(item.name ?? item.data)} />
                                            </Stack>
                                        )
                                    }

                                    ) : ''}
                                </div>
                            )
                        }
                    },
                ])
            case "cuisines":
                return ([
                    { field: "name", headerName: "Name", width: 700 },
                ])
            case "presets":
                return ([
                    { field: "name", headerName: "Name", width: 200 },
                    {
                        field: "cookingAction", headerName: "Cooking Action", width: 200,
                        renderCell: (props) => {

                            return (
                                <div className={classes.scrollBar} >
                                    {props.row ? props.row.cookingAction.length > 0 && props.row.cookingAction?.map((item) => {
                                        return (
                                            <Stack direction="row" spacing={2}>
                                                <Chip label={item.name} />
                                            </Stack>
                                        )
                                    }

                                    ) : ''}
                                </div>
                            )
                        }
                    },
                    { field: "temperature", headerName: "Temperature", width: 200 },
                    { field: "duration", headerName: "Duration", width: 200 }
                ])
            // independent
            case "ingredientCategory":
                return ([
                    {
                        field: "imageLink", headerName: "Image", width: 150,
                        renderCell: (props) => {
                            // console.log(props, props.row.image?.[0])
                            return (
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "center", width: 50, height: 50 }} >
                                    {props.row.imageUrl ? (

                                        <img src={props?.row?.imageLink} alt="" style={{ width: '90%', height: '90%', borderRadius: 5 }} />
                                    ) : (
                                        ''
                                    )}
                                </div>
                            )
                        }
                    },
                    { field: "name", headerName: "Name", width: 400 },
                ])
            // case 'ingredientType':
            //     return ([
            //         { field: 'imageUrl', headerName: 'Image', width: 200,
            //             renderCell: (props) => {
            //                 console.log(props.row);
            //                     return (
            //                         <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "center", width: 50, height: 50 }} >
            //                         {props.row.imageUrl ? (

            //                             <img src={props?.row?.imageUrl} alt="" style={{ width: '90%', height: '90%', borderRadius: 5 }} />
            //                         ) : (
            //                             ''
            //                         )}
            //                     </div> 
            //                     )
            //                 }
            //         },
            //         { field: "name", headerName: 'Name', width: 250 },
            //     ])
            case 'units':
                return ([
                    { field: "name", headerName: "Name", width: 150 },
                    { field: "shortHand", headerName: 'Short hand', width: 170 },
                ])
            case 'cookingMethod':
                return ([
                    { field: "name", headerName: "Name", width: 200 },
                    { field: "description", headerName: "Description", width: 350 },
                ]);
            case 'tools':
                return ([
                    {
                        field: "imageUrl", headerName: "Image", width: 150,
                        renderCell: (props) => {
                            return (
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "center", width: 50, height: 50 }} >
                                    {props.row.imageUrl ? (

                                        <img src={props?.row?.imageUrl} alt="" style={{ width: '90%', height: '90%', borderRadius: 5 }} />
                                    ) : (
                                        ''
                                    )}
                                </div>
                            )
                        }
                    },
                    { field: "name", headerName: "Name", width: 300 },
                ])
            case 'prepStyle':
                return ([
                    { field: "name", headerName: "Name", width: 200 },
                    { field: "desciption", headerName: "Description", width: 350 },
                ])
            case 'conversions':
                return ([
                    {
                        field: "sourceUnit", headerName: "Source Unit", width: 150,
                        renderCell: (props) => {
                            return (
                                <div style={{ display: 'flex', flexDirection: 'row', }} >
                                    {props.row ? props.row.sourceUnit.length > 0 && props.row.sourceUnit?.map((item) => {
                                        return (
                                            <Stack direction="row" spacing={2}>
                                                <Chip label={item.shortHand} />
                                            </Stack>
                                        )
                                    }) : ''}
                                </div>
                            )
                        }
                    },
                    {
                        field: "destinationUnit", headerName: 'Destination Unit', width: 250,
                        renderCell: (props) => {
                            return (
                                <div style={{ display: 'flex', flexDirection: 'row', }} >
                                    {props.row ? props.row.destinationUnit.length > 0 && props.row.destinationUnit?.map((item) => {
                                        return (
                                            <Stack direction="row" spacing={2}>
                                                <Chip label={item.shortHand} />
                                            </Stack>
                                        )
                                    }) : ''}
                                </div>
                            )
                        }
                    },
                    { field: "conversionFactor", headerName: 'Conversion factor', width: 250 },
                    { field: "unitType", headerName: 'Unit type', width: 250 },
                ])
            default:
        }
    };

    const getRows = async () => {
        setLoading(true)
        toggleSnackbar("Fetching Data ...", true, false);
        try {
            const data = await FirebaseApi[currentEntity].get()
            const parsedData = data.docs.map((item) => {
                return {
                    id: item.id,
                    ...item.data()
                }
            })
            setRowData(parsedData)
            setSnackBarVisible(false)
            toggleSnackbar("Updated Data ... ", true, false)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)

        }
    }

    const addRow = () => {
        history.push({
            pathname: `/entities/${currentEntityCategory}/${currentEntity}/add`
        })
    }

    const editRow = () => {
        let currentIngredientId = selectedRows[0]
        let currentIngredient = rowData.filter(r => r.id === currentIngredientId)[0]
        history.push({
            pathname: `/entities/${currentEntityCategory}/${currentEntity}/edit/${currentIngredient.id}`,
            state: currentIngredient
        })
    }

    const deleteRow = () => {
        // setAddedEntity(rowData)
        toggleSnackbar("Deleting Data ...", true, false);
        console.log(currentEntityData);
        // settimeout for 5 seconds to add.
        // if else statement to setTimeout to delete 
        var rowsToDelete = selectedRows;
        console.log(rowsToDelete);
        selectedRows.length > 0 && selectedRows.map((rowId) => {
            FirebaseApi[currentEntity].doc(rowId).delete().then(() => {
                console.log("Deleted :" + rowId);
                //history.go(0);
                setRowsDeleted(true);
            }).catch((e) => {
                // console.log("Failed to delete :" + row.name )
            })
        })
    }

    return (
        <>
            <EntityNavbar Entity={currentEntity} currentEntityCategory={currentEntityCategory} rowsCount={selectedRows} addEntity={addRow} editEntity={editRow} deleteEntity={deleteRow}></EntityNavbar>
            <Snackbar key={currentEntity} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={snackbarVisible} autoHideDuration={2000} onClose={() => toggleSnackbar("", false, false)} message={snackBarMessage} />
            {loading ? <div>Loading...</div> :
                <Box style={{ height: '85vh', width: '100%' }}>
                    <DataGrid
                        hideFooter
                        columns={generateEntityColumn()}
                        rows={rowData}
                        checkboxSelection
                        onSelectionModelChange={(details) => handleSelectedRows(details)}
                    />
                </Box>
            }
        </>
    )
}



// // steps to follow
// modal state in index like entities
// pass the values to required files
// using parent child pass props