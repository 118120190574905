import React, { Component, PureComponent } from 'react';
import {FirebaseApi} from '../../firebaseConfig';
import NewRow from '../Diets/cupdate';

import { DataGrid } from '@material-ui/data-grid';
import { Button, Divider, Grid, Paper, Snackbar, Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { ExpandMore } from '@material-ui/icons'
import AppContext from '../../config/AppContext';
import moment from 'moment'


import { unitMassConverter, unitVolumeConverter, conversion } from '../../utils/UnitConverter'

import { VictoryChart, VictoryStack, VictoryBar, VictoryContainer, VictoryLabel, VictoryScatter, VictoryAxis, VictoryLine, VictoryTooltip, VictoryVoronoiContainer, VictoryTheme } from "victory";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    ZAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList,
    Scatter,
    ScatterChart
} from "recharts";


import { CSVLink, CSVDownload } from "react-csv";

const header = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 150 },
    { field: "type", headerName: "Type", width: 150 },
    { field: "unit", headerName: "Units", width: 250 },
    { field: "description", headerName: "Description", width: 250 },
]


class CustomizedLabel extends PureComponent {
    render() {
        const { x, y, stroke, value } = this.props;

        return (
            <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
                {value}
            </text>
        );
    }
}
const CustomizedAxisTick = (props) => {
    const { x, y, payload } = props;

    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={0}
                dy={16}
                textAnchor="end"
                fill="#666"
                transform="rotate(-35)"
            >
                {payload.value}
            </text>
        </g>
    );
};


class Charts extends Component {
    static contextType = AppContext
    state = {
        openDialog: false,
        dataOf: "Chart",
        dataOfPlural: "Charts",
        rowData: [],
        selectedRows: [],
        snackbarVisible: true,
        snackBarMessage: "Fetching data ...",
        actionInProgress: false,
        lastFetchedTimeStamp: null,

        // recipes
        recipes: [],
        actions: [],
        actionsData: [],
        dishesAndTotalTime: [],
        dishesAndPrepTime: [],
        dishesAndCookTime: [],
        ingredientsFrequency: [],
        dishesAndNoOfSteps: [],
        oilItems: [],
        waterItems: [],

        recipeCusines: [],
        ingredientNumbers: [],

        noOfToolsRequired: [],

        toolFrequency: [],
        comboFrequency: [],

        cuisineTools: [],

        actionsInRecipes: [],

        toolsInRecipes: [],

        toolsStackInRecipes: [],

        spiceQuantity: [],

        recipeData: [],
        ingredients: [],
        csvAllIngredients:[]
    }
    
    componentDidMount() {
        this.fetchData()
        this.context.closeDrawer()
    }
    
    toggleDialog = (e) => {
        e.preventDefault();
        this.setState({ openDialog: !this.state.openDialog })
    }

    updateData = (updatedData) => {
        this.setState({ recipes: updatedData, snackbarVisibe: false })
    }

    toggleSnackbar = (message = "", visible = false, actionInProgress = false) => {
        console.log(message)
        this.setState({ snackBarMessage: message, snackbarVisible: visible, actionInProgress: actionInProgress })
    }

    fetchData = () => {
        this.toggleSnackbar("Fetching data ...", true, false);
        var recipes = []
        var actions = []
        var ingredients = []
        var tools = []
        FirebaseApi['recipe'].where("datePublished",">=","2022-01-01T06:19:01.595Z").get().then((snapshot) => {
            
            snapshot.forEach(function (childSnapshot) {
                var id = childSnapshot.id;
                var data = childSnapshot.data();
                //   newData.push({ id: id, name: data.name, description: data.description, synonyms: data.synonyms});
                recipes.push({ ...data, id: id });
            });

        });
        FirebaseApi['actions'].get().then((snapshot) => {
            
            snapshot.forEach(function (childSnapshot) {
                var id = childSnapshot.id;
                var data = childSnapshot.data();
                //   newData.push({ id: id, name: data.name, description: data.description, synonyms: data.synonyms});
                actions.push({ ...data, id: id });
            });

        });
        FirebaseApi['ingredient'].get().then((snapshot) => {
            
            snapshot.forEach(function (childSnapshot) {
                var id = childSnapshot.id;
                var data = childSnapshot.data();
                //   newData.push({ id: id, name: data.name, description: data.description, synonyms: data.synonyms});
                ingredients.push({ ...data, id: id });
            });

        });
        console.log(ingredients,"ingredients");
        FirebaseApi['tools'].get().then((snapshot) => {
            
            snapshot.forEach(function (childSnapshot) {
                var id = childSnapshot.id;
                var data = childSnapshot.data();
                //   newData.push({ id: id, name: data.name, description: data.description, synonyms: data.synonyms});
                tools.push({ ...data, id: id });
            });

        });

        

        this.setState({ lastFetchedTimeStamp: new Date().toGMTString(), recipes, actions, ingredients, tools })
        this.toggleSnackbar("Updated data ... ", true, false)
    }

    fetchAllIngredients = (ingredients) => {
        let allList = [];
        console.log(ingredients);
        ingredients.length > 0 && ingredients.map((item) =>{ allList.push({name:item.name,id:item.id})}
        )
        // this.setState(csvAllIngredients: [{ x: 'name', y: 'imageUrl' , z:'id'}, ...ingredients]);

        console.log(allList,"allList");
        this.setState({ ingredients, csvAllIngredients: [{ x: 'name', y: 'id'}, ...allList] });
        // this.setState({ csvAllIngredients : ingredients})
        console.log(this.state.csvAllIngredients);
        // console.log(ingredients);
    }

    fetchIngredients = (recipes) => {
        console.log("coming here")
        var list = [];
        recipes.map((item) => {
            item?.recipeIngredient.length > 0 && item.recipeIngredient.map((ing) => {
                list.push({url:item['@id'],name:item?.name,ingredients:ing?.ingredient})
            })
        })
        console.log(list,"list");
        this.setState({recipeData:list})
        // this.state.recipeData(list);
    }

    addRow = (rowData) => {
        this.toggleSnackbar("Saving ...", true, true)
        FirebaseApi[this.state.dataOf].add({
            ...rowData
        })
            .then(function (docRef) {
                this.toggleSnackbar("Saved ...", true, false);
                // console.log( "Data row created with ID: ", docRef.id);
            }.bind(this))
            .catch(function (error) {
            })
        this.toggleSnackbar("", false, false)
    }


    handleDialogClose = () => {
        this.setState({ openDialog: !this.state.openDialog, snackbarVisibe: false })
        this.fetchData()
    }

    deleteRows = () => {
        console.log(this.state.selectedRows)
        var rowsToDelete = this.state.selectedRows
        rowsToDelete.map((row) => {
            FirebaseApi[this.state.dataOf].doc(row.id).delete().then(() => {
                console.log("Deleted :" + row.name);
            }).catch((e) => {
                // console.log("Failed to delete :" + row.name )
            })
        })
        this.fetchData();
    }

    editRow = () => {

    }

    calculateActionFrequency = () => {
        var frequency = []
        var cookingActions = []

        this.state.recipes.map((recipe, index) => {

            recipe.recipeInstructionsParsed && recipe.recipeInstructionsParsed.map((instructions, index) => {
                instructions.itemListElement.map((item, itemIndex) => {
                    item.machineCode.map((code, index) => cookingActions.push(code.cookingAction))
                })
            })

        })
        this.state.actions.map((action, index) => {
            cookingActions && cookingActions.map((collectedAction) => {

                if (collectedAction == action.name) {


                    var filtered = frequency.filter((freqItem) => freqItem.x == action.name)

                    if (filtered.length >= 1) {

                        frequency.map((freqItem, index) => {
                            if (freqItem.x == action.name) {

                                frequency[index] = { ...frequency[index], y: frequency[index].y + 1 }


                            }
                        })


                    }
                    else {

                        frequency.push({
                            x: action.name,
                            y: 1
                        })

                    }
                }
            })
        })

        this.setState({ actionsData: frequency, csvActionFrequency: [{ x: 'Actions', y: 'no Of times Repeated' }, ...frequency] })



    }

    actionInRecipes = () => {

        var finalFilter = []
        var combine = []

        this.state.recipes.map((recipe, index) => {

            var cookingActions = []
            recipe.recipeInstructionsParsed && recipe.recipeInstructionsParsed.map((instructions, index) => {
                instructions.itemListElement.map((item, itemIndex) => {
                    item.machineCode.map((code, index) => cookingActions.push(code.cookingAction))
                })
            })
            combine.push([...new Set(cookingActions)])


        })
        combine && combine.map((collection) => {
            collection.map(actionItem => {
                this.state.actions.map((action) => {

                    if (action.name == actionItem) {

                        var filtered = finalFilter.filter((freqItem) => freqItem.x == actionItem)


                        if (filtered.length >= 1) {
                            finalFilter.map((freqItem, index) => {
                                if (freqItem.x == actionItem) {

                                    finalFilter[index] = { ...finalFilter[index], y: finalFilter[index].y + 1 }


                                }
                            })


                        }
                        else {

                            finalFilter.push({ x: actionItem, y: 1 })

                        }
                    }
                })

            })
        })

        this.setState({ ...this.state, actionsInRecipes: finalFilter, csvActionsInRecipes: [{ x: 'Actions', y: 'present in No of Recipes' }, ...finalFilter] })



    }
    toolsInRecipes = () => {

        var finalFilter = []
        var combine = []



        this.state.recipes.map((recipe, index) => {

            var tools = []
            recipe.recipeInstructionsParsed && recipe.recipeInstructionsParsed.map((instructions, index) => {

                instructions.itemListElement.map((item, itemIndex) => {
                    item.machineCode.map((code, index) => code.tool && tools.push(code.tool))
                })

            })

            combine.push([...new Set(tools)])



        })



        combine && combine.map((collection) => {
            collection.map(toolItem => {
                this.state.tools.map((tool) => {

                    if (tool.name == toolItem) {

                        var filtered = finalFilter.filter((freqItem) => freqItem.x == toolItem)


                        if (filtered.length >= 1) {
                            finalFilter.map((freqItem, index) => {
                                if (freqItem.x == toolItem) {

                                    finalFilter[index] = { ...finalFilter[index], y: finalFilter[index].y + 1 }


                                }
                            })


                        }
                        else {

                            finalFilter.push({ x: toolItem, y: 1 })

                        }
                    }
                })

            })
        })


        this.setState({ ...this.state, toolsInRecipes: finalFilter, csvToolsInRecipes: [{ x: 'Tools', y: 'present in No of Recipes' }, ...finalFilter] })



    }

    toolsStackInRecipes = () => {

        var finalFilter = []
        var combine = []



        this.state.recipes.map((recipe, index) => {

            var tools = []
            // console.log(recipe.recipe.recipeInstructionsParsed && recipe.recipe.recipeInstructionsParsed.length)
            recipe.recipeInstructionsParsed && recipe.recipeInstructionsParsed.map((instructions, index) => {
                // console.log(instructions, recipe.id)
                var section = []
                instructions.itemListElement.map((item, itemIndex) => {
                    item.machineCode.map((code, index) => code.tool && section.push(code.tool))
                })
                tools.push({ x: instructions.name, y: [...new Set(section)].length })

            })

            // console.log(recipe.recipe.recipeInstructionsParsed && recipe.recipe.recipeInstructionsParsed.length, tools)
            combine.push(tools)
            // combine.push([...new Set(tools)])



        })
        console.log(combine)
        this.setState({ toolsStackInRecipes: combine })



        // combine && combine.map((collection) => {
        //     collection.map(toolItem => {
        //         this.state.tools.map((tool) => {

        //             if (tool.name == toolItem) {

        //                 var filtered = finalFilter.filter((freqItem) => freqItem.x == toolItem)


        //                 if (filtered.length >= 1) {
        //                     finalFilter.map((freqItem, index) => {
        //                         if (freqItem.x == toolItem) {

        //                             finalFilter[index] = { ...finalFilter[index], y: finalFilter[index].y + 1 }


        //                         }
        //                     })


        //                 }
        //                 else {

        //                     finalFilter.push({ x: toolItem, y: 1 })

        //                 }
        //             }
        //         })

        //     })
        // })
        // console.log(finalFilter)


        // this.setState({ ...this.state, toolsInRecipes: finalFilter, csvToolsInRecipes: [{ x: 'Tools', y: 'present in No of Recipes' }, ...finalFilter] })



    }



    calculateIngredientsFrequency = () => {
        var frequency = []
        var ingredients = []

        this.state.recipes.map((recipe, index) => {

            recipe.recipeInstructionsParsed && recipe.recipeInstructionsParsed.map((instructions, index) => {
                instructions.itemListElement.map((item, itemIndex) => {
                    item.machineCode.map((code, index) => ingredients.push(code.ingredient))
                })
            })

        })
        this.state.ingredients.map((action, index) => {
            ingredients && ingredients.map((collectedAction) => {

                if (collectedAction == action.name) {


                    var filtered = frequency.filter((freqItem) => freqItem.x == action.name)

                    if (filtered.length >= 1) {

                        frequency.map((freqItem, index) => {
                            if (freqItem.x == action.name) {

                                frequency[index] = { ...frequency[index], y: frequency[index].y + 1 }


                            }
                        })


                    }
                    else {

                        frequency.push({
                            x: action.name,
                            y: 1
                        })

                    }
                }
            })
        })
        this.setState({ ingredientsFrequency: frequency, csvIngredientsFrequency: [{ x: 'Ingredients', y: 'no Of Times Repeated' }, ...frequency] })



    }

    caluclateDishesAndPrepTime = () => {
        var dishesAndPrepTime = []

        this.state.recipes.map((recipe, index) => {
            var date = new Date()
            var time = date.getTime()
            var prepTime = moment.duration(recipe.prepTime)

            dishesAndPrepTime.push({ y: prepTime._data.minutes, x: recipe.name.substring(0, 15) })

        })
        this.setState({ ...this.state, dishesAndPrepTime, csvDishesAndPrepTime: [{ x: "Dish Name", y: "Prep Time(in Minutes)" }, ...dishesAndPrepTime] })

    }

    caluclateDishesAndCookTime = () => {
        var dishesAndCookTime = []

        this.state.recipes.map((recipe, index) => {
            var date = new Date()
            var time = date.getTime()
            var cookTime = moment.duration(recipe.cookTime)
            dishesAndCookTime.push({ x: recipe.name.substring(0, 15), y: cookTime._data.minutes })

        })
        this.setState({ ...this.state, dishesAndCookTime, csvDishesAndCookTime: [{ x: "Dish Name", y: "Cook Time(in Minutes)" }, ...dishesAndCookTime] })
    }


    caluclateDishesAndTotalTime = () => {
        var dishesAndTotalTime = []

        this.state.recipes.map((recipe, index) => {
            var date = new Date()
            var time = date.getTime()
            var totalTime = moment.duration(recipe.totalTime)
            if (totalTime._data.hours >= 1) {
                var hours = totalTime._data.hours * 60
                var minutes = totalTime._data.minutes
                dishesAndTotalTime.push({ x: recipe.name.substring(0, 15), y: hours + minutes, })


            }
            else {
                dishesAndTotalTime.push({ x: recipe.name.substring(0, 15), y: totalTime._data.minutes, })

            }


        })
        this.setState({ ...this.state, dishesAndTotalTime, csvDishesAndTotalTime: [{ x: "Dish Name", y: "Total Time(in Minutes)" }, ...dishesAndTotalTime] })

    }


    caluclateDishesAndNoOfSteps = () => {
        var dishesAndNoOfSteps = []

        this.state.recipes.map((recipe, index) => {
            var steps = 0
            recipe.recipeInstructionsParsed && recipe.recipeInstructionsParsed.map((instruction) => {
                steps = steps + instruction.itemListElement.length
            })
            console.log(steps)
            dishesAndNoOfSteps.push({ x: recipe.name.substring(0, 15), y: steps })

        })
        this.setState({ ...this.state, dishesAndNoOfSteps, csvDishesAndNoOfSteps: [{ x: "Dish Name", y: "No of Steps" }, ...dishesAndNoOfSteps] })
    }

    caluclateWaterLevel = () => {
        var waterItems = []
        var oilItems = []

        this.state.recipes.map((recipe, index) => {
            recipe.recipeIngredientParsed && recipe.recipeIngredientParsed.map(ingredient => {
                if (ingredient.category === 'Oil') {

                    // console.log(ingredient.unit)
                    if (ingredient.unit) {

                        const convertToMl = unitVolumeConverter(Number(ingredient.quantity), ingredient.unit, 'ml')
                        console.log(convertToMl, recipe.id)
                        oilItems.push({ x: ingredient.ingredient, y: convertToMl })
                    }

                }
                if (ingredient.category === 'Water') {
                    // console.log(ingredient.unit)
                    if (ingredient.unit) {

                        const convertToMl = unitVolumeConverter(Number(ingredient.quantity), ingredient.unit, 'ml')
                        waterItems.push({ x: ingredient.ingredient, y: convertToMl })
                    }
                }
            })
            // recipe.recipeInstructionsParsed && recipe.recipeInstructionsParsed.map((instructions, index) => {
            //     instructions.itemListElement.map((item, itemIndex) => {
            //         item.machineCode.map((code, index) => {
            //             if (code.ingredient == 'Water') {
            //                 waterItems.push(code.ingredientDetails)

            //             }
            //             if (code.category == 'Oil') {
            //                 if (code.ingredientDetails.unit == 'ml') {
            //                     oilItems.push({ x: recipe.name.substring(0, 15), y: code.ingredientDetails.quantity })

            //                 }
            //                 else {
            //                     const quantityIntoMl = code.ingredientDetails.unit && unitVolumeConverter(code.ingredientDetails.quantity, code.ingredientDetails.unit, 'ML')
            //                     if (quantityIntoMl) {
            //                         oilItems.push({ x: recipe.name.substring(0, 15), y: quantityIntoMl })


            //                     }
            //                 }

            //             }
            //         })
            //     })
            // })

        })


        this.setState({ ...this.state, waterItems, oilItems, csvOilLevel: [{ x: "type", y: "Oil Required level(in ml)" }, ...oilItems], csvWaterLevel: [{ x: "type", y: "Water required level(in ml)" }, ...waterItems] })

    }

    cusineAndIngredients = () => {

        var recipeCusines = []
        var ingredientNumbers = []

        this.state.recipes.map((recipe, index) => {

            // ingredientNumbers.push({ x: recipeItem.recipeCuisine, y: recipe.id })

            if (recipe.recipeIngredientParsed) {
                ingredientNumbers.push({ x: recipe.recipeCuisine, y: recipe.recipeIngredientParsed.length })

                recipeCusines.push({ x: recipe.recipeCuisine, y: recipe.recipeIngredientParsed.length })

            }
            // recipe.recipe && recipe.recipe.ingredientParsed.map((ingredient,ingredientIndex)=>{
            //     cuisineAndIngredients.push({})

            // })

        })
        this.setState({ ...this.state, recipeCusines, ingredientNumbers, csvCuisineAndIngredients: [{ x: "Cusine Name", y: "Ingredients required" }, ...ingredientNumbers] })

    }

    cusineAndTools = () => {

        var cuisineTools = []

        this.state.recipes.map((recipe, index) => {
            var tools = []
            recipe.recipeInstructionsParsed && recipe.recipeInstructionsParsed.map((instructions, index) => {
                instructions.itemListElement.map((item, itemIndex) => {
                    item.machineCode.map((code, index) => tools.push(code.tool))
                })
            })
            cuisineTools.push({ x: recipe.recipeCuisine, y: [...new Set(tools)].length })



        })

        this.setState({ ...this.state, cuisineTools, csvCuisineAndTools: [{ x: "Cusine Name", y: "no Of Tools required" }, ...cuisineTools] })
        // this.setState({ ...this.state, recipeCusines, ingredientNumbers })

    }

    noOfToolsRequired = () => {
        var noOfToolsRequired = []

        this.state.recipes.map((recipe, index) => {

            var tools = []
            recipe.recipeInstructionsParsed && recipe.recipeInstructionsParsed.map((instructions, index) => {
                instructions.itemListElement.map((item, itemIndex) => {
                    item.machineCode.map((code, index) => code.tool && tools.push(code.tool))
                })

            })
            console.log([...new Set(tools)])
            noOfToolsRequired.push({ x: recipe.name.substring(0, 15), y: [...new Set(tools)].length })


        })
        this.setState({ ...this.state, noOfToolsRequired, csvToolsRequired: [{ x: "Recipe Name", y: "Required Number of tools" }, ...noOfToolsRequired] })


    }


    toolsFrequency = () => {

        var frequency = []
        var tools = []

        this.state.recipes.map((recipe, index) => {

            recipe.recipeInstructionsParsed && recipe.recipeInstructionsParsed.map((instructions, index) => {
                instructions.itemListElement.map((item, itemIndex) => {

                    item.machineCode.map((code, index) => {
                        if (code.tool) {
                            tools.push(code.tool)
                        }
                    })
                })
            })

        })
        this.state.tools.map((tool, index) => {
            tools && tools.map((collectedTool) => {

                if (collectedTool == tool.name) {


                    var filtered = frequency.filter((freqItem) => freqItem.x == tool.name)

                    if (filtered.length >= 1) {

                        frequency.map((freqItem, index) => {
                            if (freqItem.x == tool.name) {

                                frequency[index] = { ...frequency[index], y: frequency[index].y + 1 }


                            }
                        })


                    }
                    else {

                        frequency.push({
                            x: tool.name,
                            y: 1
                        })

                    }
                }
            })
        })


        this.setState({ ...this.state, toolFrequency: frequency, csvToolsFrequency: [{ x: "Tool name", y: "no of Times Repeated" }, ...frequency] })

    }


    comboFrequency = () => {
        var allActions = []

        this.state.recipes.map((recipe, index) => {

            var actions = []
            recipe.recipeInstructionsParsed && recipe.recipeInstructionsParsed.map((instructions, index) => {
                instructions.itemListElement.map((item, itemIndex) => {

                    item.machineCode.map((code, index) => {
                        if (code.cookingAction) {
                            // if (code.cookingAction == 'Sprinkle' || code.cookingAction == 'Blend' || code.cookingAction == 'Wash' || code.cookingAction == 'Whisk' || code.cookingAction == 'Chop' || code.cookingAction == 'Cool' || code.cookingAction == 'Peel' || code.cookingAction == 'Slice') {
                            actions.push(code.cookingAction)
                        }
                    })
                })
            })

            const filteredActions = new Set(actions)


            if ([...filteredActions].length > 1) {
                allActions.push([...filteredActions])

            }



        })


        var finalComparison = []
        var finalFilter = []

        for (var i = 0; i <= allActions.length; i++) {
            for (var j = 0; j <= allActions.length; j++) {
                if (allActions[i] && allActions[j] && i !== j) {
                    // console.log(allActions[i], allActions[j])
                    const filteredArray = allActions[i].filter(value => allActions[j].includes(value))

                    if (filteredArray.length > 1 && filteredArray.length < 3) {

                        var filtered = finalFilter.filter((freqItem) => freqItem.x == JSON.stringify(filteredArray))


                        if (filtered.length >= 1) {
                            finalFilter.map((freqItem, index) => {
                                if (freqItem.x == JSON.stringify(filteredArray)) {

                                    finalFilter[index] = { ...finalFilter[index], y: finalFilter[index].y + 1 }


                                }
                            })


                        }
                        else {

                            finalFilter.push({ x: JSON.stringify(filteredArray), y: 1 })

                        }


                    }

                }
            }
            // if (allActions[i + 1]) {
            //     console.log(allActions[i], allActions[i + 1])

            // }
            // const filteredArray = allActions[i].filter(value => allActions[i + 1].includes(value));

            // console.log(filteredArray)

        }

        const finalCombo = finalFilter.filter((item) => item.y > 1)
        console.log(finalCombo)


        this.setState({ ...this.state, comboFrequency: finalCombo, csvActionComboFrequency: [{ x: "Actions", y: "no Of times Combo Actions repeated " }, ...finalCombo] })
    }


    collectAllSpices = () => {

        this.state.recipes.map(recipe => {
            var spices = []
            recipe.recipeInstructionsParsed && recipe.recipeInstructionsParsed.map((instructions, index) => {
                instructions.itemListElement.map((item, itemIndex) => {
                    item.machineCode.map((code, index) => {
                        if (code.ingredientDetails?.category === 'Spice/Herb') {
                            FirebaseApi.spices.add({ name: code.ingredientDetails.ingredient })
                                .then(function (docRef) {
                                    console.log("row created with ID: ", docRef.id);
                                })
                                .catch(function (error) {
                                    console.log("row creation failed ");
                                })
                            // console.log(code.ingredientDetails)
                        }
                    })
                })
            })

        })
    }


    // spiceQuantity = ()=>{
    //     var spicesWithQuantity = []
    //     var totalCount = 0
    //     var withUnit =0
    //     var withoutUnit =[]
    //     var matched = []
    //     this.state.recipes.map(recipe => {
    //         // console.log(recipe)
    //         recipe.recipeIngredientParsed && recipe.recipeIngredientParsed.map(ingredient=>{

    //             var re = new RegExp('salt', "i")
    //             if(ingredient.category === 'Spice/Herb'){
    //                 console.log(re)
    //                 const match = ingredient.ingredient.match(re)
    //                 if(match){
    //                     matched.push({...ingredient})
    //                 }
    //                 totalCount++
    //                 console.log(ingredient,recipe.id)
    //                 if(ingredient.unit){
    //                     withUnit++

    //                     const convertToGrams = unitMassConverter(Number(ingredient.quantity),ingredient.unit,'gram')
    //                     const finalQuantity = convertToGrams * (4/Number(ingredient.scaling))


    //                     spicesWithQuantity.push({x:ingredient.ingredient,y:Number(finalQuantity.toFixed(2))})
    //                 }
    //                 else{
    //                     withoutUnit.push({x:ingredient.ingredient,y:ingredient.unit})
    //                 }


    //             }
    //         })
    //     })
    //     console.log(totalCount,withUnit)
    //     console.log(withoutUnit)
    //     console.log(matched)
    //     console.log(JSON.stringify(matched))

    //     this.setState({spiceQuantity:spicesWithQuantity,csvSpiceQuantity:[{x:"Spice Name",y:"Quantity (in grams)"},...spicesWithQuantity]})

    // }


    spiceQuantity = () => {
        var spicesWithQuantity = []
        var totalCount = 0
        var withUnit = 0
        var withoutUnit = []
        var matched = []
        const spices = ['salt', 'chilli powder', 'corainder', 'turmeric', 'jeera', 'cumin', 'clove', 'cardamom', 'fennel', 'fenugreek']
        this.state.recipes.map(recipe => {
            // console.log(recipe)
            recipe.recipeIngredientParsed && recipe.recipeIngredientParsed.map(ingredient => {

                if (ingredient.category === 'Spice/Herb') {
                    if (ingredient.unit) {
                        spices.map(spice => {
                            var re = new RegExp(`${spice}`, "i")
                            const match = ingredient.ingredient.match(re)

                            if (match) {
                                // console.log(match,ingredient.ingredient)
                                const value = conversion(spice, match, ingredient)
                                console.log(match, ingredient)
                                spicesWithQuantity.push({ x: spice, y: Number(value.toFixed(2)) })
                                // console.log(ingredient.ingredient,value,recipe.id)
                            }

                        })



                    }



                }
            })
        })
        console.log(spicesWithQuantity)

        this.setState({ spiceQuantity: spicesWithQuantity, csvSpiceQuantity: [{ x: "Spice Name", y: "Quantity (in cc)" }, ...spicesWithQuantity] })


    }


    ingredientOrder=()=>{
        this.state.recipes.map(recipe => {
            // console.log(recipe.recipeInstructionsParsed)
            if(recipe.recipeInstructionsParsed?.length >0){
                var eachInstruction =[]
                recipe.recipeInstructionsParsed.map(instruction=>{
                   var indiInstruction=[]
                   instruction.itemListElement.map(element=>{
                       let order = 1;
                       var ingredientOrder =[] ;
                       element.machineCode.map(code=>{

                           const ingredient = {
                               ingredient:code.ingredient,
                               order:order++,
                               name:recipe.name,
                               step:instruction.co

                           }
                           ingredientOrder.push(ingredient)


                       })
                    //    console.log({ingredientOrder})
                       indiInstruction.push(ingredientOrder)

                   })
                   console.log(indiInstruction)
               })

            }
        })

    }

    

    render() {
        console.log(this.state)

        return (
            <Grid container >
                {/* <Grid style={{top: 10,  marginLeft:"auto", marginRight: "auto", backgroundColor: "rgb(196 242 198)", border: "1px bold #000", borderRadius: 4, height: 60, width: 300, position: "absolute", zIndex: 2, display: this.state.snackbarVisible ? "": "none"}}>
                    {this.state.snackBarMessage}
                </Grid> */}
                <Snackbar key={this.state.dataOf} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={this.state.snackbarVisibe} autoHideDuration={6000} onClose={this.toggleSnackbar} message={this.state.snackBarMessage} />

                <Grid container direction="column" style={{ height: 150 }} spacing={2}>
                    <Typography variant="h3">
                        {this.state.dataOfPlural}
                    </Typography>
                    <Divider />
                </Grid>



                <Button onClick={this.ingredientOrder} >ingredientOrder</Button>
                <Button onClick={() => this.fetchAllIngredients(this.state.ingredients)}>Genrate CSV</Button>
                <CSVLink filename={"Ingredients.csv"} data={this.state.csvAllIngredients}><Button variant='outlined'>Download CSV</Button></CSVLink>
                <Grid xs={12}>
                    <Grid container justify="space-between" >



                        <Grid xs={12}>



                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Actions and no of times actions repeated </Typography>
                                </AccordionSummary>
                                <AccordionDetails >
                                    <Grid container>


                                        <Grid xs={12} item>

                                            <VictoryChart
                                                height={200}
                                                animate={{
                                                    duration: 1500,
                                                    onLoad: { duration: 1000 }
                                                }}
                                                theme={VictoryTheme.material} domainPadding={10}
                                                containerComponent={
                                                    <VictoryVoronoiContainer

                                                        labels={({ datum }) => datum.x + ":" + datum.y}
                                                    />
                                                }
                                            >
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                                        axisLabel: { fontSize: 5, fontWeight: 'bold', padding: 40 },
                                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                                        ticks: { stroke: "grey", size: 5 },
                                                        tickLabels: { fontSize: 5, padding: 5 }
                                                    }}
                                                    tickLabelComponent={
                                                        <VictoryLabel angle={-90} textAnchor="end" />
                                                    }
                                                    axisLabelComponent={<VictoryLabel dx={-140} dy={-25} />}
                                                    label={'ACTIONS'}

                                                    tickFormat={(label, index) => label} />
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                                        axisLabel: { fontSize: 8, padding: 20 },
                                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                                        ticks: { stroke: "grey", size: 5 },
                                                        tickLabels: { fontSize: 5, padding: 5 }
                                                    }}
                                                    tickFormat={(label) => label}
                                                    label={'No. of times Actions repeated'}
                                                    dependentAxis />

                                                <VictoryBar
                                                    interpolation="natural"
                                                    containerComponent={<VictoryContainer responsive={false} />}
                                                    style={{
                                                        data: {
                                                            stroke: "#8884d8",
                                                            strokeWidth: 0.3
                                                        },
                                                        labels: {
                                                            fontSize: 3,
                                                            paddingBottom: 10
                                                        }
                                                    }}

                                                    data={this.state.actionsData}
                                                    labelComponent={<VictoryLabel dy={-5} />}
                                                    labels={({ datum }) => Math.round(datum.y)}
                                                />
                                            </VictoryChart>

                                        </Grid>

                                        <Grid xs={6} item>
                                            <Button variant='outlined' onClick={this.calculateActionFrequency}>Calculate Actions</Button>
                                        </Grid>

                                        {this.state.csvActionFrequency && this.state.csvActionFrequency.length > 1 && (
                                            <Grid xs={6} item>
                                                <CSVLink filename={"Actions-Frequency.csv"} data={this.state.csvActionFrequency}><Button variant='outlined'>Download CSV</Button></CSVLink>
                                            </Grid>

                                        )}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        {/* Calculate Actions in recipes */}
                        <Grid xs={12}>


                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Calculate Actions in recipes</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid xs={12}>

                                            <VictoryChart
                                                height={200}
                                                animate={{
                                                    duration: 1500,
                                                    onLoad: { duration: 1000 }
                                                }}
                                                theme={VictoryTheme.material} domainPadding={10}
                                                containerComponent={
                                                    <VictoryVoronoiContainer

                                                        labels={({ datum }) => datum.x + ":" + datum.y}
                                                    />
                                                }
                                            >
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                                        axisLabel: { fontSize: 5, fontWeight: 'bold', padding: 40 },
                                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                                        ticks: { stroke: "grey", size: 5 },
                                                        tickLabels: { fontSize: 5, padding: 5 }
                                                    }}
                                                    tickLabelComponent={
                                                        <VictoryLabel angle={-90} textAnchor="end" />
                                                    }
                                                    axisLabelComponent={<VictoryLabel dx={-140} dy={-25} />}
                                                    label={'ACTIONS'}

                                                    tickFormat={(label, index) => label} />
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                                        axisLabel: { fontSize: 8, padding: 20 },
                                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                                        ticks: { stroke: "grey", size: 5 },
                                                        tickLabels: { fontSize: 5, padding: 5 }
                                                    }}
                                                    tickFormat={(label) => label}
                                                    label={'present in No of Recipes'}
                                                    dependentAxis />

                                                <VictoryBar
                                                    interpolation="natural"
                                                    containerComponent={<VictoryContainer responsive={false} />}
                                                    style={{
                                                        data: {
                                                            stroke: "#8884d8",
                                                            strokeWidth: 0.3
                                                        },
                                                        labels: {
                                                            fontSize: 3,
                                                            paddingBottom: 10
                                                        }
                                                    }}

                                                    data={this.state.actionsInRecipes}
                                                    labelComponent={<VictoryLabel dy={-5} />}
                                                    labels={({ datum }) => Math.round(datum.y)}
                                                />
                                            </VictoryChart>

                                        </Grid>

                                        <Grid xs={6}>
                                            <Button variant='outlined' onClick={this.actionInRecipes}>Calculate Actions in recipes</Button>
                                        </Grid>

                                        {this.state.csvActionsInRecipes && this.state.csvActionsInRecipes.length > 1 && (
                                            <Grid xs={6}>
                                                <CSVLink filename={"Actions-Frequency.csv"} data={this.state.csvActionsInRecipes}><Button variant='outlined'>Download CSV</Button></CSVLink>
                                            </Grid>

                                        )}

                                    </Grid>
                                </AccordionDetails>

                            </Accordion>

                        </Grid>
                        {/* actions in stack */}
                        <Grid xs={12}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Actions and no of times actions repeated </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid xs={12}>
                                            <VictoryChart
                                                height={250}
                                                animate={{
                                                    duration: 1500,
                                                    onLoad: { duration: 1000 }
                                                }}
                                                theme={VictoryTheme.material} domainPadding={10}
                                                containerComponent={
                                                    <VictoryVoronoiContainer

                                                        labels={({ datum }) => datum.x + ":" + datum.y}
                                                    />
                                                }
                                            >
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                                        axisLabel: { fontSize: 8, padding: 40 },
                                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                                        ticks: { stroke: "grey", size: 5 },
                                                        tickLabels: { fontSize: 2, padding: 1 }
                                                    }}
                                                    axisLabelComponent={<VictoryLabel dx={-150} dy={-25} />}
                                                    tickLabelComponent={
                                                        <VictoryLabel angle={-90} textAnchor="end" />
                                                    }
                                                    label={"Recipe Id's"}

                                                    tickFormat={(label, index) => label} />
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                                        axisLabel: { fontSize: 8, padding: 20 },
                                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                                        ticks: { stroke: "grey", size: 5 },
                                                        tickLabels: { fontSize: 5, padding: 5 }
                                                    }}
                                                    tickFormat={(label) => label}
                                                    label={'no of times Tools Repeated'}
                                                    dependentAxis />

                                                <VictoryBar
                                                    interpolation="natural"
                                                    containerComponent={<VictoryContainer responsive={false} />}
                                                    style={{
                                                        data: {
                                                            stroke: "#8884d8",
                                                            strokeWidth: 0.3
                                                        },
                                                        labels: {
                                                            fontSize: 3,
                                                            paddingBottom: 10
                                                        }
                                                    }}

                                                    data={this.state.comboFrequency}
                                                    labelComponent={<VictoryLabel dy={-5} />}
                                                    labels={({ datum }) => Math.round(datum.y)}
                                                />
                                            </VictoryChart>

                                        </Grid>
                                        <Grid xs={6}>
                                            <Button variant='outlined' onClick={this.comboFrequency}>Action combo Frequency</Button>
                                        </Grid>
                                        {this.state.csvActionComboFrequency && this.state.csvActionComboFrequency.length > 1 && (
                                            <Grid xs={6}>
                                                <CSVLink filename={"Combo-Actions-Frequency.csv"} data={this.state.csvActionComboFrequency}><Button variant='outlined'>Download CSV</Button></CSVLink>
                                            </Grid>

                                        )}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>





                        {/* Calculate Dishes and prepTime */}
                        <Grid xs={12}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Calculate Dishes and prepTime</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid xs={12}>

                                            <VictoryChart
                                                height={200}
                                                animate={{
                                                    duration: 1500,
                                                    onLoad: { duration: 1000 }
                                                }}
                                                theme={VictoryTheme.material} domainPadding={10}
                                                containerComponent={
                                                    <VictoryVoronoiContainer

                                                        labels={({ datum }) => datum.x + ":" + datum.y}
                                                    />
                                                }
                                            >
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                                        axisLabel: { fontSize: 8, padding: 40 },
                                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                                        ticks: { stroke: "grey", size: 5 },
                                                        tickLabels: { fontSize: 5, padding: 5 }
                                                    }}
                                                    axisLabelComponent={<VictoryLabel dx={-150} dy={-25} />}
                                                    tickLabelComponent={
                                                        <VictoryLabel angle={-90} textAnchor="end" />
                                                    }
                                                    label={"recipe Id's"}

                                                    tickFormat={(label, index) => label} />
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                                        axisLabel: { fontSize: 8, padding: 20 },
                                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                                        ticks: { stroke: "grey", size: 5 },
                                                        tickLabels: { fontSize: 5, padding: 5 }
                                                    }}
                                                    tickFormat={(label) => label}
                                                    label={'Prep Time in Min'}
                                                    dependentAxis />

                                                <VictoryBar
                                                    interpolation="natural"
                                                    containerComponent={<VictoryContainer responsive={false} />}
                                                    style={{
                                                        data: {
                                                            stroke: "#8884d8",
                                                            strokeWidth: 0.3
                                                        },
                                                        labels: {
                                                            fontSize: 3,
                                                            paddingBottom: 10
                                                        }
                                                    }}

                                                    data={this.state.dishesAndPrepTime}
                                                    labelComponent={<VictoryLabel dy={-5} />}
                                                    labels={({ datum }) => Math.round(datum.y)}
                                                />
                                            </VictoryChart>


                                        </Grid>

                                        <Grid xs={6}>
                                            <Button variant='outlined' onClick={this.caluclateDishesAndPrepTime}>Calculate Dishes and prepTime</Button>
                                        </Grid>
                                        {this.state.csvDishesAndPrepTime && this.state.csvDishesAndPrepTime.length > 1 && (
                                            <Grid xs={6}>
                                                <CSVLink filename={"Recipes-PrepTime.csv"} data={this.state.csvDishesAndPrepTime}><Button variant='outlined'>Download CSV</Button></CSVLink>
                                            </Grid>

                                        )}

                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                        </Grid>

                        {/* Calculate Dishes and cookTime */}
                        <Grid xs={12}>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Calculate Dishes and cookTime</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid xs={12}>

                                            <VictoryChart
                                                height={200}
                                                animate={{
                                                    duration: 1500,
                                                    onLoad: { duration: 1000 }
                                                }}
                                                theme={VictoryTheme.material} domainPadding={10}
                                                containerComponent={
                                                    <VictoryVoronoiContainer

                                                        labels={({ datum }) => datum.x + ":" + datum.y}
                                                    />
                                                }
                                            >
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                                        axisLabel: { fontSize: 8, padding: 40 },
                                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                                        ticks: { stroke: "grey", size: 5 },
                                                        tickLabels: { fontSize: 5, padding: 5 }
                                                    }}
                                                    axisLabelComponent={<VictoryLabel dx={-150} dy={-25} />}
                                                    tickLabelComponent={
                                                        <VictoryLabel angle={-90} textAnchor="end" />
                                                    }
                                                    label={"recipe Id's"}

                                                    tickFormat={(label, index) => label} />
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                                        axisLabel: { fontSize: 8, padding: 20 },
                                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                                        ticks: { stroke: "grey", size: 5 },
                                                        tickLabels: { fontSize: 5, padding: 5 }
                                                    }}
                                                    tickFormat={(label) => label}
                                                    label={'Cook Time in Min'}
                                                    dependentAxis />

                                                <VictoryBar
                                                    interpolation="natural"
                                                    containerComponent={<VictoryContainer responsive={false} />}
                                                    style={{
                                                        data: {
                                                            stroke: "#8884d8",
                                                            strokeWidth: 0.3
                                                        },
                                                        labels: {
                                                            fontSize: 3,
                                                            paddingBottom: 10
                                                        }
                                                    }}

                                                    data={this.state.dishesAndCookTime}
                                                    labelComponent={<VictoryLabel dy={-5} />}
                                                    labels={({ datum }) => Math.round(datum.y)}
                                                />
                                            </VictoryChart>

                                        </Grid>

                                        <Grid xs={6}>
                                            <Button variant='outlined' onClick={this.caluclateDishesAndCookTime}>Calculate Dishes and cookTime</Button>
                                        </Grid>
                                        {this.state.csvDishesAndCookTime && this.state.csvDishesAndCookTime.length > 1 && (
                                            <Grid xs={6}>
                                                <CSVLink filename={"Dishes-And-Cooktime.csv"} data={this.state.csvDishesAndCookTime}><Button variant='outlined'>Download CSV</Button></CSVLink>
                                            </Grid>

                                        )}

                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                        </Grid>

                        {/* Calculate Dishes and totalTime */}
                        <Grid xs={12}>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Calculate Dishes and totalTime</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid xs={12}>

                                            <VictoryChart
                                                height={200}
                                                animate={{
                                                    duration: 1500,
                                                    onLoad: { duration: 1000 }
                                                }}
                                                theme={VictoryTheme.material} domainPadding={10}
                                                containerComponent={
                                                    <VictoryVoronoiContainer

                                                        labels={({ datum }) => datum.x + ":" + datum.y}
                                                    />
                                                }
                                            >
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                                        axisLabel: { fontSize: 8, padding: 40 },
                                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                                        ticks: { stroke: "grey", size: 5 },
                                                        tickLabels: { fontSize: 5, padding: 5 }
                                                    }}
                                                    axisLabelComponent={<VictoryLabel dx={-150} dy={-25} />}
                                                    tickLabelComponent={
                                                        <VictoryLabel angle={-90} textAnchor="end" />
                                                    }
                                                    label={"recipe Id's"}

                                                    tickFormat={(label, index) => label} />
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                                        axisLabel: { fontSize: 8, padding: 20 },
                                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                                        ticks: { stroke: "grey", size: 5 },
                                                        tickLabels: { fontSize: 5, padding: 5 }
                                                    }}
                                                    tickFormat={(label) => label}
                                                    label={'Total Time taken in Min'}
                                                    dependentAxis />

                                                <VictoryBar
                                                    interpolation="natural"
                                                    containerComponent={<VictoryContainer responsive={false} />}
                                                    style={{
                                                        data: {
                                                            stroke: "#8884d8",
                                                            strokeWidth: 0.3
                                                        },
                                                        labels: {
                                                            fontSize: 3,
                                                            paddingBottom: 10
                                                        }
                                                    }}

                                                    data={this.state.dishesAndTotalTime}
                                                    labelComponent={<VictoryLabel dy={-5} />}
                                                    labels={({ datum }) => Math.round(datum.y)}
                                                />
                                            </VictoryChart>

                                        </Grid>

                                        <Grid xs={6}>
                                            <Button variant='outlined' onClick={this.caluclateDishesAndTotalTime}>Calculate Dishes and totalTime</Button>
                                        </Grid>
                                        {this.state.csvDishesAndTotalTime && this.state.csvDishesAndTotalTime.length > 1 && (
                                            <Grid xs={6}>
                                                <CSVLink filename={"Dishes-And-TotalTime.csv"} data={this.state.csvDishesAndTotalTime}><Button variant='outlined'>Download CSV</Button></CSVLink>
                                            </Grid>

                                        )}

                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        {/* Calculate Dishes and ingredients */}
                        <Grid xs={12}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Calculate Dishes and ingredients</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid xs={12}>

                                            <VictoryChart
                                                height={200}
                                                animate={{
                                                    duration: 1500,
                                                    onLoad: { duration: 1000 }
                                                }}
                                                theme={VictoryTheme.material} domainPadding={10}
                                                containerComponent={
                                                    <VictoryVoronoiContainer

                                                        labels={({ datum }) => datum.x + ":" + datum.y}
                                                    />
                                                }
                                            >
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                                        axisLabel: { fontSize: 8, padding: 40 },
                                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                                        ticks: { stroke: "grey", size: 5 },
                                                        tickLabels: { fontSize: 5, padding: 5 }
                                                    }}
                                                    axisLabelComponent={<VictoryLabel dx={-150} dy={-25} />}
                                                    tickLabelComponent={
                                                        <VictoryLabel angle={-90} textAnchor="end" />
                                                    }
                                                    label={"Ingredients"}

                                                    tickFormat={(label, index) => label} />
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                                        axisLabel: { fontSize: 8, padding: 20 },
                                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                                        ticks: { stroke: "grey", size: 5 },
                                                        tickLabels: { fontSize: 5, padding: 5 }
                                                    }}
                                                    tickFormat={(label) => label}
                                                    label={'no of Ingredinets required'}
                                                    dependentAxis />

                                                <VictoryBar
                                                    interpolation="natural"
                                                    containerComponent={<VictoryContainer responsive={false} />}
                                                    style={{
                                                        data: {
                                                            stroke: "#8884d8",
                                                            strokeWidth: 0.3
                                                        },
                                                        labels: {
                                                            fontSize: 3,
                                                            paddingBottom: 10
                                                        }
                                                    }}

                                                    data={this.state.ingredientsFrequency}
                                                    labelComponent={<VictoryLabel dy={-5} />}
                                                    labels={({ datum }) => Math.round(datum.y)}
                                                />
                                            </VictoryChart>

                                        </Grid>

                                        <Grid xs={6}>
                                            <Button variant='outlined' onClick={this.calculateIngredientsFrequency}>Calculate Dishes and ingredients</Button>
                                        </Grid>
                                        {this.state.csvIngredientsFrequency && this.state.csvIngredientsFrequency.length > 1 && (
                                            <Grid xs={6}>
                                                <CSVLink filename={"Dishes-Ingredients.csv"} data={this.state.csvIngredientsFrequency}><Button variant='outlined'>Download CSV</Button></CSVLink>
                                            </Grid>

                                        )}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        {/* Calculate Dishes and noOfSteps */}
                        <Grid xs={12}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Calculate Dishes and noOfSteps</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid xs={12}>

                                            <VictoryChart
                                                height={200}
                                                animate={{
                                                    duration: 1500,
                                                    onLoad: { duration: 1000 }
                                                }}
                                                theme={VictoryTheme.material} domainPadding={10}
                                                containerComponent={
                                                    <VictoryVoronoiContainer

                                                        labels={({ datum }) => datum.x + ":" + datum.y}
                                                    />
                                                }
                                            >
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                                        axisLabel: { fontSize: 8, padding: 40 },
                                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                                        ticks: { stroke: "grey", size: 5 },
                                                        tickLabels: { fontSize: 5, padding: 5 }
                                                    }}
                                                    axisLabelComponent={<VictoryLabel dx={-150} dy={-25} />}
                                                    tickLabelComponent={
                                                        <VictoryLabel angle={-90} textAnchor="end" />
                                                    }
                                                    label={"Recipe Id's"}

                                                    tickFormat={(label, index) => label} />
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                                        axisLabel: { fontSize: 8, padding: 20 },
                                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                                        ticks: { stroke: "grey", size: 5 },
                                                        tickLabels: { fontSize: 5, padding: 5 }
                                                    }}
                                                    tickFormat={(label) => label}
                                                    label={'no of Steps '}
                                                    dependentAxis />

                                                <VictoryBar
                                                    interpolation="natural"
                                                    containerComponent={<VictoryContainer responsive={false} />}
                                                    style={{
                                                        data: {
                                                            stroke: "#8884d8",
                                                            strokeWidth: 0.3
                                                        },
                                                        labels: {
                                                            fontSize: 3,
                                                            paddingBottom: 10
                                                        }
                                                    }}

                                                    data={this.state.dishesAndNoOfSteps}
                                                    labelComponent={<VictoryLabel dy={-5} />}
                                                    labels={({ datum }) => Math.round(datum.y)}
                                                />
                                            </VictoryChart>

                                        </Grid>

                                        <Grid xs={6}>
                                            <Button variant='outlined' onClick={this.caluclateDishesAndNoOfSteps}>Calculate Dishes and noOfSteps</Button>
                                        </Grid>
                                        {this.state.csvDishesAndNoOfSteps && this.state.csvDishesAndNoOfSteps.length > 1 && (
                                            <Grid xs={6}>
                                                <CSVLink filename={"Dishes-No-Of-Steps.csv"} data={this.state.csvDishesAndNoOfSteps}><Button variant='outlined'>Download CSV</Button></CSVLink>
                                            </Grid>

                                        )}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        {/* Calculate Oil Level */}
                        <Grid xs={12}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Calculate Oil Level </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>

                                        <Grid xs={12}>
                                            <VictoryChart
                                                height={250}
                                                domainPadding={20}
                                                containerComponent={
                                                    <VictoryVoronoiContainer
                                                        labels={({ datum }) => datum.x + ":" + datum.y}
                                                    />
                                                }


                                            >
                                                <VictoryScatter
                                                    style={{ data: { fill: "#c43a31" } }}

                                                    data={this.state.oilItems}
                                                />
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                                        axisLabel: { fontSize: 8, padding: 40 },
                                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                                        ticks: { stroke: "grey", size: 5 },
                                                        tickLabels: { fontSize: 5, padding: 1 }
                                                    }}
                                                    axisLabelComponent={<VictoryLabel dx={-200} dy={-25} />}
                                                    tickLabelComponent={
                                                        <VictoryLabel angle={-45} textAnchor="end" />
                                                    }
                                                    label={"Cuisines"}

                                                    tickFormat={(label, index) => label} />
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                                        axisLabel: { fontSize: 8, padding: 20 },
                                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                                        ticks: { stroke: "grey", size: 5 },
                                                        tickLabels: { fontSize: 5, padding: 5 }
                                                    }}
                                                    tickFormat={(label) => label}
                                                    label={'no of tools Required'}
                                                    dependentAxis />
                                                <VictoryTooltip center={{ x: 225, y: 30 }}
                                                    pointerOrientation="bottom"
                                                    flyoutWidth={150}
                                                    flyoutHeight={50}
                                                    pointerWidth={150}
                                                    cornerRadius={0} />
                                            </VictoryChart>

                                        </Grid>

                                        <Grid xs={6}>
                                            <Button variant='outlined' onClick={this.caluclateWaterLevel}>Calculate Oil Level</Button>
                                        </Grid>
                                        {this.state.csvOilLevel && this.state.csvOilLevel.length > 1 && (
                                            <Grid xs={6}>
                                                <CSVLink filename={"oil-Level.csv"} data={this.state.csvOilLevel}><Button variant='outlined'>Download CSV</Button></CSVLink>
                                            </Grid>

                                        )}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        {/* calculate water Level */}
                        <Grid xs={12}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Calculate water Level </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>

                                        <Grid xs={12}>
                                            <VictoryChart
                                                height={250}
                                                domainPadding={20}
                                                containerComponent={
                                                    <VictoryVoronoiContainer
                                                        labels={({ datum }) => datum.x + ":" + datum.y}
                                                    />
                                                }


                                            >
                                                <VictoryScatter
                                                    style={{ data: { fill: "#c43a31" } }}

                                                    data={this.state.waterItems}
                                                />
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                                        axisLabel: { fontSize: 8, padding: 40 },
                                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                                        ticks: { stroke: "grey", size: 5 },
                                                        tickLabels: { fontSize: 5, padding: 1 }
                                                    }}
                                                    axisLabelComponent={<VictoryLabel dx={-200} dy={-25} />}
                                                    tickLabelComponent={
                                                        <VictoryLabel angle={-45} textAnchor="end" />
                                                    }
                                                    label={"Cuisines"}

                                                    tickFormat={(label, index) => label} />
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                                        axisLabel: { fontSize: 8, padding: 20 },
                                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                                        ticks: { stroke: "grey", size: 5 },
                                                        tickLabels: { fontSize: 5, padding: 5 }
                                                    }}
                                                    tickFormat={(label) => label}
                                                    label={'no of tools Required'}
                                                    dependentAxis />
                                                <VictoryTooltip center={{ x: 225, y: 30 }}
                                                    pointerOrientation="bottom"
                                                    flyoutWidth={150}
                                                    flyoutHeight={50}
                                                    pointerWidth={150}
                                                    cornerRadius={0} />
                                            </VictoryChart>

                                        </Grid>
                                        <Grid xs={6}>
                                            <Button variant='outlined' onClick={this.caluclateWaterLevel}>Calculate Oil Level</Button>
                                        </Grid>
                                        {this.state.csvWaterLevel && this.state.csvWaterLevel.length > 1 && (
                                            <Grid xs={6}>
                                                <CSVLink filename={"water-Level.csv"} data={this.state.csvWaterLevel}><Button variant='outlined'>Download CSV</Button></CSVLink>
                                            </Grid>

                                        )}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        {/* Recipes and required No of Tools */}
                        <Grid xs={12}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Recipes and required No of Tools</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid xs={12}>
                                            <VictoryChart
                                                height={200}
                                                animate={{
                                                    duration: 1500,
                                                    onLoad: { duration: 1000 }
                                                }}
                                                theme={VictoryTheme.material} domainPadding={10}
                                                containerComponent={
                                                    <VictoryVoronoiContainer

                                                        labels={({ datum }) => datum.x + ":" + datum.y}
                                                    />
                                                }
                                            >
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                                        axisLabel: { fontSize: 8, padding: 40 },
                                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                                        ticks: { stroke: "grey", size: 5 },
                                                        tickLabels: { fontSize: 5, padding: 5 }
                                                    }}
                                                    axisLabelComponent={<VictoryLabel dx={-150} dy={-25} />}
                                                    tickLabelComponent={
                                                        <VictoryLabel angle={-90} textAnchor="end" />
                                                    }
                                                    label={"Recipe Id's"}

                                                    tickFormat={(label, index) => label} />
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                                        axisLabel: { fontSize: 8, padding: 20 },
                                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                                        ticks: { stroke: "grey", size: 5 },
                                                        tickLabels: { fontSize: 5, padding: 5 }
                                                    }}
                                                    tickFormat={(label) => label}
                                                    label={'no of Tools Required '}
                                                    dependentAxis />

                                                <VictoryBar
                                                    interpolation="natural"
                                                    containerComponent={<VictoryContainer responsive={false} />}
                                                    style={{
                                                        data: {
                                                            stroke: "#8884d8",
                                                            strokeWidth: 0.3
                                                        },
                                                        labels: {
                                                            fontSize: 3,
                                                            paddingBottom: 10
                                                        }
                                                    }}

                                                    data={this.state.noOfToolsRequired}
                                                    labelComponent={<VictoryLabel dy={-5} />}
                                                    labels={({ datum }) => Math.round(datum.y)}
                                                />
                                            </VictoryChart>


                                        </Grid>

                                        <Grid xs={6}>
                                            <Button variant='outlined' onClick={this.noOfToolsRequired}>Recipes and required No of Tools</Button>
                                        </Grid>
                                        {this.state.csvToolsRequired && this.state.csvToolsRequired.length > 1 && (
                                            <Grid xs={6}>
                                                <CSVLink filename={"Recipe-No-Of-Tools-Required.csv"} data={this.state.csvToolsRequired}><Button variant='outlined'>Download CSV</Button></CSVLink>
                                            </Grid>

                                        )}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        {/* Tools Frequency */}
                        <Grid xs={12}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Tools Frequency</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid xs={12}>
                                            <VictoryChart
                                                height={200}
                                                animate={{
                                                    duration: 1500,
                                                    onLoad: { duration: 1000 }
                                                }}
                                                theme={VictoryTheme.material} domainPadding={10}
                                                containerComponent={
                                                    <VictoryVoronoiContainer

                                                        labels={({ datum }) => datum.x + ":" + datum.y}
                                                    />
                                                }
                                            >
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                                        axisLabel: { fontSize: 8, padding: 40 },
                                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                                        ticks: { stroke: "grey", size: 5 },
                                                        tickLabels: { fontSize: 5, padding: 5 }
                                                    }}
                                                    axisLabelComponent={<VictoryLabel dx={-150} dy={-25} />}
                                                    tickLabelComponent={
                                                        <VictoryLabel angle={-90} textAnchor="end" />
                                                    }
                                                    label={"Tools "}

                                                    tickFormat={(label, index) => label} />
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                                        axisLabel: { fontSize: 8, padding: 20 },
                                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                                        ticks: { stroke: "grey", size: 5 },
                                                        tickLabels: { fontSize: 5, padding: 5 }
                                                    }}
                                                    tickFormat={(label) => label}
                                                    label={'tools frequency'}
                                                    dependentAxis />

                                                <VictoryBar
                                                    interpolation="natural"
                                                    containerComponent={<VictoryContainer responsive={false} />}
                                                    style={{
                                                        data: {
                                                            stroke: "#8884d8",
                                                            strokeWidth: 0.3
                                                        },
                                                        labels: {
                                                            fontSize: 3,
                                                            paddingBottom: 10
                                                        }
                                                    }}

                                                    data={this.state.toolFrequency}
                                                    labelComponent={<VictoryLabel dy={-5} />}
                                                    labels={({ datum }) => Math.round(datum.y)}
                                                />
                                            </VictoryChart>

                                        </Grid>
                                        <Grid xs={6}>
                                            <Button variant='outlined' onClick={this.toolsFrequency}>Tools Frequency</Button>
                                        </Grid>
                                        {this.state.csvToolsFrequency && this.state.csvToolsFrequency.length > 1 && (
                                            <Grid xs={6}>
                                                <CSVLink filename={"Tools-Frequency.csv"} data={this.state.csvToolsFrequency}><Button variant='outlined'>Download CSV</Button></CSVLink>
                                            </Grid>

                                        )}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        {/* tools In Recipes */}
                        <Grid xs={12}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Tools in Recipes</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid xs={12}>
                                            <VictoryChart
                                                height={200}
                                                animate={{
                                                    duration: 1500,
                                                    onLoad: { duration: 1000 }
                                                }}
                                                theme={VictoryTheme.material} domainPadding={10}
                                                containerComponent={
                                                    <VictoryVoronoiContainer

                                                        labels={({ datum }) => datum.x + ":" + datum.y}
                                                    />
                                                }
                                            >
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                                        axisLabel: { fontSize: 8, padding: 40 },
                                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                                        ticks: { stroke: "grey", size: 5 },
                                                        tickLabels: { fontSize: 5, padding: 5 }
                                                    }}
                                                    axisLabelComponent={<VictoryLabel dx={-150} dy={-25} />}
                                                    tickLabelComponent={
                                                        <VictoryLabel angle={-90} textAnchor="end" />
                                                    }
                                                    label={"Tools "}

                                                    tickFormat={(label, index) => label} />
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                                        axisLabel: { fontSize: 8, padding: 20 },
                                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                                        ticks: { stroke: "grey", size: 5 },
                                                        tickLabels: { fontSize: 5, padding: 5 }
                                                    }}
                                                    tickFormat={(label) => label}
                                                    label={'used in recipes no'}
                                                    dependentAxis />

                                                <VictoryBar
                                                    interpolation="natural"
                                                    containerComponent={<VictoryContainer responsive={false} />}
                                                    style={{
                                                        data: {
                                                            stroke: "#8884d8",
                                                            strokeWidth: 0.3
                                                        },
                                                        labels: {
                                                            fontSize: 3,
                                                            paddingBottom: 10
                                                        }
                                                    }}

                                                    data={this.state.toolsInRecipes}
                                                    labelComponent={<VictoryLabel dy={-5} />}
                                                    labels={({ datum }) => Math.round(datum.y)}
                                                />
                                            </VictoryChart>

                                        </Grid>
                                        <Grid xs={6}>
                                            <Button variant='outlined' onClick={this.toolsInRecipes}>tools In Recipes</Button>
                                        </Grid>
                                        {this.state.csvToolsInRecipes && this.state.csvToolsInRecipes.length > 1 && (
                                            <Grid xs={6}>
                                                <CSVLink filename={"Tools-In-No-Recipes.csv"} data={this.state.csvToolsInRecipes}><Button variant='outlined'>Download CSV</Button></CSVLink>
                                            </Grid>

                                        )}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        {/* tools stack in recipes */}
                        <Grid xs={12}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Tools stack in recipes </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid xs={12}>

                                            <VictoryStack
                                                colorScale={["tomato", "orange", "gold"]}
                                            >
                                                {/* {this.state.toolsStackInRecipes && this.state.toolsStackInRecipes.map((toolsStack, index) => (

                                                    <VictoryBar
                                                        key={index}
                                                        data={toolsStack}
                                                    // interpolation="natural"
                                                    // containerComponent={<VictoryContainer responsive={false} />}
                                                    // style={{
                                                    //     data: {
                                                    //         stroke: "#8884d8",
                                                    //         strokeWidth: 0.3
                                                    //     },
                                                    //     labels: {
                                                    //         fontSize: 3,
                                                    //         paddingBottom: 10
                                                    //     }
                                                    // }}

                                                    // data={toolsStack}
                                                    // labelComponent={<VictoryLabel dy={-5} />}
                                                    // labels={({ datum }) => Math.round(datum.y)}

                                                    >{console.log(toolsStack)}</VictoryBar>
                                                ))} */}
                                                <VictoryBar
                                                    data={[{ x: "a", y: 2 }, { x: "b", y: 3 }, { x: "c", y: 5 }]}
                                                />
                                                <VictoryBar
                                                    data={[{ x: "a", y: 1 }, { x: "b", y: 4 }, { x: "c", y: 5 }]}
                                                />
                                                <VictoryBar
                                                    data={[{ x: "a", y: 3 }, { x: "b", y: 2 }, { x: "c", y: 6 }]}
                                                />
                                            </VictoryStack>
                                            {/* <VictoryStack

                                                height={200}
                                                animate={{
                                                    duration: 1500,
                                                    onLoad: { duration: 1000 }
                                                }}
                                                theme={VictoryTheme.material} domainPadding={10}
                                                containerComponent={
                                                    <VictoryVoronoiContainer

                                                        labels={({ datum }) => datum.x + ":" + datum.y}
                                                    />
                                                }
                                            >
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                                        axisLabel: { fontSize: 8, padding: 40 },
                                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                                        ticks: { stroke: "grey", size: 5 },
                                                        tickLabels: { fontSize: 5, padding: 5 }
                                                    }}
                                                    axisLabelComponent={<VictoryLabel dx={-150} dy={-25} />}
                                                    tickLabelComponent={
                                                        <VictoryLabel angle={-90} textAnchor="end" />
                                                    }
                                                    label={"Tools "}

                                                    tickFormat={(label, index) => label} />
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                                        axisLabel: { fontSize: 8, padding: 20 },
                                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                                        ticks: { stroke: "grey", size: 5 },
                                                        tickLabels: { fontSize: 5, padding: 5 }
                                                    }}
                                                    tickFormat={(label) => label}
                                                    label={'used in recipes no'}
                                                    dependentAxis />
                                                {this.state.toolsStackInRecipes && this.state.toolsStackInRecipes.map((toolsStack, index) => (
                                                    <VictoryBar
                                                        key={index}
                                                        interpolation="natural"
                                                        containerComponent={<VictoryContainer responsive={false} />}
                                                        style={{
                                                            data: {
                                                                stroke: "#8884d8",
                                                                strokeWidth: 0.3
                                                            },
                                                            labels: {
                                                                fontSize: 3,
                                                                paddingBottom: 10
                                                            }
                                                        }}

                                                        data={toolsStack}
                                                        labelComponent={<VictoryLabel dy={-5} />}
                                                        labels={({ datum }) => Math.round(datum.y)}
                                                    />
                                                ))}

                                            </VictoryStack> */}

                                        </Grid>
                                        <Grid xs={6}>
                                            <Button variant='outlined' onClick={this.toolsStackInRecipes}>tools In Recipes</Button>
                                        </Grid>
                                        {this.state.toolsStackInRecipes && this.state.toolsStackInRecipes.length > 1 && (
                                            <Grid xs={6}>
                                                <CSVLink filename={"Tools-In-No-Recipes.csv"} data={this.state.toolsStackInRecipes}><Button variant='outlined'>Download CSV</Button></CSVLink>
                                            </Grid>

                                        )}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        {/* Action combo Frequency */}
                        <Grid xs={12}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Actions and no of times actions repeated </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid xs={12}>
                                            <VictoryChart
                                                height={250}
                                                animate={{
                                                    duration: 1500,
                                                    onLoad: { duration: 1000 }
                                                }}
                                                theme={VictoryTheme.material} domainPadding={10}
                                                containerComponent={
                                                    <VictoryVoronoiContainer

                                                        labels={({ datum }) => datum.x + ":" + datum.y}
                                                    />
                                                }
                                            >
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                                        axisLabel: { fontSize: 8, padding: 40 },
                                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                                        ticks: { stroke: "grey", size: 5 },
                                                        tickLabels: { fontSize: 2, padding: 1 }
                                                    }}
                                                    axisLabelComponent={<VictoryLabel dx={-150} dy={-25} />}
                                                    tickLabelComponent={
                                                        <VictoryLabel angle={-90} textAnchor="end" />
                                                    }
                                                    label={"Recipe Id's"}

                                                    tickFormat={(label, index) => label} />
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                                        axisLabel: { fontSize: 8, padding: 20 },
                                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                                        ticks: { stroke: "grey", size: 5 },
                                                        tickLabels: { fontSize: 5, padding: 5 }
                                                    }}
                                                    tickFormat={(label) => label}
                                                    label={'no of times Tools Repeated'}
                                                    dependentAxis />

                                                <VictoryBar
                                                    interpolation="natural"
                                                    containerComponent={<VictoryContainer responsive={false} />}
                                                    style={{
                                                        data: {
                                                            stroke: "#8884d8",
                                                            strokeWidth: 0.3
                                                        },
                                                        labels: {
                                                            fontSize: 3,
                                                            paddingBottom: 10
                                                        }
                                                    }}

                                                    data={this.state.comboFrequency}
                                                    labelComponent={<VictoryLabel dy={-5} />}
                                                    labels={({ datum }) => Math.round(datum.y)}
                                                />
                                            </VictoryChart>

                                        </Grid>
                                        <Grid xs={6}>
                                            <Button variant='outlined' onClick={this.comboFrequency}>Action combo Frequency</Button>
                                        </Grid>
                                        {this.state.csvActionComboFrequency && this.state.csvActionComboFrequency.length > 1 && (
                                            <Grid xs={6}>
                                                <CSVLink filename={"Combo-Actions-Frequency.csv"} data={this.state.csvActionComboFrequency}><Button variant='outlined'>Download CSV</Button></CSVLink>
                                            </Grid>

                                        )}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>


                        {/* Scatter Plot */}

                        <Grid xs={12}>
                            <VictoryChart
                                height={250}
                                domainPadding={20}
                                containerComponent={
                                    <VictoryVoronoiContainer
                                        labels={({ datum }) => datum.x + ":" + datum.y}
                                    />
                                }


                            >
                                <VictoryAxis
                                    style={{
                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                        axisLabel: { fontSize: 8, padding: 40 },
                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                        ticks: { stroke: "grey", size: 5 },
                                        tickLabels: { fontSize: 5, padding: 1 }
                                    }}
                                    axisLabelComponent={<VictoryLabel dx={-200} dy={-25} />}
                                    tickLabelComponent={
                                        <VictoryLabel angle={-45} textAnchor="end" />
                                    }
                                    label={"Cuisines"}

                                    tickFormat={(label, index) => label} />
                                <VictoryAxis
                                    style={{
                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                        axisLabel: { fontSize: 8, padding: 20 },
                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                        ticks: { stroke: "grey", size: 5 },
                                        tickLabels: { fontSize: 5, padding: 5 }
                                    }}
                                    tickFormat={(label) => label}
                                    label={'no of ingredients Repeated'}
                                    dependentAxis />
                                <VictoryScatter
                                    style={{ data: { fill: "#c43a31" } }}

                                    data={this.state.ingredientNumbers}
                                />
                                <VictoryTooltip center={{ x: 225, y: 30 }}
                                    pointerOrientation="bottom"
                                    flyoutWidth={150}
                                    flyoutHeight={50}
                                    pointerWidth={150}
                                    cornerRadius={0} />
                            </VictoryChart>

                        </Grid>

                        <Grid xs={12}>
                            <Button variant='outlined' onClick={this.cusineAndIngredients}>Cuisine And Ingredients</Button>
                        </Grid>



                        <Grid xs={12}>
                            <VictoryChart
                                height={250}
                                domainPadding={20}
                                containerComponent={
                                    <VictoryVoronoiContainer
                                        labels={({ datum }) => datum.x + ":" + datum.y}
                                    />
                                }


                            >
                                <VictoryScatter
                                    style={{ data: { fill: "#c43a31" } }}

                                    data={this.state.cuisineTools}
                                />
                                <VictoryAxis
                                    style={{
                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                        axisLabel: { fontSize: 8, padding: 40 },
                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                        ticks: { stroke: "grey", size: 5 },
                                        tickLabels: { fontSize: 5, padding: 1 }
                                    }}
                                    axisLabelComponent={<VictoryLabel dx={-200} dy={-25} />}
                                    tickLabelComponent={
                                        <VictoryLabel angle={-45} textAnchor="end" />
                                    }
                                    label={"Cuisines"}

                                    tickFormat={(label, index) => label} />
                                <VictoryAxis
                                    style={{
                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                        axisLabel: { fontSize: 8, padding: 20 },
                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                        ticks: { stroke: "grey", size: 5 },
                                        tickLabels: { fontSize: 5, padding: 5 }
                                    }}
                                    tickFormat={(label) => label}
                                    label={'no of tools Required'}
                                    dependentAxis />
                                <VictoryTooltip center={{ x: 225, y: 30 }}
                                    pointerOrientation="bottom"
                                    flyoutWidth={150}
                                    flyoutHeight={50}
                                    pointerWidth={150}
                                    cornerRadius={0} />
                            </VictoryChart>

                        </Grid>

                        <Grid xs={12}>
                            <Button variant='outlined' onClick={this.cusineAndTools}>Cuisine And Tools</Button>
                        </Grid>


                        <Grid xs={12}>
                            <VictoryChart
                                height={250}
                                domainPadding={20}
                                containerComponent={
                                    <VictoryVoronoiContainer
                                        labels={({ datum }) => datum.x + ":" + datum.y}
                                    />
                                }


                            >
                                <VictoryScatter
                                    style={{ data: { fill: "#c43a31" } }}

                                    data={this.state.spiceQuantity}
                                />
                                <VictoryAxis
                                    style={{
                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                        axisLabel: { fontSize: 8, padding: 40 },
                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                        ticks: { stroke: "grey", size: 5 },
                                        tickLabels: { fontSize: 5, padding: 1 }
                                    }}
                                    axisLabelComponent={<VictoryLabel dx={-200} dy={-25} />}
                                    tickLabelComponent={
                                        <VictoryLabel angle={-90} textAnchor="end" />
                                    }
                                    label={"spices"}

                                    tickFormat={(label, index) => label} />
                                <VictoryAxis
                                    style={{
                                        axis: { stroke: "#756f6a", strokeWidth: 1 },
                                        axisLabel: { fontSize: 8, padding: 20 },
                                        // grid: { stroke: ({ tick }) => tick > 0.5 ? "red" : "grey" },
                                        ticks: { stroke: "grey", size: 5 },
                                        tickLabels: { fontSize: 5, padding: 5 }
                                    }}
                                    tickFormat={(label) => label}
                                    label={'quantity in cc'}
                                    dependentAxis />
                                <VictoryTooltip center={{ x: 225, y: 30 }}
                                    pointerOrientation="bottom"
                                    flyoutWidth={150}
                                    flyoutHeight={50}
                                    pointerWidth={150}
                                    cornerRadius={0} />
                            </VictoryChart>

                        </Grid>

                        <Grid xs={12}>

                            <Button variant='outlined' onClick={this.spiceQuantity}>spices quantity</Button>
                            {this.state.csvSpiceQuantity && this.state.csvSpiceQuantity.length > 1 && (
                                <Grid xs={6}>
                                    <CSVLink filename={"Spice-Quantity.csv"} data={this.state.csvSpiceQuantity}><Button variant='outlined'>Download CSV</Button></CSVLink>
                                </Grid>

                            )}
                        </Grid>


                    </Grid>

                </Grid>





            </Grid >
        );
    }
}

export default Charts;