import { storage } from "../firebaseConfig";

export default async function uploadImage(storagePlace, file) {
    try {
        const imgref = storage.ref(`${storagePlace}/${file?.name}`);
        await imgref.put(file)
        return await getUrl(storagePlace, file?.name)
    } catch (error) {
        throw error
    }
}

export async function getUrl(storagePlace, id) {
    try {
        return await storage.ref(`${storagePlace}/${id}`).getDownloadURL();
    } catch (error) {
        throw error
    }
}