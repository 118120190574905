import React, { Component } from 'react';

import {FirebaseApi} from '../../firebaseConfig';
import NewRow from './cupdate';

import { DataGrid } from '@material-ui/data-grid';
import { Avatar, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Divider, Grid, IconButton, Paper, Snackbar, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';
import { red } from '@material-ui/core/colors';
import AppContext from '../../config/AppContext';
import { withStyles } from '@material-ui/core/styles';
import { styles } from '../../config/styles';


const header = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 150 },
    { field: "type", headerName: "Type", width: 150 },
    { field: "unit", headerName: "Units", width: 250 },
    { field: "description", headerName: "Description", width: 250 },
]

// Scaling Factor
// https://modernistcuisine.com/mc/how-to-scale-a-recipe/

class Recipes extends Component {
    static contextType = AppContext
    state = {
        openDialog: false,
        dataOf: "recipe",
        dataOfPlural: "Recipes",
        rowData: [],
        selectedRows: [],
        snackbarVisibe: true,
        snackBarMessage: "Fetching data ...",
        actionInProgress: false,
        lastFetchedTimeStamp: null
    }

    toggleDialog = (e) => {
        e.preventDefault();
        this.setState({ openDialog: !this.state.openDialog })
    }

    updateData = (updatedData) => {
        this.setState({ rowData: updatedData, snackbarVisibe: false })
    }

    toggleSnackbar = (message = "", visible = false, actionInProgress = false) => {
        console.log({message,visible,actionInProgress})
        this.setState({ snackBarMessage: message, snackbarVisible: visible, actionInProgress: actionInProgress })
    }

    fetchData = () => {
        this.toggleSnackbar("Fetching data ...", true, false);
        FirebaseApi['recipe'].get().then(function (snapshot) {
            var newData = [];
            snapshot.forEach(function (childSnapshot) {
                var id = childSnapshot.id;
                var data = childSnapshot.data();
                //   newData.push({ id: id, name: data.name, description: data.description, synonyms: data.synonyms});
                newData.push({ ...data, id: id });
            });
            console.log(newData)
            this.updateData(newData);
            this.setState({ lastFetchedTimeStamp: new Date().toGMTString() })
            this.toggleSnackbar("Updated data ... ", true, false)
        }.bind(this));
    }

    addRow = (rowData) => {
        this.toggleSnackbar("Saving ...", true, true)
        FirebaseApi[this.state.dataOf].add({
            ...rowData
        })
            .then(function (docRef) {
                this.toggleSnackbar("Saved ...", true, false);
                // console.log( "Data row created with ID: ", docRef.id);
            }.bind(this))
            .catch(function (error) {
            })
        this.toggleSnackbar("", false, false)
    }
    // Store in hash here and keep updating the variable
    // addMetaData
    // addIngredient(s)
    // addInstruction(s)
    // Final save

    componentDidMount() {
        this.fetchData()
        console.log(this.context)
        this.context.closeDrawer()
    }

    handleDialogClose = () => {
        this.setState({ openDialog: !this.state.openDialog, snackbarVisibe: false })
        this.fetchData()
    }

    deleteRows = () => {
        console.log(this.state.selectedRows)
        var rowsToDelete = this.state.selectedRows
        rowsToDelete.map((row) => {
            FirebaseApi[this.state.dataOf].doc(row.id).delete().then(() => {
                // console.log("Deleted :" + row.name);
            }).catch((e) => {
                // console.log("Failed to delete :" + row.name )
            })
        })
        this.fetchData();
    }

    editRow = () => {

    }

    editRecipe = (recipe) => {

    }

    deleteRecipe = (recipe) => {
        FirebaseApi[this.state.dataOf].doc(recipe.id).delete().then(() => {
            console.log("Deleted :" + recipe.name);
        }).catch((e) => {
            // console.log("Failed to delete :" + row.name )
        })
        this.fetchData();
    }


    render() {
        console.log(this.state.rowData)
        return (
            <Grid container style={{width:'100%',height:'100%'}}>
                {/* <Grid item direction="column" alignItems="center" justifyContent="space-between" style={{width:'100%'}}  >

                    <Grid lg={4} item style={{width:'100%',backgroundColor:'gray',height:150}}>

                    </Grid>
                    <Grid lg={4} item style={{width:'100%',backgroundColor:'green',height:150}}>

                    </Grid>
                </Grid> */}
              
                <Snackbar key={this.state.dataOf} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={this.state.snackbarVisibe} autoHideDuration={6000} onClose={this.toggleSnackbar} message={this.state.snackBarMessage} />
                <Dialog open={this.state.openDialog} onClose={this.handleDialogClose} aria-labelledby="form-dialog-title" >
                    <Button onClick={this.handleDialogClose} color="primary" style={{ position: "absolute", top: 10, right: 10 }}>
                        x
                        </Button>
                    <DialogTitle id="form-dialog-title">Add New {this.state.dataOf}</DialogTitle>
                    <DialogContent>
                        <NewRow toggleSnackbar={this.toggleSnackbar} actionInProgress={this.state.actionInProgress} addRow={this.addRow} />
                    </DialogContent>
                </Dialog>
                <Grid container direction="column" justifyContent="center" alignItems="flex-start" style={{ width:'100%' }} >
                    <Grid item>
                    <Typography variant="h3">
                        {this.state.dataOfPlural}
                    </Typography>

                    </Grid>
                    <Grid item>
                    <Typography variant="caption">Last updated at :{this.state.lastFetchedTimeStamp}</Typography>

                    </Grid>
                   

                   
                    <Grid container direction="row" alignItems="center" justify="flex-start" >
                        <Button variant="contained" onClick={this.fetchData} disabled={this.state.actionInProgress}  >
                            <RefreshIcon />
                        </Button>
                        <Link to='/recipes/new/0'>
                            <Button variant="contained" style={{margin:"10px 0px",marginLeft:5}}>
                                <AddIcon />
                            </Button>
                        </Link>
                        {/* //  <Button variant="contained" onClick={this.editRow} style={{ margin: 5}} disabled={this.state.selectedRows.length ==1 ? false : true}>
                        //     <EditIcon/>
                        // </Button>
                        // <Button variant="contained" onClick={this.deleteRows}style={{ margin: 5}} 
                        // disabled={this.state.selectedRows.length > 0 ? false : true}>
                        //     <DeleteIcon/>
                        // </Button>   */}
                    </Grid>
                 
                </Grid>
                {/* <Divider /> */}
                <Grid container style={{ marginTop:20 }} spacing={2} >
                    {this.state.rowData.map((recipe, index) => (

                        <Grid item lg={4}  sm={6} key={recipe.id} >
                            <Card className={this.props.classes.recipeCard} >
                               
                                <CardActionArea>
                                    <CardHeader
                                        avatar={
                                            <Avatar aria-label="recipe" style={{ backgroundColor: red[500] }}>
                                                R
                                    </Avatar>
                                        }
                                        title={recipe.name}

                                    />
                                    <CardMedia
                                        style={{
                                            height: 0,
                                            paddingTop: '56.25%',
                                        }}
                                        image={recipe.image ? recipe.image[0] : "https://via.placeholder.com/150"}
                                        title={recipe.name}
                                    />
                                    <CardContent style={{height:98,maxHeight:100}}>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            {recipe.description?.substring(0, 180)}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>

                                <CardActions>
                                    <Link to={`/recipes/new/${recipe.id}`}>

                                        
                                            Edit
                                    
                                    </Link>
                                    <Button size="small" color="error" onClick={() => this.deleteRecipe(recipe)}>
                                        Delete
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(Recipes);

{/* <DataGrid onSelectionChange={(newSelection) => this.setState({selectedRows: newSelection.rows})} rows={this.state.rowData} columns={header} pageSize={5} checkboxSelection /> */ }
