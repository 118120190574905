import React from 'react';
import { Typography } from '@material-ui/core';
import ProfileImage from '../../../assets/ProfileCreation/loading-image.png'
import { ProfileLeftContainerStep2 } from '../Styles';

const ProfileImage2 = () => {
  return (
    <ProfileLeftContainerStep2>
      <img src={ProfileImage} alt="step 2" style={{ marginLeft: 160, width: 300, height: 300 }} />
      <Typography variant='h5' style={{ color: 'white' }}>Getting your site ready!</Typography>
      <Typography style={{ color: 'white' }}>Once your site is ready, we will send you a notification through email.</Typography>
      <Typography style={{ color: 'white' }}>Track your process? This might take around 24 hours.</Typography>
    </ProfileLeftContainerStep2>
  )
}

export default ProfileImage2