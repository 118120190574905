import React, { useState, useContext, useEffect } from 'react'
import Drawer from '@mui/material/Drawer';
import { Typography, Box, Grid, TextField, Button } from '@mui/material';
import { Grid4x4 } from '@mui/icons-material';
import AppContext from '../../../config/AppContext';
import CloudServices from '../../../cloud-services-core';
import { Link } from 'react-router-dom';


const AlternateCardLayout = (props) => {
    const AppState = useContext(AppContext);
    const [articles, setArticles] = useState([]);
    console.log(props);
    const [userID, setUserID] = useState(props.id || AppState?.user?.id)




    const getArticles = async () => {
        try{
        console.log(props.id);
        let article = await CloudServices.articles.fetchByCreatorId(userID);
        setArticles(article);
        console.log(article);}
        catch(e){
            console.log(e);
        }
    }

    useEffect(() => {

        getArticles();
        //updateArticles();

    }, [])


    const layout1 = (article) => {
        return (
            <Grid padding='8px' sx={{ display: 'flex', width: 1000, justifyContent: 'center' }} >
                <Grid item style={{ background: ` url(${article.values.image})`, width: '50%' }}>


                </Grid>

                <Grid item style={{ background: '#F7F7F7', display: 'flex', flexDirection: 'column', justifyContent: 'flexStart', alignItems: 'flexStart', padding: 8, width: '50%', height: 130 }}>
                    <Typography item marginBottom='8px' marginTop="8px">
                        {article.values.tags}
                    </Typography>
                    <Typography variant='h5' item >
                        {article.values.name}
                    </Typography>
                    <Typography item marginBottom='8px'>
                        {article.values.description}
                    </Typography>

                    {/* <Typography item>
                        Items goes here
                    </Typography> */}

                </Grid>
            </Grid>)


    }

    const layout2 = (article) => {
        return (

            <Grid padding='8px' sx={{ display: 'flex', width: 1000, justifyContent: 'center' }} >
                <Grid item style={{ background: '#F7F7F7', display: 'flex', flexDirection: 'column', justifyContent: 'flexStart', alignItems: 'flexStart', padding: 8, width: '50%', height: 130 }}>
                    <Typography item marginBottom='8px' marginTop="8px">
                        {article.values.tags}
                    </Typography>
                    <Typography variant='h5' item >
                        {article.values.name}
                    </Typography>
                    <Typography item marginBottom='8px'>
                        {article.values.description}
                    </Typography>



                </Grid>

                <Grid item style={{ background: `url(${article.values.image})`, width: '50%' }}>


                </Grid>

            </Grid>)
    }
    return (

        articles.map((article) => (
            <Grid style={props.style}>
                <Link to={`/createArticle/${article.id}`} style={{ textDecoration: 'none' }}>
                    {articles.indexOf(article) % 2 == 0 ? layout1(article) : layout2(article)}
                </Link>
            </Grid>
        ))


    )
}

export default AlternateCardLayout;

