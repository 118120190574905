import React, { useEffect, useContext, useState } from 'react'
import { Typography, Grid, Button, Divider, ButtonGroup, Avatar } from '@mui/material';
import { Link } from 'react-router-dom'

import Setting1 from '../../../assets/CreatorScreen/Settings1.svg'
import logout from '../../../assets/CreatorScreen/logout.svg'
import Dollar1 from '../../../assets/CreatorScreen/Dollar1.svg';
import Subscribers1 from '../../../assets/CreatorScreen/Subscribers1.svg';
import Vector from '../../../assets/CreatorScreen/Vector.svg';

import Dollar2 from '../../../assets/CreatorScreen/Dollar2.svg';
import Subs2 from '../../../assets/CreatorScreen/Subs2.svg';
import Vector1 from '../../../assets/CreatorScreen/Vector1.svg';
import Edit1 from '../../../assets/CreatorScreen/Edit1.svg';
import CloudServices from '../../../cloud-services-core';
import AppContext from '../../../config/AppContext';



const CreatorNavbar = (props) => {
    const { currentMode, currentScreen } = props;
    const AppState = useContext(AppContext);
    const [userData, setUserData] = useState(AppState.user);
    const [screen, setScreen] = useState('Dashboard');
    const [mode, setMode] = useState(currentMode || 'Creator');
    const { firstName, profileImage, id } = userData;

    const getUser = async () => {
        let userDetails = await CloudServices.user.getUserDetails('2usA40OHzDQWhbjf7pVkS3odtoG3');
        setUserData(userDetails);
        AppState.handleUser(userDetails);
    }

    useEffect(() => {
        //getUser();
    }, [])

    const CreateMode = () => {
        return (
            <>
                <Grid item style={{ padding: 20, paddingTop: 10 }}>
                    <Link to='/creator' style={{ textDecoration: 'none' }}>
                        <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => setScreen('Editor')} >
                            <img alt='' src={currentScreen === 'Dashboard' ? Vector1 : Vector} style={{ height: 25, width: 25 }} />
                            <Typography style={currentScreen === 'Dashboard' ? { color: '#F94C0C', margin: 8 } : { color: '#000', margin: 8 }}>
                                Dashboard
                            </Typography>
                        </Grid>
                    </Link>
                    <Link to='/creator/content-creation' style={{ textDecoration: 'none' }}>
                        <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => setScreen('Content-Creation')} >
                            <img src={Edit1} style={{ height: 25, width: 25 }} alt='' />
                            <Typography style={currentScreen === 'Content-Creation' ? { color: '#F94C0C', margin: 8 } : { color: '#000', margin: 8 }}>
                                Content Creation
                            </Typography>
                        </Grid>
                    </Link>
                    <Link to='/creator/editor' style={{ textDecoration: 'none' }}>
                        <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => setScreen('Editor')} >
                            <img src={Edit1} style={{ height: 25, width: 25 }} alt='' />
                            <Typography style={currentScreen === 'Editor' ? { color: '#F94C0C', margin: 8 } : { color: '#000', margin: 8 }}>
                                Site Editor
                            </Typography>
                        </Grid>
                    </Link>
                    <Link to='/creator/billings' style={{ textDecoration: 'none' }}>
                        <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => setScreen('Billings')} >
                            <img src={currentScreen === 'Billings' ? Dollar2 : Dollar1} style={{ height: 25, width: 25 }} alt='' />
                            <Typography style={currentScreen === 'Billings' ? { color: '#F94C0C', margin: 8 } : { color: '#000', margin: 8 }}>
                                Billings
                            </Typography>
                        </Grid>
                    </Link>
                </Grid>

                <Divider variant='middle' />

                <Grid item style={{ padding: 20, paddingTop: 10 }}>
                    <Link to='/creator/settings' style={{ textDecoration: 'none' }}>
                        <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => setScreen('Settings')} >
                            <img src={Setting1} style={{ height: 25, width: 25 }} alt='' />
                            <Typography style={currentScreen === 'Settings' ? { color: '#F94C0C', margin: 8 } : { color: '#000', margin: 8 }}>
                                Settings
                            </Typography>
                        </Grid>
                    </Link>
                    <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => setScreen('Log Out')} >
                        <img src={logout} style={{ height: 25, width: 25 }} alt='' />
                        <Typography style={currentScreen === 'Log Out' ? { color: '#F94C0C', margin: 8 } : { color: '#000', margin: 8 }}>
                            Log Out
                        </Typography>
                    </Grid>
                </Grid>
            </>
        )
    }

    const ViewMode = () => {
        return (
            <>
                <Grid item style={{ padding: 20, paddingTop: 10 }}>
                    <Link to='/myFeed' style={{ textDecoration: 'none' }}>
                        <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => setScreen('Feed')} >
                            <img src={Vector} style={{ height: 25, width: 25 }} alt='' />
                            <Typography style={screen === 'Feed' ? { color: '#F94C0C', margin: 8 } : { color: '#000', margin: 8 }}>
                                My Feed
                            </Typography>
                        </Grid>
                    </Link>
                    <Link to='/subscriptions' style={{ textDecoration: 'none' }}>
                        <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => setScreen('Subscriptions')} >
                            <img alt='' src={currentScreen === "Manage subscription" ? Subs2 : Subscribers1} style={{ height: 25, width: 25 }} />
                            <Typography style={currentScreen === 'Manage subscription' ? { color: '#F94C0C', margin: 8 } : { color: '#000', margin: 8 }}>
                                Subscribtions
                            </Typography>
                        </Grid>
                    </Link>
                    <Link to='/creatorBillings' style={{ textDecoration: 'none' }}>
                        <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => setScreen('Billings')} >
                            <img alt='' src={currentScreen === 'Billings' ? Dollar2 : Dollar1} style={{ height: 25, width: 25 }} />
                            <Typography style={currentScreen === 'Billings' ? { color: '#F94C0C', margin: 8 } : { color: '#000', margin: 8 }}>
                                Billings
                            </Typography>
                        </Grid>
                    </Link>
                </Grid>
                <Divider variant='middle' />
                <Grid item style={{ padding: 20, paddingTop: 10 }}>
                    <Link to='/settings' style={{ textDecoration: 'none' }}>
                        <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => setScreen('Settings')} >
                            <img src={currentScreen === 'Settings' ? Setting1 : Setting1} alt='' style={{ height: 25, width: 25 }} />
                            <Typography style={currentScreen === 'Settings' ? { color: '#F94C0C', margin: 8 } : { color: '#000', margin: 8 }}>
                                Settings
                            </Typography>
                        </Grid>
                    </Link>
                    <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => setScreen('Logout')} >
                        <img src={logout} style={{ height: 25, width: 25 }} alt='' />
                        <Typography style={currentScreen === 'Logout' ? { color: '#F94C0C', margin: 8 } : { color: '#000', margin: 8 }}>
                            Log Out
                        </Typography>
                    </Grid>
                </Grid>
            </>
        )
    }

    return (
        <Grid style={{ position: 'fixed', background: '#fafbfc', width: 240, height: '95vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >
            <Grid>
                <Grid item style={{ display: 'flex', flexDirection: 'row' }}>
                    <Grid item style={{ marginLeft: 20 }}>
                        {profileImage ? 
                            <Avatar src={profileImage} variant='rounded' /> :
                            <Avatar>{firstName.slice(0,1)}</Avatar>
                        }
                    </Grid>
                    <Grid item style={{ display: 'flex', flexDirection: 'column', marginLeft: 20 }}>
                        <Grid item >
                            <Typography style={{ color: '#000' }}> {firstName} </Typography>
                        </Grid>
                        <Link to={`/chefPage/${id}`} style={{ textDecoration: 'none' }}>
                            <Grid item>
                                <Typography style={{ color: '#000', fontSize: 'smaller' }}> View Live Site </Typography>
                            </Grid>
                        </Link>
                    </Grid>
                </Grid>
                {
                    mode === 'View' ? ViewMode() : CreateMode()
                }
            </Grid>
            <Grid style={{ background: '#fafbfc', padding: 10, display: 'flex', flexDirection: 'column', borderRadius: 2, marginTop: 210, margin: 7, justifyContent: 'center' }}>
                {/* <ButtonGroup size="medium" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 8, }} >
                    <Button variant='contained' onClick={() => setMode('Creator')} style={mode === 'Creator' ? { background: '#F94C0C', width: 100, textTransform: 'none', boxShadow: 'none', border: '1px solid #DBDCDD' } : { border: '1px solid #DBDCDD', boxShadow: 'none', background: '#FFF', color: 'black', width: 100, textTransform: 'none' }}> Create </Button>
                    <Button variant='contained' onClick={() => setMode('View')} style={mode === 'View' ? { background: '#F94C0C', width: 90, textTransform: 'none', boxShadow: 'none', border: '1px solid #DBDCDD' } : { border: '1px solid #DBDCDD', boxShadow: 'none', background: '#FFF', color: 'black', width: 90, textTransform: 'none' }}> View </Button>
                </ButtonGroup> */}
                <Grid>
                    <Grid >
                        <Typography item style={{ marginTop: 10, fontSize: 13, color: '#797979', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            Recipe Platform Version 3.0
                        </Typography>
                    </Grid>
                    <Typography item style={{ marginTop: 7, fontSize: 13, color: '#797979', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        FAQ | T&C | Privacy
                    </Typography>
                </Grid>
            </Grid>
        </Grid >
    )
}

export default CreatorNavbar;