import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid';
import { Button, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import Modal from '@mui/material/Modal';
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";
import { Autocomplete } from '@mui/material';
import { FirebaseApi } from '../../../firebaseConfig';
import { useLocation } from 'react-router-dom';

const MedicalModal = (props) => {
    const [modal, setModal] = useState(true);

    const history = useHistory();
    const [ingredientsLoading, setIngredientsLoading] = useState(false)

    const [allergiesList, setAllergiesList] = useState([])
    const [ingredientList, setIngredientList] = useState([])
    const location = useLocation()
    const [currentEntityData, setCurrentEntityData] = useState(location.state);
    // const [selectedRows, setSelectedRowData] = useState([]);

    const getIngredientsList = async () => {
        setIngredientsLoading(true)
        try {
            const ingredientsFromDB = await FirebaseApi["ingredient"].get()
            const ingredientsData = ingredientsFromDB.docs.map((ingredient) => {
                return {
                    id: ingredient.id,
                    ...ingredient.data()
                }
            });
            setIngredientList(ingredientsData);
        } catch (error) {
            console.log(error)
        }
    }
    const getAllergiesList = async () => {
        setIngredientsLoading(true)
        try {
            const allergiesFromDB = await FirebaseApi["allergies"].get()
            const allergiesData = allergiesFromDB.docs.map((item) => {
                return {
                    id: item.id,
                    ...item.data()
                }
            });
            setAllergiesList(allergiesData);
        } catch (error) {
            console.log(error)
        }
    }

    // CRUD
    const addMedical = (rowData) => {
        // console.log("Add", rowData);
        FirebaseApi['medical'].add({
            ...rowData
        }).then(function (docRef) {
        })
            .catch(function (error) {
            })
    }

    const updateMedical = (data, id) => {
        FirebaseApi['medical'].doc(id).update(data)
            .then((result) => {
                // setSelectedRowData(null)
            })
            .catch((error) => {
                console.log({ error })
            })
        // console.log(data);
    }


    const actionTriggered = (event, data) => {
        if (data.id) {
            // console.log(data, 'updating');
            const { name, ingredientExcluded, allergies } = data
            updateMedical({ name, ingredientExcluded, allergies }, data.id);
            history.goBack();
        }
        else {
            // console.log(data, "adding")
            const { name, ingredientExcluded, allergies } = data
            addMedical({ name, ingredientExcluded, allergies });
            history.goBack();
        }
    }

    const formik = useFormik({
        initialValues: {
            'id': currentEntityData ? currentEntityData.id : '',
            "name": currentEntityData ? currentEntityData.name : '',
            "ingredientExcluded": currentEntityData ? currentEntityData.ingredientExcluded : [],
            "allergies": currentEntityData ? currentEntityData.allergies : [],
            // "ingredientExcludedIDs": [],
            // "imageLink": imageLink,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required("required"),
            // ingredientExcluded: Yup.object().required('required'),
        }),
        onSubmit: values => {
            console.log("input value", values);
            if (currentEntityData) {
                actionTriggered(true, values)
            } else {
                actionTriggered(false, values)
            }
        },
    });

    console.log(formik.values);
    console.log(currentEntityData);


    useEffect(() => {
        if (location.state) {
            setCurrentEntityData(location.state)
        } else {
            // Get the data from the db using the params
        }
        getIngredientsList();
        getAllergiesList();
    }, [])

    return (
        <Modal
            open={modal}
            // onClose={() => setModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <form onSubmit={formik.handleSubmit} style={{ outline: "none" }}>
                <Grid sx={style} container spacing={2} flexDirection='column' justifyContent="space-between">

                    <Grid item style={{ display: "flex", flexDirection: "row" }} justifyContent='space-between' marginBottom='10'>
                        <Typography variant='h6'>{currentEntityData ? 'Edit a restriction' : 'Add a new restriction' }</Typography>
                        <CloseIcon style={{ marginLeft: '100px', cursor: 'pointer' }} onClick={() => history.goBack()} />
                    </Grid>
                    <Grid item>
                        {
                            formik.errors.name && formik.errors.ingredientExcluded ? 
                            <Typography style={{ color: 'red', fontSize: 10 }}>Please fill in the required fields</Typography>: null
                        }
                        {
                            formik.errors.name ? 
                            <Typography style={{ color: 'red', fontSize: 10 }}>Please fill in the name field</Typography>: null
                        }
                        {/* {
                            formik.errors.ingredientExcluded ? 
                            <Typography style={{ color: 'red', fontSize: 10 }}>Please fill in the ingredients field</Typography>: null
                        } */}
                    </Grid>
                    <Grid item>
                        <Typography>Name*</Typography>
                        <TextField
                            id="name"
                            defaultValue={formik.values.name ?? ''}
                            variant="outlined"
                            onChange={formik.handleChange}
                            placeholder="Name"
                            size="small"
                        />
                    </Grid>
                    <Grid item>
                        <Typography>Ingredient*</Typography>
                        <Autocomplete
                            id="ingredientExcluded"
                            style={{ width: "50%", zIndex: 1000, flexGrow: 1 }}
                            options={ingredientList}
                            multiple
                            filterSelectedOptions
                            getOptionLabel={(option) => (option.name ?? option.data)}
                            value={formik.values.ingredientExcluded ?? []}
                            onChange={(e, value) => formik.setFieldValue('ingredientExcluded', value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Medical Restrictions"
                                    variant="outlined"
                                />
                            )}
                            size="small"
                        />
                    </Grid>
                    <Grid item>
                        <Typography>Allergies</Typography>
                        <Autocomplete
                            id="allgeries"
                            style={{ width: "50%", zIndex: 1000, flexGrow: 1 }}
                            options={allergiesList}
                            multiple
                            filterSelectedOptions
                            getOptionLabel={(option) => option.name}
                            value={formik.values.allergies ?? []}
                            onChange={(e, value) => formik.setFieldValue('allergies', value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Medical Restrictions"
                                    variant="outlined"
                                />
                            )}
                            size="small"
                        />
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={() => { formik.handleSubmit(); }}>{currentEntityData ? 'Save' : 'Add'}</Button>
                    </Grid>
                </Grid>
            </form>
        </Modal>
    )
}

export default MedicalModal

const style = {
    marginTop: "5%",
    marginLeft: 'auto',
    marginRight: "auto",
    // transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,

};