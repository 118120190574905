import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
// material ui imports
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import SaveIcon from '@mui/icons-material/Save';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import Modal from '@mui/material/Modal'
import { makeStyles } from '@mui/styles'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import { useFormik } from 'formik'

// components
import { Switch as CustomSwitch } from '../../../components/Switch'
import generateId from '../../../components/GenerateId';
// sub components
import MetaData from './MetaData'
import IngredientInfo from './IngredientInfo'
import IngredientSection from './IngredientSection'
import IngredientCheck from './IngredientCheck'
// import StepDetails from './StepDetails'
import StepDetails from './StepDetails'

import Back from '../../../assets/back.svg'
import AddRecipeContext from '../../../config/AddRecipeContext'
import AppContext from '../../../config/AppContext'
import CloudServices, { ingredients } from '../../../cloud-services-core'
import { FirebaseApi, storage } from '../../../firebaseConfig'
import Upload from '../../../assets/upload.svg'


import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { parse } from 'recipe-ingredient-parser-v2';





const steps = [
    "Recipe Metadata",
    "Ingredient Section",
    "Ingredient Info",
    "Step Details"
]

const useStyles = makeStyles((theme) => ({
    loading: {
        width: 100,
        height: 100,
        color: 'white',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 0,
        animation: "$fadeIn 0.7s linear infinite alternate",
        backgroundColor: 'black',
        borderRadius: '50%'
    },
    "@keyframes fadeIn": {
        "0%": {
            //   transform:'rotate(90deg)',
            marginBottom: 150
        },
        "50%": {
            // transform:'rotate(180deg)',
            marginBottom: 50
        },
        "95%": {
            // transform:'scale(1.2)',
            marginBottom: 0
        },
        "100%": {
            // transform:'rotate(360deg)',
            marginBottom: 0,
            // transform:'scale(1)'
        }
    },
    addRecipeContainer: {
        backgroundColor: '#F6F7FB',
        minHeight: '100vh'
    },
    addRecipeNavContainer: {
        width: '100%',
        margin: 'auto',
        backgroundColor: '#F94C0C',
        marginBottom: 20
    },
    backIconContainer: {
        width: 35,
        height: 35,
        backgroundColor: 'rgba(255,255,255,0.5)',
        borderRadius: '50%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backIcon: {
        color: 'white'
    },
    navItemContainer: {
        flex: 1,
        width: 'auto',
        padding: 5,
        borderRadius: 4,
        cursor: 'pointer',
        backgroundColor: 'rgba(255,255,255,0.5)',
        '&[data-stepno=true]': {
            backgroundColor: 'white'
        }

    },
    navItemLabel: {
        textAlign: 'center',
        color: 'white',
        cursor: 'pointer',
        '&[data-currentstep=true]': {
            color: '#F94C0C'

        },
    },

    // Modal
    modalHeadingContainer: {
        // backgroundColor:theme.palette.secondary.main,
        padding: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 6
    },
    imageHeading: {
        padding: "0 25px"
    },
    emptyImagesContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 124
    },
    imagesContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflowX: 'scroll',
        flexWrap: 'nowrap',
        padding: "10px 20px"
    },
    image: {
        width: 124,
        height: 124,
        borderRadius: 8,
        flexShrink: 0,
        marginRight: 10,
        cursor: 'pointer'
    },
    dragContainer: {
        height: '16vh',
        maxHeight: '20vh',
        padding: "10px 25px",
    },
    drag: {
        borderRadius: 10,
        border: '1px dashed black',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },

    // loading 
    importLoading: {
        position: 'relative',
        width: 100,
        height: 100,
        backgroundColor: 'gray',
        borderRadius: '50%'
    },
    importLoadingRing: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: 100,
        height: 100,
        border: '5px solid black',
        borderRadius: '50%',
        transform: 'scale(1)',
        animation: "$rotate 2s linear infinite alternate"

    },
    "@keyframes rotate": {
        "0%": {
            transform: 'scale(1)',
            borderColor: 'orange'
        },
        "25%": {
            transform: 'scale(1.3)',
            borderColor: 'blue'
        },
        "50%": {
            transform: 'scale(1.5)',
            borderColor: 'green'
        },
        "75%": {
            transform: 'scale(1.3)',
            borderColor: 'gray'
        },
        "100%": {
            transform: 'scale(1)',
            borderColor: 'black'
        }
    },

}))

export default function (props) {
    const AppState = useContext(AppContext)
    const [recipeLoading, setRecipeLoading] = useState(false)
    const [proMode, setProMode] = useState(false)
    const [stepNo, setStepNo] = useState(0)
    const [savingToCloud, setSavingToCloud] = useState(false)
    const [savedSuccessfully, setSavedSuccessfully] = useState(false)

    const [savedRecipeId, setSavedRecipeId] = useState("")

    const [uploadImages, setUploadImages] = useState(false)
    const [imagesLoading, setImagesLoading] = useState(false)

    const [finishStatus, setFinishStatus] = useState(false)

    const [alert, setAlert] = useState(false)

    const [localData, setLocalData] = useState('')

    const classes = useStyles({ stepNo })

    const [initialIngredientsLoad, setInitialIngredientsLoad] = useState(true)


    const [stepDetailonFocus, setStepDetailOnFocus] = useState({
        sectionIndex: null,
        stepIndex: null
    })

    const [images, setImages] = useState({})

    useEffect(() => {
        AppState.closeDrawer()
        const id = props.match.params?.id
        const collection = props.location.search.split('=')[1]
        const local = localStorage.getItem('saveLocally')

        if (local) {
            setAlert(true)
            setLocalData(JSON.parse(local))
        }
        if (id.length > 1) {
            setRecipeLoading(true)
            getCollectionWithId(collection, id)
        }
    }, [])

    // function onBackButtonEvent(e){
    //     console.log(e)

    // // if (!finishStatus) {
    //     if (window.confirm("Are you sure  ?")) {

    //         // setFinishStatus(true)
    //         // your logic
    //         // window.removeEventListener('popstate',e=>onBackButtonEvent(e))
    //         props.history.goBack();
    //     } else {
    //         window.history.pushState(null, null, window.location.pathname);
    //         // setFinishStatus(false)
    //     }
    // // }

    // }

    const addImages = (values) => {


        formik.setFieldValue('images', { ...formik.values.images, ...values })
        setImages(values)

    }
    const collectImages = (e) => {
        setImagesLoading(true)
        // const addingImages = [...images];

        // function getBase64(file) {

        //     return new Promise((resolve) => {
        //         const reader = new FileReader();
        //         reader.readAsDataURL(file); 
        //         reader.onloadend = () => {
        //             const base64data = reader.result;   
        //             resolve(base64data);
        //         }
        //     })
        // }



        var imageObject = {};
        console.log([...Object.keys(formik.values.images)].length)
        if (formik.values.images && [...Object.keys(formik.values.images)].length >= 0) {
            [...Array(e.target.files.length).keys()].map(async (item, index) => {

                const file = e.target.files[item]
                const uploadRef = await storage.child(`images/${formik.values.id}/img-${[...Object.keys(formik.values.images)].length + index}`)
                await uploadRef.put(file)

                const url = await storage.child(`images/${formik.values.id}/img-${[...Object.keys(formik.values.images)].length + index}`).getDownloadURL()

                imageObject[`img-${[...Object.keys(formik.values.images)].length + index}`] = await url


                // addingImages({id:`img-${addingImages.length}`,image:'ASDFASDasdfas234'})
                //  getBase64(e.target.files[item]).then((data)=>addingImages.push({id:`img-${addingImages.length}`,
                //     image:data}))
            });

        }
        else {


            [...Array(e.target.files.length).keys()].map(async (item, index) => {

                const file = e.target.files[item]
                const uploadRef = await storage.child(`images/${formik.values.id}/img-${index}`)
                await uploadRef.put(file)

                const url = await storage.child(`images/${formik.values.id}/img-${index}`).getDownloadURL()

                imageObject[`img-${index}`] = await url


                // addingImages({id:`img-${addingImages.length}`,image:'ASDFASDasdfas234'})
                //  getBase64(e.target.files[item]).then((data)=>addingImages.push({id:`img-${addingImages.length}`,
                //     image:data}))
            });
        }

        // let vs = {
        //     ...imageObject
        // }
        // console.log(vs)
        addImages(imageObject)
        setImagesLoading(false)
        getImages(formik.values.id)
    }
    async function getImages(id) {

        var listRef = await storage.child(`images/${id}`)

        var res = await listRef.listAll()

        var img = {}
        res.items.forEach(async (itemRef, index) => {
            // All the items under listRef.

            try {

                const url = await storage.child(`images/${id}/${itemRef.name}`).getDownloadURL()

                console.log(url)

                // recipe.recipe.images[`image-${index}`] =  url
                img[`image-${index}`] = url

                console.log(img)
                formik.setFieldValue('images', { ...formik.values.images, ...img })


            }
            catch (err) {
                console.log({ err })
            }

        });


    }

    const setFetchedRecipeValues = {
        'drafts': (recipe) => {



            // console.log({recipe})
            // checking wether it is new recipe or old 
            // let newRecipe = recipeDetails.recipeInstructions;
            recipe.recipe.images = {}
            var images = {}

            getImages(recipe.id)
            const ingredients = recipe.recipe.recipeIngredient.map(ingredient => {
                // console.log(ingredient)
                if (ingredient.ingredient) {
                    const parsedData = parse(ingredient.ingredient)
                    // console.log({parsedData})
                    const parsedName = parsedData.ingredient.split('(')[0]
                    return ({
                        id: generateId(),
                        ...ingredient,
                        ingredient: parsedName
                    })


                }
                else {
                    // console.log({ingredient})
                    const parsedData = parse(ingredient)
                    // console.log({parsedData})
                    const parsedName = parsedData.ingredient.split('(')[0]
                    return ({
                        id: generateId(),
                        ...parsedData,
                        ingredient: parsedName
                    })
                }



            })
            recipe.recipe.recipeIngredient = ingredients
            if (recipe.recipe.proMode === undefined) {
                let newRecipeInstructions = []
                let instructionStepTrue = false
                var combiningHowToSteps = [{
                    '@type': 'HowToSection',
                    id: 0,
                    ingredients: [],
                    derivedIngredients: [],
                    itemListElement: [],
                    name: 'main'
                }]

                var howToSectionInstructions = []
                recipe.recipe.recipeInstructions.map((instruction, index) => {
                    if (instruction['@type'] === 'HowToStep') {
                        instructionStepTrue = true
                        combiningHowToSteps[0].itemListElement.push({
                            id: generateId(),
                            image: '',
                            ingredients: [],
                            parameters: {
                                duration: {
                                    unit: 'sec',
                                    time: 0
                                },
                                temperature: 'low',
                                toolsUsed: []
                            },
                            ...instruction
                        })





                    }
                    else {


                        instruction.id = index + 1
                        instruction.ingredients = []
                        instruction.derivedIngredients = []
                        if (instruction.itemListElement) {

                            instruction.itemListElement.map((element) => {
                                element.id = generateId()
                                element.image = ''
                                element.ingredients = []
                                element.parameters = {
                                    duration: {
                                        unit: 'sec',
                                        time: 0
                                    },
                                    temperature: 'low',
                                    toolsUsed: []
                                }
                                return element
                            })
                        }

                        howToSectionInstructions.push(instruction)



                    }
                })
                newRecipeInstructions = instructionStepTrue ? [...combiningHowToSteps, ...howToSectionInstructions] : [...howToSectionInstructions]
                // console.log(newRecipeInstructions)
                // if(recipe.recipe.recipeInstructions.some(instruction=>instruction['@type']==='HowToStep')){


                // }
                // else{


                // }
                // let newRecipe = recipe.recipe.recipeInstructions.map((instruction,index)=>{
                //     instruction.id = index+1
                //     instruction.ingredients = []
                //     instruction.derivedIngredients = []
                //     instruction.itemListElement.map((element)=>{
                //         element.id = generateId()
                //         element.image = ''
                //         element.ingredients = []
                //         element.parameters = {
                //             duration:{
                //                 unit:'sec',
                //                 time:0
                //             },
                //             temperature:'low',
                //             toolsUsed:[]
                //         }
                //         return element
                //     })
                //     return instruction
                // })

                recipe.recipe.recipeInstructions = newRecipeInstructions
            }



            console.log({ recipe: recipe.recipe })
            formik.setValues({ ...recipe.recipe, id: recipe.id, images })

        },
        'recipe': (recipe) => {

            var imagesFromRecipe = []
            recipe.images = {}
            getImages(recipe.id)
            // const newRecipe = recipe.recipeInstructions.map(instruction=>{

            //     instruction.itemListElement.map((element,index)=>{

            //         if(element.image?.image){
            //             // console.log(element.image)

            //             recipe.images[`image-${index}`] = element.image?.image
            //             imagesFromRecipe.push({id:`image-${imagesFromRecipe.length}`,image:element.image?.image})
            //             element.image = `image-${imagesFromRecipe.length}`
            //         }

            //     //    delete element.parameters.pro

            //         return element
            //     })

            //     return instruction
            // })

            // console.log({newRecipe})

            // const recipeIngredient = recipe.recipeIngredients
            // delete recipe.recipeIngredients
            // const newRecip = { ...recipe,recipeIngredient}

            setImages(imagesFromRecipe)


            formik.setValues(recipe)
        }
    }

    const getCollectionWithId = async (collection, id) => {

        try {
            const recipe = await CloudServices.crud.getCollectionWithId(collection, id)
            console.log({ recipe })

            setFetchedRecipeValues[collection]({ ...recipe, id })
            setRecipeLoading(false)

            // formik.setValues(recipe)

        } catch (error) {
            console.log(error)
        }
    }
    const formik = useFormik({
        initialValues: {
            '@context': '',
            '@id': '',
            '@type': '',
            aggregateRating: {
                '@type': "AggregateRating",
                ratingCount: "",
                ratingValue: ""
            },
            image: [],
            images: {

            },
            author: {
                '@type': "Person",
                name: 'Team RIKU'
            },
            nutrition: {
                "@type": "NutritionInformation",
                calories: "",
                carbohydrateContent: "",
                fatContent: "",
                proteinContent: ""
            },
            difficultyType: '',
            keywords: [],
            name: '',
            recipeCategory: [],
            recipeCuisine: [],
            recipeIngredient: [


            ],
            recipeInstructions: [
                {
                    id: '0',
                    '@type': 'HowToSection',
                    itemListElement: [
                        {
                            id: generateId(),
                            text: "",
                            ingredients: [],
                            parameters: {
                                toolsUsed: [],
                                temperature: 'off',
                                duration: {
                                    time: 0,
                                    unit: 'sec'
                                },
                                pro: {
                                    cookingAction: '',
                                    stirring: false,
                                    temperature: {
                                        value: 0,
                                        unit: 'c'
                                    },
                                    duration: {
                                        time: 0,
                                        unit: 'sec'
                                    },
                                    power: {
                                        value: 0,
                                        unit: 'watt'
                                    }
                                }

                            },
                            image: ''
                        }


                    ],
                    name: 'Preparation',
                    derivedIngredients: [],
                    ingredients: []
                },


            ],
            mealType: [],
            prepTime: '',
            cookTime: '',
            totalTime: "",
            recipeYield: "",
            proMode: false,
            suitableForDiet: [],
            video: {
                '@type': "VideoObject",
                contentUrl: "",
                description: "",
                name: ""
            }
        },
        onSubmit: values => {
            // setSavingToCloud(true)
            console.log(values)
            //   window.alert(JSON.stringify(values, null, 2));

            const checkIfRecipeAlreadySaved = () => {
                return props.match.params?.id.length > 1 && props.location.search.split('=')[1] === 'recipe'
            }

            const checkIfRecipeIsFromDrafts = () => {
                return props.location.search.split('=')[1] === 'drafts'
            }



            if (checkIfRecipeAlreadySaved()) {
                FirebaseApi['recipe'].doc(props.match.params?.id).update(JSON.parse(JSON.stringify({ ...values, proMode, editedOn: new Date() })))
                    .then(() => {
                        setSavedSuccessfully(true)
                        setSavingToCloud(false)
                    })
                    .catch((error) => {
                        setSavingToCloud(false)
                        console.log(error)
                    })

            }
            else if (checkIfRecipeIsFromDrafts()) {
                console.log(props.match.params?.id)
                FirebaseApi['recipe'].doc(props.match.params?.id).set(JSON.parse(JSON.stringify({ ...values, proMode, datePublished: new Date() })))
                    .then((data) => {


                        setSavedSuccessfully(true)
                        setSavingToCloud(false)
                    })
                    .catch((error) => {
                        setSavingToCloud(false)
                        console.log(error)
                    })

                FirebaseApi['drafts'].doc(props.match.paramas?.id).delete().then(() => {
                    console.log('deleted from drafts')
                }).catch((err) => {
                    console.log(err)
                })

            }
            else {

                FirebaseApi['recipe'].doc(formik.values.id).set(JSON.parse(JSON.stringify({ ...values, proMode, datePublished: new Date() })))
                    .then((data) => {

                        setSavedSuccessfully(true)
                        setSavingToCloud(false)
                        localStorage.removeItem('drafted')
                        localStorage.removeItem('saveLocally')
                    })
                    .catch((error) => {
                        setSavingToCloud(false)
                        console.log(error)
                    })
            }
        },
    });



    const changeStepNumber = (no) => {
        setStepNo(no)
    }

    const renderSection = (stepNo) => {
        switch (stepNo) {
            case 0:
                return <MetaData {...{ ...props }} />
            case 1:
                return <IngredientCheck />
            case 2:
                return <IngredientInfo />
            case 3:
                return <StepDetails />
            default:
                return <MetaData />
        }
    }


    const saveToDraft = async () => {
        try {

        }
        catch (err) {

        }
    }

    const changeSection = (index) => {
        setStepNo(index)

        if (formik.values.name.length > 0) {

            localStorage.setItem('saveLocally', JSON.stringify(formik.values))
            // localStorage.setItem('images',JSON.stringify(formik.values.images))
        }

    }


    const continueWithSavedData = () => {
        var localData = localStorage.getItem('saveLocally')
        formik.setValues(JSON.parse(localData))
        setAlert(false)

    }

    const deleteSavedData = () => {
        localStorage.removeItem('saveLocally')
        localStorage.removeItem('drafted')
        setAlert(false)

    }


    const defaultStepDetail = () => {
        setStepDetailOnFocus({
            sectionIndex: null,
            stepIndex: null
        })
    }

    const changeMode = () => {
        setProMode(!proMode)
        defaultStepDetail()
    }





    return recipeLoading ? (
        <Grid item container direction="row" justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
            <Grid item>
                <div
                    className={classes.importLoading}>
                    <div
                        className={classes.importLoadingRing}
                    >

                    </div>
                    <div className={classes.importLoadingReverseRing}>

                    </div>
                </div>


            </Grid>

        </Grid>
    ) : (
        <AddRecipeContext.Provider value={{ formik, images, proMode, changeStepNumber, setUploadImages, initialIngredientsLoad, setInitialIngredientsLoad, stepDetailonFocus, setStepDetailOnFocus, defaultStepDetail }}>
            <Box className={classes.addRecipeContainer} >
                <Box className={classes.addRecipeNavContainer} >
                    <Container maxWidth="xl">
                        <Grid container spacing={2} direction="row" alignItems="center" paddingY={2}  >

                            <Grid item md={8} spacing={2} container   >
                                <Grid item xs={'auto'}>

                                    <Box onClick={() => props.history.goBack()} className={classes.backIconContainer} >
                                        <KeyboardBackspaceIcon className={classes.backIcon} />
                                    </Box>

                                </Grid>
                                <Grid item xs={11} container direction="row" alignItems="center">

                                    {steps.map((label, index) => (
                                        <Grid item onClick={() => changeSection(index)} key={index} className={classes.navItemContainer} data-stepno={stepNo == index} >
                                            <Typography className={classes.navItemLabel} data-currentstep={stepNo == index} >{label}</Typography>
                                        </Grid>
                                    ))}
                                </Grid>



                            </Grid>
                            <Grid item md={4}>


                                <Grid container columnSpacing={1} direction="row" alignItems="center" justifyContent="flex-end">
                                    <Grid item marginRight={1} >
                                        <Grid container columnSpacing={2}>

                                            <Grid item>
                                                <Typography style={{ fontSize: 19, color: 'white' }}>Pro</Typography>
                                            </Grid>
                                            <Grid item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                                <CustomSwitch disabled={props.match.params?.id == 0} checked={proMode} onChange={() => changeMode()} />

                                            </Grid>

                                        </Grid>
                                    </Grid>
                                    <Grid item  >
                                        <Box style={{ padding: 5, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', border: '1px solid white', borderRadius: 4 }}>

                                            <ShoppingBasketIcon style={{ color: 'white' }} />
                                        </Box>
                                    </Grid>
                                    <Grid item >
                                        <Box style={{ padding: 5, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', border: '1px solid white', borderRadius: 4 }}>

                                            <RemoveRedEyeIcon style={{ color: 'white' }} />
                                        </Box>
                                    </Grid>
                                    <Grid item >
                                        <Box style={{ padding: 5, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', border: '1px solid white', borderRadius: 4 }}>

                                            <SaveIcon onClick={saveToDraft} style={{ color: 'white' }} />
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <Button color="info" disabled={stepNo <= 2} variant="outlined" onClick={() => formik.submitForm()}  >Publish</Button>
                                    </Grid>


                                </Grid>
                            </Grid>

                        </Grid>

                    </Container>
                </Box>
                <Container maxWidth="xl">
                    {savingToCloud ? (
                        <Grid container direction="row" justifyContent="center" alignItems="center" style={{ height: '90vh' }} >
                            <Grid item>
                                <p style={{ fontSize: 70 }} className={classes.loading}></p>

                            </Grid>

                        </Grid>
                    ) : savedSuccessfully ? (
                        <Grid container direction="column" justifyContent="center" alignItems="center" style={{ height: '90vh' }}>
                            <Grid item>
                                <CheckCircleOutlineIcon style={{ color: 'green', fontSize: 100 }} />
                            </Grid>
                            <Grid item>
                                <Typography style={{ fontSize: 28 }} >Save Successfully</Typography>
                            </Grid>
                            <Grid item>
                                {savedRecipeId.length > 0 && (
                                    <Link to={`/recipe/${savedRecipeId}?collection=recipe`} style={{ textDecoration: 'none' }}>

                                        <Button variant="outlined">go to recipe</Button>
                                    </Link>

                                )}
                            </Grid>
                        </Grid>
                    ) :
                        renderSection(stepNo)
                    }
                    <Modal
                        open={uploadImages}
                        disableAutoFocus
                        onClose={() => setUploadImages(false)}
                        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}

                    >
                        <Box style={{ width: '60%', height: 'clamp(415px,60%,47vh)', backgroundColor: 'white', borderRadius: 6 }}>

                            <Grid container >
                                <Grid item xs={12}>
                                    <Box className={classes.modalHeadingContainer}>

                                        <Grid container spacing={1} direction="row" justifyContent="space-between" alignItems="center">
                                            <Grid item >
                                                <Typography color="white">Uploading your images</Typography>
                                            </Grid>
                                            <Grid item onClick={() => setUploadImages(false)} >
                                                <Typography color="white" style={{ cursor: 'pointer' }}>X</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} >

                                    <Box style={{ paddingTop: 20 }}>
                                        <Grid container>

                                            <Grid item xs={12}>
                                                <Box className={classes.imageHeading}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography>Recipe Image Bank</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography>Drag and drop images to main image section. Rest will be saved for recipe steps.</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box className={classes.imagesContainer} >

                                                    {formik.values?.images ? [...Object.values(formik.values.images)].map((file, index) => {

                                                        return (


                                                            <img key={index} src={file} className={classes.image} />

                                                        )
                                                    }) : (
                                                        <Box className={classes.emptyImagesContainer} >


                                                            <Typography>No Images</Typography>


                                                        </Box>
                                                    )}

                                                </Box>

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box pl={3}>

                                                    <Typography>Main images</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={8} >
                                                <Box className={classes.dragContainer}  >

                                                    <Box className={classes.drag} >

                                                        <label htmlFor="upload">
                                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
                                                                <img src={Upload} />
                                                                <p style={{ margin: 5 }}>upload</p>
                                                            </div>
                                                        </label>
                                                        <input id="upload" type="file" accept="image/png, image/jpeg" multiple onChange={e => collectImages(e)} style={{ display: 'none' }} />
                                                    </Box>
                                                </Box>

                                            </Grid>
                                            <Grid item xs={4}>
                                                {/* <Box  style={{height:'16vh',maxHeight:'20vh',padding:"0 25px"}}> */}
                                                <Grid container direction="row" justifyContent="flex-end" alignItems="end" style={{ height: '100%', paddingRight: 25 }}>
                                                    <Grid item>
                                                        <label htmlFor="upload">

                                                            <Button component="span" >Add More</Button>
                                                        </label>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button onClick={() => setUploadImages(false)}>Cancel</Button>
                                                    </Grid>
                                                </Grid>
                                                {/* </Box> */}

                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>


                    </Modal>
                    <Modal
                        open={alert}
                        disableAutoFocus
                        onClose={() => setAlert(false)}
                        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <Box style={{
                            width: '50%',
                            height: '20%',
                            backgroundColor: 'white',
                            borderRadius: 6,
                            display: 'flex',
                            flexDirection: 'column',
                            padding: 20

                        }}>
                            <Box style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography>
                                    {`You have locally saved data with recipe name "${localData.name}". do you want to continue with that data ? or delete saved data and start new?`}

                                </Typography>

                            </Box>
                            <Box style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                gap: 15
                            }}>
                                <Button variant="outlined" color="secondary" onClick={deleteSavedData} >Delete </Button>
                                <Button variant="contained" onClick={continueWithSavedData} >Continue </Button>
                            </Box>

                        </Box>

                    </Modal>

                </Container>
            </Box>
        </AddRecipeContext.Provider>
    )
}