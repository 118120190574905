import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import { makeStyles } from '@mui/styles'
import { ButtonGroup, TextField, TextareaAutosize, Select, MenuItem, ToggleButtonGroup, ToggleButton, Checkbox } from '@mui/material';
import Fuse from 'fuse.js' // need to remove and search alternative.
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CloseIcon from '@mui/icons-material/Close';
import classNames from 'classnames'; // need to remove and search alternative.
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

///accordion import MUI
import { styled } from '@mui/material/styles'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary from '@mui/material/AccordionSummary'

import { Autocomplete } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

/// LeftNav
import Collapse from '@mui/material/Collapse';
import { useSpring, animated } from 'react-spring';
import PropTypes from 'prop-types';
import LaunchIcon from '@mui/icons-material/Launch';

//formik
import { FieldArray } from 'formik';

// Internal Image Imports
import Upload from '../../../../assets/upload.svg'
import generateId from '../../../../components/GenerateId';
import ImageReplace from '../../../../assets/ImageReplace.jpg';
import ModalGallery from '../../../../assets/ModalGallery.svg';
import AddStepImage from '../../../../assets/AddStepImage.svg';
import DeleteAccordion from '../../../../assets/DeleteAccordion.svg';
import CloseIconn from '../../../../assets/CloseIconn.svg';

import CreateRecipeContext from '../../../../config/CreateRecipeContext'

// cloud services
import { FirebaseApi } from '../../../../firebaseConfig'

function TransitionComponent(props) {
    const style = useSpring({
        from: {
            opacity: 0,
            transform: 'translate3d(20px,0,0)',
        },
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
        },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

TransitionComponent.propTypes = {
    /**
     * Show the component; triggers the enter or exit states
     */
    in: PropTypes.bool,
};

const StepsAccordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
    '&:before': {
        display: 'none',
    },
    width: 1238,
    marginTop: -18,
    marginRight: -18,
    marginLeft: -18,
}));

const StepsAccordionSummary = styled((props) => (
    <MuiAccordionSummary
        {...props}
    />
    ))(({ theme }) => ({
    backgroundColor:
        '#FFF',
    flexDirection: 'row-reverse',
    width: '100%',
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: 0,
    flex: 1,
    width: 1238,
}));

const StepsAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: 0,
    flex: 1,
}));

export const RecipeStepsAccordion = (props) => {
    const classes = useStyles()
    const { formik, proMode } = useContext(CreateRecipeContext)
    const { index, value } = props
    const { values, handleChange, setFieldValue } = formik
    const [stepHover, setStepHover] = useState(false);
    const [stepsUploadImages, setStepsUploadImages] = useState(false);
    const [stepDetailonFocus, setStepDetailOnFocus] = useState({
        sectionIndex: null,
        stepIndex: null
    })

    // Steps
    // tools ingredient action
    const [ingredientsFuse, setIngredientsFuse] = useState(null)
    const [toolsFuse, setToolsFuse] = useState(null)
    const [unitsFuse, setUnitsFuse] = useState(null);
    const [prepStyleFuse, setPrepStyleFuse] = useState(null);
    const [newIngredientSuggestions, setNewIngredientSuggestions] = useState([])
    const [newToolSuggestions, setNewToolSuggestions] = useState([])
    const [copyStepMode, setCopyStepMode] = useState(false);
    const [tempValueEdited, setTempValueEdited] = useState(false);
    // buttons
    const [cookingStyleButtonActive, setCookingStyleButtonActive] = useState("Closed");
    const [stirringButton, setStirringButton] = useState("On");
    const [temperatureButton, setTemperatureButton] = useState("Off");
    // display images
    const [currentStepImage, setCurrentStepImage] = useState('');
    // image
    const [uploadedImage, setUploadedImage] = useState(null);
    const [wrongDurationFormat, setWrongDurationFormat] = useState(false);
    const [stepExpand, setStepExpand] = useState(false);
    // Ingredient 
    const [ingredientSwap, setIngredientSwapModal] = useState(false);
    const [outOfSuggestion, setOutOfSuggestions] = useState(false);
    const [currentIngredient, setCurrentIngredient] = useState(null);

    // Ingredients
    const getIngredientsList = async () => {
        try {
            const ingredientsFromDB = await FirebaseApi["ingredient"].get()
            const ingredientsData = ingredientsFromDB.docs.map((ingredient) => {
                return {
                    id: ingredient.id,
                    ingredient: ingredient.data().name,
                    ...ingredient.data()
                }
            })
            const fuseInstance = new Fuse(ingredientsData, {
               useExtendedSearch: true,
                keys: ["name"]
            })
            setIngredientsFuse(fuseInstance)
            //checkRecipeIngredientsInOurDB['exactMatch'](fuseInstance)
        } catch (error) {
            console.log(error)
        }
    }

    const addingNewIngredient = (e) => {
        const splittedValue = e.target.value.split(" ")
        if (Number(splittedValue[0]) > 0 && splittedValue[1]) {
            const suggestedUnits = checkForUnitsOrSimilarUnits(unitsFuse, splittedValue);
            const suggestedPreps = checkForPrepsOrSimilarPreps(prepStyleFuse, splittedValue);
            if (suggestedUnits.length > 0) {
                const suggestedIngredients = checkForIngredientOrSimilarIngredients(ingredientsFuse, splittedValue[3] ? splittedValue[3] : 'a');
                const ingredient = suggestedUnits.map(unit => {
                    return suggestedPreps.map(prep => {
                        return { ...prep, ...unit };
                    })
                })
                const finalIngredient = ingredient.flat();
                const final = suggestedIngredients.map(ing => {
                    return finalIngredient.map(unit => {
                        return { ...unit, ...ing }
                    })
                })
                setNewIngredientSuggestions(final.flat());
            }
        }
        else {
            const suggestedIngredients = checkForIngredientOrSimilarIngredients(ingredientsFuse, e.target.value);
            setNewIngredientSuggestions(suggestedIngredients)
            if (suggestedIngredients.length === 0) {
                setOutOfSuggestions(true);
            }
        }
    }
    const checkForIngredientOrSimilarIngredients = (ingredientsFuse, value) => {
        return ingredientsFuse.search(`'${value.replace(/[^a-zA-Z ]/g, "")}`).map(i => ({ ...i.item }))
    }
    const checkForUnitsOrSimilarUnits = (fuse, name) => {
        return unitsFuse?.search(`'${name[1]?.replace(/[^a-zA-Z ]/g, "")}`).map(i => ({ ...i.item, quantity: name[0] }))
    }
    const checkForPrepsOrSimilarPreps = (fuse, name) => {
        return prepStyleFuse?.search(`'${name[2]?.replace(/[^a-zA-Z ]/g, "")}`).map(i => ({ ...i.item }))
    }
    const handleIngredientModal = (value) => {
        setIngredientSwapModal(value)
    }
    const handleAddingIngredient = (index, stepIndex, value) => {
        if (value.length > 0 || value.name != undefined) {
            if (values.recipeInstructions[index].itemListElement[stepIndex].ingredients.length > 0) {
                values.recipeInstructions[index].itemListElement[stepIndex].ingredients.map((item) => {
                    if (item.quantity == undefined || null) {
                        values.recipeInstructions[index].itemListElement[stepIndex].ingredients.length = 0;
                        setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].ingredients`, value);
                    } else {
                        if (value[0] != undefined) {
                            setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].ingredients`, [...values.recipeInstructions[index].itemListElement[stepIndex].ingredients, value[0]])
                        } else {
                            setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].ingredients`, [...values.recipeInstructions[index].itemListElement[stepIndex].ingredients, value])
                        }
                    }
                })
            } else {
                setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].ingredients`, value)
            }
        }
    }

    const handleMainIngredient = (value) => {
        if (value.length > 0 || value.name != undefined) {
            if (values.recipeIngredient.length > 0) {
                if (values.recipeIngredient[0].name == undefined) {
                    setFieldValue('linkIngredients', values.recipeIngredient)
                    setFieldValue('recipeIngredient', value)

                } else if (values.recipeIngredient[0].name != undefined) {

                    if (value[0] != undefined) {
                        setFieldValue('recipeIngredient', [...values.recipeIngredient, value[0]]);
                    } else {
                        setFieldValue('recipeIngredient', [...values.recipeIngredient, value]);
                    }
                }


            } else {
                setFieldValue('recipeIngredient', value)
            }
        }
    }



    const handleCopyStepMode = () => {
        setCopyStepMode(!copyStepMode)
    }

    // tools
    const getToolsList = async () => {
        try {
            const toolsFromDB = await FirebaseApi["tools"].get()
            const toolsData = toolsFromDB.docs.map((tool) => {
                return {
                    id: tool.id,
                    tool: tool.data().name,
                    ...tool.data()
                }
            })
            const fuseInstance = new Fuse(toolsData, {
                useExtendedSearch: true,
                keys: ["name"]
            })
            setToolsFuse(fuseInstance)
            //checkRecipeIngredientsInOurDB['exactMatch'](fuseInstance)
        } catch (error) {
            console.log(error)
        }
    }

    const addingNewTool = (e) => {
        getToolsList()
        setNewToolSuggestions(checkForToolOrSimilarTools(toolsFuse, e.target.value))
    }
    const checkForToolOrSimilarTools = (fuse, name) => {
        return toolsFuse?.search(`'${name.replace(/[^a-zA-Z ]/g, "")}`).map(i => i.item)
    }


    // units
    const getUnitsList = async () => {

        try {
            const unitsFromDB = await FirebaseApi["units"].get()

            const unitsData = unitsFromDB.docs.map((unit) => {
                return {
                    id: unit.id,
                    unit: unit.data().name,
                    ...unit.data()
                }
            })


            const fuseInstance = new Fuse(unitsData, {
                useExtendedSearch: true,
                keys: ["name", "synonyms"]
            })
            setUnitsFuse(fuseInstance)

            //checkRecipeIngredientsInOurDB['exactMatch'](fuseInstance)
        } catch (error) {
            console.log(error)
        }
    }

    // prepStyles
    const getPrepStyles = async () => {

        try {
            const prepFromDB = await FirebaseApi["prepStyle"].get()

            const prepData = prepFromDB.docs.map((prep) => {
                return {
                    id: prep.id,
                    prepStyle: prep.data().name,
                    ...prep.data()
                }
            })


            const fuseInstance = new Fuse(prepData, {
                useExtendedSearch: true,
                keys: ["name", "synonyms"]
            })
            setPrepStyleFuse(fuseInstance)

            //checkRecipeIngredientsInOurDB['exactMatch'](fuseInstance)
        } catch (error) {
            console.log(error)
        }
    }

    /// Cooking, stir, action, temp, power, duration
    const handleCookingButton = (e) => {
        setCookingStyleButtonActive(e.target.value);
    }

    const handleCookActionChange = (e, index, stepIndex) => {
        setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].parameters.pro.cookingAction`, e.target.value);
    }
    const handleStirringButton = (e) => {
        setStirringButton(e.target.value);
    }

    const handleTemperatureButton = (e) => {
        setTemperatureButton(e.target.value);
    }

    const checkString = (inputElem) => {
        var regex = /^\d+[hms]$|^\d+[h]\s\d+[m](?:\s\d+[s])?$|^\d+m\s\d+s$/gm

        if (!regex.test(inputElem)) {
            setWrongDurationFormat(true);
        } else {
            console.log("Works");
        }
    }

    const onDragEnter = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const onDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const onDragLeave = (e, index, stepIndex) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const onDrop = (e, index, stepIndex) => {
        e.preventDefault();
        const [file] = e.dataTransfer.files;
        const reader = new FileReader();
        reader.onload = (e) => (
            setUploadedImage(e.target.result),
            saveToStep(e, index, stepIndex, e.target.result))
        reader.readAsDataURL(file);
    };

    const saveToStep = (e, index, stepIndex, draggedImage) => {
        var count = 0;
        setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}.images`, { ...values?.recipeInstructions[index].itemListElement[stepIndex].images, draggedImage })
        count += 1;
    }

    const selectImageForStep = (file) => {
        setCurrentStepImage(file);
    }

    const removeImageStep = (index, stepIndex) => {
        delete values.recipeInstructions[index].itemListElement[stepIndex].images[currentStepImage]
        setFieldValue(`recipeInstructions[${stepDetailonFocus?.sectionIndex}].itemListElement[${stepDetailonFocus?.stepIndex}].images`, values.recipeInstructions[index].itemListElement[stepIndex].images)
    }

    // refer previous coomits in case of error
    const collectImagesWithIndex = async (e, sectionIndex = 0, stepIndex = 0) => {
        var imageObject = {};
        if (formik.values.images && [...Object.keys(formik.values.images)].length >= 0) {
            await Promise.all([...Array(e.target.files.length).keys()].map(async (item, index) => {
                const data = await getBase64(e.target.files[item])
                imageObject[`img-${sectionIndex}-${stepIndex}-${[...Object.keys(formik.values.images)].length + index}`] = data
            }));
        }
        else {
            await Promise.all([...Array(e.target.files.length).keys()].map(async (item, index) => {
                const data = await getBase64(e.target.files[item])
                imageObject[`img-${sectionIndex}-${stepIndex}-${index}`] = data
            }));
        }
        if (sectionIndex == 0 && stepIndex == 0) {
            setFieldValue('images', { ...formik.values.images, ...imageObject })
        }
        if (sectionIndex >= 1 && stepIndex >= 1) {
            setFieldValue(`recipeInstructions[${sectionIndex - 1}].itemListElement[${stepIndex - 1}].images`, { ...formik.values.recipeInstructions[sectionIndex - 1].itemListElement[stepIndex - 1].images, ...imageObject })
        }
    }

    function getBase64(file) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
            }
        })
    }

    useEffect(() => {
        getToolsList();
        getIngredientsList()
        getUnitsList();
        getPrepStyles();
    }, [])

    const toggleStepAccordion = () => {
        setStepExpand(!stepExpand);
    }

    const deleteIngredient = (index, stepIndex, item) => {
        const filteredStep = values.recipeInstructions[index].itemListElement[stepIndex].ingredients.filter((value) => value.id != item.id);
        setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].ingredients`, filteredStep);
        const filteredItem = values.recipeIngredient.filter((value) => value?.id != item.id);
        setFieldValue('recipeIngredient', filteredItem);
        handleIngredientModal(false);
    }
    
    return (
        <AccordionDetails>
            <div >
                <FieldArray name={`recipeInstructions[${index}].itemListElement`} >
                    {(elementHelpers) => {
                        const { insert, remove } = elementHelpers
                        return (
                            <div  >
                                {value?.itemListElement?.length > 0 && value?.itemListElement?.map((step, stepIndex) =>
                                (
                                    <div key={step.id} className={classes.accordionDetail}>
                                        <div className={stepDetailonFocus.sectionIndex === index && stepDetailonFocus.stepIndex === stepIndex && stepExpand ? classes.accordionBackgroundStepExpand : classes.accordionBackground}>
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
                                                <StepsAccordion onMouseEnter={() => setStepHover(true)} expanded={stepExpand}>
                                                    <Grid style={{ display: 'flex', flexDirection: 'row', width: 1300 }}>
                                                        <Grid item style={{ width: 1238 }}>
                                                            <StepsAccordionSummary
                                                                className={classNames(classes.stepsAccordionSummary, classes.stepsAccordionSummary2)}
                                                                style={
                                                                    stepDetailonFocus.sectionIndex === index && stepDetailonFocus.stepIndex === stepIndex && stepExpand ? {
                                                                        background: '#387CF0',
                                                                        color: '#FFF',
                                                                    } : {
                                                                        fontFamily: 'Product Sans',
                                                                        borderTop: '1px solid rgba(0, 0, 0, .125)'

                                                                    }
                                                                }  >

                                                                <Grid className={classes.stepIndexStyle}
                                                                    style={stepDetailonFocus.sectionIndex === index && stepDetailonFocus.stepIndex === stepIndex && stepExpand ? {
                                                                        color: '#387CF0',
                                                                        backgroundColor: '#FFF',
                                                                        border: '2px solid #387CF0',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                    } : {
                                                                        fontFamily: 'Product Sans', display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                    }}>

                                                                    <Typography style={{ fontWeight: 700, fontFamily: 'Product Sans' }}>{stepIndex + 1}</Typography>
                                                                </Grid>
                                                                <TextareaAutosize
                                                                    id="stepDetail"
                                                                    name={`recipeInstructions[${index}].itemListElement[${stepIndex}].text`}
                                                                    onBlur={(e) => setFieldValue(`recipeInstructions[${stepDetailonFocus?.sectionIndex}].itemListElement[${stepDetailonFocus.stepIndex}].text`, e.target.value)}
                                                                    autoFocus={step.autoFocus}
                                                                    onFocus={(e) => setStepDetailOnFocus({ sectionIndex: index, stepIndex })}
                                                                    defaultValue={step.text}
                                                                    placeholder="Add Step Here"
                                                                    className={classes.stepNameInput} sx={stepDetailonFocus.sectionIndex === index && stepDetailonFocus.stepIndex === stepIndex && stepExpand ? {
                                                                        backgroundColor: '#387CF0',
                                                                        color: '#FFF',
                                                                        '&::placeholder': {
                                                                            color: 'white'
                                                                        },
                                                                    } : { fontFamily: 'Product Sans', }
                                                                    }
                                                                />
                                                                {stepDetailonFocus.sectionIndex === index && stepDetailonFocus.stepIndex === stepIndex && (
                                                                    <>
                                                                        {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: 5, borderRadius: 4, }}>
                                                                            <RemoveCircleOutlineRoundedIcon onClick={(e) => (console.log(stepIndex), remove(stepIndex), setStepDetailOnFocus({ sectionIndex: null, stepIndex: null }))} className={classes.deleteCircle} />
                                                                        </div> */}

                                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: 5, borderRadius: 4, marginRight: -3, color: '#FFF' }}>
                                                                            <ExpandCircleDownIcon onClick={() => toggleStepAccordion()} />
                                                                        </div>

                                                                    </>
                                                                )}

                                                            </StepsAccordionSummary>
                                                        </Grid>
                                                        {stepDetailonFocus.sectionIndex === index && stepDetailonFocus.stepIndex === stepIndex && (
                                                            <Grid item style={{ marginLeft: 2 }} onClick={(e) => ( remove(stepIndex), setStepDetailOnFocus({ sectionIndex: null, stepIndex: null }))}>
                                                                {/* <RemoveCircleOutlineRoundedIcon onClick={(e) => deleteSection(e, index)} style={{ color: "#FA3131", fontSize: 18, width: "30px", height: "30px", marginLeft: "20px" }} /> */}
                                                                <img src={DeleteAccordion} style={{ height: 48, marginTop: 5 }} alt="delete" />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                    <StepsAccordionDetails  >
                                                        {
                                                            stepDetailonFocus.sectionIndex === index && stepDetailonFocus.stepIndex === stepIndex && (
                                                                <Grid display="flex" flexDirection="row" justifyContent="space-around" margin="20px" classes={{ root: classes.typography }} /* style={stepExpand ? { border: '3px solid #387CF0', margin: 0 } : { border: '3px solid transparent', margin: 20 }} */
                                                                >
                                                                    {/* Image */}
                                                                    <Grid item margin="10px 10px">
                                                                        <Grid item style={{ overflow: 'hidden' }} >
                                                                            <Grid item >
                                                                                        {values?.recipeInstructions[index]?.itemListElement[stepIndex]?.images[currentStepImage] ?
                                                                                            <img alt=" " src={values?.recipeInstructions[index]?.itemListElement[stepIndex]?.images[currentStepImage]} style={{ height: '330px', width: '330px', borderRadius: 4 }} />
                                                                                            : <img src={ImageReplace} style={{ height: '330px', width: '330px', borderRadius: 4 }} alt="
                                                                                            "/>
                                                                                        }
                                                                            </Grid>
                                                                            <Grid item container spacing={1} direction="column" margin="4px" >
                                                                                <Grid width="330px" style={{ border: "3px solid #F8F9FD", borderRadius: 8, display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>

                                                                                    <Grid item>
                                                                                        <Grid style={{ width: '250px', display: 'flex', flexDirection: 'row', alignItems: 'center', overflowX: 'auto', }}>

                                                                                            {[...Object.keys(values?.recipeInstructions[index]?.itemListElement[stepIndex]?.images)].map((file, indexValue) => {
                                                                                                const element = values
                                                                                                return (
                                                                                                    <Grid item xs={4} key={indexValue} >
                                                                                                        <Box style={{ position: 'relative' }}>
                                                                                                            <Grid container direction="row" alignItems="center" flexWrap="wrap">
                                                                                                                <Grid item key={file} onClick={() => selectImageForStep(file, element)}  >
                                                                                                                    <img src={values?.recipeInstructions[index]?.itemListElement[stepIndex]?.images[file]} style={{ width: 80, height: 80, borderRadius: 8, border: currentStepImage === file ? '2px solid #2F7EEF' : 'none', margin: 2 }} alt='' />
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                            {currentStepImage === file && (
                                                                                                                <Box style={{ position: 'absolute', top: -8, right: -7 }}>
                                                                                                                    <Box style={{ display: 'flex', flexDirection: 'row', justifyItems: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.5)', borderRadius: '50%', padding: 5 }}>
                                                                                                                        <CloseIcon onClick={(e) => removeImageStep(index, stepIndex)} style={{ fontSize: 16, color: 'white' }} />

                                                                                                                    </Box>

                                                                                                                </Box>

                                                                                                            )}
                                                                                                        </Box>
                                                                                                    </Grid>
                                                                                                )
                                                                                            })}

                                                                                            <Grid
                                                                                                item
                                                                                                onMouseUp={onDragLeave}
                                                                                                onDragEnter={onDragEnter}
                                                                                                onDragOver={onDragOver}
                                                                                                onDrop={(e) => onDrop(e, index, stepIndex)}
                                                                                                style={{ width: "80", height: "80", }}
                                                                                            >
                                                                                                <div onDragLeave={(e) => onDragLeave(e, index, stepIndex)} style={{ width: 80, height: 80, backgroundColor: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', border: '1px solid gray', borderRadius: 10 }} onClick={() => setStepsUploadImages(true)}>
                                                                                                    <img item src={ModalGallery} alt={"drag here"} style={{ height: 40, width: 60 }} />
                                                                                                    <Typography item style={{ color: '#397DF3', fontWeight: 400 }} > Drag here</Typography>
                                                                                                </div>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <label htmlFor='upload'>
                                                                                        <div style={{ width: 80, height: 80, backgroundColor: 'white', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', border: '1px solid gray', borderRadius: 10 }}>
                                                                                            <input id="upload" type="file" accept="image/png, image/jpeg" multiple onChange={(e) => collectImagesWithIndex(e, index + 1, stepIndex + 1)} style={{ display: 'none' }} />
                                                                                            <AddAPhotoIcon style={{ color: '#1B76D2' }}></AddAPhotoIcon>
                                                                                        </div>
                                                                                        </label>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    {/* Ingredients, Tools */}
                                                                    <Grid direction='row' >  { /*style={{ display: 'flex', flexDirection: 'row' }} */}
                                                                        <Grid item style={{ marginTop: '-px' }} >
                                                                            <Grid item container spacing={1} direction="column" margin='10px' >
                                                                                <Grid item>
                                                                                    <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', }}>Ingredients</Typography>
                                                                                </Grid>
                                                                                <Grid item>
                                                                                    <Autocomplete
                                                                                        id="ingredients"
                                                                                        style={{
                                                                                            zIndex: 1000, flexGrow: 1, width: "300px"
                                                                                        }}
                                                                                        InputProps={{ className: classes.autocomplete }}
                                                                                        options={newIngredientSuggestions}
                                                                                        filterOptions={(x) => x}
                                                                                        freeSolo
                                                                                        multiple
                                                                                        onInput={addingNewIngredient}
                                                                                        getOptionLabel={(option) => option.quantity ? ` ${option.quantity} ${option.unit} ${option.prepStyle} ${option.name}` : `${option.name}`}
                                                                                        onChange={(e, value) => { handleAddingIngredient(index, stepIndex, value); setCurrentIngredient(value); handleMainIngredient(value); }}
                                                                                        renderInput={(params) => (
                                                                                            <TextField
                                                                                                {...params}
                                                                                                InputProps={{
                                                                                                    ...params.InputProps,
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid container style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center', marginTop: 20 }} >
                                                                                    <Grid item>
                                                                                        {
                                                                                            values?.recipeInstructions[index]?.itemListElement[stepIndex]?.ingredients.length >= 0 && values?.recipeInstructions[index]?.itemListElement[stepIndex]?.ingredients?.map((item) => (
                                                                                                <Grid item style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', width: 305, alignItems: 'center' }} >
                                                                                                    <Grid item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
                                                                                                        <Grid item >
                                                                                                            {item?.quantity !== undefined ?
                                                                                                                <img src={item?.imageUrl} style={{ width: 50, height: 50, borderRadius: '50%', }} alt="ingredient" />
                                                                                                                : " "}
                                                                                                        </Grid>
                                                                                                        <Grid item>
                                                                                                            <Typography type="text" variant='h6' style={{ marginLeft: 5 }}>
                                                                                                                {item?.quantity !== undefined || currentIngredient?.quantity !== undefined ? item?.unit === undefined ? `${item?.quantity} ${item?.unit} ${item?.prepStyle} ${item?.name}` : `${item?.quantity} ${item?.unit} ${item?.prepStyle} ${item?.name}` : " "}
                                                                                                            </Typography>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                    <Grid item>
                                                                                                        {item?.quantity !== undefined && <LaunchIcon onClick={() => { handleIngredientModal(true); setCurrentIngredient(item); }} style={{ width: 32, height: 32, marginTop: 8, color: '#000000', opacity: '40%', cursor: 'pointer' }} />}
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            ))
                                                                                        }
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                            {/* IngredientSwapModal */}
                                                                            <Modal
                                                                                open={ingredientSwap}
                                                                                onClose={() => handleIngredientModal(false)}
                                                                                aria-labelledby="modal-modal-title"
                                                                                aria-describedby="modal-modal-description"
                                                                            >
                                                                                <Box sx={modalStyle}>
                                                                                    <Grid style={{ display: 'flex', flexDirection: 'column', padding: 20, alignContent: 'space-between' }} classes={{ root: classes.typography }}>
                                                                                        <Grid item style={{ display: 'flex', flexDirection: 'column' }}>
                                                                                            <Grid item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                                                <Grid item>
                                                                                                    <Typography variant='h5' item > Additional Info </Typography>
                                                                                                </Grid>

                                                                                                <Grid item >
                                                                                                    <img src={CloseIconn} onClick={() => handleIngredientModal(false)} style={{ width: 55, height: 55, position: 'absolute', top: 0, right: 0 }} alt='' />
                                                                                                </Grid>
                                                                                            </Grid>

                                                                                            <Grid item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }}>
                                                                                                <Grid item>
                                                                                                    <Typography item style={{ color: '#000000', opacity: 0.5 }} > Current </Typography>
                                                                                                </Grid>

                                                                                                <Grid item style={{ display: 'flex', flexDirection: 'row' }}>
                                                                                                    <Typography item style={{ color: '#000000', opacity: 0.5 }} > Optional </Typography>
                                                                                                    <Checkbox item style={{
                                                                                                        marginTop: -10,
                                                                                                        color: '#397EF5', '&.Mui-checked': {
                                                                                                            color: '#397EF5',
                                                                                                        }
                                                                                                    }} />

                                                                                                </Grid>

                                                                                            </Grid>

                                                                                            <Grid item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: -3 }}>
                                                                                                {/* <img src={ } /> */}

                                                                                                <Grid item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
                                                                                                    <Grid item >
                                                                                                        <img src={currentIngredient?.imageUrl} style={{ width: 50, height: 50, borderRadius: '50%', }} alt='' />
                                                                                                    </Grid>
                                                                                                    <Grid item>
                                                                                                        <Typography type="text" variant='h6' style={{ marginLeft: 5 }}>  {currentIngredient?.unit == undefined ? `${currentIngredient?.quantity} ${currentIngredient?.unit} ${currentIngredient?.prepStyle} ${currentIngredient?.name}` : `${currentIngredient?.quantity} ${currentIngredient?.unit} ${currentIngredient?.prepStyle} ${currentIngredient?.name}`}</Typography>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                                {/* <Typography variant="h6" >  {`${currentIngredient?.quantity} ${currentIngredient?.unit}  ${currentIngredient?.name}`}</Typography> */}


                                                                                            </Grid>

                                                                                            <Grid item style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 20 }}>
                                                                                                <Grid item>
                                                                                                    <Typography item variant='h6' style={{ color: '#000000', opacity: 0.5 }} > Suggest Swaps </Typography>
                                                                                                </Grid>

                                                                                                <Grid item>
                                                                                                    <Typography item style={{ color: '#000000', opacity: 0.5 }} > New ingredient should also follow the same recipe flow </Typography>
                                                                                                </Grid>

                                                                                                <Grid item >
                                                                                                    <Autocomplete
                                                                                                        id="ingredients"
                                                                                                        style={{
                                                                                                            zIndex: 1000, flexGrow: 1, width: "440px", marginTop: 7,
                                                                                                        }}
                                                                                                        options={newIngredientSuggestions}
                                                                                                        filterSelectedOptions
                                                                                                        InputProps={{ className: classes.autocomplete }}
                                                                                                        filterOptions={(x) => x}
                                                                                                        freeSolo
                                                                                                        onInput={addingNewIngredient}
                                                                                                        getOptionLabel={(option) => ` ${option.quantity} ${option.unit} ${option.prepStyle} ${option.name} `}
                                                                                                        onChange={(e, value) => { handleAddingIngredient(index, stepIndex, value); handleMainIngredient(value); }}

                                                                                                        closeIcon={null}
                                                                                                        value={null}
                                                                                                        blurOnSelect={true}
                                                                                                        renderInput={(params) => (
                                                                                                            <TextField autoComplete='off'
                                                                                                                {...params}
                                                                                                            />
                                                                                                        )}
                                                                                                    />
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        <Grid item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 140 }} >
                                                                                            <Button item variant="outlined" onClick={() => deleteIngredient(index, stepIndex, currentIngredient)} style={{ width: 170, backgroundColor: '#FF5353', color: 'white', border: '1px solid #FF5353' }}>Delete Ingredient</Button>
                                                                                            <Button item variant="outlined" onClick={() => handleIngredientModal(false)} style={{ width: 170, color: '#387CF0', border: '1px solid #387CF0' }}>Save Changes</Button>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Box>
                                                                            </Modal>
                                                                        </Grid>
                                                                        <Grid item>
                                                                                    <Grid container spacing={1} direction="column" margin='10px' marginTop="25px">
                                                                                        <Grid item>
                                                                                            <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', }}>Tools</Typography>
                                                                                        </Grid>
                                                                                        <Grid item >
                                                                                            <Autocomplete
                                                                                                id="tools"
                                                                                                sx={{ width: 300 }}
                                                                                                options={newToolSuggestions}
                                                                                                noOptionsText={"Search for a Tool"}
                                                                                                InputProps={{ className: classes.autocomplete }}
                                                                                                freeSolo
                                                                                                filterOptions={(x) => x}
                                                                                                multiple
                                                                                                onInput={addingNewTool}
                                                                                                // onClick={ }
                                                                                                getOptionLabel={(option) => option.name}
                                                                                                onChange={(e, value) => { setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].parameters.toolsUsed`, value); }}
                                                                                                closeIcon={null}
                                                                                                defaultValue={values?.recipeInstructions[index]?.itemListElement[stepIndex]?.parameters?.toolsUsed}
                                                                                                renderInput={(params) => (
                                                                                                    <TextField
                                                                                                        {...params}
                                                                                                        placeholder='tools'
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        </Grid>
                                                                                    </Grid>
                                                                        </Grid>
                                                                        {values.proMode && 
                                                                            <Grid item>
                                                                                    <Grid container spacing={1} direction="column" margin='10px' marginTop="25px">
                                                                                        <Grid item>
                                                                                            <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', }}>Tips</Typography>
                                                                                        </Grid>
                                                                                        <Grid item >
                                                                                            <TextField
                                                                                                id="tips"
                                                                                                name={`recipeInstructions[${index}].itemListElement[${stepIndex}].parameters.pro.tips`}
                                                                                                multiline
                                                                                                placeholder='tips'
                                                                                                defaultValue={values?.recipeInstructions[index].itemListElement[stepIndex].parameters.pro.tips}
                                                                                                onChange={(e)=> setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].parameters.pro.tips`, e.target.value)}
                                                                                                sx={{ width: 300 }}
                                                                                                inputProps={{
                                                                                                    maxLength: 60,
                                                                                                }}
                                                                                            />
                                                                                        </Grid>
                                                                                    </Grid>
                                                                        </Grid>}
                                                                    </Grid>
                                                                                                            
                                                                    {/* Tools,Action, Temp, Style */}
                                                                    {values.proMode ?
                                                                        <>
                                                                            <Grid style={{ width: "430px", marginTop: -17 }}>
                                                                                <Grid item margin="20px" display="flex" flexDirection="row">
                                                                                    <Grid item width='200px '>
                                                                                        <Grid item container spacing={1} direction="column" margin="10px" marginLeft="3px" width="174px" >
                                                                                            <Grid item sm={6} xs={5}  >
                                                                                                <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', }}>Cooking Style</Typography>
                                                                                            </Grid>
                                                                                            <Grid item sm={6} xs={7} width="20px" margin="10px"  >
                                                                                                <Grid container direction="row" alignItems="center" margin="-9px"   >

                                                                                                    <ToggleButtonGroup size="small" value={values?.recipeInstructions[index]?.itemListElement[stepIndex]?.cookingStyle ?? cookingStyleButtonActive} onChange={handleCookingButton} >

                                                                                                        <ToggleButton value={"Open"}
                                                                                                            style={values.recipeInstructions[index].itemListElement[stepIndex].cookingStyle == "Open" ? { borderTopLeftRadius: "10%", borderBottomLeftRadius: "10%", fontWeight: "600", background: "rgb(134 170 243 / 43%)", color: "#397DF3", border: "1px solid #387DF2" } : { borderTopLeftRadius: "10%", borderBottomLeftRadius: "10%" }}
                                                                                                            onClick={(e) => { setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].cookingStyle`, e.target.value); handleCookingButton(e); }}>Open</ToggleButton>

                                                                                                        <ToggleButton value={"Closed"}
                                                                                                            style={values.recipeInstructions[index].itemListElement[stepIndex].cookingStyle == "Closed" ? { borderTopRightRadius: "10%", borderBottomRightRadius: "10%", fontWeight: "600", background: "rgb(134 170 243 / 43%)", color: "#397DF3", border: "1px solid #387DF2" } : { borderTopRightRadius: "10%", borderBottomRightRadius: "10%", }}
                                                                                                            onClick={(e) => { setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].cookingStyle`, e.target.value); handleCookingButton(e); }}>Closed</ToggleButton>
                                                                                                    </ToggleButtonGroup>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        <Grid item container spacing={1} direction="column" margin="4px" marginLeft="3px" >
                                                                                            <Grid item>
                                                                                                <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', marginTop: 4 }}>Cooking Action</Typography>
                                                                                            </Grid>
                                                                                            <Grid item>
                                                                                                <Select
                                                                                                    id="cookingAction"
                                                                                                    name={`recipeInstructions[${index}].itemListElement[${stepIndex}].parameters.pro.cookingAction`}
                                                                                                    value={values.recipeInstructions[index].itemListElement[stepIndex].parameters.pro.cookingAction}
                                                                                                    // onChange={(e) => handleSourceUnit(e)}
                                                                                                    onChange={(e) => handleCookActionChange(e, index, stepIndex)}
                                                                                                    fullWidth

                                                                                                    style={{ width: "174px", height: '37px', marginTop: 2 }}
                                                                                                >
                                                                                                    <MenuItem value={'Heat'}>Heat</MenuItem>
                                                                                                    <MenuItem value={'Stirring'}>Stirring</MenuItem>
                                                                                                    <MenuItem value={'Dispense'}>Dispense</MenuItem>
                                                                                                    <MenuItem value={'Steaming'}>Steaming</MenuItem>
                                                                                                    <MenuItem value={'Prep'}>Prep</MenuItem>
                                                                                                </Select>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        <Grid item container spacing={1} direction="column" margin="4px" marginLeft="3px" style={wrongDurationFormat ? { marginTop: 30 } : { fontFamily: 'Product Sans' }}>
                                                                                            <Grid item    >
                                                                                                <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', }}>Temperature</Typography>
                                                                                            </Grid>
                                                                                            <Grid item container spacing={1} direction="column" margin="4px"    >
                                                                                                <Grid container style={{ display: 'flex', flexDirection: 'row' }}  >

                                                                                                    <TextField autoComplete='off' size="small" style={{ width: 56, marginRight: 10, }} placeholder="0" value={copyStepMode ? values?.recipeInstructions[index]?.itemListElement[stepIndex - 1]?.parameters?.pro?.temperature?.value : values?.recipeInstructions[index]?.itemListElement[stepIndex]?.parameters?.pro?.temperature?.value}
                                                                                                        onChange={(e) => { setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].parameters.pro.temperature.value`, e.target.value); handleChange(e); setTempValueEdited(true) }} />
                                                                                                    <ToggleButtonGroup value={temperatureButton} handleChange={handleTemperatureButton} >

                                                                                                        <ToggleButton size="small" value={"c"}
                                                                                                            style={temperatureButton == "c" ? { borderTopLeftRadius: "10%", borderBottomLeftRadius: "10%", fontWeight: "600", background: "rgb(134 170 243 / 43%)", color: "#397DF3", border: "1px solid #387DF2", height: 39, width: 50 } : { height: 39, borderTopLeftRadius: "10%", borderBottomLeftRadius: "10%", width: 50 }}
                                                                                                            onClick={(e) => { setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].parameters.pro.temperature.unit`, e.target.value); setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].parameters.temperature`, "On"); handleTemperatureButton(e) }}>˚C</ToggleButton>
                                                                                                        <ToggleButton size="small" value={"f"}
                                                                                                            style={temperatureButton == "f" ? { borderTopRightRadius: "10%", borderBottomRightRadius: "10%", fontWeight: "600", background: "rgb(134 170 243 / 43%)", color: "#397DF3", border: "1px solid #387DF2", height: 39, width: 50 } : { height: 39, borderTopRightRadius: "10%", borderBottomRightRadius: "10%", width: 50 }}
                                                                                                            onClick={(e) => { setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].parameters.pro.temperature.unit`, e.target.value); setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].parameters.temperature`, "On"); handleTemperatureButton(e) }}>˚F</ToggleButton>
                                                                                                    </ToggleButtonGroup>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Grid item width="200px">
                                                                                        <Grid item container spacing={1} direction="column" margin="4px"  >
                                                                                            <Grid item sm={6} xs={5}  >
                                                                                                <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', marginTop: 7 }}>Stirring</Typography>
                                                                                            </Grid>
                                                                                            <Grid item sm={6} xs={7}    >
                                                                                                <Grid container direction="row" alignItems="center"   >

                                                                                                    <ToggleButtonGroup value={stirringButton} size="small" onChange={handleStirringButton} >

                                                                                                        <ToggleButton value={"On"}
                                                                                                            style={stirringButton == "On" ? { borderTopLeftRadius: "10%", borderBottomLeftRadius: "10%", fontWeight: "600", background: "rgb(134 170 243 / 43%)", color: "#397DF3", border: "1px solid #387DF2" } : { borderTopLeftRadius: "10%", borderBottomLeftRadius: "10%" }}
                                                                                                            onClick={(e) => { setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].parameters.pro.stirring`, e.target.value); handleStirringButton(e); }}>On</ToggleButton>
                                                                                                        <ToggleButton value={"Occasional"}
                                                                                                            style={stirringButton == "Occasional" ? { background: "rgb(134 170 243 / 43%)", color: "#397DF3", border: "1px solid #387DF2" } : {}}
                                                                                                            onClick={(e) => { setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].parameters.pro.stirring`, e.target.value); handleStirringButton(e); }}>Occasional</ToggleButton>

                                                                                                        <ToggleButton value={"Off"}
                                                                                                            style={stirringButton == "Off" ? { borderTopRightRadius: "10%", borderBottomRightRadius: "10%", fontWeight: "600", background: "rgb(134 170 243 / 43%)", color: "#397DF3", border: "1px solid #387DF2" } : { borderTopRightRadius: "10%", borderBottomRightRadius: "10%", }}
                                                                                                            onClick={(e) => { setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].parameters.pro.stirring`, e.target.value); handleStirringButton(e); }}>Off</ToggleButton>
                                                                                                    </ToggleButtonGroup>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                        <Grid item container spacing={1} direction="column" margin="4px" >
                                                                                            <Grid item>
                                                                                                <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', marginTop: 10 }}>Duration</Typography>

                                                                                            </Grid>
                                                                                            <Grid item>
                                                                                                <TextField autoComplete='off' onClick={() => setWrongDurationFormat(false)} placeholder="1h 30m 30s" type="text" defaultValue={values?.recipeInstructions[index]?.itemListElement[stepIndex]?.parameters?.pro?.duration} id="duration" onBlur={(e) => { setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].parameters.pro.duration`, e.target.value); checkString(e.target.value); }} InputProps={{ className: classes.textField }} style={{ width: "174px" }} />

                                                                                            </Grid>

                                                                                            <Grid item>
                                                                                                {
                                                                                                    //wrongDurationFormat
                                                                                                    1 == 2
                                                                                                        ? <>
                                                                                                            <Typography style={{ color: '#FF5353', fontFamily: 'Product Sans' }}> Format in 1h 2m 30s </Typography>
                                                                                                        </>
                                                                                                        : <></>

                                                                                                }
                                                                                            </Grid>

                                                                                        </Grid>


                                                                                        <Grid item container spacing={1} direction="column" margin="4px" >
                                                                                            <Grid item>
                                                                                                <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', marginTop: -11 }}>Power</Typography>

                                                                                            </Grid>
                                                                                            <Grid item marginTop="5px">

                                                                                                <ButtonGroup size="small" >

                                                                                                    <Button size="small" disabled={values.recipeInstructions[index].itemListElement[stepIndex].parameters.pro.power.value < 200}
                                                                                                        style={{ borderTopLeftRadius: "20%", borderBottomLeftRadius: "20%", fontWeight: "600", background: "rgb(134 170 243 / 43%)", color: "#397DF3", border: '1px solid #387DF2', borderRight: '1px solid transparent' }}
                                                                                                        onClick={() => setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].parameters.pro.power.value`, values?.recipeInstructions[index]?.itemListElement[stepIndex]?.parameters?.pro?.power?.value - 200)} >-</Button>

                                                                                                    <div style={{ borderRadius: 0 }}>
                                                                                                        <TextField autoComplete='off' disabled placeholder='200' style={{ borderRadius: 0 }} InputProps={{ className: classes.buttonTextField }} onBlur={(e) => { setFieldValue(`values.recipeInstructions[${index}].itemListElement[${stepIndex}].parameters.pro.power.value`, e.target.value); }} size="small"
                                                                                                            onChange={handleChange} value={copyStepMode ? values?.recipeInstructions[index]?.itemListElement[stepIndex - 1]?.parameters?.pro?.power?.value : values?.recipeInstructions[index]?.itemListElement[stepIndex]?.parameters?.pro?.power?.value}
                                                                                                            sx={{

                                                                                                                '& input:disabled': {
                                                                                                                    color: 'red',
                                                                                                                    '-webkit-text-fill-color': 'black',
                                                                                                                },
                                                                                                                '&.MuiInputBase-root-MuiOutlinedInput-root': {
                                                                                                                    borderRadius: 0
                                                                                                                }
                                                                                                            }} />
                                                                                                    </div>
                                                                                                    <Button size="small" disabled={values.recipeInstructions[index].itemListElement[stepIndex].parameters.pro.power.value > 1700}
                                                                                                        style={{ borderTopRightRadius: "20%", borderBottomRightRadius: "20%", fontWeight: "600", background: "rgb(134 170 243 / 43%)", color: "#397DF3", border: '1px solid #387DF2', borderLeft: '1px solid transparent' }}
                                                                                                        onClick={() => setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].parameters.pro.power.value`, values?.recipeInstructions[index]?.itemListElement[stepIndex]?.parameters?.pro?.power?.value + 200)} >+</Button>
                                                                                                </ButtonGroup>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                {values.recipeInstructions[index].itemListElement[stepIndex].parameters.pro.temperature.value == "" && !tempValueEdited ? <>
                                                                                    <Grid item style={{ display: 'flex', flexDirection: 'row', marginTop: 7, width: 200, paddingLeft: 24 }}>
                                                                                        <Grid item>
                                                                                            <Checkbox item checked={copyStepMode} onChange={handleCopyStepMode} sx={{
                                                                                                color: '#397EF5', '&.Mui-checked': {
                                                                                                    color: '#397EF5',
                                                                                                }
                                                                                            }} />
                                                                                        </Grid>
                                                                                        <Grid item>
                                                                                            <Typography>
                                                                                                Copy previous step settings
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </>
                                                                                    :
                                                                                    <></>
                                                                                }
                                                                            </Grid>
                                                                        </>

                                                                        :
                                                                        <>
                                                                            <Grid style={{ width: "430px" }} >
                                                                                {/* <Grid item margin="20px">
                                                                                    <Grid spacing={1} direction="column" margin='10px' marginTop="25px" >
                                                                                        <Grid item>
                                                                                            <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', marginTop: 27 }}>Tools</Typography>

                                                                                        </Grid>
                                                                                        <Grid item>
                                                                                            <Autocomplete
                                                                                                id="tools"
                                                                                                style={{
                                                                                                    zIndex: 1000, flexGrow: 1, width: 400
                                                                                                }}
                                                                                                options={newToolSuggestions}
                                                                                                filterSelectedOptions
                                                                                                noOptionsText={"Search for a Tool"}
                                                                                                autoHighlight
                                                                                                InputProps={{ className: classes.autocomplete }}
                                                                                                multiple
                                                                                                onInput={addingNewTool}
                                                                                                // onClick={ }
                                                                                                getOptionLabel={(option) => option.name}
                                                                                                onChange={(e, value) => { setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].parameters.toolsUsed`, value); }}
                                                                                                closeIcon={null}
                                                                                                defaultValue={values?.recipeInstructions[index]?.itemListElement[stepIndex]?.parameters?.toolsUsed}
                                                                                                renderInput={(params) => (
                                                                                                    <TextField
                                                                                                        {...params}
                                                                                                    />
                                                                                                )}
                                                                                            />
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid> */}
                                                                                <Grid item margin="20px" display="flex" flexDirection="row">

                                                                                    <Grid item width="200px">
                                                                                        <Grid item container spacing={1} direction="column" margin="4px"  >


                                                                                            <Grid item sm={6} xs={5}  >
                                                                                                <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', marginTop: 7 }}>Stirring</Typography>
                                                                                            </Grid>
                                                                                            <Grid item sm={6} xs={7}    >
                                                                                                <Grid container direction="row" alignItems="center"   >

                                                                                                    <ToggleButtonGroup value={stirringButton} size="small" onChange={handleStirringButton} >

                                                                                                        <ToggleButton value={"On"}
                                                                                                            style={stirringButton == "On" ? { borderTopLeftRadius: "10%", borderBottomLeftRadius: "10%", fontWeight: "600", background: "rgb(134 170 243 / 43%)", color: "#397DF3", border: "1px solid #387DF2" } : { borderTopLeftRadius: "10%", borderBottomLeftRadius: "10%" }}
                                                                                                            onClick={(e) => { setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].parameters.pro.stirring`, e.target.value); handleStirringButton(e); }}>On</ToggleButton>
                                                                                                        <ToggleButton value={"Occasional"}
                                                                                                            style={stirringButton == "Occasional" ? { background: "rgb(134 170 243 / 43%)", color: "#397DF3", border: "1px solid #387DF2" } : {}}
                                                                                                            onClick={(e) => { setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].parameters.pro.stirring`, e.target.value); handleStirringButton(e); }}>Occasional</ToggleButton>

                                                                                                        <ToggleButton value={"Off"}
                                                                                                            style={stirringButton == "Off" ? { borderTopRightRadius: "10%", borderBottomRightRadius: "10%", fontWeight: "600", background: "rgb(134 170 243 / 43%)", color: "#397DF3", border: "1px solid #387DF2" } : { borderTopRightRadius: "10%", borderBottomRightRadius: "10%", }}
                                                                                                            onClick={(e) => { setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].parameters.pro.stirring`, e.target.value); handleStirringButton(e); }}>Off</ToggleButton>
                                                                                                    </ToggleButtonGroup>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        <Grid item container spacing={1} direction="column" margin="4px" marginLeft="3px" style={wrongDurationFormat ? { marginTop: 30 } : { fontFamily: 'Product Sans' }}>
                                                                                            <Grid item    >
                                                                                                <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', }}>Temperature</Typography>
                                                                                            </Grid>
                                                                                            <Grid item container spacing={1} direction="column" margin="4px"    >
                                                                                                <Grid container style={{ display: 'flex', flexDirection: 'row' }}  >
                                                                                                    <ToggleButtonGroup size="small" value={values?.recipeInstructions[index]?.itemListElement[stepIndex]?.parameters?.pro?.temperature?.unit ?  values?.recipeInstructions[index]?.itemListElement[stepIndex]?.parameters?.pro?.temperature?.unit : temperatureButton} handleChange={handleTemperatureButton} >
                                                                                                    <ToggleButton size="small" value={"Off"}
                                                                                                            style={temperatureButton == "Off" ? { borderTopLeftRadius: "10%", borderBottomLeftRadius: "10%", fontWeight: "600", background: "rgb(134 170 243 / 43%)", color: "#397DF3", border: "1px solid #387DF2", height: 39, width: 50 } : { height: 39, borderTopLeftRadius: "10%", borderBottomLeftRadius: "10%", width: 50 }}
                                                                                                            onClick={(e) => { setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].parameters.pro.temperature.unit`, e.target.value); setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].parameters.temperature`, "On"); handleTemperatureButton(e) }}>Off</ToggleButton>
                                                                                                             <ToggleButton size="small" value={"Low"}
                                                                                                            style={temperatureButton == "Low" ? {  fontWeight: "600", background: "rgb(134 170 243 / 43%)", color: "#397DF3", border: "1px solid #387DF2", height: 39, width: 50 } : { height: 39, width: 50 }}
                                                                                                            onClick={(e) => { setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].parameters.pro.temperature.unit`, e.target.value); setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].parameters.temperature`, "On"); handleTemperatureButton(e) }}>Low</ToggleButton>
                                                                                                        <ToggleButton size="small" value={"Medium"}
                                                                                                            style={temperatureButton == "Medium" ? { fontWeight: "600", background: "rgb(134 170 243 / 43%)", color: "#397DF3", border: "1px solid #387DF2", height: 39, width: 50 } : { height: 39, width: 50 }}
                                                                                                            onClick={(e) => { setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].parameters.pro.temperature.unit`, e.target.value); setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].parameters.temperature`, "On"); handleTemperatureButton(e) }}>Med</ToggleButton>
                                                                                                        <ToggleButton size="small" value={"High"}
                                                                                                            style={temperatureButton == "High" ? { borderTopRightRadius: "10%", borderBottomRightRadius: "10%", fontWeight: "600", background: "rgb(134 170 243 / 43%)", color: "#397DF3", border: "1px solid #387DF2", height: 39, width: 50 } : { height: 39, borderTopRightRadius: "10%", borderBottomRightRadius: "10%", width: 50 }}
                                                                                                            onClick={(e) => { setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].parameters.pro.temperature.unit`, e.target.value); setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].parameters.temperature`, "On"); handleTemperatureButton(e) }}>High</ToggleButton>
                                                                                                    </ToggleButtonGroup>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <Grid item style={{ marginTop: "-7px", width: "200px" }}>
                                                                                        <Grid item container spacing={1} direction="column" margin="4px" >
                                                                                            <Grid item>
                                                                                                <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', marginTop: 10 }}>Duration</Typography>
                                                                                            </Grid>
                                                                                            <Grid item>
                                                                                                <TextField autoComplete='off' onClick={() => setWrongDurationFormat(false)} placeholder="1h 30m 30s" type="text" defaultValue={values?.recipeInstructions[index]?.itemListElement[stepIndex]?.parameters?.pro?.duration} id="duration" onBlur={(e) => { setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].parameters.pro.duration`, e.target.value); checkString(e.target.value); }} InputProps={{ className: classes.textField }} style={{ width: "174px" }} />
                                                                                            </Grid>
                                                                                            <Grid item>
                                                                                                {
                                                                                                    //wrongDurationFormat
                                                                                                    1 == 2 ? <>
                                                                                                        <Typography style={{ color: '#FF5353', fontFamily: 'Product Sans' }}> Format in 1h 2m 30s </Typography>
                                                                                                    </>
                                                                                                        : <></>
                                                                                                }
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                {values.recipeInstructions[index].itemListElement[stepIndex].parameters.pro.temperature.value == "" && !tempValueEdited ? <>
                                                                                    <Grid item style={{ display: 'flex', flexDirection: 'row', marginTop: 7, width: 200, paddingLeft: 24 }}>
                                                                                        <Grid item>
                                                                                            <Checkbox item checked={copyStepMode} onChange={handleCopyStepMode} sx={{
                                                                                                color: '#397EF5', '&.Mui-checked': {
                                                                                                    color: '#397EF5',
                                                                                                }
                                                                                            }} />
                                                                                        </Grid>
                                                                                        <Grid item>
                                                                                            <Typography>
                                                                                                Copy previous step settings
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </>
                                                                                    :
                                                                                    <></>
                                                                                }
                                                                            </Grid>
                                                                        </>
                                                                    }
                                                                </Grid>
                                                            )}
                                                    </StepsAccordionDetails>
                                                </StepsAccordion>
                                                <Grid style={{ marginBottom: '20px', marginTop: '10px' }}>
                                                    <TextField id="semiRequirement" name={`recipeInstructions[${index}].itemListElement[${stepIndex}].semiRequirement`} defaultValue={values?.recipeInstructions[index]?.itemListElement[stepIndex]?.semiRequirement} onChange={(e, value) => setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].semiRequirement`, e.target.value)}
                                                     inputProps={{ maxLength: 60 }} size='small' sx={{ width: '100%' }} placeholder='Only 60 characters will be visible on device display, type the text you want the users to see on device while cooking.' />
                                                </Grid>
                                                {stepDetailonFocus.sectionIndex === index && stepDetailonFocus.stepIndex === stepIndex && (
                                                    stepHover ? <>
                                                      < Grid onMouseEnter={() => setStepHover(true)} style={values.recipeInstructions[index].itemListElement.length >= 2 && stepExpand === false ? { position: 'absolute', right: 660, bottom: -15, zIndex: 1000 } : { position: 'absolute', right: 660, bottom: -40, zIndex: 1000 }}>
                                                            <img src={AddStepImage} alt="add step" onClick={(e) =>
                                                                insert(stepDetailonFocus.stepIndex + 1, {
                                                                    id: generateId(),
                                                                    text: '',
                                                                    ingredients: [],
                                                                    autoFocus: true,
                                                                    parameters: {
                                                                        toolsUsed: [],
                                                                        temperature: null,
                                                                        duration: '',
                                                                        pro: {
                                                                            tips: '',
                                                                            cookingAction: 'Heat',
                                                                            stirring: false,
                                                                            temperature: {
                                                                                value: 0,
                                                                                unit: 'c'
                                                                            },

                                                                            power: {
                                                                                value: null,
                                                                                unit: 'watt'
                                                                            }
                                                                        }

                                                                    },
                                                                    images: [],
                                                                    cookingStyle: '',
                                                                    semiRequirement: '',
                                                                })
                                                            } />

                                                        </Grid>
                                                        </>: <></>

                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )
                    }}
                </FieldArray >
            </div >
        </AccordionDetails >
    )
}

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 470,
    height: 520,
    bgcolor: 'background.paper',
};

// styles
const useStyles = makeStyles((theme) => ({

    typography: {
        "& .MuiTypography-root": {

            fontFamily: 'Product Sans',
        }
    },


    textField: {

        height: 42,
        border: '2px solid transparent',
        "&.Mui-focused": {
            border: "2px solid #397EF5",
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
            }
        }
    },

    autocomplete: {

        "& .MuiInputBase-input": {
            height: 48,
        },

    },

    /// meta data
    tags: {
        padding: "3px 10px",
        borderRadius: 24,
        display: 'flex',
        flexDirection: 'row',
        alignItems: "center",
        transition: ' .5s ease-in-out',
        backgroundColor: '#e6e6e6',
        width: 100,
        margin: 2,
    },
    tagTextField: {
        border: 'none',
        outline: 'none',
        backgroundColor: 'transparent',
        padding: 5,
    },
    tagClose: {
        margin: 0,
        transition: '.5s linear',
        cursor: 'pointer'
    },
    accordionDetail: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative',
    },

    stepsAccordionSummary2: {
        '&:hover': {
            backgroundColor: 'red'
        }
    },
    accordionBackground: {
        padding: "0px 15px",
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        border: '3px solid transparent',
        '&:hover': {



            '& $stepNameInput': {

                color: '#FFF',
                '&::placeholder': {

                    color: '#FFF'
                },

            },

            '& $stepIndexStyle': {
                color: '#387CF0',
                backgroundColor: '#FFF',
                border: '2px solid #387CF0',

            },

            '& $stepsAccordionSummary2': {
                background: "#387CF0",
            },

            '& $deleteCircle': {
                color: "#FFFFFF"
            },

        }
    },

    accordionBackgroundStepExpand: {
        padding: "0px 15px",
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        border: '3px solid transparent',




        '& $stepNameInput': {

            color: '#FFF',
            '&::placeholder': {

                color: '#FFF'
            },

        },
        '&:hover': {
            '& $stepIndexStyle': {
                color: '#387CF0',
                backgroundColor: '#FFF',
                border: '2px solid #387CF0',

            },

            '& $stepsAccordionSummary2': {
                background: "#387CF0",
            },

            '& $deleteCircle': {
                color: "#FFFFFF"
            },

        }
    },


    deleteCircle: {
        color: "#FA3131", fontSize: 18, width: "30px", height: "30px"
    },



    stepNameInput: {
        fontFamily: 'Product Sans',
        flex: 1,
        outline: 'none',
        border: 'none',
        lineHeight: 2,
        resize: 'none',
        marginRight: 10,
        backgroundColor: 'inherit',
        marginTop: 2,
        marginLeft: 2,

        //fontWeight: 'bold',


        '&::placeholder': {
            color: 'black'
        },
        '&:hover': {
            '&::placeholder': {
                color: '#FFF'
            },
        }

    },

    stepIndexStyle: {
        margin: 5,
        border: '2px solid black',
        borderRadius: '50%',
        fontWeight: '600',

        backgroundColor: 'black',
        color: '#FFF',
        width: 27,
        height: 27,
        marginLeft: 20,

    },


    // imageModal
    modalHeadingContainer: {
        // backgroundColor:theme.palette.secondary.main,

        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 6
    },
    imageHeading: {
        padding: "0 25px"
    },
    emptyImagesContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 124
    },
    imagesContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflowX: 'scroll',
        flexWrap: 'nowrap',
        padding: "10px 20px"
    },
    image: {
        width: 124,
        height: 124,
        borderRadius: 8,
        flexShrink: 0,
        marginRight: 10,
        cursor: 'pointer'
    },
    dragContainer: {
        height: '16vh',
        maxHeight: '20vh',
        padding: "10px 25px",
    },
    drag: {
        borderRadius: 10,
        border: '1px dashed black',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },


    buttonTextField: {
        alignContent: 'center',
        border: 'none',
        height: 39,
        width: "65px",
        outline: 'none',

        "&:focus": {
            outline: 'none'
        }
    },


}));