import React, { useState, useEffect, useContext } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import CloudServices from '../../cloud-services-core/index'
import { makeStyles } from '@mui/styles';
import RecipeCard from '../../components/RecipeCard';
import Delhi from '../../assets/States/Delhi.png'
import Bengal from '../../assets/States/Bengal.png'
import Karnataka from '../../assets/States/Karnataka.png'
import TamilNadu from '../../assets/States/TamilNadu.png'
import Rajasthan from '../../assets/States/Rajasthan.png'
import UP from '../../assets/States/UP.png'
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MuiButton from '../../components/MuiButton'
import AppContext from '../../config/AppContext'

const useStyles = makeStyles({
    BrowseRecipes: {
        miHeight: '100vh'
    },
    BrowseRecipes__SearchContainer: {
        height: '50%',
        minHeight: '60vh',
        background: 'url(https://images.unsplash.com/photo-1542866789-bb9c9d086a5d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80) no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',

    },
    BrowseRecipes__SearchTitleContainer: {},
    BrowseRecipes__SearchTitle: {},
    BrowseRecipes__SearchInputContainer: {},
    BrowseRecipes__SearchInputIcon: {},
    BrowseRecipes__SearchInput: {},
    loading: {
        width: 100,
        height: 100,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        animation: "$fadeIn 1s linear infinite "
    },
    "@keyframes fadeIn": {
        "0%": {
            transform: 'rotate(90deg)'

        },
        "50%": {
            transform: 'rotate(180deg)'

        },
        "75%": {
            transform: 'rotate(270deg)'
        },
        "100%": {
            transform: 'rotate(360deg)'

        }
    },

})


export default function BrowseRecipes(props) {
    const AppState = useContext(AppContext)
    const classes = useStyles(props)
    const [recipes, setRecipes] = useState([])
    // const [searchKeyword, setSearchKeyword] = useState('')
    const [loadingRecipes, setLoadingRecipes] = useState(false)
    const [move, setMove] = useState(0)

    // const onSearch = (e) => {
    //     if (e.key == 'Enter') {
    //         props.history.push({ pathname: '/results', state: e.target.value })
    //     }
    //     setSearchKeyword(e.target.value)
    // }


    useEffect(() => {
        const params = new URLSearchParams(props.location.search)
        const mode = params.get('mode') || '';
        const oobCode = params.get('oobCode')
        const email = params.get('email')
        const redirect = (mode) => {
            console.log(props)
            props.history.push({
                pathname: `/${mode}`,
                state: { oobCode, email }
            })
        }
        if (mode) {
            redirect(mode)
        }
        getRecipes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getRecipes = async () => {
        setLoadingRecipes(true)
        try {
            const recipesFromDB = await CloudServices.recipes.search({ order: "descending", orderBy: 'datePublished', limit: 20 })
            if (!localStorage.getItem('recipes')) {
                localStorage.setItem('recipes', JSON.stringify(recipesFromDB))
            }
            setRecipes(recipesFromDB)
            setLoadingRecipes(false);
        }
        catch (err) {
            console.log(err)
        }
    }

    const removeRecipeFromRecipes = (id) => {
        const updatedRecipes = recipes.filter(recipe => recipe.id !== id)
        setRecipes(updatedRecipes)
        AppState.showSnackBar('Delete recipe Successfully', "success")
    }

    const states = [
        {
            name: 'Delhi',
            image: Delhi
        },
        {
            name: 'West Bengal',
            image: Bengal
        },
        {
            name: 'Karnataka',
            image: Karnataka
        },
        {
            name: 'Rajasthan',
            image: Rajasthan
        },
        {
            name: 'Tamil Nadu',
            image: TamilNadu
        },
        {
            name: 'Uttar Pradesh',
            image: UP
        }
    ]
    return (
        <Grid container spacing={5} direction="column" justifyContent="flex-start" className={classes.BrowseRecipes}>
            <Grid item container sm={12} >
                <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.BrowseRecipes__SearchContainer}    >
                </Grid>
            </Grid>
            <Grid item container sm={12}  >
                <Container maxWidth="xl">
                    <Grid container spacing={2} direction="column" >
                        <Grid item container sm={12}>
                            <Grid item container  >
                                <Grid item xs={6} >
                                    <Typography>Recently Added</Typography>
                                </Grid>
                                <Grid item xs={6} >
                                    <Grid container sx={{
                                        justifyContent: {
                                            xs: 'space-between',
                                            md: "flex-end"
                                        }
                                    }} direction="row" alignItems='center'   >
                                        <Grid item sx={{
                                            marginRight: {
                                                md: 2
                                            }
                                        }} >
                                            <MuiButton title="Recipe Table" to="/recipeTable" />
                                        </Grid>
                                        <Grid item >
                                            <MuiButton title="filter" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container sm={12}>
                            <Grid item container spacing={4} direction="row" alignItems="center">
                                {loadingRecipes ?
                                    (
                                        <Grid item xs={12} style={{ minHeight: '50vh' }} >
                                            <Grid container direction="row" justifyContent="center" alignItems="center" style={{ height: '20vh' }}>
                                                <Grid item  >
                                                    <p style={{ fontSize: 70 }} className={classes.loading}>X</p>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                    : (recipes.map((recipe => (
                                        <Grid item lg={3} md={6} xs={12} key={recipe.id}>
                                            <RecipeCard
                                                action={() => getRecipes()}
                                                collectionType="recipe"
                                                id={recipe.id}
                                                image={recipe?.image?.length > 0 ? recipe.image[0] : ""}
                                                name={recipe.name}
                                                meal={`${recipe.recipeCategory} | ${recipe.recipeCuisine} `}
                                                date={recipe.datePublished.substring(0, 10)}
                                                recipe={recipe}
                                                updateRecipes={(id) => removeRecipeFromRecipes(id)}
                                            />
                                        </Grid>
                                    ))))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Grid item container sm={12} >
                <Container maxWidth="xl">
                    <Grid container direction="row" alignItems="center">
                        <Grid xs="auto" item>
                            <Grid container direction="row" justifyContent="center" alignItems="center"  >
                                <Grid item >

                                    <ChevronLeftIcon onClick={() => setMove(move === 0 ? move : move - 17)} style={{ fontSize: 40, cursor: 'pointer' }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={11} item style={{ overflow: "hidden" }}>
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                                style={{
                                    flexWrap: "nowrap",
                                    transform: `translateX(-${move}%)`,
                                    transition: "0.5s linear"
                                }}
                            >
                                {states.map((state, index) => (
                                    <Grid key={index} item
                                        xs={2} style={{ flexShrink: 0 }}>
                                        <Grid
                                            spacing={1}
                                            rowSpacing={1}
                                            key={state.name}
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Grid
                                                item
                                                xs={4}
                                                sx={{
                                                    width: {
                                                        xs: 70,
                                                        md: 165
                                                    },
                                                    height: {
                                                        xs: 70,
                                                        md: 165
                                                    }
                                                }}

                                            >
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        borderRadius: "50%",
                                                    }}
                                                >
                                                    <img
                                                        src={state.image}
                                                        style={{ width: "100%", height: "100%" }}
                                                        alt='map images'
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <Typography>{state.name}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid xs="auto" item>
                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                <Grid item>
                                    <ChevronRightIcon onClick={() => setMove(move === states.length * 40 ? move : move + 17)} style={{ fontSize: 40, cursor: 'pointer' }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>

            </Grid>

        </Grid>
    )
}
