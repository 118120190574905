import { Button, Grid, Typography, Badge, TextField, Select, MenuItem, Stepper, Step, StepLabel, FormControl, InputLabel, OutlinedInput, InputAdornment, Fab, Card } from '@material-ui/core';
import React, { Component } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import { FieldArray, Form, Formik } from 'formik';
import ImageUploader from 'react-images-upload';
import FirebaseApi from '../../apis/firebaseApi';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { FormatLineSpacing } from '@material-ui/icons';
import ErrorIcon from '@material-ui/icons/Error';
import { green, red } from '@material-ui/core/colors';

const STEPS = ["Upload", "Recipe MetaData", "Ingredients", "Template", "Instructions"]

const recipeMetaData = {
    name: "",    
    author: "",
    description: "",
    prepTime: 0,
    cookTime: 0,
    servings: 0,
    calories: 0,
    course: "",
    cuisine: "",
    keywords: ""

}

const ingredient= { 
    amount: "",
    unit: "", 
    name: "",
    state: "",
    scalingFactor: "",
    category: "",
    saved: false
    }

const recipeInstruction = {
    humanReadable: "",
    cookingAction: "",
    cooktopTemperature: "",
    stirrerState: "",
    ingredient: ingredient
}

const cookingActions = [
    
    { 
        name: "preHeatPan", 
        description: "Place the pan on the stove and heat it up to 70°C",
        temperature: 70,
        stirring: false
    },
    {
        name: "dispenseLiquid",
        temperature: "inherit",
        stirring: false
    },
    {
        name: "dispenseSpice",
        temperature: "inherit",
        stirring: false
    },
    {
        name: "dispenseIngredient",
        temperature: "inherit",
        stirring: false
    },
    {
        name: "startStirring",
        temperature: "inherit",
        stirring: true
    },
    {
        name: "stopStirring",
        temperature: "inherit",
        stirring: false
    },
    {
        name: "boil",
        temperature: 100,
        stirring: false,
    },
    {
        name: "boilWithStirring",
        temperature: "inherit",
        stirring: true,        
    },
    {
        name: "saute",
        temperature: 80,
        stirring: true
    }
]


const cookingActionsHash = {    
    "preHeatPan": { 
        name: "preHeatPan", 
        humanReadable: "Place the pan on the stove and heat it up to 70°C",
        temperature: 70,
        stirring: false
    },
    "dispenseLiquid": {
        name: "dispenseLiquid",
        temperature: "inherit",
        stirring: false
    },
    "dispenseSpice":{
        name: "dispenseSpice",
        temperature: "inherit",
        stirring: false
    },
    "startStirring": {
        name: "startStirring",
        temperature: "inherit",
        stirring: true
    },
    "stopStirring":{
        name: "stopStirring",
        temperature: "inherit",
        stirring: false
    },
    "boil":{
        name: "boil",
        temperature: 100,
        stirring: false,
    },
    "boilWithStirring":{
        name: "boilWithStirring",
        temperature: "inherit",
        stirring: true,        
    },
    "saute": {
        name: "saute",
        temperature: 80,
        stirring: true
    }
}

const templates =[
    {
        name: "Classic Curry",
        style: "Stew",
        steps: [
            ["preHeatPan"],
            ["dispense", "Oil"],
            ["dispense", "Spice/Herb"],
            ["stir"],
            ["saute"],
            ["dispense", "Sauce Ingredient"],
            ["dispense", "Flavor"],
            ["dispense", "Main Ingredient"],
            ["dispense", "Water"],
            ["boil"],
            ["dispense", "Seasoning"],
            ["dispense", "Garnish"]
        ]
    },
    {
        name: "Classic Fry",
        style: "Fry",
        steps: [
            ["preHeatPan"],
            ["dispense", "Oil"],
            ["dispense", "Spice/Herb"],
            ["stir"],
            ["dispense", "Sauce Ingredient"],
            ["saute"],
            ["dispense", "Flavor"],
            ["saute"],
            ["dispense", "Main Ingredient"],
            ["dispense", "Seasoning"],
            ["dispense", "Garnish"]
        ]
    }
    
    
]

class NewRecipe extends Component {
    state = {
        recipeSourceUrl: "",
        parsingRecipe: false,
        parsedRecipe: "",
        currentStep: 0,
        cuisines: [],
        courses: [],
        recipeMetaData: recipeMetaData,
        ingredients: [],
        recipeInstructions: [],
        canProceedToNextStep: false,
        ingredientStates: ["Chopped", "Minced","Cut", "Diced", "Sliced"],
        ingredientCategories: ["Oil", "Spice/Herb", "Flavor" ,"Sauce Ingredient", "Main Ingredient", "Seasoning", "Garnish"],
        selectedTemplate: null
    }

    gotoStep = (index) => {
        this.setState({currentStep: index})
    }
    gotoNextStep = () => {
        this.setState({currentStep: this.state.currentStep+1})
        console.log(this.state.currentStep);
    }
    gotoPreviousStep = () => {
        this.setState({currentStep: this.state.currentStep-1})
    }
    

    fetchCoursesAndCuisines = () => {        
        FirebaseApi["cuisine"].get().then(function(snapshot) {
            var newData = [];
            snapshot.forEach(function(childSnapshot) {
              var id = childSnapshot.id;
              var data = childSnapshot.data();              
            //   newData.push({ id: id, name: data.name, description: data.description, synonyms: data.synonyms});
              newData.push({...data, id: id});
            });
            this.setState({cuisines: newData});
            this.setState({lastFetchedTimeStamp: new Date().toGMTString()})
        }.bind(this));

        FirebaseApi["course"].get().then(function(snapshot) {
            var newData = [];
            snapshot.forEach(function(childSnapshot) {
              var id = childSnapshot.id;
              var data = childSnapshot.data();              
            //   newData.push({ id: id, name: data.name, description: data.description, synonyms: data.synonyms});
              newData.push({...data, id: id});
            });
            this.setState({courses: newData});
            this.setState({lastFetchedTimeStamp: new Date().toGMTString()})
        }.bind(this));
    }

    componentDidMount(){
        this.fetchCoursesAndCuisines();
    }

    renderMetaDataForm = () => {
        return (
            <Grid container spacing={2} direction="column" style={{width:800}}>
                <Formik initialValues={this.state.recipeMetaData}
                    onSubmit= {(values, actions) => { 
                        console.log(values);                            
                        this.setState({recipeMetaData: values});
                        this.setState({canProceedToNextStep: true})
                    }}>
                    {formik => (
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>                                        
                                    <TextField fullWidth variant="outlined" onChange={formik.handleChange}  type="text" placeholder="name" id="name" label="Recipe Name" value={formik.values.name}/>
                                    </Grid>
                                <Grid item>                                        
                                    <TextField fullWidth variant="outlined" onChange={formik.handleChange}  type="text" placeholder="Source" id="source" label="Source" value={formik.values.source}/>
                                    </Grid>
                                    <Grid item>                                        
                                    <TextField fullWidth variant="outlined" onChange={formik.handleChange}  type="text" placeholder="Thumbnail Url" id="thumbnail" label="Thumbnail" value={formik.values.thumbnail}/>
                                    </Grid>
                                <Grid item>
                                    <TextField fullWidth variant="outlined" multiline onChange={formik.handleChange} type="text" placeholder="description" label="Description" id="description" value={formik.values.description} rows={5}/>
                                </Grid>
                                <Grid item>
                                    <TextField fullWidth label="Author" variant="outlined" onChange={formik.handleChange} type="text" placeholder="author" id="author" value={formik.values.author}/>
                                </Grid>
                                <Grid item style={{display: "flex", justifyContent: "space-between"}}>
                                    <TextField label="PrepTime(Secs)" variant="outlined" onChange={formik.handleChange} type="text" placeholder="prepTime" id="prepTime" value={formik.values.prepTime}/>
                                    <TextField label="CookTime(Secs)" variant="outlined" onChange={formik.handleChange} type="text" placeholder="cookTime" id="cookTime" value={formik.values.cookTime}/>                                    
                                <TextField variant="outlined" onChange={formik.handleChange} type="text" placeholder="servings" label="Servings" id="servings" value={formik.values.servings}/>
                                <TextField variant="outlined" onChange={formik.handleChange} type="text" placeholder="calories" label="Calories" id="calories" value={formik.values.calories}/>
                                </Grid>

                                <Grid item style={{display: "flex", justifyContent: "space-between"}}>
                                <select variant="outlined" value={formik.values.course} onChange={formik.handleChange} id="course" style={{height: 55,  width: "49%"}}>
                                    {
                                        this.state.courses.map((course) => (
                                            <option key={course.name} value={course.name}>{course.name}</option>
                                        ))  
                                    }
                                </select>                                    
                                <select variant="outlined" value={formik.values.cuisine} id="cuisine" onChange={formik.handleChange} style={{height:55, width: "49%"}}>
                                    {
                                        this.state.cuisines.map((cuisine) => (
                                            <option key={cuisine.name} value={cuisine.name}>{cuisine.name}</option>
                                        ))  
                                    }
                                </select>
                                </Grid>
                                <Grid item>
                                <TextField label="Keywords" fullWidth variant="outlined" onChange={formik.handleChange} id="keywords" type="text" placeholder="keywords" value={formik.values.keywords} />                                   
                                </Grid>
                                <Grid item style={{display: "flex", justifyContent: "center"}}>
                                <Button variant="contained" color="secondary" type="submit" disabled={this.state.currentStep == 3 ? true : false}>
                                    <Typography variant="overline">Save</Typography>
                                </Button>
                                </Grid>
                            </Grid>                            
                        </form>
                        )
                    }
                </Formik>
            </Grid>
        )
    }

    addNewIngredientRow = () => {
        this.setState({ingredients: [...this.state.ingredients, ingredient] }) 
    }

    deleteIngredient = (index) => {
        let ingredients = this.state.ingredients;
        ingredients.splice(index,1)
        this.setState({ingredients: ingredients})
    }

    saveIngredient = (newValues, index) => {
        let updatedIngredients = this.state.ingredients;
        updatedIngredients.splice(index,1,{...newValues, saved: true});
        this.setState({ingredients: updatedIngredients});
    }

    newIngredient = (ingredient, index) => {
        return(
            <Formik
                initialValues={ingredient}
                onSubmit={(values) =>{
                    this.saveIngredient(values,index)
                }}>
               {formik => (
                    <form onSubmit={formik.handleSubmit} style={{ alignItems:"center", padding: 5}}>
                        {
                            formik.submitCount == 0 ? (
                                <ErrorIcon color="error"/>
                            ):(
                                <CheckCircleIcon color="action"/>
                            )
                        }
                        <TextField variant="outlined" onChange={formik.handleChange} id="amount" type="text" placeholder="Amount" value={formik.values.amount} label="Amount" style={{width: 100,  marginLeft:10}}  />                                   
                        <TextField variant="outlined" onChange={formik.handleChange} id="unit" type="text" placeholder="Unit" value={formik.values.unit} label="Unit" style={{width: 100, marginLeft: 5}} />                                   
                        <select variant="outlined" value={formik.values.state} id="state" onChange={formik.handleChange} style={{height:55, width: 100, marginLeft: 5}}>
                            <option value ="" selected disabled hidden>State?</option>
                            {
                                this.state.ingredientStates.map((ingredientState,index) => (
                                    <option key={ingredientState} selected={index === 0 ? "selected" : ""} value={ingredientState}>{ingredientState}</option>
                                ))  
                            }
                        </select>
                        <TextField variant="outlined" onChange={formik.handleChange} id="name" type="text" placeholder="Ingredient" value={formik.values.name} label="Ingredient" style={{width: 300, marginLeft: 5}} />     
                        <TextField variant="outlined" onChange={formik.handleChange} id="scalingFactor" type="text" placeholder="1x" value={formik.values.scalingFactor} label="Scaling" style={{width: 100, marginLeft: 5}} />     
                        <select variant="outlined" defaultValue={"Oil"} value={formik.values.category} id="category" onChange={formik.handleChange} style={{height:55, width: 150, marginLeft: 5}}>
                            <option value ="" selected disabled hidden>Category?</option>
                            {
                                this.state.ingredientCategories.map((ingredientCategory,index) => (
                                    <option key={ingredientCategory} selected={index == 0 ? "selected" : ""}  value={ingredientCategory}>{ingredientCategory}</option>
                                ))  
                            }
                        </select>
                        <Button variant="outlined" type="submit" style={{height: 55, marginLeft: 5}}> 
                            Save
                        </Button>
                        <Button variant="outlined" style={{height: 55, marginLeft: 5}} onClick={() => this.deleteIngredient(index)}>Delete</Button>
                    </form>
                )}
            </Formik>
        )
    }

    renderIngredientsForm = ()  => {
        return(
            <Grid container spacing={2} direction="column" alignItems="center">
                <Grid item>
                    <Button variant="contained" onClick={this.addNewIngredientRow}>
                        Add Ingredient
                    </Button>
                </Grid>
                <Grid item style={{height: 600, overflow: "scroll"}}>
                    <Grid container spacing={2} direction="column" justify="center" alignItems="center">
                        {
                            this.state.ingredients.map((ingredient, index) => {
                                return (
                                    <Grid item key={index} style={{display: "flex", justify: "center", alignItems: "center"}}>
                                        {this.newIngredient(ingredient,index)}
                                        
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>                
            </Grid>
        )

    }

    selectTemplate = (index) => {
        this.setState({selectedTemplate: index})
        this.generateInstructions(templates[index], this.state.ingredients)
    }

    renderTemplateForm = () => {
        return(
            <Grid container direction="column" justify="" spacing={2}>
                        {templates.map((template, index) => (
                            <Grid item>
                            <Card style={{padding: 10, margin: 10}}>
                            <Grid container direction="column" justify="center">
                                <Grid item>
                                    <Button variant="outlined" onClick={() => this.selectTemplate(index)}>Select</Button>
                                    {
                                        this.state.selectedTemplate == index && (<CheckCircleIcon />)
                                    }
                                    <Typography variant="h5">
                                        Name: {template.name}
                                    </Typography>
                                    <Typography variant="overline">
                                        Style : {template.style}
                                    </Typography>
                                    
                                </Grid>
                                <Grid item>
                                    <Grid container direction="column" spacing={2}>
                                        { template.steps.map((step,index) => (
                                            <Grid item>{index} : {step.join("-")}</Grid>
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            </Card>
                            </Grid>
                            ))                            
                        }
            </Grid>
        )
    }

    getCookingInstructionFromCookingAction = (action, currentIngredient) => {
        return(
            {
                humanReadable: "",
               ...cookingActionsHash[action],
               cookingAction: action,
               timer: 0,
               ingredient: currentIngredient ? currentIngredient : ingredient
            }
        )
    }

    generateInstructions = (template, ingredients) => {
        console.log(template, ingredients)
        let recipeInstructions = []
        recipeInstructions = template.steps.map(step => {
            if(step.length >1){
                // get all the ingredients
                let stepIngredients = ingredients.filter(ingredient => ingredient.category == step[1])
                let stepInstructions = stepIngredients.map(ingredient => (
                    this.getCookingInstructionFromCookingAction(step[0],ingredient)
                ))
                return stepInstructions

            }else{
                return (
                    this.getCookingInstructionFromCookingAction(step[0])
                )
            }
        } ).flat()
        this.setState({recipeInstructions: recipeInstructions})
    }

    saveInstruction = (newValues, index) => {
        let updatedInstructions = this.state.recipeInstructions;
        updatedInstructions.splice(index,1,newValues);
        this.setState({recipeInstructions: updatedInstructions})
    }

    renderNewInstruction = (recipeInstructionInitialValues, index) => {
        return(
            <Grid container spacing={2} direction="column" justify="center">
                <Grid item>
                    <Card style={{padding: 10}}>
                        <Formik
                            initialValues={recipeInstructionInitialValues}
                            onSubmit={(values,{setSubmitting}) =>{
                                this.saveInstruction(values, index)
                                setSubmitting(false)
                            }}>
                            {formik => (
                                <form onSubmit={formik.handleSubmit}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <TextField fullWidth variant="outlined" multiline row={3} onChange={formik.handleChange} id="humanReadable" type="text" placeholder="Human Readable Instruction" value={formik.values.humanReadable} label="Human Readable Instruction"/>                                   
                                        </Grid>
                                        <Grid item>
                                            <TextField variant="outlined" onChange={formik.handleChange} id="cookingAction" type="text" placeholder="CookingAction" value={formik.values.cookingAction} label="CookingAction" style={{width: 150}} />
                                            <TextField variant="outlined" onChange={formik.handleChange} id="ingredient.name" type="text" placeholder="Ingredient" value={formik.values.ingredient.name} label="Ingredient" style={{width: 200, marginLeft: 5}} />
                                            <TextField variant="outlined" onChange={formik.handleChange} id="ingredient.category" type="text" placeholder="Category" value={formik.values.ingredient.category} label="Category" style={{width: 150, marginLeft: 5}} />
                                            <TextField variant="outlined" onChange={formik.handleChange} id="temperature" type="text" placeholder="Temperature" value={formik.values.temperature} label="Temperature(°C)" style={{width: 150, marginLeft: 5}} />
                                            <select variant="outlined" defaultValue={"Oil"} value={formik.values.stirring} id="stirring" onChange={formik.handleChange} style={{height:55, width: 100, marginLeft: 5}}>
                                                <option value ="" selected disabled hidden>Stirring?</option>
                                                <option selected={formik.values.stirring ? "selected" : ""}  value={true}>ON</option>
                                                <option selected={formik.values.stirring ? "" : "selected"}  value={false}>OFF</option>
                                            </select>
                                            <TextField variant="outlined" onChange={formik.handleChange} id="timer" type="text" placeholder="Timer(Secs)" value={formik.values.timer} label="Timer(Secs)" style={{width: 100, marginLeft: 5}} />
                                            <Button variant="outlined" type="submit" style={{height: 55, marginLeft: 5}}> 
                                                Update
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </Card>
                </Grid>
            </Grid>
        )
    }


    // renderNewInstruction = (step) => {
    //     if(step.length >1){
    //         return(   
    //             ""             
    //         )            
    //     }else{
    //         return(
    //            this.renderNewInstruction({humanReadable: "",
    //                                     cookingAction: step[0],
    //                                     cooktopTemperature: "",
    //                                     stirrerState: ""})
    //         )
    //     }
    // }

    renderInstructionsForm = () => {
        return(
            <Grid direction="column" style={{height: 700, overflow: "scroll"}}>
                {this.state.recipeInstructions.map((instruction,index) => (
                    this.renderNewInstruction(instruction,index)
                ))}
            </Grid>
        )
    }

    parseRecipe = (recipeUrl) => {
        // const main = async () => {
        //     const recipe = await parse(recipeUrl)
        //     console.log(recipe)
        //   }
    }

    renderRecipeParser = () => {
        return(
            <Grid container direction="column" spacing={4} style={{width: "50%"}} justify="center">
                <Grid item>
                    <TextField variant="outlined" value={this.state.recipeSourceUrl} id="recipeSourceUrl" type="text" placeholder="paste the url you want to parse from" label="Recipe Source Url" fullWidth />
                </Grid>
                <Grid item>
                    <Button fullWidth variant="contained" onClick={this.parseRecipe}>Parse</Button>
                </Grid>
            </Grid>
        )
    }

    renderCurrentStep = () => {
        switch(this.state.currentStep){
            case 0:
                return(
                    this.renderRecipeParser()
                    // this.renderMetaDataForm()
                )
            case 1:
                return(
                    // this.renderRecipeParser()
                    this.renderMetaDataForm()
                )
            case 2:
                return(
                    this.renderIngredientsForm()
                )
            case 3:
                return(
                    this.renderTemplateForm()
                )
            case 4:
                return(
                    this.renderInstructionsForm()
                )
        }
    }


    saveRecipe = () => {
        let recipe = {
            ...this.state.recipeMetaData,
            ingredientList: this.state.ingredients,
            recipeInstructions: this.state.recipeInstructions 
        }

        FirebaseApi["recipe"].add({
            recipe
           })
           .then(function(docRef) {
                 console.log( "Data row created with ID: ", docRef.id);
           }.bind(this))
           .catch(function(error) {
           })
    }

    render() {
        return (
            <div>
                <Grid container direction="column" justify="center">
                    <Grid item>
                    <Stepper activeStep={this.state.currentStep} alternativeLabel style={{display: "flex", justifyContent: "center"}}>
                        {STEPS.map((label,index) => (
                            <Step key={label} style={{width: 200}} onClick={()=> this.setState({currentStep: index})}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    </Grid>
                    <Grid item style={{display: 'flex', justifyContent: "center", height: 700}}>
                        { this.renderCurrentStep() }
                    </Grid>
                    <Grid item style={{display: 'flex', justifyContent: "center", alignItems: "center", marginTop: 50}}>
                        <Fab size="small" variant="round" color="secondary" onClick={this.gotoPreviousStep} disabled={this.state.currentStep == 0 ? true : false} style={{margin: 5}}>
                            <NavigateBeforeIcon />
                        </Fab>
                       
                        <Button variant="contained" color="primary" onClick={this.saveRecipe} style={{marginLeft: 20,marginRight: 20}}>
                            Submit
                        </Button>

                        <Fab size="small" variant="round" color="secondary" disabled={this.state.currentStep == 4  ? true : false} onClick={this.gotoNextStep} style={{margin: 5}}>
                            <NavigateNextIcon />
                        </Fab>
                    </Grid>                 

                    
                </Grid>                
            </div>
        );
    }
}

export default NewRecipe;