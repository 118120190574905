
import React, { useContext, useState, useEffect } from 'react'
import Grid from '@mui/material/Grid';
import { Button, TextField, Typography, Select, MenuItem, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { Formik, useFormik } from 'formik';
import EntityAPI from '../../../apis/entity';
import { FirebaseApi } from '../../../firebaseConfig'
import Stack from '@mui/material/Stack';
//import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useHistory, useLocation, useParams } from 'react-router-dom';
import AppContext from '../../../config/AppContext';
import { Autocomplete } from '@mui/material';
import Fuse from 'fuse.js'




const UnitConversionsModal = (props) => {
    const [modal, setModal] = useState(true);
    const [dataOf, setDataOf] = useState('conversions')
    const [imageLink, setImageLink] = useState('');
    const [selectedRows, setSelectedRowData] = useState([])
    const [unitsLaoding, setUnitsLoading] = useState(false)
    const [newUnitSuggestions, setNewUnitSuggestions] = useState([])
    const [destinationUnitSuggestions, setDestinationUnitSuggestions] = useState([])
    const [fuse, setFuse] = useState(null)
    const location = useLocation()
    const [addingUnit, setAddingUnit] = useState('')
    const [addingDestinationUnit, setAddingDestinationUnit] = useState('')
    const [currentEntityData, setCurrentEntityData] = useState(location.state)

    const [newunitsSuggestions, setNewunitsSuggestions] = useState([])
    const [addingUnits, setAddingUnits] = useState('')

    const history = useHistory();


    //  for Getting from DB
    const getUnitsList = async () => {
        setUnitsLoading(true)
        try {
            const ingredientsFromDB = await FirebaseApi["units"].get()

            const ingredientsData = ingredientsFromDB.docs.map((ingredient) => {
                return {
                    id: ingredient.id,
                    ingredient: ingredient.data().name,
                    ...ingredient.data()
                }
            })

            // setIngredientList(ingredientsData)
            const fuseInstance = new Fuse(ingredientsData, {
                useExtendedSearch: true,
                keys: ["name"]
            })
            setFuse(fuseInstance)
            console.log("called this",)
            //checkRecipeIngredientsInOurDB['exactMatch'](fuseInstance)
        } catch (error) {
            console.log(error)
        }
    }

    const addingNewUnit = (e) => {
        setAddingUnit(e.target.value)
        console.log(checkForSimilarUnits(fuse, e.target.value))
        setNewUnitSuggestions(checkForSimilarUnits(fuse, e.target.value))
    }

    const checkForSimilarUnits = (fuse, name) => {
        return fuse.search(`'${name.replace(/[^a-zA-Z ]/g, "")}`).map(i => i.item)
    }
    const onInputChange = (e, value) => {
        formik.setFieldValue('sourceUnit', value)
    };

    const searchAddingDestinationUnit = (e) => {
        setAddingDestinationUnit(e.target.value)
        setDestinationUnitSuggestions(checkForSimilarUnits(fuse, e.target.value))
    }

    const onDestinationUnitsChange = (e, value) => {
        formik.setFieldValue('destinationUnit', value)
    }


    // For Images

    useEffect(() => {
        if (location.state) {
            setCurrentEntityData(location.state)
        } else {
            // Get the data from the db using the params
        }

        getUnitsList()
    }, [])


    // CRUD
    const addConversion = (rowData) => {
        console.log("coming here", rowData)
        FirebaseApi[dataOf].add({
            ...rowData
        }).then(function (docRef) {
        })
            .catch(function (error) {
            })
    }

    const updateConversion = (data, id) => {
        console.log("coming here", data)
        FirebaseApi[dataOf].doc(id).update(data)
            .then((result) => {
                setSelectedRowData(null)
            })
            .catch((error) => {
                console.log({ error })
            })
    }



    const actionTriggered = (edited, data) => {
        if (data.id) {
            console.log("updating the row", data)
            const { sourceUnit, destinationUnit, unitType, conversionFactor } = data
            updateConversion({ sourceUnit, destinationUnit, unitType, conversionFactor }, data.id)
        }
        else {
            console.log("adding the row", data)
            const { sourceUnit, destinationUnit, unitType, conversionFactor } = data
            addConversion({ sourceUnit, destinationUnit, unitType, conversionFactor })

        }

    }



    const formik = useFormik({
        initialValues: {
            'id': currentEntityData ? currentEntityData.id : '',
            'sourceUnit': currentEntityData ? currentEntityData.sourceUnit : [],
            'destinationUnit': currentEntityData ? currentEntityData.destinationUnit : [],
            'unitType': currentEntityData ? currentEntityData.unitType : '',
            'conversionFactor': currentEntityData ? currentEntityData.conversionFactor : ''
        },
        // validationSchema,
        onSubmit: values => {
            console.log(values)
            if (currentEntityData) {
                actionTriggered(true, values)
            } else {
                actionTriggered(false, values)
            }

        },
    });



    return (

        <Modal
            open={modal}
            // onClose={() => setModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <form onSubmit={formik.handleSubmit} style={{ outline: "none" }}>
                <Grid sx={style} container spacing={2} flexDirection='column' justifyContent="space-between">

                    <Grid item style={{ display: "flex", flexDirection: "row" }} justifyContent='space-between' marginBottom='10'>
                        <Typography fontWeight='700' variant='h6'> {currentEntityData ? "Edit a Conversion": "Add a new Conversion"}</Typography>
                        <CloseIcon style={{ marginLeft: '100px', cursor: 'pointer' }} onClick={() => history.goBack()} />
                    </Grid>
                    <Grid item container style={{ display: "flex", flexDirection: "row" }} justifyContent='space-between'>
                        <Grid item xs={5}>
                            <Typography>Source Unit</Typography>
                            <Autocomplete
                                id="sourceUnit"
                                style={{ width: "100%", zIndex: 1000, flexGrow: 1, marginBottom: '10' }}
                                options={newUnitSuggestions}
                                autoHighlight
                                multiple
                                onInput={addingNewUnit}
                                getOptionLabel={((option) => option.name) || "Choose" }
                                onChange={(e, value) => onInputChange(e, value)}
                                size='small'
                                defaultValue={formik.values.sourceUnit}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder='Source Unit'
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={5} marginRight={2}>
                            <Typography>Destination Unit</Typography>
                            <Autocomplete
                                id="destinationUnit"
                                style={{ width: "100%", zIndex: 1000, flexGrow: 1, marginBottom: '10' }}
                                options={destinationUnitSuggestions}
                                autoHighlight
                                multiple
                                onInput={searchAddingDestinationUnit}
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => onDestinationUnitsChange(e, value)}
                                size='small'
                                defaultValue={formik.values.destinationUnit}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder='Destination Unit'
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography>Conversion Factor</Typography>
                        <TextField
                            id="conversionFactor"
                            defaultValue={currentEntityData ? currentEntityData.conversionFactor : formik.values.conversionFactor}
                            variant="outlined"
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item>
                        <Typography>Unit type</Typography>
                        <Select
                            id="unitType"
                            name="unitType"
                            value={currentEntityData ? currentEntityData.unitType : formik.values.unitType}
                            label="unitType"
                            fullWidth
                            onChange={formik.handleChange}
                        >
                            <MenuItem value={'default'}>default</MenuItem>
                            <MenuItem value={'Mass'}>Mass</MenuItem>
                            <MenuItem value={'Temperature'}>Temperature</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={() => { formik.handleSubmit(); history.goBack(); }}>{currentEntityData ? 'Save' : 'Add'}</Button>
                    </Grid>

                </Grid>
            </form>
        </Modal>
    )
}

export default UnitConversionsModal

const style = {
    marginTop: "5%",
    marginLeft: 'auto',
    marginRight: "auto",
    width: '50%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
