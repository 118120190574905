import { FirebaseApi } from "../../../firebaseConfig";
import { CRUDoperations } from "../../CRUD/crud";


const creatorUILayouts = {
    // for creating layout
    create: async (creatorId, data) => {
        try {
            const newLayout = await CRUDoperations.create('creatorUILayouts', {
                creatorId: creatorId,
                type: 'creatorUILayout',
                values: data

            });
            return newLayout
        }
        catch (err) {
            return "No creatorUILayouts found";
        }
    },
    fetch: async () => {
        try {
            const getcreatorUILayouts = await FirebaseApi['creatorUILayouts'].get();
            const creatorUILayouts = getcreatorUILayouts.docs.map(creatorUILayouts => ({
                ...creatorUILayouts.data(), id: creatorUILayouts.id
            }))




            return creatorUILayouts;

        } catch (err) {

        }
    },
    fetchByCreatorId: async (userId) => {
        try {
            const data = await FirebaseApi.creatorUILayouts.where('creatorId', '==', `${userId}`).get();
            const final = data.docs.map(creatorUILayouts => ({
                ...creatorUILayouts.data(), id: creatorUILayouts.id
            }))

            return final;


        } catch (err) {

        }
    },
    delete: async (docId) => {
        try {
            const deleting = await CRUDoperations.delete("creatorUILayouts", docId)
            return 'done'

        }
        catch (err) {
            throw err

        }

    },
    update: async (docId, data) => {

        try {
            const updating = await CRUDoperations.update("creatorUILayouts", docId,)
            return updating




        } catch (err) {
            return err;
        }
    },

}

export default creatorUILayouts;