import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    addRecipeContainer: {
        backgroundColor: '#F6F7FB',
    },
    addRecipeNavContainer: {
        width: '100%',
        margin: 'auto',
        backgroundColor: '#F94C0C',
        marginBottom: 0,
    },
    entityContainer: {
        borderTopRightRadius: 4,
        borderTopLeftRadius: 4,
        backgroundColor: '#FF875A',
        padding: 5,
        color: 'white',
        "&[data-currentEntity=true]": {
            backgroundColor: 'white',
            color: 'black'
        }
    },
    entityType: {
        width: '150px',
        padding: 10,
        borderRadius: 4,
        outline: 'none'
    },
    navSearchDiv: {
        width: '200px',
        backgroundColor: 'transparent',
        marginLeft: 5,
    },
    navSearch: {
        outline: 'none',
        border: 'none',
        width: '100%',
        background: 'transparent'
    },

    // disable button
    enabledButton: {
        padding: 5, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
        '&:disabled': {
            color: 'green', padding: 5, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'
        }
    },
    iconButtons: { padding: 5, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', border: '1px solid white', borderRadius: 4 }
});

export default useStyles;