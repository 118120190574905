import React, { useState, useEffect, useContext } from 'react'
import { Typography, Grid } from '@mui/material';
import { Formik, useFormik } from 'formik';
import CloudServices from '../../../cloud-services-core';
import { FirebaseApi } from '../../../firebaseConfig';


const UserDetails = (props) => {
    console.log(CloudServices);

    const getUser = async (id) => {

        const val = await CloudServices.user.getUserDetails();
        console.log(val);
    }
    getUser('sJIGnc0kzCgpKFDrNGIZ0gtQ8cY2');

    // name = "TestUser10", 2usA40OHzDQWhbjf7pVkS3odtoG3
    // name: 'Random2", "sJIGnc0kzCgpKFDrNGIZ0gtQ8cY2"
    // name: 'Random3' ,  "B1UpxCkJ8ub8Z49Y59G8cFJfYk13"
    // name: 'Random4', "cslTcwidabdnNO9o1GWnnH39XMD2"
    // "sam", "wGSHwYMt1KOIBTMXpUyEnt3Pqgz2"



    const makeUser = async (values) => {
        console.log(values);
        try {
            //const article = await CloudServices.creatorBillings.createWithId('2usA40OHzDQWhbjf7pVkS3odtoG3', 'cslTcwidabdnNO9o1GWnnH39XMD2', values);
            ///const user = await FirebaseApi["user"].doc(values.id).set(values.)

            console.log("Updated");
        } catch (err) {
            console.log(err);
        }
    }



    const formik = useFormik({
        initialValues: {
            id: 'sJIGnc0kzCgpKFDrNGIZ0gtQ8cY2',
            username: 'Random2',
            email: '',
            password: '12345'
        },
        // initialValues: {
        //     issueDate: '2/3/22',
        //     dueDate: '4/4/22',
        //     amount: 833,
        //     status: 'Paid',
        // },
        onSubmit: values => {
            console.log(values);
            // makeUser(values);
        },
    });
    return (
        <form onSubmit={formik.handleSubmit}>
            {/* <Grid style={{ display: 'flex', flexDirection: 'column', height: 800, justifyContent: 'center', alignItems: 'center' }}>
                <Typography >issueDate</Typography>
                <input

                    id="issueDate"
                    onChange={formik.handleChange}
                    value={formik.values.issueDate}

                />



                <Typography >dueDate</Typography>
                <input

                    id="dueDate"
                    onChange={formik.handleChange}
                    value={formik.values.dueDate}


                />
                <Typography >amount</Typography>
                <input

                    id="amount"
                    onChange={formik.handleChange}
                    value={formik.values.amount}


                />

                <Typography >status</Typography>
                <input

                    id="status"
                    onChange={formik.handleChange}
                    value={formik.values.status}


                />

                <Grid>
                    <button type="submit">Submit</button>
                </Grid>
            </Grid> */}

            <Grid style={{ display: 'flex', flexDirection: 'column', height: 800, justifyContent: 'center', alignItems: 'center' }}>
                <Typography >Id</Typography>
                <input

                    id="id"
                    onChange={formik.handleChange}
                    value={formik.values.id}

                />
                <Typography >Name</Typography>
                <input

                    id="username"
                    onChange={formik.handleChange}
                    value={formik.values.username}

                />

                <Typography >email</Typography>
                <input

                    id="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}


                />


                <Typography >password</Typography>
                <input

                    id="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}


                />


                <Grid>
                    <button type="submit">Submit</button>
                </Grid>
            </Grid>
        </form>

    )
}

export default UserDetails;