import { styled } from "@mui/system";
import { makeStyles } from "@material-ui/core";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const DashBoardLink = styled('div')({
    height: 150,
    width: 150,
    marginRight: 16,
    border: '1px solid #E8E8E8',
    borderRadius: 5,
    cursor: 'pointer'
});

export const ChartsBox = styled(Box)({
    marginTop: 24,
    marginLeft: 16,
    height: 180,
    width: 800,
    border: '1px solid #E8E8E8',
});

export const ProfileBox = styled(Box)({
    width: 'inherit',
    height: 350,
    borderRadius: '5px',
    // border: '1px solid #DBDCDD',
    overflow: "auto"
});

const useStyles = makeStyles({
    imageDashboard: {
        width: 40,
        height: 40,
    },
});

export const ButtonCreatorPage = styled(Link)({
    width: 'fitContent',
    height: '20px',
    borderRadius: '5px',
    textDecoration: 'none',
    background: '#F4F5F7',
    padding: '8px 10px',
    color: '#292929',
}) ;

//content creation
export const EmptyCard = styled(Box)({
    margin: '20px auto',
    height: '50vh',
    width: '80%',
    // backgroundImage: url('../../assets/CreatorScreen/emptyImage.png'),
});

export const AccordionHeader = styled(Box)({
    height: '40px',
    width: '100%',
    background: "#DBDCDD",
});

export const HeaderTypography = styled(Typography)({
    padding: '10px',
    fontSize: '14px',
    color: '#292929'
});

export const ItemsLink = styled(Link)({
    textDecoration: 'none',
    "&:hover": {
        textDecoration: 'underLine',
    },
});

export const GridCharts = styled(Box)({
    padding: "20px 10px",
});

export default useStyles;