import {
    Button,
    Grid,
    Typography,
    Badge,
    TextField,
    Select,
    MenuItem,
    NativeSelect,
    Stepper,
    Step,
    StepLabel,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    Fab,
    Card,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    CircularProgress
} from '@material-ui/core';
import { withStyles,makeStyles } from '@material-ui/core/styles';
import { styles } from '../../config/styles';
import React, { Component,useState,useContext,useEffect } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import { FieldArray, Form, Formik, FastField, Field } from 'formik';
import ImageUploader from 'react-images-upload';
import FirebaseApi from '../../apis/firebaseApi';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { FormatColorResetSharp, FormatLineSpacing } from '@material-ui/icons';
import ErrorIcon from '@material-ui/icons/Error';
import { green, red } from '@material-ui/core/colors';
import axios from 'axios'
import ing from 'ingredientparser'
// import { parse } from 'recipe-ingredient-parser';
import { parse } from 'recipe-ingredient-parser-v2'

import { Add, Remove, ExpandMore } from '@material-ui/icons';
import AppContext from '../../config/AppContext';

const STEPS = ["Upload", "Recipe MetaData", "Ingredients", "Template", "Instructions"]

const recipeMetaDataSample = {
    '@context': '',
    '@id': '',
    '@type': 'Recipe',
    source: '',
    aggregateRating: {
        '@type': 'AggregateRating',
        ratingCount: '',
        ratingValue: '',
    },
    author: {
        '@type': 'Person',
        name: ''
    },
    cookTime: 0,
    datePublished: '',
    description: "",
    image: [''],
    keywords: "",
    name: "",
    nutrition: {
        '@type': 'NutritionInformation',
        calories: '',
        servingSize: ''
    },
    prepTime: 0,
    recipeCategory: '',
    recipeCuisine: '',
    recipeIngredient: [''],
    recipeInstructions: [],
    recipeYield: [],
    video: {
        '@type': 'VideoObject',
        contentUrl: '',
        description: '',
        embedUrl: '',
        name: '',
        thumbnailUrl: ''
    }

}

const ingredient = {
    amount: "",
    unit: "",
    name: "",
    state: "",
    scalingFactor: "",
    category: "",
    saved: false
}

const newIngredient = "10 grams ingredient"

const recipeInstruction = {
    humanReadable: "",
    cookingAction: "",
    cooktopTemperature: "",
    stirrerState: "",
    ingredient: ingredient
}

const cookingActions = [

    {
        name: "preHeatPan",
        description: "Place the pan on the stove and heat it up to 70°C",
        temperature: 70,
        stirring: false
    },
    {
        name: "dispenseLiquid",
        temperature: "inherit",
        stirring: false
    },
    {
        name: "dispenseSpice",
        temperature: "inherit",
        stirring: false
    },
    {
        name: "dispenseIngredient",
        temperature: "inherit",
        stirring: false
    },
    {
        name: "startStirring",
        temperature: "inherit",
        stirring: true
    },
    {
        name: "stopStirring",
        temperature: "inherit",
        stirring: false
    },
    {
        name: "boil",
        temperature: 100,
        stirring: false,
    },
    {
        name: "boilWithStirring",
        temperature: "inherit",
        stirring: true,
    },
    {
        name: "saute",
        temperature: 80,
        stirring: true
    }
]


const cookingActionsHash = {
    "preHeatPan": {
        name: "preHeatPan",
        text: "Place the pan on the stove and heat it up to 70°C",
        temperature: 70,
        stirring: false
    },
    "dispenseLiquid": {
        name: "dispenseLiquid",
        text: "Dispensing Liquid",
        temperature: "inherit",
        stirring: false
    },
    "dispenseSpice": {
        name: "dispenseSpice",
        temperature: "inherit",
        text: "Dispensing Spice",
        stirring: false
    },
    "startStirring": {
        name: "startStirring",
        temperature: "inherit",
        text: "Stirring",
        stirring: true
    },
    "stopStirring": {
        name: "stopStirring",
        temperature: "inherit",
        text: "Stopped stirrig",
        stirring: false
    },
    "boil": {
        name: "boil",
        temperature: 100,
        text: "boiling",
        stirring: false,
    },
    "boilWithStirring": {
        name: "boilWithStirring",
        temperature: "inherit",
        text: "boil with stirring",
        stirring: true,
    },
    "saute": {
        name: "saute",
        temperature: 80,
        text: "Sauting",
        stirring: true
    }
}

const templates = [
    {
        name: "Classic Curry",
        style: "Stew",
        steps: [
            ["preHeatPan"],
            ["dispense", "Oil"],
            ["dispense", "Spice/Herb"],
            ["stir"],
            ["saute"],
            ["dispense", "Sauce Ingredient"],
            ["dispense", "Flavor"],
            ["dispense", "Main Ingredient"],
            ["dispense", "Water"],
            ["boil"],
            ["dispense", "Seasoning"],
            ["dispense", "Garnish"]
        ]
    },
    {
        name: "Classic Fry",
        style: "Fry",
        steps: [
            ["preHeatPan"],
            ["dispense", "Oil"],
            ["dispense", "Spice/Herb"],
            ["stir"],
            ["dispense", "Sauce Ingredient"],
            ["saute"],
            ["dispense", "Flavor"],
            ["saute"],
            ["dispense", "Main Ingredient"],
            ["dispense", "Seasoning"],
            ["dispense", "Garnish"]
        ]
    }, {
        name: 'Custom Type',
        style: 'Custom',
        steps: [['Custom Steps']]
    }


]

const useStyles = makeStyles((theme)=>({
    parseContainer:{
        width:'50%',
        backgroundColor:'gray',
        [theme.breakpoints.down('sm')]:{
          width:'90%'
        }

    }

}))


function NewRecipe (props){

    const [fetching,setFetching] = useState(false)
    const [recipeSourceUrl,setRecipeSourceUrl] = useState("")
    const [parsingRecipe,setParsingRecipe] = useState()
    const [parsedRecipe,setParsedRecipe] = useState("")
    const [currentStep,setCurrentStep] = useState(0)
    const [cuisines,setCuisines] = useState(['Indian', 'Italian', 'Thai'])
    const [courses,setCourses] = useState(['Main', 'Starter'])
    const [recipeMetaData,setRecipeMetaData] = useState(recipeMetaDataSample)
    const [ingredients,setIngredients] = useState([])
    const [recipeInstructions,setRecipeInstructions] = useState([{}])
    const [tools,setTools] = useState([])
    const [canProceedToNextStep,setCanProceedToNextStep] = useState(false)
    const [ingredientStates,setIngredientStates] = useState([])
    const [ingredientCategories,setIngredientCategories] = useState([
        { name: "Oil" },
        { name: "Spice/Herb" },
        { name: "Sauce Ingredient" },
        { name: "Flavor" },
        { name: "Main Ingredient" },
        { name: "Seasoning" },
        { name: "Garnish" }
    ])
    const [selectedTemplate,setSelectedTemplate] = useState(null)
    const [parsedData,setParsedData] = useState('')
    const [parsedLinkData,setParsedLinkData] = useState("")
    const [parsingError,setParsingError] =useState(false)


    // cookingactions
    const [cookingActions,setCookingActions] = useState(["preHeatPan", "dispense", "stir", "saute"])
     // instructions tab
    const [selectedIngredientState,setSelectedIngredientState] = useState('')
    const [ingredient,setIngredient] = useState('')

     // ExistingDataSection

    const [existingRecipeId,setExistingRecipeId] = useState(props.match.params.id || null)
    const [existingRecipeEdit,setExistingRecipeEdit] = useState(false)
    const [existingDataFetched,setExistingDataFetched] = useState(false)



    const [ingredientAll,setIngredientAll] = useState('')
    const [recipeIngredient,setRecipeIngredient] = useState('')
    const [toolsEnabled,setToolsEnabled] = useState(false)
    
    // context
    const AppState = useContext(AppContext)
    // const [] = useState()
    // const [] = useState()
    // const [] = useState()
    // const [] = useState()
    // const [] = useState()
    // const [] = useState()
    // const [] = useState()
    // const [] = useState()
 
       


    


    const gotoStep = (index) => {
        setCurrentStep(index)
        
    }
    const gotoNextStep = () => {

        setCurrentStep(currentStep + 1)
        



    }
    const gotoPreviousStep = () => {
        setCurrentStep(currentStep-1)
        
    }




    useEffect(()=>{
        // this.fetchCoursesAndCuisines();
        AppState.closeDrawer()
        if (props.match.params.id != 0) {
            setFetching(true)
            
            getParsedData(props.match.params.id, true)
        }
        var ingredientStates = []
        var cuisines = []
        var courses = []
        var cookingActions = []
        var tools = []
        FirebaseApi["ingredientState"].get().then(snapshot => {
            snapshot.forEach(childSnapshot => {
                var id = childSnapshot.id;
                var data = childSnapshot.data()

                ingredientStates.push({ ...data, id: id })
            })
        })
        FirebaseApi["cuisine"].get().then(snapshot => {
            snapshot.forEach(childSnapshot => {
                var id = childSnapshot.id;
                var data = childSnapshot.data()

                cuisines.push({ ...data, id: id })
            })
        })
        FirebaseApi["course"].get().then(snapshot => {
            snapshot.forEach(childSnapshot => {
                var id = childSnapshot.id;
                var data = childSnapshot.data()

                courses.push({ ...data, id: id })
            })
        })
        FirebaseApi["actions"].get().then(snapshot => {
            snapshot.forEach(childSnapshot => {
                var id = childSnapshot.id;
                var data = childSnapshot.data()

                cookingActions.push({ ...data, id: id })
            })
        })
        FirebaseApi["tools"].get().then(snapshot => {
            snapshot.forEach(childSnapshot => {
                var id = childSnapshot.id;
                var data = childSnapshot.data()

                tools.push({ ...data, id: id })
            })
        })

        setIngredientStates(ingredientStates)
        setCuisines(cuisines)
        setCourses(courses)
        setCookingActions(cookingActions)
        setTools(tools)

        

        
    },[])



    const addNewIngredientRow = () => {

        setRecipeMetaData({...recipeMetaData,recipeIngredient: [...recipeMetaData.recipeIngredient, newIngredient]})
        
    }

    const deleteIngredient = (index) => {
        let ingredients = ingredients;
        ingredients.splice(index, 1)
        setIngredients(ingredients)
        
    }

    const saveLocally = (e, ingredient) => {
        console.log(ingredient)

    }

    const saveIngredient = (newValues, index) => {
        if (existingRecipeEdit) {
            // when we are changing the ingredients of existing recipe
            // we need to update the recipeMetaData.recipeInstrcutionParsed,
            // to show them correctly in instruction page 
            var val = recipeMetaData && recipeMetaData.recipeInstructionsParsed.map((instructions, index) => {
                var itemListElement = instructions.itemListElement.map((value, index) => {
                    return ({
                        ...value,
                        machineCode: (value.machineCode.map((code, valueIndex) => {

                            return ({
                                ...code,
                                category: (newValues.ingredientDetails.filter(ingredient => ingredient.ingredient == code.ingredient))[0] && (newValues.ingredientDetails.filter(ingredient => ingredient.ingredient == code.ingredient))[0].category,
                                ingredientDetails: (newValues.ingredientDetails.filter(ingredient => ingredient.ingredient == code.ingredient))[0]
                            })
                        }))
                    })
                })
                return ({
                    ...instructions,
                    itemListElement: itemListElement
                })
            })

            setRecipeMetaData({ ...recipeMetaData, recipeIngredientParsed: newValues.ingredientDetails, recipeInstructionsParsed: val })

            


        }
        else {

            setIngredients(newValues.ingredientDetails)
            setRecipeMetaData({ ...recipeMetaData, recipeIngredientParsed: newValues.ingredientDetails })

            

        }

        // let updatedIngredients = ingredients;
        // updatedIngredients.splice(index, 1, { ...newValues, saved: true });
        // this.setState({ ...this.state, ingredients: updatedIngredients });
    }





    const templateSelect = (index) => {
        if (index === 0 || index === 1) {
            if (existingRecipeEdit) {
                generateInstructions(templates[index], null, recipeMetaData && recipeMetaData.recipeIngredientParsed, index)

            }
            else {

                generateInstructions(templates[index], null, ingredients, index,)
            }

        }
        else {
            if (existingRecipeEdit) {
                generateInstructions(templates[index], recipeMetaData && recipeMetaData.recipeInstructionsParsed, null, index)

            }
            else {

                generateInstructions(templates[index], recipeMetaData.recipeInstructions, null, index)
            }

        }
    }


    const getCookingInstructionFromCookingAction = (action, currentIngredient = {}) => {

        return ({
            '@type': 'HowToStep',
            text: "",
            machineCode: [{
                category: action[1],
                cookingAction: action[0],
                timer: 0,
                ingredient: currentIngredient.ingredient,
                ingredientDetails: currentIngredient ? currentIngredient : { ...ingredient },

            }]



        })
        // }

    }

    const generateInstructions = (template, instructions, ingredients, index) => {

        let newArray = []
        var automatic;
        if (index === 0 || index === 1) {


            newArray = template.steps.map(step => {
                // step is sub array of individual steps in template.steps
                if (step.length > 1) {
                    // get all the ingredients
                    // step:['dispense','Spice/Herb']
                    // step[0]= dispense,step[1]='Spice/Herb'
                    let stepIngredients = ingredients.filter(ingredient => ingredient.category == step[1])

                    // let stepInstructions = this.getCookingInstructionFromCookingAction(step[0], stepIngredients)
                    let stepInstructions = stepIngredients.map(ingredient => (
                        // step:['dispense','Spice/Herb']
                        getCookingInstructionFromCookingAction(step, ingredient)
                    ))


                    return stepInstructions

                } else {
                    return (
                        // step:['dispense','Spice/Herb']
                        getCookingInstructionFromCookingAction(step)
                    )
                }
            }).flat()
            automatic = true

        }
        else {


            instructions.map(instruction => {
                if (instruction.itemListElement) {
                    // console.log(instruction.itemListElement)
                    let itemInstructions = []
                    instruction.itemListElement.map((item, index) => (
                        itemInstructions.push(item.text)

                    ))

                    newArray.push({ name: instruction.name, itemInstructions })
                }
                else {

                    newArray.push(instruction.text)

                }

            })
            automatic = false
        }



        setRecipeInstructions([...newArray])
        setSelectedTemplate(index)
        

    }

    const saveInstruction = (newValues, index,) => {





        var defaultHasSections;
        var val = {};
        if (existingRecipeEdit) {
            recipeMetaData && recipeMetaData.recipeInstructionsParsed.map((instruction, index) => {


                var list = newValues.instruction.itemListElement.map((value, index) => {

                    return ({
                        ...value,
                        machineCode: (value.machineCode.map((code, valueIndex) => {
                            return ({
                                ...code,
                                ingredientDetails: (recipeMetaData && recipeMetaData.recipeIngredientParsed.filter(ingredient => ingredient.ingredient == code.ingredient))[0]
                            })
                        }))


                    })
                })

                val = {
                    ...newValues.instruction,
                    itemListElement: list
                }
                return defaultHasSections = true
            })

        }
        else {

            var itemListElement = []
            var itemListElement = newValues.instruction.itemListElement.map((value, index) => {

                return ({
                    ...value,
                    machineCode: (value.machineCode.map((code, valueIndex) => {
                        return ({
                            ...code,
                            ingredientDetails: (ingredients.filter(ingredient => ingredient.ingredient == code.ingredient))[0]
                        })
                    }))


                })
            })
            defaultHasSections = true
            val = {
                '@type': 'HowToSection',
                itemListElement: itemListElement,
                name: 'cook with riku'
            }


        }

        if (defaultHasSections) {


            setRecipeMetaData({ ...recipeMetaData, recipeInstructionsParsed: [val] })
           
        }
        else {

            setRecipeMetaData({ ...recipeMetaData, recipeInstructionsParsed: [{ '@type': 'HowToSection', name: 'Cook With Riku', itemListElement: val }] })
            
        }



        // completeInstructions: newValues.instruction 
    }


    const handleIngredient = (value) => {


    }

    // when ever user selects a state in 4th step we show ingredient which have category same as selected category
    const selectIngredientOptions = (state) => {
        if (existingRecipeEdit) {
            // if(ingredients.length >=1){
            //     const ingredients = ingredients.filter(ingredient => ingredient.category == state)

            //     return (
            //        ingredients.map((ingredient, index) => (
            //                 <option value={ingredient.ingredient}>{ingredient.ingredient}</option>

            //             ))
            //     )

            // }
            // else{

            const ingredients = recipeMetaData.recipeIngredientParsed.filter((ingredient, index) => ingredient.category == state)
            return (
                ingredients.map((ingredient, index) => (
                    <option value={ingredient.ingredient}>{ingredient.ingredient}</option>

                ))

            )
            // }
        }
        else {
            const ingredients = ingredients.filter(ingredient => ingredient.category == state)

            return (
                <>
                    {ingredients.map((ingredient, index) => (
                        <option>{ingredient.ingredient}</option>

                    ))}
                </>
            )
        }


    }

    const renderNewInstruction = (recipeInstructionInitialValues, selectedTemplate) => {


        // recipeInstructionInitialValues comes from instrucitons

        // if this is for editing the existing recipe then we dont need to generate instructions
        //  beacause we have them already we are here only for editing that

        // Instructions are generated in three ways
        // 2 of them are fully automatic with predefined steps 
        // other one is semi automatic with steps defined by author

        // So initially we need to check if the selected Template is fully automatic or author defined
        // if selected Template is between 0 or 1 
        // then these are RIKU prefered and fully automatic
        if (selectedTemplate === 0 || selectedTemplate === 1) {

            // Once we check the selected template 
            // we need to check wether user is editing the exisiting recipe or creating a new 
            // if editing the existing recipe
            if (existingRecipeEdit) {

                var newArray = []
                // if this is existing recipe we already have instructions 
                // we are trying to assign them to state



                // recipeMetaData Example
                // recipeMetaData:{
                //     recipeInstructions:[
                //     {
                //      @type:'HowToSection',
                //      name:'preparations',
                //      ListItemElements:[],

                //     }
                // ]
                // }

                // recipeInstructions Example
                // recipeInstructions can be of two types according to SCHEMA.org
                // recipeInstructions:[
                //     {
                //      @type:'HowToSection',
                //      name:'preparations',
                //      ListItemElements:[
                //          {
                //              @type:'HowToStep',
                //              text:'',
                //              name:''
                //          }
                //      ],
                //     }
                // ]

                // recipeInstructions:[
                //     {
                //         @type:'HowToStep',
                //         name:'',
                //         text:''
                //     }
                // ]

                // So we are iterating through recipeInstruction array
                recipeMetaData && recipeMetaData.recipeInstructionsParsed.map(instruction => {
                    newArray.push(instruction)
                    // instruction.itemListElement.map((list) => {

                    // })
                })
                // recipeInstructionInitialValues.map(instruction => {
                //     // Trying to find a field in array with name='Cook With Riku',
                //     // Because this is an existing code and it should have this field already 

                //     // after finding the field with name='Cook With Riku'
                //     // we need to check which type of instruction structure it have , as mentioned above
                //     // if we have itemListElement in the field then it is type one 
                //     // we can pull instructions directly and assign it newArray
                //     if (instruction.itemListElement) {

                //         newArray.push(recipeInstructions)
                //     }
                //     else {

                //     }
                //     // if we dont have itemListElement , then it doesn't have section . it has steps directly




                // })

                return (
                    <Grid container spacing={2} direction="column" justify="center">
                        <Grid item>
                            <Card style={{ padding: 10 }}>
                                <Formik
                                    initialValues={{
                                        instruction: newArray[0]
                                    }}
                                    onSubmit={(values,) => {
                                        saveInstruction(values)

                                    }}>
                                    {formik => (
                                        <FieldArray name='instruction.itemListElement'>
                                            {props => {
                                                const { form, insert, remove } = props
                                                const { values } = form
                                                const { instruction } = values


                                                return (
                                                    <>
                                                        <div>
                                                            {instruction.itemListElement && instruction.itemListElement.map((value, elementIndex) => (




                                                                <Form onSubmit={formik.handleSubmit}>

                                                                    <Grid container direction="column" spacing={2}>
                                                                        <Grid item style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                            <FastField fullWidth variant="outlined" multiline row={3} onChange={formik.handleChange} id={`instruction.itemListElement[${elementIndex}].text`} type="text" placeholder="Human Readable Instruction" value={formik.values.instruction.itemListElement[elementIndex].text} label="Human Readable Instruction" />
                                                                            <div>
                                                                                <Add onClick={() => insert(elementIndex + 1, {

                                                                                    instruction: '',
                                                                                    machineCode: [{
                                                                                        ingredientState: '',
                                                                                        action: '',
                                                                                        ingredient: '',
                                                                                        tool: ''

                                                                                    }]


                                                                                })} />


                                                                                <Remove onClick={() => remove(elementIndex)} />
                                                                            </div>
                                                                        </Grid>

                                                                        {/* {initialValues[index].ingredient && initialValues[index].ingredient.map((ingredient, ingredientIndex) => ( */}

                                                                        <FieldArray name={`instruction.itemListElement[${elementIndex}].machineCode`}>
                                                                            {(props) => {
                                                                                const { push, remove, form, insert } = props
                                                                                const { values } = form
                                                                                const { instruction } = values


                                                                                return (
                                                                                    instruction.itemListElement[elementIndex].machineCode.map((code, machineIndex) => (

                                                                                        <Grid item>
                                                                                            <FastField variant="outlined" onChange={formik.handleChange} id={`instruction.itemListElement[${elementIndex}].machineCode[${machineIndex}].cookingAction`} type="text" placeholder="CookingAction" value={code.cookingAction} label="CookingAction" style={{ width: 150 }} />

                                                                                            <FastField variant="outlined" onChange={formik.handleChange} id={`instruction.itemListElement[${elementIndex}].machineCode[${machineIndex}].ingredient`} type="text" placeholder="Ingredient" value={code && code.ingredient} style={{ width: 200, marginLeft: 5 }} />
                                                                                            <FastField variant="outlined" onChange={formik.handleChange} id={`instruction.itemListElement[${elementIndex}].machineCode[${machineIndex}].category`} type="text" placeholder="Category" value={code && code.category} label="Category" style={{ width: 150, marginLeft: 5 }} />
                                                                                            <FastField variant="outlined" onChange={formik.handleChange} id={`instruction.itemListElement[${elementIndex}].machineCode[${machineIndex}].temperature`} type="number" placeholder="Temperature" value={code.temperature} label="Temperature(°C)" style={{ width: 150, marginLeft: 5 }} />
                                                                                            <select variant="outlined" value={code.stirring} id={`instruction.itemListElement[${elementIndex}].machineCode[${machineIndex}].stirring`} onChange={formik.handleChange} style={{ height: 55, width: 100, marginLeft: 5 }}>
                                                                                                <option value="" selected disabled hidden>Stirring?</option>
                                                                                                <option value={true}>ON</option>
                                                                                                <option value={false}>OFF</option>
                                                                                            </select>
                                                                                            <FastField variant="outlined" onChange={formik.handleChange} id={`instruction.itemListElement[${elementIndex}].machineCode[${machineIndex}].timer`} type="number" placeholder="Timer(Sec)" value={code.timer} label="Timer(Secs)" style={{ width: 100, marginLeft: 5 }} />
                                                                                            <select variant="outlined" value={formik.values.instruction && code.tool} id={`instruction.itemListElement[${elementIndex}].machineCode[${machineIndex}].tool`} onChange={formik.handleChange} style={{ height: 55, width: 100, marginLeft: 5 }}>
                                                                                                <option value="" >select Tool</option>
                                                                                                {tools.map((tool, index) => (
                                                                                                    <option key={index} value={tool.name}>{tool.name}</option>
                                                                                                ))}

                                                                                            </select>

                                                                                        </Grid>



                                                                                    ))


                                                                                )
                                                                            }}
                                                                        </FieldArray>
                                                                    </Grid>
                                                                </Form>
                                                            )

                                                            )}

                                                        </div>

                                                        <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>

                                                            <Button variant="outlined" onClick={formik.handleSubmit} style={{ height: 55, marginLeft: 5 }}>
                                                                Update
                                                            </Button>
                                                        </div>
                                                    </>
                                                )
                                            }}
                                        </FieldArray>
                                    )}
                                </Formik>
                                
                            </Card>
                        </Grid>
                    </Grid>
                )


            }
            // if this is a new reicpe 
            // we are sending the recipeInstructionInitialValues as intialValues to formik
            else {
                return (
                    // <Grid container spacing={2} direction="column" justify="center">
                    //     <Grid item>

                    <Formik
                        initialValues={{
                            itemListElements: [...recipeInstructionInitialValues]
                        }}
                        onSubmit={(data) => {
                            console.log(data)
                            setRecipeMetaData({...recipeMetaData,recipeInstructionsParsed:{
                                "@type":"HowToSection",
                                ...data,
                                name:"cook with riku"
                            }
                            })
                        }}
                        >
                        {(formik) => (
                            <FieldArray name="itemListElements">
                            {(props) => {
                                const { form, remove, insert } = props;
                                const { values } = form;
                                console.log({formik})

                                return (
                                <Form onSubmit={formik.handleSubmit}>
                                    {values.itemListElements.map((item, index) => (
                                        <Grid container direction="column"  key={index} style={{width:'100%'}} >
                                            <Grid item container direction="row" alignItems="center">
                                                <div style={{flex:1}}>

                                                <Field variant="outlined"
                                                fullWidth
                                                type="text"
                                                value={item.text || ''}
                                                name={`itemListElements[${index}].text`}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                id={`itemListElements[${index}].text`}
                                                placeholder="Step Name"
                                                />
                                                </div>
                                                <div>
                                                    <Add
                                                    type="button"
                                                    onClick={() =>
                                                        insert(index + 1, {
                                                            '@type':'HowToStep',
                                                            machineCode: [
                                                                {
                                                                    ingredient: "",
                                                                    cookingAction: "",
                                                                    category:"",
                                                                    ingredientDetails:{},
                                                                    timer:0
                                                                }
                                                            ],
                                                            text: "added Element"
                                                        })
                                                    }
                                                    />
                                                  
                                                    <Remove type="button" onClick={() => remove(index)} />
                                                   
                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <FieldArray name={`itemListElements[${index}].machineCode`}>
                                                {(innerProps) => {
                                                    const {
                                                    form: innerForm,
                                                    remove: innerRemove,
                                                    insert: innerInsert
                                                    } = innerProps;
                                                    const { values: innerValues } = innerForm;
                                                    

                                                    return (
                                                    <div>
                                                        {innerValues.itemListElements[
                                                        index
                                                        ].machineCode?.map((code, codeIndex) => (
                                                        <div key={codeIndex}>


                                                            <Field placeholder="enter cooking step name" variant="outlined" onChange={formik.handleChange}  onBlur={formik.handleBlur} name={`itemListElements[${index}].machineCode[${codeIndex}].cookingAction`} id={`itemListElements[${index}].machineCode[${codeIndex}].cookingAction`} type="text" value={code.cookingAction || ''} label="CookingAction" style={{ width: '1005' }} />

                                                            <Field variant="outlined" onChange={formik.handleChange}  onBlur={formik.handleBlur} name={`itemListElements[${index}].machineCode[${codeIndex}].ingredient`} id={`itemListElements[${index}].machineCode[${codeIndex}].ingredient`} type="text" placeholder="Ingredient" value={code.ingredient || ''} style={{ width: 200, marginLeft: 5 }} />
                                                            <Field variant="outlined" onChange={formik.handleChange}  onBlur={formik.handleBlur} name={`itemListElements[${index}].machineCode[${codeIndex}].category`} id={`itemListElements[${index}].machineCode[${codeIndex}].category`} type="text" placeholder="Category" value={code.category || ''} label="Category" style={{ width: 150, marginLeft: 5 }} />
                                                            <Field variant="outlined" onChange={formik.handleChange}  onBlur={formik.handleBlur} name={`itemListElements[${index}].machineCode[${codeIndex}].temperature`} id={`itemListElements[${index}].machineCode[${codeIndex}].temperature`} type="number" placeholder="Temperature" value={code.temperature || ''} label="Temperature(°C)" style={{ width: 150, marginLeft: 5 }} />
                                                            <select variant="outlined" value={code.stirring || ''}  onBlur={formik.handleBlur} name={`itemListElements[${index}].machineCode[${codeIndex}].stirring`} id={`itemListElements[${index}].machineCode[${codeIndex}].stirring`} onChange={formik.handleChange} style={{ height: 55, width: 100, marginLeft: 5 }}>
                                                                <option value="" selected disabled hidden>Stirring?</option>
                                                                <option value={true}>ON</option>
                                                                <option value={false}>OFF</option>
                                                            </select>
                                                            <Field variant="outlined" name={`itemListElements[${index}].machineCode[${codeIndex}].timer`}  onBlur={formik.handleBlur} onChange={formik.handleChange} id={`itemListElements[${index}].machineCode[${codeIndex}].timer`} type="number" placeholder="Timer(Secs)" value={code.timer || ''} label="Timer(Secs)" style={{ width: 100, marginLeft: 5 }} />
                                                            <select variant="outlined" onFocus={()=>setToolsEnabled(true)} defaultValue={code.tool || ''} id={`itemListElements[${index}].machineCode[${codeIndex}].tool`} onChange={formik.handleChange} style={{ height: 55, width: 100, marginLeft: 5 }}>
                                                                <option value="" >select Tool</option>
                                                                {toolsEnabled && tools.map((tool, index) => (
                                                                    <option key={index} value={tool.name}>{tool.name}</option>
                                                                ))}

                                                            </select>
                                                            {/* <input variant="outlined"
                                                            type="text"
                                                            id={`itemListElements[${index}].machinCode[${codeIndex}].cookingAction`}
                                                            defaultValue={code.cookingAction}
                                                            name=""
                                                            />
                                                            <TextField variant="outlined" type="text" /> */}
                                                            <button
                                                            type="button"
                                                            onClick={() =>
                                                                innerInsert(codeIndex + 1, {
                                                                cookingAction: "",
                                                                ingredient: ""
                                                                })
                                                            }
                                                            >
                                                            Add
                                                            </button>
                                                            <button
                                                            type="button"
                                                            onClick={() => innerRemove(codeIndex)}
                                                            >
                                                            delete
                                                            </button>
                                                        </div>
                                                        ))}
                                                    </div>
                                                    );
                                                }}
                                                </FieldArray>
                                            </Grid>
                                        </Grid>
                                    ))}
                                    <button type="submit">Submit</button>
                                </Form>
                                );
                            }}
                            </FieldArray>
                        )}
                        </Formik>
                           
                            // <Formik
                            //     initialValues={{
                            //         '@type': 'HowToSection',
                            //         itemListElement: recipeInstructionInitialValues,
                            //         name: ''
                                
                            //     }}
                            //     onSubmit={(values,) => {
                            //         saveInstruction(values)

                            //     }}>
                            //     {formik => (
                            //         <FieldArray name='itemListElement'>
                            //             {(props) => {
                            //                 const { form, insert, remove } = props
                            //                 const { values } = form
                            //                 const { itemListElement } = values
                            //                 // console.log({topValues:values})


                            //                 return (
                            //                         <Form onSubmit={form.handleSubmit} >
                            //                             {itemListElement?.map((value, elementIndex) => (

                                                        

                            //                                 <Grid container direction="column" spacing={2} key={elementIndex}>
                            //                                     <Grid item style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            //                                         <TextField fullWidth variant="outlined" multiline row={3} onChange={formik.handleChange} id={`itemListElement[${elementIndex}].text`} type="text" placeholder="Human Readable Instruction" value={value.text} label="Human Readable Instruction" />
                            //                                         <div>
                            //                                             <Add onClick={() => insert(elementIndex + 1, {

                            //                                                 instruction: '',
                            //                                                 machineCode: [{
                            //                                                     ingredientState: '',
                            //                                                     action: '',
                            //                                                     ingredient: '',
                            //                                                     tool: ''

                            //                                                 }]


                            //                                             })} />


                            //                                             <Remove onClick={() => remove(elementIndex)} />
                            //                                         </div>
                            //                                     </Grid>

                                                            

                            //                                     <FieldArray name={`itemListElement[${elementIndex}].machineCode`}>
                            //                                         {(innerProps) => {
                            //                                             const { push, remove:removeMachine, form, insert: insertMachine } = innerProps
                            //                                             const { values } = form
                            //                                             const { itemListElement:innerListElement } = values
                            //                                             console.log({values})


                            //                                             return (
                            //                                                 <div>

                                                                            
                            //                                                 {innerListElement[elementIndex].machineCode.map((code, machineIndex) => (

                            //                                                     <Grid item key={machineIndex}>
                                                                                    
                            //                                                         <TextField variant="outlined" onChange={formik.handleChange} id={`itemListElement[${elementIndex}].machineCode[${machineIndex}].cookingAction`} type="text" placeholder="CookingAction" value={code.cookingAction} label="CookingAction" style={{ width: 150 }} />

                            //                                                         <TextField variant="outlined" onChange={formik.handleChange} id={`itemListElement[${elementIndex}].machineCode[${machineIndex}].ingredient`} type="text" placeholder="Ingredient" value={code && code.ingredient} style={{ width: 200, marginLeft: 5 }} />
                            //                                                         <TextField variant="outlined" onChange={formik.handleChange} id={`itemListElement[${elementIndex}].machineCode[${machineIndex}].category`} type="text" placeholder="Category" value={code && code.category} label="Category" style={{ width: 150, marginLeft: 5 }} />
                            //                                                         <TextField variant="outlined" onChange={formik.handleChange} id={`itemListElement[${elementIndex}].machineCode[${machineIndex}].temperature`} type="number" placeholder="Temperature" value={code.temperature} label="Temperature(°C)" style={{ width: 150, marginLeft: 5 }} />
                            //                                                         <select variant="outlined" value={code.stirring} id={`itemListElement[${elementIndex}].machineCode[${machineIndex}].stirring`} onChange={formik.handleChange} style={{ height: 55, width: 100, marginLeft: 5 }}>
                            //                                                             <option value="" selected disabled hidden>Stirring?</option>
                            //                                                             <option value={true}>ON</option>
                            //                                                             <option value={false}>OFF</option>
                            //                                                         </select>
                            //                                                         <TextField variant="outlined" onChange={formik.handleChange} id={`itemListElement[${elementIndex}].machineCode[${machineIndex}].timer`} type="number" placeholder="Timer(Secs)" value={code.timer} label="Timer(Secs)" style={{ width: 100, marginLeft: 5 }} />
                            //                                                         <select variant="outlined" defaultValue={formik.values.instruction && code.tool} id={`itemListElement[${elementIndex}].machineCode[${machineIndex}].tool`} onChange={formik.handleChange} style={{ height: 55, width: 100, marginLeft: 5 }}>
                            //                                                             <option value="" >select Tool</option>
                            //                                                             {tools.map((tool, index) => (
                            //                                                                 <option key={index} value={tool.name}>{tool.name}</option>
                            //                                                             ))}

                            //                                                         </select>
                            //                                                         <div>
                            //                                                             <Add onClick={() => insertMachine(elementIndex + 1, {

                            //                                                                 instruction: '',
                            //                                                                 machineCode: [{
                            //                                                                     ingredientState: '',
                            //                                                                     action: '',
                            //                                                                     ingredient: '',
                            //                                                                     tool: ''

                            //                                                                 }]


                            //                                                             })} />


                            //                                                             <Remove onClick={() => removeMachine(elementIndex)} />
                            //                                                         </div>

                            //                                                     </Grid>



                            //                                                 ))}
                            //                                                 </div>


                            //                                             )
                            //                                         }}



                            //                                     </FieldArray>






                            //                                 </Grid>

        



                            //                             )

                            //                             )}
                            //                             <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>

                            //                                 <Button variant="outlined" type='submit' style={{ height: 55, marginLeft: 5 }}>
                            //                                     Update
                            //                                 </Button>
                            //                             </div>

                            //                         </Form>

                                            
                            //                 )
                            //             }}
                            //         </FieldArray>
                            //     )}
                            // </Formik>

                          

                    //     </Grid>
                    // </Grid >
                )
            }

        }

        // this is categorised as author spceified steps
        // this option doesn't have any defined structure of steps
        // author can add it any style or format . but this option is considered as semi-automatic
        else {
            var newObj = []

            //  this variable is created just to confirm that recipeInstruction is in itemInstruction structure ,
            //  then this variable will be assigned to true or false
            var itemListElement;

            if (recipeInstructionInitialValues.length >= 1) {
                if (existingRecipeEdit) {
                    newObj = [...recipeInstructionInitialValues]
                    itemListElement = true
                    // recipeInstructionInitialValues.map((instruction, index) => {
                    //     if (instruction.name == 'Cook With Riku') {
                    //         newObj = [...instruction.itemListElement]
                    //     }
                    // })

                }
                else {

                    recipeInstructionInitialValues.map((instruction, index) => {
                        // we need to check which type of instruction structure it have , as mentioned above
                        // if we have itemListElement in the field then it is type one 
                        // we can pull instructions directly and assign it newArray

                        // {
                        //              @type:'HowToStep',
                        //              text:'',
                        //              name:''
                        //          }

                        if (instruction.itemInstructions) {
                            var instructions = []
                            instruction.itemInstructions.map((item, index) => (
                                instructions.push({
                                    '@type': 'HowToStep',
                                    text: item,
                                    machineCode: [{
                                        ingredient: "",
                                        cookingAction: '',
                                        temperature: '',
                                        category: ''
                                    }]

                                })
                            ))
                            newObj.push({
                                '@type': 'HowToSection',
                                name: instruction.name,
                                itemListElement: instructions
                            })
                            itemListElement = true

                        }
                        else {

                            newObj.push({
                                '@type': 'HowToStep',
                                text: instruction,
                                machineCode: [{
                                    ingredient: "",
                                    cookingAction: '',
                                    temperature: '',
                                    category: ''
                                }]


                            })


                            itemListElement = false

                        }
                    })
                }
            }
            else {
                newObj.push({
                    instruction: '',
                    machineCode: [{
                        ingredient: "",
                        cookingAction: '',
                        temperature: '',
                        category: ''

                    }]
                })
                itemListElement = false
            }


            if (itemListElement) {
                return (
                    <Grid item>

                        <Formik

                            initialValues={{
                                // initialvalues sample
                                // {
                                //     instruction: instruction,
                                //     machineCode: [{
                                //         ingredientState: selectedIngredientState,
                                //         action: '',
                                //         ingredient: ''

                                //     }]
                                // }
                                instruction: newObj,

                            }}
                            onSubmit={(values, { setSubmitting }) => {


                                saveInstruction(values)
                                // setSubmitting(false)
                            }}


                        >
                            {
                                formik => (
                                    <form onSubmit={formik.handleSubmit}>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item>
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    {/* using FieldArray of formik for creating a new instruction
                                                which create a new field with skeleton of
                                                {
                                                 instruction: instruction,
                                                 machineCode: [{
                                                     ingredientState: selectedIngredientState,
                                                     action: '',
                                                     ingredient: '          
                                                 }]
                                                 so initial values become 
                                                 instruction:[
                                                     {
                                                        instruction: instruction,
                                                        machineCode: [{
                                                            ingredientState: selectedIngredientState,
                                                            action: '',
                                                            ingredient: '          
                                                    },
                                                    {
                                                        instruction: instruction,
                                                        machineCode: [{
                                                            ingredientState: selectedIngredientState,
                                                            action: '',
                                                            ingredient: '          
                                                    }
                                                     
                                                ]
                                             }
        
                                                
                                                */}
                                                    <FieldArray name='instruction'>
                                                        {props => {
                                                            const { push, remove, form, insert } = props
                                                            const { values } = form
                                                            const { instruction, machineCode } = values



                                                            return (
                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                    {instruction.map((item, index) => (
                                                                        <div>


                                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                                <Accordion style={{ width: '100%' }}>
                                                                                    <AccordionSummary
                                                                                        expandIcon={<ExpandMore />}
                                                                                        aria-controls="panel1a-content"
                                                                                        id="panel1a-header"
                                                                                    >

                                                                                        <TextField fullWidth variant="outlined" row={3} onChange={formik.handleChange} id={`instruction[${index}].name`} type="text" placeholder="Instructions Title" value={formik.values.instruction[index].name} label="Instructions Title" />

                                                                                    </AccordionSummary>
                                                                                    <AccordionDetails style={{ display: 'flex', flexDirection: 'column', }}>
                                                                                        <FieldArray name={`instruction[${index}].itemListElement`}>
                                                                                            {props => {
                                                                                                const { push, remove, form, insert } = props
                                                                                                const { values } = form
                                                                                                const { instruction } = values



                                                                                                return (
                                                                                                    <>
                                                                                                        {instruction[index].itemListElement.map((itemInstruction, instructionIndex) => (
                                                                                                            <Grid key={instructionIndex} container direction="column" spacing={2} style={{ margin: 5 }}>

                                                                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                                                                    <div style={{ flex: 1, width: '90%' }}>
                                                                                                                        <TextField fullWidth variant="outlined" multiline row={3} onChange={formik.handleChange} id={`instruction[${index}].itemListElement[${instructionIndex}].text`} type="text" placeholder="Human Readable Instruction" value={formik.values.instruction[index].itemListElement[instructionIndex].text} label="Human Readable Instruction" />
                                                                                                                    </div>

                                                                                                                    <Add onClick={() => insert(instructionIndex + 1, {
                                                                                                                        text: '',
                                                                                                                        machineCode: [{
                                                                                                                            ingredientState: '',
                                                                                                                            action: '',
                                                                                                                            ingredient: '',
                                                                                                                            tool: ''

                                                                                                                        }]

                                                                                                                    })} />


                                                                                                                    <Remove onClick={() => remove(instructionIndex)} />


                                                                                                                </div>
                                                                                                                <FieldArray name={`instruction[${index}].itemListElement[${instructionIndex}].machineCode`}>
                                                                                                                    {props => {
                                                                                                                        const { push, remove, form, insert } = props
                                                                                                                        const { values } = form
                                                                                                                        const { instruction } = values



                                                                                                                        return (
                                                                                                                            <Grid item style={{ width: '95%', border: '1px solid red', margin: '10px 0px' }}>
                                                                                                                                {instruction[index].itemListElement[instructionIndex].machineCode && instruction[index].itemListElement[instructionIndex].machineCode.map((code, machineIndex) => (
                                                                                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                                                                                        <div >
                                                                                                                                            <select value={formik.values.instruction[index].itemListElement[instructionIndex].machineCode[machineIndex].cookingAction} onChange={formik.handleChange} id={`instruction[${index}].itemListElement[${instructionIndex}].machineCode[${machineIndex}].cookingAction`} style={{ height: 55, width: 100, marginLeft: 5 }}>
                                                                                                                                                <option vlaue="">select action</option>
                                                                                                                                                {cookingActions.map((action, index) => (
                                                                                                                                                    <option key={index} value={action.name}>{action.name}</option>
                                                                                                                                                ))}

                                                                                                                                            </select>

                                                                                                                                            <select value={formik.values.instruction[index].itemListElement[instructionIndex].machineCode[machineIndex] && formik.values.instruction[index].itemListElement[instructionIndex].machineCode[machineIndex].ingredient ? formik.values.instruction[index].itemListElement[instructionIndex].machineCode[machineIndex].ingredient : ''} onChange={formik.handleChange} id={`instruction[${index}].itemListElement[${instructionIndex}]machineCode[${machineIndex}].ingredient`} style={{ height: 55, width: 100, marginLeft: 5 }}>
                                                                                                                                                <option value="">select ingredient</option>
                                                                                                                                                {selectIngredientOptions(formik.values.instruction[index].itemListElement[instructionIndex].machineCode[machineIndex].category)}

                                                                                                                                            </select>

                                                                                                                                            <select value={formik.values.instruction[index].itemListElement[instructionIndex].machineCode[machineIndex].category} onChange={formik.handleChange} id={`instruction[${index}].itemListElement[${instructionIndex}].machineCode[${machineIndex}].category`} style={{ height: 55, width: 100, marginLeft: 5 }}>
                                                                                                                                                <option value="">select a  state</option>
                                                                                                                                                {ingredientCategories.map((category, index) => (
                                                                                                                                                    <option key={index} value={category.name}>{category.name}</option>
                                                                                                                                                ))}

                                                                                                                                            </select>

                                                                                                                                            <TextField variant="outlined" onChange={formik.handleChange} id={`instruction[${index}].itemListElement[${instructionIndex}].machineCode[${machineIndex}].temperature`} type="number" placeholder="Temperature" value={formik.values.instruction[index].itemListElement[instructionIndex].machineCode[machineIndex] && formik.values.instruction[index].itemListElement[instructionIndex].machineCode[machineIndex].temperature ? formik.values.instruction[index].itemListElement[instructionIndex].machineCode[machineIndex].temperature : ''} label="Temperature(°C)" style={{ width: 150, marginLeft: 5 }} />
                                                                                                                                            <select variant="outlined" defaultValue={formik.values.instruction[index]?.itemListElement[instructionIndex]?.machineCode[machineIndex] && formik.values.instruction[index].itemListElement[instructionIndex].machineCode[machineIndex].stirring ? formik.values.instruction[index].itemListElement[instructionIndex].machineCode[machineIndex].stirring : false} id={`instruction[${index}].itemListElement[${instructionIndex}].machineCode[${machineIndex}].stirring`} onChange={formik.handleChange} style={{ height: 55, width: 100, marginLeft: 5 }}>
                                                                                                                                                <option value="" selected disabled hidden>Stirring?</option>
                                                                                                                                                <option selected={formik.values.stirring ? "selected" : ""} value={true}>ON</option>
                                                                                                                                                <option selected={formik.values.stirring ? "" : "selected"} value={false}>OFF</option>
                                                                                                                                            </select>
                                                                                                                                            <TextField variant="outlined" onChange={formik.handleChange} id={`instruction[${index}].itemListElement[${instructionIndex}].machineCode[${machineIndex}].timer`} type="text" placeholder="Timer(Secs)" value={formik.values.instruction[index].itemListElement[instructionIndex].machineCode[machineIndex] && formik.values.instruction[index].itemListElement[instructionIndex].machineCode[machineIndex].timer ? formik.values.instruction[index].itemListElement[instructionIndex].machineCode[machineIndex].timer : ''} label="Timer(Secs)" style={{ width: 100, marginLeft: 5 }} />
                                                                                                                                            <select variant="outlined" defaultValue={formik.values.instruction[index].itemListElement[instructionIndex].machineCode[machineIndex] && formik.values.instruction[index].itemListElement[instructionIndex].machineCode[machineIndex].tool} id={`instruction[${index}].itemListElement[${instructionIndex}].machineCode[${machineIndex}].tool`} onChange={formik.handleChange} style={{ height: 55, width: 100, marginLeft: 5 }}>
                                                                                                                                                <option value="" >select Tool</option>
                                                                                                                                                {tools.map((tool, index) => (
                                                                                                                                                    <option key={index} value={tool.name}>{tool.name}</option>
                                                                                                                                                ))}

                                                                                                                                            </select>
                                                                                                                                        </div>

                                                                                                                                        <div>
                                                                                                                                            <Add onClick={() => insert(machineIndex + 1, {
                                                                                                                                                category: '',
                                                                                                                                                action: '',
                                                                                                                                                ingredient: '',
                                                                                                                                                tool: ''

                                                                                                                                            })} />


                                                                                                                                            <Remove onClick={() => remove(machineIndex)} />

                                                                                                                                        </div>
                                                                                                                                    </div>


                                                                                                                                ))}
                                                                                                                            </Grid>)



                                                                                                                    }}
                                                                                                                </FieldArray>

                                                                                                            </Grid>


                                                                                                        ))}
                                                                                                    </>

                                                                                                )

                                                                                            }}

                                                                                        </FieldArray>

                                                                                    </AccordionDetails>

                                                                                </Accordion>


                                                                                <div>
                                                                                    <Add onClick={() => insert(index + 1, {
                                                                                        name: '',
                                                                                        itemListElement: [{
                                                                                            instruction: '',
                                                                                            machineCode: [{
                                                                                                ingredientState: '',
                                                                                                action: '',
                                                                                                ingredient: '',
                                                                                                tool: ''

                                                                                            }]
                                                                                        }]

                                                                                    })} />


                                                                                    <Remove onClick={() => remove(index)} />
                                                                                </div>
                                                                            </div>







                                                                        </div>
                                                                    ))}

                                                                </div>


                                                            )
                                                        }}

                                                    </FieldArray>


                                                </div>
                                            </Grid>


                                        </Grid>
                                        <Button variant="outlined" type="submit" style={{ height: 55, marginLeft: 5 }}>
                                            Update
                                        </Button>
                                    </form>
                                )

                            }

                        </Formik>




                    </Grid>

                )
            }
            else {
                return (
                    <Grid item>
                        <Formik
                            initialValues={{
                                // initialvalues sample
                                // {
                                //     instruction: instruction,
                                //     machineCode: [{
                                //         ingredientState: selectedIngredientState,
                                //         action: '',
                                //         ingredient: ''

                                //     }]
                                // }
                                instruction: newObj,

                            }}
                            onSubmit={(values, { setSubmitting }) => {


                                saveInstruction(values)
                                // setSubmitting(false)
                            }}
                        >
                            {formik => (
                                <form onSubmit={formik.handleSubmit}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                {/* using FieldArray of formik for creating a new instruction
                                        which create a new field with skeleton of
                                        {
                                         instruction: instruction,
                                         machineCode: [{
                                             ingredientState: selectedIngredientState,
                                             action: '',
                                             ingredient: '          
                                         }]
                                         so initial values become 
                                         instruction:[
                                             {
                                                instruction: instruction,
                                                machineCode: [{
                                                    ingredientState: selectedIngredientState,
                                                    action: '',
                                                    ingredient: '          
                                            },
                                            {
                                                instruction: instruction,
                                                machineCode: [{
                                                    ingredientState: selectedIngredientState,
                                                    action: '',
                                                    ingredient: '          
                                            }
                                             
                                        ]
                                     }

                                        
                                        */}
                                                <FieldArray name='instruction'>
                                                    {props => {
                                                        const { push, remove, form, insert } = props
                                                        const { values } = form
                                                        const { instruction, machineCode } = values

                                                        return (
                                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                                                                {instruction.map((item, index) => (
                                                                    <Grid container direction="column" spacing={2} style={{ margin: 5 }}>

                                                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                            <div style={{ flex: 1, width: '90%' }}>
                                                                                <TextField fullWidth variant="outlined" multiline row={3} onChange={formik.handleChange} id={`instruction[${index}].text`} type="text" placeholder="Human Readable Instruction" value={instruction[index].text} label="Human Readable Instruction" />
                                                                            </div>

                                                                            <Add onClick={() => insert(index + 1, {
                                                                                text: '',
                                                                                machineCode: [{
                                                                                    ingredientState: '',
                                                                                    action: '',
                                                                                    ingredient: '',
                                                                                    tool: ''

                                                                                }]
                                                                            })} />


                                                                            <Remove onClick={() => remove(index)} />


                                                                        </div>
                                                                        {/* 
                                                                we even want to add multiple machine code for the same instructions
                                                                creating a nested FieldArray in instruction FieldArray
                                                                the basic stucture for this fieldArray is
                                                                machineCode: [{
                                                                            ingredientState: '',
                                                                            action: '',
                                                                            ingredient: ''

                                                                        }]
                                                                if we add another it becomes
                                                                instruction:[{
                                                                    instruction:'',
                                                                    machineCode: [
                                                                        {
                                                                            ingredientState: '',
                                                                            action: '',
                                                                            ingredient: ''
                                                                        },
                                                                        {
                                                                            ingredientState: '',
                                                                            action: '',
                                                                            ingredient: ''
                                                                        }
                                                                    ]

                                                                }]
                                                                 
                                                                */}
                                                                        <FieldArray name={`instruction[${index}].machineCode`}>
                                                                            {props => {
                                                                                const { push, remove, form, insert } = props
                                                                                const { values } = form
                                                                                const { instruction } = values



                                                                                return (
                                                                                    <Grid item style={{ width: '100%', border: '1px solid red', margin: '10px 0px' }}>
                                                                                        {instruction[index].machineCode && instruction[index].machineCode.map((code, machineIndex) => (
                                                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                                                <div >
                                                                                                    <select value={formik.values.instruction[index].machineCode[machineIndex].cookingAction} onChange={formik.handleChange} id={`instruction[${index}].machineCode[${machineIndex}].cookingAction`} style={{ height: 55, width: 100, marginLeft: 5 }}>
                                                                                                        <option vlaue="">select action</option>
                                                                                                        {cookingActions.map((action, index) => (
                                                                                                            <option key={index} value={action.name}>{action.name}</option>
                                                                                                        ))}

                                                                                                    </select>

                                                                                                    <select value={formik.values.instruction[index].machineCode[machineIndex] && formik.values.instruction[index].machineCode[machineIndex].ingredient ? formik.values.instruction[index].machineCode[machineIndex].ingredient : ''} onChange={formik.handleChange} id={`instruction[${index}].machineCode[${machineIndex}].ingredient`} style={{ height: 55, width: 100, marginLeft: 5 }}>
                                                                                                        <option value="">select ingredient</option>
                                                                                                        {selectIngredientOptions(formik.values.instruction[index].machineCode[machineIndex].category)}

                                                                                                    </select>

                                                                                                    <select value={formik.values.instruction[index].machineCode[machineIndex].category} onChange={formik.handleChange} id={`instruction[${index}].machineCode[${machineIndex}].category`} style={{ height: 55, width: 100, marginLeft: 5 }}>
                                                                                                        <option value="">select a  state</option>
                                                                                                        {ingredientCategories.map((category, index) => (
                                                                                                            <option key={index} value={category.name}>{category.name}</option>
                                                                                                        ))}

                                                                                                    </select>

                                                                                                    <TextField variant="outlined" onChange={formik.handleChange} id={`instruction[${index}].machineCode[${machineIndex}].temperature`} type="number" placeholder="Temperature" value={formik.values.instruction[index].machineCode[machineIndex] && formik.values.instruction[index].machineCode[machineIndex].temperature ? formik.values.instruction[index].machineCode[machineIndex].temperature : ''} label="Temperature(°C)" style={{ width: 150, marginLeft: 5 }} />
                                                                                                    <select variant="outlined" defaultValue={formik.values.instruction[index].machineCode[machineIndex] && formik.values.instruction[index].machineCode[machineIndex].stirring ? formik.values.instruction[index].machineCode[machineIndex].stirring : false} id={`instruction[${index}].machineCode[${machineIndex}].stirring`} onChange={formik.handleChange} style={{ height: 55, width: 100, marginLeft: 5 }}>
                                                                                                        <option value="" selected disabled hidden>Stirring?</option>
                                                                                                        <option selected={formik.values.stirring ? "selected" : ""} value={true}>ON</option>
                                                                                                        <option selected={formik.values.stirring ? "" : "selected"} value={false}>OFF</option>
                                                                                                    </select>
                                                                                                    <TextField variant="outlined" onChange={formik.handleChange} id={`instruction[${index}].machineCode[${machineIndex}].timer`} type="text" placeholder="Timer(Secs)" value={formik.values.instruction[index].machineCode[machineIndex] && formik.values.instruction[index].machineCode[machineIndex].timer ? formik.values.instruction[index].machineCode[machineIndex].timer : ''} label="Timer(Secs)" style={{ width: 100, marginLeft: 5 }} />
                                                                                                    <select variant="outlined" defaultValue={formik.values.instruction[index].machineCode[machineIndex] && formik.values.instruction[index].machineCode[machineIndex].tool} id={`instruction[${index}].machineCode[${machineIndex}].tool`} onChange={formik.handleChange} style={{ height: 55, width: 100, marginLeft: 5 }}>
                                                                                                        <option value="" >select Tool</option>
                                                                                                        {tools.map((tool, index) => (
                                                                                                            <option key={index} value={tool.name}>{tool.name}</option>
                                                                                                        ))}

                                                                                                    </select>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <Add onClick={() => insert(machineIndex + 1, {
                                                                                                        category: '',
                                                                                                        action: '',
                                                                                                        ingredient: '',
                                                                                                        tool: ''

                                                                                                    })} />


                                                                                                    <Remove onClick={() => remove(machineIndex)} />

                                                                                                </div>
                                                                                            </div>


                                                                                        ))}
                                                                                    </Grid>)



                                                                            }}
                                                                        </FieldArray>

                                                                    </Grid>

                                                                ))}
                                                            </div>


                                                        )
                                                    }}

                                                </FieldArray>


                                            </div>
                                        </Grid>


                                    </Grid>
                                    <Button variant="outlined" type="submit" style={{ height: 55, marginLeft: 5 }}>
                                        Update
                                    </Button>
                                </form>
                            )
                            }
                        </Formik>




                    </Grid>

                )
            }
        }
    }





    const getParsedData = (id, existing = false) => {
        // we are storing the parsed data from the url into 'drafts' collection of firebase firestore
        // later we are fetching that data from drafts to manage the data

        // if existing is true then it means user is editing already saved recipe 
        // that recipe needs to be searched in 'recipe' collection instead of 'drafts'
        if (existing) {

            FirebaseApi["recipe"].get().then((snapshot) => {
                var newData = [];
                snapshot.docs.forEach(function (childSnapshot) {
                    if (id === childSnapshot.id) {
                        newData.push(childSnapshot.data())
                    }
                });
                var ingredients = []

                newData[0] && newData[0].recipeIngredientParsed.map((ingredient, index) => {
                    ingredients.push(ingredient.ingredient)
                })

                console.log({newData})
                setFetching(false)
                setSelectedTemplate(newData[0].template)
                setRecipeMetaData(newData[0])
                setRecipeIngredient(newData[0].recipeIngredientParsed)
                setIngredientAll(ingredients)
                setExistingDataFetched(true)
                setExistingRecipeEdit(true)

                
            });




        }
        // if existing is false, then user is trying to create new recipe
        // if he enters url and click parse, we get response back with collection name where it stored and id of the file
        // we use that id and bring the data
        else {

            // FirebaseApi["drafts"].get().then((snapshot) => {
            FirebaseApi["drafts"].get().then((snapshot) => {

                var newData = [];

                snapshot.docs.forEach(function (childSnapshot) {
                    if (id === childSnapshot.id) {

                        newData.push(childSnapshot.data().recipe)
                    }


                });

                // we need to refine the data as this is new recipe 

                // trying to parse ingredient states from the recieved data
                // the ingredient data consits in the form "1 tsp ginger (finely chopped)"
                // the words which are in bracket are made as one of the ingredient State 
                // var parsedIngredientState = []
                // newData.length >= 1 && newData[0].recipeIngredient.map((ingredient, index) => {
                //     let parsed = ing.parse(ingredient)
                //     parsedIngredientState.push(parsed.prep)


                // })

                // we even find many ingredient like "1 tsp Kashmiri red chili paste (or kashmiri red chili powder)"
                // In the above ingredient detail the word in bracket cannot be ingredient State because it is synomyn or other name for that ingredient
                // So trying to remove the state values which have or as first word in bracket 
                // let filtered = parsedIngredientState.filter(ingredient => ingredient && ingredient.substring(0, 2) != 'or')



                // lastly updating the correct ingredient states 
                setFetching(false)
                setRecipeMetaData(newData[0])


            })

        }


    }

    const collectIngredients = () => {
        // Parsed Instructions Format
        // const newArray = [{
        //     ingredients: [],
        //     maxQty: [],
        //     minQty: [],
        //     prep: [],
        //     quantity: [],
        //     scaling: [],
        //     unit: []
        // }]

        var objectArray = []
        if (existingRecipeEdit) {
            // if this is existing file edit , we have structured data already 
            // just assigning the data to objectArray
            objectArray = [...recipeMetaData.recipeIngredientParsed]


        }
        else {

            if(ingredients.length > 0){
                objectArray = [...ingredients]

            }
            else{

                //  these recipe ingredients are coming from the recipe directly we are arranging them accordingly 
                recipeMetaData && recipeMetaData.recipeIngredient.map((ingredient, index) => {

                    // we have two libraries for parsing recipe ingredients 1)recipe-ingredient-parser-v2  2)ingredientparser
                    // first we parse with recipe-ingredient-parser-v2 so we get the data in the format

                    //  parse("500 grams Chicken (bone-in , curry cut)")
                    // afterParsing:{
                    //     ingredient:'Chicken (bone-in , curry cut)',
                    //     maxQty:'500',
                    //     minQty:'500',
                    //     quantity:'500',
                    //     unit:'grams'
                    // }

                    // adding scale to already passed data
                    let details = {
                        ...parse(ingredient),
                        scaling: 1
                    }


                    // this would be enough for us but we need to collect the data which is in bracket so for that we are using
                    // 2nd package (becausing we dont have this option in first package), we are only parsing the afterParsing.ingredient value and adding the parsed data back to the 1st parsed data and sending
                    // 
                    // 
                    let parsingAgain = ing.parse(details.ingredient)


                    let finalDetails = {
                        ...details,
                        prep: parsingAgain.prep
                    }
                    objectArray.push(finalDetails)

                    // newArray[0].ingredients.push(finalDetails.ingredient);
                    // newArray[0].maxQty.push(finalDetails.maxQty);
                    // newArray[0].minQty.push(finalDetails.minQty);
                    // newArray[0].prep.push(finalDetails.prep);
                    // newArray[0].quantity.push(finalDetails.quantity);
                    // newArray[0].scaling.push(finalDetails.scaling);
                    // newArray[0].unit.push(finalDetails.unit);



                })
            }
        }

        // later objectArray is assigned as initialValue to formik
        return (
            <>
                <Formik

                    // the initial values structure be like
                    // ingrdientDetails : [{},{},{}]
                    // we are using this structure because we can add instruction dynamically at any position or delete using Formik field Array
                    // when we insert new instruction the initialValues become
                    // ingrdientDetails : [{},{},{},{}]


                    initialValues={{ ingredientDetails: objectArray }}
                    onSubmit={(values) => {
                        saveIngredient(values)
                        // console.log(values)
                    }}
                >
                    {formik => (
                        <>

                            <form onSubmit={formik.handleSubmit} style={{ alignItems: "center", padding: 5 }}>
                                {/* formik FieldArray start from here 
                                we need to give the name of what initialValue we are making dynamically
                            
                            */}
                                <FieldArray name='ingredientDetails'>
                                    {props => {
                                        const { push, remove, form, insert } = props
                                        const { values } = form
                                        const { ingredientDetails } = values




                                        return (
                                            <div >
                                                {ingredientDetails && ingredientDetails.map((ingredient, index) => (
                                                    <div key={index} style={{ alignItems: "center", padding: 5 }}>

                                                        {
                                                            formik.submitCount == 0 ? (
                                                                <ErrorIcon color="error" />
                                                            ) : (
                                                                <CheckCircleIcon color="action" />
                                                            )
                                                        }
                                                        <TextField variant="outlined" onChange={formik.handleChange} id={`ingredientDetails[${index}].quantity`} type="text" placeholder="Amount" value={formik.values.ingredientDetails[index] && formik.values.ingredientDetails[index].quantity ? formik.values.ingredientDetails[index].quantity : ''} label="quantity" style={{ width: 100, marginLeft: 10 }} />
                                                        <TextField variant="outlined" onChange={formik.handleChange} id={`ingredientDetails[${index}].unit`} type="text" placeholder="Unit" value={formik.values.ingredientDetails[index] && formik.values.ingredientDetails[index].unit ? formik.values.ingredientDetails[index].unit : ''} label="Unit" style={{ width: 100, marginLeft: 5 }} />

                                                        <select defaultValue={formik.values.ingredientDetails[index] && formik.values.ingredientDetails[index].prep} id={`ingredientDetails[${index}].prep`} onChange={formik.handleChange} style={{ height: 55, width: 100, marginLeft: 5 }}>
                                                            <option value=""  >select State</option>
                                                            {
                                                                ingredientStates.map((ingredientState, index) => (
                                                                    <option key={index} value={ingredientState.name}>{ingredientState.name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        <TextField variant="outlined" onChange={formik.handleChange} id={`ingredientDetails[${index}].ingredient`} type="text" placeholder="Ingredient" value={formik.values.ingredientDetails[index] && formik.values.ingredientDetails[index].ingredient} label="Ingredient" style={{ width: 300, marginLeft: 5 }} />
                                                        <TextField variant="outlined" onChange={formik.handleChange} id={`ingredientDetails[${index}].scaling`} type="text" placeholder="1x" value={formik.values.ingredientDetails[index] && formik.values.ingredientDetails[index].scaling} label="Scaling" style={{ width: 100, marginLeft: 5 }} />
                                                        <select variant="outlined" defaultValue={formik.values.ingredientDetails[index] && formik.values.ingredientDetails[index].category} id={`ingredientDetails[${index}].category`} onChange={formik.handleChange} style={{ height: 55, width: 150, marginLeft: 5 }}>
                                                            <option value="" >Select Category</option>
                                                            {
                                                                ingredientCategories.map((ingredientCategory, index) => (
                                                                    <option key={index} value={ingredientCategory.name}>{ingredientCategory.name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        {/* these are the functions to insert new field or delete current field */}
                                                        {/* insert(position,structure) insert taked 2 parameters one is position where to insert new field
                                                     and what is the structure of the field */}
                                                        <Add onClick={() => insert(index + 1, {
                                                            ingredient: '',
                                                            maxQty: '',
                                                            minQty: '',
                                                            prep: '',
                                                            quantity: '',
                                                            scaling: 1,
                                                            unit: ''
                                                        })} />

                                                        {/* remove(index) function taked one parameter which filed to be removed */}
                                                        <Remove onClick={() => remove(index)} />

                                                    </div>
                                                ))}



                                            </div>



                                        )
                                    }}
                                </FieldArray>
                                <Button variant='contained' color='secondary' type='submit'>Save</Button>
                            </form>
                        </>

                    )}


                </Formik>
            </>


        )


    }








    // using switch case to go through each step with Material Ui stepper
    const renderCurrentStep = () => {
        
        switch (currentStep) {
            case 0:

                return (
                    // input field for entering a url, sending that url to firebase backend to get our parsed details
                    <Grid container direction="column" spacing={1} style={{width:'50%',margin:'auto'}} justifyContent="center">
                        <Grid item>
                            {props.match.params.id != 0 ? (
                                existingDataFetched ? (
                                    <div>
                                        <Typography>Data Fetched Successfully . Proceed to Next Step</Typography>
                                    </div>
                                ) : (
                                    <div>
                                        <Typography>Getting detials from firestore ,please wait...</Typography>
                                    </div>

                                )

                            ) : (
                                <Formik initialValues={{ url: '' }}

                                    onSubmit={(values) => {

                                        // connecting to firebase function for getting the parsed Data of the site 
                                        
                                        setFetching(true)
                                        axios.get(`https://us-central1-riku-staging.cloudfunctions.net/helloWorld?url=${values.url}`)

                                            .then(result => {
                                                // As soon as getting data we are updating the data to state
                                            
                                                setParsedData(result.data)
                                                setParsingError(false)
                                                // and immediatley refining the data we got back for working accordingly
                                                getParsedData(result.data[1], false)
                                            })
                                            .catch(err =>{
                                                setParsingError(true)
                                                setFetching(false)
                                            })


                                    }}
                                    >
                                    {(formik) => {
                                        console.log({formik})
                                    
                                        return(
                                        <Form onSubmit={formik.handleSubmit} style={{height:200,display:'flex',flexDirection:'column',justifyContent:'space-between'}}>


                                            {/* <FastField value={formik.values.url} onChange={formik.handleChange} style={{ width: '95%', padding: 15, borderRadius: 10 }} id="url" type="text" placeholder="paste the url you want to parse from" label="Recipe Source Url" /> */}

                                            <Typography>Enter a URL or click next to create from scratch</Typography>

                                            <FastField variant="outlined" value={formik.values.url} onChange={formik.handleChange} id="url" type="text" placeholder="paste the url you want to parse from" label="Recipe Source Url" style={{padding:15,borderRadius:5,borderColor:'gray'}} fullWidth />

                                            <Button type='submit' fullWidth variant="contained" disabled={formik.values.url.length > 0 ? false : true}>Parse</Button>
                                        </Form>
                                    )}} 
                                    </Formik>
                            )}

                        </Grid>
                        {fetching && (
                            <Grid item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <CircularProgress />
                            </Grid>

                        )}
                        {/* giving user a message that the data from the url is safed in drafts collection of firebase and bring the id of the file back and displaying 
                                we use this id to parse the actual data from drafts collection
                                */}
                        {parsedData && (
                            <Grid item>

                                <Typography >Data parsed successfully and stored to firestore in "{parsedData[0]}" collection with id {parsedData[1]}</Typography>
                            </Grid>
                        )}

                        {parsingError && (
                            <Grid item>
                                <Typography>Error while parsing, try again. if error occurs one or more times for this url, that means url cannot be parsed. Try with different Url</Typography>
                            </Grid>
                        )}



                    </Grid >
                )
            case 1:

                return (
                    // For new Recipe from scratch we need to start adding details for the recipe here
                    // displaying basic detials of the recipe
                    <Grid container spacing={2} direction="column" style={{ width: '80%',margin:'auto' }}>
                        <Formik initialValues={recipeMetaData}
                            onSubmit={(values, actions) => {
                                setRecipeMetaData(values)
                                setCanProceedToNextStep(true)
                                
                            }}>
                            {formik => {

                                return (
                                    // here we are displaying the basic detials of the recipe

                                    <Form onSubmit={formik.handleSubmit}>


                                        <Grid container direction="column" spacing={2}>
                                            <Grid item>
                                                {/* <FastField variant="outlined" onChange={formik.handleChange} type="text" placeholder="name" id="name" label="Recipe Name" value={formik.values?.name ? formik.values?.name : ''} /> */}
                                                <TextField fullWidth variant="outlined" onChange={formik.handleChange} type="text" placeholder="name" id="name" label="Recipe Name" value={formik.values?.name ? formik.values?.name : ''} />
                                            </Grid>
                                            <Grid item>
                                                <TextField fullWidth variant="outlined" onChange={formik.handleChange} type="text" placeholder="Source" id="source" label="Source" value={formik.values['@id'] ? formik.values['@id'] : ''} />
                                            </Grid>
                                            <Grid item>
                                                <TextField fullWidth variant="outlined" onChange={formik.handleChange} type="text" placeholder="Thumbnail Url" id="thumbnail" label="Thumbnail" value={formik.values?.image ? formik.values?.image[0] : ''} />
                                            </Grid>
                                            <Grid item>
                                                <TextField fullWidth variant="outlined" multiline onChange={formik.handleChange} type="text" placeholder="description" label="Description" id="description" value={formik.values?.description ? formik.values?.description : ''} rows={5} />
                                            </Grid>
                                            <Grid item>
                                                <TextField fullWidth label="Author" variant="outlined" onChange={formik.handleChange} type="text" placeholder="author" id="author.name" value={formik.values?.author ? formik.values?.author.name : ''} />
                                            </Grid>
                                            <Grid item style={{ display: "flex", justifyContent: "space-between" }}>
                                                <TextField label="PrepTime" variant="outlined" onChange={formik.handleChange} type="text" placeholder="prepTime" id="prepTime" value={formik.values?.prepTime ? formik.values?.prepTime : ''} />
                                                <TextField label="CookTime" variant="outlined" onChange={formik.handleChange} type="text" placeholder="cookTime" id="cookTime" value={formik.values?.cookTime ? formik.values?.cookTime : ''} />
                                                <TextField variant="outlined" onChange={formik.handleChange} type="text" placeholder="servings" label="Servings" id="nutrition.servingSize" value={formik.values?.nutrition ? formik.values?.nutrition.servingSize : ''} />
                                                <TextField variant="outlined" onChange={formik.handleChange} type="text" placeholder="calories" label="Calories" id="nutrition.calories" value={formik.values?.nutrition ? formik.values?.nutrition.calories : ''} />
                                            </Grid>

                                            <Grid item style={{ display: "flex", justifyContent: "space-between" }}>

                                                <select variant="outlined" value={formik.values.recipeCategory ? formik.values.recipeCategory : ''} onChange={formik.handleChange} id="recipeCategory" style={{ height: 55, width: "49%" }}>
                                                    {
                                                        courses.map((course, index) => (
                                                            <option key={index} value={course.name}>{course.name}</option>
                                                        ))
                                                    }
                                                </select>
                                                <select variant="outlined" value={formik.values.recipeCuisine ? formik.values.recipeCuisine : ''} id="recipeCuisine" onChange={formik.handleChange} style={{ height: 55, width: "49%" }}>
                                                    {
                                                        cuisines.map((cuisine, index) => (
                                                            <option key={index} value={cuisine.name}>{cuisine.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </Grid>
                                            <Grid item>
                                                <TextField label="Keywords" fullWidth variant="outlined" onChange={formik.handleChange} id="keywords" type="text" placeholder="keywords" value={formik.values?.keywords} />
                                            </Grid>

                                            <FieldArray name="image">
                                                {props => {
                                                    const { form, remove, insert } = props
                                                    const { values } = form
                                                    const { image } = values
                                                    return (

                                                        <Grid item>
                                                            {
                                                                image.map((item, index) => (
                                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                        <TextField fullWidth variant='outlined' type='text' label='Image Url' placeholder='image url' onChange={formik.handleChange} id={`image[${index}]`} value={formik.values?.image[index]} />
                                                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                            <Add onClick={() => insert(index + 1)} />
                                                                            <Remove onClick={() => remove(index)} />

                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </Grid>


                                                    )
                                                }}

                                            </FieldArray>

                                            <Grid item style={{ display: "flex", justifyContent: "center" }}>
                                                <Button variant="contained" color="secondary" type="submit" disabled={currentStep == 3 ? true : false}>
                                                    <Typography variant="overline">Save</Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )
                            }
                            }
                        </Formik>
                    </Grid>
                )
            case 2:


                return (
                    // getting all ingredients and  making them into separate categories , to make instructions for machine  in next step
                    <Grid container spacing={2} direction="column" alignItems="center">

                        <Grid item style={{ height: 600, overflow: "scroll" }}>
                            <Grid container spacing={2} direction="column" justify="center" alignItems="center">
                                {/*collecting all ingredients  */}
                                {collectIngredients()}


                            </Grid>
                        </Grid>
                    </Grid>
                )
            case 3:
                return (
                    // here we are providing option for selecting the template to generating instrcutions
                    <Grid container direction="row" justify="" spacing={2}>
                        {templates.map((template, index) => (
                            <Grid item>
                                <Card style={{ padding: 10, margin: 10 }}>
                                    <Grid container direction="column" justify="center">
                                        <Grid item>
                                            <Button variant="outlined" onClick={() => templateSelect(index)}>Select</Button>
                                            {
                                                selectedTemplate == index && (<CheckCircleIcon />)
                                            }
                                            <Typography variant="h5">
                                                Name: {template.name}
                                            </Typography>
                                            <Typography variant="overline">
                                                Style : {template.style}
                                            </Typography>

                                        </Grid>
                                        <Grid item>
                                            <Grid container direction="column" spacing={2}>
                                                {template.steps.map((step, index) => (
                                                    <Grid item>{index} : {step.join("-")}</Grid>
                                                ))
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        ))
                        }
                    </Grid>
                )
            case 4:

                return (
                    // In this case we are generating new instructions according to the recipeInstructions[key] in the parsedData
                    // firstly if user wants to prepare fully automatic recipe they need to
                    // if have 2 common structure for the recipeInstructions[key] 
                    // 1)itemListElement is an array consits of sub keys divided into section like preparation, cooking etc
                    // 2)text this is pretty straight forward,  have instructions directly
                    <Grid container direction="column" justify="center"  >
                        {
                            renderNewInstruction(existingRecipeEdit ? recipeMetaData.recipeInstructionsParsed : recipeInstructions, selectedTemplate)
                        }
                    </Grid>
                )
        }
    }


    const saveRecipe = () => {

        if (existingRecipeEdit) {
            console.log(JSON.parse(JSON.stringify({ ...recipeMetaData, datePublished: new Date(), template: selectedTemplate })))
            FirebaseApi["recipe"].doc(props.match.params.id).update({ recipe: JSON.parse(JSON.stringify({ ...recipeMetaData, datePublished: new Date(), template: selectedTemplate })) })
                .then((data) => {
                    console.log("Data updated successfully");
                })
                .catch(function (error) {
                    console.log(error)
                })


        }
        else {
            FirebaseApi["recipe"].add(JSON.parse(JSON.stringify({ ...recipeMetaData, datePublished: new Date(), template: selectedTemplate })) )
                .then((data) => {
                    console.log("Data row created with ID: ", data.id);
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    }

   
    console.count()
    return (

        <Grid container direction="column" >
            

            <Grid item >
                <Stepper activeStep={currentStep} alternativeLabel style={{ display: "flex", justifyContent: "center" }}>
                    {STEPS.map((label, index) => (
                        <Step key={label} style={{ width: '30%' }} onClick={() => setCurrentStep(index)}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Grid>
            {/* className={[props.classes.flexRowCenter,props.classes.flexWeight]} */}
            <Grid item  >
                {renderCurrentStep()}
            </Grid>
            
            <Grid item style={{ display: 'flex', justifyContent: "center", alignItems: "center", marginTop: 50 }}>
                <Fab size="small" variant="round" color="secondary" onClick={gotoPreviousStep} disabled={currentStep == 0 ? true : false} style={{ margin: 5 }}>
                    <NavigateBeforeIcon />
                </Fab>
                <Button disabled={currentStep < 4} variant="contained" color="primary" onClick={saveRecipe} style={{ marginLeft: 20, marginRight: 20 }}>
                    Submit
                </Button>

                <Fab size="small" variant="round" color="secondary" disabled={parsingError || fetching ? true : currentStep == 4 ? true : false} onClick={gotoNextStep} style={{ margin: 5 }}>
                    <NavigateNextIcon />
                </Fab>
            </Grid>

            <button onClick={()=>props.history.goBack()}>goback</button>


        </Grid>

    );
    
}

// const GlobalRecipe = withStyles(useStyles)(NewRecipe)
export default NewRecipe;