import React, { useState, useEffect, useContext } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Typography, Box } from '@mui/material'
import Grid from '@mui/material/Grid'
import { styled, useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom'
import Entities from '..'

import { FirebaseApi } from '../../../firebaseConfig'
import AppContext from '../../../config/AppContext'



export default function ToolsEntityTable(props) {
    const AppState = useContext(AppContext);
    const { addedEntity, setAddedEntity, close } = props;
    const [rowData, setRowData] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedRowsData, setSelectedRowsData] = useState([])
    const [currentIngredient, setCurrentIngredient] = useState('');
    const [currentEntityData, setCurrentEntityData] = useState();
    const [rowsLength, setRowsLength] = useState([]);
    const currentEntity = 'units'
    const currentEntityCategory = 'pure'

    const selectARow = (row) => {
        setCurrentIngredient(row?.row)
        console.log(row);
        // setCurrentEntityData(row?.row)

        AppState.handleCurrentEntityData(row?.row)
        AppState.handleCurrentEntityType(currentEntity)
    }

    const handleSelectedRows = (rows) => {
        setRowsLength(rows)
    }

    console.log("Added entity", addedEntity)


    const getData = async (entityName) => {
        setLoading(true)
        try {
            const data = await FirebaseApi['tools'].get()
            const parsedData = data.docs.map((item) => {
                return {
                    id: item.id,
                    ...item.data()
                }
            })
            console.log("parsedData", parsedData)
            setRowData(parsedData)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)

        }
    }

    useEffect(() => {

        getData(props.entity)

        return () => {
            setRowData([])
        }
    }, [props.entity])

    const column = [
        {
            field: "imageLink", headerName: "Image", width: 250,
            renderCell: (props) => {
                // console.log(props, props.row.image?.[0])
                return (
                    <div style={{ display: 'flex', flexDirection: 'column', width: 100, height: 100 }} >
                        {props.row ? (

                            <img src={props?.row?.imageLink} alt="entityImage" style={{ width: '100%', height: '100%' }} />
                        ) : (
                            <img src={props?.row?.imageLink} alt="entityImage" style={{ width: '100%', height: '100%' }} />
                        )}
                    </div>
                )
            }
        },
        { field: "name", headerName: "Name", width: 200 },
        { field: "description", headerName: " Description", width: 300 }
    ]

    return (
        <>
            <Entities Entity={'tools'} currentEntityData={currentEntityData} currentEntityCategory={currentEntityCategory} rowsCount={rowsLength} />
            {loading ? <div>Loading..</div> :
                <Box style={{ height: '90vh', width: '100%' }}>
                    <DataGrid
                        hideFooter
                        columns={column}
                        rows={rowData}
                        checkboxSelection
                        onRowClick={(details) => selectARow(details)}
                        onSelectionModelChange={(details) => handleSelectedRows(details)}
                    />
                </Box>}

        </>


    )


}