import React, { useEffect, useContext, useState } from 'react'
import { Typography, Grid, OutlinedInput, InputAdornment } from '@mui/material';
import AppContext from '../../../config/AppContext';
import { Link } from 'react-router-dom';
import CreatorNavbar from '../Common/CreatorNavbar';
import Writing from '../../../assets/CreatorScreen/writing.png'
import HotPot from '../../../assets/CreatorScreen/hot-pot.png'
import CloudServices from '../../../cloud-services-core';
import { DashBoardLink, EmptyCard } from '../Styles';
import SearchIcon from '@mui/icons-material/Search';
import RecipeCard from '../EditorComponents/RecipeCard';
import EmptyImage from '../../../assets/CreatorScreen/emptyImage.png'

const ContentCreation = () => {
    const AppState = useContext(AppContext);
    const [articleDrafts, setArticleDrafts] = useState([]);
    const [recipeDrafts, setRecipeDrafts] = useState([]);
    const [search, setSearch] = useState([]);
    const recipes = [{name: 'recipe'}];
    // const [searchResults, setSearchResults] = useState([]);
    const [totalItems, setTotalItems] = useState([]);

    var totalCollections = [];
    const getArticles = async () => {

        let article = await CloudServices.articles.fetchByCreatorId(AppState?.user?.id);
        let recipe = await CloudServices.recipes.fetchByCreatorId(AppState?.user?.id);
        setArticleDrafts(article);
        setRecipeDrafts(recipe);

        totalCollections.push(...article, ...recipe);
        setTotalItems(totalCollections);
    }

    useEffect(() => {
        AppState.handleNavbar(false);
        getArticles();
        return () => {
            AppState.handleNavbar(true);
        }
    }, [])

    console.log(AppState);
    // console.log(searchResults);
    // user context
    // routing

    return (
        <Grid container spacing={2} marginTop={3}>
            <Grid item container xs={2}>
                <CreatorNavbar currentScreen="Content-Creation" />
            </Grid>
            <Grid item container direction='column' spacing={2} xs={10}>
                <Grid item container spacing={2}>
                    <Grid item container justifyContent='space-between'>
                        <Grid item>
                            <Typography variant="h6" style={{ fontWeight: 600 }} >
                                Create new
                            </Typography>
                        </Grid>
                        <Grid item marginRight={2}>
                            <OutlinedInput 
                                id='search creator'
                                type="text"
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                                size='small'
                                placeholder='Search here'
                                endAdornment={
                                    <InputAdornment position="end"><SearchIcon /></InputAdornment>
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid item container direction='row'>
                            <DashBoardLink>
                                <Link to='/createRecipe/0' style={{ textDecoration: 'none' }} >
                                    <Grid margin={3}>
                                        <img src={HotPot} style={{ height: 40, width: 40 }} alt="" />
                                        <Typography item marginTop={2} style={{ textTransform: 'uppercase'}}>
                                            Recipe
                                        </Typography>
                                        <Typography item style={{ fontSize: 10}}>
                                            Smart recipe creation tool
                                        </Typography>
                                    </Grid>
                                </Link>
                            </DashBoardLink>
                            <DashBoardLink>
                                <Link to='/createArticle/0' style={{ textDecoration: 'none' }} >
                                    <Grid margin={3}>
                                        <img src={Writing} style={{ height: 40, width: 40 }} alt="" />
                                        <Typography item marginTop={2} style={{ textTransform: 'uppercase'}}>
                                            Article
                                        </Typography>
                                        <Typography item style={{ fontSize: 10}}>
                                            Food blog creation tool
                                        </Typography>
                                    </Grid>
                                </Link>
                            </DashBoardLink>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2} direction='column'>
                    <Grid item>
                        <Typography marginTop={2} marginLeft={2}>
                            Drafts
                        </Typography>
                    </Grid>
                    <Grid item container direction='row'>
                        {/* Recipe card  */}
                        {recipes.length === 0 ? (
                            <EmptyCard>
                                <img src={EmptyImage} alt=''/>
                            </EmptyCard>
                        ): (
                            <Grid item>
                                <RecipeCard />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    )
}

export default ContentCreation;