import Recipes from '../pages/Recipes/recipes'
import NewRecipe2 from '../pages/Recipes/cupdate2'
import TestRecipe from '../pages/Recipes/testUpdate'
import RecipeCategories from '../pages/RecipeCategories/recipeCategories';
import RecipeStates from '../pages/RecipeStates/recipeStates';

import Ingredients from '../pages/Ingredients/ingredients';
import IngredientStates from '../pages/IngredientStates/ingredientStates';
import IngredientCategories from '../pages/IngredientCategories/ingredientCategories';

import Diets from '../pages/Diets/diets';

import Cuisines from '../pages/Cuisines/cuisines';
import Courses from '../pages/Courses/courses';

import CookingMethods from '../pages/CookingMethods/cookingMethods';
import MeasurementUnits from '../pages/MeasurementUnits/measurementUnits';
import Tools from '../pages/Tools/tools'

import Actions from '../pages/Actions/Actions'
import Charts from '../pages/Charts/Charts'
import Spices from '../pages/Spices/Spices'
import Filters from '../pages/Filters/Filters'
import Browse from '../pages/Browse/BrowseRecipes'
import Recipe from '../pages/Browse/Recipe'
import SearchResults from '../pages/Browse/SearchResults'
import DraftedRecipes from '../pages/Drafts/DraftedRecipes'

import AddRecipe from '../pages/Recipes/AddRecipeComponent';
import Profile from '../pages/Auth/Profile';
import Trash from '../pages/Trash/Trash';


import RecipeTable from '../pages/RecipeTable'

// entities
import DietEntityTable from '../pages/Entities/Diets/DietTable';
import IngredientTable from '../pages/Entities/Ingredients/IngredientTable';
import ToolsEntityTable from "../pages/Entities/Tools/ToolsTable"
import MedicalTable from '../pages/Entities/Medical/MedicalTable';
import AllergiesEntityTable from '../pages/Entities/Allergies/AllergiesTable';
import CuisinesEntityTable from '../pages/Entities/Cuisines/CuisinesTable';
import IngredientCategoryEntityTable from '../pages/Entities/IngredientCategory/IngredientCategoryTable';
import IngredientTypeTable from '../pages/Entities/IngredientType/IngredientTypeTable';
import UnitsTable from '../pages/Entities/Units/UnitsTable';
import CookingActionTable from '../pages/Entities/CookingActions/CookingActionTable';
import PrepStyleTable from '../pages/Entities/PrepStyles/PrepStyleTable';
import UnitConversionsTable from '../pages/Entities/UnitsConversion/UnitConversionsTable';
import PresetsEntityTable from '../pages/Entities/Presets/PresetsTable';
import DietModal from '../pages/Entities/Diets/DietModal';
import IngredientModal from '../pages/Entities/Ingredients/IngredientModal';
import MedicalModal from '../pages/Entities/Medical/MedicalModal';
import AllergyModal from '../pages/Entities/Allergies/AllergyModal';
import CuisineModal from '../pages/Entities/Cuisines/CuisineModal';
import PresetModal from '../pages/Entities/Presets/PresetModal';
import IngredientCategoryModal from '../pages/Entities/IngredientCategory/IngredientCategoryModal';
import IngredientTypeModal from '../pages/Entities/IngredientType/IngredientTypeModal';
import UnitsModal from '../pages/Entities/Units/UnitsModal';
import CookingActionModal from '../pages/Entities/CookingActions/CookingActionModal';
import ToolsModal from '../pages/Entities/Tools/ToolsModal';
import PrepStyleModal from '../pages/Entities/PrepStyles/PrepStyleModal';
import UnitConversionsModal from '../pages/Entities/UnitsConversion/UnitConversionsModal';
import EntitiesTable from '../pages/Entities/Entities';
import CreateRecipe from '../pages/Recipes/CreateRecipe/index';

// Creators
import CreatorsPage from '../pages/CreatorsPage/Dashboard';
import ContentCreation from '../pages/CreatorsPage/ContentCreation';
import MemberShipLevels from '../pages/CreatorsPage/Membership/MembershipLevel';
import UpdateMembership from '../pages/CreatorsPage/Membership/UpdateMembershipLevel';
import TwoColumnCard from '../pages/CreatorsPage/CardLayouts/TwoColumnCard';
import AlternateCardLayout from '../pages/CreatorsPage/CardLayouts/AlternateCard';
import PaymentsSuccess from '../pages/CreatorsPage/Payments/PaymentsSuccess';

import SemiTest from '../pages/SemiTest';
import Editor from '../pages/CreatorsPage/Editor/Editor';
import ChefInfo from '../pages/CreatorsPage/Common/ChefInfo';
import Test from '../pages/CreatorsPage/Common/Test';
import MySubscriptions from '../pages/CreatorsPage/MySubscriptions';
import ManageLevelsScreen from '../pages/CreatorsPage/Membership/ManageMembershipLevels';
import EditLevelsScreen from '../pages/CreatorsPage/Membership/EditMemberLevels';
import Settings from '../pages/CreatorsPage/Settings/Settings';
import EarningsScreen from '../pages/CreatorsPage/Earnings/Earnings';
import SubscribersScreen from '../pages/CreatorsPage/Subscribers/SubscribersScreen';

import RazorPayImplementation from '../pages/CreatorsPage/Payments';
import TestCollection from '../pages/CreatorsPage/TestCollections';
import CreateArticle from '../pages/CreatorsPage/Article/index';
import UserDetails from '../pages/CreatorsPage/User/CreatorDetails';
import CreateCollection from '../pages/CreatorsPage/Collections/index';
import Feed from '../pages/CreatorsPage/Feed';
import TurnCreator from '../pages/CreatorsPage/CreatorMode';
import Billings from '../pages/CreatorsPage/Billings';
import ProfileCreation from '../pages/ProfileSteps';
import DownloadCSV from '../pages/Entities/DownloadCSV';
import SimpleCard from '../pages/CreatorsPage/EditorComponents/SimpleCard';
import RandomCards from '../pages/CreatorsPage/EditorComponents/RandomCard';

export const routes = [
    {
        path:'/randomCards',
        component: RandomCards,
    },
    {
        path: '/updateMembership',
        exact: true,
        component: UpdateMembership
    },
    {
        path: '/memberShipLevels',
        component: MemberShipLevels,
    },
    {
        path: '/downloadCSV',
        exact: true,
        component: DownloadCSV,
    },
    {
        path: '/profileCreation',
        exact: true,
        component: ProfileCreation
    },
    {
        path: '/creator',
        exact: true,
        component: CreatorsPage
    },
    {
        path: '/creator/content-creation',
        exact: true,
        component: ContentCreation
    },
    {
        path: '/testRazor',
        exact: true,
        component: RazorPayImplementation
    },
    {
        path: '/testUser',
        exact: true,
        component: UserDetails
    },
    {
        path: '/testChef',
        exact: true,
        component: TestCollection

    }, {
        path: '/testCreatorOn',
        exact: true,
        component: TurnCreator

    }, {
        path: '/createArticle/:id',
        exact: true,
        component: CreateArticle

    }, {
        path: '/createCollection/:id',
        exact: true,
        component: CreateCollection,
    },
    {
        path: '/subscriptions',
        exact: true,
        component: MySubscriptions
    },
    {
        path: '/earnings',
        exact: true,
        component: EarningsScreen
    },
    {
        path: '/myFeed',
        exact: true,
        component: Feed
    },
    {
        path: "/creator/editor",
        exact: true,
        component: Editor
    },
    {
        path: "/creator/settings",
        exact: true,
        component: Settings
    }, {
        path: '/creator/billings',
        exact: true,
        component: Billings

    },
    {
        path: "/chefPage/:id",
        exact: true,
        component: ChefInfo
    },
    {
        path: "/trial2",
        exact: true,
        component: Test
    },
    {
        path: '/success',
        exact: true,
        component: PaymentsSuccess
    },
    {
        path: '/subscribers',
        exact: true,
        component: SubscribersScreen
    },
    {
        path: '/manageMember',
        exact: true,
        component: ManageLevelsScreen // has to change
    },
    {
        path: '/actions',
        exact: true,
        component: Actions
    },
    {
        path: '/charts',
        exact: true,
        component: Charts
    },
    {
        path: '/drafts',
        exact: true,
        component: DraftedRecipes
    },
    {
        path: "/diets",
        exact: true,
        component: Diets
    },
    {
        // not working
        path: "/cuisines",
        exact: true,
        component: Cuisines
    },
    {
        // not working
        path: "/courses",
        exact: true,
        component: Courses
    },
    {
        path: "/recipeStates",
        exact: true,
        component: RecipeStates
    },
    {
        path: "/recipeCategories",
        exact: true,
        component: RecipeCategories
    },
    {
        path: "/ingredients",
        exact: true,
        component: Ingredients
    },
    {
        path: "/ingredientStates",
        exact: true,
        component: IngredientStates
    },
    {
        path: "/ingredientCategories",
        exact: true,
        component: IngredientCategories
    },
    {
        path: '/cookingMethods',
        exact: true,
        component: CookingMethods
    },
    {

        path: "/tools",
        exact: true,
        component: Tools
    },
    {
        // not working
        path: "/measurementUnits",
        exact: true,
        component: MeasurementUnits
    },
    {
        path: "/recipes",
        exact: true,
        component: Recipes
    },
    {
        path: "/recipes/new/:id",
        exact: true,
        component: NewRecipe2
    },
    {
        path: "/recipes/test/:id",
        exact: true,
        component: TestRecipe
    },
    {
        path: '/spices',
        exact: true,
        component: Spices
    },
    {
        // not working
        path: '/filters',
        exact: true,
        component: Filters
    },
    // {
    //     path: '/entities/dependent/:id',
    //     exact: true,
    //     component: DietEntityTable
    // // },
    // {
    //     path: '/entities/dependent/diets',
    //     exact: true,
    //     component: DietEntityTable
    // },
    // {
    //     path: '/entities/pure/:id',
    //     exact: true,
    //     component: DietEntityTable
    // },
    {
        path: '/addrecipe/:id',
        exact: true,
        component: AddRecipe
    },
    {
        path: '/createRecipe/:id',
        exact: true,
        component: CreateRecipe
    },
    {
        path: '/entities',
        exact: true,
        component: DietEntityTable
    },
    {
        path: '/recipe/:id',
        exact: true,
        component: Recipe
    },
    {
        path: '/results',
        exact: true,
        component: SearchResults
    },
    {
        // not working completely
        path: '/profile',
        exact: true,
        component: Profile
    },
    {
        path: '/trash',
        exact: true,
        component: Trash
    },
    {
        path: '/recipeTable',
        exact: true,
        component: RecipeTable
    },
    {
        path: "/",
        exact: true,
        component: Browse
    },
    // dependent Entities
    // {
    //     path: '/entities/dependent/diets',
    //     exact: true,
    //     component: DietEntityTable
    // },

    {
        path: '/entities/:currentEntityCategory/:currentEntity',
        exact: true,
        component: EntitiesTable
    },

    {
        path: '/entities/dependent/diets/add',
        exact: true,
        component: DietModal
    },

    // dependent
    {
        path: '/entities/dependent/diets/edit/:id',
        component: DietModal
    },

    {
        path: '/entities/dependent/allergies/edit/:id',
        component: AllergyModal
    },
    {
        path: '/entities/dependent/cuisines/edit/:id',
        component: CuisineModal
    },
    {
        path: '/entities/dependent/presets/edit/:id',
        component: PresetModal
    },

    // pure
    {
        path: '/entities/pure/tools/edit/:id',
        component: ToolsModal
    },
    {
        path: '/entities/pure/ingredientCategory/edit/:id',
        component: IngredientCategoryModal
    },
    {
        path: '/entities/pure/ingredientType/edit/:id',
        component: IngredientTypeModal
    },
    {
        path: '/entities/pure/units/edit/:id',
        component: UnitsModal
    },


    {
        path: '/entities/dependent/ingredient',
        exact: true,
        component: IngredientTable
    },
    {
        path: '/entities/dependent/ingredient/edit/:id',
        exact: true,
        component: IngredientModal
    },
    {
        path: '/entities/dependent/ingredient/add',
        exact: true,
        component: IngredientModal
    },
    {
        path: '/entities/dependent/medical',
        exact: true,
        component: MedicalTable
    },
    {
        path: '/entities/dependent/medical/add',
        exact: true,
        component: MedicalModal
    },
    {
        path: '/entities/dependent/medical/edit/:id',
        component: MedicalModal
    },
    {
        path: '/entities/dependent/allergies',
        exact: true,
        component: AllergiesEntityTable
    },
    {
        path: '/entities/dependent/allergies/add',
        exact: true,
        component: AllergyModal
    },
    {
        path: '/entities/dependent/cuisines',
        exact: true,
        component: CuisinesEntityTable
    },
    {
        path: '/entities/dependent/cuisines/add',
        exact: true,
        component: CuisineModal
    },
    {
        path: '/entities/dependent/presets',
        exact: true,
        component: PresetsEntityTable
    },
    {
        path: '/entities/dependent/presets/add',
        exact: true,
        component: PresetModal
    },

    // Pure entities
    {
        path: '/entities/pure/ingredientCategory',
        exact: true,
        component: IngredientCategoryEntityTable
    },
    {
        path: '/entities/pure/ingredientCategory/add',
        exact: true,
        component: IngredientCategoryModal
    },
    {
        path: '/entities/pure/ingredientType',
        exact: true,
        component: IngredientTypeTable
    },
    {
        path: '/entities/pure/ingredientType/add',
        exact: true,
        component: IngredientTypeModal
    },
    {
        path: '/entities/pure/units',
        exact: true,
        component: UnitsTable
    },
    {
        path: '/entities/pure/units/add',
        exact: true,
        component: UnitsModal
    },
    {
        path: '/entities/pure/cookingMethod',
        exact: true,
        component: CookingActionTable
    },
    {
        path: '/entities/pure/cookingMethod/add',
        exact: true,
        component: CookingActionModal
    },
    {
        path: '/entities/pure/cookingMethod/edit/:id',
        component: CookingActionModal
    },
    {
        path: '/entities/pure/tools',
        exact: true,
        component: ToolsEntityTable
    },
    {
        path: '/entities/pure/tools/add',
        exact: true,
        component: ToolsModal
    },
    {
        path: '/entities/pure/prepStyle',
        exact: true,
        component: PrepStyleTable
    },
    {
        path: '/entities/pure/prepStyle/add',
        exact: true,
        component: PrepStyleModal
    },
    {
        path: '/entities/pure/prepStyle/edit/:id',
        component: PrepStyleModal
    },
    {
        path: '/entities/pure/conversions',
        exact: true,
        component: UnitConversionsTable
    },
    {
        path: '/entities/pure/conversions/add',
        exact: true,
        component: UnitConversionsModal
    },
    {
        path: '/entities/pure/conversions/edit/:id',
        component: UnitConversionsModal
    },
    {
        path: '/semitest',
        component: SemiTest
    },
]
