import React, { useEffect, useState, useContext } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'

import Modal from '@mui/material/Modal'
import { makeStyles } from '@mui/styles'

import { ButtonGroup, TextField, TextareaAutosize, ToggleButtonGroup, ToggleButton, Select, MenuItem, ListItem, ListItemText, Slide, Checkbox, Tooltip } from '@mui/material';

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import CancelIcon from '@mui/icons-material/Cancel';

import Collapse from '@mui/material/Collapse';
// web.cjs is required for IE11 support
import { useSpring, animated } from 'react-spring';
import PropTypes from 'prop-types';





import { useFormik, FieldArray, FormikProvider } from 'formik';

import { Link, useHistory, useLocation } from 'react-router-dom'

// Internal Imports


import Upload from '../../../assets/upload.svg'
import LinkIcon from '../../../assets/LinkIcon.svg';
import ImageReplace from '../../../assets/ImageReplace.jpg';
import CreateRecipeContext from '../../../config/CreateRecipeContext'





// cloud services

import AppContext from '../../../config/AppContext'


// styles
const useStyles = makeStyles((theme) => ({

    // textfield
    textField: {

        height: 42,
        border: '2px solid transparent',
        "&.Mui-focused": {
            border: "2px solid #397EF5",
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
            }
        }
    },
    typography: {
        "& .MuiTypography-root": {

            fontFamily: 'Product Sans',
        }
    },

    textArea: {
        padding: 16, borderRadius: 3, border: 'none', display: 'flex', justifyContent: 'center', alignContent: 'center',
        '&:hover': {
            border: 'none',
            outline: 'none'
        },
        '&:focus': {
            outline: 'none'
        },
        resize: 'none',
    },

    hideScrollBar: {
        '&::-webkit-scrollbar': {
            display: 'none',
        }
    },

    // buttons
    buttonTextField: {
        alignContent: 'center',

        width: "50px",
        outline: 'none',
        color: '#000',



    },

    inputRoot: {
        '&$disabled': {
            color: '#000'
        },
    },


    /// meta data
    tags: {
        padding: "3px 10px",
        borderRadius: 24,
        display: 'flex',
        flexDirection: 'row',
        alignItems: "center",
        transition: ' .5s ease-in-out',
        backgroundColor: '#e6e6e6',
        width: 100,
        margin: 2,
    },
    tagTextField: {
        border: 'none',
        outline: 'none',
        backgroundColor: 'transparent',
        padding: 5,
    },
    tagClose: {
        color: '#898989',
        cursor: 'pointer',
        height: 20,
        marginBottom: 4,
        marginLeft: -11



    },




    // imageModal
    modalHeadingContainer: {
        // backgroundColor:theme.palette.secondary.main,

        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 6
    },
    imageHeading: {
        padding: "0 25px"
    },
    emptyImagesContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 124
    },
    imagesContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflowX: 'scroll',
        flexWrap: 'nowrap',
        padding: "10px 20px"
    },
    image: {
        width: 124,
        height: 124,
        borderRadius: 8,
        flexShrink: 0,
        marginRight: 10,
        cursor: 'pointer'
    },


}))







const ArticleMetaDataCreation = (props) => {
    const AppState = useContext(AppContext)
    // Global

    const classes = useStyles()

    const formik = props.formik;

    const { values, handleChange, setFieldValue, setValues } = formik


    const [metaDataUploadImages, setMetaDataUploadImages] = useState(false);

    const [imagesLoading, setImagesLoading] = useState(false)
    const [coverUploaded, setCoverUploaded] = useState(false);

    const [keywordOnHover, setKeywordOnHover] = useState(null)
    const [newKeyword, setNewKeyword] = useState("");



    useEffect(() => {



    }, [])

    function getBase64(file) {

        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
            }
        })
    }


    const collectImagesWithIndex = async (e, sectionIndex = 0, stepIndex = 0) => {
        console.log(sectionIndex, stepIndex)
        setImagesLoading(true)

        var imageObject = {};
        if (formik.values.images && [...Object.keys(formik.values.images)].length >= 0) {
            await Promise.all([...Array(e.target.files.length).keys()].map(async (item, index) => {

                const data = await getBase64(e.target.files[item])
                imageObject[`img-${sectionIndex}-${stepIndex}-${[...Object.keys(formik.values.images)].length + index}`] = data
            }));

        }
        else {


            await Promise.all([...Array(e.target.files.length).keys()].map(async (item, index) => {

                const data = await getBase64(e.target.files[item])
                imageObject[`img-${sectionIndex}-${stepIndex}-${index}`] = data




            }));
        }
        console.log(imageObject)


        setFieldValue('image', [imageObject['img-0-0-0']]);
        setCoverUploaded(true);

    }


    const showCloseOnEnter = (index) => {

        setKeywordOnHover(index)

    }

    const addNewKeyword = (event, insert) => {
        setNewKeyword(event.target.value);

    }

    return (
        <Grid display="flex" flexDirection="row" style={{ marginTop: 44, background: "#FFFFFF", borderRadius: "1%", maxWidth: 1435 }} classes={{ root: classes.typography }} >


            {/* Image, Featured */}
            <Grid item style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 25, }}>

                <Tooltip title={coverUploaded ? "Change Pic" : "Add Pic"} placement="top">
                    <Grid item onClick={() => setMetaDataUploadImages(true)}>
                        <TransformWrapper>
                            <TransformComponent>

                                {values.image && values.image[0] ?
                                    <img src={values.image[0]} style={{ height: '330px', width: '330px', borderRadius: 4 }} />
                                    : <img src={ImageReplace} style={{ height: '330px', width: '330px', borderRadius: 4 }} />
                                }
                            </TransformComponent>
                        </TransformWrapper>



                    </Grid>
                </Tooltip>

                {/* Featured */}


                <Modal
                    open={metaDataUploadImages}
                    disableAutoFocus
                    onClose={() => setMetaDataUploadImages(false)}
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}

                >
                    <Box style={{ width: '60%', height: 'clamp(415px,60%,47vh)', backgroundColor: 'white', borderRadius: 6 }}>

                        <Grid container >
                            <Grid item xs={12}>
                                <Box className={classes.modalHeadingContainer}>

                                    <Grid container spacing={1} direction="row" justifyContent="space-between" alignItems="center">
                                        <Grid item >
                                            <Typography color="white">Uploading your images</Typography>
                                        </Grid>
                                        <Grid item onClick={() => setMetaDataUploadImages(false)} >
                                            <Typography color="white" style={{ cursor: 'pointer' }}>X</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} >

                                <Box style={{ paddingTop: 20 }}>
                                    <Grid container>

                                        <Grid item xs={12}>
                                            <Box className={classes.imageHeading}>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography>Recipe Image Bank</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography>Drag and drop images to main image section. Rest will be saved for recipe steps.</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Box>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box className={classes.imagesContainer} >

                                                {formik.values?.images ? [...Object.values(formik.values.images)].map((file, index) => {

                                                    return (


                                                        <img key={index} src={file} className={classes.image} />

                                                    )
                                                }) : (
                                                    <Box className={classes.emptyImagesContainer} >


                                                        <Typography>No Images</Typography>


                                                    </Box>
                                                )}

                                            </Box>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box pl={3}>

                                                <Typography>Main images</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={8} >
                                            <Box   >

                                                <Box  >

                                                    <label htmlFor="upload">
                                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>
                                                            <img src={Upload} />
                                                            <p style={{ margin: 5 }}>upload</p>
                                                        </div>
                                                    </label>
                                                    <input id="upload" type="file" accept="image/png, image/jpeg" multiple onChange={e => collectImagesWithIndex(e)} style={{ display: 'none' }} />
                                                </Box>
                                            </Box>

                                        </Grid>
                                        <Grid item xs={4}>
                                            {/* <Box  style={{height:'16vh',maxHeight:'20vh',padding:"0 25px"}}> */}
                                            <Grid container direction="row" justifyContent="flex-end" alignItems="end" style={{ height: '100%', paddingRight: 25 }}>

                                                <Grid item>
                                                    <Button onClick={() => setMetaDataUploadImages(false)}>Confirm</Button>
                                                </Grid>
                                            </Grid>
                                            {/* </Box> */}

                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>


                </Modal>




            </Grid>

            {/* Name, Description, Hashtags */}
            <Grid item marginTop="34px" width="450px" >
                {/* Name */}
                <Grid item container spacing={1} direction="column" margin='4px' >
                    <Grid item>
                        <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', }}>Article Name</Typography>

                    </Grid>
                    <Grid item>
                        <TextField autoComplete='off' placeholder="What is it about? Christmas? Keto?" type="text" style={{ width: '430px' }} value={values.name} id="name" onChange={(e) => { setFieldValue("name", e.target.value) }} InputProps={{ className: classes.textField }} />

                    </Grid>



                </Grid>

                {/* Link */}
                <Grid item container spacing={1} direction="column" margin='4px' >
                    <Grid item>
                        <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', }}>Description</Typography>

                    </Grid>
                    <Grid item>

                        <Grid item>
                            <TextField autoComplete='off' placeholder="What was your though process behind creating this?" type="text" style={{ width: '760px' }} value={values.description} id="description" onChange={(e) => { setFieldValue("description", e.target.value) }} InputProps={{ className: classes.textField }} />

                        </Grid>
                    </Grid>



                </Grid>


                {/* Hashtags */}
                <Grid item>
                    <Grid item container spacing={1} direction="column" margin='4px' >

                        <Grid item>
                            <FormikProvider value={formik}>


                                <FieldArray name="keywords">
                                    {(keywordHelpers) => {
                                        const { push, remove, insert } = keywordHelpers


                                        return (



                                            <Grid item container spacing={1} style={{ display: 'flex', flexDirection: "column", marginTop: "-17px", }}  >
                                                <Grid item>
                                                    <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', }}>Hashtags</Typography>

                                                </Grid>


                                                <Grid item>
                                                    <Grid style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: 760, border: "2px solid rgb(235 235 235)", borderRadius: 5 }}>

                                                        <Grid item >

                                                            <Grid style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', paddingLeft: 14, paddingTop: 7, }}>
                                                                {
                                                                    values.keywords && typeof values.keywords === 'object' && values.keywords?.map((item, keywordIndex) => (
                                                                        <Grid item key={keywordIndex}  >

                                                                            <Grid className={classes.tags} key={keywordIndex} onMouseEnter={() => showCloseOnEnter(keywordIndex)} onMouseLeave={() => setKeywordOnHover(null)} >
                                                                                <input className={classes.tagTextField} type="text" id={`keywords[${keywordIndex}]`} value={item} onChange={handleChange} style={{ width: 65 }} />
                                                                                {/* {keywordOnHover === keywordIndex && <p className={classes.tagClose} onClick={() => remove(keywordIndex)}>X</p>} */}
                                                                                {1 === 1 && <Grid className={classes.tagClose} onClick={() => remove(keywordIndex)}>
                                                                                    <CancelIcon />
                                                                                </Grid>
                                                                                }
                                                                            </Grid>
                                                                        </Grid>



                                                                    ))

                                                                }
                                                            </Grid>

                                                        </Grid>
                                                        <Grid>
                                                            <TextareaAutosize item placeholder="Add Tags" type="text" value={newKeyword} onChange={(e) => addNewKeyword(e)} onKeyPress={(e) => {
                                                                if (e.key == "Enter") {
                                                                    e.preventDefault();

                                                                    insert(values.keywords.length + 1, newKeyword)
                                                                    setNewKeyword("")



                                                                }
                                                            }} className={classes.textArea} />
                                                        </Grid>

                                                    </Grid>
                                                </Grid>

                                            </Grid>


                                        )
                                    }}
                                </FieldArray>
                            </FormikProvider>
                        </Grid>
                    </Grid>

                </Grid>





            </Grid>



        </Grid >
    )
}

export default ArticleMetaDataCreation