import React, { useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { Grid, Typography } from '@mui/material';
import EmptyImage from '../../../assets/CreatorScreen/emptyImage.png'

const BillingsTable = (props) => {
    const [loadingBills, setLoadingBills] = useState(false);
    const [rowData, setRowData] = useState([]);

    const columns =
        [
            { field: 'id', headerName: 'Bill Number', width: 130 },
            { field: 'username', headerName: 'Billed For', width: 250 },
            { field: 'issueDate', headerName: 'Issue Date', width: 150 },
            {
                field: 'dueDate',
                headerName: 'Due Date',
                width: 150,
            },
            {
                field: 'amount',
                headerName: 'Amount',
                width: 150,
            },
            { field: 'status', headerName: 'Status', width: 130 },
            { field: 'statement', headerName: 'Download Statement', width: 180 },
        ];
    // to get all bills
    // const getBillsData = async () => {
    //     try {
    //         const data = await FirebaseApi["creatorBillings"].get()
    //         console.log(data);
    //         const parsedData = data.docs.map((item) => {
    //             return {
    //                 id: item.id,
    //                 ...item.data()
    //             }
    //             // return item
    //         })

    //         setRowData(parsedData);
    //         setLoadingBills(false);

    //         console.log('calling getDetails', parsedData)
    //         getPayerDetails(parsedData);


    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    // let final = [];

    // // to get payers details
    // const getPayerDetails = (data) => {
    //     console.log(data);
    //     data.map(async (x) => {
    //         console.log("get details called");
    //         let val = await CloudServices.user.getUserDetails(x.payerId);
    //         let newData = Object.assign({ ...x, ... { userName: val['username'] }, ...{ email: val['email'] } });
    //         //rowData.push({ ...x, ... { username: val['username'] }, ...{ email: val['email'] } });
    //         console.log(newData);
    //         //rowData.unshift(newData);
    //         final = [...final, newData];
    //         console.log(final);
    //         setRowData(final);
    //     })
    // }

    // useEffect(() => {
    //     getBillsData();
    // }, [])

    const noData = () => {
        return (
            <Grid style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', height: 300, width: 'inherit', alignItems: 'center' }}>
                <img src={EmptyImage} alt="" style={{ width: 200, height: 200 }} />
                <Typography>There's no earnings started yet</Typography>
            </Grid>
        )
    }

    return (
        <>
            <Grid style={{ height: 800, width: '97%' }}>
                <DataGrid
                    hideFooter
                    rows={rowData}
                    columns={columns}
                    components={{
                        NoRowsOverlay: noData
                    }}
                    checkboxSelection
                />
            </Grid>
        </>

    )
}

export default BillingsTable;