import React, { useEffect, useState, useContext } from 'react'
import { Grid, Button } from '@mui/material';


import Riku from '../../../assets/CreatorScreen/Riku.PNG'
import CloudServices from '../../../cloud-services-core';

const RazorPayImplementation = () => {
    console.log(CloudServices);
    const options = {
        key: "rzp_test_HJG5Rtuy8Xh2NB",
        amount: "382100",
        currency: 'INR', //  = INR 1
        name: "Riku Creator",
        description: "Intergration Payment Method",
        image: Riku,
        handler: function (response) {
            alert(response.razorpay_payment_id);
        },
        prefill: {
            name: "Riku",
            contact: "9999999999",
            email: "Riku@Cloud.com"
        },
        notes: {
            address: "some address"
        },
        theme: {
            color: "#F94C0C",
            hide_topbar: false
        }
    };

    const data = {

        duration: " 66",

        imageLink: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABZw",
        name: "PreHeat",
        temperature: " 66"
    }



    const getCollectionsList = async () => {
        try {
            const value = await CloudServices.creatorMemberships.fetch();
            console.log(value);

        } catch {
            console.log('error aaya');

        }
    }
    console.log(CloudServices);
    getCollectionsList();

    const openPayModal = options => {
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
    };
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);

    return (
        <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 800 }}>
            <Button variant='contained' onClick={() => openPayModal(options)}>
                Make Payment
            </Button>

        </Grid>
    )
}

export default RazorPayImplementation;