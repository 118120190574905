import React, { useEffect, useState, useContext } from 'react'
import { Grid, Button } from '@mui/material';
import AppContext from '../../../config/AppContext';
import { FirebaseApi } from '../../../firebaseConfig';
import CloudServices from '../../../cloud-services-core';

import { useFormik } from 'formik';
import DetailsLayout from './DetailsLayout';
import StylesLayout from './StylesLayout';
import CreatorSiteEditor from './CreatorSiteEditor';

const Editor = () => {
  const AppState = useContext(AppContext);
  const [currentMode, setCurrentMode] = useState('Details');
  const [userLayout, setUserLayout] = useState(AppState.user);
  const [layoutPresent, setLayoutPresent] = useState(false);

  const fetchUserLayout = async () => {
    try {
      const data = await CloudServices.creatorUILayouts.fetchByCreatorId(AppState?.user?.id);
      const allData = await CloudServices.creatorUILayouts.fetch();
      setLayoutPresent(true);
    } catch(e){
      console.log(e);
    }
  }

  useEffect(() => {
    AppState.handleNavbar(false);
    fetchUserLayout();
    return () => AppState.handleNavbar(true)
  }, [])

  const handleSave = async (values) => {
    // check if data preesent ? update : save

    if (layoutPresent) {
      const data = FirebaseApi.creatorUILayouts.doc(userLayout.id).update(JSON.parse(JSON.stringify({ creatorId: AppState.user.id, values: values, editedOn: new Date() })))
        .then(() => {
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      const data = await CloudServices.creatorUILayouts.create(AppState?.user?.id, values);
    }
  }

  // for formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      creatorName: '',
      profileImage: [],
      backgroundColor: '#DACEBE',
      accentColor: '#1F2122',
      paraFont: '',
      headerFont: '',
      description: '',
      layout: '',
      button: '',
    },
    onSubmit: values => {
      handleSave(values);
    }
  })

  return (
    <Grid container style={{ width: '100%', height: '100vh' }}>
      <Grid item container direction='column' xs={2.5} style={{ height: '100vh' }}>
        <Grid item style={{ margin: 10 }}>
          <Button onClick={() => setCurrentMode('Details')}>Details</Button>
          <Button onClick={() => setCurrentMode('Styles')}>Styles</Button>
        </Grid>
        <Grid item>
          {currentMode === 'Details' && <DetailsLayout formik={formik} /> }
          {currentMode === 'Styles' && <StylesLayout formik={formik} /> }
        </Grid>
        <Grid item justifyContent='flex-end' style={{ width: '100%' }}>
          {currentMode === 'Details' && <Button onClick={()=> setCurrentMode('Styles')}>Next</Button>}
          {
            currentMode === 'Styles' 
            && (
            <>
              <Button onClick={()=> setCurrentMode('Details')}>Back</Button>
              <Button onClick={console.log("submit")}>Submit</Button>
            </>
            )
          }
        </Grid>
      </Grid>
      <Grid item xs={9.5} style={{ height: 'inherit', overflowY: 'auto' }}>
        <CreatorSiteEditor />
      </Grid>
    </Grid>
  )
}

export default Editor