import { TextField } from "@material-ui/core";
import React from "react";
import { useFieldArray } from "react-hook-form";

const NestedArray =  ({ nestIndex, control, register,tools }) => {
  const { fields, remove, append,insert } = useFieldArray({
    control,
    name: `itemListElement[${nestIndex}].machineCode`
  });

  

  return (
    <div>
      {fields.map((item, k) => {
        
        return (
          <div key={item.id} style={{ margin: 20 }}>
            
            {/* <input
              name={`itemListElement[${nestIndex}].machineCode[${k}].text`}
              ref={register()}
              defaultValue={item.field1}
              style={{ marginRight: "25px" }}
            /> */}
            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>

            
            
            <div style={{flex:1}}>

            
            <TextField variant="outlined"  {...register(`itemListElement[${nestIndex}].machineCode[${k}].cookingAction`)}  type="text" placeholder="CookingAction" defaultValue={item.cookingAction}  label="CookingAction" style={{ width: 150 }} />

            <TextField variant="outlined" {...register(`itemListElement[${nestIndex}].machineCode[${k}].ingredient`)} type="text" placeholder="Ingredient" defaultValue={item.ingredient}  style={{ width: 200, marginLeft: 5 }} />
            <TextField variant="outlined" {...register(`itemListElement[${nestIndex}].machineCode[${k}].category`)} type="text" placeholder="Category" defaultValue={item.category}  label="Category" style={{ width: 150, marginLeft: 5 }} />
            <TextField variant="outlined" {...register(`itemListElement[${nestIndex}].machineCode[${k}].temperature`)} type="number" placeholder="Temperature" defaultValue={item.temperature}  label="Temperature(°C)" style={{ width: 150, marginLeft: 5 }} />
            <select variant="outlined" defaultValue={item.stirring ?? false}  {...register(`itemListElement[${nestIndex}].machineCode[${k}].stirring`)}  style={{ height: 55, width: 100, marginLeft: 5 }}>
                <option  selected disabled hidden>Stirring?</option>
                <option value={true}>ON</option>
                <option value={false}>OFF</option>
            </select>
            <TextField variant="outlined" {...register(`itemListElement[${nestIndex}].machineCode[${k}].timer`)} defaultValue={item.timer} type="number" placeholder="Timer(Secs)" label="Timer(Secs)" style={{ width: 100, marginLeft: 5 }} />
            <select variant="outlined" defaultValue={item.tool} {...register(`itemListElement[${nestIndex}].machineCode[${k}].tool`)}  style={{ height: 55, width: 100, marginLeft: 5 }}>
                <option value="" >select Tool</option>
                {tools.map((tool, index) => (
                    <option key={index} value={tool.name}>{tool.name}</option>
                ))}

            </select>
            </div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-around'}}>
              
            <button type="button" style={{backgroundColor:'red',fontSize:40,color:'white',border:'none',borderRadius:10,margin:5}} onClick={() => remove(k)}>
              -
            </button>
            <button
            style={{backgroundColor:'red',fontSize:40,color:'white',border:'none',borderRadius:10,margin:5}}
                type="button"
                onClick={() =>
                    insert(k+1,{
                        cookingAction: "",
                        temperature:"",
                        stirring:false,
                        category:'',
                        timer:30,
                        tool:''
                    })
                }
            >
                +
            </button>

            </div>
            </div>
          </div>
        );
      })}

      

      <hr />
    </div>
  );
};


export default NestedArray