import React, { useContext, useState, useEffect } from 'react'
import AddRecipeContext from "../../../../config/AddRecipeContext"
import { FieldArray, FormikProvider } from 'formik'
import { Grid, Box, Typography, TextareaAutosize, Container, Button, TextField } from '@mui/material'
import generateId from '../../../../components/GenerateId'
import { styled } from '@mui/material/styles'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { makeStyles } from '@mui/styles'


import RenderStepDetails from './RenderStepDetails'
import RenderEmptyStepDetails from './RenderEmptyStepDetails'
//icons

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import DeleteIcon from '@mui/icons-material/Delete';



//context

import StepDetailContext from '../../../../config/StepDetailContext'



const grid = 8;
const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 8,
    marginBottom: 10,
    '&:before': {
        display: 'none',
    },
}));
const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: 0,

}));



const useStyles = makeStyles({
    addSection: {
        width: '100%',
        border: 'none',
        backgroundColor: 'transparent',
        fontSize: 14,
        fontWeight: 'bold',
        color: '#5200F1',
        '&:hover': {
            cursor: 'pointer'
        },


    },
    addSectionDiv: {
        border: "1px solid #5200F1",
        padding: 15,
        backgroundColor: 'white',
        borderRadius: 12
    },

    accordionDetail: {
        borderTop: '1px solid rgba(0, 0, 0, .125)',
        // borderBottom: '1px solid rgba(0, 0, 0, .125)',
        // backgroundColor:'blue',

        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative',
        '&:nth-last-child(1)': {
            // borderBottom: '1px solid rgba(0, 0, 0, .125)',
        },
        // '&::before':{
        //     content:"<div style={{height:30,width:10,backgroundColor:'blue'}}></div>",
        //     backgroundColor:'blue'
        // }

    },

    stepDetailsDiv: {
        position: 'sticky',
        top: 10,
        border: "1px solid #DDDDDD",
        minHeight: '52vh',
        height: 'auto',
        borderRadius: 8,
        backgroundColor: 'white',

    },

    stepDetailsOptions: {

        padding: 10,
        borderBottom: "1px solid #DDDDDD",

    },
    stepDetailsHeadingDiv: {
        borderBottom: "1px solid #DDDDDD"
    },
    stepDetailsHeading: {
        flex: 1,
        padding: 10
    },
    // stepDetailsHeadingText:{
    //     text
    // }

    stepTemperature: {
        flex: 1,
        transform: "scale(1)",
        border: "none",
        zIndex: 3,
        // borderRadius:8,
        // border: "1px solid gray",
        // '&[data-temperature=true]':{
        //     transform:"scale(1.4)",
        //     // padding:20,
        //     backgroundColor: 'white',
        //     border: "1px solid #F94C0C",
        //     borderRadius:8
        // },


    },

    stepTemperatureElement: {
        transition: 'transform .2s ease-in-out',
        zIndex: 3,
        "&[data-selected=true]": {
            color: '#F94C0C'
        }


    },
    emptyStepTemperatureElement: {
        color: '#D9D9D9'
    },
    stepDuration: {
        flex: 1,
        zIndex: 3,
    },
    stepDurationUnit: {

        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        '&[data-selected=true]': {
            color: '#F94C0C'
        }
        // '&[data-durationUnit=true]':{
        //     border:'2px solid #F94C0C',
        //     borderRadius:7
        // }
    },
    emptyStepDurationUnit: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#d9d9d9'
    },

    emptyStepContent: {
        minHeight: '40vh',

    },
    imageAddBtn: {
        padding: "15px 40px",
        border: '1px solid #F94C0C',
        borderRadius: 12,

    },
    imgAddBtnText: {
        color: '#F94C0C'
    },
    stepImages: {
        overflowY: 'scroll',
        height: '40vh',

    },


    // section name edit
    sectionNameInput: {
        outline: 'none',
        border: 'none',
        background: 'transparent',
        width: '70%'
    }
})

export default function (props) {
    const { formik, images, setUploadImages, proMode, stepDetailonFocus, setStepDetailOnFocus } = useContext(AddRecipeContext)
    // const [] = useState({
    //     sectionIndex:null,
    //     stepIndex:null
    // })

    const [stepDetailName, setStepDetailName] = useState('parameters')
    const [emptyStepName, setEmptyStepName] = useState('parameters')

    const classes = useStyles()
    const { values, setFieldValue, setValues } = formik

    const array_move = (arr, old_index, new_index) => {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    };

    const onStepDragEnd = (result) => {
        const sectionNumber = result.source.droppableId.split('p')[1]
        console.log(sectionNumber)

        if (!result.destination) {
            return;
        }
        if (result.destination.droppableId === result.source.droppableId) {
            const itemsReordered = array_move(
                values.recipeInstructions[sectionNumber].itemListElement,
                result.source.index,
                result.destination.index
            );
        }
    }
    const onProStepDragEnd = (result) => {
        const sectionNumber = result.source.droppableId.split('ep')[1]
        console.log(sectionNumber, result)
        if (!result.destination) {
            return;
        }
        if (result.destination.droppableId === result.source.droppableId) {
            const itemsReordered = array_move(
                values.recipeInstructionsPro[sectionNumber].itemListElement,
                result.source.index,
                result.destination.index
            );
        }
    }

    const getListStyle = (isDraggingOver) => ({
        // background: isDraggingOver ? "lightblue" : "lightgrey",
        // padding: grid,
        width: '100%',
    });

    const getItemStyle = (isDragging, draggableStyle) => ({
        display: 'flex',
        flexDirection: 'column',
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        // padding: grid * 2,
        margin: `0 0 ${grid}px 0`,
        // change background colour if dragging
        background: "white",
        // color:isDragging? 'white' : 'black',
        // styles we need to apply on draggables
        ...draggableStyle,
        // border:'1px solid black',
    });

    const regexIngredients = (e, sectionIngredients) => {
        let matchedIngredients = [];
        // sectionIngredients.map(ingredient => {
        values.recipeIngredient.map((recipeIngredient) => {
            //    console.log(recipeIngredient.id == ingredient,recipeIngredient.id,ingredient)
            const ingredientSplit = recipeIngredient.ingredient.trim().replace(/[^a-zA-Z ]/g, "").split(" ")
            let pattern = new RegExp(`\\b(${ingredientSplit[0]}\s${ingredientSplit[1]}\s${ingredientSplit[2]}|${ingredientSplit[0]}|${ingredientSplit[1]}|${ingredientSplit[2]})\\b`, 'i')
            if (pattern.test(e.target.value)) {
                matchedIngredients.push(recipeIngredient.id)
            }
        })
        //  })
        return matchedIngredients
    }

    const trackProStep = (e) => {
        const sectionIngredients = values.recipeInstructionsPro[stepDetailonFocus?.sectionIndex].ingredients
        setFieldValue(`recipeInstructionsPro[${stepDetailonFocus?.sectionIndex}].itemListElement[${stepDetailonFocus.stepIndex}].text`, e.target.value)
        const matchedIngredients = regexIngredients(e, sectionIngredients)
        console.log(matchedIngredients)
        if (matchedIngredients.length > 0) {
            values.recipeInstructionsPro[stepDetailonFocus?.sectionIndex].itemListElement[stepDetailonFocus.stepIndex].ingredients = matchedIngredients
            // setFieldValue(`recipeInstructions[${stepDetailonFocus?.sectionIndex}].derivedIngredients`,usingDerived)
            setFieldValue(`recipeInstructionsPro[${stepDetailonFocus?.sectionIndex}].itemListElement[${stepDetailonFocus.stepIndex}].ingredients`, values.recipeInstructionsPro[stepDetailonFocus?.sectionIndex].itemListElement[stepDetailonFocus.stepIndex].ingredients)
        }
    }

    const trackStep = (e) => {
        const sectionIngredients = values.recipeInstructions[stepDetailonFocus?.sectionIndex].ingredients
        setFieldValue(`recipeInstructions[${stepDetailonFocus?.sectionIndex}].itemListElement[${stepDetailonFocus.stepIndex}].text`, e.target.value)
        let matchedIngredients = regexIngredients(e, sectionIngredients)
        if (matchedIngredients.length > 0) {
            values.recipeInstructions[stepDetailonFocus?.sectionIndex].itemListElement[stepDetailonFocus.stepIndex].ingredients = matchedIngredients
            // setFieldValue(`recipeInstructions[${stepDetailonFocus?.sectionIndex}].derivedIngredients`,usingDerived)
            setFieldValue(`recipeInstructions[${stepDetailonFocus?.sectionIndex}].itemListElement[${stepDetailonFocus.stepIndex}].ingredients`, values.recipeInstructions[stepDetailonFocus?.sectionIndex].itemListElement[stepDetailonFocus.stepIndex].ingredients)
        }
    }

    const addStepDescription = (e, index) => {
        if (e.key === "Enter") {
            values.recipeInstructions[index].itemListElement.push({
                id: generateId(),
                text: e.target.value,
                ingredients: [],
                parameters: {
                    temperature: 'off',
                    duration: {
                        time: 0,
                        unit: 'sec'
                    },
                    toolsUsed: []
                }
            })
            setFieldValue('recipeInstructions', values.recipeInstructions)
            e.target.value = ""
            e.target.placeholder = "Enter new Step"
            e.target.blur()
            setStepDetailOnFocus({
                ...stepDetailonFocus,
                stepIndex: values.recipeInstructions[index].itemListElement.length
            })
        }
    }

    const getInstructionFrombasic = () => {
        var combineAllInstructions = []
        var ingredients = []
        const proInstructions = values.recipeInstructions.map((instruction, instructionIndex) => {

            instruction.itemListElement.map((element, elementIndex) => {
                combineAllInstructions.push({
                    ...element,
                    '@type': 'HowToStep',
                    parameters: {
                        toolsUsed: element.parameters.toolsUsed,
                        cookingAction: '',
                        stirring: false,
                        temperature: {
                            value: 0,
                            unit: 'c'
                        },
                        duration: {
                            time: 0,
                            unit: 'sec'
                        },
                        power: {
                            value: 0,
                            unit: 'watt'
                        }
                    },
                    recipeInstructionsSectionId: instruction.id,
                    recipeInstructionsStepId: element.id,
                    recipeInstructionsSubStep: 0
                })
            })
            ingredients.push(...instruction.ingredients)

            // return {
            //     id:generateId(),
            //     itemListElement:combineAllInstructions,
            //     ingredients:instruction.ingredients
            // }
        })
        // console.log({itemListElement:combineAllInstructions,ingredients})

        setFieldValue('recipeInstructionsPro', [{
            '@type': 'HowToSection',
            itemListElement: combineAllInstructions,
            ingredients,
            name: 'pro section',

        }])

        // console.log(values)

    }

    const deleteProSection = (e, toRemoveSectinoIndex) => {
        e.stopPropagation();

        setFieldValue('recipeInstructionsPro', values.recipeInstructionsPro.filter((instruction, index) => index !== toRemoveSectinoIndex))
    }



    const addNewStepInPro = (e, insert, step, stepIndex, sectionIndex, value) => {
        let value1 = step.text.slice(0, e.target.selectionStart);
        let value2 = step.text.slice(e.target.selectionStart, step.text.length);
        if (value2) {
            let value1Ingredients = []
            let value2Ingredients = []
            values.recipeIngredient.map((recipeIngredient) => {
                const ingredientSplit = recipeIngredient.name ? recipeIngredient.name.replace(/[^a-zA-Z ]/g, "").split(" ") : recipeIngredient.ingredient.replace(/[^a-zA-Z ]/g, "").split(" ")
                let pattern = new RegExp(`\\b(${ingredientSplit[0]}\s${ingredientSplit[1]}}|${ingredientSplit[0]}|${ingredientSplit[1]})\\b`, 'i')
                if (pattern.test(value1)) {
                    value1Ingredients.push(recipeIngredient.id)
                }
                if (pattern.test(value2)) {
                    value2Ingredients.push(recipeIngredient.id)
                }
            })
            insert(stepIndex + 1, {
                id: generateId(),
                text: value2,
                ingredients: value2Ingredients,
                autoFocus: true,
                parameters: {
                    toolsUsed: [],
                    cookingAction: '',
                    stirring: false,
                    temperature: {
                        value: 0,
                        unit: 'c'
                    },
                    duration: {
                        time: 0,
                        unit: 'sec'
                    },
                    power: {
                        value: 0,
                        unit: 'watt'
                    }
                },
                image: {},
                recipeInstructionsSubStep: step.recipeInstructionsSubStep + 1,
                recipeInstructionsStepId: step.recipeInstructionsStepId,
                recipeInstructionsSectionId: step.recipeInstructionsSectionId
            })
            setFieldValue(`recipeInstructionsPro[${sectionIndex}].itemListElement[${stepIndex}].text`, value1)
            setFieldValue(`recipeInstructionsPro[${sectionIndex}].itemListElement[${stepIndex}].ingredients`, value1Ingredients)
        }
        else {
            insert(stepIndex + 1, {
                id: generateId(),
                text: '',
                ingredients: [],
                autoFocus: true,
                parameters: {
                    toolsUsed: [],
                    cookingAction: '',
                    stirring: false,
                    temperature: {
                        value: 0,
                        unit: 'c'
                    },
                    duration: {
                        time: 0,
                        unit: 'sec'
                    },
                    power: {
                        value: 0,
                        unit: 'watt'
                    }
                },
                image: {},
                recipeInstructionsProIndex: step.recipeInstructionsProIndex + 1,
                recipeInstructionsStepIndex: step.recipeInstructionsStepIndex,
                recipeInstructionsSectionIndex: step.recipeInstructionsSectionIndex
            })
        }
    }

    const switchMode = {
        'basic': () => {
            return (
                <DragDropContext onDragEnd={onStepDragEnd}>
                    <FieldArray name='recipeInstructions'>
                        {(arrayHelpers) => {
                            const { push, insert } = arrayHelpers
                            const addNewStep = (e, step, index, value, stepIndex, insert) => {
                                let value1 = step.text.slice(0, e.target.selectionStart);
                                let value2 = step.text.slice(e.target.selectionStart, step.text.length);
                                let value1Ingredients = []
                                let value2Ingredients = []
                                values.recipeIngredient.map((recipeIngredient) => {
                                    const ingredientSplit = recipeIngredient.name ? recipeIngredient.name.replace(/[^a-zA-Z ]/g, "").split(" ") : recipeIngredient.ingredient.replace(/[^a-zA-Z ]/g, "").split(" ")
                                    let pattern = new RegExp(`\\b(${ingredientSplit[0]}\s${ingredientSplit[1]}|${ingredientSplit[0]}|${ingredientSplit[1]})\\b`, 'i')
                                    if (pattern.test(value1)) {
                                        value1Ingredients.push(recipeIngredient.id)
                                    }
                                    if (pattern.test(value2)) {
                                        value2Ingredients.push(recipeIngredient.id)
                                    }
                                })
                                insert(stepIndex + 1, {
                                    id: generateId(),
                                    text: value2,
                                    ingredients: [],
                                    autoFocus: true,
                                    parameters: {
                                        temperature: 'low',
                                        duration: {
                                            unit: 'sec',
                                            time: 0
                                        },
                                        toolsUsed: [],
                                    }
                                })
                                e.target.value = ""
                                e.target.placeholder = "Enter new Step"
                                e.target.blur()
                                setStepDetailOnFocus({
                                    ...stepDetailonFocus,
                                    stepIndex: stepDetailonFocus.stepIndex + 1
                                })
                                setFieldValue(`recipeInstructions[${index}].itemListElement[${stepIndex}].text`, value1)
                            }
                            const deleteSection = (e, toRemoveSectinoIndex) => {
                                e.stopPropagation()
                                setStepDetailOnFocus({ stepIndex: null, sectionIndex: null })
                                setFieldValue('recipeInstructions', values.recipeInstructions.filter((instruction, index) => index != toRemoveSectinoIndex))
                            }
                            return (
                                <div >
                                    {values.recipeInstructions?.length > 0 && values.recipeInstructions.map((value, index) => (
                                        <Accordion defaultExpanded={true} key={index}>
                                            <AccordionSummary>
                                                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                    <Box style={{ flex: 1 }}>
                                                        <input placeholder='Add Section Title here' value={value.name} onChange={(e) => setFieldValue(`recipeInstructions[${index}].name`, e.target.value)} onClick={(e) => e.stopPropagation()} className={classes.sectionNameInput} />
                                                    </Box>
                                                    <DeleteIcon onClick={(e) => deleteSection(e, index)} style={{ color: "#FA3131", fontSize: 18 }} />
                                                </Box>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Droppable droppableId={`step${index}`}>
                                                    {(provided, snapshot) => (
                                                        <div >
                                                            <FieldArray name={`recipeInstructions[${index}].itemListElement`} >
                                                                {(elementHelpers) => {
                                                                    const { insert, remove } = elementHelpers
                                                                    return (
                                                                        <div
                                                                            {...provided.droppableProps}
                                                                            ref={provided.innerRef}
                                                                            style={{ ...getListStyle(snapshot.isDraggingOver), }}
                                                                        >
                                                                            {value.itemListElement.length > 0 && value.itemListElement.map((step, stepIndex) => (
                                                                                <Draggable key={step.id} draggableId={step.id} index={stepIndex}>
                                                                                    {(provided, snapshot) => (
                                                                                        <div
                                                                                            ref={provided.innerRef}
                                                                                            {...provided.draggableProps}
                                                                                            style={{
                                                                                                ...getItemStyle(
                                                                                                    snapshot.isDragging,
                                                                                                    provided.draggableProps?.style
                                                                                                ), margin: 0
                                                                                            }}
                                                                                        >
                                                                                            <div key={stepIndex} className={classes.accordionDetail}>
                                                                                                <div style={{ padding: "10px 15px", display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', }}>
                                                                                                    <div
                                                                                                        {...provided.dragHandleProps}
                                                                                                        style={{ display: 'flex', flexDirection: 'row', margin: "0px 10px" }}>
                                                                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                                                            <div style={{ margin: "1px 0px", width: 6, height: 6, backgroundColor: stepDetailonFocus.sectionIndex === index && stepDetailonFocus.stepIndex === stepIndex ? '#B998F9' : '#BDBDBD', borderRadius: 50 }} />
                                                                                                            <div style={{ margin: "1px 0px", width: 6, height: 6, backgroundColor: stepDetailonFocus.sectionIndex === index && stepDetailonFocus.stepIndex === stepIndex ? '#B998F9' : '#BDBDBD', borderRadius: 50 }} />
                                                                                                            <div style={{ margin: "1px 0px", width: 6, height: 6, backgroundColor: stepDetailonFocus.sectionIndex === index && stepDetailonFocus.stepIndex === stepIndex ? '#B998F9' : '#BDBDBD', borderRadius: 50 }} />

                                                                                                        </div>
                                                                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                                                            <div style={{ margin: "1px 1px", width: 6, height: 6, backgroundColor: stepDetailonFocus.sectionIndex === index && stepDetailonFocus.stepIndex === stepIndex ? '#B998F9' : '#BDBDBD', borderRadius: 50 }} />
                                                                                                            <div style={{ margin: "1px 1px", width: 6, height: 6, backgroundColor: stepDetailonFocus.sectionIndex === index && stepDetailonFocus.stepIndex === stepIndex ? '#B998F9' : '#BDBDBD', borderRadius: 50 }} />
                                                                                                            <div style={{ margin: "1px 1px", width: 6, height: 6, backgroundColor: stepDetailonFocus.sectionIndex === index && stepDetailonFocus.stepIndex === stepIndex ? '#B998F9' : '#BDBDBD', borderRadius: 50 }} />

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                                                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>

                                                                                                            <p style={{ fontSize: 14, margin: 5 }}>{stepIndex + 1}.</p>
                                                                                                        </div>
                                                                                                        <TextareaAutosize
                                                                                                            id="stepDetail"
                                                                                                            name={`recipeInstructions[${index}].itemListElement[${stepIndex}].text`}
                                                                                                            // onChange={formik.handleChange} 
                                                                                                            onChange={trackStep}
                                                                                                            autoFocus={step.autoFocus}
                                                                                                            onFocus={(e) => setStepDetailOnFocus({ sectionIndex: index, stepIndex })}
                                                                                                            value={step.text}
                                                                                                            onKeyPress={(e) => {
                                                                                                                if (e.key == 'Enter') {
                                                                                                                    e.preventDefault()
                                                                                                                    addNewStep(e, step, index, value, stepIndex, insert)
                                                                                                                    // addStepDescription(e,index,stepIndex)
                                                                                                                    // if(e.key==="Enter"){
                                                                                                                    //     e.preventDefault()
                                                                                                                    // addNewStepInPro(e,insert,step,stepIndex,index,value)


                                                                                                                    // }


                                                                                                                }
                                                                                                            }}
                                                                                                            style={{
                                                                                                                fontFamily: 'Arial Regular',
                                                                                                                flex: 1,
                                                                                                                outline: 'none',
                                                                                                                border: 'none',
                                                                                                                lineHeight: 2,
                                                                                                                resize: 'none',
                                                                                                                marginRight: 10,
                                                                                                                backgroundColor: stepDetailonFocus.sectionIndex === index && stepDetailonFocus.stepIndex === stepIndex ? '#EAE0FF' : 'transparent'
                                                                                                            }}
                                                                                                        />
                                                                                                        {/* <textarea name={`sections[${index}].steps[${stepIndex}].description`} onChange={formik.handleChange}  type="text" wrap="soft" value={step.description} style={{flex:1,outline:'none',border:'none',lineHeight:2,height:'auto',resize:'none'}} /> */}
                                                                                                    </div>
                                                                                                    {stepDetailonFocus.sectionIndex === index && stepDetailonFocus.stepIndex === stepIndex && (
                                                                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', border: "1px solid #FA3131", padding: 5, borderRadius: 4 }}>
                                                                                                            <DeleteIcon onClick={(e) => (remove(stepIndex), setStepDetailOnFocus({ sectionIndex: null, stepIndex: null }))} style={{ color: "#FA3131", fontSize: 18 }} />
                                                                                                        </div>

                                                                                                    )}
                                                                                                </div>
                                                                                                {
                                                                                                    stepDetailonFocus.sectionIndex === index && stepDetailonFocus.stepIndex === stepIndex && (

                                                                                                        <div style={{ position: 'absolute', height: '100%', width: 12, backgroundColor: '#5200F1', top: 0, left: -12, borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }}>

                                                                                                        </div>
                                                                                                    )
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </Draggable>


                                                                            ))}

                                                                        </div>

                                                                    )


                                                                }}
                                                            </FieldArray>

                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                                <div id="dummy" style={{ padding: "10px 50px 10px 50px", borderTop: "1px solid rgba(0, 0, 0, .125)" }} >

                                                    <textarea id="addNewStep" onFocus={(e) => setStepDetailOnFocus({ sectionIndex: null, stepIndex: null })} onKeyDown={(e) => addStepDescription(e, index)} placeholder="enter new step " style={{ width: '100%', border: 'none', outline: 'none', fontSize: 16, resize: 'none' }} />
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}



                                    <div className={classes.addSectionDiv}>

                                        <button className={classes.addSection} onClick={() => push({
                                            id: `${values.recipeInstructions?.length + 1}`,
                                            name: 'new section',
                                            ingredients: [{ id: generateId(), name: 'chicken' }],
                                            itemListElement: [{
                                                id: generateId(),
                                                text: "Add instruction here",
                                                ingredients: [],
                                                derivedIngredients: [],
                                                parameters: {
                                                    temperature: 'off',
                                                    duration: {
                                                        time: 0,
                                                        unit: 'sec'
                                                    },
                                                    toolsUsed: []
                                                },
                                                image: ''
                                            }]
                                        })
                                        }>ADD A SECTION</button>
                                    </div>

                                </div>


                            )
                        }}
                    </FieldArray>
                </DragDropContext>
            )
        },
        'pro': () => {

            return (
                <DragDropContext onDragEnd={onProStepDragEnd}>
                    <Grid>
                        {values.recipeInstructionsPro && values.recipeInstructionsPro?.length > 0 ? (
                            <FieldArray name='recipeInstructionsPro'>
                                {(arrayHelpers) => {

                                    const { push, insert } = arrayHelpers

                                    const addNewStep = (e, stepIndex) => {
                                        insert(stepIndex + 1, {
                                            id: generateId(),
                                            text: '',
                                            ingredients: [],
                                            parameters: {
                                                temperature: 'low',
                                                duration: {
                                                    unit: 'sec',
                                                    time: 0
                                                },
                                                toolsUsed: [],
                                                pro: {
                                                    cookingAction: '',
                                                    power: {
                                                        value: 0,
                                                        unit: 'watt'
                                                    },
                                                    temperature: {
                                                        value: 0,
                                                        unit: 'c'
                                                    },
                                                    duration: {
                                                        time: 0,
                                                        unit: 'sec'
                                                    },
                                                    stirring: false
                                                }
                                            }
                                        })

                                        e.target.value = ""
                                        e.target.placeholder = "Enter new Step"
                                        e.target.blur()
                                        setStepDetailOnFocus({
                                            ...stepDetailonFocus,
                                            stepIndex: stepDetailonFocus.stepIndex + 1
                                        })
                                    }
                                    return (

                                        <div>


                                            {values.recipeInstructionsPro?.length > 0 && values.recipeInstructionsPro.map((value, index) => (
                                                <Accordion defaultExpanded={true} key={index}>
                                                    <AccordionSummary>
                                                        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                                                            <Box style={{ flex: 1 }}>


                                                                <input value={value.name} onClick={(e) => e.stopPropagation()} onChange={(e) => setFieldValue(`recipeInstructionsPro[${index}].name`, e.target.value)} className={classes.sectionNameInput} />
                                                            </Box>
                                                            <DeleteIcon onClick={(e) => deleteProSection(e, index)} style={{ color: "#FA3131", fontSize: 18 }} />
                                                        </Box>
                                                        {/* <Typography>{proMode ? `${value.name} (no more than one action in a step)` : value.name}</Typography> */}
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Droppable droppableId={`proStep${index}`}>
                                                            {(provided, snapshot) => (
                                                                <div>

                                                                    <FieldArray name={`recipeInstructionsPro[${index}].itemListElement`} >
                                                                        {(elementHelpers) => {

                                                                            const { insert, remove } = elementHelpers

                                                                            return (
                                                                                <div
                                                                                    {...provided.droppableProps}
                                                                                    ref={provided.innerRef}
                                                                                    style={{ ...getListStyle(snapshot.isDraggingOver), }}
                                                                                >
                                                                                    {value.itemListElement.length > 0 && value.itemListElement.map((step, stepIndex) => (
                                                                                        <Draggable key={step.id} draggableId={step.id} index={stepIndex}>
                                                                                            {(provided, snapshot) => (
                                                                                                <div

                                                                                                    ref={provided.innerRef}
                                                                                                    {...provided.draggableProps}

                                                                                                    style={{
                                                                                                        ...getItemStyle(
                                                                                                            snapshot.isDragging,
                                                                                                            provided.draggableProps?.style
                                                                                                        ), margin: 0
                                                                                                    }}
                                                                                                >
                                                                                                    <div key={stepIndex} className={classes.accordionDetail}>
                                                                                                        <div style={{ padding: "10px 15px", display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', }}>



                                                                                                            <div
                                                                                                                {...provided.dragHandleProps}
                                                                                                                style={{ display: 'flex', flexDirection: 'row', margin: "0px 10px" }}>
                                                                                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                                                                    <div style={{ margin: "1px 0px", width: 6, height: 6, backgroundColor: stepDetailonFocus.sectionIndex === index && stepDetailonFocus.stepIndex === stepIndex ? '#B998F9' : '#BDBDBD', borderRadius: 50 }} />
                                                                                                                    <div style={{ margin: "1px 0px", width: 6, height: 6, backgroundColor: stepDetailonFocus.sectionIndex === index && stepDetailonFocus.stepIndex === stepIndex ? '#B998F9' : '#BDBDBD', borderRadius: 50 }} />
                                                                                                                    <div style={{ margin: "1px 0px", width: 6, height: 6, backgroundColor: stepDetailonFocus.sectionIndex === index && stepDetailonFocus.stepIndex === stepIndex ? '#B998F9' : '#BDBDBD', borderRadius: 50 }} />

                                                                                                                </div>
                                                                                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                                                                    <div style={{ margin: "1px 1px", width: 6, height: 6, backgroundColor: stepDetailonFocus.sectionIndex === index && stepDetailonFocus.stepIndex === stepIndex ? '#B998F9' : '#BDBDBD', borderRadius: 50 }} />
                                                                                                                    <div style={{ margin: "1px 1px", width: 6, height: 6, backgroundColor: stepDetailonFocus.sectionIndex === index && stepDetailonFocus.stepIndex === stepIndex ? '#B998F9' : '#BDBDBD', borderRadius: 50 }} />
                                                                                                                    <div style={{ margin: "1px 1px", width: 6, height: 6, backgroundColor: stepDetailonFocus.sectionIndex === index && stepDetailonFocus.stepIndex === stepIndex ? '#B998F9' : '#BDBDBD', borderRadius: 50 }} />

                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                                                                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>

                                                                                                                    <p style={{ fontSize: 14, margin: 5 }}>{stepIndex + 1}.</p>
                                                                                                                </div>

                                                                                                                <TextareaAutosize

                                                                                                                    name={`recipeInstructionsPro[${index}].itemListElement[${stepIndex}].text`}
                                                                                                                    autoFocus={step.autoFocus}
                                                                                                                    onChange={trackProStep}

                                                                                                                    onFocus={(e) => (setStepDetailOnFocus({ sectionIndex: index, stepIndex }), console.log(values))}
                                                                                                                    value={step.text}
                                                                                                                    onKeyPress={(e) => {


                                                                                                                        if (e.key === "Enter") {
                                                                                                                            e.preventDefault()
                                                                                                                            addNewStepInPro(e, insert, step, stepIndex, index, value)


                                                                                                                        }


                                                                                                                    }}
                                                                                                                    style={{
                                                                                                                        fontFamily: 'Arial Regular',
                                                                                                                        flex: 1,
                                                                                                                        outline: 'none',
                                                                                                                        border: 'none',
                                                                                                                        lineHeight: 2,
                                                                                                                        resize: 'none',
                                                                                                                        marginRight: 10,
                                                                                                                        backgroundColor: stepDetailonFocus.sectionIndex === index && stepDetailonFocus.stepIndex === stepIndex ? '#EAE0FF' : 'transparent'
                                                                                                                    }}
                                                                                                                />

                                                                                                            </div>
                                                                                                            {stepDetailonFocus.sectionIndex === index && stepDetailonFocus.stepIndex === stepIndex && (
                                                                                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', border: "1px solid #FA3131", padding: 5, borderRadius: 4 }}>
                                                                                                                    <DeleteIcon onClick={(e) => remove(stepIndex)} style={{ color: "#FA3131", fontSize: 18 }} />
                                                                                                                </div>

                                                                                                            )}
                                                                                                        </div>
                                                                                                        {
                                                                                                            stepDetailonFocus.sectionIndex === index && stepDetailonFocus.stepIndex === stepIndex && (

                                                                                                                <div style={{ position: 'absolute', height: '100%', width: 12, backgroundColor: '#5200F1', top: 0, left: -12, borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }}>

                                                                                                                </div>
                                                                                                            )
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}
                                                                                        </Draggable>


                                                                                    ))}

                                                                                </div>

                                                                            )


                                                                        }}
                                                                    </FieldArray>

                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                        <div style={{ padding: "10px 50px 10px 50px", borderTop: "1px solid rgba(0, 0, 0, .125)" }} >

                                                            <textarea onFocus={(e) => setStepDetailOnFocus({ sectionIndex: null, stepIndex: null })} onKeyDown={(e) => addStepDescription(e, index)} placeholder="enter new step " style={{ width: '100%', border: 'none', outline: 'none', fontSize: 16, resize: 'none' }} />
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                            ))}



                                            <div className={classes.addSectionDiv}>

                                                <button className={classes.addSection} onClick={() => push({
                                                    id: `${values.recipeInstructions?.length + 1}`,
                                                    name: 'new section',
                                                    ingredients: [{ id: generateId(), name: 'chicken' }],
                                                    itemListElement: [{
                                                        id: generateId(),
                                                        text: "Add instruction here",
                                                        ingredients: [],
                                                        derivedIngredients: [],
                                                        parameters: {
                                                            toolsUsed: [],
                                                            temperature: 'off',
                                                            duration: 0,
                                                            durationUnit: 'sec'
                                                        },
                                                        image: ''
                                                    }]
                                                })
                                                }>ADD A SECTION</button>

                                            </div>
                                            {/* <div className={classes.addSectionDiv}>

                                            <button className={classes.addSection} onClick={()=>{
                                                setStepDetailOnFocus({sectionIndex:null,stepIndex:null})
                                                setFieldValue('recipeInstructionsPro',[])
                                            }}>CLEAR PRO STEPS</button>
                                               
                                        </div> */}

                                        </div>


                                    )
                                }}
                            </FieldArray>
                        ) : (
                            <Grid >
                                <Typography>convert normal instructions to pro template</Typography>
                                <Button onClick={getInstructionFrombasic} >Convert</Button>

                            </Grid>
                        )}
                    </Grid>
                </DragDropContext>
            )

        }
    }


    return (
        <StepDetailContext.Provider value={{ stepDetailonFocus }}>


            <Grid container columnSpacing={3}>
                <Grid item md={8}>
                    <FormikProvider value={formik}>


                        {switchMode[proMode ? 'pro' : 'basic']()}


                    </FormikProvider>
                </Grid>
                <Grid item md={4}>
                    {stepDetailonFocus.sectionIndex !== null && stepDetailonFocus.stepIndex !== null ? (
                        <Box className={classes.stepDetailsDiv}>


                            <Grid container  >
                                <Grid item container className={classes.stepDetailsOptions} >
                                    <Grid item xs={4} container direction="row" justifyContent="center" alignItems="center" onClick={() => setStepDetailName('parameters')} >
                                        <Typography style={{ cursor: 'pointer', color: stepDetailName === 'parameters' ? 'black' : '#ADADAD' }}>Parameters</Typography>
                                    </Grid>
                                    <Grid item xs={4} container direction="row" justifyContent="center" alignItems="center" onClick={() => setStepDetailName('ingredients')} >
                                        <Typography style={{ cursor: 'pointer', color: stepDetailName === 'ingredients' ? 'black' : '#ADADAD' }}>Ingredients</Typography>
                                    </Grid>
                                    <Grid item xs={4} container direction="row" justifyContent="center" alignItems="center" onClick={() => setStepDetailName('images')}>
                                        <Typography style={{ cursor: 'pointer', color: stepDetailName === 'images' ? 'black' : '#ADADAD' }}>Images</Typography>
                                    </Grid>

                                </Grid>
                                <Grid item xs={12}>
                                    <Container>

                                        <Grid container direction="row" justifyContent="center" style={{ height: "min(40vh,300px)" }} spacing={2}>
                                            <Grid item xs={12}>

                                                {/* {renderStepDetails[stepDetailName]()} */}

                                                <RenderStepDetails detailsOf={stepDetailName} />


                                            </Grid>
                                        </Grid>
                                    </Container>

                                </Grid>
                            </Grid>
                        </Box>


                    ) : (
                        <div className={classes.stepDetailsDiv}>
                            <Grid container  >
                                <Grid item xs={12} className={classes.stepDetailsHeadingDiv} >
                                    <Grid container direction="row" alignItems="center" >
                                        <Grid item className={classes.stepDetailsHeading} onClick={() => setEmptyStepName('parameters')}>
                                            <Typography textAlign="center" color="#d9d9d9">Parameters</Typography>
                                        </Grid>
                                        <Grid item className={classes.stepDetailsHeading} onClick={() => setEmptyStepName('ingredients')}>
                                            <Typography textAlign="center" color="#d9d9d9" >Ingredients</Typography>
                                        </Grid>
                                        <Grid item className={classes.stepDetailsHeading} onClick={() => setEmptyStepName('images')}>
                                            <Typography textAlign="center" color="#d9d9d9">Images</Typography>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid item xs={12} className={classes.emptyStepContent} style={{ minHeight: '45vh' }}  >
                                    <Container style={{ height: '100%' }}>
                                        <Grid container direction="row" justifyContent="center" style={{ height: '100%', }}>

                                            {/* {renderEmptyStepDetails[emptyStepName]()} */}
                                            <RenderEmptyStepDetails detailsOf={emptyStepName} />
                                        </Grid>

                                    </Container>

                                </Grid>
                            </Grid>

                        </div>

                    )}
                </Grid>
            </Grid>
        </StepDetailContext.Provider>
    )
}
