import { FirebaseApi } from "../../firebaseConfig";
import { CRUDoperations } from "../CRUD/crud";

const articleDrafts = {

    createWithUserId: async (creatorId, data) => {
        console.log(creatorId, data)

        const result = await CRUDoperations.create("articleDrafts", {
            creatorId: creatorId,
            type: 'ArticleDraft',
            values: data,



        })
        console.log("Added new article in drafts");
        return result

    },
    fetch: async () => {
        try {
            const getArticles = await FirebaseApi["articleDrafts"].get();

            const articles = getArticles.docs.map(articles => ({
                ...articles.data(), id: articles.id
            }))




            return articles;

        } catch (err) {

        }
    },
    fetchByCreatorId: async (userId) => {
        try {
            const data = await FirebaseApi.articleDrafts.where('creatorId', '==', `${userId}`).get();
            const final = data.docs.map(articles => ({
                ...articles.data(), id: articles.id
            }))

            return final;


        } catch (err) {

        }
    },

    fetchByArticleId: async (articleId) => {

        try {
            const result = await FirebaseApi.articleDrafts.doc(articleId).get();

            if (result.data()) {
                return { id: articleId, ...result.data() }
            }
        } catch (err) {
            console.log(err);
        }
    },
    delete: async (docId) => {
        try {
            const deleting = await CRUDoperations.delete("articleDrafts", docId)
            return 'done'

        }
        catch (err) {
            throw err

        }

    },
    update: async (docId, data) => {

        try {
            const updating = await CRUDoperations.update("articleDrafts", docId, data)
            return updating




        } catch (err) {
            return err;
        }
    },

}

export default articleDrafts;