
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';
import {theme} from './theme';

export const drawerWidth = 240;
export const styles ={

    // commonlyUsed
    flexColumnCenter:{
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      justifyContent:'center'
    },
    flexRowCenter:{
      display:'flex',
      flexDirection:'row',
      justifyContent:'center',
      alignItems:'center'
    },
    flexWeight:{
      flex:1
    },

    // main
    root: {
        display: 'flex',
      },
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      menuButton: {
        marginRight: 36,
      },
      hide: {
        display: 'none',
      },
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
      },
      drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      drawerClose: {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9) + 1,
        },
      },
      drawerPaper: {
        width: drawerWidth,
      },
      toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      },
      content: {
        flexGrow: 1,
        width:'80%',
        height:'85vh',
        padding: theme.spacing(3),
        marginLeft:drawerWidth,
        marginTop:65,
        [theme.breakpoints.down('sm')]:{
          marginLeft:0,
          width:'100%',
          height:'95vh',
         
        }
      },

      // recipes page
      recipeCard:{
        width: '100%',
        height: 'auto',
        // [theme.breakpoints.down('lg')]:{
        //   height:'50vh'
        // }

      },



      // adding recipe page 
      // parse url section
      

};


