import React, { useContext } from 'react'
import { Grid } from '@mui/material'
import { SiteEditorHeader, SiteEditorHeaderTypograpghy } from './Styles'
import AppContext from '../../../config/AppContext';
import CreatorInfoTopContainer from './CreatorInfoTopContainer';

const CreatorInfo = () => {

  return (
    <Grid container direction="column" style={{ height: '100vh', background: '#DACEBE', width: '100%' }}>
        <Grid item style={{ height: '93vh' }}>
            <CreatorInfoTopContainer />
        </Grid>
        <Grid item container direction='row'>
            <SiteEditorHeader>
                <SiteEditorHeaderTypograpghy>all</SiteEditorHeaderTypograpghy>
            </SiteEditorHeader>
            <SiteEditorHeader>
                <SiteEditorHeaderTypograpghy>recipe</SiteEditorHeaderTypograpghy>
            </SiteEditorHeader>
            <SiteEditorHeader>
                <SiteEditorHeaderTypograpghy>article</SiteEditorHeaderTypograpghy>
            </SiteEditorHeader>
        </Grid>
    </Grid>
  )
}

export default CreatorInfo