import React, { useEffect, useContext, useState } from 'react'
import { Grid, Button, Typography, TextField, InputAdornment } from '@mui/material'
import AppContext from '../../../config/AppContext'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useFormik, } from 'formik';
import CloudServices from '../../../cloud-services-core'
import axios from 'axios'
import { Box } from '@mui/system'
import { Lock } from '@mui/icons-material';

const EditMemberLevelScreen = () => {
    const AppState = useContext(AppContext);
    const [memberLevel, setMemberLevel] = useState('free');
    const [membershipData, setMembershipData] = useState({});
    const [noteNumber,setNoteNumber] = useState(1);
    const [currentUser, setCurrentUser] = useState(AppState?.user?.id)
    const [existingCreator,setExistingCreator] = useState(false);
    const [documentId,setDocumentId] = useState('');
    const createMembership = async (values) => {
        let val = await CloudServices.creatorMemberships.createWithId('cslTcwidabdnNO9o1GWnnH39XMD2', values);
    }

    const [newBenifit,setNewBenifit] = useState('')
    const formik = useFormik({
        enableReinitialize:true,
        initialValues: {
            free:
            {
                period:"monthly",
                interval:1,
                item:{
                    name: membershipData ? membershipData?.free?.item?.name : '',
                    amount: membershipData ? membershipData?.free?.item?.amount : '',
                    currency: "INR",  
                },
                benefits: membershipData ? membershipData?.free?.benefits : [''],
                imageUrl:'',
                id:membershipData ?  membershipData?.free?.id : ''
            },
            paid:
            {
                period:"monthly",
                interval:1,
                item:{
                    name:membershipData ? membershipData?.platinum?.item?.name : '',
                    amount:membershipData ? membershipData?.platinum?.item?.amount : '',
                    currency: "INR",  
                },
                benefits: membershipData ? membershipData?.platinum?.benefits : [''],
                imageUrl:''
            }
        },
        onSubmit: values => {
            let data = values[memberLevel];
            createAPlan(data);
        }
    });


    // console.log( membershipData?.free?.benefits);
    const createAPlan =async (body) => {
        try{
            let apiBody = {period:body?.period,interval:body?.interval,item:body?.item}
            console.log(apiBody,"apiBody");
            const result = await axios.post('https://us-central1-riku-staging.cloudfunctions.net/razorpay/createPlan',apiBody);
            let planId = result?.data?.id
            console.log(planId,"planId");
            console.log(existingCreator,"existingCreator")
            
            // console.log(values[memberLevel]);
            
            if(planId?.length > 0){
                if(existingCreator){
                    let status = await CloudServices.creatorMemberships.update(documentId,memberLevel,planId,values[memberLevel])
                    console.log(status,"status");
                }else{
                    let status = await CloudServices.creatorMemberships.createWithId(currentUser,memberLevel,planId,values[memberLevel]);
                    console.log(status,"status");
                }
            }
            console.log(result);
        }catch(error){
            console.log(error,"error");
        }
    }

    const { values, handleChange, setFieldValue } = formik

    const getMembershipDetails = async (userId) => {
        let val = await CloudServices.creatorMemberships.fetchByUserId(currentUser);
        if(val.length > 0){
            setExistingCreator(true);
            setDocumentId(val[0]['id'])
        }
        console.log(val,"getMembershipDetails");
        // console.log(val[0]['id'],'docId');
        setMembershipData(val[0]);
    }

    useEffect(() => {
        getMembershipDetails();
    }, [])


    // To generate a new field in formik
    const generateField =async () => {
        setFieldValue(`${memberLevel}.benefits`,[...values[memberLevel]['benefits'],''])
    }

    const removeField = (index) => {
            let newBenefits = [...values[memberLevel]['benefits']];
            newBenefits.splice(index,1);
            setFieldValue(`${memberLevel}.benefits`,newBenefits);
    }

    const renderScreen = (screen) => {
        switch(screen){
            case 'free': 
            return (
                <Grid container direction='row' style={{ width: 800, height: 500 }}>
                    <Grid item container direction='column' xs={8}>
                        <Grid item container justifyContent='space-between'>
                            <Grid item xs={7}>
                                <Typography>Title</Typography>
                                <TextField 
                                    size="small"
                                    type="text" 
                                    placeholder='Name of this tier' 
                                    defaultValue={membershipData ? membershipData?.free?.item?.name : ''} 
                                    onChange={(e) => setFieldValue('free.item.name', e.target.value)} style={{ width: '100%', marginRight: '20px' }} 
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>
                                    Amount
                                    <Lock style={{ width: 14, height: 14, marginLeft: 10 }} />
                                </Typography>
                                <TextField 
                                    size="small" 
                                    type='number'
                                    placeholder='Rs. 0' 
                                    defaultValue={membershipData ? membershipData?.free?.item?.amount : ''} 
                                    onChange={(e) => setFieldValue('free.item.amount', e.target.value)} 
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">/month</InputAdornment>,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item style={{ marginTop: 20 }}>
                            <Typography>Benefits</Typography>
                            {
                                values?.free?.benefits?.length>0 &&  values?.free?.benefits.map((item,index) => (
                                    <Grid container key={item} justifyContent='space-between'>
                                        <Grid item xs={10}>
                                            <TextField 
                                                size='small' 
                                                style={{ width: '100%', marginBottom: 10 }} 
                                                placeholder='Start typing your offerings here' 
                                                value={item}  
                                                name={`free.benefits[${index}]`} 
                                                onChange={handleChange} 
                                            />
                                        </Grid>
                                        <Grid item style={{ background: '#f4f4f4', width: '40px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center',marginLeft:'20px' }} onClick={() => index ==  values?.free?.benefits?.length - 1 ?  generateField(index) : removeField(index) }>
                                           {
                                            index ==  values?.free?.benefits?.length - 1 ?  <AddIcon style={{ color: '#8D8D8D' }} /> : <RemoveIcon style={{color: '#8D8D8D'}} />
                                           }
                                        </Grid>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography style={{ marginLeft: 50 }}>Photo</Typography>
                        <Box style={{ width: 100, height: 100, borderRadius: '50%', marginLeft: 30, border: '1px solid #E0E3E7'}}>
                            <img src='' alt='' />
                        </Box>
                    </Grid>
                </Grid>
            )
            case 'paid':
                return (
                    <Grid container direction='row' style={{ width: 800, height: 500 }}>
                    <Grid item container direction='column' xs={8}>
                        <Grid item container justifyContent='space-between'>
                            <Grid item xs={7}>
                                <Typography>Title</Typography>
                                <TextField 
                                    size="small"
                                    type="text" 
                                    id=''
                                    name=''
                                    placeholder='Name of this tier' 
                                    defaultValue={membershipData ? membershipData?.paid?.item?.name : ''}
                                    onChange={(e) => setFieldValue('paid.item.name', e.target.value)}
                                    style={{ width: '100%', marginRight: '20px' }} 
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>
                                    Amount
                                    <Lock style={{ width: 14, height: 14, marginLeft: 10 }} />
                                </Typography>
                                <TextField 
                                    size="small" 
                                    type='number'
                                    id=''
                                    name=''
                                    placeholder='Rs. 299' 
                                    defaultValue={membershipData ? membershipData?.paid?.item?.amount : ''} 
                                    onChange={(e) => setFieldValue('paid.item.amount', e.target.value)} 
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">/month</InputAdornment>,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item style={{ marginTop: 20 }}>
                            <Typography>Benefits</Typography>
                            {
                                values?.paid?.benefits?.length > 0 &&  values?.paid?.benefits.map((item,index) => (
                                    <Grid container key={item} justifyContent='space-between'>
                                        <Grid item xs={10}>
                                            <TextField 
                                                size='small' 
                                                style={{ width: '100%', marginBottom: 10 }} 
                                                placeholder='Start typing your offerings here' 
                                                value={item}  
                                                name={`paid.benefits[${index}]`} 
                                                onChange={handleChange} 
                                            />
                                        </Grid>
                                        <Grid item style={{ background: '#f4f4f4', width: '40px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center',marginLeft:'20px' }} onClick={() => index ===  values?.paid?.benefits?.length - 1 ?  generateField(index) : removeField(index) }>
                                           {
                                            index ===  values?.paid?.benefits?.length - 1 ?  <AddIcon style={{ color: '#8D8D8D' }} /> : <RemoveIcon style={{color: '#8D8D8D'}} />
                                           }
                                        </Grid>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography style={{ marginLeft: 50 }}>Photo</Typography>
                        <Box style={{ width: 100, height: 100, borderRadius: '50%', marginLeft: 30, border: '1px solid #E0E3E7'}}>
                            <img src='' alt='' />
                        </Box>
                    </Grid>
                </Grid>
                )
            default:
                return;
        }
    }
   
    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid>
                <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginBottom: 20 }}>
                    <Button item value="free" style={memberLevel === "free" ? { borderBottom: '1px solid #F94C0C', borderRadius: 0, color: '#8E8E8E'  } : { color: '#8E8E8E' }} onClick={() => setMemberLevel('free')}> Free Level</Button>
                    <Button item value="paid" style={memberLevel === "paid" ? { borderBottom: '1px solid #F94C0C', borderRadius: 0, color: '#8E8E8E',  } : { color: '#8E8E8E' }} onClick={() => setMemberLevel('paid')}> Paid Level</Button>
                </Grid>
                {renderScreen(memberLevel)}
                {/* <Grid>
                    <button type="submit" >Submit</button>
                </Grid> */}
            </Grid >
        </form>
    )
}

export default EditMemberLevelScreen

/*
{ values?.platinum?.benefits?.length>0 ? (
                                    values?.platinum?.benefits.map((item,index) => (
                                       <Grid key={item} style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',width:'100%',marginTop:'10px'}}>
                                           <TextField style={{}}  defaultValue={item} onKeyPress={(e)=>{
                                               if(e.key =='Enter'){
                                                   setFieldValue(`platinum.benefits`,[...values.platinum.benefits,""])
                                               }
                                           }} onChange={(e) => setFieldValue(`platinum.benefits[${index}]`,e.target.value) } />
                                           <Grid item style={{ background: '#f4f4f4', width: '40px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center',marginLeft:'20px' }} onClick={() => index ==  values?.platinum?.benefits?.length - 1 ?  generateField(index) : removeField(index) }>
                                              {
                                               index ==  values?.platinum?.benefits?.length - 1 ?  <AddIcon style={{ color: '#8D8D8D' }} /> : <RemoveIcon style={{color: '#8D8D8D'}} />
                                              }
                                           </Grid>
                                       </Grid>
                                   ))
                                ): (
                                    <Grid style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',width:'100%',marginTop:'10px'}}>
                                           <TextField style={{}}  defaultValue={values?.platinum?.benefits} onKeyPress={(e)=>{
                                               if(e.key =='Enter'){
                                                   setFieldValue(`platinum.benefits`,[...values.platinum.benefits,""])
                                               }
                                           }} onChange={(e) => setFieldValue(`platinum.benefits[0]`,e.target.value) } />
                                           <Grid item style={{ background: '#f4f4f4', width: '40px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center',marginLeft:'20px' }} onClick={() =>  generateField() }>
                                              <AddIcon style={{ color: '#8D8D8D' }} />      
                                           </Grid>
                                       </Grid>
                                    )
                                }
*/