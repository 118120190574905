import React, { Component } from 'react';
import {withStyles} from '@material-ui/core/styles';
import {styles, drawerWidth} from '../config/styles';



const MainContent =(props)=>{
   
    return(
        // <main className={props.classes.content}>     
        <main style={{flex:1,scrollBehavior:'smooth'}}>           
            {props.children}
        </main>
    )

}


// class MainContent extends Component {
//     render() {
//         const {classes} = this.props;
//         return (
//             <main className={classes.content} style={{marginLeft: drawerWidth,marginTop:65 }}>                
//                 {this.props.children}
//             </main>
//         );
//     }
// }

export default withStyles(styles)(MainContent);