import { useState, useContext } from 'react'
import { useEffect } from 'react';
import CloudServices from '../../../cloud-services-core';
import AppContext from '../../../config/AppContext';

const useDashBoardHook = () => {
    const AppState = useContext(AppContext);
    const { firstName, lastName } = AppState.user;
    const [articleDrafts, setArticleDrafts] = useState([]);
    const [recipeDrafts, setRecipeDrafts] = useState([]);
    const [totalItems, setTotalItems] = useState([]);
    const totalCollections = [];
    
    const getArticles = async () => {
        try{
            let article = await CloudServices.articles.fetchByCreatorId(AppState?.user?.id);
            let recipe = await CloudServices.recipes.fetchByCreatorId(AppState?.user?.id);
            setArticleDrafts(article);
            setRecipeDrafts(recipe);
            totalCollections.push(...article, ...recipe);
            setTotalItems(totalCollections);
        } catch(e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getArticles();
    },[])

    return {
        states: {
            firstName,
            lastName,
            totalItems,
            articleDrafts,
            recipeDrafts,
        },
        actions: {

        }
    }
}

export default useDashBoardHook;