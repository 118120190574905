import { Button, Grid, TextField } from '@material-ui/core'
import React from 'react'
import { ProfileRightContainerButton } from '../Styles';

const ProfileStep1 = (props) => {
  const { values, setFieldValue } = props;
  
  return (
    <Grid container>
      <Grid item container direction="column" spacing={2}>
        <Grid item container direction="row" spacing={2}>
          <Grid item>
            <label 
              style = {{
                fontSize: "18px",
                fontWeight: 600,
                marginBottom: 10, 
              }}
            >
              First Name
            </label>
            <br />
            <TextField
              name='firstName' 
              id="firstName"
              variant="outlined" 
              type="text" 
              value={values.firstName} 
              onChange={(e) => setFieldValue('firstName', e.target.value)}
              size="small"
              placeholder='First Name'
            />
          </Grid>
          <Grid item>
            <label 
              style = {{
                fontSize: "18px",
                fontWeight: 600,
                marginBottom: 10, 
              }}
            >
              Last Name
            </label>
            <br />
            <TextField 
              name='lastName'
              id="lastName"
              variant="outlined" 
              type="text" 
              value={values.lastName}
              onChange={(e) => setFieldValue('lastName', e.target.value)}
              size='small'
              placeholder='Last Name'
            />
          </Grid>
        </Grid>
        <Grid item>
          <label 
              style = {{
                fontSize: "18px",
                fontWeight: 600,
                marginBottom: 10, 
              }}
            >
              Web URL
            </label>
          <br />
          <TextField 
            type="text" 
            value={values.webURL} 
            onChange={(e) => setFieldValue('webURL', e.target.value)}
            variant="outlined" 
            id="webURL"
            name="webURL"
            size="small"
            placeholder='Web Site URL'
          />
            .klynk.recipes
        </Grid>
        <Grid item>
          <label 
              style = {{
                fontSize: "18px",
                fontWeight: 600,
                marginBottom: 10, 
              }}
            >
              Country of Residence
            </label>
          <br />
          <TextField 
            type="text" 
            value={values.country} 
            onChange={(e) => setFieldValue('country', e.target.value)}
            variant="outlined" 
            id="country"
            name='country'
            size="small"
            placeholder='Country'
          />
        </Grid>
      </Grid>
      <ProfileRightContainerButton>
        <Button type='submit' style={{ color: 'white' }}>Continue</Button>
      </ProfileRightContainerButton>
    </Grid>
  )
}

export default ProfileStep1