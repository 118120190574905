import React,{useEffect,useState} from 'react'
import {Box,Container,Grid ,Typography,Button} from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {Switch} from '../../components/Switch'
import CloudServices from '../../cloud-services-core';
import {FirebaseApi} from '../../firebaseConfig';
import RecipeCard from '../../components/RecipeCard';

export default function DraftedRecipes(props) {

    const [draftedRecipes,setDraftedRecipes] = useState([])


    useEffect(()=>{
        getDraftedRecipes()
    },[])


    


    const getDraftedRecipes = async() =>{
        try {
            const recipes = await FirebaseApi['drafts'].limit(30).orderBy("dateAdded","desc").get()
            const parsedRecipes = recipes.docs.map(doc => {
                const id = doc.id
                return { id, ...doc.data() }
            })
            console.log(parsedRecipes)
          
            setDraftedRecipes(parsedRecipes)
        } catch (error) {
            console.log(error)
        }

    }
    return (
        <div>
            <Box  style={{width:'100%',minHeight:'8vh',backgroundColor:'#F94C0C',position:'sticky',top:0,zIndex:1000}} >
                <Container maxWidth="xl" > 
                    <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{minHeight:'8vh'}}>
                        <Grid item lg={9} xs={5}>
                            <Box style={{
                                width:40,
                                height:40,
                                display:'flex',
                                flexDirection:'row',
                                justifyContent:'center',
                                alignItems:'center',
                                borderRadius:'50%',
                                backgroundColor:'rgba(255,255,255,0.5)'
                                }}>
                                <KeyboardBackspaceIcon  style={{color:'white'}}/>

                            </Box>
                        </Grid>
                        <Grid item lg={3}  xs={7} >
                            <Grid container >
                                <Grid item lg={4} xs={4}>
                                    <Grid container direction="row" alignItems="center" justifyContent="space-around" style={{height:'100%'}} >
                                        <Grid item>
                                            <Typography style={{color:'white'}}>Pro</Typography>

                                        </Grid>
                                        <Grid item>
                                            <Switch />

                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} xs={6} >
                                    <Box    style={{height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>

                                    <Button variant="contained" style={{backgroundColor:'black'}}>Cook Now</Button>
                                    </Box>
                                </Grid>
                                <Grid item lg="auto" xs="auto" >
                                    <Box style={{height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                        <MoreVertIcon style={{fontSize:30}} />

                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Container>

            </Box>
            <Container maxWidth="xl">

                <Box style={{paddingTop:40}}>
                    <Grid container spacing={1}>
                        {draftedRecipes.length > 0 && (
                            
                            draftedRecipes.map(recipe=>(
                                <Grid key={recipe.id} item>
                                    <RecipeCard 
                                    collectionType="drafts"  
                                    name={recipe.recipe?.name} 
                                    id={recipe.id} 
                                    image={recipe.recipe?.image ?  recipe.recipe?.image[0] : ''} 
                                    recipe={recipe} action={()=>getDraftedRecipes()}  
                                    date={recipe.recipe?.datePublished?.substring(0,10)} 
                                    />
                                </Grid>
                            ))
                            )}
                    </Grid>
                </Box>
            </Container>


        </div>
    )
}
