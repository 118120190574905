import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(()=> ({
    mainContainer: {
        maxWidth: '1238px',
        margin: "10px 0 0 135px",
        border: '1px solid #000'
    },
    ingredientTab: {
        width: '336px',
        border: '1px solid white',
        background: '#D0D0D0',
        fontSize: "24px",
    },
    quantityTab: {
        width: '232px',
        border: '1px solid white',
        background: '#D0D0D0',
    },
    unitsTab: {
        width: '232px',
        border: '1px solid white',
        background: '#D0D0D0',
    },
    prepstylesTab: {
        width: '438px',
        border: '1px solid white',
        background: '#D0D0D0',
    },
    ingridentsTable: {
        border: "2px solid #E2E2E2",
        height: "61px",
        padding: "17px"
    },
    textField: {
        border: "none",
        width: "100%",
        height: 15,
        padding: 5,
    },
    typography: {
        fontSize: 17,
        fontWeight: 'bold',
        padding: "auto"
    },
    closeTab: {
        width: '50px',
        borderTopRightRadius: 15,
        borderBottomRightRadius: 15,
    }
}))

export { useStyles }