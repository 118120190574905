import { FirebaseApi } from "../../firebaseConfig";
import { CRUDoperations } from "../CRUD/crud";

const articles = {
    create: async (data) => {
        try {
            const newArticle = await CRUDoperations.create('articles', data);
            return newArticle
        }
        catch (err) {
            return "No articles found";
        }
    },
    createWithUserId: async (creatorId, data) => {
        console.log(creatorId, data)

        const result = await CRUDoperations.create("articles", {
            creatorId: creatorId,
            type: 'Article',
            values: data,



        })
        console.log("Added new article");
        return result

    },
    fetch: async () => {
        try {
            const getArticles = await FirebaseApi['articles'].get();

            const articles = getArticles.docs.map(articles => ({
                ...articles.data(), id: articles.id
            }))




            return articles;

        } catch (err) {

        }
    },
    fetchByCreatorId: async (userId) => {
        try {
            const data = await FirebaseApi.articles.where('creatorId', '==', `${userId}`).get();
            const final = data.docs.map(articles => ({
                ...articles.data(), id: articles.id
            }))

            return final;


        } catch (err) {

        }
    },

    fetchByArticleId: async (articleId) => {

        try {
            const result = await FirebaseApi.articles.doc(articleId).get();

            if (result.data()) {
                return { id: articleId, ...result.data() }
            }
        } catch (err) {
            console.log(err);
        }
    },
    delete: async (docId) => {
        try {
            const deleting = await CRUDoperations.delete("articles", docId)
            return 'done'

        }
        catch (err) {
            throw err

        }

    },
    update: async (docId, data) => {

        try {
            const updating = await CRUDoperations.update("articles", docId, data)
            return updating




        } catch (err) {
            return err;
        }
    },
    fetchLatest: async () => {
        try {
            const getAllArticles = await FirebaseApi['articles'].orderBy("datePublished", "desc").get()

            const allArticles = getAllArticles.docs.map(recipe => ({ ...recipe.data(), id: recipe.id }))
            return allArticles
        } catch (error) {
            throw error
        }
    },

    search: async (options) => {
        console.log(options);
        try {
            const value = await CRUDoperations.search('articles', options);
            return value;

        } catch (err) {
            throw err;
        }
    },
    customSearch: async (options) => {
        try {
            const result = await CRUDoperations.suggestSearch('articles', options);
            return result;
        } catch (err) {
            throw err;
        }
    }

}

export default articles;