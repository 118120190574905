import React,{useState,useContext} from 'react'
import {Grid,Typography,Box,Chip} from '@mui/material'
import AddRecipeContext from '../../../../config/AddRecipeContext'
import {makeStyles} from '@mui/styles'

//icons
import DeleteIcon from '@mui/icons-material/Delete';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Ramen from '../../../../assets/ramen@2x.png'


// images
import EmptyIngredients from '../../../../assets/AddRecipe/emptyIngredients.png'


const useStyles = makeStyles({
    stepDuration:{
        flex:1,
        zIndex:3,
    },
    stepDurationUnit:{
        
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
       '&[data-selected=true]':{
           color:'#F94C0C'
       }
        // '&[data-durationUnit=true]':{
        //     border:'2px solid #F94C0C',
        //     borderRadius:7
        // }
    },
    stepTemperature:{
        flex:1,
        transform: "scale(1)",
        border:"none",
        zIndex:3,
        // borderRadius:8,
        // border: "1px solid gray",
        // '&[data-temperature=true]':{
        //     transform:"scale(1.4)",
        //     // padding:20,
        //     backgroundColor: 'white',
        //     border: "1px solid #F94C0C",
        //     borderRadius:8
        // },
        
        
    },
    
    emptyStepTemperatureElement:{
        color:'gray',
        transition:'transform .2s ease-in-out',
        zIndex:3,
        
       
        
    },
    emptyStepDurationUnit:{
        flex:1,
        textAlign:'center',
        color:'gray',
    }
})

const RenderEmptyStepDetails = (props) => {

    const classes = useStyles()
    const [durationLeftValue,setDurationLeftValue] = useState(0)

    const {formik,proMode} = useContext(AddRecipeContext)
    const {values,setFieldValue} = formik
    const details = {

    
        parameters:()=>{
            return (
                <Grid container spacing={3} style={{height:'100%'}} >
                    <Grid item xs={12} container spacing={1} >
                        <Grid item >
                            <Typography color="#D9D9D9">Temperature</Typography>
                        </Grid>
                        <Grid item xs={12} >
                            <Box style={{position:'relative'}}>

                            
                                <Grid   
                                    container 
                                    direction="row" 
                                    alignItems="center"
                                    border="1px solid #D9D9D9"
                                    borderRadius={1}
                                
                                >
                                    <Grid  item  data-temperature={true}    className={classes.stepTemperature} >
                                        <Typography  textAlign="center"    className={classes.emptyStepTemperatureElement} >OFF</Typography>
                                    </Grid>
                                    <Grid item  data-temperature={false}    className={classes.stepTemperature} >
                                        <Typography  textAlign="center"   className={classes.emptyStepTemperatureElement}>LOW</Typography>
                                    </Grid>
                                    <Grid item  data-temperature={false}    className={classes.stepTemperature}>
                                        <Typography  textAlign="center"  className={classes.emptyStepTemperatureElement} >MID</Typography>
                                    </Grid>
                                    <Grid item   data-temperature={false}    className={classes.stepTemperature} >
                                        <Typography   textAlign="center"  className={classes.emptyStepTemperatureElement}>HIGH</Typography>
                                    </Grid>
                                </Grid>

                                <Box   style={{position:'absolute',top:-7,left:'0%',height:40,width:'25%',border:"1px solid #D9D9D9",transition:'.3s ease-in-out',zIndex:2,borderRadius:8,backgroundColor:'white'}}>

                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container spacing={1} >
                        <Grid item>
                            <Typography color="#d9d9d9">Duration(optional)</Typography>
                        </Grid>
                        <Grid item xs={12} container spacing={2} >
                            <Grid item xs={4}>
                                <input type="text" disabled style={{width:'100%',border:'1px solid #d9d9d9'}} />
                            </Grid>
                            <Grid item xs={6} >
                                <Box style={{position:'relative'}} >

                                    <Grid container direction="row" alignItems="center" border="1px solid #D9D9D9" borderRadius={2}>

                                        <Grid className={classes.stepDuration} item >
                                            <Typography data-durationunit={true} data-selected={durationLeftValue===0} className={classes.emptyStepDurationUnit}>sec</Typography>
                                        </Grid>
                                        <Grid className={classes.stepDuration} item >
                                            <Typography data-durationunit={false} data-selected={durationLeftValue===69} className={classes.emptyStepDurationUnit}>min</Typography>
                                        </Grid>
                                        <Grid className={classes.stepDuration} item > 
                                            <Typography data-durationunit={false} data-selected={durationLeftValue===138} className={classes.emptyStepDurationUnit}>hour</Typography>
                                        </Grid>
                                    </Grid>
                                    <Box style={{position:'absolute',top:0,left:`${durationLeftValue}%`,border:"1px solid #D9D9D9",width:'33.3%',height:'100%',transition:'.2s ease-in-out',borderRadius:8,zIndex:2,backgroundColor:'white'}}>

                                    </Box>
                                </Box>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={12} container spacing={1}>
                        <Grid item>
                            <Typography color="#d9d9d9">TOOLS USED</Typography>
                        </Grid>
                        <Grid item xs={12}>
                        

                            <Grid container  direction="row" alignItems="center" border="1px solid #D9D9D9" borderRadius={1}>
                                <Grid item xs={1} container style={{height:'100%'}} >
                                    <Box  style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',padding:10}}>


                                    <SearchOutlinedIcon style={{color:'#d9d9d9'}} />
                                    </Box>
                                
                                </Grid>
                                <Grid item xs={11} >
                                    <input type="text" disabled style={{border:'none',outline:'none',width:'100%'}}  />
                                </Grid>
                            </Grid>
                            
                            
                            
                        </Grid>
                        <Grid item xs={12} spacing={1} container direction="row" alignItems="center" flexWrap="wrap">
                            {['Tool name one','Two','Tool name Three','Tool name Four'].map((item,index)=>(
                                <Grid item key={index}>
                                    <Chip label={item} variant="outlined" disabled />
                                </Grid>
                            ))}
                            
                        </Grid>
                    </Grid>
                </Grid> 
            )
        },
        ingredients:()=>{

            return(
                <Grid container direction="row" justifyContent="center" alignItems="center" style={{height:'100%'}} >
                    <Grid item>
                        <Box style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>

                            <img src={EmptyIngredients} style={{width:'80%',height:'100%',margin:'auto'}} />
                        </Box>

                    </Grid>
                </Grid>
            )

        },
        images:()=>{

            return (
                <Grid container direction="column" justifyContent="space-around" alignItems="center" style={{height:'100%'}} >
                    <Grid item >
                        <Box style={{width:153,height:153}}>

                        <img src={Ramen} style={{width:'100%',height:'100%'}} />
                        </Box>
                    </Grid>
                    <Grid item > 
                        <Box >
                            
                            <label htmlFor="images">
                                <Box className={classes.imageAddBtn}>
                                    <Typography className={classes.imgAddBtnText}>Add Step Images</Typography>
                                </Box>
                            </label>
                            <input id="images" type="file" style={{display:'none'}} />

                        </Box>
                    </Grid>
                </Grid>
            )

        },
    }

    return <>{details[props.detailsOf]()} </>

}



export default RenderEmptyStepDetails