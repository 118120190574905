import React, { useEffect, useContext, useState } from 'react'
import { Grid } from '@mui/material';
import AppContext from '../../../config/AppContext';
import CloudServices from '../../../cloud-services-core';
import CreatorInfo from '../EditorComponents/CreatorInfo';
import FeaturedPosts from '../EditorComponents/FeaturedPosts';
import { ChefInfoNavbar } from './Styles';
import CreatorInfoSide from '../EditorComponents/CreatorInfoSide';
import RandomCard from '../EditorComponents/RandomCard';

const ChefInfo = (props) => {
  const AppState = useContext(AppContext);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [memberLevel, setMemberLevel] = useState(false);
  const [userData, setUserData] = useState(AppState.user);

  const [layoutDetails, setLayoutDetails] = useState([]);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  console.log(props);
  const getUserDetails = async () => {
    const id = props.match.params?.id;
    let value = await CloudServices.user.getUserDetails(id);
    let layout = await CloudServices.creatorUILayouts.fetchByCreatorId(id);
    console.log(value, layout);
    setUserData(value);
    setLayoutDetails(layout[0]);
  }

  useEffect(() => {
    // getUserDetails();
    AppState.handleNavbar(false);
    window.addEventListener("scroll", handleScroll);
    return () => {
      AppState.handleNavbar(true);
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  window.onScroll = function() {scrollFunction()};

  function scrollFunction() {
    if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
      document.getElementById("navbar").style.top = "0";
    } else {
      document.getElementById("navbar").style.top = "-50px";
    }
  }

  // userData = 
  return (
    <Grid container direction='column'>
      <Grid item>
        <CreatorInfo />
      </Grid>
      <Grid item container style={{ height: '100vh' }}>
        <Grid item container xs={10} style={{ overflowY: 'auto', height: 'inherit', '&::-webkit-scrollbar': {
          display: 'none',
          width: '1px',
        }}}>
          {/* <ChefInfoNavbar id='navbar'>
            <a href="#home">Home</a>
            <a href="#news">News</a>
            <a href="#contact">Contact</a>
          </ChefInfoNavbar> */}
          <FeaturedPosts />
          <RandomCard />
        </Grid>
        <Grid item xs={2} style={{ height: 'inherit' }}>
          <CreatorInfoSide />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ChefInfo