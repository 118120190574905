//react imports
import React, { useState } from 'react'

//material imports
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import EditIcon from '@mui/icons-material/Edit';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
// import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';

//entity stylings
import useStyles from './EntityStyling'

//link imports
import { Link } from 'react-router-dom'

// entities values imports
import { dependentEntities, pureEntities } from './EntitiesValues'
import { DeleteOutline } from '@material-ui/icons'

export default function (props) {
    const { currentEntityCategory, rowsCount, Entity, addEntity, editEntity, deleteEntity } = props;
    const classes = useStyles();
    // const history = useHistory();

    const [selectedEntityCategory, setSelectedEntityCategory] = useState(currentEntityCategory)

    const changeSelectedEntityCategory = (e) => {
        setSelectedEntityCategory(e.target.value);
    }

    // const downloadCSV = () => {
    //     history.push('/downloadCSV');
    // }

    return (
        <Box className={classes.addRecipeContainer} >
            <Box className={classes.addRecipeNavContainer} >
                <Container maxWidth="xl" >
                    <Grid container display='flex' flexDirection='column'>
                        <Grid display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                            <Grid item xs={2} padding="10px" display="flex" flexDirection="row" alignItems='center'>
                                <Box >
                                    <select value={selectedEntityCategory} className={classes.entityType} placeholder={currentEntityCategory} defaultValue={`Entity: ${currentEntityCategory}`} onChange={changeSelectedEntityCategory}  >
                                        <option value={'dependent'}> Dependent </option>
                                        <option value={'pure'} > Pure</option>
                                    </select>
                                </Box>
                                <Button style={{ marginLeft: 10, border: '1px solid white', borderRadius: 4 }} variant="contained" onClick={() => addEntity()} className={classes.enabledButton} disabled={rowsCount.length !== 0}  >
                                    <ControlPointIcon style={{ color: 'white' }} />
                                </Button>
                            </Grid >
                            <Grid item padding="10px" display='flex' flexDirection="row" alignItems="center" >
                                {/* <Button onClick={downloadCSV} style={{ marginLeft: 5, border: '1px solid white', borderRadius: 4 }} variant="contained">
                                    <DownloadIcon style={{ color: 'white' }} />
                                </Button> */}
                                <Button onClick={editEntity} style={{ marginLeft: 5, border: '1px solid white', borderRadius: 4 }} variant="contained" disabled={rowsCount.length !== 1}>
                                    <EditIcon style={{ color: 'white' }} />
                                </Button>
                                <Button style={{ marginLeft: 5, border: '1px solid white', borderRadius: 4 }} variant="contained" onClick={deleteEntity} disabled={rowsCount.length === 0}>
                                    <DeleteOutline style={{ color: 'white'}} />
                                </Button>
                            </Grid>
                        </Grid >
                        <Grid item columnSpacing={1} container direction="row" justifyContent='center' style={{ marginTop: 10 }}>
                            {selectedEntityCategory === 'dependent' ? dependentEntities.map((entity, index) => (
                                <Grid item xs={2} md={2} lg={1.5} key={index}>
                                    <Link to={`/entities/dependent/${entity.db}`} style={{ textDecoration: "none"}}>
                                        <Box className={classes.entityContainer} data-currentEntity={entity.db === Entity} >
                                            <Typography textAlign="center" style={{ fontSize: 16 }}>{entity.heading}</Typography>
                                        </Box>
                                    </Link>
                                </Grid>
                            )) : pureEntities.map((entity, index) => (
                                <Grid xs={1.5} md={1.5} lg={1.5} display="grid" alignItems="center" gridRow={1} item key={index}  >
                                    <Link to={`/entities/pure/${entity.db}`} style={{ background: "none", textDecoration: "none" }}>
                                        <Box className={classes.entityContainer} data-currentEntity={entity.db === Entity}>
                                            <Typography textAlign="center" style={{ fontSize: 16 }}>{entity.heading}</Typography>
                                        </Box>
                                    </Link>
                                </Grid>
                            ))
                            }

                        </Grid>
                    </Grid >
                </Container >
            </Box >
        </Box >
    )
}