import React, { useEffect, useContext, useState } from 'react'
import { Typography, Grid } from '@mui/material';
import AppContext from '../../../config/AppContext';
import CreatorNavbar from '../Common/CreatorNavbar';
import ContentWriting from '../../../assets/CreatorScreen/ContentWriting.png'
import menu from '../../../assets/CreatorScreen/menu.png'
import RecBoo from '../../../assets/CreatorScreen/RecBoo.png'
import { 
    EarningsTable, 
    PublishedCollectionsTable, 
    SubscriptionsTable 
} from './PublishedCollectionsTable';
import { ChartsBox, DashBoardLink, ProfileBox } from '../Styles';
import { 
    RecipePublishedSortBy, 
    SubscribersJoinedManageMemberShipLevels,
    EarnedSubscriptionInPrevMonth 
} from './HeaderBadges';
import Charts from './Charts';
import useDashBoardHook from './useDashBoardHook';
import { EarningsData, RecipeData, SubscriberData } from './initialValues';
import ProfileDashBord from './ProfileDashBoard';

const CreatorsPage = () => {
    const AppState = useContext(AppContext);
    const { states } = useDashBoardHook();
    const [currentTab, setCurrentTab] = useState('recipePublished');

    const handleCurrentTab = (value) => {
        setCurrentTab(value);
    }

    useEffect(() => {
        AppState.handleNavbar(false);
        return () => {
            AppState.handleNavbar(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    console.log(AppState.recipes);

    return (
        <Grid container spacing={2} marginTop={3}>
            <Grid item container xs={2}>
                <CreatorNavbar currentScreen="Dashboard" />
            </Grid>
            <Grid item container direction='column' spacing={2} xs={10}>
                <Grid item>
                    <Typography variant="h6" style={{ fontWeight: 600, textTransform: 'uppercase' }} >
                        welcome back, { (states.firstName + ' ' + states.lastName) || 'Creator'}
                    </Typography>
                    <Typography>
                        Here's your data analytics in a nutshell.
                    </Typography>
                </Grid>
                <Grid item container style={{ marginTop: 20 }}>
                    <Grid item container xs={8.5} direction='row'>
                        <Grid item container direction='row' justifyContent="space-between" spacing={4} style={{ marginLeft: 10 }}>
                            <Grid item container spacing={2} xs={8}>
                                <DashBoardLink  onClick={() => handleCurrentTab('recipePublished')}
                                    // style={currentTab === 'recipePublished' && { background: '#F4F5F7'}}
                                    >
                                    <Grid marginLeft={2} marginTop={3}>
                                        <img src={RecBoo} style={{ height: 40, width: 40 }} alt="" />
                                        <Typography item marginTop={2}>
                                            {states.recipeDrafts.length || 0}
                                        </Typography>
                                        <Typography item style={{ fontSize: 11}}>
                                            Recipes Published
                                        </Typography>
                                    </Grid>
                                </DashBoardLink>
                                <DashBoardLink  onClick={() => handleCurrentTab('subscribersJoined')}>
                                    <Grid marginLeft={2} marginTop={3}>
                                        <img src={ContentWriting} style={{ height: 40, width: 40 }} alt="" />
                                        <Typography item marginTop={2}>
                                            {states.articleDrafts.length || 0} {/* subscriber count has included*/}
                                        </Typography>
                                        <Typography item style={{ fontSize: 11}}>
                                            Subscribers Joined
                                        </Typography>
                                    </Grid>
                                </DashBoardLink>
                                <DashBoardLink  onClick={() => handleCurrentTab('earnedBySubscription')}>
                                    <Grid marginLeft={2} marginTop={3}>
                                        <img src={menu} style={{ height: 40, width: 40 }} alt="" />
                                        <Typography item marginTop={2}>
                                            Rs. {states.articleDrafts.length || 0} {/*earned money has to reflect */}
                                        </Typography>
                                        <Typography item style={{ fontSize: 11}}>
                                            Earned via Subscriptions
                                        </Typography>
                                    </Grid>
                                </DashBoardLink>
                            </Grid>
                            {/* button links to different field */}
                            <Grid item xs={4}>
                                {currentTab === 'recipePublished' && (<RecipePublishedSortBy />)}
                                {currentTab === 'subscribersJoined' && (<SubscribersJoinedManageMemberShipLevels />)}
                                {currentTab === 'earnedBySubscription' && (<EarnedSubscriptionInPrevMonth />)}
                            </Grid>
                        </Grid>
                        <Grid item>
                            <ChartsBox>
                                {currentTab === 'recipePublished' && (<Charts data={RecipeData} />)}
                                {currentTab === 'subscribersJoined' && (<Charts data={SubscriberData} />)}
                                {currentTab === 'earnedBySubscription' && (<Charts data={EarningsData} />)}
                            </ChartsBox>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <ProfileBox>
                            <ProfileDashBord />
                        </ProfileBox>
                    </Grid>
                </Grid>
                <Grid item>
                    {currentTab === 'recipePublished' && <PublishedCollectionsTable data={states.totalItems} />}
                    {currentTab === 'subscribersJoined' && <SubscriptionsTable data={states.totalItems} />}
                    {currentTab === 'earnedBySubscription' && <EarningsTable data={states.totalItems} />}
                </Grid>
            </Grid>
        </Grid >
    )
}

export default CreatorsPage;