import React, { useContext, useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Modal from '@mui/material/Modal'
import { makeStyles } from '@mui/styles'

//icons
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import InfoIcon from '@mui/icons-material/Info';

import { FieldArray, FormikProvider } from 'formik';
import AddRecipeContext from '../../../config/AddRecipeContext'

import generateId from '../../../components/GenerateId';

// icons
import sunActive from '../../../assets/AddRecipe/sunActive.svg'
import sunInActive from '../../../assets/AddRecipe/sunInActive.svg'
import moonActive from '../../../assets/AddRecipe/moonActive.svg'
import moonInActive from '../../../assets/AddRecipe/moonInActive.svg'
import sunriseActive from '../../../assets/AddRecipe/sunriseActive.svg'
import sunriseInActive from '../../../assets/AddRecipe/sunriseInActive.svg'
import sunsetActive from '../../../assets/AddRecipe/sunsetActive.svg'
import sunsetInActive from '../../../assets/AddRecipe/sunsetInActive.svg'
import Upload from '../../../assets/upload.svg'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

// components
import RecipeCard from '../../../components/AddRecipe/RecipeCard'


// cloud services
import CloudServices from '../../../cloud-services-core'
import { Button, Chip } from '@mui/material'
import { FirebaseApi } from '../../../firebaseConfig'
import AppContext from '../../../config/AppContext'



const useStyles = makeStyles((theme) => ({
    tags: {
        padding: "3px 10px",
        borderRadius: 24,
        display: 'flex',
        flexDirection: 'row',
        alignItems: "center",
        transition: ' .5s ease-in-out',
        backgroundColor: '#e6e6e6'
    },
    tagTextField: {
        border: 'none',
        outline: 'none',
        backgroundColor: 'transparent',
        padding: 5,
    },
    tagClose: {
        margin: 0,
        transition: '.5s linear',
        cursor: 'pointer'

    },
    difficultyTypeContainer: {
        width: '100%',
        height: 30,
        backgroundColor: 'transparent',
        borderRadius: 6,
        transform: 'scale(1)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        transition: "transform 0.2s ease-in",
        border: "none",
        '&[data-difficultytype=true]': {
            backgroundColor: 'white',
            transform: 'scale(1.25)',
            border: "1px solid #F94C0C"
        }
    },
    addKeyword: {

        width: 120,
        border: '1px solid black',
        borderRadius: 24,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: 5,
        cursor: 'pointer'

    },


}))


export default function MetaData(props) {
    const AppState = useContext(AppContext)
    // context
    const { formik, images, setUploadImages, proMode } = useContext(AddRecipeContext)
    const [imagesLoading, setImagesLoading] = useState(false)

    const [keywordOnHover, setKeywordOnHover] = useState(null)

    const classes = useStyles()

    const [difficultyType, setDifficultyType] = useState("EASY")
    // cuisine data
    const [cuisineList, setCuisineList] = useState([])
    // diets data
    const [dietList, setDietList] = useState([])

    const { values, handleChange, setFieldValue } = formik




    useEffect(() => {
        getCuisinesList()
        getDietsList()



        if (typeof values.keywords === 'string') {
            const keywords = String(values?.keywords).split(",")
            setFieldValue('keywords', keywords)

        }


    }, [])


    const getCuisinesList = async () => {
        try {
            const cuisinesData = await CloudServices.cuisines.read()

            setCuisineList(cuisinesData)
        } catch (error) {
            console.log(error)
        }
    }
    const getDietsList = async () => {
        try {
            const diets = await CloudServices.diets.read()

            setDietList(diets)
        } catch (error) {
            console.log(error)
        }
    }






    const showCloseOnEnter = (index) => {

        setKeywordOnHover(index)

    }
    const difficultyTypes = [
        {
            name: 'EASY',
            left: false,
            right: true,
        },
        {
            name: 'MEDIUM',
            left: true,
            right: true
        },
        {
            name: 'HARD',
            left: true,
            right: false
        }
    ]




    const changeDifficultyType = (type) => {
        setDifficultyType(type);
        setFieldValue('difficultyType', type)
    }

    const selectMealType = (type) => {
        const mealTypes = values.mealType
        var newMealTypes = []
        if (mealTypes) {

            if (mealTypes.includes(type)) {
                newMealTypes = mealTypes.filter(mealType => mealType !== type)
            }
            else {
                newMealTypes.push(...mealTypes, type)
            }
            setFieldValue('mealType', newMealTypes)
        } else {
            setFieldValue('mealType', [type])

        }


    }

    const checkBoxOnChange = (e, type, selectedType) => {
        const typeValues = values[type]

        console.log(e, type, typeValues)

        if (typeValues) {

            if (e.target.checked) {

                typeValues.push(selectedType.name)
                formik.setFieldValue(`${type}`, typeValues)

            }
            else {

                const newValues = typeValues.filter(type => type !== selectedType.name)



                formik.setFieldValue(`${type}`, newValues)
            }
        }
        else {
            formik.setFieldValue(`${type}`, [selectedType.name])
        }

    }

    const selectPreferedDiets = (e, selectedDiet) => {

        checkBoxOnChange(e, "suitableForDiet", selectedDiet)

    }

    const selectCuisineTypes = (e, selectedCuisine) => {

        checkBoxOnChange(e, "recipeCuisine", selectedCuisine)
        console.log(values)
    }

    const searchRecipes = () => {

    }

    const saveAfterRecipeName = () => {


        if (values.name.length > 0 && !localStorage.getItem('drafted') && !values.id) {
            // console.log(props.props,props.history,props.location,props.match,typeof props)

            FirebaseApi['drafts'].add(JSON.parse(JSON.stringify({ recipe: { ...values, proMode }, recipeState: "entryDraft", dateAdded: new Date() })))
                .then((data) => {
                    console.log(data.id)
                    setFieldValue('id', data.id)
                    localStorage.setItem('drafted', JSON.stringify({ id: data.id }))
                    localStorage.setItem('saveLocally', JSON.stringify({ ...values, id: data.id, proMode, editedOn: new Date() }))

                    props.history.push(`/addRecipe/${data.id}?collection=drafts`)

                })
                .catch((error) => {

                    console.log(error)
                })
        }
        else {
            console.log("empty")
        }
    }


    const renderFlow = (name) => {
        switch (name) {
            case 'basic':
                return (
                    <Grid container spacing={2} columnSpacing={{ md: 5 }} >
                        <Grid item md={6} xs={12}  >

                            <Grid container spacing={2} direction="column" justifyContent="space-between"   >
                                <Grid item md={12} xs={12} container spacing={1} direction="column" >
                                    <Grid item>
                                        <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', }}>RECIPE NAME</Typography>

                                    </Grid>
                                    <Grid item>
                                        <input onBlur={() => saveAfterRecipeName()} placeholder="Enter your recipe name" type="text" value={values.name} id="name" onChange={handleChange} style={{ padding: 16, borderRadius: 8, width: '100%', border: "1px solid gray" }} />

                                    </Grid>



                                </Grid>
                                <Grid item spacing={1} container md={12} xs={12} direction="column"  >
                                    <Grid item>


                                        <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', }}>DESCRIPTION</Typography>
                                    </Grid>
                                    <Grid item>

                                        <TextareaAutosize id="description" onChange={handleChange} value={values.description} minRows={6} style={{ width: '100%', resize: 'none', border: '1px solid gray', outline: 'none', borderRadius: 12, padding: 20 }} />
                                    </Grid>

                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container direction="row" columnSpacing={1} alignItems="center">
                                        <Grid item md={3} xs={4} container spacing={1} direction="column" justifyContent="center">
                                            <Grid item>
                                                <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', }}>PREP TIME</Typography>

                                            </Grid>
                                            <Grid item>
                                                <TextField type="text" id="prepTime" onChange={handleChange} value={values.prepTime} />
                                            </Grid>

                                        </Grid>
                                        <Grid item md={3} xs={4} container spacing={1} direction="column" justifyContent="center">
                                            <Grid item>
                                                <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', }}>COOK TIME</Typography>

                                            </Grid>
                                            <Grid item>
                                                <TextField type="text" id="cookTime" onChange={handleChange} value={values.cookTime} />
                                            </Grid>

                                        </Grid>
                                        <Grid item md={3} xs={4} container spacing={1} direction="column" justifyContent="center">
                                            <Grid item>
                                                <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', }}>SERVING SIZE</Typography>

                                            </Grid>
                                            <Grid item>
                                                <TextField type="text" name="servings" onChange={handleChange} value={values.servings} />
                                            </Grid>

                                        </Grid>

                                    </Grid>


                                </Grid>

                                <Grid item xs={12} container >
                                    <Grid item xs={12} container direction="row" alignItems="center" justifyContent="space-between">


                                        <Grid item>
                                            <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', }}>IMAGES</Typography>

                                        </Grid>
                                        <Grid item>

                                            <InfoIcon />
                                        </Grid>

                                    </Grid>
                                    <Grid item xs={12} container>
                                        <Grid item xs={3}>
                                            <div style={{ width: 124, height: 124, backgroundColor: 'white', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', border: '1px solid gray', borderRadius: 10 }}>
                                                <img src={Upload} onClick={() => values.name.length > 0 && setUploadImages(true)} />

                                            </div>

                                        </Grid>
                                        <Grid item xs={9} container style={{ overflow: 'hidden' }} >

                                            <Box style={{ width: '40vw', display: 'flex', flexDirection: 'row', alignItems: 'center', overflowX: 'scroll', flexWrap: 'nowrap' }}>
                                                {/* {console.log(values.images)} */}
                                                {[...Object.keys(values?.images)].map((file, index) => {

                                                    return (

                                                        <img key={index} src={values.images[file]} style={{ width: 124, height: 124, borderRadius: 8, flexShrink: 0, marginRight: 10, cursor: 'pointer' }} />

                                                    )
                                                })}

                                            </Box>




                                        </Grid>

                                    </Grid>
                                </Grid>

                                <Grid item xs={12} style={{ maxHeight: '8vh', }} >

                                    <Grid container direction="row" alignItems="center"  >

                                        <Grid item sm={6} xs={5}  >
                                            <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', }}>DIFFICULTY</Typography>
                                        </Grid>
                                        <Grid item sm={6} xs={7}    >
                                            <Grid container direction="row" alignItems="center" style={{ border: "1px solid #CDCDCD", borderRadius: 6 }}  >
                                                {difficultyTypes.map((type, index) => (
                                                    <Grid key={index} item onClick={() => changeDifficultyType(type.name)} style={{ flex: 1, cursor: 'pointer' }} >
                                                        <div className={classes.difficultyTypeContainer} data-difficultytype={difficultyType === type.name} style={{

                                                        }}>

                                                            <Typography style={{ textAlign: 'center', color: difficultyType === type.name ? '#F94C0C' : 'black', fontSize: 14, cursor: 'pointer' }} >{type.name}</Typography>
                                                        </div>

                                                    </Grid>

                                                ))}

                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Grid container spacing={1} direction="column" >
                                        <Grid item>
                                            <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', }}> PAIRINGS</Typography>

                                        </Grid>
                                        <Grid item>
                                            <Grid container columnSpacing={3} direction="row" justifyContent="flex-start" alignItems="center">

                                                <Grid item md={6}>
                                                    <TextField id="searchRecipes" style={{ width: '100%' }} placeholder="enter url" onChange={searchRecipes} />
                                                    <datalist htmlFor="searchRecipes">
                                                        { }

                                                    </datalist>

                                                </Grid>
                                                <Grid item md={6}>
                                                    <Grid container columnSpacing={2} >
                                                        {[1, 2, 3].map((item) => (
                                                            <Grid item key={item}  >
                                                                <div style={{ width: 50, height: 50, backgroundColor: 'gray', borderRadius: 10 }} />


                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </Grid>


                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>

                        </Grid>
                        <Grid item md={6} xs={12}  >
                            <Grid container spacing={2} direction="column" justifyContent="space-between"   >
                                <Grid item container direction="column" spacing={1}>
                                    <Grid item>

                                        <Typography style={{ fontSize: 17, fontWeight: 'bold' }}>MEAL TYPE(multi-select)</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Box
                                            style={{
                                                border: '1px solid gray',
                                                borderRadius: 5,
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-around'
                                                , alignItems: 'center',
                                                maxHeight: '6vh',
                                                padding: "20px 0",
                                                backgroundColor: 'white'
                                            }}>
                                            <img src={values.mealType?.includes('breakfast') ? sunriseActive : sunriseInActive} onClick={() => selectMealType('breakfast')} style={{ width: 30, cursor: 'pointer' }} />
                                            <img src={values.mealType?.includes('lunch') ? sunActive : sunInActive} onClick={() => selectMealType('lunch')} style={{ width: 30, cursor: 'pointer' }} />
                                            <img src={values.mealType?.includes('snacks') ? sunsetActive : sunsetInActive} onClick={() => selectMealType('snacks')} style={{ width: 30, cursor: 'pointer' }} />
                                            <img src={values.mealType?.includes('dinner') ? moonActive : moonInActive} onClick={() => selectMealType('dinner')} style={{ width: 30, cursor: 'pointer' }} />

                                        </Box>

                                    </Grid>
                                </Grid>
                                <Grid item container spacing={1} direction="column">
                                    <Grid item md={12}>
                                        <Typography style={{ fontSize: 17, fontWeight: 'bold' }}>PREFERED DIETS </Typography>
                                    </Grid>
                                    <Grid item md={12} container >
                                        {dietList.map((diet, index) => (

                                            <Grid key={index} item md={3}>
                                                <Grid container direction="row" alignItems="center">

                                                    <Checkbox onChange={(e) => selectPreferedDiets(e, diet)} checked={values.suitableForDiet?.includes(diet.name)} />
                                                    <Typography>{diet.name}</Typography>
                                                </Grid>
                                            </Grid>
                                        ))}

                                    </Grid>

                                </Grid>

                                <Grid item container rowSpacing={1} >
                                    <Grid item md={12}>
                                        <Typography style={{ fontSize: 17, fontWeight: 'bold' }} > CUISINE TYPES</Typography>
                                    </Grid>
                                    <Grid item md={12}>
                                        <Grid container>
                                            {cuisineList.map((cuisine, index) => (

                                                <Grid key={index} item md={3}>
                                                    <Grid container direction="row" alignItems="center">

                                                        <Checkbox onChange={(e) => selectCuisineTypes(e, cuisine)} checked={values.recipeCuisine?.includes(cuisine.name)} />
                                                        <Typography >{cuisine.name}</Typography>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>

                                </Grid>

                                <Grid item>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography style={{ fontSize: 17, fontWeight: 'bold' }}>General Tags</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormikProvider value={formik}>


                                                <FieldArray name="keywords">
                                                    {(keywordHelpers) => {
                                                        const { push, remove, insert } = keywordHelpers


                                                        return (
                                                            <Grid container spacing={2} direction="row" alignItems="center">
                                                                <Grid item>
                                                                    <Box
                                                                        className={classes.addKeyword}
                                                                        onClick={() => insert(values.keywords.length + 1, "sample")}
                                                                    >
                                                                        <ControlPointIcon />
                                                                        <Typography >Add new</Typography>

                                                                    </Box>

                                                                </Grid>
                                                                {
                                                                    values.keywords && typeof values.keywords === 'object' && values.keywords?.map((item, keywordIndex) => (
                                                                        <Grid item key={keywordIndex}>

                                                                            <div className={classes.tags} key={keywordIndex} onMouseEnter={() => showCloseOnEnter(keywordIndex)} onMouseLeave={() => setKeywordOnHover(null)} >
                                                                                <input className={classes.tagTextField} type="text" id={`keywords[${keywordIndex}]`} value={item} onChange={handleChange} />
                                                                                {keywordOnHover === keywordIndex && <p className={classes.tagClose} onClick={() => remove(keywordIndex)}>X</p>}
                                                                            </div>
                                                                        </Grid>



                                                                    ))

                                                                }
                                                            </Grid>
                                                        )

                                                    }}


                                                </FieldArray>
                                            </FormikProvider>


                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>



                        </Grid>
                    </Grid>
                )
            case 'pro':
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={6} container spacing={2} justifyContent="flex-start" >
                            <Grid item xs={12} container spacing={2}>
                                <Grid item xs={12} >
                                    <Typography style={{ fontSize: 32 }}>{(values.name)?.toUpperCase()}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>{values.description}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container spacing={1}>
                                <Grid item xs={4} container direction="row" spacing={1}>
                                    <Grid item>
                                        <StarIcon style={{ fontSize: 32 }} />
                                    </Grid>
                                    <Grid item>
                                        <StarIcon style={{ fontSize: 32 }} />
                                    </Grid>
                                    <Grid item>
                                        <StarBorderIcon style={{ fontSize: 32 }} />
                                    </Grid>
                                </Grid>
                                <Grid item xs={8} container spacing={1} alignItems="center">
                                    <Grid item >
                                        <RestaurantIcon style={{ fontSize: 32 }} />
                                    </Grid>
                                    <Grid item >
                                        <Typography>{`Serves - ${values.servings}`}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={1} alignItems="center">
                                    <Grid item >
                                        <AccessTimeIcon style={{ fontSize: 32 }} />
                                    </Grid>
                                    <Grid item>

                                        <Typography>{`Prep ${(values.prepTime.split("T")[1])?.split('M')[0]} mins | Cook ${(values.cookTime.split("T")[1])?.split('M')[0]} mins`}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container spacing={1}>


                                <Grid item >

                                    <Typography>IMAGES</Typography>
                                </Grid>

                                <Grid item xs={12} container style={{ overflow: 'hidden' }} >
                                    <Grid item xs={3}>
                                        <div style={{ width: 124, height: 124, backgroundColor: 'white', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', border: '1px solid gray', borderRadius: 10 }}>

                                            <img src={Upload} onClick={() => setUploadImages(true)} />

                                        </div>

                                    </Grid>
                                    <Grid item xs={9} container style={{ overflow: 'hidden' }} >

                                        <Box style={{ width: '40vw', display: 'flex', flexDirection: 'row', alignItems: 'center', overflowX: 'scroll', flexWrap: 'nowrap' }}>

                                            {[...Object.keys(values.images)].map((file, index) => {

                                                return (


                                                    <img key={index} src={values.images[file]} style={{ width: 124, height: 124, borderRadius: 8, flexShrink: 0, marginRight: 10, cursor: 'pointer' }} />

                                                )
                                            })}

                                        </Box>




                                    </Grid>

                                    {/* <Box style={{display:'flex',flexDirection:'row',alignItems:'center',overflowX:'scroll',flexWrap:'nowrap'}}>

                                    {images.map((file,index)=>(
                                        

                                        <img key={file.id} src={file.image} style={{width:124,height:124,borderRadius:8,flexShrink:0,marginRight:10,cursor:'pointer'}} />
                                        
                                    ))}

                                    </Box>     */}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container spacing={1}>
                                <Grid item>
                                    <Typography>PAIRINGS</Typography>
                                </Grid>
                                <Grid item container xs={12} direction="row" spacing={2}>
                                    {['0crqOyRRO5YVOqF3gxkE', '1EvXJExQ8or1IWv0bwBw'].map((item) => (
                                        <Grid item key={item} >

                                            <RecipeCard id={item} />
                                        </Grid>
                                    ))}

                                </Grid>
                            </Grid>



                        </Grid>
                        <Grid item xs={6}>
                            <Grid spacing={4} container>
                                <Grid item xs={12} container spacing={2} >
                                    <Grid item xs={12}>
                                        <Typography>MEAL TYPE</Typography>
                                    </Grid>
                                    <Grid item xs={12} container spacing={2}  >
                                        {values.mealType?.map(((item, index) => (
                                            <Grid item key={index}>

                                                <Chip label={item} />
                                            </Grid>
                                        )))}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container spacing={2}>
                                    <Grid item >
                                        <Typography>PREFERRED DIET</Typography>
                                    </Grid>
                                    <Grid item xs={12} container spacing={2} >
                                        {values.preferedDiets?.map((item, index) => (
                                            <Grid item key={index}>
                                                <Chip label={item} />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container spacing={2}>
                                    <Grid item >
                                        <Typography>CUISINE</Typography>
                                    </Grid>
                                    <Grid item xs={12} container spacing={2} >
                                        {values.cuisineTypes?.map((item, index) => (
                                            <Grid item key={index}>
                                                <Chip label={item} />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} container spacing={2}>
                                    <Grid item >
                                        <Typography>TAGS</Typography>
                                    </Grid>
                                    <Grid item xs={12}  >


                                        <FormikProvider value={formik}>


                                            <FieldArray name="keywords">
                                                {(keywordHelpers) => {
                                                    const { push, remove, insert } = keywordHelpers
                                                    return (
                                                        <Grid container spacing={2}>
                                                            <Grid item >
                                                                <Chip avatar={<AddCircleOutlineIcon />} label="Add New" onClick={() => insert(values.keywords.length + 1, "sample")} />
                                                            </Grid>
                                                            {values.keywords?.map((item, keywordIndex) => (
                                                                <Grid item key={keywordIndex}>
                                                                    <div className={classes.tags} key={keywordIndex} onMouseEnter={() => showCloseOnEnter(keywordIndex)} onMouseLeave={() => setKeywordOnHover(null)} >
                                                                        <input className={classes.tagTextField} type="text" id={`keywords[${keywordIndex}]`} value={item} onChange={handleChange} />
                                                                        {keywordOnHover === keywordIndex && <p className={classes.tagClose} onClick={() => remove(keywordIndex)}>X</p>}
                                                                    </div>
                                                                </Grid>
                                                            ))}
                                                        </Grid>

                                                    )
                                                }}

                                            </FieldArray>
                                        </FormikProvider>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )
        }
    }

    // return  (
    //     <Grid container  spacing={2} columnSpacing={{md:5}} style={{minHeight:'85vh'}}>

    //         {renderFlow(proMode ? 'pro' : 'basic')}
    //     </Grid>

    // )

    return renderFlow(proMode ? 'pro' : 'basic')
}
