import { Grid, Button, Typography, TextField, Paper } from '@mui/material'
import React, { useEffect, useContext, useState } from 'react'
import HeaderNavbar from '../Common/HeaderNavbar'
import CreatorNavbar from '../Common/CreatorNavbar'
import AppContext from '../../../config/AppContext'

import { Link } from 'react-router-dom';
import EarningsTable from './EarningsTable'
import Razorpay from "../../../razorpayConfig";

import {
    ArgumentAxis,
    ValueAxis,
    Chart,
    LineSeries,
    SplineSeries
} from '@devexpress/dx-react-chart-material-ui';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers';

const EarningsScreen = () => {

    const AppState = useContext(AppContext);
    const [totalEarnings, setTotalEarnings] = useState(null);
    const [value, setValue] = useState('');
    const [endDate, setEndDate] = useState('');
    useEffect(() => {
        AppState.handleNavbar(false)
        return () => AppState.handleNavbar(true)
    }, [])

    console.log('hello');
    const data = [
        { x: 1, y: 30 },
        { x: 2, y: 40 },
        { x: 3, y: 5 },
        { x: 4, y: 2 },
        { x: 5, y: 21 },
    ];


    console.log(Razorpay);

    return (
        <Grid style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>

            <CreatorNavbar currentScreen="Earnings" />

            <Grid style={{ width: '84%' }}>
                {/* <HeaderNavbar text="Earnings" /> */}

                <Grid style={{ display: 'flex', flexDirection: 'column', padding: 20 }}>
                    <Grid item style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                        <Grid item>

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Start Date"
                                    value={value}
                                    onChange={(newValue) => {
                                        setValue(newValue);
                                    }}
                                    renderInput={(params) => <TextField   {...params} />}
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="End Date"
                                    value={endDate}
                                    onChange={(newValue) => {
                                        setEndDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField  {...params} />}
                                />
                            </LocalizationProvider>

                        </Grid>
                        <Grid item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }} >
                                {/* <Paper style={{ height: '50%', width: 800 }} >
                                    <Chart
                                        data={data}
                                        style={{ height: '10%', width: 800 }}
                                    >
                                        <ArgumentAxis />
                                        <ValueAxis />
                                        <SplineSeries />
                                        <LineSeries valueField="y" argumentField="x" />
                                    </Chart>
                                </Paper> */}



                            </Grid>

                            <Grid item style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Grid item style={{ display: 'flex', flexDirection: 'column', padding: 8, width: 185, background: '#F7F7F7', marginBottom: 10 }}>

                                    <Typography item style={{ color: '#858080', textDecoration: 'underline' }} >
                                        Available for Payout
                                    </Typography>
                                    <Grid item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 5 }}>
                                        <Typography item variant='h6'>
                                            Rs {totalEarnings}

                                        </Typography>

                                        <Typography item style={{ color: '#858080', textDecoration: 'underline' }} >
                                            Setup
                                        </Typography>

                                    </Grid>


                                </Grid>

                                <Grid item style={{ display: 'flex', flexDirection: 'column', padding: 8, width: 185, background: '#F7F7F7', marginBottom: 10 }}>
                                    <Typography item style={{ color: '#858080' }} >
                                        MemberShipLevels
                                    </Typography>

                                    <Grid item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 5 }}>
                                        <Typography item variant='h6' style={{ color: '#858080' }} >
                                            ....

                                        </Typography>
                                        <Link to={'./managemember'} >
                                            <Typography item style={{ color: '#858080', textDecoration: 'underline' }} >
                                                View
                                            </Typography>
                                        </Link>

                                    </Grid>


                                </Grid>

                                <Grid item style={{ display: 'flex', flexDirection: 'column', padding: 8, width: 185, height: 50, background: '#F7F7F7' }}>


                                </Grid>


                            </Grid>


                        </Grid>

                    </Grid>

                    <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>


                        <EarningsTable totalEarnings={totalEarnings} setTotalEarnings={setTotalEarnings} />
                    </Grid>

                </Grid>

            </Grid>

        </Grid>

    )

}


export default EarningsScreen