import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { CSVLink } from 'react-csv'
import { FirebaseApi } from '../../firebaseConfig';

const DownloadCSV = () => {
    const [rowData, setRowData] = useState([]);
    const [file, setFile] = useState();
    const [fileData, setFileData] = useState([]);
    const [unCreatedData, setUnCreatedData] = useState([]);

    const header = [
        {label: 'ID', key: 'id'},
        {label: 'ingredientName', key: 'name'},
        // {label: 'Ingredient Steamable', key: 'ingredientSteamable'},
        // {label: 'Search Names', key: 'searchNames'},
        // {label: 'Ingredient Family', key: 'ingredientFamily'},
        // {label: 'Major Category', key: 'majorCategory'},
        // {label: 'Ingredient Category', key: 'ingredientCategories'},
        // {label: 'Physcial Category', key: 'physcialCategory'},
        // {label: 'Stickness', key: 'stickness'},
        // {label: 'Ingredient State', key: 'ingredientState'},
        // {label: 'Taste Profile', key: 'TasteProfile'},
        // {label: 'Cooking Sequence Category', key: 'CookingSequenceCategory'},
        // {label: 'Units', key: 'units'},
        // {label: "Prep Styles", key: "prepStyle"},
        // {label: 'Max Size (mm)', key: 'maxSize(mm)'},
        // {label: 'Storage Agent', key: 'storageAgent'},
        // {label: 'StorageTime at Low Humidity [<5] (hr)', key: 'storageAt5hr'},
        // {label: 'StorageTime at Medium Humidity [5-40] (hr)', key: 'storageAt5-40hr'},
        // {label: 'StorageTime at High Humidity [>40] (hr)', key: 'storageAt40hr'},
        // {label: 'Storage Relative Humidity', key: 'storageRelativeHumidity'},
        // {label: 'Storage Temp (C)', key: 'storageTemp(C)'},
        // {label: 'Storage Temp (C)', key: 'storageTemp(C)'},
        // {label: 'Density (g/cc)',key: 'density(g/cc)'},
        // {label: 'Small (g)',key: 'small(g)'},
        // {label: 'Small (g)',key: 'small(g)'},
        // {label: 'Medium (g)',key: 'medium(g)'},
        // {label: 'large (g)',key: 'large(g)'},
        // {label: 'Riku Compatible',key: 'rikuCompatible'},
        // {label: 'Riku Loading Category',key: 'rikuLoadingCategory'},
        // {label: 'Low Volume Filling Allowed',key: 'lowVolumeFillingAllowed'},
        // {label: 'Allowable error',key: 'allowableError'},
        // {label: 'Sticky with temp increase/ moisture',key: 'stickyWithTempIncrease/Moisture'},
        // {label: 'Loss/Gain after Prep',key: 'loss/GainAfterPrep'},
    ];

    // const ingredientsList = async () => {
    //     const dataFromDB = await FirebaseApi['ingredient'].get();
    //     const dataList = dataFromDB.docs.map((item)=> {
    //         return {
    //             id: item.id,
    //             ...item.data(),
    //         }
    //     })
    //     setRowData(dataList);
    // }

    // useEffect(() => {
    //     ingredientsList();
    // },[]);

    // console.log(rowData);
    const data = [
        // {name: 'almond', ingredientCategories: 'Nuts & Nut Butter', physicalCategory: 'Solid', density: '', units: ['piece', 'q.b', 'teaspoon', 'cup','tablespoon', 'gram'], prepStyle: '' },
        // {name: 'almond1', ingredientCategories: 'Nuts & Nut', density: '', units: ['piece', 'teaspoon', 'cup','tablespoon', 'gram']},
    ];

    const handleChange = (e) => {
        setFile(e.target.files[0]);
    }

    const handleSubmit = () => {
        const dataArray = file;
        const reader = new FileReader();

        reader.onload = (e) => {
            const text = e.target.result;
            processCSV(text);
        }

        reader.readAsText(dataArray);
    };

    const processCSV = (str, delim=',') => {
        const headers = str.slice(0,str.indexOf('\n')).split(delim);
        const header = headers[1].replace(/[\r]+/gm, "");
        const head = [headers[0], header];
        const rows = str.slice(0,str.indexOf('\n')).split('\n');
        console.log(rows);

        const newArray = rows.map( row => {
            const values = row.split(delim);
            console.log(values);
            const eachObject = head.reduce((obj, header, i) => {
                obj[header] = values[i];
                return obj;
            }, {})
            return eachObject;
        })
        setFileData(newArray);
    }
    
    const addRowDataToFirebase = (data) => {
        console.log(data);
    }

  return (
    <div>
        <CSVLink
            headers={header}
            data={data}
        >
            download
        </CSVLink>
        <div>
            <input type='file' name='file importer' accept='.csv' onChange={handleChange} />
            <button onClick={handleSubmit}>Click me</button>
        </div>
        <ul style={{ height: "90vh", overflow: 'auto' }}>
        {fileData.map((item, i)=>{
            return (
                <>
                    {addRowDataToFirebase(item)}
                </>
            )
        } )}
        </ul>
    </div>
  )
}

export default DownloadCSV;