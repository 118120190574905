import React, { useState, useEffect, useContext } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { FirebaseApi } from '../../../firebaseConfig';
import { Grid } from '@mui/material';


const EarningsTable = (props) => {
    const [loadingEarnings, setLoadingEarnings] = useState(true);
    const [rowData, setRowData] = useState([]);
    const columns =
        [
            { field: 'id', headerName: '#', width: 130 },
            { field: 'name', headerName: 'Name', width: 150 },
            { field: 'status', headerName: 'Status', width: 150 },
            {
                field: 'level',
                headerName: 'Level',
                width: 150,
            },
            { field: 'cost', headerName: 'Cost', width: 150 },


        ];





    const getData = async () => {
        try {
            const data = await FirebaseApi["creatorEarnings"].get()
            console.log(data);
            const parsedData = data.docs.map((item) => {
                return {
                    id: item.id,
                    ...item.data()
                }
                // return item
            })
            console.log(parsedData)
            setRowData(parsedData);
            setLoadingEarnings(false);


        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getData();
    }, [])

    let costArray = [];
    const getCost = () => {
        rowData.map(x => {
            if (parseInt(x.cost) >= 0) {
                costArray.push(parseInt(x.cost));
            }
        });
        console.log(costArray);
        const initialValue = 0;
        const totalCost = costArray.reduce((previousValue, currentValue) => previousValue + currentValue, initialValue);
        console.log(totalCost);
        totalCost && props.setTotalEarnings(totalCost);
    }

    getCost();

    const noData = () => {
        return (
            <Grid style={{ display: 'flex', justifyContent: 'center', height: 300, alignItems: 'center' }}>
                <h2> No data available! </h2>
            </Grid>
        )
    }


    return (
        <>
            {loadingEarnings ?
                <h3> Loading Data... </h3>
                :

                <Grid style={{ height: 400, width: '100%' }}>
                    <DataGrid
                        rows={rowData}
                        columns={columns}
                        components={{
                            NoRowsOverlay: noData
                        }}

                        checkboxSelection
                    />
                </Grid>
            }
        </>

    )
}

export default EarningsTable;