import { CardMedia, Grid, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { Box } from "@mui/system";

export const RecipeOrArticleBadge = styled('div')({
    width: 'fit-content',
    height: '20px',
    padding: '5px 10px',
    borderRadius: '5px',
    background: '#F0F2F4',
    color: '#5E6C84',
    fontSize: '14px',
    textTransform: 'capitalize',
    marginTop: 100,
});

export const RecipeNameOrArticleName = styled('div')({
    width: 'fit-content',
    height: '20px',
    fontSize: '14px',
    color: "#FFFFFF",
    textTransform: 'capitalize',
    marginTop: 10,
});

export const RecipeNameOrArticleNameCardMedia = styled(CardMedia)({
    backgroundPosition: 'center',
    backgroundSize: 'fill',
    height: 'inherit',
    width: 'inherit'
});

export const SiteEditorHeader = styled(Grid)({
    width: 'fit-content',
    height: '20px',
    padding: '10px 5px',
    marginLeft: '20px',
    cursor: 'pointer',
});

export const SiteEditorHeaderTypograpghy = styled(Typography)({
    textTransform: 'uppercase',
    fontSize: '14px',
    cursor: 'pointer',
});

export const InfoPageGoBackButton = styled(Grid)({
    height: '7vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: '30px',
    marginTop: '15px',
    paddingTop: '10px',
    cursor: 'pointer',
});

export const InfoPageEmailTextArea = styled(Grid)({
    width: 'min(75%, 400px)', 
    height: '40px', 
    marginBottom: '15px', 
    padding: "5px 10px", 
    border: '2px dashed black',
    textAlign: 'center',
    backgroundColor: '#FFF5E8',
    borderRadius: '10px',
});

export const InfoPageInfoTextArea = styled(Grid)({
    width: 'min(85%, 700px)', 
    height: '100px', 
    padding: '10px 10px', 
    textAlign: 'center'
});

export const InfoPageNameTextArea = styled(Grid)({
    height: '30px', 
    width: 'fit-content', 
    padding: '5px 15px', 
});

//featured posts
export const FeaturedSectionArea = styled(Grid)({
    display: "flex",
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    // marginLeft: '60px',
    marginBottom: '20px',
    padding: '5px 15px', 
});

export const FeaturedRecipeCard = styled(Grid)({
    marginLeft: '20px',
});

export const FeaturedRecipeHeader = styled(Grid)({
    display: 'flex', 
    justifyContent: 'center', 
    marginTop: 20,
    alignItems: 'center',
    width: '100%',
});

// simple card
export const SimpleRecipeCardStyle = styled(Grid)({
    display: 'flex',
    flexDirection: 'column',
    width: 448,
    height: 448,
    border: '1px solid black',
    margin: 10
});

export const SimpleCardRecipeImage = styled(Grid)({
    width: '448px',
    height: '80%',
    border: '1px solid black'
});

export const SimpleCardRecipeDetails = styled(Grid)({
    border: '1px solid black',
    width: '448px',
    height: '20%',
});

//Random cards
export const RandomRecipeCards = styled(Box)({
    width: 'inherit',
    margin: 25,
    minHeight: 829,
});