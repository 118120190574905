import React, { useEffect, useState, useContext } from "react";
import { Grid, Typography, Button, TextField, Checkbox } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';

import CloudServices from "../../../cloud-services-core";
import AppContext from "../../../config/AppContext";
import AlternateCardLayout from '../CardLayouts/AlternateCard';
import { set } from "date-fns";


const PublishedCollections = (props) => {
    const AppState = useContext(AppContext);
    const formik = props.formik;
    const { values, handleChange, setFieldValue, setValues } = formik


    const [currentButton, setCurrentButton] = useState('');
    const [articleDrafts, setArticleDrafts] = useState([]);
    const [searchItem, setSearchItem] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [postsLoaded, setPostsLoaded] = useState(false);
    const [postSelected, setPostSelected] = useState(false);
    const [selectedPosts, setSelectedPosts] = useState([]);



    const [articlesData, setArticlesData] = useState([]);


    // for fetching items
    const fetchArticles = async () => {
        let val = await CloudServices.articles.fetchByCreatorId(AppState.user.id);
        console.log(val);
        setArticlesData(val);
        setPostsLoaded(true);
    }


    // for filtering results
    let finalResults = [];
    const searchForItem = async (value) => {
        const regVale = new RegExp(value, 'i');
        console.log(value, articleDrafts);
        articlesData.map((article) => {
            if (article.values.name.match(regVale)) {
                console.log(article);
                finalResults = [...finalResults, article];
                setSearchResults(finalResults);
            }
        })

    }

    // for handling selected posts
    const handlePostSelected = (post, postSelected) => {
        console.log(post.values.name)
        let postPresent = selectedPosts.includes(post.id);

        //postPresent == false && selectedPosts.push(post.id);
        if (postPresent) {
            let index = selectedPosts.indexOf(post.id);
            selectedPosts.splice(index, 1);
            setFieldValue('articles', selectedPosts);
        } else {
            selectedPosts.push(post.id);
            setFieldValue('articles', selectedPosts);

        }



    }

    console.log(values);

    console.log(articlesData, selectedPosts);
    useEffect(() => {
        fetchArticles();

    }, [])

    const PostCard = (post) => {
        return (
            <Grid style={{ display: 'flex', flexDirection: 'column', width: 350, height: 400, margin: 10 }}>
                <Checkbox
                    style={{ display: 'flex', position: 'absolute' }}
                    checked={values.articles?.length > 0 ? values.articles.includes(post.id) : selectedPosts.includes(post.id)}
                    onChange={() => { handlePostSelected(post, postSelected); setPostSelected(!postSelected) }}
                />
                <Grid style={{ background: `url(${post.values.image})`, width: 350, height: '70%', border: '1px solid #A5A5A5' }}>

                </Grid>
                <Grid style={{ border: '1px solid #A5A5A5', width: 350, height: '30%', padding: 10 }}>
                    <Typography variant="h6">
                        {post.values.name}

                    </Typography>
                    <Typography>

                        {post.values.description}

                    </Typography>

                </Grid>

            </Grid>
        )
    }
    return (
        <>
            {postsLoaded ?
                <Grid style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '15px 40px 15px' }}>
                        <Grid>
                            <Typography variant="h5">
                                All Published Posts
                            </Typography>

                            <Typography>
                                Tap to select the desired posts & add it to the collection.
                            </Typography>

                        </Grid>

                        <Grid style={{ display: 'flex', flexDirection: 'row', height: 40 }}>

                            {searchItem && <TextField onChange={(e) => searchForItem(e.target.value)} />}
                            <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #E8E8E8', marginRight: 10 }}>
                                <SearchIcon style={{ height: 25, width: 25, padding: 5 }} onClick={() => setSearchItem(!searchItem)} />

                            </Grid>

                            <Grid item style={{ display: 'flex', flexDirection: 'row', border: '1px solid #E8E8E8', height: '100%', padding: 5 }}>
                                <Button item value="Free" style={currentButton == "Article" ? { borderBottom: '2px solid #000', borderRadius: 0, color: 'black', paddingBottom: -2, fontWeight: 600 } : { color: '#8E8E8E' }} onClick={() => setCurrentButton('Article')}> Article</Button>
                                <Button item value="Silver" style={currentButton == "Recipe" ? { borderBottom: '2px solid #000', borderRadius: 0, color: 'black', paddingBottom: -2, fontWeight: 600 } : { color: '#8E8E8E' }} onClick={() => setCurrentButton('Recipe')}>  Recipe </Button>

                                <Button item value="Platinum" style={currentButton == "Collection" ? { borderBottom: '2px solid #000', borderRadius: 0, color: 'black', paddingBottom: -2, fontWeight: 600 } : { color: '#8E8E8E' }} onClick={() => setCurrentButton('Collection')}> Collection</Button>




                            </Grid>

                        </Grid>
                    </Grid>


                    <Grid style={{ padding: '10px 40px 10px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        {
                            articlesData && articlesData.map((article) => (
                                PostCard(article)
                            ))
                        }

                    </Grid>


                </Grid>

                :
                <Typography variant="h4">
                    Loading Data ...
                </Typography>

            }
        </>


    )

}

export default PublishedCollections;