export const RecipeData = [
    {name: 'Mon', uv: 400, pv: 2400, amt: 2400},
    {name: 'Tue', uv: 100, pv: 2400, amt: 2400},
    {name: 'Wed', uv: 200, pv: 2400, amt: 2400},
    {name: 'Thu', uv: 300, pv: 2400, amt: 2400},
    {name: 'Fri', uv: 300, pv: 2400, amt: 2400},
    {name: 'Sat', uv: 300, pv: 2400, amt: 2400},
    {name: 'Sun', uv: 300, pv: 2400, amt: 2400},
];

export const SubscriberData = [
    {name: 'Mon', uv: 400, pv: 2400, amt: 2400},
    {name: 'Tue', uv: 100, pv: 2400, amt: 2400},
    {name: 'Wed', uv: 200, pv: 2400, amt: 2400},
    {name: 'Thu', uv: 300, pv: 2400, amt: 2400},
    {name: 'Fri', uv: 300, pv: 2400, amt: 2400},
    {name: 'Sat', uv: 300, pv: 2400, amt: 2400},
    {name: 'Sun', uv: 300, pv: 2400, amt: 2400},
];

export const EarningsData = [
    {name: 'Mon', uv: 400, pv: 2400, amt: 2400},
    {name: 'Tue', uv: 100, pv: 2400, amt: 2400},
    {name: 'Wed', uv: 200, pv: 2400, amt: 2400},
    {name: 'Thu', uv: 300, pv: 2400, amt: 2400},
    {name: 'Fri', uv: 300, pv: 2400, amt: 2400},
    {name: 'Sat', uv: 300, pv: 2400, amt: 2400},
    {name: 'Sun', uv: 300, pv: 2400, amt: 2400},
];

export const Profileinformation = [
    {
        headerName: '1. Profile Information',
        itemList: [
            {
                name: 'Add a cover picture',
                to: '/creator/editor',
            },
            {
                name: 'Write your intro',
                to: '/creator/editor'
            },
            {
                name: 'Add site name',
                to: '/creator/editor'
            }
        ]
    },
    {
        headerName: '2. Site Information',
        itemList: [
            {
                name: 'Choose layout',
                to: '/creator/editor',
            },
            {
                name: 'Choose site colours',
                to: '/creator/editor'
            },
            {
                name: 'Choose site font',
                to: '/creator/editor'
            }
        ]
    },
    {
        headerName: '3. Membership levels',
        itemList: [
            {
                name: 'Add level and benefits',
                to: '/creator/editor',
            },
            {
                name: 'Set up a payout method',
                to: '/creator/editor'
            },
        ]
    },
    {
        headerName: '4. Create Content',
        itemList: [
            {
                name: 'Create a Recipe',
                to: '/creator/editor',
            },
            {
                name: 'Create a Article',
                to: '/creator/editor',
            },
        ]
    },
    {
        headerName: '5. Invite People',
        itemList: [
            {
                name: 'Send out invites',
                to: '/creator/editor',
            },
        ]
    },
]