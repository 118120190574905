import React, { useState, useEffect, useContext } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { FirebaseApi } from '../../../firebaseConfig'
import { Typography, Box } from '@mui/material'
import Grid from '@mui/material/Grid'
import { styled, useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom'
import Entities from '..'
import AppContext from '../../../config/AppContext'
import EntityNavbar from '../EntityNavbar'
//import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Path } from 'victory'



export default function DietEntityTable(props) {
    const [rowData, setRowData] = useState([])
    const [loading, setLoading] = useState(false)
    // const [currentIngredient, setCurrentIngredient] = useState('');
    const [currentEntityData, setCurrentEntityData] = useState();

    const [selectedRows, setSelectedRows] = useState([]);
    const currentEntity = 'diets'
    const currentEntityCategory = 'dependent'
    const history = useHistory()
    const location = useLocation()



    return (
        <>
            <EntityNavbar entity="diets" currentEntityCategory={currentEntityCategory} > </EntityNavbar>
        </>


    )

}