import React, { useState, useEffect } from 'react'
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid'
import { FirebaseApi } from '../../firebaseConfig'
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom'
import { Box, Grid } from '@material-ui/core';

const DataGrids = styled(MuiDataGrid)({
    // backgroundColor:'gray',
    // '& ..MuiDataGrid-row':{
    //     height:'200px !important'
    // },
    // '& .MuiDataGrid-cell':{
    //     height:'200px !important'
    // },
    // '& .MuiDataGrid-cell--withRenderer':{

    //     lineHeight:'18px !important'
    // },
    // '& .MuiDataGrid-cell--textLeft':{
    //     height:'200px !important',
    //     minHeight:'200px !important',
    //     maxHeight:'200px !important'
    // }
})

export default function EntityTable(props) {
    const [rowData, setRowData] = useState([])
    const [loading, setLoading] = useState(false);
    const [dataFetched, setDataFetched] = useState(false);

    useEffect(() => {
        getData(props.entity);
    }, [props.entity])

    const column = [
        { field: "id", headerName: "ID", width: 200 },
        // {
        //     field: "imageUrl", headerName: 'Image Url', width: 250,
        //     renderCell: (props) => {
        //         return (
        //             <div style={{ display: 'flex', flexDirection: 'column', width: 100, height: 100 }} >
        //                 {props.nameLink ? (

        //                     <img src={props.row.image?.[0]} style={{ width: '100%', height: '100%' }} />
        //                 ) : (

        //                     <img src={props.row.imageUrl} style={{ width: '100%', height: '100%' }} />
        //                 )}
        //             </div>
        //         )
        //     }
        // },
        props.nameLink ? (
            {
                field: "name", headerName: "Name", width: 250, renderCell: (props) => (
                    <Link to={`recipe/${props.row.id}?collection=recipe`} >{props.row.name}</Link>
                )
            }
        )   :   ({ field: "name", headerName: "Name", width: 250 }),
        { field: "description", headerName: "Description", width: 500 },
        // props.entity == 'diets' ? { field: "ingredientExcluded", headerName: "Excluded", width: 500 } : {}
    ]

    const getData = async (entityName) => {
        setLoading(true)
        try {
            const data = await FirebaseApi[entityName].get()
            const parsedData = data.docs.map((item) => {
                return {
                    id: item.id,
                    ...item.data()
                }
            })
            setRowData(parsedData);
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    const filterRecipes = async (value) => {
        const newArr = [];
        const newValue = value;
        newValue.map((x) => {
            if (x?.name !== "" && x?.recipeInstructions !== undefined && x?.recipeInstructions[0]?.itemListElement !== undefined && x?.recipeInstructions[0]?.itemListElement[0]?.images !== undefined && x?.recipeInstructions[0]?.itemListElement[0]?.parameters?.pro?.duration?.unit === undefined) newArr.push(x);

        })
        setRowData(newArr);
        setDataFetched(true);
    }
    if (rowData.length > 0 && dataFetched === false) {
        filterRecipes(rowData);
    }

    // return <Typography>{props.entity}</Typography>
    return (
        <Box style={{ height: '100vh', width: '100%' }}>
        {loading ? (
        <div>Loading..</div>
    ) : (
        <DataGrids
            hideFooter
            columns={column}
            rows={rowData}
        />)}
    </Box>
    )
}
