import React,{useState,useEffect} from 'react'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { Typography } from '@mui/material'
import {makeStyles,useTheme} from '@mui/styles'
import {styled} from '@mui/material/styles'
import { css, keyframes } from "@emotion/react";
import AddRecipe from '../../assets/NavBar/AddRecipe.svg'
import Browse from '../../assets/NavBar/Browse.svg'
//icons

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import CloudServices from '../../cloud-services-core'

import RecipeCard from '../../components/RecipeCard'
import {FirebaseApi} from '../../firebaseConfig'

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {Switch} from '../../components/Switch'
import generateId from '../../components/GenerateId'
import { Link } from 'react-router-dom'
import MuiButton from '../../components/MuiButton'

const effect = keyframes`
0%{
    margin-left:10
    transform:rotate(25deg)
}
50%{
    margin-left:20,
    transform:rotate(60deg)
}
`;
const useStyles = makeStyles({
    loading:{
        width:100,
        height:100,
        color:'white',
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        marginBottom:0,
        animation: "$fadeIn 0.7s linear infinite alternate",
        backgroundColor:'black',
        borderRadius:'50%'
    },
    "@keyframes fadeIn": {
        "0%": {
        //   transform:'rotate(90deg)',
            marginBottom:150
        },
        "50%":{
            // transform:'rotate(180deg)',
            marginBottom:50
        },
        "95%":{
            // transform:'scale(1.2)',
            marginBottom:0
        },
        "100%": {
            // transform:'rotate(360deg)',
            marginBottom:0,
            // transform:'scale(1)'
        }
      },
    buttonRoll:{
        width:40,
        height:40,
        borderRadius:50,
        backgroundColor:'gray',
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        '&[animate="true"]':{
            backgroundColor:'green',
            animation: `$myEffect 1s linear`,
            animationFillMode:'forwards'
        }
        
    },
    "@keyframes myEffect": {
        "0%": {
          marginLeft: 20,
          transform: "rotate(90deg)"
        },
        "100%": {
          marginLeft: 40,
          transform: "rotate(180deg)"
        }
      },
    checkbox:{
        position:'relative',
        width:'100%',
        height:40,
        appearance:'none',
        backgroundColor:'white',
        outline:'none',
        borderRadius: 20,
        transition:'.5s',
        margin:0,
        border:'1px solid black',
        '&::after':{
            content:'"Manual"',
            position:'absolute',
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            width:40,
            height:40,
            
            right:'35%',
            color:'black',
            transition:'.5s',
            


        },
        '&::before':{
            content:'""',
            position:'absolute',
            backgroundImage:`url(${AddRecipe})`,
            backgroundPosition: 'center', 
            backgroundSize: 'cover', 
            backgroundRepeat: 'no-repeat',
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            width:40,
            height:40,
            borderRadius:20,
            top:0,
            left:0,
            backgroundColor:'white',
            transform:'scale(1.1)',
            transition:'.5s'
        },
        
        '&:checked':{
            backgroundColor:'black'
        },
        '&:checked::before':{
            left:'78%',
            right:0,
            top:0,
            backgroundImage:`url(${Browse})`

        },
        '&:checked::after':{
            content:'"Professional"',
            position:'absolute',
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            alignItems:'center',
            width:'50%',
            height:40,
            
            left:'10%',
            color:'white',
            backgroundColor:'transparent',
            transition:'.5s'
        }
        
    },
    accordionStep:{
        borderBottom:'1px solid #DDDDDD',
        '&:nth-last-child(1)': {
            borderBottom: 0,
        },
    }
})
const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
    border: `1px solid ${theme.palette?.divider}`,
    borderRadius:12,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor: '#fff',
    //   theme.palette?.mode === 'dark'
    //     ? 'rgba(255, 255, 255, .05)'
    //     : 'rgba(0, 0, 0, .03)',
    borderRadius:12,
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding:0,
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));


export default function Recipe(props) {
    

    const [onHover,setOnHover] = useState(false)
    const [recipeLoading,setRecipeLoading] = useState(false)

    const [recipeType,setRecipeType] = useState('manual')
    const [recipe,setRecipe] = useState({})

    const classes = useStyles()

    const theme = useTheme()


    const [currentStep,setCurrentStep] = useState(0)
    const [totalSteps,setTotalSteps] = useState(0)
    const [currentSectionName,setCurrentSectionName] = useState("")
    const [currentStepDescription,setCurrentStepDescription] = useState("")
    const [currentStepIngredients,setCurrentStepIngredients] = useState([])
    const [currentStepTools,setCurrentStepTools] = useState([])

    const [proMode,setProMode] = useState(false)


    const [navOptions,setNavOptions] =useState(false)

    const [servings,setServings] =useState(0)

    useEffect(()=>{
       
        const collection = props.location.search.split("=")[1]
        getRecipeFrom(collection,props.match.params.id)
    },[])
    
    const segregateAccordingToCollections ={
        "drafts":(fetchedRecipe)=>{
            // const recipeIngredient =  fetchedRecipe.recipe.recipeIngredients
            // delete fetchedRecipe.recipe.recipeIngredients
            // const newRecipe = {...fetchedRecipe.recipe,recipeIngredient}
                
        
            // return newRecipe

            if(fetchedRecipe.recipe.proMode === undefined){
                let newRecipeInstructions=[]
                if(fetchedRecipe.recipe.recipeInstructions.some(instruction=>instruction['@type']==='HowToStep')){
                    var combiningHowToSteps =[{
                        id:0,
                        ingredients:[],
                        derivedIngredients:[],
                        itemListElement:[]
                    }]
                    fetchedRecipe.recipe.recipeInstructions.map((instruction,index)=>{
                        combiningHowToSteps[0].itemListElement.push({
                            id:generateId(),
                            image:'',
                            ingredients:[],
                            parameters:{
                                duration:{
                                    unit:'sec',
                                    time:0
                                },
                                temperature:'low',
                                toolsUsed:[]
                            },
                            ...instruction
                        })
                    })

                    newRecipeInstructions = combiningHowToSteps

                }
                else{
                    newRecipeInstructions = fetchedRecipe.recipe.recipeInstructions.map((instruction,index)=>{
                        if(instruction['@type']==='HowToSection'){

                            instruction.id = index+1
                            instruction.ingredients = []
                            instruction.derivedIngredients = []
                            if(instruction.itemListElement){
        
                                instruction.itemListElement.map((element)=>{
                                    element.id = generateId()
                                    element.image = ''
                                    element.ingredients = []
                                    element.parameters = {
                                        duration:{
                                            unit:'sec',
                                            time:0
                                        },
                                        temperature:'low',
                                        toolsUsed:[]
                                    }
                                    return element
                                })
                            }
                            
                            return instruction
                        }
                    })

                }

        

                // console.log({newRecipeInstructions})
            
                

                // console.log(combiningHowToSteps)
                
                fetchedRecipe.recipe.recipeInstructions = newRecipeInstructions
            }

   

            return fetchedRecipe.recipe
            
            
        },
        "recipe":(fetchedRecipe)=>{
            // const recipeIngredient =  fetchedRecipe.recipeIngredients
            // delete fetchedRecipe.recipeIngredients
            // const newRecipe = {...fetchedRecipe,recipeIngredient}
            // return newRecipe


            return fetchedRecipe
        },
        "trash":(fetchedRecipe)=>{
            return fetchedRecipe
        }
    }
    const getRecipeFrom=async(collection,id)=>{
        setRecipeLoading(true)
        try{
        
           
            
            const fetchedRecipe = await CloudServices.crud.getCollectionWithId(collection,id)
   
           
            const recipe = segregateAccordingToCollections[collection](collection==='trash' ? fetchedRecipe.recipe : fetchedRecipe)
         
            setRecipe(recipe)
            setServings(Number(recipe.servings))
            console.log(recipe)
            if(typeof recipe.keywords=='string'){
                const keywords = recipe.keywords.split(",")
                recipe.keywords = keywords
            }
            if(recipe.recipeInstructions){

            
                setCurrentSectionName(recipe.recipeInstructions[0]?.name)
                setCurrentStep(0)
                if(recipe.recipeInstructions[0]?.itemListElement){

                    setCurrentStepDescription(recipe.recipeInstructions[0]?.itemListElement[0]?.text)
                    let stepIngredients =  []
                    recipe.recipeInstructions[0].itemListElement[0] && recipe.recipeInstructions[0].itemListElement[0].ingredients && recipe.recipeInstructions[0].itemListElement[0].ingredients.map(ingredient=>{
                        recipe.recipeIngredient && recipe.recipeIngredient.map((recipeIngredient)=>{
                            if(recipeIngredient.id === ingredient){
                                stepIngredients.push(recipeIngredient)
                            }
                        })
                    })
                    
                    setCurrentStepIngredients(stepIngredients)
                    setTotalSteps(recipe.recipeInstructions[0]?.itemListElement?.length)
                }
            }
            setRecipeLoading(false)
        }
        catch(err){
            console.log(err)
        }


    }

    const getCurrentStepDetails = (stepNumber) =>{

        return recipe?.recipeInstructions[0].itemListElement[stepNumber]

    }

    const prevStep = () =>{
        if(currentStep-1>=0){
            const details = getCurrentStepDetails(currentStep-1)
            setCurrentStepDescription(details.text)
            let stepIngredients =  []
            details.ingredients.map((code)=>{
                recipe.recipeIngredient.map(ingredient=>{
                    if(ingredient.id===code){
                        stepIngredients.push(ingredient)
                    }
                })
            })
            setCurrentStepIngredients(stepIngredients)
            setCurrentStep(currentStep-1)
        }
    }

    const nextStep = () =>{
        const details = getCurrentStepDetails(currentStep+1)
        setCurrentStepDescription(details.text)
        let stepIngredients =  []
        details.ingredients.map((code)=>{
            recipe.recipeIngredient.map((ingredient) => {
                if(ingredient.id===code){
                    stepIngredients.push(ingredient)
                }
            })        
        })
        setCurrentStepIngredients(stepIngredients)
        setCurrentStep(currentStep+1)
    }



    const renderInstructionSteps = (instruction,index,pro=false) =>{
       
        return(
            <Grid item key={index}>

                <Accordion defaultExpanded key={instruction.name}>
                    <AccordionSummary>{instruction.name}</AccordionSummary>
                    <AccordionDetails>
                        {instruction.itemListElement && instruction.itemListElement.map((step,stepIndex)=>(
                            <div key={`${instruction.name}${stepIndex}`} className={classes.accordionStep}>
                                <Grid container columnSpacing={1} style={{padding:10}}>
                                    <Grid item sm={1}  xs={2}>
                                     
                                        <Grid container direction="row" justifyContent="flex-end">
                                            <Typography style={{fontSize:22,fontWeight:'bold'}}>{stepIndex}.</Typography>

                                        </Grid>
                                    </Grid>
                                     <Grid item sm={9} xs={10} >
                                        <Grid container spacing={1} direction="column" >
                                            <Grid item>


                                                <Typography style={{color:'#292929',fontSize:22,fontWeight:'bold'}}>{step.name}</Typography>
                                            </Grid>
                                            <Grid item>

                                                <Typography>{step.text}</Typography>
                                            </Grid>
                                           <Grid item>
                                                {pro ? (
                                                    <div style={{display:'flex',flexDirection:'row',}}>
                                                       
                                                    <Typography>{step.parameters.duration.time}{step?.parameters?.duration?.unit}</Typography>
                                                    <Typography style={{marginLeft:10}}>{step?.parameters?.temperature?.value}{step?.parameters?.temperature?.unit}</Typography>
                                                </div>
                                                 ) :(
                                                    <div style={{display:'flex',flexDirection:'row'}}>
                                                    <Typography>{step?.parameters?.duration?.time} {step?.parameters?.duration?.unit}</Typography> 
                                                    <Typography style={{marginLeft:10}}>{step?.parameters?.temperature} temperature</Typography>
                                                </div>
                                                )}
                                                
                                                
                                            </Grid>
                                       
                                        </Grid>

                                    </Grid>
                                  
                                    {step.image?.length >0 && (

                                        <Grid item sm={2} xs={4}>
                                             <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',width:'100%',height:150}}>
                                                
                                               
                                                 <img src={recipe.images[step.image]} style={{width:'100%',height:'auto',borderRadius:15}} />
                                            </div>
                                        </Grid>
                                    )} 
                                    


                            
                                </Grid> 

                            </div>
                        ))}
                    </AccordionDetails> 
                    
                </Accordion>
            </Grid>
        )
    }

    const changeServings = (newServings) =>{
        const newIngredients = recipe.recipeIngredient.map(ingredient=>{
            const newQuantity = (newServings*Number(ingredient.quantity)) / servings
           
            ingredient.quantity = newQuantity.toFixed(2)

            return ingredient

        })
        setRecipe({...recipe,recipeIngredient:newIngredients})
    

    }

    const decreaseServings = ()=>{
        console.log(servings,servings >= 1)
        var newServings = servings
        if(servings > 1){

            newServings =  servings - 1
        }
        changeServings(newServings)
        setServings(newServings)

    }
    const increaseServings = () =>{
        var newServings = servings
        if(servings < 5){

            newServings = servings + 1
        }
        changeServings(newServings)
        
        
        setServings(newServings)

    }

    const showIngredients = ()=>{
        var ingredientInThisSection = []
        var spices = []
        // instruction.ingredients && instruction.ingredients.map((ingredient)=>{
        //     console.log(ingredient)
                recipe.recipeIngredient && recipe.recipeIngredient.map((recipeIngredient)=>{
                    console.log(recipeIngredient)
                    if(recipeIngredient.ingredientType == "Spice/Herb"){
                        spices.push({id:recipeIngredient.id,name:recipeIngredient.name})
                    }
                    ingredientInThisSection.push(recipeIngredient)
                    
                    // console.log(recipeIngredient.id,ingredient,recipeIngredient.id==ingredient)
                // if(recipeIngredient.id==ingredient){
                    //     ingredientInThisSection.push(recipeIngredient)
                // }
            })
        // })
        console.log({spices})

        return(
        <Grid container direction="column"  >
           
            <Grid item xs={12}>

                <div style={{width:'90%',margin:'auto'}}>
                    <Grid container direction="row">
                    {ingredientInThisSection.length > 0 && ingredientInThisSection.map((ingredient,index)=>(
                        <Grid item xs={12} key={index}>

                            <Grid container  >
                                <Grid item xs={4}>
                                    <Typography style={{fontSize:14}}>{ingredient.quantity} {ingredient.unit} {ingredient.ingredient}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid container direction="row" justifyContent="flex-end" alignItems="center">

                                        <Typography style={{fontSize:14}}> {ingredient.preparationStyle}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>{ingredient.id}</Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                    ))}
                    </Grid>
                </div>
            </Grid>
        </Grid>

    )}
                                                                

                                                                
    
    const renderRecipeType = (name)=>{
        switch(name){
            case 'manual':
                return(
                    <>
                        <Grid item sm={12}>
                

                            <Grid container columnSpacing={2} rowSpacing={2}  direction="row"   >
                                <Grid item md={4} xs={12}>
                                    <Grid container rowSpacing={3} direction="column" >

                                        <Grid item xs={12} >
                                            <Grid container direction="column" style={{border:'1px solid #E3E3E3',borderRadius:12,backgroundColor:'#fff'}}>
                                                <Grid item xs={12} style={{borderBottom:'1px solid #E3E3E3'}}>
                                                    <Typography style={{padding:"10px 20px"}}>Ingredients</Typography>
                                                </Grid>
                                                <Grid item xs={12} style={{minHeight:'60vh',padding:"0 20px",}}>
                                                    <Grid container rowSpacing={1}  direction="column">

                                                        <Grid item xs={12} >
                                                            <Grid container  direction="row" justifyContent="center" alignItems="center" style={{minHeight:'10vh'}} >
                                                                
                                                                <Grid item xs={6}>
                                                                    <Grid container  direction="row" justifyContent="center" alignItems="center" >
                                                                        <Grid item>

                                                                
                                                                        <Grid container  direction="row" justifyContent="space-around" alignItems="center" style={{width:'100%',border:'1px solid #707070',borderRadius:12}}>
                                                                            <Grid item style={{padding:"5px 15px",borderRight:"1px solid #707070",borderRadius:10}}>
                                                                                <Typography>US</Typography>
                                                                            </Grid>
                                                                            <Grid item style={{padding:5}}>
                                                                                <Typography>Metric</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    
                                                                        </Grid>
                                                                    </Grid>

                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Grid container direction="row" alignItems="center" >
                                                                        <Grid item xs={3} >
                                                                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                                                                <Grid item>
                                                                                    <Typography style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',border:'1px solid gray',borderRadius:50}} >
                                                                                        <RemoveIcon style={{fontSize:18}} onClick={decreaseServings} />
                                                                                    </Typography>

                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item xs={6} >
                                                                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                                                                <Grid item >
                                                                                    <div>
                                                                                        <Typography style={{fontSize:14}}>{`Servings ${servings}`}</Typography>

                                                                                    </div>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item xs={3} >
                                                                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                                                                    <Grid item >
                                                                                        

                                                                                            <Typography style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',border:'1px solid gray',borderRadius:50}} >
                                                                                                <AddIcon style={{fontSize:18}} onClick={increaseServings} />
                                                                                                </Typography>
                                                                                        

                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                    </Grid>

                                                                </Grid>
                                                            </Grid>

                                                        </Grid>
                                                        <Grid item >
                                                            {showIngredients()}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <Grid container rowSpacing={1}  direction="column" style={{border:'1px solid #E3E3E3',borderRadius:12,backgroundColor:'#fff'}}>

                                                <Grid item xs={12} style={{borderBottom:'1px solid #E3E3E3'}}>
                                                    <Typography style={{marginLeft:10,padding:10}}>Tools Needed</Typography>
                                                </Grid>
                                                <Grid item xs={12} style={{borderRadius:12,}}>
                                                    <Grid container rowSpacing={3} columnSpacing={3} direction="row" alignItems="center" style={{borderRadius:12,padding:10}}>

                                                        {[1,2,3,4,5,6].map((item,index)=>(
                                                            <Grid key={index} item xs={4} style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                                                                <div style={{width:90,height:90,backgroundColor:'gray',borderRadius:50}} />
                                                                <Typography>{`Tool name ${item}`}</Typography>
                                                            </Grid>
                                                        ))}
                                                        
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid item md={8} xs={12}>
                                    <Grid container rowSpacing={2} direction="column" justifyContent="flex-start"   >

                                        {proMode ? 
                                        recipe?.recipeInstructionsPro?.length > 0 && recipe.recipeInstructionsPro.map((instruction,index,)=>renderInstructionSteps(instruction,index,true))   :
                                        recipe?.recipeInstructions?.length > 0 && recipe.recipeInstructions.map((instruciton,index,)=>renderInstructionSteps(instruciton,index))}
                                        
                                        
                                    </Grid>

                                </Grid>
                                                    
                            </Grid>
                    
                        </Grid>
                        <Grid item sm={12} style={{backgroundColor:'white',margin:"131px 0"}}>
                            <div style={{width:'90%',margin:'auto'}}>

                            
                           <Grid container rowSpacing={2} direction="row" justifyContent="space-between" alignItems="center" style={{margin:"58px 0",}} >
                                <Grid item  sm={5} >
                                    <Grid container direction="column" >
                                        <Grid xs={12} item style={{marginBottom:-10}} >
                                        {recipe?.image?.length > 0 && (
                                            <img src={recipe?.image[0]} style={{height:'100%',width:'100%'}} />
                                        )}
                                            
                                        </Grid>
                                        <Grid xs={12} item>
                                            <Grid container  style={{backgroundColor:'#DCDCDC'}} >
                                                <Grid item xs={4} style={{height:'10vh'}}>
                                                    <Grid container direction="column" alignItems="center" justifyContent="space-evenly" style={{height:'100%'}}  >
                                                        <Grid item>
                                                            <Typography>Icon</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography>description</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    
                                                </Grid>
                                                <Grid item xs={4} style={{height:'10vh'}}>

                                                    <Grid container direction="column" alignItems="center" justifyContent="space-evenly" style={{height:'100%'}}  >
                                                        <Grid item>
                                                            <Typography>Icon</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography>description</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4} style={{height:'10vh'}}>
                                                    <Grid container direction="column" alignItems="center" justifyContent="space-evenly" style={{height:'100%'}} >
                                                        <Grid item>
                                                            <Typography>Icon</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography>description</Typography>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm={7}>
                                    <div style={{marginLeft:'8%',}}>

                                    
                                    <Grid container rowSpacing={2} direction="column" >
                                            <Grid item xs={12}>
                                                <Grid container direction="row" justifyContent="flex-end" >
                                                    <Grid item>
                                                        <Typography>COOKING MODE</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container direction="column" >
                                                    <Grid item xs={12}>
                                                        <Typography>{`Step ${currentStep+1} of ${totalSteps}`}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography>{currentSectionName}</Typography>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container direction="row" justifyContent="center" alignItems="center">
                                                    <Grid item >
                                                        <Typography>{currentStepDescription}</Typography>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                            <Grid item xs={12} style={{position:'relative',minHeight:'20vh'}}> 
     
                                                <div style={{
                                                    position:'absolute',
                                                    top:0,
                                                    left:0,
                                                    bottom:0,
                                                    display:'flex',
                                                    flexDirection:'row',
                                                    justifyContent:'center',
                                                    width:140
                                                    }}>
                                                        <Typography style={{transform:'rotate(270deg)',color:'#C0C0C0',fontSize:18,fontWeight:'bold'}}>INGREDIENTS</Typography>
                                                </div>
                                                   
                                                <Grid container columnSpacing={2} direction="row" alignItems="center" style={{marginLeft:35}}>

                                                { currentStepIngredients.length > 0 && currentStepIngredients.map((item,index)=>(
                                                    <Grid key={index} item>
                                                        <Grid container direction="column" alignItems="center" >
                                                            <Grid item xs={12}>
                                                                {/* <div style={{width:80,height:80,backgroundColor:'green',borderRadius:'50%',background:`url(${item.imageUrl})`}} /> */}
                                                                <img src={item.imageUrl} style={{width:80,height:80,borderRadius:'50%',}} />
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography style={{textAlign:'center'}}>{item.name}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                                </Grid>
                                                   

                                            </Grid>
                                            <Grid item xs={12} style={{position:'relative',minHeight:'20vh'}}>
                                            <div style={{
                                                    position:'absolute',
                                                    top:0,
                                                    left:0,
                                                    bottom:0,
                                                    display:'flex',
                                                    flexDirection:'row',
                                                    justifyContent:'center',
                                                    width:140
                                                    }}>
                                                        <Typography style={{transform:'rotate(270deg)',color:'#C0C0C0',fontSize:18,fontWeight:'bold'}}>TOOLS</Typography>
                                                </div>
                                                
                                                <Grid container columnSpacing={2} direction="row" alignItems="center" style={{marginLeft:35}}>

                                                    {[0,1].map((item=>(
                                                        <Grid key={item} item>
                                                            <Grid container direction="column" >
                                                                <Grid item>
                                                                    <div style={{width:'100%',height:'10vh',backgroundColor:'green',borderRadius:'50%'}} />
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography>item name</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography>prep</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )))}
                                                </Grid>
                                                    
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container direction="row" >
                                                    <Grid item xs={6} >
                                                        <Grid container direction="row">
                                                            <Grid item sm={5} xs={8} style={{backgroundColor:'black'}}>
                                                                <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',padding:10,border:'1px solid black'}}>
                                                                    <Typography style={{color:'white'}}>Start timer</Typography>
                                                                </div>

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={6}  >

                                                        <Grid container direction="row" columnSpacing={1}  alignItems="center" justifyContent="flex-end" >
                                           
                                                                    <Grid item xs={5} >
                                                                        <div  onClick={prevStep} style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',padding:10,border:'1px solid black',}}>
                                                                            <Typography>PREV</Typography>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item xs={5} >
                                                                        <div onClick={nextStep} style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',padding:10,border:'1px solid black'}}>
                                                                            <Typography>NEXT</Typography>
                                                                        
                                                                        </div>
                                                                    </Grid>
                                                              



                                                        

                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>

                                    </Grid>
                                    </div>

                                </Grid>
                           </Grid>
                           </div>
                        </Grid>
                        <Grid item sm={12} style={{marginBottom:50}}>
                            <Grid container rowSpacing={3} direction="column" >
                                <Grid item>
                                    <Typography style={{fontSize:30,fontWeight:'bold'}}>Suggested recipe pairings</Typography>
                                </Grid>
                                <Grid item>

                                    <Grid container rowSpacing={2} columnSpacing={4} direction="row"  >

                                        {[0,1,2,3].map(item=>(
                                            <Grid item lg={3} md={4} sm={6} xs={12}  key={item}>
                                                <RecipeCard name={`Recipe ${item}`} collectionType="recipe" hideActions />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </>
                )
            case 'createProfessional':
                return(
                    <Grid item sm={12} >
                        <Grid container direction="row"   style={{backgroundColor:'white',padding:20}}>
                            <Grid item sm={12}>
                                <div style={{width:'80%',margin:'auto'}}>

                              
                                    <Grid container direction="column" rowSpacing={2}>
                                        <Grid item style={{padding:"15px 0"}}>
                                            <Grid container direction="row" justifyContent="center" alignItems="center">

                                                <Typography style={{fontSize:24}}>Check out recipes compatible with our hardware</Typography>
                                                
                                            </Grid>
                                        </Grid>

                                        <Grid item sm={12} >
                                            <Grid container columnSpacing={1}  direction="row" >

                                                <Grid item md={4} sm={6} xs={12} >
                                                    <Grid container style={{border:'1px solid black'}}  >
                                                        <Grid  xs={12}  item style={{minHeight:'20vh',}}>

                                                                {/* <Typography>Automatic Cooking</Typography> */}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                                                <Typography>Automatic Cooking</Typography>
                                                            </Grid>

                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                                <Grid item md={4} sm={6} xs={12} >
                                                    <Grid container direction="row" style={{border:'1px solid black'}} >
                                                        <Grid xs={12} item style={{minHeight:'20vh',}}>

                                                                {/* <Typography>Automatic Cooking</Typography> */}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                                                <Typography>Semi automatic Cooking</Typography>
                                                            </Grid>

                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item md={4} sm={6} xs={12} > 
                                                    <Grid container direction="row" style={{border:'1px solid black'}} >
                                                        <Grid xs={12} item style={{minHeight:'20vh',}}>

                                                                {/* <Typography>Automatic Cooking</Typography> */}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                                                <Typography>Sous vide cooking</Typography>
                                                            </Grid>

                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                                
                                            </Grid>
                                        </Grid>

                                        <Grid item  sm={12}>
                                            <Grid container rowSpacing={2} direction="column" justifyContent="center" alignItems="center" >

                                                <Grid item>
                                                    <Typography>Want to create pro version of this recipe?</Typography>

                                                </Grid>

                                                <Grid item>

                                                    <button style={{border:'none',backgroundColor:'#000000',padding:15,color:'white',fontSize:18}}>Make Pro version</button>

                                                </Grid>
                                            </Grid>
                                            
                                            
                                        </Grid>
                                        <Grid item sm={12}>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                           

                        </Grid>
                    </Grid>
                )
           default:
        }
    }

    const makeACopy = async() =>{
        try {
            const result = await FirebaseApi['recipe'].add(recipe)
            console.log(result)
        } catch (error) {
            console.log(error)
        }

    }

    return (
        <div style={{width:'100%',backgroundColor:'#F6F7FB',position:'relative'}}>
            <Box style={{position:'sticky',top:0,backgroundColor:'#F94C0C',zIndex:100}}>
                <Container maxWidth="xl" style={{position:'relative'}}> 
                    <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{minHeight:'8vh'}}>
                        <Grid item lg={9} xs={5}>
                            <Box style={{
                                width:40,
                                height:40,
                                display:'flex',
                                flexDirection:'row',
                                justifyContent:'center',
                                alignItems:'center',
                                borderRadius:'50%',
                                backgroundColor:'rgba(255,255,255,0.5)'
                                }}>
                                <KeyboardBackspaceIcon onClick={()=>props.history.goBack()}  style={{color:'white'}}/>
                            </Box>
                        </Grid>
                        <Grid item lg={3}  xs={7} >
                            <Grid container >
                                <Grid item lg={4} xs={4}>
                                    <Grid container direction="row" alignItems="center" justifyContent="space-around" style={{height:'100%'}} >
                                        <Grid item>
                                            <Typography style={{color:'white'}}>Pro</Typography>

                                        </Grid>
                                        <Grid item>
                                            <Switch  onChange={()=>setProMode(!proMode)}  />

                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} xs={6} >
                                    <Box    style={{height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>

                                    <Button variant="contained" style={{backgroundColor:'black'}}>Cook Now</Button>
                                    </Box>
                                </Grid>
                                <Grid item lg="auto" xs="auto" >
                                    <Box style={{height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                        <MoreVertIcon onClick={()=>setNavOptions(!navOptions)} style={{fontSize:30,transform:'rotate(90deg)'}} />

                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    {navOptions && (
                        <>
                        <Box style={{
                            position:'absolute',
                            top:'9vh',
                            right:40,
                            backgroundColor:'white',
                            width:150,
                            height:100,
                            transition:'.5s linear',
                            boxShadow:"0 7px 17px #615D5D29",
                            zIndex:11,
                            padding:10
                            
                            }}>
                                <Grid container >
                                    <Grid xs="12" spacing={2} item>
                                        <MuiButton to={`/createRecipe/${recipe.id}?collection=${props.location.search.split("=")[1]}`} title='Edit' start={<EditIcon />} />
                                    </Grid>
                                    <Grid xs={12} spacing={2} item>
                                        <MuiButton title='Copy' click={makeACopy} />
                                    </Grid>
                                </Grid>
                                

                        </Box>
                        <Box style={{boxShadow:"0 7px 17px #615D5D29",position:'absolute',top:'8vh',right:50,width:20,height:20,backgroundColor:'white',transform:"rotate(45deg) skew(20deg,20deg)",zIndex:10}}>

                        </Box>
                        </>

                    )}
                </Container>

            </Box>
        <Container maxWidth="xl">

       
    
                
            {recipeLoading ? (
                <Grid container direction="row" justifyContent="center" alignItems="center" style={{height:'90vh'}} >
                    <Grid item>
                        <p  style={{fontSize:70}} className={classes.loading}></p>

                    </Grid>

                </Grid>
            ) : (

                <Grid container direction="column" >
                    <Grid item xs={12} container spacing={2}  style={{minHeight:'85vh',maxHeight:'85vh'}}>
                        <Grid item lg={4} xs={12} container direction="row" justifyContent="center" alignItems="center" >
                            <Grid item >
                            {recipe?.image?.length > 0 && (
                                            <img src={recipe.image[0]} alt="food" style={{width:'100%',objectFit:'contain',borderRadius:16}} /> 

                                        )}
                            </Grid>

                        </Grid>
                        {/* <Grid item lg={4} xs={12}>
                            <Grid container direction="row" justifyContent="center" alignItems="center" sx={{
                                minHeight:{
                                    lg:'70vh',
                                    xs:'50vh' 
                                },
                                
                            }} style={{width:'100%'}}>
                                <Grid item xs={12}>
                                    <Grid sx={{
                                        height:{
                                            lg:'70vh',
                                            xs:'50vh'
                                        }
                                    }} style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                        {recipe?.image?.length > 0 && (
                                            <img src={recipe.image[0]} alt="food" style={{width:'100%',height:'90%',objectFit:'cover'}} /> 

                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid> */}
                        <Grid item lg={8} xs={12} container  direction="column" justifyContent="center" alignItems="center"  >
                                <Grid item  spacing={2} container  >
                                    
                                    {/* <Grid item sm={12} >
                                        <Typography>Meal type | Cuisine | Diet</Typography>
                                    </Grid> */}
                                    <Grid item sm={12} >
                                        <Typography style={{fontSize:40,lineHeight:1.2,color:'#292929',fontWeight:'bold'}}>{recipe?.name}</Typography>
                                    </Grid>
                                    <Grid item sm={12} >
                                        <Typography style={{fontSize:20}}>{recipe?.description}</Typography>
                                    </Grid>
                                    <Grid item sm={12}   >
                                        <Grid container direction="row" alignItems="center"  >
                                            <Grid item md={3} xs={6}>
                                                <Grid container direction="row" alignItems="center" >
                                                    <Grid item>
                                                        <StarIcon style={{fontSize:35}} />
                                                    </Grid>
                                                    <Grid item>
                                                        <StarIcon style={{fontSize:35}}  />

                                                    </Grid>
                                                    <Grid item>
                                                        <StarBorderIcon style={{fontSize:35}}  />

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item md={3} xs={6}>
                                                <Typography style={{fontSize:20}}>15/20</Typography>
                                            </Grid>
                                            <Grid item md={6} xs={12} >
                                                <Grid container direction="row" columnSpacing={2} justifyContent="center" alignItems="center">
                                                    <Grid item >
                                                    <AccessTimeIcon />
                                                    </Grid>
                                                    <Grid item>

                                                        <Typography style={{fontSize:20,margin:0}}>{`Prep ${recipe?.prepTime && recipe.prepTime.split("T")[1]}  | Cook ${recipe?.cookTime && recipe.cookTime.split("T")[1]}`}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <Grid container columnSpacing={2} direction="row" alignItems="center" >
                                            <Grid item >
                                                <div  style={{width:40,height:40,backgroundColor:'gray',borderRadius:50}}/>
                                            </Grid>
                                            <Grid item>
                                                <Typography>Author</Typography>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={12}  >
                                        <Grid container columnSpacing={2} direction="row" alignItems="center" >
                                            {(recipe.keywords && typeof keywords === 'array') && recipe.keywords?.map((item,index)=>(
                                                <Grid item key={index}  >
                                                    <div style={{border:'1px solid gray',borderRadius:20,padding:"5px 10px"}}>

                                                        <Typography>{item}</Typography>
                                                    </div>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                    <Grid item spacing={2}  container direction="row" alignItems="center" flexWrap="wrap">
                                        {recipe?.keywords && recipe.keywords
                                        .map((tag,index)=>(
                                            <Grid key={index} item flexShrink={0} >
                                                <Box style={{width:'auto',padding:"5px 20px",borderRadius:35,backgroundColor:'#E9ECFA'}}>
                                                    <Typography textAlign="center">{tag}</Typography>

                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>
                                    
                                    
                                </Grid>
                        
                            
                    
                        </Grid>
                        




                    </Grid> 

                    {renderRecipeType(recipeType)}



                </Grid>




            )}

       
            
     
        </Container>
        </div>
    )
}
