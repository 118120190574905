
import { createMuiTheme, } from '@material-ui/core/styles';
// import { orange } from '@material-ui/core/colors';

export const theme = createMuiTheme({

  // palette: {
  //     primary: {
  //       light: '#ff6434',
  //       main: '#dd2c00',
  //       dark: '#a30000',
  //       contrastText: '#ffffff',
  //     },
  //     secondary: {
  //       light: '#484848',
  //       main: '#212121',
  //       dark: '#000000',
  //       contrastText: '#ffffff',
  //     },
  //   },


});