import styled from '@emotion/styled';
import { Divider, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, {useState, useEffect } from 'react'
import { useContext } from 'react';
import CloudServices from '../../cloud-services-core'
import AppContext from '../../config/AppContext';

const VerifyEmailGrid = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '300px',
  border: '1px solid black',
  height: '400px',
  padding: 20,
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '19vh'
});

export default function VerifyEmail(props) {
    const [verification,setVerification] = useState(false);
    const [loading,setLoading] = useState(false);
    const AppState = useContext(AppContext);

    useEffect(()=>{
        verify();
        AppState.handleNavbar(false);
        return () => AppState.handleNavbar(true);
    },[]);

    const verify = async()=>{
        setLoading(true)
        try {
          const result = await CloudServices.user.verifyEmail(props.location?.state?.oobCode)
          console.log(result)
          // props.navigation.navigate('Main')
          setVerification(true)
          setLoading(false)
        
        } catch (error) {
            setLoading(false)
          console.log(error)
        }
      }
  return loading ? (
      <div style={{width:'100vw',height:'100vh',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
          <h1>loading....</h1>
      </div>
  ) :(
    <Box style={{ width: '100%', height: '100%' }}>
    <VerifyEmailGrid container direction="column" rowSpacing={2}>
      <Grid item>
        <img src='' alt='verify email' style={{ width: 100, height: 100, border: '1px solid red' }} />
      </Grid>
      <Grid item rowSpacing={2}>
        <Typography style={{ textAlign: 'center' }}>Verify your email address!</Typography>
        <small>Please go to you account to validate your email address and confirm that you are the owner of this account.</small>
      </Grid>
      <Divider />
      <Grid item style={{ marginTop: 50 }}>
        Verify Information
      </Grid>
    </VerifyEmailGrid>
    </Box>
  )
}
