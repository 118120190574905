import user from './User/user'
import actions from './Actions/actions'
import courses from './Courses/courses'
import cuisines from './Cuisines/cuisines'
import diets from './Diets/diets'
import ingredients from './Ingredients/ingredients'
import tools from './Tools/tools'
import pantry from './Pantry/pantry'
import shoppingCart from './ShoppingCart/shoppingCart'
import mealPlan from './MealPlan/mealPlan'
import recipes from './Recipes/recipes'
import unitConverter from './Utils/UnitConverter/unitConverter'
import vision from './Vision/vision'
import medical from './Medical/medical';

import { CRUDoperations as crud } from './CRUD/crud'
import allergies from './Allergies/allergies'
import presets from './Presets/presets'
import units from './Units/units'
import IngredientCategory from './Ingredients/IngredientCategory'
import ingredientType from './Ingredients/ingredientType'
import cookingMethod from './CookingActions/cookingMethod'
import prepStyle from './PrepStyles/prepStyles'
import unitConversions from './UnitConversions/UnitConversions'
import articles from './Articles/articles'
import creatorMemberships from './Memberships/membership'
import creatorEarnings from './Earnings/earnings';
import creatorUILayouts from './CreatorFlow/CreatorUILayout/Layouts'
import creatorSubscribers from './CreatorFlow/Subscribers/Subscribers'
import creatorBillings from './CreatorFlow/Billings/Billings'
import creatorCollections from './CreatorFlow/CreatorCollections'
import articleDrafts from './Articles/articleDrafts'




const CloudServices = {
    user,
    mealPlan,
    pantry,
    recipes,
    shoppingCart,
    unitConverter,
    actions,
    courses,
    cuisines,
    diets,
    ingredients,
    tools,
    vision,
    crud,
    medical,
    allergies,
    presets,
    units,
    IngredientCategory,
    ingredientType,
    cookingMethod,
    prepStyle,
    unitConversions,
    articles,
    creatorMemberships,
    creatorEarnings,
    creatorUILayouts, 
    creatorSubscribers,
    creatorBillings,
    creatorCollections,
    articleDrafts

}




export default CloudServices
export {
    user,
    actions,
    courses,
    unitConverter,
    cuisines,
    diets,
    ingredients,
    tools,
    pantry,
    shoppingCart,
    mealPlan,
    recipes,
    medical,
    allergies,
    presets,
    units,
    IngredientCategory,
    ingredientType,
    cookingMethod,
    prepStyle,
    unitConversions,
    articles,
    creatorMemberships,
    creatorEarnings,
    creatorUILayouts,
    creatorSubscribers,
    creatorBillings,
    creatorCollections,
    articleDrafts


}