import React,{useContext,useState,useEffect} from 'react'
import Grid from '@mui/material/Grid'
import  Typography  from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { DataGrid } from '@mui/x-data-grid'
import {makeStyles,styled} from '@mui/styles'

import AddRecipeContext from '../../../config/AddRecipeContext'
import {Switch as CustomSwitch} from '../../../components/Switch'
import Fuse from 'fuse.js'
import { FirebaseApi } from '../../../firebaseConfig'
import { Checkbox, Modal, TextareaAutosize, TextField } from '@mui/material';

// icons
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import CupUpdate from '../../../components/CupUpdate'
import IngredientChip from '../../../components/AddRecipe/IngredientChip'




const useStyles = makeStyles({
    sectionContainer:{
        boxShadow:'0 3px 6px #00000029',
        width:'100%'
    },
    sectionName:{
        padding:"10px 30px",
        backgroundColor:'white',
        borderTopLeftRadius:12,
        borderTopRightRadius:12,
        overflow:'hidden'
    }
})


const MuiDataGrid = styled(DataGrid)(()=>({
    '& .MuiDataGrid-cell':{
        outline:'none'
    },
    '& .MuiDataGrid-cell:focus':{
        outline:'none'
    },
    '& .MuiDataGrid-cell:focus-within':{
        outline:'none'
    },
    '& .MuiDataGrid-cell.MuiDataGrid-cell--editing':{
        boxShadow:'none',
        backgroundColor:'transparent'
    },
    '& .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within':{
        outline:'none'
    }
}))

export default function IngredientInfo(props) {
    const {formik,proMode,changeStepNumber} = useContext(AddRecipeContext)
    const [focusedCell,setFocusedCell] = useState(null)
    const {values,setFieldValue,handleChange} = formik

    const [ingredientsLoading,setIngredientsLoading] = useState(false)
    const [ingredientList,setIngredientList] =useState([])
    const [fuse,setFuse] = useState(null)

    // const [ingredients,setIngredients] = useState([])
    const classes = useStyles()

    const [addIngredient,setAddIngredient] = useState(false)

    const [suggestedIngredient,setSuggestedIngredient]= useState([])


    const [searchIngredient,setSearchIngredient] = useState('')
    const [addIngredientSearch,setAddIngredientSearch] = useState(false)

    const [searchRecomendation,setSearchRecomendations] = useState([])

    // useEffect(()=>{

    //     // getIngredientsList()
        
    // },[])

    const getIngredientsList  =async()=>{
        setIngredientsLoading(true)
        try {
            const ingredientsFromDB = await FirebaseApi["ingredient"].get()

            const ingredientsData = ingredientsFromDB.docs.map((ingredient)=>{
                return {
                    id:ingredient.id,
                    ingredient:ingredient.data().name,
                    ...ingredient.data()
                }
            })
            
            
            setIngredientList(ingredientsData) 
            const fuseInstance = new Fuse(ingredientsData,{
                useExtendedSearch: true,
                keys:["name"]
            })
            setFuse(fuseInstance)
            setIngredientsLoading(false)
            checkRecipeIngredientsInOurDB['exactMatch'](fuseInstance)
        } catch (error) {
            console.log(error)
        }
    }

    const checkRecipeIngredientsInOurDB = {
        exactMatch:(fuse)=>{
            var ingredients =[]
            values.recipeIngredient.map((ingredient=>{
                console.log(ingredient)
                const foundIngredient = checkForExactIngredient(fuse,ingredient.ingredient)
                // console.log(foundIngredient)
                
                
                if(foundIngredient.length > 0){
                    var newIngredient = foundIngredient[0]
                    // console.log("found",newIngredient)
                    ingredients.push({
                        ...newIngredient.item,
                        ingredient:newIngredient.item.ingredient,
                        matchedToDB:true,
                        oldId:ingredient.id
                    });
                   
                    // {
                    // //     console.log(ingredient)
                    //     const newInstructionIngredients = instruction.ingredients.filter(ingredientId=>{
                    //         // console.log(ingredientId,ingredient.id,ingredientId != ingredient.id)
                    //                 if(ingredientId == ingredient.id){
                    //                     console.log(ingredient,newIngredient)
                    //     //             // console.log(ingredientId,ingredient.id,ingredientId != ingredient.id)
                    //             return true
                    //         }
                    //     })
                      
                    //     newInstructionIngredients.push(newIngredient.item.id)
                    //     console.log(newInstructionIngredients)
                          
                    // // //     setFieldValue(`recipeInstructions[${index}].ingredients`,newInstructionIngredients)
                            
                    
                    // })
                    
                }
                else{
                    ingredients.push({
                        ...ingredient,
                        ingredient:ingredient.ingredient.replace(/[^a-zA-Z ]/g, "").trim(),
                        matchedToDB:false
                    })

                }
                // fuse?.search(ingredient.ingredient)
            }))
            console.log(ingredients)
           
            // const ins = values.recipeInstructions.filter((instruction,index)=> {
            //     instruction.ingredients=[]
            //     return instruction
            // })
            // console.log(ins)
             
            // console.log(matched)
            // setIngredients(ingredients)
            // setFieldValue('recipeInstructions',ins)
            setFieldValue('recipeIngredientOriginal',values.recipeIngredient)
            setFieldValue('recipeIngredient',ingredients)

        },
        similarMatch:(fuse)=>{
            var ingredients =[]
            values.recipeIngredient.map((ingredient=>{
                const foundIngredients = checkForIngredientOrSimilarIngredients(fuse,ingredient.ingredient)
            
                ingredients= foundIngredients
                // fuse?.search(ingredient.ingredient)
            }))
            console.log(ingredients)

        }
    
        
    }


    const columns =(index,section) => ([
        {field :"matchedToDB",
        headerName:'matched with DB',
        width:150,
        renderCell:(props)=>{
            return(
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',width:'100%',justifyContent:'space-between'}}>
                    <input type="checkbox" checked={props.row.matchedToDB} defaultChecked={false} />
                    {/* <Checkbox checked={props.row.matchedToDB} defaultChecked={false}  /> */}
            {/* <Typography>{props.row.matchedToDB ? "true" : "false"}</Typography> */}
                    {!props.row.matchedToDB && (
                        <>
                            <RefreshIcon onClick={()=>checkRecipeIngredientsInOurDB['exactMatch'](fuse)} />
                            <AddIcon  onClick={()=>{
                                setAddIngredient(true)
                                
                                }} />
                        </>
                    )}
                </div>
        )}
        },
        { 
            field: 'ingredient',
           headerName: 'Your Ingredients',
          width: 200,
        //   editable:true
          renderCell:(props)=>{
              var suggestions=[]
              const changeIngredientName = (e) =>{
                  console.log(e.target.value,props)
                console.log(checkForIngredientOrSimilarIngredients(fuse,e.target.value))
                //   setSuggestedIngredient(checkForIngredientOrSimilarIngredients(fuse,e.target.value))
                  const updatedRecipeIngredients = values.recipeIngredient.map(ingredient=>{
                      // console.log(ingredient.id===recipeIngredientIndex,ingredient.id,recipeIngredientIndex)
                      if(ingredient.id===props.id){
                            
                            ingredient['ingredient'] = e.target.value
                            return ingredient
                        }
                        return ingredient
                    })
                    console.log(updatedRecipeIngredients)
                    
                    setFieldValue('recipeIngredient',updatedRecipeIngredients)
           
                
                }
                // console.log(suggestions)
              return(
                  <div>
                      
                <input list="ingredients" style={{width:'100%',border:'none',outline:'none',backgroundColor:'transparent'}} type="text" value={props.row.ingredient} onChange={changeIngredientName}
                 />
                 <datalist id="ingredients">
                     {/* <option value="hello">hello</option>
                     <option value="hello">hello</option>
                     <option value="hello">hello</option>
                     <option value="hello">hello</option>
                     <option value="hello">hello</option>
                     <option value="hello">hello</option> */}
                    {suggestedIngredient.length >0 && suggestedIngredient.map(ingredient=>(
                        <option value={ingredient.item.ingredient}>{ingredient.item.ingredient}</option>
                    ))}
                 </datalist>
                 </div>
              )
          }
         },
        { 
            field: 'quantity',
            headerName: 'Quantity', 
            width: 150,
            // editable:true
            renderCell:(props)=>{
                
               
                return(
                    <input style={{width:'100%',border:'none',outline:'none',backgroundColor:'transparent'}} type="number" id={`recipeIngredient[${props.api.getRowIndex(props.id)}].quantity`}  value={props.row.quantity} onChange={formik.handleChange} />
                )
            } 
        },
        { 
            field: 'unit', 
            headerName: 'Units', 
            width: 150,
            renderCell:(props)=>{
               
                
                return(
                <div  style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                {/* <InputLabel id="demo-simple-select-standard-label">Age</InputLabel> */}
                <select id={`recipeIngredient[${props.api.getRowIndex(props.id)}].unit`} onChange={formik.handleChange}  value={props.row.unit} style={{width:'50%',border:'none',backgroundColor:'transparent',outline:'none'}}>
                    <option value="" >Select unit</option>
                    <option value="g">g</option>
                    <option value="kg">kg</option>
                    <option value="ml">ml</option>
                    <option value="pcs">pcs</option>
                    <option value="cubes">cubes</option>
                    <option value="tablespoon">tbsp</option>
                    <option value="teaspoon">tsp</option>
                    <option value="inch">inch</option>
                    <option value="cup">cup</option>

                </select>
                
                </div>
            )} 
        },
        { 
            field: 'preparationStyle',
            headerName: 'Preparation Style', 
            width: 150,
            renderCell:(props)=>{
               

                return (
                    <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        <input style={{width:'100%',border:'none',outline:'none',backgroundColor:'transparent'}} id={`recipeIngredient[${props.api.getRowIndex(props.id)}].prepStyle`} type="text" defaultValue='As it is' value={props.row.prepStyle} onChange={formik.handleChange} />
                    </div>
                )
            }
            
        },
        proMode && (
        {
            field:'Type',
            headerName:'Type',
            width:150 ,
            renderCell:(props)=>{
                const changeIngredientStyle = (e)=>{
                    const updatedRecipeIngredients = values.recipeIngredient.map((ingredient)=>{
                        if(ingredient.id===props.row.recipeIngredientIndex){
                            ingredient['ingredientType'] = e.target.value
                            return ingredient
                        }
                        return ingredient
                    })
                    console.log(updatedRecipeIngredients)
                }
                return(
                    <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        <select value={props.row.ingredientType} id={`recipeIngredient[${props.api.getRowIndex(props.id)}].ingredientType`} onChange={formik.handleChange} style={{width:'50%',border:'none',backgroundColor:'transparent',outline:'none'}} >
                            <option value="">select</option>
                            <option value="Oil">Oil</option>
                            <option value="Water">Water</option>
                            <option value="Spice/Herb">Spice/Herb</option>
                            <option value="Sauce Ingredient">Sauce Ingredient</option>
                            <option value="Flavor">Flavor</option>
                            <option value="Main">Main Ingredient</option>
                            <option value="Seasoning">Seasoning</option>
                            <option value="Garnish">Garnish</option>
                        </select>
                    </div>
                )
            }
        }),
        proMode && ({
            field:'loadingPosition',
            headerName:'Loading Position',
            width:150 ,
            renderCell:(props)=>{
                const changeLoadingPosition = (e)=>{
                    const updatedRecipeIngredients = values.recipeIngredient.map((ingredient)=>{
                        if(ingredient.id===props.row.recipeIngredientIndex){
                            ingredient['loadingPosition'] = e.target.value
                            return ingredient
                        }
                        return ingredient
                    })
                  
                }
                return(
                    <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        <select value={props.row.loadingPosition} id={`recipeIngredient[${props.api.getRowIndex(props.id)}].loadingPosition`} onChange={formik.handleChange} style={{width:'50%',border:'none',backgroundColor:'transparent',outline:'none'}} >
                            <option value="">select</option>
                            <option value="ingredientRack">Ingredient Rack</option>
                            <option value="spiceRack">Spice Rack</option>
                            <option value="liquidStation">Liquid Station</option>
                            
                        </select>
                    </div>
                )
            }

        }),
        { field: 'optional', headerName: 'Optional', width: 150 , renderCell:(props)=>{
        //    console.log(props)
            
            const changeOptionalSwitch=(e)=>{
                
                const newValues = values.recipeIngredient.map((ingredient,ingredientIndex)=>{
                    if(ingredient.id==props.row.recipeIngredientIndex){
                        
                        ingredient[props.field] = e.target.checked
                       
                        return ingredient
                    }
                    return ingredient
                    
                })

                setFieldValue('recipeIngredient',newValues)
               
                // values.recipeInstructions[index].ingredients = newValues
                
            

            }
           

        return (
            <div  style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>

            <CustomSwitch id={`recipeIngredient[${props.api.getRowIndex(props.id)}].optional`} checked={props.row.optional ?? false} value={props.row.optional}  onChange={handleChange} checkedBackgroundColor="#F94C0C" unCheckedBackgroundColor="#ffffff" />
            </div>
        )}},
        { 
            field: 'brandName', 
            headerName: 'Brand Name(optional)', 
            width: 150,
            renderCell:(props)=>{

                const changeBrand = (e)=>{
                    const updatedRecipeIngredients = values.recipeIngredient.map(ingredient=>{
                        if(ingredient.id===props.row.recipeIngredientIndex){
                            ingredient[props.field] = e.target.value
                            return ingredient
                        }
                        return ingredient
                    })
                    setFieldValue('recipeIngredient',updatedRecipeIngredients)
                }
                return(
                    <input 
                        style={{width:'100%',border:'none',outline:'none',backgroundColor:'transparent'}} 
                        type="text" value={props.row.brandName} 
                        onChange={(e)=>changeBrand(e,props.row.recipeIngredientIndex)} 
                    />
                )
            } 
        },
        { field: "delete",headerName:"delete",width:150,renderCell:(value)=>focusedCell===value.id ? (
            <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',}}>
                

                <DeleteIcon onClick={()=>deleteIngredient(value.id,index,value.row.recipeIngredientIndex)}  style={{color:'#FA3131',border:"1px solid #FA3131",cursor:'pointer',}}/>
            
            </div>
            ) : '',}
      ]);


  


      const deleteIngredient = (id,index,recipeIngredientIndex) =>{

        const updatedValues = values.recipeIngredient.filter((ingredient)=> ingredient.id !== id)
        
     
        setFieldValue('recipeIngredient',updatedValues)

        const updateRecipeInstrucitons = values.recipeInstructions[index].ingredients.filter(ingredient=>ingredient !== recipeIngredientIndex)
      
        setFieldValue(`recipeInstructions[${index}].ingredients`,updateRecipeInstrucitons)

       
      }


    const checkForIngredientOrSimilarIngredients = (fuse,name)=>{
      
        return fuse.search(`'${name.replace(/[^a-zA-Z ]/g, "").trim()}`)
    }
    const checkForExactIngredient = (fuse,name)=>{
        
        return fuse.search(`="${name.replace(/[^a-zA-Z ]/g, "").trim()}"`)
    }

    const editIngredientsTable = (e,index)=>{
 
        checkForIngredientOrSimilarIngredients(fuse,e.value)
        // const newValues = values.recipeIngredient.map((section,sectionIndex)=>{
          
        //     if(section.id===e.id){
        //         section.ingredient = e.value
        //         return section
                
        //     }
        //     return section
        // })

        // console.log(newValues)
  
        // setFieldValue('recipeIngredient',newValues)

    }


    const onCellFocus = (e) =>{
        // console.log(e)

        setFocusedCell(e.id)


    }


    const saveToIngredients = async(data) =>{
       
        try {
           
            await FirebaseApi['ingredient'].add(data)
            getIngredientsList()
            setAddIngredient(false)
        } catch (error) {
            console.log(error)
        }
        
    }


    const addingIngredientForSection =(e) =>{
        if(e.inputType == "insertReplacementText" || e.inputType == null) {
            console.log(e.target.value)
        }
        console.log(fuse.search(e.target.value))
        setSearchRecomendations(fuse.search(e.target.value))
        
        console.log(e.key)
        setSearchIngredient(e.target.value)

    }

    const removeIngredient = (item,index) =>{
        console.log(item)
        const newIngredients = [...values.recipeIngredient,{...item,matchedToDB:true}]
        const newSectionIngredients = [...values.recipeInstructions[index].ingredients,item.id]
        console.log(newSectionIngredients)
        setFieldValue('recipeIngredient',newIngredients)
        setFieldValue(`recipeInstructions[${index}].ingredients`,newSectionIngredients)

    }
    
 
    return ingredientsLoading ? (
        <div>
            <p>Loading...</p>
        </div>
    ):(
        <Grid container spacing={2} >
            {/* <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>

            <div>
                <p><b><u>matched</u></b></p>
                {values.recipeIngredient.map(ingredient=>{
                    if(ingredient.matchedToDB){
                        return(
                            <p>{ingredient.ingredient}</p>
                        )
                    }
                })}
            </div>
            <div>
                <p><b><u>un matched</u></b></p>
                {values.recipeIngredient.map(ingredient=>{
                    if(!ingredient.matchedToDB){
                        return(
                            <p>{ingredient.ingredient}</p>
                        )
                    }
                })}
            </div>
            </div> */}
                            
            {/* {values.recipeInstructions.length > 0 ? values.recipeInstructions.map((section,index)=>{
                
                const ingredients = values.recipeIngredient.filter(ingredient=>{
                   
                    return section.ingredients?.some((ingredientId)=>{
                    
                    return ingredientId===ingredient.id
                })})
            
                return( */}
            <Grid xs={12} item  >
                <Box className={classes.sectionContainer}>
                   
                    <Box className={classes.sectionName} >
                       

                        {/* <Typography>{index+1}.{section.name}</Typography>
                        <Button onClick={()=>setAddIngredientSearch(true)}>Add Ingredient</Button>
                        {addIngredientSearch && (
                            <div style={{overflow:'hidden',width:'100%'}}>
                                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>

                            <input  type="text" value={searchIngredient} onChange={addingIngredientForSection} />
                                <Button onClick={()=>setAddIngredientSearch(false)} >Close</Button>
                                </div>
                            <div  id="ingre" style={{display:'flex',flexDirection:'row',alignItems:'center',overflow:'scroll',width:'88vw'}}>
                                {searchRecomendation.length > 0 && searchRecomendation.map(({item})=>(
                                    <IngredientChip key={item.id} image={item?.imageUrl? item.imageUrl : ''} name={item.ingredient} shiftToIngredients={()=>removeIngredient(item,index)}   />
                                  

                                )
                                )}

                            </div>
                            </div>
                        )} */}
                        
                    </Box>
                
                    <Box transition=".3s linear" width="100%" >

                    
                        <MuiDataGrid 
                            hideFooter 
                            onCellLeave={(e)=>setFocusedCell(null)}
                            onCellEnter={(e)=>onCellFocus(e)} 
                            onCellEditStart={(e)=>{
                                console.log(e)
                                if(e.key==="Enter"){
                                    // let value1 = e.target.text.slice(0, e.target.selectionStart);
                                    // let value2 = step.text.slice(e.target.selectionStart, step.text.length)
                                }
                            }} 
                            onCellEditCommit={(e)=>editIngredientsTable(e,)} 
                            autoHeight 
                            columns={[...columns()]} 
                            rows={values.recipeIngredient}  
                            
                        />
                    </Box>
                    
                </Box>

            </Grid>
            {/* )}) : (
                <Grid container>
                    <Grid item>
                        <Button variant="outlined" onClick={()=>changeStepNumber(1)}>Add a section</Button>
                    </Grid>
                </Grid>
            )} */}


            <Modal
            open={addIngredient}
            onClose={()=>setAddIngredient(false)}
            style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}
            >
                <Box style={{width:'60%',height:'clamp(415px,60%,47vh)',backgroundColor:'white',borderRadius:6}}>
                        <Typography >Enter details for adding ingredient</Typography>
                    <Box style={{height:'100%',padding:20}}>
                       
                        <CupUpdate  initialValues={{imageUrl:"",name:"",description:""}} dataStructure={["imageUrl",'name',"description"]} triggerAction={(data)=>saveToIngredients(data)} />
                    </Box>
                </Box>


            </Modal>
            


    </Grid>
    )
}
