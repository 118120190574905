import { Grid, Typography } from '@material-ui/core';
import { styled } from '@mui/styles';

export const ProfileLeftContainerStep1 = styled(Grid)({
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    flexDirection: 'column',
    width: 'inherit',
    background: '#BD6B5C',
});

export const ProfileLeftContainerStep2 = styled(Grid)({
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    flexDirection: 'column',
    width: 'inherit',
    background: '#ACCEF7',
});

export const ProfileLeftContainerStep3 = styled(Grid)({
    height: '100vh',
    background: '#BD6B5C',
    width: 'inherit',
});

export const ProfileRightContainerFormElements = styled(Grid)({
    marginLeft: 35,
    marginTop: 20,
    width: 500,
    height: '60%',
    // border: '1px solid black'
});

export const ProfileRightContainerButton = styled(Grid)({
    marginTop: 20,
    marginLeft: 20,
    width: 'fit-content',
    height: 'fit-content',
    background: '#707070',
    borderRadius: 5,
    padding: '5px 10px',
})

export const ProfileHeader = styled(Grid)({
    width: '100%',
    height: '20vh',
});

export const ProfileHeaderTypography = styled(Typography)({
    fontSize: '32px',
    marginTop: 40,
    marginLeft: 40,
    height: '8vh'
});

export const ProfileStylesCustomGrid = styled(Grid)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '5px',
    height: '40px',
    padding: '5px',
    border: '1px solid #DBDCDD',
    borderRadius: '5px',
});

export const ProfileLayoutCustomGrid = styled(Grid)({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '5px',
    height: '100px',
    padding: '5px',
    width: 'fit-content',
    border: '1px solid #DBDCDD',
    borderRadius: '5px',
});
