import React, { useEffect, useState, useContext } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { makeStyles } from '@mui/styles'
import { ButtonGroup, TextField, TextareaAutosize, ToggleButtonGroup, ToggleButton } from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CloseIcon from '@mui/icons-material/Close';
import { Switch as CustomSwitch } from '../../../components/Switch'
import axios from 'axios'
import moment from 'moment'
import CancelIcon from '@mui/icons-material/Cancel';
import Collapse from '@mui/material/Collapse';
import { useSpring, animated } from 'react-spring';
import PropTypes from 'prop-types';
import { FieldArray, FormikProvider } from 'formik';
import { useHistory } from 'react-router-dom'

// Internal Imports
import LinkIcon from '../../../assets/LinkIcon.svg';
import ImageReplace from '../../../assets/ImageReplace.jpg';
import CreateRecipeContext from '../../../config/CreateRecipeContext'

// cloud services
import AppContext from '../../../config/AppContext'
import uploadImage from '../../../utils/saveImageToStorage'

function TransitionComponent(props) {
    const style = useSpring({
        from: {
            opacity: 0,
            transform: 'translate3d(20px,0,0)',
        },
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
        },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

TransitionComponent.propTypes = {
    /**
     * Show the component; triggers the enter or exit states
     */
    in: PropTypes.bool,
};

// styles
const useStyles = makeStyles((theme) => ({
    // textfield
    textField: {

        height: '48px',
        border: '2px solid transparent',
        "&.Mui-focused": {
            border: "2px solid #397EF5",
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
            }
        }
    },

    linkTextField: {
        border: 'none',
        height: 42,
        padding: -8,
        "&.MuiInput-input": {
            // border: '2px solid red',
            // background: 'red'
        },
        "&.Mui-focused": {
            border: "2px solid #397EF5",
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
            }
        }
    },

    typography: {
        "& .MuiTypography-root": {

            fontFamily: 'Product Sans',
        }
    },

    textArea: {
        padding: '16px 3px', borderRadius: 3, border: 'none', display: 'flex', justifyContent: 'center', alignContent: 'center',
        '&:hover': {
            border: 'none',
            outline: 'none'
        },
        '&:focus': {
            outline: 'none'
        },
        resize: 'none',
        height: 101,
    },

    hideScrollBar: {
        '&::-webkit-scrollbar': {
            display: 'none',
        }
    },

    // buttons
    buttonTextField: {
        alignContent: 'center',

        width: "50px",
        outline: 'none',
        color: '#000',



    },

    inputRoot: {
        '&$disabled': {
            color: '#000'
        },
    },


    /// meta data
    tags: {
        padding: "3px 10px",
        borderRadius: 24,
        display: 'flex',
        flexDirection: 'row',
        alignItems: "center",
        transition: ' .5s ease-in-out',
        backgroundColor: '#e6e6e6',
        width: 100,
        margin: 2,
    },
    tagTextField: {
        border: 'none',
        outline: 'none',
        backgroundColor: 'transparent',
        padding: 5,
    },
    tagClose: {
        color: '#898989',
        cursor: 'pointer',
        height: 20,
        marginBottom: 4,



    },




    // imageModal
    modalHeadingContainer: {
        // backgroundColor:theme.palette.secondary.main,

        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 6
    },
    imageHeading: {
        padding: "0 25px"
    },
    emptyImagesContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 124
    },
    imagesContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflowX: 'scroll',
        flexWrap: 'nowrap',
        padding: "10px 20px"
    },
    image: {
        width: 124,
        height: 124,
        borderRadius: 8,
        flexShrink: 0,
        marginRight: 10,
        cursor: 'pointer'
    },
    dragContainer: {
        height: '16vh',
        maxHeight: '20vh',
        padding: "10px 25px",
    },
    drag: {
        borderRadius: 10,
        border: '1px dashed black',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
}))

const MetaDataCreation = (props) => {
    const AppState = useContext(AppContext)
    // Global
    const classes = useStyles()
    const { formik, urlRecipeFetch, setURLRecipeFetched } = useContext(CreateRecipeContext)

    const { values, handleChange, setFieldValue } = formik

    const [imagesLoading, setImagesLoading] = useState(false)

    const [keywordOnHover, setKeywordOnHover] = useState(null)
    const [newKeyword, setNewKeyword] = useState("");

    const [recipeUrl, setRecipeUrl] = useState(values.recipeUrl ?? ' ');

    const [difficultyLevelButton, setDifficultyLevelButton] = useState(values.difficultyType ?? "LOW");
    const [alignment, setAlignment] = useState('');

    const [wrongPrepFormat, setWrongPrepFormat] = useState(false);
    const [wrongCookFormat, setWrongCookFormat] = useState(false);

    // display images
    const [currentMetaImage, setCurrentMetaImage] = useState('');

    // Fetchig Recipe
    const [fetchingRecipe, setFetchingRecipe] = useState(false)
    const [createRecipe, setCreateRecipe] = useState(false)
    const history = useHistory();
    const { '@id': recipeLink } = values;

    useEffect(() => {
        if (recipeLink && values?.recipeUrl != "") {
            setFieldValue('recipeUrl', recipeLink);
            setRecipeUrl(recipeLink);
        }
    }, [urlRecipeFetch])

    function getBase64(file) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            console.log(reader);
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
            }
        })
    }


    const collectImagesWithIndex = async (e, sectionIndex = 0, stepIndex = 0) => {
        setImagesLoading(true)

        var imageObject = {};
        if (formik.values.images && [...Object.keys(formik.values.images)].length >= 0) {
            await Promise.all([...Array(e.target.files.length).keys()].map(async (item, index) => {
                const imageDetails = e.target.files[item];
                const imageUrl = await uploadImage("recipe", imageDetails);
                imageObject[`img-${sectionIndex}-${stepIndex}-${[...Object.keys(formik.values.images)].length + index}`] = imageUrl
            }));
        }
        else {
            await Promise.all([...Array(e.target.files.length).keys()].map(async (item, index) => {
                const imageDetails = e.target.files[item];
                const imageUrl = await uploadImage('recipe', imageDetails)
                imageObject[`img-${sectionIndex}-${stepIndex}-${index}`] = imageUrl
            }));
        }

        if (sectionIndex === 0 && stepIndex === 0) {
            setFieldValue('images', { ...formik.values.images, ...imageObject })
        }

        if (sectionIndex >= 1 && stepIndex >= 1) {
            setFieldValue(`recipeInstructions[${sectionIndex - 1}].itemListElement[${stepIndex - 1}].images`, { ...formik.values.recipeInstructions[sectionIndex - 1].itemListElement[stepIndex - 1].images, ...imageObject })
        }
    }

    const selectMetaImage = (file) => {
        setFieldValue('image', [values.images[file]])
        setCurrentMetaImage(file)
    }

    const removeMetaImage = (e, file) => {
        delete values.images[currentMetaImage]
        setFieldValue('images', values.images)
    }

    const getRecipeFromUrl = (e) => {
        setFetchingRecipe(true)
        axios.get(`https://us-central1-riku-staging.cloudfunctions.net/recipeScraper?url=${recipeUrl}`)
            .then(result => {
                // As soon as getting data we are updating the data to state
                setFetchingRecipe(false)
                setCreateRecipe(false)
                // and immediatley refining the data we got back for working accordingly
                history.push(`/createRecipe/${result.data[1]}?collection=${result.data[0]}`)
                setURLRecipeFetched(true)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleDifficultyButton = (e) => {
        setDifficultyLevelButton(e.target.value);
    }

    const addNewKeyword = (event, insert) => {
        setNewKeyword(event.target.value);
    }

    const handleDiffChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const handlePrepTime = (e) => {
        var hours = moment(e.target.value, 'HH mm ss').hours();
        var minutes = moment(e.target.value, 'HH mm ss').minutes();
        var hourMinutes = moment.duration(hours, 'h').asMinutes();
        var totalPrep = moment.duration(hourMinutes + minutes, 'm').toISOString();
        setFieldValue("prepTime", totalPrep);

        // for getting from PT
        var prepTimeInverse = moment.duration('PT5M');
        if (prepTimeInverse._milliseconds >= 1) {
            var secondsValue = prepTimeInverse._milliseconds / 60000;
        }
    }

    const handleCookTime = (e) => {
        var hours = moment(e.target.value, 'HH mm ss').hours();
        var minutes = moment(e.target.value, 'HH mm ss').minutes();
        var hourMinutes = moment.duration(hours, 'h').asMinutes();
        var totalCook = moment.duration(hourMinutes + minutes, 'm').toISOString();
        setFieldValue("cookTime", totalCook);

        // for getting from PT
        var prepTimeInverse = moment.duration(values.cookTime);
        if (prepTimeInverse._milliseconds >= 1) {
            var secondsValue = prepTimeInverse._milliseconds / 60000;
        }
    }

    const checkPrepString = (inputElem) => {
        var regex = /^\d+[hms]$|^\d+[h]\s\d+[m](?:\s\d+[s])?$|^\d+m\s\d+s$/gm
        // var regex = new RegExp("/^\d+[hms]$|^\d+[h]\s\d+[m](?:\s\d+[s])?$|^\d+m\s\d+s$/", "gm");

        if (!regex.test(inputElem)) {
            setWrongPrepFormat(true);
            console.log("Error in Code")
        } else {
            console.log(inputElem, regex.test(inputElem))
        }
    }

    const checkCookString = (inputElem) => {
        var regex = /^\d+[hms]$|^\d+[h]\s\d+[m](?:\s\d+[s])?$|^\d+m\s\d+s$/gm
        // var regex = new RegExp("/^\d+[hms]$|^\d+[h]\s\d+[m](?:\s\d+[s])?$|^\d+m\s\d+s$/", "gm");

        if (!regex.test(inputElem)) {
            setWrongCookFormat(true);
            console.log("Error in Code")
        } else {
            console.log(inputElem, regex.test(inputElem))
        }
    }

    const showCloseOnEnter = (index) => {
        setKeywordOnHover(index)
    }

    return (
        <Grid display="flex" flexDirection="row" justifyContent="space-around" style={{ marginLeft: 135, marginTop: 44, background: "#FFFFFF", borderRadius: "1%", maxWidth: 1238 }} classes={{ root: classes.typography }} >

            {/* Image, Featured */}
            <Grid item style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 50, marginLeft: 23 }}>
                <Grid item >
                    {values?.image?.length > 0 && values?.image[0] ?
                        <img src={values?.image[0]} style={{ height: '330px', width: '330px', borderRadius: 4 }} alt='meta-images' />
                        : <img src={ImageReplace} style={{ height: '330px', width: '330px', borderRadius: 4 }} alt='meta-default-images' />
                    }
                </Grid>

                {/* Featured */}
                <Grid item container spacing={1} direction="column" margin="4px" paddingLeft='10px' >
                    <Grid style={{ width: "330px", border: '1px solid #E2E2E2', marginLeft: '3px', borderRadius: 8, display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                        <Grid item>
                            <Box style={{ width: '258px', display: 'flex', flexDirection: 'row', overflowX: 'auto', alignItems: 'center', height: '72px' }} className={classes.hideScrollBar}>
                                {[...Object.keys(values.images)].map((file, index) => {
                                    const element = values
                                    return (
                                        <Grid item key={index} >
                                            <Box style={{ position: 'relative' }}>
                                                <Grid container direction="row" alignItems="center">
                                                    <Grid item key={file.id} onClick={() => selectMetaImage(file, element)}  >
                                                        <img src={values.images[file]} style={{ width: '65px', height: '65px', borderRadius: 8, margin: 2, border: currentMetaImage === file ? '2px solid #2F7EEF' : 'none' }} alt='coursal' />
                                                    </Grid>
                                                </Grid>
                                                {currentMetaImage === file && (
                                                    <Box style={{ position: 'absolute', top: 3, right: 0 }}>
                                                        <Box style={{ display: 'flex', flexDirection: 'row', justifyItems: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.5)', borderRadius: '50%', padding: 2 }}>
                                                            <CloseIcon onClick={(e) => removeMetaImage(e, file)} style={{ fontSize: 16, color: 'white' }} />
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Box>
                                        </Grid>
                                    )
                                })}
                            </Box>
                        </Grid>
                        <Grid item>
                            <label htmlFor='upload'>
                                <div style={{ width: '70px', height: '72px', backgroundColor: 'white', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', borderRadius: 10 }}>
                                    <input type="file" accept="image/png, image/jpeg" multiple onClick={e => collectImagesWithIndex(e)} id="upload" style={{ display: 'none',cursor:'pointer' }} />
                                    <AddAPhotoIcon style={{ color: '#1B76D2', border: 'none' }} />
                                </div>
                            </label>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/* Name, Link, FeaturedImages */}
            <Grid item marginTop="56px" width="450px" >
                {/* Name */}
                <Grid item container spacing={1} direction="column" margin='4px' >
                    <Grid item>
                        <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', }}>Recipe Name</Typography>
                    </Grid>
                    <Grid item>
                      <TextField 
                        autoComplete='off' 
                        placeholder="Enter your recipe name" 
                        type="text" 
                        style={{ width: '430px' }} 
                        value={values.name} 
                        id="name" 
                        onChange={handleChange} 
                        InputProps={{ className: classes.textField }} 
                        required
                      />
                    </Grid>
                </Grid>

                {/* Author Name */}
                <Grid item container spacing={1} direction='column' margin='4px' sx={{ marginTop: "15px" }}>
                    <Grid item>
                        <Typography 
                            sx={{
                                color: '#292929', 
                                fontSize: 17,
                                fontWeight: 'bold'
                            }}
                        >
                            Author Name
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TextField 
                            autoComplete='off'
                            type='text'
                            placeholder='Author Name'
                            defaultValue={values?.author?.name}
                            onChange={e => setFieldValue('author name', e.target.value)}
                            id="name"
                            InputProps={{ className: classes.textField}}
                            sx= {{ width: '430px' }}
                        />
                    </Grid>
                </Grid>

                {/* Link */}
                <Grid item container spacing={1} direction="column" margin='4px' >
                    <Grid item>
                        <Typography 
                            style={{
                                color: '#292929', 
                                fontSize: 17, 
                                fontWeight: 'bold',
                            }}
                        >
                                Source Link
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', borderRadius: 8, width: 430 }}>
                            <Box item>
                                <TextField 
                                    autoComplete='off' 
                                    placeholder="link" 
                                    type="text" 
                                    value={recipeUrl} 
                                    onChange={(e) => {
                                        setFieldValue("recipeUrl", ''); 
                                        setRecipeUrl(e.target.value); 
                                    }} 
                                    InputProps={{ className: classes.linkTextField }}
                                    style={{ border: 'none', width: 575, height: '48', marginRight: '4px' }}
                                 />
                            </Box>
                            <Box item onClick={() => getRecipeFromUrl(recipeUrl)}>
                                <img 
                                    src={LinkIcon}
                                    style={{
                                        width: 40,
                                        height: 48,
                                        marginTop: 3
                                    }}
                                    alt="url link"
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                {/* Hashtags */}
                <Grid item>
                    <Grid item container spacing={1} direction="column" margin='4px' >
                        <Grid item>
                            <FormikProvider value={formik}>
                                <FieldArray name="keywords">
                                    {(keywordHelpers) => {
                                        const { remove, insert } = keywordHelpers
                                        return (
                                            <Grid item container spacing={1} style={{ display: 'flex', flexDirection: "column", marginTop: "-17px", }}  >
                                                <Grid item>
                                                    <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', }}>Hashtags</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Grid style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: 775, border: "2px solid rgb(235 235 235)", borderRadius: 5 }}>
                                                        <Grid item >
                                                            <Grid style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', paddingLeft: 14, paddingTop: 7, }}>
                                                                {
                                                                    values.keywords && typeof values.keywords === 'object' && values.keywords?.map((item, keywordIndex) => (
                                                                        <Grid item key={keywordIndex}  >
                                                                            <Grid className={classes.tags} key={keywordIndex} onMouseEnter={() => showCloseOnEnter(keywordIndex)} onMouseLeave={() => setKeywordOnHover(null)} >
                                                                                <input className={classes.tagTextField} type="text" id={`keywords[${keywordIndex}]`} value={item} onChange={handleChange} style={{ width: 65 }} />
                                                                                {/* {keywordOnHover === keywordIndex && <p className={classes.tagClose} onClick={() => remove(keywordIndex)}>X</p>} */}
                                                                                {<Grid className={classes.tagClose} onClick={() => remove(keywordIndex)}>
                                                                                    <CancelIcon />
                                                                                </Grid>
                                                                                }
                                                                            </Grid>
                                                                        </Grid>
                                                                    ))
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                        <Grid>
                                                            <TextareaAutosize 
                                                                item 
                                                                placeholder="Add Tags" 
                                                                type="text" 
                                                                value={newKeyword} 
                                                                onChange={(e) => addNewKeyword(e)} 
                                                                onKeyPress={(e) => {
                                                                    if (e.key === "Enter") {
                                                                        e.preventDefault();
                                                                        insert(values.keywords.length + 1, newKeyword)
                                                                        setNewKeyword("")
                                                                    }
                                                                }}
                                                                className={classes.textArea}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    }}
                                </FieldArray>
                            </FormikProvider>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/* dificulty */}
            <Grid style={{ display: 'flex', flexDirection: "column", marginTop: "-31px", width: ' 417px', maxWidth: 420 }}>
                <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                    {/* Difficulty, PrepTime */}
                    <Grid item marginTop="60px" marginLeft="10px" marginRight="10px">

                        {/* Difficulty */}
                        <Grid item container spacing={1} direction="column" margin="4px" marginTop="40px" width="158px" >
                            <Grid item sm={6} xs={5}>
                                <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', }}>Difficulty Level</Typography>
                            </Grid>
                            <Grid item sm={6} xs={7}>
                                <ToggleButtonGroup size="small" value={difficultyLevelButton} onChange={handleDiffChange}>
                                    <ToggleButton value="LOW"
                                        style={difficultyLevelButton === "LOW" ? { borderTopLeftRadius: "20%", borderBottomLeftRadius: "20%", fontWeight: "600", background: "rgb(134 170 243 / 43%)", color: "#397DF3", border: "1px solid #387DF2" } : { borderTopLeftRadius: "20%", borderBottomLeftRadius: "20%" }}
                                        onClick={(e) => { setFieldValue('difficultyType', e.target.value); handleDifficultyButton(e); }}>LOW</ToggleButton>
                                    <ToggleButton value="MID"
                                        style={difficultyLevelButton == "MID" ? { fontWeight: "600", background: "rgb(134 170 243 / 43%)", color: "#397DF3", border: "1px solid #387DF2" } : {}}
                                        onClick={(e) => { setFieldValue('difficultyType', e.target.value); handleDifficultyButton(e); }}>MID</ToggleButton>
                                    <ToggleButton value="HIGH"
                                        style={difficultyLevelButton == "HIGH" ? { borderTopRightRadius: "20%", borderBottomRightRadius: "20%", fontWeight: "600", background: "rgb(134 170 243 / 43%)", color: "#397DF3", border: "1px solid #387DF2" } : { borderTopRightRadius: "20%", borderBottomRightRadius: "20%", }}
                                        onClick={(e) => { setFieldValue('difficultyType', e.target.value); handleDifficultyButton(e); }}>HIGH</ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                        </Grid>

                        {/* Prep Time */}
                        <Grid item container spacing={1} direction="column" style={{ margin: 4, marginTop: 15 }} >
                            <Grid item >
                                <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', }}>Prep Time</Typography>
                            </Grid>
                            <Grid item>
                                <TextField 
                                    autoComplete='off' 
                                    placeholder="1h 30m 30s" 
                                    onClick={() => setWrongPrepFormat(false)} 
                                    type="text" 
                                    id="prepTime" 
                                    value={values?.prepTime} 
                                    onBlur={(e) => { 
                                        handlePrepTime(e);
                                        handleChange(e);
                                        checkPrepString(e.target.value)
                                    }}
                                    onChange={(e)=> setFieldValue('prepTime', e.target.value)}
                                    InputProps={{ className: classes.textField }} 
                                    style={{ borderRadius: 8, width: 158, }} 
                                />
                            </Grid>
                            <Grid item>
                                {
                                    //wrongPrepFormat
                                    false
                                        ? <>
                                            <Typography style={{ color: '#FF5353', fontFamily: 'Product Sans', marginLeft: 8 }}> Format in 1h 2m 30s </Typography>
                                        </>
                                        : <></>
                                }
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Servings, cookTime */}
                    <Grid item marginTop="60px" marginLeft="10px" style={{ paddingTop: 2 }}>
                        <Grid item container spacing={1} direction="column" marginTop="40px" >
                            <Grid item>
                                <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', }}>Serving Size</Typography>
                            </Grid>
                            <Grid item style={{ marginTop: -5 }} >
                                <ButtonGroup size="small" >
                                    <Button size="small" disabled={values.servings < 2} style={{ borderTopLeftRadius: "20%", borderBottomLeftRadius: "20%", fontWeight: "600", background: "#E5E5E5", color: "#898989", border: '1px solid #E5E5E5', marginRight: 0 }} onClick={() => setFieldValue("servings", values.servings - 1)}>-</Button>
                                    <TextField autoComplete='off' type="text" size="small" onChange={(e) => setFieldValue("servings", e.target.value)} value={`0${values.servings !== undefined ? values.servings : 3}`} disabled={true} InputProps={{ className: classes.buttonTextField }} sx={{
                                        '& input:disabled': {
                                            color: 'red',
                                            '-webkit-text-fill-color': 'black',
                                        },
                                        "&.Mui-focused": {
                                            outline: "none",
                                            border: 'none',
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                                outline: 'none'
                                            }
                                        },
                                        "&.MuiTextField-root": {
                                            border: 'none',
                                            outline: 'none',
                                        },
                                    }} />
                                    {<Button size="small" disabled={values.servings > 5} style={{ borderTopRightRadius: "20%", borderBottomRightRadius: "20%", fontWeight: "600", background: "#E5E5E5", color: "#898989", border: '1px solid #E5E5E5', marginLeft: 0 }} onClick={() => setFieldValue("servings", values.servings + 1)}>+</Button>}
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                        <Grid item container spacing={1} direction="column" style={{ margin: 4, marginTop: 18 }} >
                            <Grid item>
                                <Typography 
                                    style={{ 
                                        color: '#292929', 
                                        fontSize: 17, 
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Cook Time
                                </Typography>
                            </Grid>
                            <Grid item>
                                <TextField 
                                    autoComplete='off' 
                                    onClick={() => setWrongCookFormat(false)} 
                                    placeholder="1h 30m 30s" 
                                    type="text" 
                                    id="cookTime" 
                                    value={values?.cookTime} 
                                    onBlur={(e) => { 
                                        handleCookTime(e); 
                                        handleChange(e); 
                                        checkCookString(e.target.value) 
                                    }} 
                                    onChange={(e) => setFieldValue("cookTime", e.target.value)}
                                    InputProps={{ className: classes.textField }} 
                                    style={{ borderRadius: 8, width: 158, marginTop: -2 }} 
                                />
                            </Grid>
                        </Grid>
                        <Grid item spacing={1} direction="column" style={{ width: 158, marginTop: 35 }} >
                            <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignContent: "center", marginBottom: 10 }}>    
                                <Typography 
                                    style={{ 
                                        fontSize: 17,
                                        fontWeight: "bold", 
                                        color: 'black',
                                        width: 'fit-content'
                                    }}
                                >
                                    Klynk Recipe
                                </Typography>
                                <CustomSwitch 
                                    style={{ marginTop: 8 }}
                                    checked={values?.klynkAppRecipe} 
                                    onChange={() => { setFieldValue("klynkAppRecipe", !values.klynkAppRecipe); }} 
                                />
                            </Grid>
                            <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignContent: "center", marginTop: 10 }}>    
                                <Typography 
                                    style={{ 
                                        fontSize: 17,
                                        fontWeight: "bold", 
                                        color: 'black',
                                        width: 'fit-content'
                                    }}
                                >
                                    Pro Version
                                </Typography>
                                <CustomSwitch 
                                    style={{ marginTop: 8 }}
                                    checked={values?.proMode} 
                                    onChange={() => { setFieldValue("proMode", !values.proMode); }} 
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    )
}

export default MetaDataCreation