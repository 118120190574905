//react imports
import React, { useEffect, useState, useContext } from 'react'

//material styles
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import { makeStyles } from '@mui/styles'
import { TextField } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { styled } from '@mui/material/styles'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import RemoveIcon from '@mui/icons-material/Remove';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import Collapse from '@mui/material/Collapse';

// axios
import axios from 'axios'

//routers
import { Link, useHistory, useLocation } from 'react-router-dom'

//drag
import { DragDropContext } from "react-beautiful-dnd";

//recipe ingredients
import { parse } from 'recipe-ingredient-parser-v2'

// web.cjs is required for IE11 support
import { useSpring, animated } from 'react-spring';
import PropTypes from 'prop-types';

/// Modal
import Divider from '@mui/material/Divider';

//formik
import { useFormik, FormikProvider } from 'formik';

// Internal Imports
import BackButton from '../../../assets/CreatorScreen/BackButton.svg';
import generateId from '../../../components/GenerateId';
import StepDetailContext from '../../../config/StepDetailContext'
import Gallery from '../../../assets/NavBar/Gallery.svg';
import Settings from '../../../assets/NavBar/Settings.svg';
import LinkIcon from '../../../assets/LinkIcon.svg';
import CloseIconn from '../../../assets/CloseIconn.svg';
import ModalGallery from '../../../assets/ModalGallery.svg';
import SaveRecipe from '../../../assets/SaveRecipe.svg';
import Publish from '../../../assets/Publish.svg';
// import MuiButton from '../../../components/MuiButton'

//recipe file imports
import CreateRecipeContext from '../../../config/CreateRecipeContext'
import InstructionsAccordion from './RecipeInstructions/index'
import MetaDataCreation from './MetaDataCF'

// cloud services
import CloudServices from '../../../cloud-services-core'
import { FirebaseApi, storageRef } from '../../../firebaseConfig'
import AppContext from '../../../config/AppContext'
import TurnCreator from '../../CreatorsPage/CreatorMode'
// import AutoSave from './AutoSave'
import IngridentsIndex from './IngredientsSection.js'

function TransitionComponent(props) {
    const style = useSpring({
        from: {
            opacity: 0,
            transform: 'translate3d(20px,0,0)',
        },
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
        },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

TransitionComponent.propTypes = {
    /**
     * Show the component; triggers the enter or exit states
     */
    in: PropTypes.bool,
};

const StyledTreeItem = styled((props) => (
    <TreeItem {...props} />
))(({ theme }) => ({

    [`& .${treeItemClasses.iconContainer}`]: {
        '& .close': {
            opacity: 0.3,
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px solid black`,
    },

    [`& .${treeItemClasses.label}`]: {
        fontFamily: 'inherit!important',


    }
}));

// drawer
const drawerWidth = `240px`;
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({

        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const openedMixin = (theme) => ({
    marginTop: 20,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    zIndex: 1

});

const closedMixin = (theme) => ({
    marginTop: 20,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
    zIndex: 1,
});

// styles
const useStyles = makeStyles((theme) => ({

    // textfield
    textField: {

        height: 42,
        border: '2px solid transparent',
        "&.Mui-focused": {
            border: "2px solid #397EF5",
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
            }
        }
    },


    linkTextField: {
        border: 'none',
        height: 42,
        padding: -8,
        "&.MuiInput-input": {
            // border: '2px solid red',
            // background: 'red'
        },
        "&.Mui-focused": {
            border: "2px solid #397EF5",
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
            }
        }
    },

    typography: {
        "& .MuiTypography-root": {

            fontFamily: 'Product Sans',
        },


    },

    hideScrollBar: {
        '&::-webkit-scrollbar': {
            display: 'none'
        },

    },



    /// Loading
    importLoading: {
        position: 'relative',
        width: 100,
        height: 100,
        backgroundColor: 'gray',
        borderRadius: '50%'
    },
    importLoadingRing: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: 100,
        height: 100,
        border: '5px solid black',
        borderRadius: '50%',
        transform: 'scale(1)',
        animation: "$rotate 2s linear infinite alternate"

    },

    // imageModal
    modalHeadingContainer: {
        // backgroundColor:theme.palette.secondary.main,

        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 6
    },
    imageHeading: {
        padding: "0 25px"
    },
    emptyImagesContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 124
    },
    imagesContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        overflowX: 'scroll',
        flexWrap: 'nowrap',
        padding: "10px 20px"
    },
    image: {
        width: 124,
        height: 124,
        borderRadius: 8,
        flexShrink: 0,
        marginRight: 10,
        cursor: 'pointer'
    },
    dragContainer: {
        height: '16vh',
        maxHeight: '20vh',
        padding: "10px 25px",
    },
    drag: {
        borderRadius: 10,
        border: '1px dashed black',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },



    // image
    scrollBar: {
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none',
        }

    },

    // buttons
    buttonTextField: {
        height: '48px',
        width: "50px",
    },

    buttonPower: {
        height: '48',
        width: "70px"
    },

    setDrawer: {
        height: '45px', 
        width: '200px', 
        background: '#FFFFFF', 
        position: "sticky", 
        alignSelf: "start",

    },

    bottomButtons: {
        flex: 1, 
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'flex-start', 
        alignItems: 'center', 
        height: '7vh',
        borderTopRightRadius: 26, 
    }
}))

const CreateRecipe = (props) => {
    const AppState = useContext(AppContext)
    // Global

    const [open, setOpen] = useState(false);
    const [hoveredOn, setHoveredOn] = useState(null)
    const [proMode, setProMode] = useState(true);
    const [klynkRecipe, setKlynkRecipe] = useState(false);
    const [savingToCloud, setSavingToCloud] = useState(false)
    const [savedSuccessfully, setSavedSuccessfully] = useState(false)
    const [modalUploadImages, setModalUploadImages] = useState(false);
    const [stepDetailonFocus, setStepDetailOnFocus] = useState({
        sectionIndex: null,
        stepIndex: null
    })
    const [images, setImages] = useState({})
    const [globalModal, setGlobalModal] = useState(false);
    const [settingsDrawer, setSettingsDrawer] = useState(false);
    const [galleryDrawer, setGalleryDrawer] = useState(false);
    const [saveDrawer, setSaveDrawer] = useState(true);
    const [publishDrawer, setPublishDrawer] = useState(true);

    const [saveText, setSaveText] = useState("Save as Draft")
    const [publishText, setPublishText] = useState("Publish")

    const [recipesLoading, setRecipeLoading] = useState(false);
    const [fetchingRecipe, setFetchingRecipe] = useState(false)
    const [recipeUrl, setRecipeUrl] = useState(null)
    const [createRecipe, setCreateRecipe] = useState(false)
    const history = useHistory();
    const location = useLocation()

    // Draft, Saved
    const [recipeDrafted, setRecipeDrafted] = useState(false);
    const [recipePublished, setRecipePublished] = useState(false);


    const classes = useStyles()
    const [imagesLoading, setImagesLoading] = useState(false)


    const [urlRecipeFetch, setURLRecipeFetched] = useState(false);
    const [currentMetaImage, setCurrentMetaImage] = useState('');
    const [stepImage, setStepImage] = useState('');
    const [creatorModeOn, setCreatorModeOn] = useState(false);
    const [fetchingFailed, setErrorInFetching] = useState(false);

    const formik = useFormik({
        initialValues: {
            '@context': '',
            '@id': '',
            '@type': '',
            aggregateRating: {
                '@type': "AggregateRating",
                ratingCount: "",
                ratingValue: ""
            },
            image: [],
            images: {

            },
            creatorId: '',
            author: {
                '@type': "Person",
                name: 'Team RIKU'
            },
            nutrition: {
                "@type": "NutritionInformation",
                calories: "",
                carbohydrateContent: "",
                fatContent: "",
                proteinContent: ""
            },
            difficultyType: '',
            keywords: [],
            name: '',
            recipeUrl: '',
            recipeCategory: [],
            recipeCuisine: [],
            recipeIngredient: [
                {
                    ingredient: "",
                    unit: "",
                    quantity: "",
                    prepStyle: "",
                },
            ],
            recipeIngredientParsed: [
            ],
            servings: 3,
            recipeInstructions: [
                {
                    id: '0',
                    '@type': 'HowToSection',
                    itemListElement: [
                        {
                            id: generateId(),
                            text: "",
                            ingredients: [],
                            parameters: {
                                toolsUsed: [],
                                temperature: null,
                                duration: '',
                                pro: {
                                    tips: 'tips',
                                    cookingAction: 'Heat',
                                    stirring: false,
                                    temperature: {
                                        value: '',
                                        unit: 'c'
                                    },

                                    power: {
                                        value: null,
                                        unit: 'watt'
                                    }
                                }
                            },
                            images: [],
                            cookingStyle: '',
                            semiRequirement: '',
                        }
                    ],
                    name: 'Preparation',
                    derivedIngredients: '',
                    ingredients: [],
                },
            ],
            recipeInstructionsPro: [
                {
                    id: '0',
                    '@type': 'HowToSection',
                    itemListElement: [
                        {
                            id: generateId(),
                            text: "",
                            ingredients: [],
                            parameters: {
                                toolsUsed: [],
                                temperature: null,
                                duration: '',
                                pro: {
                                    cookingAction: 'Heat',
                                    stirring: false,
                                    temperature: {
                                        value: '',
                                        unit: 'c'
                                    },

                                    power: {
                                        value: null,
                                        unit: 'watt'
                                    }
                                }
                            },
                            cookingStyle: '',
                            images: [],
                        }
                    ],
                    name: 'Preparation',
                    derivedIngredients: '',
                    ingredients: []
                },
            ],
            mealType: [],
            prepTime: '',
            cookTime: '',
            totalDispense: "",
            recipeYield: "",
            proMode: false,
            suitableForDiet: [],
            video: {
                '@type': "VideoObject",
                contentUrl: "",
                description: "",
                name: ""
            },
            klynkAppRecipe: false,
        },
        onSubmit: async (values) => {
            // try catch block of content for auto content
            // filtering out the submitting using a setSubmitting to save the content.
            const checkIfRecipeAlreadySaved = () => {
                return props.match.params?.id?.length > 1 && props.location.search.split('=')[1] === 'recipe'
            }
            const checkIfRecipeIsFromDrafts = () => {
                return props.location.search.split('=')[1] === 'drafts'
            }
            if (checkIfRecipeAlreadySaved()) {
                setPublishText('Publishing...');
                FirebaseApi['recipe'].doc(props.match.params?.id).update(JSON.parse(JSON.stringify({ ...values, proMode, editedOn: new Date() })))
                    .then(() => {
                        setSavedSuccessfully(true);
                        setSavingToCloud(false);
                        console.log("Recipe published", values);
                        setRecipePublished(true);
                        setPublishText("Published");
                        //alert("published");
                    })
                    .catch((error) => {
                        setSavingToCloud(false)
                        console.log(error)
                        setPublishText("Not published");
                    })
            }
            else if (checkIfRecipeIsFromDrafts()) {
                console.log("in saving drats");
                setPublishText('Publishing...');
                console.log(props.match.params?.id, 'drafts')
                FirebaseApi['recipe'].doc(props.match.params?.id).set(JSON.parse(JSON.stringify({ ...values, proMode, datePublished: new Date() })))
                    .then((data) => {
                        setSavedSuccessfully(true)
                        setSavingToCloud(false);
                        console.log("Recipe published", values);
                        setRecipePublished(true);
                        setPublishText("Published");
                    })
                    .catch((error) => {
                        setSavingToCloud(false)
                        console.log(error)
                    })

                FirebaseApi['drafts'].doc(props.match.paramas?.id).delete().then(() => {
                    console.log('deleted from drafts')
                }).catch((err) => {
                    console.log(err)
                })

            }
            else {
                console.log("in saving new");
                setPublishText('Publishing...');
                FirebaseApi['recipe'].doc(formik.values.id).set(JSON.parse(JSON.stringify({ ...values, proMode, datePublished: new Date() })))
                    .then((data) => {
                        setSavedSuccessfully(true)
                        setSavingToCloud(false)
                        localStorage.removeItem('drafted')
                        localStorage.removeItem('saveLocally');
                        console.log("Recipe published", values);
                        setRecipePublished(true);
                        setPublishText("Published");
                    })
                    .catch((error) => {
                        setSavingToCloud(false)
                        console.log(error);
                    })
            }
        },
    });

    const { values, handleChange, setFieldValue } = formik

    useEffect(() => {
        const id = props?.match?.params?.id
        const collection = props?.location?.search?.split('=')[1]
        handleModalOpen();
        if (id.length > 1) {
            setRecipeLoading(true)
            getCollectionWithId(collection, id)
            handleModalClose();
        }
    }, [urlRecipeFetch]);

    function getBase64(file) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
            }
        })
    };

    // Meta Data
    /// Image
    const collectImagesWithIndex = async (e, sectionIndex = 0, stepIndex = 0) => {
        setImagesLoading(true)

        var imageObject = {};
        if (formik.values.images && [...Object.keys(formik.values.images)].length >= 0) {
            await Promise.all([...Array(e.target.files.length).keys()].map(async (item, index) => {
                const data = await getBase64(e.target.files[item])
                imageObject[`img-${sectionIndex}-${stepIndex}-${[...Object.keys(formik.values.images)].length + index}`] = data
            }));
        }
        else {
            await Promise.all([...Array(e.target.files.length).keys()].map(async (item, index) => {
                const data = await getBase64(e.target.files[item])
                imageObject[`img-${sectionIndex}-${stepIndex}-${index}`] = data
            }));
        }
        if (sectionIndex === 0 && stepIndex === 0) {
            setFieldValue('images', { ...formik.values.images, ...imageObject })
        }
        if (sectionIndex >= 1 && stepIndex >= 1) {
            setFieldValue(`recipeInstructions[${sectionIndex - 1}].itemListElement[${stepIndex - 1}].images`, { ...formik.values.recipeInstructions[sectionIndex - 1].itemListElement[stepIndex - 1].images, ...imageObject })
        }
    }

    async function getImages(id) {
        var listRef = await storageRef.child(`images/${id}`)
        var res = await listRef.listAll()
        var img = {}
        res.items.forEach(async (itemRef, index) => {
            // All the items under listRef.
            try {
                const url = await storageRef.child(`images/${id}/${itemRef.name}`).getDownloadURL()
                img[`image-${index}`] = url
                formik.setFieldValue('images', { ...formik.values.images, ...img })
            }
            catch (err) {
                console.log({ err })
            }
        });
    }

    // Edit Image, Remove Image
    const selectMetaImage = (file) => {
        setFieldValue('image', [values.images[file]])
        setCurrentMetaImage(file)
    }

    const removeMetaImage = (e, file) => {
        delete values.images[currentMetaImage]
        setFieldValue('images', values.images)
    }

    // Image DragNDrop
    const onDragEnd = (e) => {
        setStepImage(values.images[e.source.index]);
        setFieldValue('image', [values.images[Object.keys(values.images)[e.source.index]]])
    };

    // Get Recipe
    const setFetchedRecipeValues = {
        'drafts': (recipe) => {
            recipe.recipe.images = {}
            var images = {}
            var servings = 2
            var keywords = ''

            getImages(recipe.id)
            const ingredients = recipe.recipe.recipeIngredient.map(ingredient => {
                // console.log(ingredient)
                if (ingredient.ingredient) {
                    const parsedData = parse(ingredient.ingredient)
                    // console.log({parsedData})
                    const parsedName = parsedData.ingredient.split('(')[0]
                    return ({
                        id: generateId(),
                        ...ingredient,
                        ingredient: parsedName
                    })
                }
                else {
                    // console.log({ingredient})
                    const parsedData = parse(ingredient)
                    // console.log({parsedData})
                    const parsedName = parsedData.ingredient.split('(')[0]
                    return ({
                        id: generateId(),
                        ...parsedData,
                        ingredient: parsedName,
                    })
                }
            })
            recipe.recipe.recipeIngredient = ingredients
            if (recipe.recipe.proMode === undefined) {
                let newRecipeInstructions = []
                let instructionStepTrue = false
                var combiningHowToSteps = [{
                    '@type': 'HowToSection',
                    id: 0,
                    ingredients: [],
                    derivedIngredients: '',
                    itemListElement: [],
                    name: 'main'
                }]

                var howToSectionInstructions = []
                recipe.recipe.recipeInstructions.map((instruction, index) => {
                    if (instruction['@type'] === 'HowToStep' || instruction['@type'] === "Recipe") {
                        instructionStepTrue = true
                        combiningHowToSteps[0].itemListElement.push(
                            {
                                id: generateId(),
                                text: "",
                                ingredients: [],
                                parameters: {
                                    toolsUsed: [],
                                    temperature: null,
                                    duration: '',
                                    pro: {
                                        cookingAction: 'Heat',
                                        stirring: false,
                                        temperature: {
                                            value: 0,
                                            unit: 'c'
                                        },
                                        power: {
                                            value: null,
                                            unit: 'watt'
                                        }
                                    }
                                },
                                images: [],
                                cookingStyle: '',
                                ...instruction
                            })
                    }
                    else {
                        instruction.id = index + 1
                        instruction.ingredients = []
                        instruction.derivedIngredients = ''
                        if (instruction.itemListElement) {
                            instruction.itemListElement.map((element) => {
                                element.id = generateId()
                                element.image = ''
                                element.images = []
                                element.ingredients = []
                                element.parameters = {
                                    toolsUsed: [],
                                    temperature: null,
                                    duration: '',
                                    pro: {
                                        cookingAction: 'Heat',
                                        stirring: false,
                                        temperature: {
                                            value: 0,
                                            unit: 'c'
                                        },
                                        power: {
                                            value: null,
                                            unit: 'watt'
                                        }
                                    }
                                }
                                return element
                            })
                        }
                        howToSectionInstructions.push(instruction);
                    }
                })
                newRecipeInstructions = instructionStepTrue ? [...combiningHowToSteps, ...howToSectionInstructions] : [...howToSectionInstructions]
                recipe.recipe.recipeInstructions = newRecipeInstructions
            }
            formik.setValues({
                ...recipe.recipe, id: recipe.id, images, recipeUrl: recipe.url, servings, keywords
            })
        },
        'recipe': (recipe) => {
            var imagesFromRecipe = []
            recipe.images = {}
            getImages(recipe.id)
            setImages(imagesFromRecipe)
            formik.setValues(recipe)
        }
    }

    const getCollectionWithId = async (collection, id) => {

        try {
            const recipe = await CloudServices.crud.getCollectionWithId(collection, id)

            setFetchedRecipeValues[collection]({ ...recipe, id })
            setRecipeLoading(false)
        } catch (error) {
            console.log(error)
            setErrorInFetching(true);
        }
    }

    // Get Recipe from URL
    const getRecipeFromUrl = (e) => {
        if (values.recipeUrl !== '') {
            setFetchingRecipe(true)
            axios.get(`https://us-central1-riku-staging.cloudfunctions.net/recipeScraper?url=${recipeUrl}`)
                .then(result => {
                    // As soon as getting data we are updating the data to state
                    setFetchingRecipe(false)
                    setCreateRecipe(false)
                    // and immediatley refining the data we got back for working accordingly
                    history.push(`/createRecipe/${result.data[1]}?collection=${result.data[0]}`)
                    setURLRecipeFetched(true)
                })
                .catch(err => {
                    console.log(err)
                })
        } else {
            setGlobalModal(false)
        }
    }

    // saveRecipe as draft
    const saveDraft = () => {
        if (values.name.length > 0 && !localStorage.getItem('drafted') && !values.id) {
            // console.log(props.props,props.history,props.location,props.match,typeof props)
            setSaveText("saving");
            FirebaseApi['drafts'].add(JSON.parse(JSON.stringify({ recipe: { ...values, proMode }, recipeState: "entryDraft", dateAdded: new Date() })))
                .then((data) => {
                    setFieldValue('id', data.id)
                    localStorage.setItem('drafted', JSON.stringify({ id: data.id }))
                    localStorage.setItem('saveLocally', JSON.stringify({ ...values, id: data.id, proMode, editedOn: new Date() }))
                    setRecipeDrafted(true);
                    setSaveText("Saved");
                    console.log("Recipe Saved to Drafts");
                    //props.history.push(`/addRecipe/${data.id}?collection=drafts`)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        else {
            setSaveText("Not saved");
            console.log("not saved in drafts");
        }
    }

    // Settings
    const handleSettingsOpen = () => {
        setGalleryDrawer(false);
        setSettingsDrawer(!settingsDrawer);
    }

    const openSettingsDrawer = () => {
        return (
            <Box direction="right" in={settingsDrawer} mountOnEnter unmountOnExit>
                <Grid className={classes.setDrawer} >
                    <Grid item sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Grid item onClick={handleSettingsOpen} style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', backgroundColor: 'rgb(134 170 243 / 43%)', borderTopRightRadius: 26, cursor: 'pointer' }}>
                            <Grid item >
                                <img src={Settings} style={{ marginLeft: 20, width: 31, height: 31 }} alt="settings drawer"/>
                            </Grid>
                            <Grid item >
                                <Typography variant="h6" style={{ marginLeft: '20px', height: 39, margin: '10px', color: '#2680EB' }}>  Settings </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid style={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "space-between", margin: 10, marginLeft: 23 }}>
                        {/* <Drawer variant="permanent"> */}
                        <Grid item alignItems="center">
                            <Grid item paddingBottom="10px">
                                <Grid >
                                    <Typography style={{ fontSize: "larger" }}> Contents </Typography>
                                    <Grid style={{ height: 'auto', display: 'flex', flexDirection: 'row' }}>
                                        <Grid style={{ borderLeft: '2px solid #D9DDE0', marginRight: -11 }}>
                                            <Grid style={{ height: '100%' }}>
                                            </Grid>
                                        </Grid>
                                        <TreeView
                                            aria-label="customized"
                                            defaultExpanded={['1']}
                                            defaultCollapseIcon={<RemoveIcon style={{ opacity: '25%' }} />}
                                            defaultExpandIcon={<RemoveIcon style={{ opacity: '25%' }} />}
                                            defaultEndIcon={<RemoveIcon style={{ opacity: '25%' }} />}
                                            sx={{ height: "auto", flexGrow: 1, maxWidth: 400, overflowY: 'auto', fontFamily: 'Product Sans' }}
                                        >
                                            <StyledTreeItem nodeId="1" sx={{ fontFamily: 'Product Sans' }} label="Metadata">
                                            </StyledTreeItem>
                                            <StyledTreeItem nodeId="1" sx={{ fontFamily: 'Product Sans' }} label="IngredientTable">
                                            </StyledTreeItem>
                                            {values.recipeInstructions?.length > 0 && values.recipeInstructions.map((value, index) => (
                                                <StyledTreeItem nodeId="1" sx={{ fontFamily: "Product Sans" }} label={value.name} />

                                            ))}
                                        </TreeView>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid item style={{ padding: '15px 0px' }}>
                                <Grid>
                                    <Typography style={{ fontSize: 'larger' }}> MembershipLevel </Typography>
                                </Grid>
                                <Grid>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="Free"
                                            name="radio-buttons-group"
                                        >
                                            <FormControlLabel value="Free" control={<Radio style={{ color: '#377AED' }} />} label="Free" />
                                            <FormControlLabel value="Paid" control={<Radio style={{ color: '#377AED' }} />} label="Paid" />
                                            {/* <FormControlLabel value="Platinum" control={<Radio style={{ color: '#377AED' }} />} label="Platinum" /> */}
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {/* <Divider />
                            <Grid item style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: '10px 0px' }}>
                                <Grid item >
                                    <Typography style={{ fontSize: 19, color: 'black' }}>Pro Version</Typography>
                                </Grid>
                                <Grid item >
                                    <CustomSwitch checked={proMode} onChange={() => changeProMode()} />
                                </Grid>
                            </Grid> */}
                        </Grid>
                    </Grid>
                </Grid >
            </Box >
        )
    }

    const openSaveDrawer = () => {
        return (
            <Box direction="right" in={saveDrawer}  >
                <Grid container>
                    <Grid 
                        item 
                        style = {
                            recipeDrafted ? {
                                backgroundColor: '#CCF0D5',
                            } : {                              
                                backgroundColor: 'rgb(134 170 243 / 43%)', 
                            }
                        } 
                        onClick={saveDraft}
                        className={classes.bottomButtons} 
                    >
                        <Grid item >
                            <img src={SaveRecipe} style={{ marginLeft: 20, width: 31, height: 31 }} alt='saveDrafts' />
                        </Grid>
                        <Grid item >
                            <Typography variant="h6" style={{ marginLeft: '20px', height: 39, margin: '10px', color: '#2680EB'}}> {saveText} </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box >
        )
    }

    const openPublishDrawer = () => {
        return (
            <Grid container>
                <Grid 
                    item 
                    style=
                        { recipePublished ? {
                                backgroundColor: '#CCF0D5', 
                            } : { 
                                backgroundColor: '#387CF0', 
                            }
                        }
                    onClick={() => formik.submitForm()}
                    className={classes.bottomButtons}
                >
                    <Grid item >
                        <img src={Publish} style={{ marginLeft: 20, width: 31, height: 31 }} alt='publish' />
                    </Grid>
                    <Grid item >
                        <Typography variant="h6" style={recipePublished ? { marginLeft: '20px', height: 39, margin: '10px', color: '#008B23' } : { marginLeft: '20px', height: 39, margin: '10px', color: '#FFF' }}>  {publishText} </Typography>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    // Gallery
    const handleGalleryDrawer = () => {
        setSettingsDrawer(false);
        setGalleryDrawer(!galleryDrawer)
    }

    const openGalleryDrawer = () => {
        return (
            <Box direction="right" in={galleryDrawer} mountOnEnter unmountOnExit>
                <Grid style={{ display: 'flex', flexDirection: 'column', width: 198, background: '#FFFFFF', position: "sticky", alignSelf: "start" }}>

                    <Grid item sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                        <Grid item onClick={handleGalleryDrawer} style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', backgroundColor: 'rgb(134 170 243 / 43%)', borderTopRightRadius: 26, cursor: 'pointer' }}>
                            <Grid item >
                                <img src={Gallery} style={{ marginLeft: 20, width: 31, height: 31 }} alt="gallery drawer" />
                            </Grid>
                            <Grid item >
                                <Typography variant="h6" style={{ marginLeft: '20px', height: 39, margin: '10px', color: '#2680EB' }}>  Gallery </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                        {[...Object.keys(values.images)].map((file, index) => {
                            return (
                                <img key={index} src={values.images[file]} style={{ margin: 10, width: 124, height: 124, borderRadius: 8, flexShrink: 0, marginRight: 10, cursor: 'pointer' }} alt="gallery list" />
                            )
                        })}
                    </Grid>
                </Grid>
            </Box >
        )
    }

    const closeOnHoverLeave = () => {
        setOpen(false)
        setHoveredOn(null)
    }

    const drawerContent = () => {
        return (
            <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 50, marginBottom: 40, height: '100%', marginLeft: -8 }}>
                <Grid item>
                    {[
                        {
                            name: 'Back',
                            activeIcon: BackButton,
                            inActiveIcon: BackButton,
                        },
                        {
                            name: 'Gallery',
                            activeIcon: Gallery,
                            inActiveIcon: Gallery,
                        },
                        {
                            name: 'Settings',
                            activeIcon: Settings,
                            inActiveIcon: Settings,
                        }].map((text, index) => {
                        if (text.name === 'Settings') {
                            return (
                                <div className={classes.hideScrollBar} key={index} onClick={() => { handleSettingsOpen(); closeOnHoverLeave(); }} style={{ width: `drawerWidth - 30px`, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', overflowX: 'scroll', margin: 10, cursor: 'pointer' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 5, backgroundColor: 'rgb(134 170 243 / 43%)', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 32, borderBottomRightRadius: 32, padding: 10 }}>
                                        <img src={location.pathname === text.route ? text.activeIcon : text.inActiveIcon} style={{ width: 28, height: 28 }} alt="settings drawer" />
                                    </div>
                                </div>
                            )
                        } else if (text.name === 'Gallery') {
                            return (
                                <div className={classes.hideScrollBar} onClick={() => { handleGalleryDrawer(); closeOnHoverLeave(); }} style={{ width: drawerWidth - 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', overflowX: 'scroll', margin: 10, cursor: 'pointer' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 5, backgroundColor: 'rgb(134 170 243 / 43%)', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 32, borderBottomRightRadius: 32, padding: 10 }}>
                                        <img src={location.pathname === text.route ? text.activeIcon : text.inActiveIcon} style={{ width: 28, height: 28 }} alt="gallery drawer" />
                                    </div>
                                </div>
                            )
                            // Steps
                        } else {
                            return (
                                <div className={classes.hideScrollBar} key={index} onClick={() => { history.go(-1); }} style={{ width: drawerWidth - 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', overflowX: 'scroll', margin: 10, cursor: 'pointer' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 5, backgroundColor: 'rgb(134 170 243 / 43%)', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 32, borderBottomRightRadius: 32, padding: 10 }}>
                                        <img src={location.pathname === text.route ? text.activeIcon : text.inActiveIcon} style={{ width: 28, height: 28 }} alt="back drawer" />
                                    </div>
                                </div>
                            )
                        }
                    })}
                </Grid>
            </Grid >
        )
    }

    const changeProMode = () => {
        setProMode(!proMode);
    }

    const handleModalOpen = () => setGlobalModal(true);
    const handleModalClose = () => setGlobalModal(false);

    return recipesLoading ? (
    <Grid item container direction="row" justifyContent="center" alignItems="center" style={{ minHeight: '100vh', background: '#E8E8E8' }}>
        <Grid item style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <div
                className={classes.importLoading}>
                <div
                    className={classes.importLoadingRing}
                >
                </div>
                <div className={classes.importLoadingReverseRing}></div>
            </div>
            {
                fetchingFailed && <Grid>
                    <Typography variant='h4'>
                        Failed to fetch recipe!
                    </Typography>
                    <Link to={`/createRecipe/0`} target='_blank'>
                        <Typography variant='h5'>
                            Try a Different URL
                        </Typography>
                    </Link>
                </Grid>
            }
        </Grid>
    </Grid>) : (
        // <Container >
        <CreateRecipeContext.Provider value={{ formik, urlRecipeFetch, setURLRecipeFetched, proMode, setProMode, props, values, handleChange, setFieldValue, }}>
            <Grid display="flex" flexDirection="row" height="auto" style={{ background: "#E8E8E8", marginLeft: -70 }} classes={{ root: classes.typography, }}>
                {/*  StartModal */}
                <Modal
                    open={globalModal}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Grid display="flex" flexDirection="row" justifyContent="space-around" style={{ background: "#FFFFFF" }} classes={{ root: classes.typography }}>
                            <img src={CloseIconn} onClick={handleModalClose} style={{ position: 'absolute', width: 55, height: 55, top: 0, right: 0, margin: -23 }} alt="url modal" />
                            {/* Image */}
                            <Grid item margin="50px 50px">
                                <Grid item style={{ overflow: 'hidden', marginLeft: -60 }} >
                                    <Grid item xs={3}>
                                        <label htmlFor='upload'>
                                        <Grid style={{ width: 250, height: 337, border: '1px dashed #707070', backgroundColor: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: 10 }}>
                                            <input type="file" accept='image/png, image/jpeg' multiple id="upload" onClick={e => collectImagesWithIndex(e)} style={{ display: 'none'}} />
                                                <img item src={ModalGallery} alt={"drag here"} />
                                                <Typography item style={{ color: '#397DF3', fontWeight: 600 }} > Drag & Drop</Typography>
                                                <Typography item style={{ color: '#B2B2B2', fontWeight: 600 }}> Or</Typography>
                                                <Typography item style={{ color: '#397DF3', fontWeight: 600 }}> Upload Media</Typography>
                                        </Grid>
                                        </label>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* Name, Link, Images */}
                            <Grid item style={{ height: 337, width: 400, marginTop: 10, marginLeft: -144 }}>
                                {/* Images */}
                                <Grid item container spacing={1} direction="column" margin="4px" paddingLeft='10px' >
                                    <Grid style={{ width: "400px", border: "3px solid #F8F9FD", borderRadius: 8, display: 'flex', flexDirection: 'row', justifyContent: "space-between", marginLeft: -5, marginTop: 34, }}>
                                        <Grid item>
                                            <Box style={{ width: '330px', display: 'flex', flexDirection: 'row', alignItems: 'center', overflowX: 'auto', height: '101px' }} className={classes.hideScrollBar}>
                                                {[...Object.keys(values.images)].map((file, index) => {
                                                    const element = values
                                                    return (
                                                        <Grid item xs={4} key={index} >
                                                            <Box style={{ position: 'relative' }}>
                                                                <Grid container direction="row" alignItems="center" flexWrap="wrap">
                                                                    <Grid item key={file.id} onClick={() => selectMetaImage(file, element)}  >
                                                                        <img src={values.images[file]} style={{ width: 90, height: 90, borderRadius: 8, margin: 15, border: currentMetaImage === file ? '2px solid #2F7EEF' : 'none' }} alt="recipe file" />
                                                                    </Grid>
                                                                </Grid>
                                                                {currentMetaImage === file && (
                                                                    <Box style={{ position: 'absolute', top: -4, right: -1 }}>
                                                                        <Box style={{ display: 'flex', flexDirection: 'row', justifyItems: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.5)', borderRadius: '50%', padding: 5 }}>
                                                                            <CloseIcon onClick={(e) => removeMetaImage(e, file)} style={{ fontSize: 16, color: 'white' }} />
                                                                        </Box> 
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                        </Grid>
                                                    )
                                                })}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* Link */}
                                <Grid item container spacing={1} direction="column" margin='4px' >
                                    <Grid item>
                                        <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', }}>Source Link</Typography>

                                    </Grid>
                                    <Grid item>

                                        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', borderRadius: 8, width: 400, marginTop: -10 }}>
                                            <Box item>
                                                <TextField autoComplete='off' placeholder="link" type="text" value={values.recipeUrl} onChange={(e) => { setFieldValue("recipeUrl", e.target.value); setRecipeUrl(e.target.value); }} InputProps={{ className: classes.linkTextField }} style={{ border: 'none', width: 350, height: '48', marginRight: '4px' }} />
                                            </Box>
                                            <Box item >
                                                <img src={LinkIcon} style={{ width: 40, height: 48, marginTop: 6 }} alt="URL recipe" />
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={1} direction="column" margin='4px' >
                                    <Grid item>
                                        <Typography style={{ color: '#292929', fontSize: 17, fontWeight: 'bold', }}>Recipe Name</Typography>
                                    </Grid>
                                    <Grid item>
                                        <TextField autoComplete='off' placeholder="Enter your recipe name" type="text" style={{ width: '400px', marginTop: -3 }} value={values.name} id="name" onChange={handleChange} InputProps={{ className: classes.textField }} />
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" style={{ color: 'black', marginLeft: 14, marginTop: 10, fontWeight: "600", background: "#397DF3", border: "2px solid #387DF2" }} onClick={() => getRecipeFromUrl(recipeUrl)}>
                                        <Typography> Create </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

                {/* Drag image to upload */}
                <DragDropContext onDragEnd={onDragEnd}>
                    <Grid item flexDirection="column" alignContent="space-between" marginTop="10px" >
                        <Grid marginBottom="20px">
                            <Drawer variant="permanent" open={open}
                                sx={{
                                    display: { xs: 'none', sm: 'block', },
                                    '& .MuiDrawer-paper': { marginTop: "-1px", background: '#F8F9FA', outline: 0, border: 'none', display: "flex", flexDirection: "column", justifyContent: "space-between" },
                                }}
                            >
                                {drawerContent(props)}
                            </Drawer>
                            <Box
                                component="nav"
                                sx={{
                                    flexShrink: { sm: 0 }
                                }}
                            >
                                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                                <MuiDrawer
                                    variant="temporary"
                                    open={AppState.drawerOpen}
                                    onClose={AppState.closeDrawer}
                                    ModalProps={{
                                        keepMounted: true, // Better open performance on mobile.
                                    }}
                                    sx={{
                                        display: { xs: 'block', sm: 'none' },
                                        '& .MuiDrawer-paper': { width: drawerWidth },
                                    }}
                                >
                                    {drawerContent()}
                                </MuiDrawer>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid  >
                        <Grid >
                            <SwipeableDrawer
                                open={settingsDrawer}
                                variant="persistent"
                                PaperProps={{
                                    style: {
                                        marginTop: 174, width: "200px", boxShadow: 'none', height: "54vh", border: '1px solid #397FF75F', borderTopRightRadius: 30, borderBottomRightRadius: 30, overflowX: 'hidden'
                                    },
                                    classes: { root: classes.hideScrollBar }
                                }}
                                ModalProps={
                                    { hideBackdrop: true }
                                }>
                                {openSettingsDrawer()}
                            </SwipeableDrawer>
                        </Grid>
                    </Grid>
                    <Grid >
                        <Grid marginBottom="20px">
                            {/* {openSettingsDrawer2()} */}
                            <SwipeableDrawer
                                open={galleryDrawer}
                                variant="persistent"
                                PaperProps={{
                                    style: {
                                        marginTop: 117, width: '200px', boxShadow: 'none', height: "61vh", border: '1px solid #397FF75F', borderTopRightRadius: 30, borderBottomRightRadius: 30, overflowX: 'hidden',
                                    },
                                    classes: { root: classes.hideScrollBar }
                                }}
                                ModalProps={{
                                    hideBackdrop: true,
                                }}
                            >
                                {openGalleryDrawer()}
                            </SwipeableDrawer>
                        </Grid>
                    </Grid>
                    <Grid >
                        {/* {openSettingsDrawer2()} */}
                        <SwipeableDrawer
                            open={publishDrawer}
                            variant="persistent"
                            PaperProps={{
                                style: {
                                    marginTop: 653, 
                                    boxShadow: 'none', 
                                    width: '200px', 
                                    height: 45,
                                    borderTopRightRadius: 30, 
                                    borderBottomRightRadius: 30,
                                },
                                className: classes.hideScrollBar
                            }}
                            ModalProps={{
                                hideBackdrop: true,
                            }}
                        >
                            {openPublishDrawer()}
                        </SwipeableDrawer>
                    </Grid>
                    <Grid >
                        {/* {openSettingsDrawer2()} */}
                        <SwipeableDrawer
                            open={saveDrawer}
                            variant="persistent"
                            PaperProps={{
                                style: {
                                    marginTop: 594, 
                                    boxShadow: 'none', 
                                    width: '200px',
                                    height: 45,
                                    borderTopRightRadius: 30, 
                                    borderBottomRightRadius: 30,
                                },
                                className: classes.hideScrollBar
                            }}
                            ModalProps={{
                                hideBackdrop: true,
                            }}
                        >
                            {openSaveDrawer()}
                        </SwipeableDrawer>
                    </Grid>
                    <Grid item style={{ height: "100%" }}>
                        {/* <AutoSave formik={formik} debounce={300} /> */}
                        {/* Creation/MetaData Details */}
                        <MetaDataCreation />
                        {/* Ingridents table */}
                        <IngridentsIndex formik={formik} />
                        {/* Step Details */}
                        <Grid margin="20px" width='1200px' >
                            <StepDetailContext.Provider value={{ stepDetailonFocus }}>
                                <Grid>
                                    <Grid item>
                                        <FormikProvider value={formik} >
                                            <InstructionsAccordion />
                                        </FormikProvider>
                                    </Grid>
                                </Grid>
                            </StepDetailContext.Provider>
                        </Grid>
                    </Grid>
                </DragDropContext>
                {creatorModeOn && <TurnCreator creatorModal={true} />}
            </Grid >
        </CreateRecipeContext.Provider >
    )
}

export default CreateRecipe
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    // width: 770,
};