import { Grid } from '@mui/material'
import React from 'react'
import CreatorInfo from '../EditorComponents/CreatorInfo'
import FeaturedPosts from '../EditorComponents/FeaturedPosts'
import RandomCard from '../EditorComponents/RandomCard'

const CreatorSiteEditor = () => {
  return (
    <Grid container direction='column' style={{ width: '100%', overflowY: 'auto' }}>
      <Grid item>
        <CreatorInfo />
      </Grid>
      <Grid item>
        <FeaturedPosts />
      </Grid>
      <Grid item>
        <RandomCard />
      </Grid>
    </Grid>
  )
}

export default CreatorSiteEditor