import React, { useEffect, useState, useContext } from 'react'
import { Grid, Button, Typography, Box, Container, Divider, styled } from '@mui/material'
import { Link, useHistory, useLocation } from 'react-router-dom'
import MuiDrawer from '@mui/material/Drawer';


import Modal from '@mui/material/Modal'
import { makeStyles } from '@mui/styles'

//main
import { useFormik, FieldArray, FormikProvider } from 'formik';

// Settings
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Switch as CustomSwitch } from '../../../components/Switch'
import SaveRecipe from '../../../assets/SaveRecipe.svg';
import Publish from '../../../assets/Publish.svg';

import BackButton from '../../../assets/CreatorScreen/BackButton.svg';


import AppContext from '../../../config/AppContext';

import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import Settings from '../../../assets/NavBar/Settings.svg';


import CollectionMetaDataCreation from './CollectionMetaData';
import PublishedCollections from './CollectionsEditor';

import { FirebaseApi } from '../../../firebaseConfig';
import CloudServices from '../../../cloud-services-core';
import TurnCreator from '../CreatorMode';






// Styles
const useStyles = makeStyles((theme) => ({

    // textfield
    textField: {

        height: 42,
        border: '2px solid transparent',
        "&.Mui-focused": {
            border: "2px solid #397EF5",
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
            }
        }
    },



    typography: {
        "& .MuiTypography-root": {

            fontFamily: 'Product Sans',
        },


    },

    hideScrollBar: {
        '&::-webkit-scrollbar': {
            display: 'none'
        },

    },



    // image
    scrollBar: {
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none',
        }

    },

    setDrawer: {
        height: '100%', width: 198, background: '#FFFFFF', position: "sticky", alignSelf: "start"
    }


}))

// drawer
const drawerWidth = 240;





const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({

        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const openedMixin = (theme) => ({
    marginTop: 20,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    // display:'flex',
    // flexDirection:'column',
    // justifyContent:'center',
    overflowX: 'hidden',
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    zIndex: 1

});

const closedMixin = (theme) => ({
    marginTop: 20,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    // display:'flex',
    // flexDirection:'column',
    // justifyContent:'center',
    overflowX: 'hidden',
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
    zIndex: 1,
});



const CreateCollection = (props) => {

    const AppState = useContext(AppContext);
    const classes = useStyles()


    const [open, setOpen] = useState(false);

    // side drawers
    const [settingsDrawer, setSettingsDrawer] = useState(false);
    const [galleryDrawer, setGalleryDrawer] = useState(false);
    const [saveDrawer, setSaveDrawer] = useState(false);
    const [publishDrawer, setPublishDrawer] = useState(false);

    const [saveText, setSaveText] = useState("Save as Draft")
    const [publishText, setPublishText] = useState("Publish")

    const history = useHistory();
    const location = useLocation();
    // const navigate = useNavigate();

    const [collectionDrafted, setCollectionDrafted] = useState(false);
    const [collectionSaved, setCollectionSaved] = useState(false);

    const [collectionData, setCollectionData] = useState([]);
    const [allCollections, setAllCollections] = useState([]);
    const [collectionPresent, setCollectionPresent] = useState(false);

    const [creatorModeOn, setCreatorModeOn] = useState(false);

    const publishCollection = async (values) => {
        if (AppState.user.creator == true) {
            let article = await CloudServices.creatorCollections.createWithUserId(AppState.user.id, values)

            setCollectionSaved(true);
            setPublishText("Published");
            console.log("Article Published");
        } else {
            setCreatorModeOn(true);
        }

    }

    const getCollection = async (id) => {
        console.log(CloudServices);
        let values = await CloudServices.creatorCollections.fetch();
        let val = await CloudServices.creatorCollections.fetchByCollectionId(id);
        console.log(val);
        setCollectionData(val);
        setAllCollections(values);
        if (val) {
            setCollectionPresent(true);
        }

        console.log(values);

    }


    console.log(allCollections);

    // Main
    const formik = useFormik({

        enableReinitialize: true,
        initialValues: {
            name: collectionData ? collectionData.values.name : '',
            description: collectionData ? collectionData.values.description : '',
            keywords: collectionData ? collectionData.values.keywords : [],
            image: collectionData ? collectionData.values.image : [],
            level: collectionData ? collectionData.values.level : '',
            articles: collectionData ? collectionData.values.articles : [],
            recipes: collectionData ? collectionData.values.recipes : [],

        },
        onSubmit: values => {
            if (collectionPresent) {
                console.log("Collection already saved");

                FirebaseApi['creatorCollections'].doc(props.match.params?.id).update(JSON.parse(JSON.stringify({ creatorId: AppState.user.id, values: values, editedOn: new Date() })))
                    .then(() => {
                        setCollectionSaved(true);
                        setPublishText("Edited");
                        console.log("Edited");
                    })
                    .catch((error) => {

                        console.log(error)
                    })


            } else {
                publishCollection(values);
            }

        }
    });

    const { setFieldValue } = formik



    useEffect(() => {
        let id = props.match.params?.id
        getCollection(id);
        AppState.handleNavbar(false)
        return () => AppState.handleNavbar(true)
    }, [])


    const handleSettingsOpen = () => {
        setGalleryDrawer(false);
        setSettingsDrawer(!settingsDrawer);
    }

    const handleDraftsOpen = () => {
        setSaveDrawer(!saveDrawer)
        setPublishDrawer(false);
    }

    const handlePublishOpen = () => {
        setPublishDrawer(!publishDrawer)
        setSaveDrawer(false);
    }


    const openSettingsDrawer = () => {
        return (
            <Box direction="right" in={settingsDrawer} mountOnEnter unmountOnExit>

                <Grid className={classes.setDrawer} >

                    <Grid item sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                        <Grid item onClick={handleSettingsOpen} style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', backgroundColor: 'rgb(134 170 243 / 43%)', borderTopRightRadius: 26, cursor: 'pointer' }}>
                            <Grid item >
                                <img src={Settings} style={{ marginLeft: 20, width: 31, height: 31 }} />
                            </Grid>
                            <Grid item >
                                <Typography variant="h6" style={{ marginLeft: '20px', height: 39, margin: '10px', color: '#2680EB' }}>  Settings </Typography>
                            </Grid>
                        </Grid>


                    </Grid>



                    <Grid style={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "space-between", margin: 10, marginLeft: 23 }}>

                        {/* <Drawer variant="permanent"> */}
                        <Grid item alignItems="center">

                            <Grid item style={{ padding: '15px 0px' }}>
                                <Grid>
                                    <Typography style={{ fontSize: 'larger' }}> MembershipLevel </Typography>
                                </Grid>
                                <Grid>
                                    <FormControl>

                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="Free"
                                            name="radio-buttons-group"

                                        >
                                            <FormControlLabel value="Free" onClick={() => setFieldValue('level', 'free')} control={<Radio style={{ color: '#377AED' }} />} label="Free" />
                                            <FormControlLabel value="Silver" onClick={() => setFieldValue('level', 'silver')} control={<Radio style={{ color: '#377AED' }} />} label="Silver" />
                                            <FormControlLabel value="Platinum" onClick={() => setFieldValue('level', 'platinum')} control={<Radio style={{ color: '#377AED' }} />} label="Platinum" />


                                        </RadioGroup>
                                    </FormControl>

                                </Grid>
                            </Grid>

                            <Divider />

                            <Grid item style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: '10px 0px' }}>
                                <Grid item >
                                    <Typography style={{ fontSize: 19, color: 'black' }}>Preview</Typography>
                                </Grid>
                                <Grid item >
                                    <CustomSwitch />

                                </Grid>
                            </Grid>


                        </Grid>

                        {/* </Drawer> */}


                    </Grid>

                </Grid >

            </Box >
        )
    }

    const openSaveDrawer = () => {
        return (
            <Box direction="right" in={saveDrawer} mountOnEnter unmountOnExit>

                <Grid className={classes.setDrawer} >

                    <Grid item sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                        <Grid item style={collectionDrafted ? { flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', backgroundColor: '#CCF0D5', borderTopRightRadius: 26, } : { flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', backgroundColor: 'rgb(134 170 243 / 43%)', borderTopRightRadius: 26, }}>
                            <Grid item >
                                <img src={SaveRecipe} onClick={handleDraftsOpen} style={{ marginLeft: 20, width: 31, height: 31, cursor: 'pointer' }} />
                            </Grid>
                            <Grid item >
                                <Typography variant="h6" style={{ marginLeft: '20px', height: 39, margin: '10px', color: '#2680EB', cursor: 'pointer' }}>  {saveText} </Typography>
                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>
            </Box >
        )
    }

    const openPublishDrawer = () => {
        return (
            <Box direction="right" in={publishDrawer} mountOnEnter unmountOnExit>

                <Grid className={classes.setDrawer} >

                    <Grid item sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                        <Grid item style={collectionSaved ? { flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', backgroundColor: '#CCF0D5', borderTopRightRadius: 26, } : { flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', backgroundColor: '#387CF0', borderTopRightRadius: 26, }}>
                            <Grid item >
                                <img src={Publish} onClick={handlePublishOpen} style={{ marginLeft: 20, width: 31, height: 31, cursor: 'pointer' }} />
                            </Grid>
                            <Grid item >
                                <Typography variant="h6" style={collectionSaved ? { marginLeft: '20px', height: 39, margin: '10px', color: '#008B23', cursor: 'pointer' } : { marginLeft: '20px', height: 39, margin: '10px', color: '#FFF', cursor: 'pointer' }} onClick={() => formik.submitForm()}>  {publishText} </Typography>
                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>
            </Box >
        )
    }


    const closeOnHoverLeave = () => {
        setOpen(false)


    }

    console.log(formik.values);




    const drawerContent = () => {

        return (
            <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 50, marginBottom: 40, height: '100%', marginLeft: -8 }}>

                <Grid item>
                    {[
                        {
                            name: 'Back',

                            activeIcon: BackButton,
                            inActiveIcon: BackButton,

                        },
                        {
                            name: 'Settings',

                            activeIcon: Settings,
                            inActiveIcon: Settings,
                        },



                    ].map((text, index) => {
                        console.log(text);
                        if (text.name === 'Settings') {
                            return (
                                <div className={classes.hideScrollBar} key={index} onClick={() => { handleSettingsOpen(); closeOnHoverLeave(); }} style={{ width: drawerWidth - 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', overflowX: 'scroll', margin: 10, cursor: 'pointer' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 5, backgroundColor: 'rgb(134 170 243 / 43%)', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 32, borderBottomRightRadius: 32, padding: 10 }}>
                                        <img src={location.pathname === text.route ? text.activeIcon : text.inActiveIcon} style={{ width: 28, height: 28 }} />

                                    </div>

                                </div>
                            )
                        } else {
                            return (
                                <div className={classes.hideScrollBar} key={index} onClick={() => { console.log("Back clicked"); history.go(-1); }} style={{ width: drawerWidth - 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', overflowX: 'scroll', margin: 10, cursor: 'pointer' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 5, backgroundColor: 'rgb(134 170 243 / 43%)', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 32, borderBottomRightRadius: 32, padding: 10 }}>
                                        <img src={location.pathname === text.route ? text.activeIcon : text.inActiveIcon} style={{ width: 28, height: 28 }} />

                                    </div>

                                </div>

                            )
                        }


                    }
                    )}
                </Grid>

                <Grid item>
                    {[

                        {
                            name: 'Save as Draft',
                            activeIcon: SaveRecipe,
                            inActiveIcon: SaveRecipe,

                        },
                        {

                            name: 'Publish',
                            activeIcon: Publish,
                            inActiveIcon: Publish,


                        },


                    ].map((text, index) => {

                        if (text.name === 'Save as Draft') {
                            return (


                                <div className={classes.hideScrollBar}
                                    onClick={handleDraftsOpen}




                                    style={{ width: drawerWidth - 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', overflowX: 'scroll', margin: 10, cursor: 'pointer' }}>
                                    <div style={collectionDrafted ? { display: 'flex', flexDirection: 'row', paddingLeft: 5, backgroundColor: '#CCF0D5', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 32, borderBottomRightRadius: 32, padding: 10 }
                                        : { display: 'flex', flexDirection: 'row', paddingLeft: 5, backgroundColor: 'rgb(134 170 243 / 43%)', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 32, borderBottomRightRadius: 32, padding: 10 }}>
                                        <img src={location.pathname === text.route ? text.activeIcon : text.inActiveIcon} style={{ width: 28, height: 28 }} />


                                    </div>

                                </div>

                            )
                            //Section
                        } else {
                            return (

                                <div className={classes.hideScrollBar}
                                    //onClick={() => formik.submitForm()}

                                    onClick={handlePublishOpen}
                                    style={{ width: drawerWidth - 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', overflowX: 'scroll', margin: 10, cursor: 'pointer' }}>
                                    <div style={collectionSaved ? { display: 'flex', flexDirection: 'row', paddingLeft: 5, backgroundColor: '#CCF0D5', borderTopLeftRadius: open ? 32 : 0, borderBottomLeftRadius: open ? 32 : 0, borderTopRightRadius: !open ? 32 : 0, borderBottomRightRadius: !open ? 32 : 0, padding: 10 }
                                        : { display: 'flex', flexDirection: 'row', paddingLeft: 5, backgroundColor: '#387CF0', borderTopLeftRadius: open ? 32 : 0, borderBottomLeftRadius: open ? 32 : 0, borderTopRightRadius: !open ? 32 : 0, borderBottomRightRadius: !open ? 32 : 0, padding: 10 }}>
                                        <img src={location.pathname === text.route ? text.activeIcon : text.inActiveIcon} style={{ width: 28, height: 28 }} />

                                    </div>

                                </div>
                            )
                        }


                    }
                    )}
                </Grid>


            </Grid>
        )
    }


    return (

        <Grid style={{ display: "flex", flexDirection: "row", background: "#F8F9FA", marginLeft: -70, height: "auto" }} classes={{ root: classes.typography, }}>



            <Grid item style={{ width: 293 }}>
                <Grid item flexDirection="column" alignContent="space-between" marginTop="10px" >
                    <Grid marginBottom="20px">

                        <Drawer variant="permanent" open={open}

                            sx={{
                                display: { xs: 'none', sm: 'block', },
                                '& .MuiDrawer-paper': { marginTop: "-1px", background: '#F8F9FA', outline: 0, border: 'none', display: "flex", flexDirection: "column", justifyContent: "space-between" },
                            }}

                        >
                            {drawerContent()}

                        </Drawer>
                        <Box
                            component="nav"
                            sx={{
                                flexShrink: { sm: 0 }
                            }}

                        >
                            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                            <MuiDrawer

                                variant="temporary"
                                open={AppState.drawerOpen}
                                onClose={AppState.closeDrawer}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                }}
                                sx={{
                                    display: { xs: 'block', sm: 'none' },
                                    '& .MuiDrawer-paper': { width: drawerWidth },
                                }}
                            >
                                {drawerContent()}
                            </MuiDrawer>

                        </Box>
                    </Grid>
                </Grid>

                <Grid  >
                    <Grid >

                        <SwipeableDrawer
                            open={settingsDrawer}
                            variant="persistent"
                            PaperProps={{
                                style: {
                                    marginTop: 116, boxShadow: 'none', height: "40vh", border: '1px solid #397FF75F', borderTopRightRadius: 30, borderBottomRightRadius: 30, overflowX: 'hidden'

                                },

                                classes: { root: classes.hideScrollBar }



                            }}
                            ModalProps={
                                { hideBackdrop: true }
                            }>

                            {openSettingsDrawer()}
                        </SwipeableDrawer>
                    </Grid>
                </Grid>



                <Grid >


                    {/* {openSettingsDrawer2()} */}
                    <SwipeableDrawer
                        open={saveDrawer}
                        onCLose={!saveDrawer}
                        variant="persistent"
                        PaperProps={{
                            style: {
                                marginTop: 594, boxShadow: 'none', height: "7vh", border: '1px solid #397FF75F', borderTopRightRadius: 30, borderBottomRightRadius: 30, overflowX: 'hidden',

                            },

                            classes: { root: classes.hideScrollBar }
                        }}
                        ModalProps={{
                            hideBackdrop: true,
                        }}
                    >

                        {openSaveDrawer()}

                    </SwipeableDrawer>

                </Grid>

                <Grid >


                    {/* {openSettingsDrawer2()} */}
                    <SwipeableDrawer
                        open={publishDrawer}
                        onClose={!publishDrawer}
                        variant="persistent"
                        PaperProps={{
                            style: {
                                marginTop: 653, boxShadow: 'none', height: "7vh", border: '1px solid #397FF75F', borderTopRightRadius: 30, borderBottomRightRadius: 30, overflowX: 'hidden',

                            },

                            classes: { root: classes.hideScrollBar }
                        }}
                        ModalProps={{
                            hideBackdrop: true,
                        }}
                    >

                        {openPublishDrawer()}

                    </SwipeableDrawer>

                </Grid>
            </Grid>



            <Grid item style={{ display: 'flex', flexDirection: 'column', height: "100%", marginLeft: -20 }}>

                {/* Creation/MetaData Details */}
                <CollectionMetaDataCreation formik={formik} />


                {/* Step Details */}
                <Grid style={{ marginTop: 20, width: '1200px', height: 'auto', background: '#FFF', display: 'flex', }} >


                    <PublishedCollections formik={formik} />

                </Grid>
            </Grid>


            {creatorModeOn && <TurnCreator creatorModal={true} />}
        </Grid >


    )
}

// World ends here
export default CreateCollection