import { Grid, Typography } from '@mui/material';
import React, { useEffect, useContext, useState } from 'react'
import AppContext from '../../../config/AppContext';
import CreatorNavbar from '../Common/CreatorNavbar';
import CreatorsPage from '../Dashboard';
import Avatar from '@mui/material/Avatar';
import { user } from '../../../cloud-services-core/index';
import AlternateCardLayout from '../CardLayouts/AlternateCard';
import CloudServices from '../../../cloud-services-core/index';

const Feed = () => {
    const AppState = useContext(AppContext);
    const [users, setUsers] = useState([]);
    const [currentUser, setCurrentUser] = useState(AppState?.user?.id)
    const [articleDrafts, setArticleDrafts] = useState([])
    const fetchAllUsers = async () => {
        const result = await user.getAllUsers()
        setUsers(result);
        console.log(result);
    }

    const getArticles = async () => {
        let article = await CloudServices.articles.fetchByCreatorId(AppState.user.id);
        setArticleDrafts(article);
        console.log(article);
    }

    useEffect(() => {
        AppState.handleNavbar(false);
        fetchAllUsers();
        getArticles();
        return () => AppState.handleNavbar(true)
    }, [])

    console.log(AppState);

    const userList = [{ name: 'bahadur' }, { name: 'salman' }, { name: 'virat' }]

    return (
        <>
            <Grid style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <CreatorNavbar currentMode='View' />
                <Grid style={{ width: '100%' }}>
                    <Grid style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#FAFBFC', justifyContent: 'flex-start', alignItems: 'center', height: 140, width: '100%' }}>
                        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 20, }}>
                            <Grid style={{ width: 94, height: 94, backgroundColor: '#F94C0C', borderRadius: 50 }}>

                            </Grid>
                            <Typography>View All</Typography>
                        </Grid>
                        {
                            userList.length > 0 && userList.map((chef) => (
                                <Grid style={{ margin: 20, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <Avatar alt="chef name" style={{ width: 94, height: 94 }} />
                                    <Typography>{chef.name}</Typography>
                                </Grid>
                            ))
                        }
                    </Grid>
                    <AlternateCardLayout articleDrafts={articleDrafts} />
                </Grid>
            </Grid>
        </>

    )
}

export default Feed