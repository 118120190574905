import { CRUDoperations } from '../CRUD/crud'



const actions = {
    create: async (value) => {
        console.log("in create operation", value)
        try {
            const result = await CRUDoperations.create("actions", value)
            console.log(result)
            return result


        }
        catch (err) {

        }
    },
    read: async () => {
        console.log("in read operation")
        try {
            const Data = await CRUDoperations.read("actions")
            console.log(Data)
            return Data
        }
        catch (err) {
            throw err

        }

    },
    update: async (docId, updatedData) => {

        try {
            const updating = await CRUDoperations.update("actions", docId, updatedData)
            return updating


        }
        catch (err) {
            throw err

        }

    },
    delete: async (docId) => {
        try {
            const deleting = await CRUDoperations.delete("actions", docId)
            return deleting

        }
        catch (err) {
            throw err

        }

    }
}

export default actions