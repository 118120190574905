import React, { Component } from 'react';
import FirebaseApi from '../../apis/firebaseApi';
import NewRow from './cupdate';
import { DataGrid } from '@material-ui/data-grid';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Backdrop, Button, CircularProgress, Divider, Grid, Paper, Snackbar, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import RefreshIcon from '@material-ui/icons/Refresh';
import CupUpdate from '../../components/CupUpdate';

const header = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 150 },
    { field: "description", headerName: "Description", width: 250 },
    { field: "synonyms", headerName: "synonyms", width: 100 },
]

class IngredientCategories extends Component {
    state = {
        openDialog: false,
        dataOf: "ingredientCategory",
        dataOfPlural: "IngredientCategories",
        rowData: [],
        selectedRows: [],
        snackbarVisibe: true,
        snackBarMessage: "Fetching data ...",
        actionInProgress: false,
        lastFetchedTimeStamp: null,
        dataStructure:["type","synonyms","name","description"]
    }

    toggleDialog = (e) => {
        e.preventDefault();
        this.setState({ openDialog: !this.state.openDialog })
    }

    updateData = (updatedData) => {
        this.setState({ rowData: updatedData, snackbarVisibe: false })
    }

    toggleSnackbar = (message = "", visible = false, actionInProgress = false) => {
        this.setState({ snackBarMessage: message, snackbarVisible: visible, actionInProgress: actionInProgress })
    }

    fetchData = () => {
        this.toggleSnackbar("Fetching data ...", true, false);
        FirebaseApi[this.state.dataOf].get().then(function (snapshot) {
            var newData = [];
            snapshot.forEach(function (childSnapshot) {
                var id = childSnapshot.id;
                var data = childSnapshot.data();
                //   newData.push({ id: id, name: data.name, description: data.description, synonyms: data.synonyms});
                newData.push({ ...data, id: id });
            });
            this.updateData(newData);
            this.setState({ lastFetchedTimeStamp: new Date().toGMTString() })
            this.toggleSnackbar("Updated data ... ", true, false)
        }.bind(this));
    }

    addRow = (rowData) => {
        this.toggleSnackbar("Saving ...", true, true)
        FirebaseApi[this.state.dataOf].add({
            ...rowData
        })
            .then(function (docRef) {
                this.toggleSnackbar("Saved ...", true, false);
                console.log("Data row created with ID: ", docRef.id);
            }.bind(this))
            .catch(function (error) {
            })
        this.toggleSnackbar("", false, false)
    }

    componentDidMount() {
        this.fetchData()
    }

    handleDialogClose = () => {
        this.setState({ openDialog: !this.state.openDialog, snackbarVisibe: false })
        this.fetchData()
    }

    deleteRows = () => {
        console.log(this.state.selectedRows)
        var rowsToDelete = this.state.selectedRows
        rowsToDelete.map((rowId) => {
            FirebaseApi[this.state.dataOf].doc(rowId).delete().then(() => {
                console.log("Deleted :" + rowId);
            }).catch((e) => {
                // console.log("Failed to delete :" + row.name )
            })
        })
        this.fetchData();
    }

    editRow = () => {
        this.setState({openDialog:true})

    }


    selectARow = (newSelection)=>{
        
        const getSelectedData = this.state.rowData.filter((data)=>data.id===newSelection.rowIds[0])
     
        this.setState({selectedRows: newSelection.rowIds ,selectedRowData:getSelectedData[0]})
    }

    updateRow = (data,id) =>{
        this.toggleSnackbar("Updating ...", true, true)
        FirebaseApi[this.state.dataOf].doc(id).update(data)
        .then((result)=>{
            this.toggleSnackbar("Updated ...", true, false);

        })
        .catch((error)=>{
            console.log({error})
        })
    }

    actionTriggered = ({edited=false,data,id}) =>{
        if(edited){
            this.updateRow(data,id)

        }
        else{
            this.addRow(data)
            
        }

    }

    render() {
        console.log(this.state)
        return (
            <Grid>
                <Backdrop style={{ zIndex: 4, color: "white" }} open={this.state.snackbarVisibe} onClose={this.toggleSnackbar}>
                    <CircularProgress />
                </Backdrop>
                <Dialog open={this.state.openDialog} onClose={this.handleDialogClose} aria-labelledby="form-dialog-title" >
                    <Button onClick={this.handleDialogClose} color="primary" style={{ position: "absolute", top: 10, right: 10 }}>
                        x
                    </Button>
                    <DialogTitle id="form-dialog-title">Add New {this.state.dataOf}</DialogTitle>
                    <DialogContent>
                        <CupUpdate data={this.state.selectedRowData} initialValues={{type:"",synonyms:"",name:"",description:""}} dataStructure={this.state.dataStructure} triggerAction={(data)=>this.actionTriggered(data)} />
                        {/* <NewRow toggleSnackbar={this.toggleSnackbar} actionInProgress={this.state.actionInProgress} addRow={this.addRow} /> */}
                    </DialogContent>
                </Dialog>
                <Grid container direction="column" style={{ height: 150 }} spacing={2}>
                    <Typography variant="h3">
                        {this.state.dataOfPlural}
                    </Typography>
                    <Typography variant="caption">Last updated at :{this.state.lastFetchedTimeStamp}</Typography>
                    <Grid container direction="row" alignItems="center" justify="flex-start" spacing={2} style={{ marginTop: 5, marginBottom: 5 }}>
                        <Button variant="contained" onClick={this.fetchData} disabled={this.state.actionInProgress} style={{ margin: 5 }}>
                            <RefreshIcon />
                        </Button>
                        <Button variant="contained" onClick={this.toggleDialog} style={{ margin: 5 }}>
                            <AddIcon />
                        </Button>
                        <Button variant="contained" onClick={this.editRow} style={{ margin: 5 }} disabled={this.state.selectedRows.length == 1 ? false : true}>
                            <EditIcon />
                        </Button>
                        <Button variant="contained" onClick={this.deleteRows} style={{ margin: 5 }}
                            disabled={this.state.selectedRows.length > 0 ? false : true}>
                            <DeleteIcon />
                        </Button>
                    </Grid>
                </Grid>
                <Divider />
                <Grid style={{ height: 550, width: '100%' }}>
                    <DataGrid sortModel={[
                        {
                            field: 'name',
                            sort: 'asc',
                        },
                    ]}
                        // onRowSelected={(row) => this.setState({ selectedRows: [...this.state.selectedRows, row.data], })}
                        onSelectionChange={(newSelection) => this.selectARow(newSelection)}
                        rows={this.state.rowData}
                        columns={header}
                        pageSize={10}
                        checkboxSelection
                    />
                </Grid>
            </Grid>
        );
    }
}

export default IngredientCategories;