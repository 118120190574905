import React, { useState, useContext, useEffect } from 'react'
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import { useHistory, useLocation } from "react-router-dom";
import { Button, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Fuse from 'fuse.js'
import { Autocomplete } from '@mui/material';
import { FirebaseApi } from '../../../firebaseConfig';
import Modal from '@mui/material/Modal';
import AppContext from '../../../config/AppContext';
import CloudServices from '../../../cloud-services-core';
import * as Yup from 'yup';


const AllergyModal = (props) => {

    const [modal, setModal] = useState(true);
    const [dataOf, setDataOf] = useState('allergies')
    const [imageLink, setImageLink] = useState('');
    //const [selectedRows, setSelectedRowData] = useState([])

    const [ingredientsLoading, setIngredientsLoading] = useState(false)
    const [ingredientList, setIngredientList] = useState([])
    const [fuse, setFuse] = useState(null)

    const [newIngredientSuggestions, setNewIngredientSuggestions] = useState([])
    const [addingIngredient, setAddingIngredient] = useState('')
    const [inputValue, setInputValue] = useState([]);

    // newAdded
    const location = useLocation()
    const [currentEntityData, setCurrentEntityData] = useState(location.state)
    const history = useHistory();

    // for Getting Data from DB
    const getIngredientsList = async () => {
        setIngredientsLoading(true)
        
        try {
           
            const ingredientsFromDB = await FirebaseApi["ingredient"].get();

               let ingredientsData = ingredientsFromDB.docs.map((ingredient) => {
                    return {
                        id: ingredient.id,
                        ingredient: (ingredient.data().name ?? ingredient.data().data),
                        ...ingredient.data()
                    }
               })
                setIngredientList(ingredientsData)

                const fuseInstance = new Fuse(ingredientsData, {
                    useExtendedSearch: true,
                    keys: ["name"]
                })
                setFuse(fuseInstance)
            //checkRecipeIngredientsInOurDB['exactMatch'](fuseInstance)
        } catch (error) {
            console.log(error)
        }
    }
    // console.log(ingredientList);

    const addingNewIngredient = (e) => {
        setAddingIngredient(e.target.value)
        console.log(checkForIngredientOrSimilarIngredients(fuse, e.target.value))
        setNewIngredientSuggestions(checkForIngredientOrSimilarIngredients(fuse, e.target.value))
    }
    const checkForIngredientOrSimilarIngredients = (fuse, name) => {

        return fuse.search(`'${name.replace(/[^a-zA-Z ]/g, "")}`).map(i => i.item)
    }


    // For Images
    const isImageEdited = () => {
        if (currentEntityData) {
            console.log("setting image to current Data")
            setImageLink(currentEntityData.imageLink)
            formik.setFieldValue('imageLink', currentEntityData.imageLink);
            //setInputValue(currentEntityData.imageLink)

        } else {
            console.log("setting image to zero")
            setImageLink(imageLink);

        }
    }



    const changeImageLink = (value) => {
        formik.setFieldValue('imageLink', value);
        setImageLink(value);
    }


    useEffect(() => {
        if (location.state) {
            setCurrentEntityData(location.state)
        } else {
            // Get the data from the db using the params
        }
        getIngredientsList()
        isImageEdited();

    }, [])


    const onInputChanges = (name, value) => {
        console.log(name);
        formik.setFieldValue('ingredientExcluded', value)
    };


    // CRUD operations
    const addAllergy = (rowData) => {
        FirebaseApi[dataOf].add({
            ...rowData
        })
            .then(function (docRef) {

            })
            .catch(function (error) {
            })
    }

    const updateAllergy = async (data, id) => {

        // FirebaseApi[dataOf].doc(id).update(data)
        //     .then((result) => {

        //         setSelectedRowData(null)

        //     })
        //     .catch((error) => {
        //         console.log({ error })
        //     })
        try {
            const updateData = await CloudServices.allergies.update(id, data);
            console.log("user", updateData);
        } catch (err) {
            console.log(err);
        }
    }



    const actionTriggered = (edited, data) => {
        if (data.id) {
            history.goBack();
            const { name, ingredientExcluded, description } = data
            updateAllergy({ name, ingredientExcluded, description }, data.id)
        }
        else {
            history.goBack();
            const { name, ingredientExcluded, description } = data
            addAllergy({ name, ingredientExcluded, description })
        }
    };

    // const AllergySchema = ;

    const formik = useFormik({
        initialValues: {
            'id': currentEntityData ? currentEntityData.id : '',
            'name': currentEntityData ? currentEntityData.name : '',
            'ingredientExcluded': currentEntityData ? currentEntityData.ingredientExcluded : [],
            // 'ingredientExcludedIDs': [],
            // 'imageLink': imageLink,
            'description': currentEntityData ? currentEntityData.description : '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required('Required'),
            ingredientExcluded: Yup.string().required('Required'),
        }),
        onSubmit: values => {
            if (currentEntityData) {
                actionTriggered(true, values)
            } else {
                actionTriggered(false, values)
            }

        },
    });

    const { values, errors } = formik;

    return (
        <Grid >
            <Modal
                open={modal}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <form onSubmit={formik.handleSubmit} style={{ outline: "none" }}>
                    <Grid sx={style} container spacing={2} flexDirection='column' justifyContent="space-between">
                        <Grid item style={{ display: "flex", flexDirection: "row" }} justifyContent='space-between' marginBottom='10'>
                            {currentEntityData ? <Typography fontWeight='700' variant='h6'>Edit Allergy</Typography> : <Typography fontWeight='700' variant='h6'>Add Allergy</Typography>}
                            <CloseIcon style={{ marginLeft: '100px', cursor: 'pointer' }} onClick={() => history.goBack()} />
                        </Grid>
                        <Grid item>
                            {errors.name && errors.ingredientExcluded ? 
                                <Typography style={{ color: 'red', fontSize: 10 }}>Please fill in the required fields</Typography>: null
                            }
                        </Grid>
                        <Grid item>
                            <Typography>Name*</Typography>
                            <TextField
                                id="name"
                                value={values.name}
                                variant="outlined"
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item>
                            <Typography>Ingredients excluded*</Typography>
                            <Autocomplete
                                id="ingredientExcluded"
                                style={{ width: "50%", zIndex: 1000, flexGrow: 1 }}
                                options={ingredientList}
                                autoHighlight
                                multiple
                                getOptionLabel={(option) => (option.ingredient) || ""}
                                onChange={(e, value) => onInputChanges('ingredientExcluded', value)}
                                value={values.ingredientExcluded}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Ingredients excluded"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={() => { formik.handleSubmit(); }}>{currentEntityData ? 'Save' : 'Add'}</Button>
                        </Grid>
                    </Grid>
                </form>
            </Modal>
        </Grid>

    )
}


export default AllergyModal

const style = {
    // position: 'absolute',
    marginTop: "5%",
    marginLeft: 'auto',
    marginRight: "auto",
    // transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};