import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import { Button, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import * as Yup from 'yup';
import { FirebaseApi } from '../../../firebaseConfig';
import Modal from '@mui/material/Modal';

import ImageUploader from '../ImageUploader';
import { useHistory, useLocation } from 'react-router-dom';

const IngredientTypeModal = (props) => {
    const [modal, setModal] = useState(true);
    const [dataOf, setDataOf] = useState('ingredientType')
    const [imageLink, setImageLink] = useState('');
    const [selectedRows, setSelectedRowData] = useState([])

    const location = useLocation()

    const [currentEntityData, setCurrentEntityData] = useState(location.state)

    const history = useHistory();

    // For Images
    // const isImageEdited = () => {
    //     if (currentEntityData) {
    //         console.log("setting image to current Data")
    //         setImageLink(currentEntityData.imageUrl)
    //         formik.setFieldValue('imageUrl', currentEntityData.imageUrl);
    //     } else {
    //         console.log("setting image to zero")
    //         setImageLink(imageUrl);

    //     }
    // }

    const changeImageLink = (value) => {
        formik.setFieldValue('imageUrl', value);
        // setImageLink(value);
    }

    useEffect(() => {
        if (location.state) {
            setCurrentEntityData(location.state)
        } else {
            // Get the data from the db using the params
        }
        // isImageEdited();
    }, [])

    // CRUD
    const addType = (rowData) => {
        FirebaseApi[dataOf].add({
            ...rowData
        }).then(function (docRef) {
        })
            .catch(function (error) {
            })
    }

    const updateType = (data, id) => {
        FirebaseApi[dataOf].doc(id).update(data)
        .then((result) => {
            setSelectedRowData(null)
        })
        .catch((error) => {
            console.log({ error })
        })
    }

    const actionTriggered = (edited, data) => {
        if (data.id) {
            const { imageUrl, name, description } = data
            updateType({ imageUrl, name, description }, data.id);
            history.goBack();
        }
        else {
            const { imageUrl, name, description } = data
            addType({ name, imageUrl, description })
            history.goBack();
        }
    }

    const formik = useFormik({
        initialValues: {
            'id': currentEntityData ? currentEntityData.id : '',
            'name': currentEntityData ? currentEntityData.name : '',
            'imageUrl': currentEntityData ? currentEntityData.imageUrl : '',
            'description': currentEntityData ? currentEntityData.description : '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required("required"),
        }),
        onSubmit: values => {
            console.log(values)
            if (currentEntityData) {
                actionTriggered(true, values)
            } else {
                actionTriggered(false, values)
            }

        },
    });

    return (
        <Grid >
            <Modal
                open={modal}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <form onSubmit={formik.handleSubmit} style={{ outline: "none" }}>
                    <Grid sx={style} container spacing={2} flexDirection='column' justifyContent="space-between">
                        <Grid item style={{ display: "flex", flexDirection: "row" }} justifyContent='space-between' marginBottom='10'>
                            <Typography fontWeight='700' variant='h6'> {currentEntityData ? 'Edit an Ingredient Type': 'Add an Ingredient Type'}</Typography>
                            <CloseIcon style={{ marginLeft: '100px', cursor: 'pointer' }} onClick={() => history.goBack()} />
                        </Grid>
                        <Grid item style={{ display: "flex", flexDirection: "row" }}>
                            {
                                formik.values.imageUrl &&
                                <img src={formik.values.imageUrl} alt="typeImage" id="imageLink" style={{ paddingLeft: "px", width: 100, height: 100 }} />
                            }
                            <ImageUploader id="imageLink" setImageLink={changeImageLink} storagePlace={'recipe'} />
                        </Grid>
                        <Grid item>
                        {
                            formik.errors.name ? 
                            <Typography style={{ color: 'red', fontSize: 10 }}>Please fill in the required fields</Typography>: null
                        }
                        </Grid>
                        <Grid item>
                            <Typography>Name*</Typography>
                            <TextField
                                id="name"
                                defaultValue={formik.values.name}
                                variant="outlined"
                                onChange={formik.handleChange}
                                placeholder="Name"
                            />
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={() => { formik.handleSubmit(); }}>{currentEntityData ? 'Save' : 'Add'}</Button>
                        </Grid>
                    </Grid>
                </form>
            </Modal>
        </Grid>

    )
}


export default IngredientTypeModal

const style = {
    marginTop: "5%",
    marginLeft: 'auto',
    marginRight: "auto",
    width: '50%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};