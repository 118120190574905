import React, { useState, useContext } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { makeStyles } from '@mui/styles'
import { TextField } from '@mui/material';

///steps
import { styled } from '@mui/material/styles'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'

/// LeftNav
import Collapse from '@mui/material/Collapse';
// web.cjs is required for IE11 support
import { useSpring, animated } from 'react-spring';
import PropTypes from 'prop-types';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

/// Drawer
import { Switch as CustomSwitch } from '../../../../components/Switch'
import DeleteAccordion from '../../../../assets/DeleteAccordion.svg';
import { FieldArray } from 'formik';

// Internal Imports
import generateId from '../../../../components/GenerateId';
import CreateRecipeContext from '../../../../config/CreateRecipeContext'
import { RecipeStepsAccordion } from './StepsAccordion'
import MuiButton from '../../../../components/MuiButton'

function TransitionComponent(props) {
    const style = useSpring({
        from: {
            opacity: 0,
            transform: 'translate3d(20px,0,0)',
        },
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
        },
    });
    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

TransitionComponent.propTypes = {
    /**
     * Show the component; triggers the enter or exit states
     */
    in: PropTypes.bool,
};

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    background: '#FFF',
    marginBottom: 20,
    '&:before': {
        display: 'none',
    },
    width: 1238,
    marginLeft: 113,
    color: '#AFAFAF',

    '& .Mui-focused': {
        backgroundColor: 'white',

    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? '#FFF'
            : '#FFF',
    background: '#FFFFFF',

    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: 8,
        background: 'none',

    },
    '& .Mui-focusVisible': {
        backgroundColor: 'white',
        background: 'red',
    },

    width: '100%'
}));

// styles
const useStyles = makeStyles((theme) => ({
    typography: {
        "& .MuiTypography-root": {

            fontFamily: 'Product Sans',
        }
    },
    /// Steps
    addSection: {
        alignItems: 'center',
        border: 'none',
        background: 'transparent',
        fontSize: 14,
        fontWeight: 'bold',
        color: '#387CF0',
        '&:hover': {
            cursor: 'pointer'
        },
        fontFamily: 'Product Sans'
    },
    addSectionDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        border: "1px solid #387CF0",
        padding: 15,
        backgroundColor: 'white',
        borderRadius: 12,
        marginLeft: 113,
        width: 1238,
        alignItems: 'center',
        marginTop: 8,
        // marginTop: 30,
        fontFamily: 'Product Sans'
    },

    deleteCircle: {
        color: "#FA3131", fontSize: 18, width: "30px", height: "30px"
    },

    // section, step name edit
    sectionNameInput: {
        fontFamily: 'Product Sans',
        textTransform: 'uppercase',
        outline: 'none',
        border: 'none',
        background: 'transparent',
        width: '300px',
        fontWeight: "700",
        color: '#292929'
    },

    derivedIngredientInput: {
        marginLeft: "20px",
        width: 230,
        height: 42,
        fontFamily: 'Product Sans',
        '&.Mui-focused': {
            //borderBottom: '2px solid #1F8BDC',
            paddingBottom: 12,
            paddingTop: 11,
        },
        "&.MuiInput-underline:before": {
            borderBottomColor: "#1F8BDC"
        },
        "&.MuiInput-underline:focus": {
            borderBottomColor: "#1F8BDC"
        },
        "&.MuiInput-underline:after": {
            borderBottomColor: "#1F8BDC"
        }
    }
}))

const InstructionsAccordion = (props) => {
    const classes = useStyles()
    const { formik } = useContext(CreateRecipeContext)
    const { values, setFieldValue } = formik
    const [indexValue, setIndexValue] = useState(null);
    const [sectionExpand, setSectionExpand] = useState(true);
    const [toggleDerived, setToggleDerived] = useState(false);

    const handleDerivedIngredient = (e, index) => {
        setFieldValue(`recipeInstructions[${index}].derivedIngredients`, e.target.value)
    }

    const toggleSectionAccordion = (index) => {
        setSectionExpand(!sectionExpand);
    }

    const toggleDerivedIngredient = () => {
        setToggleDerived(!toggleDerived);
    }

    return (
        <FieldArray name='recipeInstructions'
            render={(arrayHelpers) => (
                <Grid classes={{ root: classes.typography }}>
                    {values.recipeInstructions?.length > 0 && values.recipeInstructions.map((value, index) => (
                        <Grid style={{ display: 'flex', flexDirection: 'row' }} key={value.name}>
                            <Grid item>
                                <Accordion expanded={sectionExpand}>
                                    <AccordionSummary style={{ width: 1238, backgroundColor: '#D0D0D0 ' }}>
                                        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                            <Box item >
                                                <input defaultValue={value.name} placeholder="Add Section Title Here" onBlur={(e) => setFieldValue(`recipeInstructions[${index}].name`, e.target.value)} onClick={(e) => { e.stopPropagation(); setIndexValue(index); }} className={classes.sectionNameInput} />
                                            </Box>
                                            <Box item style={{ height: '22px', display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 265 }}>
                                                <CustomSwitch checked={indexValue === index && toggleDerived} onChange={() => { setIndexValue(index); toggleDerivedIngredient(); }} style={{ color: '#387CF0' }} />
                                                {indexValue === index && toggleDerived ?
                                                    <TextField variant='standard' onBlur={(e) => { handleDerivedIngredient(e, index); }}
                                                        InputProps={{ className: classes.derivedIngredientInput }} defaultValue={values.recipeInstructions[index].derivedIngredients} placeholder="Name the Derived Ingredient" /> :
                                                    <Typography style={{ marginLeft: 5, color: '#292929' }}> Derived Ingredient </Typography>}
                                            </Box>
                                            <Box >
                                                <ExpandCircleDownIcon 
                                                    onClick={() => toggleSectionAccordion()} 
                                                    style={{ color: '#FFFFFF' }}
                                                />
                                            </Box>
                                        </Box>
                                    </AccordionSummary>
                                    <Grid style={{ height: 20, background: '#F8F9FA', width: 1238 }}>
                                    </Grid>
                                    <RecipeStepsAccordion index={index} value={value} />
                                    <Grid style={{ height: 23, background: '#F8F9FA', width: 1238 }}>
                                    </Grid>
                                </Accordion>
                            </Grid>
                            {indexValue === index &&
                                <Grid item onClick={(e) => { arrayHelpers.remove(index); }}>
                                    <img src={DeleteAccordion} style={{ height: 48, marginLeft: 2, marginTop: 1 }} alt="delete" />
                                </Grid>
                            }
                        </Grid>
                    ))
                    }
                        <MuiButton style={{ width: 1238, marginLeft: 113, marginTop: 10}} click={() => arrayHelpers.push({
                            id: `${values.recipeInstructions?.length + 1}`,
                            name: `Section ${values.recipeInstructions?.length + 1}`,
                            ingredients: [{ id: generateId(), name: 'chicken' }],
                            itemListElement: [
                                {
                                    id: generateId(),
                                    text: "",
                                    ingredients: [],
                                    parameters: {
                                        toolsUsed: [],
                                        temperature: null,
                                        duration: {
                                            time: 0,
                                            unit: 'sec'
                                        },
                                        pro: {
                                            cookingAction: 'Heat',
                                            stirring: false,
                                            temperature: {
                                                value: 0,
                                                unit: 'c'
                                            },
                                            power: {
                                                value: null,
                                                unit: 'watt'
                                            }
                                        }
                                    },
                                    images: [],
                                    cookingStyle: '',
                                }
                            ]
                        })
                        }
                        title='ADD A SECTION'
                        />

                </Grid >
            )
            }
        />



    )

}

export default InstructionsAccordion