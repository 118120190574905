import { CRUDoperations } from '../CRUD/crud'



const shoppingCart = {
    create: async (userId, item) => {

        try {
            const existingDoc = await shoppingCart.read()

            if (existingDoc.length > 0) {
                const cart = existingDoc[0]
                const alreadyExists = cart.list.filter(listItem => listItem.name === item.name)

                if (alreadyExists.length > 0) {
                    throw 'Item Already in Cart'
                }
                else {
                    const updatedList = await shoppingCart.update(existingDoc[0].id, { ...existingDoc[0], list: [...existingDoc[0].list, item] })

                    return updatedList

                }
            }
            else {
                const result = await CRUDoperations.create("collections", {
                    name: 'cart',
                    userId,
                    list: [item],
                    type: 'shoppingCart'
                })
                return result

            }


        }
        catch (err) {
            throw err
        }
    },
    read: async (userId) => {
        try {
            const Data = await CRUDoperations.search("collections", { keyword: [["userId","==",`${userId}`],["type", "==", "shoppingCart"]] })

            return Data
        }
        catch (err) {
            throw err

        }

    },
    update: async (docId, updatedData) => {

        try {
            const updating = await CRUDoperations.update("collections", docId, updatedData)
            return updating


        }
        catch (err) {
            throw err

        }

    },
    delete: async (name) => {
        try {
            const existingDoc = await shoppingCart.read()
            const cart = existingDoc[0]
            const deleteItem = cart.list.filter(item => item.name !== name)
            const updateCart = await shoppingCart.update(cart.id, { ...cart, list: deleteItem })
            // const deleting = await CRUDoperations.delete("collections", docId)
            return 'Removed From Cart'

        }
        catch (err) {
            throw err

        }

    }
}

export default shoppingCart