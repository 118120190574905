import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { ProfileLeftContainerStep1 } from '../Styles';
import ProfileImage from '../../../assets/ProfileCreation/boy-cooking.png';

const ProfileImage1 = () => {
  return (
    <Grid container>
      <ProfileLeftContainerStep1>
        <img src={ProfileImage} alt="step no 1 images" style={{ width: 420, height: 400}} />
        <Typography variant='h4' style={{ marginTop: 10, color: 'white' }}>Create actionable content</Typography>
      </ProfileLeftContainerStep1>
    </Grid>
  )
}

export default ProfileImage1