import React, { useContext, useEffect, useState } from 'react'
import { Redirect } from 'react-router'
import Google from '../../assets/google.svg'
import Facebook from '../../assets/facebook.svg'
import CloudServices from '../../cloud-services-core'
import AppContext from '../../config/AppContext'
import firebase, { auth, FirebaseApi } from '../../firebaseConfig'
import { useFormik } from 'formik';
import CircularProgress from '@mui/material/CircularProgress';
import { Grid, Button, Typography, TextField, Box, Divider, Paper } from '@mui/material'
import { styled } from '@mui/material/styles'

const LoginPopup = styled(Paper)({
    width: 500,
    height: 600,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '9vh'
});

const LoginPageButton = styled(Grid)({
    border: '1px solid black',
    background: '',
    borderRadius: 30,
    width: '90%',
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around'
});

const LoginPageButtonImage = styled(Button)({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around'
})

export default function Login(props) {
    const [loading, setLoading] = useState(false)
    const [signUpMode, setSignUpMode] = useState(true);
    const AppState = useContext(AppContext);
    console.log(AppState);

    useEffect(() => {
        getUser();
        AppState.handleNavbar(false);
        auth().onAuthStateChanged((user) => {
            if (user) {
                console.log(user)
            }
            setLoading(false)
        })
        return () => AppState.handleNavbar(true)
    }, [])

    const googleSignIn = async () => {

        try {
            var provider = new firebase.auth.GoogleAuthProvider()
            const login = await auth().signInWithPopup(provider)

            if (login.user.email.split('@')[1] === 'ftlabs.io') {
                AppState.authenticate({
                    photoURL: login.user.photoURL,
                    email: login.user.email,
                    id: login.user.uid,
                    displayName: login.user.displayName
                })
                localStorage.setItem('user', JSON.stringify({
                    photoURL: login.user.photoURL,
                    email: login.user.email,
                    id: login.user.uid,
                    displayName: login.user.displayName
                }))
            }
            else {
                window.alert("only ftlabs team can access")
            }

        } catch (error) {
            console.log({ error })

        }
    }

    const getUser = async () => {
        let val = await FirebaseApi['user'].get();
        let users = val.docs.map((user) => ({
            ...user.data(),
            id: user.id
        }));
        console.log(users);
    }

    const makeUser = async (values) => {
        let value = await CloudServices.user.register(values.email, values.password, values.username);
        if (value == "created") {
            setSignUpMode(false);
        }
        // console.log(value);
    }

    const userLogin = async (values) => {
        let result = await CloudServices.user.login(values.email, values.password);
        console.log(result);
        if (result) {
            AppState.authenticate(result);
            localStorage.setItem("user", JSON.stringify(result))

        }
    }

    const formik = useFormik({
        initialValues: {
            email: '', // user
            password: ''
        },
        // validationSchema: ,
        onSubmit: values => {
            console.log(values)
            // signUpMode ? makeUser(values) : userLogin(values);
        },
    });

    const { values, setFieldValue } = formik

    if (AppState.loggedIn) {
        return <Redirect to="/" />
        // if(lastName === '' && firstName === '' && country === '' && spaceName.layout === ''){
        //     return <Redirect to ="/profileCreation" />
        // } else {
        //     return <Redirect to='/creator' />
        // }
    }

    return loading ? (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100%' }}>
            <CircularProgress size={60} />
        </div>

    ) : (
        <Box style={{ height: '100%', width: '100%' }}>
            <LoginPopup elevation={5}>
                <Grid container direction='column' style={{ width: '80%' }} rowSpacing={2}>
                    <Grid item>
                        <Typography variant='h5' style={{ textAlign: 'center', fontWeight: 600 }}>Become a Creator on Klynk</Typography>
                    </Grid>
                    <Grid item container direction='column' style={{ marginLeft: '10px' }} rowSpacing={2}>
                        <Grid item>
                            <Typography>Email Address<sup style={{ color: 'red' }}>*</sup></Typography>
                            <TextField 
                                size='small' 
                                type='email' 
                                value={values.email} 
                                onChange={e => setFieldValue('email', e.target.value)} 
                                style={{ width: '90%' }}
                                placeholder="samplename@email.com"
                            />
                        </Grid>
                        <Grid item>
                            <Typography>Create a Password</Typography>
                            <TextField 
                                size='small' 
                                type='password' 
                                value={values.password} 
                                onChange={e => setFieldValue('password', e.target.value)} 
                                style={{ width: '90%' }}
                                placeholder="********"
                            />
                        </Grid>
                        <LoginPageButton>
                            <Button onClick={() => formik.submitForm()}>Continue</Button>
                        </LoginPageButton>
                    </Grid>
                    <Divider style={{ marginTop: 20 }}>OR</Divider>
                    <Grid item container direction='column' style={{ marginLeft: '10px' }} rowSpacing={2}>
                        <LoginPageButton>
                            <LoginPageButtonImage>
                                <img src={Google} alt='' />
                                <Typography>Continue with Google</Typography>
                            </LoginPageButtonImage>
                        </LoginPageButton>
                        <LoginPageButton>
                            <LoginPageButtonImage>
                                <img src={Facebook} alt='' style={{ width: '24', height: '24' }} />
                                <Typography>Continue with Facebook</Typography>
                            </LoginPageButtonImage>
                        </LoginPageButton>
                        <Grid item style={{ textAlign: 'center' }}>
                            Already on klynk? <Button onClick={console.log("login")}>Login</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </LoginPopup>
        </Box>
    )
}