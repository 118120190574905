import { Grid } from '@material-ui/core';
import React from 'react';
import EntityTable from '../Entities/EntityTable';

export default function index(props) {
  return ( 
    <Grid style={{ height: '100vh' }}>
      <EntityTable entity={"recipe"} nameLink />
    </Grid>
  )
}
