
import firebase, { FirebaseApi, storage, auth } from '../../firebaseConfig'
import { CRUDoperations } from '../CRUD/crud'
import recipes from '../Recipes/recipes'





const user = {
    register: async (email, password, username, imageFile = "") => {
        try {

            // Registering user with firebase authentication, no need for checking account exists. firebase authentication will do that.
            const register = await auth().createUserWithEmailAndPassword(email, password)
            console.log(register);
            const user = await register.user
            console.log(user)
            // console.log(user)
            // var getURL;
            // if (imageFile) {

            // const uploadImage = await user.uploadFile(imageFile)
            // const getURL = await user.getURL(imageFile)
            // // }

            // const options = {
            //     maxSizeMB: 2,
            //     maxWidthOrHeight: 200,
            //     useWebWorker: true
            // }
            // const compressedFile = await imageCompression(imageFile, options)
            // const compressedImage = await imageCompression.getDataUrlFromFile(compressedFile)




            //  firebase authentication only creates user with email and password, if you want to store other  details we need to create a collection and store there
            // created a collection with registered user.id (for easy access later) and storing additional information there
            await user.sendEmailVerification()
            const userData = {
                verified: false,
                id: user.uid,
                allergies: [],
                birthDay: "",
                cookbookCount: 0,
                diets: [],
                dislikedIngredients: [],
                gender: '',
                groups: [],
                height: {
                    value: "",
                    units: ""
                },
                followers: 0,
                following: 0,
                likedRecipes: 0,
                cookbooksCount: 0,
                profileImageName: '',
                profileImage: '',
                userDetails: false,
                email: email,
                username: username,
                likedRecipes: 0,
                location: "",
                phone: "",
                profileImageName: "",
                weight: {
                    value: "",
                    units: ""
                },
                age: "",
                // providerData: register.user?.providerData,
                creator: false,

            }
            console.log(userData);
            await FirebaseApi["user"].doc(user.uid).set(userData)

            // await FirebaseApi["mealPlanGroups"].add({
            //     mealPlanId:user.uid,
            //     mealPlanName:username,
            //     userId:user.uid
            // })

            // after storing the addtional info, getting that and returning

            return 'created'

        }

        catch (error) {
            throw error

        }

    },
    login: async (email, password) => {
        try {
            // firebase auth will check for the credentials
            const userCredentials = await auth().signInWithEmailAndPassword(email, password)
            // after getting userCredentials we are getting additional info with userId from firestore collection and returing that details

            console.log({ userCredentials, providerData: userCredentials.user?.providerData })
            if (1 == 1) {
                const doc = await FirebaseApi["user"].doc(userCredentials?.user?.uid).get()

                const userData = {
                    ...doc.data(),
                    providerData: userCredentials.user?.providerData,
                    emailVerified: userCredentials.user?.emailVerified
                }
                return userData

            }
            else {
                throw new Error("[auth/not-verified]")
                return ({
                    emailVerified: userCredentials.emailVerified,
                    email: email
                })
            }
            // return User.user
        }
        catch (err) {
            throw err


        }
    },
    verifyResetPasswordCode: async (actionCode) => {
        try {
            // console.log(auth())
            const email = auth().verifyPasswordResetCode(actionCode)
            return email
        } catch (error) {
            throw error
        }
    },
    resetPassword: async (actionCode, newPassword) => {
        try {
            await auth().confirmPasswordReset(actionCode, newPassword);
            return 'success'
        } catch (error) {
            throw error
        }
    },
    verifyEmail: async (actionCode) => {
        try {
            await auth().applyActionCode(actionCode)
            if (auth().currentUser) {

                await auth().currentUser.reload()
            }
            return 'success'

        } catch (error) {
            throw error
        }
    },
    sendEmailVerification: async () => {
        try {
            var actionCodeSettings = {
                url: 'http://riku.cloud',
                iOS: {
                    bundleId: 'com.example.ios'
                },
                android: {
                    packageName: 'com.futuristiclabs.kitchen',
                    installApp: true,
                    minimumVersion: '12'
                },
                handleCodeInApp: true
            };
            console.log(auth().currentUser)
            return await auth().currentUser.sendEmailVerification(actionCodeSettings)
        } catch (error) {
            throw error
        }
    },
    logout: async () => {
        try {
            await auth().signOut()
            return 'logout successful'
        } catch (error) {
            throw error

        }
    },
    deleteAccount: async (id) => {
        try {
            await FirebaseApi["user"].doc(id).delete()
            await auth().currentUser.delete()
        } catch (error) {
            throw error
        }
    },
    authCheck: async () => {
        try {
            auth().onAuthStateChanged((user) => {

                if (user) {
                    return user
                }
                else {
                    return ''
                }

            })
        }
        catch (err) {
            throw err
        }

    },
    currentUser: async () => {
        var user = await auth().currentUser

        return user
    },
    googleSignIn: async () => {
        try {
            var provider = new auth().GoogleAuthProvider();
            const result = await auth().signInWithPopup(provider)
            return result
        } catch (error) {
            throw error

        }
    },
    googleCustomSignIn: async ({ idToken, accessToken, user }) => {
        try {
            const credentials = await auth.GoogleAuthProvider.credential(idToken, accessToken)

            const result = await auth().signInWithCredential(credentials)

            const checkForUserData = await FirebaseApi["user"].doc(result.user?.uid).get()
            const userDataInFirestore = checkForUserData.data()



            if (!userDataInFirestore) {

                const addToCollection = await FirebaseApi["user"].doc(result.user?.uid).set({
                    id: result.user?.uid,
                    email: user.email,
                    username: user.name,
                    firstname: user.givenName,
                    lastname: user.familyName,
                    profileImage: user.photo,
                    followers: 0,
                    following: 0,
                    likedRecipes: 0,
                    cookbooksCount: 0,
                    profileImageName: '',
                    userDetails: false
                })
            }
            const doc = await FirebaseApi["user"].doc(result.user?.uid).get()

            return {
                ...doc.data(),
                providerData: result.user?.providerData
            }


        } catch (error) {
            throw error

        }
    },
    facebookCustomSignIn: async (accessToken) => {
        try {
            const credentials = await auth().FacebookAuthProvider.credential(accessToken)

            const result = await auth().signInWithCredential(credentials)

            const checkForUserData = await FirebaseApi["user"].doc(result.user?.uid).get()
            const userDataInFirestore = checkForUserData.data()

            if (!userDataInFirestore) {

                const addToCollection = await FirebaseApi["user"].doc(result.user?.uid).set({
                    id: result.user?.uid,
                    email: result.additionalUserInfo?.profile?.email,
                    username: result.additionalUserInfo?.profile?.name,
                    firstname: result.additionalUserInfo?.profile?.first_name,
                    lastname: result.additionalUserInfo?.profile?.last_name,
                    profileImage: result.additionalUserInfo?.profile?.picture?.data?.url,
                    provider: result.additionalUserInfo?.providerId,
                    followers: 0,
                    following: 0,
                    likedRecipes: 0,
                    cookbooksCount: 0,
                    profileImageName: '',
                    userDetails: false
                })
            }
            const doc = await FirebaseApi["user"].doc(result.user?.uid).get()
            return {
                ...doc.data(),
                providerData: result.user?.providerData
            }


        } catch (error) {
            throw error

        }
    },
    getOtpPhoneSignIn: async (phoneNumber, recaptchaVerifier) => {
        try {
            const phoneProvider = new auth().PhoneAuthProvider();
            const verification = await phoneProvider.verifyPhoneNumber(phoneNumber, recaptchaVerifier);
            return verification
        } catch (error) {
            throw error

        }
    },
    phoneSignIn: async (phoneNumber) => {
        try {
            const confirmation = await auth().signInWithPhoneNumber(phoneNumber);
            return confirmation
        } catch (error) {
            throw error

        }

    },
    verifyOtpAndSignIn: async (confirmation, otp) => {
        try {
            const result = await confirmation.confirm(otp)
            const checkForUserData = await FirebaseApi["user"].doc(result.user?.uid).get()
            const userDataInFirestore = checkForUserData.data()
            console.log(result)

            if (!userDataInFirestore) {

                const addToCollection = await FirebaseApi["user"].doc(result.user?.uid).set({
                    id: result.user?.uid,
                    email: result.additionalUserInfo?.profile?.email || '',
                    username: result.additionalUserInfo?.profile?.name || '',
                    firstname: result.additionalUserInfo?.profile?.first_name || '',
                    lastname: result.additionalUserInfo?.profile?.last_name || '',
                    profileImage: result.additionalUserInfo?.profile?.picture?.data?.url || '',
                    provider: result.additionalUserInfo?.providerId || '',
                    phoneNumber: result.user?.phoneNumber,
                    providerData: result.user?.providerData,
                    followers: 0,
                    following: 0,
                    likedRecipes: 0,
                    cookbooksCount: 0,
                    profileImageName: '',
                    userDetails: false
                })
                const doc = await FirebaseApi["user"].doc(result.user?.uid).get()
                return {
                    ...doc.data(),
                    providerData: result.user?.providerData,
                }
            }
            else {

                return {
                    ...userDataInFirestore,
                    providerData: result.user?.providerData
                }
            }

        }
        catch (error) {
            throw error

        }
    },
    googleSignIn: async (details) => {
        try {
            const googleCredential = auth.GoogleAuthProvider.credential(details.idToken);

            // Sign-in the user with the credential
            const result = await auth().signInWithCredential(googleCredential);
            const checkForUserData = await FirebaseApi["user"].doc(result.user?.uid).get()
            const userDataInFirestore = checkForUserData.data()

            if (!userDataInFirestore) {

                const addToCollection = await FirebaseApi["user"].doc(result.user?.uid).set({
                    id: result.user?.uid,
                    email: details.user?.email,
                    username: details.user?.name,
                    firstname: details.user.givenName,
                    lastname: details.user?.familyName,
                    profileImage: details.user?.photo,
                    followers: 0,
                    following: 0,
                    likedRecipes: 0,
                    cookbooksCount: 0,
                    profileImageName: '',
                    userDetails: false
                })
                const doc = await FirebaseApi["user"].doc(result.user?.uid).get()

                return {
                    ...doc.data(),
                    providerData: result.user?.providerData
                }
            }
            else {

                return {
                    ...userDataInFirestore,
                    providerData: result.user?.providerData
                }
            }

        } catch (error) {
            throw error

        }
    },
    faceBookSignIn: async (accessToken) => {
        try {
            const facebookCredential = auth.FacebookAuthProvider.credential(accessToken);

            // Sign-in the user with the credential
            return auth().signInWithCredential(facebookCredential);
        } catch (error) {
            throw error
        }
    },
    validateOtpAndSignIn: async (verificationId, otp) => {
        try {
            const credential = await auth().PhoneAuthProvider.credential(verificationId, otp);
            const result = await auth().signInWithCredential(credential);

            const checkForUserData = await FirebaseApi["user"].doc(result.user?.uid).get()
            const userDataInFirestore = checkForUserData.data()

            if (!userDataInFirestore) {

                const addToCollection = await FirebaseApi["user"].doc(result.user?.uid).set({
                    id: result.user?.uid,
                    email: result.additionalUserInfo?.profile?.email || '',
                    username: result.additionalUserInfo?.profile?.name || '',
                    firstname: result.additionalUserInfo?.profile?.first_name || '',
                    lastname: result.additionalUserInfo?.profile?.last_name || '',
                    profileImage: result.additionalUserInfo?.profile?.picture?.data?.url || '',
                    provider: result.additionalUserInfo?.providerId || '',
                    phoneNumber: result.user?.phoneNumber,
                    providerData: result.user?.providerData,
                    followers: 0,
                    following: 0,
                    likedRecipes: 0,
                    cookbooksCount: 0,
                    profileImageName: '',
                    userDetails: false
                })
            }
            const doc = await FirebaseApi["user"].doc(result.user?.uid).get()
            return {
                ...doc.data(),
                providerData: result.user?.providerData,
            }


        } catch (error) {
            throw error

        }
    },
    verifyPhoneNumber: async (phoneNumber) => {
        try {
            const confirmation = await auth().verifyPhoneNumber(phoneNumber)
            return confirmation
        } catch (error) {
            throw error
        }
    },
    linkPhoneToAccount: async (confirmation, code) => {
        try {

            const credentials = auth.PhoneAuthProvider.credential(confirmation.verificationId, code);
            const result = await user.linkAccount(credentials)
            if (result === 'Linked Successfully') {
                await FirebaseApi['user'].doc(auth().currentUser.uid).update({ providerData: auth().currentUser.providerData })
            }
            return result

        } catch (error) {
            throw error

        }

    },
    linkFaceBookToAccount: async (accessToken) => {
        try {
            const credentials = await auth().FacebookAuthProvider.credential(accessToken)
            user.linkAccount(credentials)

        } catch (error) {
            throw error

        }
    },
    linkAccount: async (credentials) => {
        try {

            const result = await auth().currentUser.linkWithCredential(credentials)
            await auth().currentUser.reload()
            return 'Linked Successfully'
        } catch (error) {
            throw error

        }
    },
    unLinkAccount: async (providerId) => {
        try {
            const user = await auth().currentUser
            const unlink = await user.unlink(providerId)
            return 'unlinked Successfully'
        } catch (error) {
            throw error

        }
    },
    forgotPassword: async (email) => {
        try {
            const result = await auth().sendPasswordResetEmail(email)

            return result
        } catch (error) {
            throw error

        }
    },
    searchUsers: async (options) => {
        try {
            // this is search operation for users 
            // first we are getting all users from firestore collection
            const snapshot = await CRUDoperations.search('user', options)

            return snapshot
            // and checking for the search keyword with the name of the individual user
            // and returing the found result back
            // const result = await snapshot.docs.filter(doc => (doc.data()).username === keyword)
            // console.log(result[0]?.data(), result)
            // return result

        }
        catch (err) {

            throw err
        }
    },
    searchRecipes: async (options) => {
        try {
            // should search recipes according to user preferences
            const snapshot = await CRUDoperations.search('recipe', options)

            return snapshot

        }
        catch (err) {
            throw err
        }
    },
    searchIngredients: async (options) => {
        try {
            const ingredientSearch = await CRUDoperations.search('ingredient', options)
            return ingredientSearch
        }
        catch (err) {
            throw err
        }
    },
    follow: async (toFollowId, userId) => {
        try {
            // getting "following" collection from firebase
            const snapshot = await FirebaseApi.following.get()

            // getting current user all details
            const getUserDetails = await FirebaseApi.user.doc(userId).get()

            // getting details of the user to whom the current user wants to follow 
            const getFollowingUserDetails = await FirebaseApi.user.doc(toFollowId).get()

            // checking if current user has any data in the following collection
            const userTable = snapshot.docs.filter(doc => (doc.data()).userId === userId)

            // if the current user has any data in following table means he already followed someone previously
            //  we are creating a new entry
            if (userTable.length === 0) {
                // adding data to "following" collection with fields { userId,followingId}
                const result = await FirebaseApi.following.add({ userId, followingId: toFollowId })

                // after adding the new entry we need to update the following count of the "user" and followers count of the "follwed user"
                const updateUserDetails = await FirebaseApi.user.doc(userId).update({ ...getUserDetails.data(), following: getUserDetails.data().following + 1 })
                const updateFollowedUserDetails = await FirebaseApi.user.doc(toFollowId).update({ ...getFollowingUserDetails.data(), followers: getFollowingUserDetails.data().followers + 1 })
                return 'Followed Successfully'
            }
            // else user have data already

            else {


                const alreadyFollowed = userTable.filter((item) => item.data().followingId === toFollowId)

                if (alreadyFollowed.length > 0) {
                    return 'Already Followed'
                }
                else {
                    const result = await FirebaseApi.following.add({ userId, followingId: toFollowId })
                    const updateUserDetails = await FirebaseApi.user.doc(userId).update({ following: getUserDetails.data().following + 1 })
                    const updateFollowedUserDetails = await FirebaseApi.user.doc(toFollowId).update({ followers: getFollowingUserDetails.data().followers + 1 })
                    return "Followed Successfully"
                }
                // userTable.forEach(async (item) => {

                //     // checking if currentUser already followed to that id
                //     if (item.data().followingId == toFollowId) {
                //         throw 'already followed'


                //     }
                //     // else we are creating a new entry 
                //     else {
                //         const result = await FirebaseApi.following.add({ userId, followingId: toFollowId })
                //         const updateUserDetails = await FirebaseApi.user.doc(userId).update({ following: getUserDetails.data().following + 1 })
                //         const updateFollowedUserDetails = await FirebaseApi.user.doc(toFollowId).update({ followers: getFollowingUserDetails.data().followers + 1 })
                //     }
                // })
            }

        }
        catch (err) {

            throw err
        }



    },
    unFollow: async (toUnFollowId, userId) => {
        try {
            // getting "following" collection from firebase
            const snapshot = await FirebaseApi.following.get()

            // getting current user all details
            const getUserDetails = await FirebaseApi.user.doc(userId).get()

            // getting details of the user to whom the current user wants to follow 
            const getFollowingUserDetails = await FirebaseApi.user.doc(toUnFollowId).get()

            // checking if current user has any data in the following collection
            const userTable = snapshot.docs.filter(doc => doc.data().userId === userId && doc.data().followingId === toUnFollowId)

            const unFollowing = await FirebaseApi.following.doc(userTable[0].id).delete()
            const updateUserDetails = await FirebaseApi.user.doc(userId).update({ ...getUserDetails.data(), following: getUserDetails.data().following - 1 })
            const updateFollowedUserDetails = await FirebaseApi.user.doc(toUnFollowId).update({ ...getFollowingUserDetails.data(), followers: getFollowingUserDetails.data().followers - 1 })
            return "unFollowed Successfully"


        }
        catch (err) {
            throw err
        }

    },
    getFollowing: async (userId) => {
        try {
            // getting the all data of the "following" collection
            const snapshot = await FirebaseApi.following.get()

            // geting the data of current user
            const userTable = await snapshot.docs.filter(doc => (doc.data()).userId === userId)

            // if user has already followed someone 
            // proceed 
            if (userTable.length > 0) {
                // var followingUsers = []

                const followingUsers = userTable.map(async (user) => {

                    // getting info of each of the following user data  
                    const userDetail = await FirebaseApi.user.doc(user.data().followingId).get()

                    // if there is data for the particular following id we add that to followingUsers
                    // console.log(userDetail.data())

                    return userDetail?.data()


                })
                // console.log(followingUsers)
                // console.log(await Promise.all(followingUsers))
                return await Promise.all(followingUsers)


                // return followingUsers

            }
            // else should return not following
            else {
                console.log("not following")
            }
        }
        catch (err) {
            throw err

        }

    },
    getFollowers: async (userId) => {
        try {

            // getting data "following" Collection 
            const snapshot = await FirebaseApi.following.get()
            // now from the same data we are searching for entries which have followingId === userId and get the id of who is following 

            const userTable = await snapshot.docs.filter(doc => (doc.data()).followingId === userId)

            // if userTable.length is 0 that means there are no followers yet
            // else
            if (userTable.length > 0) {
                // var followingUsers = []
                const followingUsers = userTable.map(async (user) => {
                    // console.log(user.data())
                    // now getting each of the user data and adding it to followingUsers
                    const userDetail = await FirebaseApi.user.doc(user.data().userId).get()

                    // console.log(userDetail.data())
                    const checkingUserFollowedCurrentUser = await snapshot.docs.filter(doc => (doc.data()).userId === userId && doc.data().followingId == userDetail.data().id)
                    // console.log(checkingUserFollowedCurrentUser)
                    if (checkingUserFollowedCurrentUser.length > 0) {
                        return { followingThisUser: true, ...userDetail?.data() }

                    }
                    else {
                        return { followingThisUser: false, ...userDetail?.data() }
                    }
                    // return userDetail?.data()


                })
                return await Promise.all(followingUsers)

            }

        }
        catch (err) {

            throw err
        }
    },
    getUserWithId: async (userId, Id) => {

        try {
            const UserDetails = await FirebaseApi.user.doc(Id).get()

            const followingData = await FirebaseApi.following.get()


            const currentUserFollowStatus = followingData.docs.filter((doc) => doc.data().userId === userId && doc.data().followingId === Id)


            if (currentUserFollowStatus.length > 0) {
                return { followingThisUser: true, ...UserDetails.data() }
            }
            else {
                return { followingThisUser: false, ...UserDetails.data() }

            }


        }
        catch (err) {
            throw err

        }
    },
    addCollection: async (userId, name, type, list = []) => {
        try {
            const addingNewUserGroup = await FirebaseApi.collections.add({
                userId,
                name,
                type,
                list
            })

        }
        catch (err) {
            throw err
        }

    },
    addUserGroups: async (userId, name, type = 'userGroup') => {
        try {
            const addingNewUserGroup = await user.addCollection(userId, name, type,)

        }
        catch (err) {
            throw err
        }
    },
    addCookBooks: async (userId, name, type = 'recipeCookBook') => {
        try {

            // getting user Details 
            const getUserDetails = await FirebaseApi.user.doc(userId).get()

            const addingNewCookbook = await user.addCollection(userId, name, type)
            // we need to update cookbooksCount in user info
            const updateUserDetails = await FirebaseApi.user.doc(userId).update({ cookbooksCount: getUserDetails.data().cookbooksCount + 1 })
            return "Created Cookbook successfully"


        }
        catch (err) {

            throw err
        }


    },
    cookBooks: async (userId) => {

        try {
            // const getUserDetails = await FirebaseApi.user.doc(userId).get()

            // getting data from "cookBooks" collection for currentUser 
            const userData = await FirebaseApi.collections.where("userId", "==", `${userId}`).where('type', "==", "recipeCookBook").get()

            const data = userData.docs.map((doc) => ({ id: doc.id, ...doc.data() }))


            // if currentUser have any cookbooks we return them
            return data
        }
        catch (err) {
            throw err
        }




    },
    groups: async (userId) => {
        try {

            // getting data from "cookBooks" collection for currentUser 
            const userData = await FirebaseApi.collections.where("userId", "==", `${userId}`).where('type', "==", "userGroup").get()

            const data = userData.docs.map((doc) => ({ id: doc.id, ...doc.data() }))


            // if currentUser have any cookbooks we return them
            return data
        }
        catch (err) {
            throw err
        }
    },
    addRecipeToCookBook: async (userId, cookBookName, recipeDetails) => {
        try {
            // searching cookbooks for the currentUser
            const userData = await FirebaseApi.collections.where('userId', "==", `${userId}`).where('name', "==", `${cookBookName}`).get()


            const collection = userData.docs.map(doc => ({ id: doc.id, data: doc.data() }))
            const cookBookDoc = collection[0]



            const searchingForExistence = cookBookDoc.data?.list.filter((recipe) => recipe.id === recipeDetails.id)


            if (searchingForExistence.length > 0) {
                throw `Already added the recipe to ${cookBookName}`
            }
            else {
                const addingRecipeToDoc = cookBookDoc.data?.list.push(recipeDetails)

                const updatingCollectionData = await FirebaseApi.collections.doc(cookBookDoc.id).update(cookBookDoc.data)
                return 'Added successfully'
            }

            // // // const updateCookBook = await FirebaseApi.cookBooks.doc(userId).update({ books: [...userData.data().books,] })

        }
        catch (err) {

            throw err
        }


    },
    deleteRecipeFromCookBook: async (userId, cookBookName, recipeId) => {
        try {
            // searching cookbooks for the currentUser
            const userData = await FirebaseApi.collections.where("userId", "==", `${userId}`).where("cookBookName", "==", `${cookBookName}`).get()


            const collection = userData.docs.map(doc => ({ id: doc.id, data: doc.data() }))
            const cookBookDoc = collection[0]


            const removingFromCollection = cookBookDoc.data?.recipes.filter((recipe) => recipe.id !== recipeId)


            const updateCollection = await FirebaseApi.collections.doc(cookBookDoc.id).update({ recipes: removingFromCollection })
            return "deleted successfully"



        }
        catch (err) {
            throw err

        }
    },
    getAllRecipes: async () => {
        try {
            const recipesData = await recipes.get()

            return recipesData

        }
        catch (err) {

            throw err
        }

    },
    getAllUsers: async () => {
        try {
            const users = await FirebaseApi['user'].get();
            console.log(users)
            return user;
        }
        catch (err) {

            throw err
        }
    },
    getRecipeWithId: async (id) => {
        try {
            const recipe = await recipes.getRecipeWithId(id)
            return recipe

        } catch (err) {
            throw err

        }
    },
    getUserRecipes: async (userName) => {
        try {
            const userRecipes = await recipes.search({ keyword: [["recipe.author.name", "==", `${userName}`]] })

            return userRecipes



        }
        catch (err) {
            throw err
        }
    },

    getAllUsers: async () => {
        let val = await FirebaseApi['user'].get();
        let users = val.docs.map((user) => ({
            ...user.data(),
            id: user.id
        }));

        return users;


        return users;

    },
    getURL: async (name) => {
        try {

            if (process.env.REACT_APP_API_KEY) {

                var url = await storage().ref().child(`images/${name}`).getDownloadURL()
                return url
            }
            else {
                var url = await storage().ref(`images/${name}`).getDownloadURL()
                return url
            }

        }
        catch (err) {
            throw err

        }
    },
    uploadLogs: async (file, name, path) => {
        try {
            if (process.env.REACT_APP_API_KEY) {
                var logsRef = storage().ref().child(`logs/${name}`)
                await logsRef.put(file)
            }
            else {
                var logsRef = storage().ref(`logs/${name}`)
                await logsRef.putFile(path)

            }
        } catch (error) {
            throw error

        }
    },
    uploadProfileImage: async (file, id) => {
        try {
            if (process.env.REACT_APP_API_KEY) {

                var profileImagesRef = storage().ref().child(`profileImages/${id}`) // 'images/JaneDoe.jpg'
                await profileImagesRef.put(file)
                const url = await profileImagesRef.getDownloadURL()
                // await user.updateUserDetails({profileImage:url},id)
                return url
            }
            else {

                var profileImagesRef = storage().ref(`profileImages/${id}`) // 'images/JaneDoe.jpg'
                await profileImagesRef.putFile(file)
                const url = await profileImagesRef.getDownloadURL()
                console.log(url)
                // await user.updateUserDetails({profileImage:url},id)
                return url
            }

        } catch (error) {
            throw error
        }
    },
    uploadFile: async (file, name) => {
        try {

            // const drawImageInCanvas = await imageCompression.drawImageInCanvas(compressedFile)


            // console.log(compressedImage)

            if (process.env.REACT_APP_API_KEY) {

                var profileImagesRef = storage().ref().child(`images/${name}`) // 'images/JaneDoe.jpg'
                await profileImagesRef.put(file)
                return "Success"
            }
            else {
                var profileImagesRef = storage().ref(`images/${name}`) // 'images/JaneDoe.jpg'
                await profileImagesRef.putFile(file)
                return "Success"
            }



        }
        catch (err) {
            throw err

        }

    },
    deleteFile: async (name) => {
        try {
            if (process.env.REACT_APP_API_KEY) {

                await storage().ref().child(`images/${name}`).delete()
                return 'Success'
            }
            else {
                await storage().ref(`images/${name}`).delete()
                return 'Success'
            }


        }
        catch (err) {
            throw err

        }
    },
    updateUserDetails: async (details, id) => {
        try {
            // const getPresentDetails = await FirebaseApi["user"].doc(id).get()

            await FirebaseApi["user"].doc(id).update(details)
            return 'updated'

        } catch (error) {
            throw error

        }
    },
    getUserDetails: async (id) => {
        try {
            const details = await FirebaseApi['user'].doc(id).get()

            return details.data()
        } catch (error) {
            throw error
        }
    },
    minderResult: async (id, data) => {
        try {
            const allDetails = await FirebaseApi['user'].doc(id).get()
            const details = allDetails.data()

            var updatedData;
            if (details.minder) {
                const minder = details.minder
                updatedData = {
                    likedRecipes: [...minder?.likedRecipes, ...data.likedRecipes],
                    disLikedRecipes: [...minder?.disLikedRecipes, ...data.disLikedRecipes],
                    superLikedRecipes: [...minder?.superLikedRecipes, ...data.superLikedRecipes]
                }

            }
            else {
                updatedData = { ...data }
            }

            const updateUserDetails = await FirebaseApi['user'].doc(id).update({ minder: updatedData })
            return 'updated'
        } catch (error) {
            throw error
        }

    },
    createGroup: async (groupDetails, userId) => {
        try {
            const groupDetail = await FirebaseApi["collections"].add({ ...groupDetails, createdOn: firebase.firestore.FieldValue.serverTimestamp() })
            console.log(groupDetail, groupDetail.id)
            await FirebaseApi["userGroups"].add({ groupId: groupDetail.id, userId: groupDetails?.ownerId, owner: true, createdOn: firebase.firestore.FieldValue.serverTimestamp() })
            return "success"
        } catch (error) {
            throw error
        }

    },
    deleteGroup: async (groupId, userId) => {
        try {




        } catch (error) {
            throw error
        }

    },
    addUserToGroup: async (groupId, user, requestId, from) => {

        try {

            await FirebaseApi["userGroups"].add(
                {
                    groupId,
                    userId: user.id,
                    link: true,
                    owner: false,
                    ownerDetails: {
                        name: from.name,
                        profileImage: from.profileImage
                    },
                    ownerId: from.id,
                    userDetails: {
                        name: user.username,
                        profileImage: user.profileImage
                    }
                }
            )


            await FirebaseApi["groupRequest"].doc(requestId).delete()
            return 'success'
        } catch (error) {
            throw error
        }
    },
    removeFromGroup: async (removeUser, groupData) => {

        try {

            // const user = await FirebaseApi["user"].doc(removeUser.id).get()
            // const removeUserData = user.data()
            // remove from user profile
            // const addingResult = await FirebaseApi["user"].doc(removeUser.id).update({groups: removeUserData.groups ? removeUserData?.groups?.filter(group=>group.id !==groupData.id) : []})
            await FirebaseApi["user"].doc(removeUser.id).update({
                groups: firebase.firestore.FieldValue.arrayRemove({ id: groupData.id, link: true })
            })

            // const groupDetails = await FirebaseApi["collection"].doc(groupData).get()
            delete removeUser.groups

            // groupData.members.filter(member=>console.log(member.id!==removeUser.id,member,removeUser))
            // remove from group members
            const update = await FirebaseApi["collection"].doc(groupData.id).update({ members: firebase.firestore.FieldValue.arrayRemove(removeUser) })

            const pantry = await FirebaseApi["collection"].where("ownerId", "==", groupData.ownerId).where("type", "==", "Pantry").get()
            const pantryData = pantry.docs.map((doc) => ({ ...doc.data(), id: doc.id }))[0]


            // remove from pantry
            const updatePantry = await FirebaseApi["collection"].doc(pantryData.id).update({ members: firebase.firestore.FieldValue.arrayRemove(removeUser) })

            return 'deleted Successfully'
        } catch (error) {
            console.log(error)
            throw error
        }
    },

    isCreator: async (userId) => {
        try {
            // get userId, and check if user falls under creator or not
            const userDetails = await FirebaseApi.user.doc(userId).get();
            console.log(userDetails);
            if (userDetails.creator) {
                return true;
            } else {
                return false;
            }

        } catch {

        }

    },
    getEarningsDetails: async (userId) => {
        if (user.isCreator(userId)) {
            try {
                // get earnings data
                const earningsData = await FirebaseApi['creatorEarnings'].get();

                // check for userEarnings with user id
                const earningsTable = earningsData.earnings.where('creatorId', '==', `${userId}`).get();
                return earningsTable;


            } catch (err) {
                throw err;
            }
        } else {
            console.log("User is not a creator");
        }
    },

    getMembershipDetails: async (userId) => {
        if (user.isCreator(userId)) {
            try {
                // get memberShipsdata
                const memberData = await FirebaseApi.memberShips.where('creatorId', '==', `${userId}`).get();
                return memberData;
                // check for usersMembership id


            } catch (err) {
                throw err;
            }
        } else {
            console.log('User is not a creator');
        }

    },

    getCreatorLayout: async (userId) => {
        if (user.isCreator(userId)) {
            try {
                // get userLayoutDetails based on userId
                const getLayoutData = await FirebaseApi.UILayouts.where('creatorId', '==', `${userId}`).get();
                return getLayoutData;

            } catch (err) {
                throw err;
            }
        } else {
            console.log('User is not a creator');

        }
    }

}

export default user