// context api
import { useContext } from 'react';

// app context
import AppContext from '../../config/AppContext';

// profile Images
import ProfileImage1 from '../../assets/ProfileCreation/boy-cooking.png'
import ProfileImage2 from '../../assets/ProfileCreation/loading-image.png';
// import ProfileImage2 from './Profile2/ProfileImage2';

export const useProfileSteps = () => {
    const AppState = useContext(AppContext);
    const userData = AppState.user;

    const initialValues = {
        firstName: (userData?.firstName || ' '),
        lastName: (userData?.lastName || ' '),
        webURL: (userData?.webURL || ' '),
        country: (userData?.country || ' '),
        importRecipesURL : (userData?.importRecipesURL || ' '),
        parseFromOutsideURL: (userData?.parseFromOutsideURL || false),
        layout: (userData?.spaceName?.layout || 'Simple'),
        accentColor: (userData?.spaceName?.accentColor || '#FFE8E3'),
        backgroundColor: (userData?.spaceName?.backgroundColor || '#BD6B5C'),
        headerFont: (userData?.spaceName?.headerFont ||'Roboto'),
        paraFont: (userData?.spaceName?.paraFont || 'Poppins'),
    };
    
    const steps = [
        {
            label: "Set Up Your Profile",
            image: ProfileImage1,
        },
        {
            label: "Import Recipe in a click",
            image: ProfileImage2,
        },
        {
            label: "Choose a site's look",
            image: "image3",
        }
    ];

    return {
        initialValues,
        steps,
    }

}
