import React from 'react'
import { LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';
import { GridCharts } from '../Styles';

const LineDashBoardChart = ({ data }) => {
  return  (
    <GridCharts>
    <LineChart width={700} height={150} data={data}>
      <Line type="monotone" dataKey="uv" stroke="#8884d8" />
      <CartesianGrid stroke="#ccc" />
      <XAxis dataKey="name" />
      <YAxis />
    </LineChart>
    </GridCharts>
  )
}

export default LineDashBoardChart;