//initial react calls
import React, { useState, useContext } from 'react';

//styles
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import AppIcon from '../../assets/AppIcon.png'
import { makeStyles } from '@mui/styles';

// router
import { Link, useHistory, useLocation } from 'react-router-dom'

// menu icons
import AddRecipe from '../../assets/NavBar/AddRecipe.svg'
import AddRecipeUnselected from '../../assets/NavBar/AddRecipeUnselected.svg'
import Browse from '../../assets/NavBar/Browse.svg'
import BrowseUnselected from '../../assets/NavBar/BrowseUnselected.svg'
import Drafts from '../../assets/NavBar/Drafts.svg'
import DraftsUnselected from '../../assets/NavBar/DraftsUnselected.svg'
import Entities from '../../assets/NavBar/Entities.svg'
import EntitiesUnselected from '../../assets/NavBar/EntitiesUnselected.svg'
import Trash from '../../assets/NavBar/Trash.svg'
import TrashUnselected from '../../assets/NavBar/TrashUnselected.svg'
import Creator from '../../assets/CreatorScreen/Creator.svg';

//context calling
import AppContext from '../../config/AppContext';

const drawerWidth = 220;

const useStyles = makeStyles({
  openButton: {
    zIndex: 2,
    position: 'fixed',
    top: '23%',
    left: (props) => props.open ? drawerWidth - 10 : `calc(${props.theme.spacing(7)})`,
    borderRadius: 50,
    backgroundColor: 'white',
    border: "1px solid gray",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    transition: (props) => props.theme.transitions.create('left', {
      easing: props.theme.transitions.easing.sharp,
      duration: props.theme.transitions.duration.enteringScreen,
    })
  },
  expandOnHover: {
    '&:hover': {

    }
  },
  importLoading: {
    position: 'relative',
    width: 100,
    height: 100,
    backgroundColor: 'gray',
    borderRadius: '50%'
  },
  importLoadingRing: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 100,
    height: 100,
    border: '5px solid black',
    borderRadius: '50%',
    transform: 'scale(1)',
    animation: "$rotate 2s linear infinite alternate"


  },
  hideScrollBar: {
    '&::-webkit-scrollbar': {
      display: 'none'
    },

  },
  "@keyframes rotate": {
    "0%": {
      transform: 'scale(1)',
      borderColor: 'orange'
    },
    "25%": {
      transform: 'scale(1.3)',
      borderColor: 'blue'
    },
    "50%": {
      transform: 'scale(1.5)',
      borderColor: 'green'
    },
    "75%": {
      transform: 'scale(1.3)',
      borderColor: 'gray'
    },
    "100%": {
      transform: 'scale(1)',
      borderColor: 'black'
    }
  },

})

const openedMixin = (theme) => ({
  marginTop: 20,
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  borderTopRightRadius: 12,
  borderBottomRightRadius: 12,
  zIndex: 1

});

const closedMixin = (theme) => ({
  marginTop: 20,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  borderTopRightRadius: 12,
  borderBottomRightRadius: 12,
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
  zIndex: 1,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  width: drawerWidth,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function MiniDrawer(props) {
  const AppState = useContext(AppContext);
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [hoveredOn, setHoveredOn] = useState(null);
  const classes = useStyles({ ...props, open, theme });
  const location = useLocation();
  const history = useHistory();

  const expandOnHover = (value) => {
    setOpen(true)
    setHoveredOn(value)
  }

  const closeOnHoverLeave = () => {
    setOpen(false)
    setHoveredOn(null);
  }
  // ::-webkit-scrollbar {display:none;}

  const onClick = () => {
    setOpen(false)
    setHoveredOn(null)
    AppState.closeDrawer()
  }

  const drawerContent = () => {
    return (
      <>
        <DrawerHeader  >
          <div onClick={() => history.push('')} style={{ paddingLeft: 10, width: 60, height: 60, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <img src={AppIcon} style={{ width: '100%', height: 'auto', borderRadius: 12 }} alt="main icon" />
          </div>
          <div style={{ marginLeft: 20 }}>
            <Typography>Riku</Typography>
            <Typography>Futuristic Labs</Typography>
          </div>
        </DrawerHeader>
        {[
          {
            name: 'Add Recipe',
            route: '/createRecipe/0',
            activeIcon: AddRecipe,
            inActiveIcon: AddRecipeUnselected,
          },
          {
            name: 'Browse',
            route: '/',
            activeIcon: Browse,
            inActiveIcon: BrowseUnselected,
          },
          {
            name: 'Drafts',
            route: '/drafts',
            activeIcon: Drafts,
            inActiveIcon: DraftsUnselected,

          },
          {
            name: 'Entities',
            route: '/entities/dependent/ingredient',
            activeIcon: Entities,
            inActiveIcon: EntitiesUnselected,
          },
          {
            name: 'Trash',
            route: '/trash',
            activeIcon: Trash,
            inActiveIcon: TrashUnselected,
          },

          {
            name: 'Creator',
            // route: AppState.user == null ? '/login' : '/creator',
            route: '/Creator',
            activeIcon: Creator,
            inActiveIcon: Creator,
          }
        ].map((text, index) => {
            return (
              <Link key={index} onMouseEnter={() => expandOnHover(text.name)} onMouseLeave={closeOnHoverLeave} onClick={onClick} to={text.route} style={{ textDecoration: 'none' }}>

                <div className={classes.hideScrollBar} style={{ width: drawerWidth - 30, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', overflowX: 'scroll', padding: 10, }}>
                  <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 5, backgroundColor: ((hoveredOn === text.name) || (open && location.pathname === text.route)) ? '#F94C0C' : 'transparent', borderTopLeftRadius: open ? 32 : 0, borderBottomLeftRadius: open ? 32 : 0 }}>
                    <img src={location.pathname === text.route ? text.activeIcon : text.inActiveIcon} style={{ width: 50, height: 50 }} alt="menu-drawer" />

                  </div>
                  <div style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', backgroundColor: ((hoveredOn === text.name) || (open && location.pathname === text.route)) ? '#F94C0C' : 'transparent', borderTopRightRadius: open ? 32 : 0, borderBottomRightRadius: open ? 32 : 0 }}>
                    <Typography style={{ flex: 1, marginLeft: 20, color: ((hoveredOn === text.name) || (open && location.pathname === text.route)) ? 'white' : 'black' }}>{text.name}</Typography>
                  </div>
                </div>
              </Link>
            )
          }
        )}
      </>
    )
  }

  return (
    <Box sx={{ display: 'flex', }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open}
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box' },
        }}
      >
        {drawerContent(props)}
      </Drawer>
      <Box
        component="nav"
        sx={{ flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <MuiDrawer
          variant="temporary"
          open={AppState.drawerOpen}
          onClose={AppState.closeDrawer}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawerContent()}
        </MuiDrawer>
      </Box>
    </Box>
  );
}