import React from 'react'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    switchView: {
        cursor: 'pointer',
        position: 'relative',
        width: 35,
        height: 10,
        appearance: 'none',
        backgroundColor: (props) => props.disabled ? '#d9d9d9' : props.unCheckedBackgroundColor,
        outline: 'none',
        borderRadius: 20,
        transition: '.5s',
        margin: 0,
        border: (props) => `1px solid ${props.disabled ? '#d9d9d9' : 'black'}`,
        '&::before': {
            content: '""',
            position: 'absolute',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: 20,
            height: 20,
            borderRadius: 20,
            top: -6,
            left: -1,
            backgroundColor: (props) => props.disabled ? '#d9d9d9' : props.unCheckedBackgroundColor,
            border: (props) => `1px solid ${props.disabled ? '#d9d9d9' : '#000'}`,
            transition: '.5s',
            boxShadow: '0px 0px 2px #000000',
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            top: -1,
            right: 4,
            width: 20,
            height: 20,
            transition: 'width .5s linear',
        },
        '&:checked': {
            backgroundColor: '#9CC8EC',
            border: "1px solid #fff"
        },
        '&:checked::before': {
            content: '""',
            left: '68%',
            top: -6,
            backgroundColor: (props) => props.checkedBackgroundColor,
            transition: '.5s',
            // border:"1px solid #F94C0C",
            border: (props) => `1px solid ${props.unCheckedBackgroundColor}`,
            boxShadow: '0px 0px 2px #000000'
        },
        '&:checked::after': {
            content: '""',
            color: 'white',
            left: 0,
            transition: 'left .5s linear',
        }
    }
})

function Switch(props) {
    const classes = useStyles(props);

    return <input id={props?.id} type="checkbox" name={props.name} disabled={props.disabled} checked={props.checked} onChange={(e) => props.onChange(e)} value={props.value} className={classes.switchView} {...props} />
}

Switch.propTypes = {
    size: PropTypes.number,
    functionality: PropTypes.func,
    title: PropTypes.string || PropTypes.func,
    checkedBackgroundColor: PropTypes.string,
    unCheckedBackgroundColor: PropTypes.string

}
Switch.defaultProps = {
    size: 30,
    noBorder: false,
    checkedBackgroundColor: '#387CF0',
    unCheckedBackgroundColor: ' #ffffff'
}


export { Switch }
