import { Button, Grid, Typography } from "@mui/material";
import { styled } from "@mui/styles";

export const SettingsTextFieldArea = styled(Grid) ({
    height: 'fit-content',
    marginBottom: 10,
});

export const SettingTypography = styled(Typography)({
    textTransform: 'uppercase',

});

export const SettingButton = styled(Button)({
    border: '1px solid #707070',
    padding: 10,
    borderRadius: 24
});