import React, { Component } from 'react';
import { ErrorMessage, Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const header = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 150 },
    { field: "type", headerName: "Type", width: 150 },
    { field: "unit", headerName: "Units", width: 250 },
    { field: "description", headerName: "Description", width: 250 },
]

const initialValues = {
    name: "",
    type: "",
    unit: "",
    descriptio: ""

}

const validate = values => {
    var errors = {}
    console.log("Will validate")
    return errors
}

class AddRow extends Component {
    render() {
        return (
            <Formik
                initialValues={initialValues}
                onSubmit={(values, actions) => {
                    this.props.addRow(values);
                }}
            >
                {formik => (
                    <form onSubmit={formik.handleSubmit} style={{
                        display: "flex", flexDirection: "column", justifyContent: "space-between",
                        alignItems: "flex-start", minHeight: 400, height: "100%"
                    }}>
                        <div>
                            <TextField id="name" label="Name"
                                onChange={formik.handleChange}
                                fullWidth
                                value={formik.values.name} />
                            <TextField id="type" label="Type"
                                onChange={formik.handleChange}
                                fullWidth
                                value={formik.values.type}
                            />
                            <TextField
                                fullWidth
                                id="synonyms"
                                label="Synonyms"
                                value={formik.values.units}
                                onChange={formik.handleChange}
                            />
                            <TextField
                                fullWidth
                                id="description"
                                label="Description"
                                multiline
                                rowsMax={4}
                                value={formik.values.description}
                                onChange={formik.handleChange}
                            />
                        </div>
                        <Button variant="contained" color="primary" type="submit" disabled={this.props.actionInProgress}>
                            Save
                            </Button>
                    </form>
                )
                }
            </Formik>
        )
    }
}

export default AddRow;